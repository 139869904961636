<template>
  <v-form ref="form"
    v-model="validForm"
    @submit.prevent="submit">
    <v-card-text>
      <h1>{{ $t(`products.delivery.detail`)}}</h1>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field outlined
            :label="$t('models.user.name')"
            type="text"
            v-model="name"
            :rules="[$vRequired]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field outlined
            :label="$t('models.user.email')"
            type="text"
            v-model="email"
            :rules="[$vRequired]"></v-text-field>
        </v-col>
        <template v-if="!isDigital">
          <v-col cols="6">
            <v-text-field outlined
              :label="$t('models.user.country')"
              v-model="country"
              :rules="[$vRequired]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field outlined
              :label="$t('models.user.province')"
              type="text"
              v-model="province"
              :rules="[$vRequired]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field outlined
              :label="$t('models.user.city')"
              type="text"
              v-model="city"
              :rules="[$vRequired]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field outlined
              :label="$t('models.user.postal_code')"
              type="text"
              v-model="postalCode"
              :rules="[$vRequired]"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field outlined
              :label="$t('models.user.address')"
              type="text"
              v-model="address"
              :rules="[$vRequired]"></v-text-field>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <c-btn
        text color="secondary"
        @click="close">
          {{ $t('actions.close') }}
      </c-btn>
      <c-btn
        :loading="disabled"
        :disabled="disabled || !validForm"
        @click="submit">
        {{ $t('actions.next') }}
      </c-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'ProductShippingForm',
  mixins: [validatable('form')],
  props: {
    disabled: Boolean,
    product: Object
  },
  data () {
    return {
      name: '',
      email: '',
      country: null,
      postalCode: null,
      province: null,
      city: null,
      address: null,
      error: false,
      errorMessage: ''
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    isDigital () {
      return this.product.deliveryType === 'digital'
    },
    packageRules () {
      return this.isDigital ? [] : [this.$vRequired]
    },

    digitalRules () {
      return this.isDigital ? [this.$vRequired] : []
    },
    shippingDetails () {
      return {
        email: this.email,
        name: this.name,
        country: this.country,
        postalCode: this.postalCode,
        province: this.province,
        city: this.city,
        address: this.address
      }
    }
  },
  methods: {
    onSubmit () {
      this.$emit('next', this.shippingDetails)
    },
    close () {
      this.$emit('close')
    }
  },
  mounted () {
    const name = this.currentUser.name || ''
    const surname = this.currentUser.surname || ''
    this.name = `${name} ${surname}`.trim()
    this.email = this.currentUser.email
  },
}
</script>
