<template>
  <div
    ref="videoWrapper"
    class="video-player"
    :class="{ rounded, 'card-view': cardView  }"
    :style="videoPlayerClass"
  >
    <!-- -->
    <component
      :is="playerComponent"
      ref="player"
      v-bind="$attrs"
      :class="playerClasses"
      :style="playerStyle"
      :height="height"
      :max-height="maxHeight"
      :width="width"
      :contain="contain"
      :rounded="rounded"
      :autoload="autoload"
      :poster="poster"
      :resource="resource"
      :sources="sources"
      @videoEnded="onEnd"
    />
  </div>
</template>

<script>
import AwsVideo from './video/AwsVideo.vue'
import BunnyVideo from './video/BunnyVideo.vue'

export default {
  name: 'VideoPlayer',
  provide () {
    return {
      viewport: this.$refs.videoWrapper
    }
  },
  props: {
    resource: Object,
    height: [Number, String],
    maxHeight: [Number, String],
    width: [Number, String],
    contain: Boolean,
    rounded: Boolean,
    autoload: Boolean,
    poster: String,
    sources: {
      type: Array,
      default: () => []
    },
    cardView: { type: Boolean },
    defineHeight: { type: Boolean }
  },
  data () {
    return {
      player: null
    }
  },
  computed: {
    metadata () {
      return this.resource.metadata || { clip: { height: 0 } }
    },

    cover () {
      return !this.contain
    },

    videoPlayerClass () {
      if (this.height && this.defineHeight) {
        return `${this.height}px`
      }
      const clipMetadata = this.metadata.clip || {}
      if (!clipMetadata.height) return {}
      return {
        height: `${clipMetadata.height}px`
      }
    },
    landscape () {
      if (!this.width || !this.height) {
        return false
      }
      return this.width > this.height
    },
    playerComponent () {
      return this.resource.location === 'bunny' ? BunnyVideo : AwsVideo
    },
    playerClasses () {
      return {
        'contain-video': this.contain,
        'cover-height': this.cover && this.landscape,
        'cover-width': this.cover && !this.landscape,
        rounded: this.rounded
      }
    },
    playerStyle () {
      const style = {}
      if (this.cover) {
        return style
      }
      if (this.maxHeight) {
        style['max-height'] = `${this.maxHeight}px`
      }
      return style
    }
  },
  methods: {
    onEnd () {
      this.$emit('videoEnded')
    },
    play () {
      this.player.play()
    },
    pause () {
      this.player.pause()
    }
  },
  mounted () {
    this.player = this.$refs.player
  }
}
</script>
<style lang="scss" scoped>
@use 'src/scss/colors';

.video-player {
  overflow: hidden;
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;

  .contain-video {
    width: 100%;
    height: 100%;
  }

  .cover-width {
    width: 100%;
  }

  .cover-height {
    height: 100%;
    width: 100%;
  }
}

.card-view {
  display: grid;
  place-content: center;
  height: 100% !important;
}
</style>
