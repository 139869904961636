<template>
  <svg :height="`${size}px`" :width="`${size}px`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g id="perfil-videollamada" transform="translate(-155 -339)">
      <rect id="Rectángulo_7107" data-name="Rectángulo 7107" width="24" height="24" transform="translate(155 339)"
            fill="none"/>
      <path :fill="color" id="Icon_awesome-video" data-name="Icon awesome-video"
            d="M14.008,4.5H1.992A1.992,1.992,0,0,0,0,6.492V18.508A1.992,1.992,0,0,0,1.992,20.5H14.008A1.992,1.992,0,0,0,16,18.508V6.492A1.992,1.992,0,0,0,14.008,4.5ZM21.9,6.071l-4.567,3.15v6.558L21.9,18.925A1.336,1.336,0,0,0,24,17.85V7.146A1.337,1.337,0,0,0,21.9,6.071Z"
            transform="translate(155 338.5)"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VideoCall',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
