<template>
  <v-row align="center">
    <v-col cols="12">{{ $t('conferences.form.already_created') }}</v-col>
    <v-col cols="8" class="small-text">
      <div class="bold">{{ title }}</div>
      <div>
        <span class="bold">{{ $t('models.conference.visibility') }}</span>
        <span class="ml-2">{{ typeLabel }}</span>
      </div>
      <div v-if="minutePrice">
        <span class="bold">{{ $t('models.conference.minute_price') }}</span>
        <span class="ml-2">{{ $currency(minutePrice, currency) }}</span></div>
    </v-col>
    <v-col cols="4">
      <a @click="link">{{ $t('actions.show_item', { item: conferenceLabel }) }}</a>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'ConferenceDescription',
  props: {
    id: Number,
    title: String,
    type: String,
    status: String,
    startsAt: String,
    freeFans: Boolean,
    currency: String,
    minutePrice: Number
  },
  computed: {
    conferenceLabel () {
      return this.$t('models.conferences.singular_name')
    },
    typeLabel () {
      return this.$t(`models.conference.types.${this.type}`)
    }
  },
  methods: {
    link () {
      this.$router.push({ name: 'conference', params: { uuid: this.id } })
      this.$emit('leave')
    }
  }
}
</script>
