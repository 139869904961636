<template>
  <div v-if="isES" class="pt-4">
    <p>¡Hola!Queremos informarte que hemos actualizado los términos que aplican en Loverfans.com, así como nuestra
      política de
      privacidad, debido a cambios corporativos y en la operativa de la plataforma.</p>

    <p>Los cambios más importantes como creador de contenido en Loverfans.com son:</p>

    <ol>
      <li>Actualizamos las condiciones para que puedas acceder y compartir contenido en nuestra plataforma. Es necesario
        que firmes el nuevo contrato actualizado para creadores de contenido.
      </li>
      <li>Actualizamos nuestros términos en cuanto a contenidos prohibidos y restricciones de acceso.</li>
      <li>Actualización respecto a colaboraciones con otras personas en los contenidos que publiques a través de
        Loverfans.com.
      </li>
    </ol>

    <p>Puedes revisar estos cambios en los textos completos con las nuevas condiciones en:</p>

    <ul>
      <li><a :href="`https://${project.locales.site}/newterms`">https://{{ project.locales.site }}/newterms</a></li>
      <li><a
        :href="`https://${project.locales.site}/contract-conditions`">https://{{ project.locales.site }}/contract-conditions</a>
      </li>
    </ul>
    <v-row class="justify-end mr-4 pt-4">
      <c-btn to="/contract">
        aceptar y firmar el contrato
      </c-btn>
    </v-row>
  </div>
  <div v-else class="pt-4">
    <p>We want to inform you that we have updated the terms that are applied on Darkfans.com, as well as our privacy
      policy.</p>

    <p>The most important changes as a content creator on Darkfans.com are:</p>

    <ol>
      <li>We update the conditions to access and share content on our platform. You have to sign an updated Content
        Creator Agreement.
      </li>
      <li>We updated our terms regarding forbidden content and access restrictions.</li>
      <li>Update regarding collaborations with other people in the content you publish on Darkfans.com.</li>
    </ol>

    <p>You can review these changes at:</p>

    <ul>
      <li><a :href="`https://${project.locales.site}/newterms`">https://{{ project.locales.site }}/newterms</a></li>
      <li><a
        :href="`https://${project.locales.site}/contract-conditions`">https://{{ project.locales.site }}/contract-conditions</a>
      </li>
    </ul>

    <v-row class="justify-end mr-4 pt-4 ">
      <c-btn to="/contract">
        ACCEPT CHANGES AND SIGN THE CONTRACT
      </c-btn>
    </v-row>
  </div>
</template>

<script>
import project from '@/project'
import UserApi from "@/api/UserApi";
import {mapState} from "vuex";

export default {
  name: 'SignCreatorsSummary',
  data() {
    return {
      project
    }
  },
  computed: {
    isES() {
      return this.$i18n.locale === 'es'
    }
  }
}
</script>
