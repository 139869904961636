'user strict'

import xlsx from 'json-as-xlsx'
import api from '@/api/Api'
import config from '@/project'
import utils from '@/utils'

const refineData = ([utm]) => {
  const t = utm
  t.content = t.content.map((c) => ({
    ...c,
    lastPostCreated: c.lastPostCreated,
    userCreatedAt: c.userCreatedAt,
    userPromotionVerifiedAt: c.userPromotionVerifiedAt,
    createdAt: c.createdAt,
    updatedAt: c.updatedAt,
  }))
  return [t]
}

export default {

  list (query) {
    return api.get('/admin/users', query)
  },

  find (username) {
    return api.get(`/admin/users/${username}`)
  },

  update (user) {
    return api.put(`/admin/users/${user.username}`, user)
  },

  updatePromotion (promotion) {
    return api.put(`/admin/users/${promotion.username}/promotion`, promotion)
  },

  superUserCount (form) {
    return api.post('/users/admin/count', form)
  },

  superUserAddFollower (form) {
    return api.post('/users/admin/add/followers', form)
  },

  superUserMessage (form) {
    return api.post('/conversations/admin/broadcast', form)
  },
  changePersonalData (form) {
    return api.put('/admin/users/personal_data', form)
  },
  inavlidateContract (username) {
    return api.put(`/admin/users/${username}/invalidate`)
  },
  fixSignedDocument (form) {
    return api.post('/admin/fixSignedDocument', form)
  },
  getWallets (query) {
    return api.get('/admin/wallets', query)
  },
  transferWalletAmount (query) {
    return api.post('/admin/wallets', query)
  },
  async getUtm (fromDate, toDate) {
    const result = await api.get('/admin/utm', { fromDate: fromDate, toDate: toDate })
    if (!result.error) {
      const data = refineData(result.data)
      const startDate = utils.formatDate(fromDate, 'YYYYMMDD')
      const endDate = utils.formatDate(toDate, 'YYYYMMDD')
      xlsx(data, { fileName: `${config.acronym}_${startDate}-${endDate}_UTM.xlsx` })
    }
  }
}
