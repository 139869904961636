<template>
  <section>
    <h1 class="mb-1">{{ $t('featured_users.title')}}
      <c-btn x-small
        class="mb-1"
        @click="dialog = true">
        {{ $t('actions.change') }}
      </c-btn>
    </h1>
    <v-chip  v-for="user in featuredUsers"
        :key="user"
        outlined
        close
        color="primary"
        class="mx-1"
        @click:close="unfeatureUser(user)">
        @{{ user.username }}
      </v-chip>
    <p v-if="!featuredUsers.length"
      class="px-5">
      {{ $t('featured_users.no_users')}}
    </p>
    <FeaturedUserDialog
      v-model="dialog" 
      @selected="featureUser"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import FeaturedUserDialog from '@/components/featured/FeaturedUserDialog'

export default {
  name: 'FeaturedUserSection',
  components: { FeaturedUserDialog },
  data () {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapState('profile', ['featuredUsers']),
  },
  methods: {
    featureUser(user) {
      this.$store.dispatch(ProfileActions.FeatureUser, user)
    },
    unfeatureUser(user) {
      this.$store.dispatch(ProfileActions.UnfeatureUser, user)
    }
  }
}
</script>
