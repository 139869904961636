<template>
  <v-form v-model="validForm" ref="form"
    :dark="false"
    @submit.prevent="submit">
    <v-alert type="error"
      v-if="signupError"
      icon="warning"
      dense outlined>
      {{ signupError }}
    </v-alert>
    <v-row>
      <v-col cols="12" sm="6">
        <username-input outlined
          v-model="username"
          :rules="[$vRequired]" />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field outlined
          persistent-hint
          :label="$t('models.user.displayName')"
          v-model="displayName"
          :rules="[$vBannedWords(displayName),$vMaxLength(50)]"
          :hint="$t('models.user.displayName_hint')"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field outlined
          ref="email"
          :label="$t('models.user.email')"
          v-model="email"
          :rules="[$vRequired,$vEmail]"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field outlined
          :label="$t('models.user.email_confirmation')"
          v-model="emailConfirmation"
          :rules="[$vRequired,$vConfirmation('email')]"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <PasswordInput outlined
          ref="password"
          v-model="password"
          :rules="[$vRequired]" />
      </v-col>
      <v-col cols="12" sm="6">
        <PasswordInput outlined
          :label="$t('models.user.password_confirmation')"
          v-model="passwordConfirmation"
          :override-rules="[$vRequired,$vConfirmation('password')]" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-checkbox outlined
          color="primary"
          :label="$t('models.user.isAdult')"
          v-model="isAdult"
          hide-details="auto"
          :rules="[$vBoolRequired]"></v-checkbox>
        <v-checkbox outlined
          color="primary"
          v-model="acceptedTerms"
          hide-details="auto"
          :rules="[$vBoolRequired]">
          <template slot="label">
            {{$t('models.user.accept')}}&nbsp;
            <a @click.stop :href="goToConditions" target="_blank"> {{ $t('application.menu.terms_conditions') }}</a>
          </template>
        </v-checkbox>
        <v-checkbox outlined
                    color="primary"
                    hide-details="auto"
                    v-model="acceptedMarketing">
          <template slot="label">
            {{ $t('application.menu.marketing') }}
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <c-btn block
      color="primary"
      css="mb-3"
      :disabled="!validForm"
      @click.stop.prevent="submit">
      {{ $t('actions.signup') }}
    </c-btn>

    <c-btn block
      text
      @click.stop.prevent="emitLogin"
      css="mb-3">
      {{ $t('actions.login') }}
    </c-btn>
  </v-form>
</template>
<script>
import { SessionActions } from '@/store'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'SignupForm',
  mixins: [validatable('form')],
  data () {
    return {
      displayName: '',
      username: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      acceptedMarketing: false,
      acceptedTerms: false,
      isAdult: false,
      signupError: ''
    }
  },
  computed: {
    goToConditions () {
      return `https://${this.$t('site')}/conditions`
    }
  },

  methods: {
    emitLogin () {
      this.$emit('login')
    },
    user () {
      return {
        username: this.username.trim(),
        displayName: this.displayName.trim(),
        email: this.email.trim(),
        password: this.password.trim(),
        acceptedTerms: this.acceptedTerms,
        acceptedMarketing: this.acceptedMarketing,
        isAdult: this.isAdult,
        lang: this.$i18n.locale.trim(),
        skype: this.skype,
        telegram: this.telegram,
        twitterUser: this.twitterUser,
        prefix: this.prefix,
        mobile: this.mobile
      }
    },

    async onSubmit () {
      const { data, error } = await this.$store.dispatch(SessionActions.Signup, this.user())
      if (!error) {
        this.$emit('submit', data)
        /*
        this.$gtm.trackEvent({
        event: 'GA4',
        category: "ux",
        action: "complete_register_clic",
        label: "complete_register",
        value: 5000,
      }); */
      } else {
        if (data.data && data.data.field) {
          const field = this.$t(`models.user.${data.data.field}`)
          const error = this.$t(`remote_errors.${data.errorCode}`, { field, value: data.data.value })
          this.signupError = error
          this.$vuetify.goTo(0)
        }
      }
    }
  }
}
</script>
<style lang="scss">
  .v-select__selections {
    min-height: 56px !important;
  }

  div.v-input__append-inner {
    margin: auto !important;
  }
</style>
