<template>
  <section>
    <Box>
      <div
        class="blurs"
        @click="showBuy"
        :style="`background-image: url(${getBlurUrl})`"
      >
        <div class="row center paymentBtn">
          <div></div>
          <div class="col-12 center" style="display: block">
            <span class="material-icons">
              lock
            </span>
            <Buttons
              v-if="!message.searchMessage"
              class="buyBtn"
              :text="
                $t(
                  message.attachments.length > 1
                    ? 'actions.buy_for_multiple'
                    : 'actions.buy_for',
                  {
                    number: message.attachments.length,
                    price: getPriceCurrency
                  }
                )
              "
            ></Buttons>
          </div>
        </div>
      </div>
    </Box>
    <WarningDialog
      :show="showBuyMessage"
      :textWarning="textBuy"
      :textButtonOK="$t('actions.buy').toUpperCase()"
      @handlerButtonCancel="showBuy"
      @handlerButtonOK="buyMessages"
      :loading="loadingButton"
    />

    <Dialog
      :show="showLoadFunds"
      @hide="() => (showLoadFunds = !showLoadFunds)"
      :title="$t('actions.load_funds').toUpperCase()"
    >
      <template slot="bodyDialog">
        <div>
          <LoadFunds :price="messageLocal.price" />
        </div>
      </template>
    </Dialog>

    <WarningDialog
      :show="showErrorBuyMessage"
      :textWarning="textWarningWallet"
      :textButtonOK="$t('actions.reload').toUpperCase()"
      @handlerButtonCancel="showErrorBuy"
      @handlerButtonOK="showFunds"
    >
      <template #plus>
        <p style="font-size: 12px;">
          {{
            `*${$t("subscriptions.vat_explain", { price: $currency(getPriceExes) })}`
          }}
        </p>
      </template></WarningDialog
    >
  </section>
</template>

<script>
import IconButton from "@/components/chat/component/IconButton.vue";
import WarningDialog from "@/components/chat/component/WarningDialog.vue";
import Dialog from "@/components/chat/component/Dialog.vue";
import LoadFunds from "@/components/chat/components/LoadFunds.vue";
import Buttons from "@/components/chat/component/Buttons";
import buyAttach from "@/components/chat/mixin/buyAttach";

export default {
  name: "FilePayment",
  mixins: [buyAttach],
  props: {
    message: { type: Object, default: {} }
  },
  components: {
    Buttons,
    IconButton,
    WarningDialog,
    LoadFunds,
    Dialog
  }
  /* data() {
    return {
      showErrorBuyMessage: false,
      showBuyMessage: false,
      messageLocal: this.message,
      showLoadFunds: false,
      loadingButton: false
    };
  }, */
  /* computed: {
    ...mapState("profile", ["currentUser"]),
    ...mapState("chats", ["selectedChat", "listMessage"]),
    ...mapGetters("profile", ["totalWalletAmount"]),
    textBuy() {
      if (this.messageLocal.hasOwnProperty("attachments")) {
        const { price, currency } = this.messageLocal;
        const totalFiles = this.messageLocal.attachments.length;
        const localePrice = this.$currency(price, currency);
        return this.$t("chats.buy_action", {
          price: localePrice,
          files: totalFiles
        });
      }
      return "";
    },
    getPrice() {
      return this.messageLocal.price;
    },
    getPriceCurrency() {
      return this.$currency(this.messageLocal.price, this.currentUser.currency);
    },
    textWarningWallet() {
      const price = this.$currency(this.messageLocal.price);
      return `${this.$t("actions.set_price")}: ${price}. ${this.$t(
        "payments.wallet_not_enought_amount"
      )}`;
    },
    getBlurUrl() {
      if (this.messageLocal.hasOwnProperty("attachments")) {
        return this.messageLocal.attachments[0].url;
      } else {
        return this.messageLocal.url;
      }
    },
    isVault() {
      return this.message.hasOwnProperty("searchMessage");
    },
    countAttachment() {
      const count = {
        exist: false,
        image: 0,
        video: 0
      };
      if (this.messageLocal.hasOwnProperty("attachments")) {
        this.messageLocal.attachments.forEach(f => {
          if (f.type === "image") {
            count.image++;
          } else {
            count.video++;
          }
          count.exist = true;
        });
      }
      if (this.isVault) {
        count.exist = false;
      }
      return count;
    }
  },
  methods: {
    async showBuy() {
      if (this.messageLocal.searchMessage) {
        await this.getMessage();
      }
      if (this.messageLocal.price > this.totalWalletAmount) {
        this.showErrorBuy();
      } else {
        this.showBuyMessage = !this.showBuyMessage;
      }
    },
    async getMessage() {
      const conversationId = this.message.conversationId;
      const conversationMessageId = this.message.messageId;
      const { data, error } = await ConversationsApi.findMessage(
        conversationId,
        conversationMessageId
      );
      if (!error) {
        this.messageLocal = data;
      }
    },
    showFunds() {
      this.showErrorBuyMessage = !this.showErrorBuyMessage;
      this.showLoadFunds = !this.showLoadFunds;
    },
    showErrorBuy() {
      this.showErrorBuyMessage = !this.showErrorBuyMessage;
    },
    async buyMessages() {
      this.loadingButton = true;
      const conversationId = this.selectedChat.id;
      const conversationMessageId = this.messageLocal.id;
      const { data, error } = await ConversationsApi.buyMessage(
        conversationId,
        conversationMessageId
      );
      if (!error) {
        this.loadingButton = false;
        this.updatedConversations(data);
      } else {
        this.showErrorBuy();
      }
      this.showBuy();
    },
    updatedConversations(chat) {
      this.$store.dispatch(ProfileActions.Fetch);
      this.$emit("refresh-message", chat);
    }
  } */
};
</script>
<style lang="scss">
.blurs {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  .centers {
    justify-content: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    background-color: rgb(128 128 128 / 40%);
    border-radius: 5px;
  }
}
.text-black {
  color: black;
}
.count {
  margin-top: -10px;
}
.buyBtn {
  font-size: 12px;
  background-color: var(--v-primary-base) !important;
  border: var(--v-primary-base) !important;
}
.paymentBtn {
  border-radius: 5px;
  margin: auto !important;
  background-color: #81818147;
  height: -webkit-fill-available;
  text-align: center !important;
}
</style>
