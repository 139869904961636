import S3Api from '@/api/S3Api'
import Utils from '@/utils'

const imageMimeTypes = ['webp', 'jpg', 'jpeg', 'png', 'gif']
const videoMimeTypes = [
  'm3u8',
  'mov',
  'mpg',
  'mpeg',
  'mp4',
  'wmv',
  'avi',
  'webm',
  'quicktime'
]
const audioMimeTypes = ['mp3', 'wav', 'mpeg','m4a','ogg']
const verifyValidExt = ext => (ext === 'quicktime' ? 'mov' : ext)

const convertImage = file => {
  if (Utils.isGif(file.name)) {
    return file
  }
  const canvas = document.createElement('CANVAS')
  const img = new Image()
  const src = URL.createObjectURL(file)
  const promise = new Promise(resolve => {
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      URL.revokeObjectURL(src)
      const converted = canvas.toFile('image/jpeg', { name: file.name })
      resolve(converted)
    }
  })
  img.src = src
  return promise
}

export const FileWrapper = function (file) {
  this.key = file.name
  this.size = file.size
  this.file = file
  this.blob = file
  this.preview = file.preview || URL.createObjectURL(file)
  this.location = null
  this.loading = false
  this.uploaded = false
  this.error = false
  this.progress = 0
  this.totalUploaded = 0
  this.type = this.file.type || Utils.extractExtension(this.file.name)
  this.name = `${Utils.extractFilename(this.file.name)}.${Utils.extractExtension(this.file.name)}`
  this.isImage = () => Utils.isImage(this.file.name)
  this.isVideo = () => Utils.isVideo(this.file.name)
  this.isAudio = () => Utils.isAudio(this.file.name)
  this.isMedia = () => this.isImage() || this.isVideo() || this.isAudio
  this.isLarger = size => this.size > size
  this.getType = () => {
    const ext = verifyValidExt(this.type)
    return videoMimeTypes.includes(ext)
      ? `video/${ext}`
      : imageMimeTypes.includes(ext)
        ? `image/${ext}`
        : audioMimeTypes.includes(ext)
          ? `audio/${ext}`
          : ext
  }
}

const wrapFile = async file => {
  if (file.constructor === FileWrapper) {
    return file
  } else if (file.constructor !== File) {
    throw Error('El parametro no es un archivo válido')
  }
  // redraw image to remove exif (personal data, orientation, etc.)
  if (Utils.isImage(file.name)) {
    file = await convertImage(file)
  }
  return new FileWrapper(file)
}

export default {

  props: {
    autoretry: false
  },

  data () {
    return {
      maxFileSize: 6 * 1024 * 1024 * 1024,
      files: [],
      maxFileSizeErrors: {}
    }
  },

  computed: {
    autouploadFile () {
      return false
    },
    maxFileNumber () {
      return null
    }
  },

  methods: {
    wrappedFile (file) {
      return wrapFile(file)
    },

    async addFiles (files) {
      if (!files || !files.length) {
        return
      }
      const fileList = [...files]
      const addedFiles = []
      for (let i = 0; i < fileList.length; i++) {
        if (await this.addFile(fileList[i])) {
          addedFiles.push(fileList[i])
        }
      }
      this.$emit('change', addedFiles)
    },

    async addFile (file) {
      if (this.files.length === parseInt(this.maxFileNumber)) {
        this.$emit('too-many-files')
        this.onTooManyFiles(true)
        return false
      }
      const wrapped = await wrapFile(file)
      if (!this.beforeAddFile(wrapped)) {
        return
      }
      this.files.push(wrapped)
      this.$emit('file-added', wrapped)
      this.onFileAdded(wrapped)
      if (wrapped.isLarger(this.maxFileSize)) {
        this.maxFileSizeErrors[wrapped.key] = wrapped
        this.$emit('file-too-large', this.maxFileSizeErrors)
        this.onFileTooLarge(wrapped)
      } else if (this.autouploadFile) {
        this.uploadFile(wrapped)
      }
      return true
    },

    getFiles () {
      return this.files
    },

    removeFile (file, index) {
      if (file.uploadTask) file.uploadTask.cancel()
      delete this.maxFileSizeErrors[file.key]
      this.$emit('file-too-large', this.maxFileSizeErrors)
      this.files.splice(index, 1)
      this.$emit('file-removed', file, index)
      this.onFileRemoved(file)
    },

    async uploadFile (file) {
      if (this.maxFileSizeErrors[file.name]) {
        return
      }
      if (!this.onUploadFile()) {
        return
      }
      file.loading = true
      file.error = false
      file.progress = 0
      file.totalUploaded = 0
      const progressCallback = size => {
        file.totalUploaded += size.uploaded
        file.progress = parseInt((file.totalUploaded / file.size) * 100, 10)
      }
      this.uploadingResponse = S3Api.submit(file, progressCallback)
      const { data, error } = await this.uploadingResponse.promise()
      this.uploadingResponse = null
      file.loading = false
      file.error = error
      if (!error) {
        file.location = data.filename
        this.fileUploaded(file)
      }
      this.onUploadFinished(file)
    },

    // Refactor this
    async retryFile (file) {
      if (file.uploadDetails) {
        file.uploadDetails.retryingIn = 0
        if (file.uploadDetails.retryingTask) clearInterval(file.uploadDetails.retryingTask)
        const progressCallback = (size) => {
          file.totalUploaded += size.uploaded
          file.progress = parseInt((file.totalUploaded / file.size) * 100, 10)
        }
        file.loading = true
        file.error = false
        this.uploadingResponse = S3Api.resumeMultipartUpload(file, progressCallback)
        const { data, error } = await this.uploadingResponse.promise()
        this.uploadingResponse = null
        file.loading = false
        file.error = error
        if (!error) {
          file.location = data.filename
          this.fileUploaded(file)
        }
        this.onUploadFinished(file)
      } else {
        this.uploadFile(file)
      }
    },

    fileUploaded (file) {
      file.uploaded = true
      this.$emit('file-uploaded', file)
      this.onFileUploaded(file)
    },

    cancelUploads() {
      this.files.forEach((f) => {
        if (f.uploadTask) f.uploadTask.cancel()
      })
    },

    clear () {
      this.cancelUploads()
      this.files.forEach(file => URL.revokeObjectURL(file.preview))
      this.files = []
      this.onClear()
      this.$emit('input', [])
      this.$emit('change', [])
    },

    beforeAddFile () {
      return true
    },

    onFileAdded () {},

    onFileRemoved () {},

    onUploadFile () {
      return true
    },

    onFileUploaded () {},

    onUploadFinished () {},

    onClear () {},

    onTooManyFiles () {},

    onFileTooLarge (file) {}
  },
  beforeDestroy () {
    this.clear();
  }
}
