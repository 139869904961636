<template>
  <section>
<!--    <div class="mb-1">* {{ vatExplain }}</div>-->
    <!-- <div class="mb-1">** {{ $t('subscriptions.charge_explain') }}</div> -->
  </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'VatExplain',
  props: {
    price: Number,
  },
  computed: {
    ...mapState('application', ['billing']),
    vatExplain () {
      const key = this.billing.code === 'CA' ? 'subscriptions.vat_ca_explain' : 'subscriptions.vat_explain'
      return this.$t(key, { price: this.$currency(this.price, this.billing.currency) })
    }
  }
}
</script>
