import ConversationsApi from '../../api/conversations/ConversationsApi'

const actionsTypes = {
  SelectChat: 'select_chat',
  SelectBroadcast: 'select_broadcast',
  RequestPermission: 'request_permission',
  MarkRead: 'mark_read',
  UpdateChat: 'update_chat',
  UpdateAttributes: 'update_attributes',
  ShowSetting: 'is_show_setting',
  ShowGeneral: 'show_general',
  ShowChatSetting: 'is_show_chat_setting',
  ShowGeneralSetting: 'is_show_general_setting',
  ShowChatNew: 'is_show_chat_new',
  ShowDeleteChat: 'is_show_delete',
  ShowVideoChat: 'is_show_video_chat',
  ShowCarousel: 'is_show_carousel',
  CarouselItemsList: 'carousel_items_list',
  CarouselItemSelected: 'carousel_item_selected',
  isBlocked: false,
  RefreshConversation: 'refresh_chat',
  SetAttachmentForm: 'set_attachmen_form',
  DeleteAttachmentForm: 'delete_attachment_form'
}
const state = {
  selectedChat: null,
  selectedBroadcast: false,
  showSetting: false,
  showGeneral: false,
  showChatSetting: false,
  showGeneralSetting: false,
  showChatNew: false,
  showDeleteChat: false,
  showVideoChat: false,
  showCarousel: false,
  carouselItems: [],
  carouselItemSelected: 0,
  viewAttachmentForm: [],
  deleteAttachmenForm: [],
  refreshChat: {
    action: 'updated',
    value: false,
    idChat: 0
  }
}
const getters = {
  isNotified: state => {
    return state.selectedChat.notified
  },
  getPreferences: state => {
    return {
      approved: state.selectedChat.approved,
      archived: state.selectedChat.sender.archived,
      price: state.selectedChat.sender.price,
      mediaPrice: state.selectedChat.sender.mediaPrice,
      notified: state.selectedChat.notified,
      videochatPrice: state.selectedChat.sender.videochatPrice
    }
  },
  getPreferencesTranslate: state => {
    return { translate: state.selectedChat.sender.translate }
  }
}
const mutations = {
  reset: state => {
    state.selectedChat = null
    state.selectedBroadcast = false
  },

  set_chat (state, chat) {
    state.selectedChat = chat
    state.selectedBroadcast = false
  },

  set_broadcast (state, selected) {
    state.selectedChat = null
    state.selectedBroadcast = selected
  },

  update_chat (state, chat) {
    if (!state.selectedChat) {
      return
    }
    Object.keys(chat).forEach(key => {
      state.selectedChat[key] = chat[key]
    })
  },
  show_setting (state, value) {
    state.showSetting = value
  },
  show_general (state, value) {
    state.showGeneral = value
  },
  is_show_chat_setting (state, value) {
    state.showChatSetting = value
  },
  is_show_general_setting (state, value) {
    state.showGeneralSetting = value
  },
  is_show_chat_new (state, value) {
    state.showChatNew = value
  },
  is_show_video_chat (state, show) {
    state.showVideoChat = show
  },
  is_show_carousel (state, show) {
    state.showCarousel = show
  },
  is_show_delete (state, show) {
    state.showDeleteChat = show
  },
  /**
   *
   * @param state
   * @param list Array de objectos (src y type) o un objeto (src y type) puede ser null
   */
  carousel_items_list (state, list) {
    if (list && !Array.isArray(list)) {
      list = new Array(1).fill({
        url: list.url,
        type: list.type
      })
    }
    state.carouselItems = list || []
  },
  carousel_item_selected (state, index) {
    state.carouselItemSelected = index
  },
  refresh_chat (state, value) {
    state.refreshChat = value
    /*  state.refreshChat.action = value.action
    state.refreshChat.value = value.value
    state.refreshChat.idChat = value.idChat */
  },
  push_attachmen_form (state, value) {
    state.viewAttachmentForm.push(value)
  },
  pop_attachmen_form (state, index) {
    state.deleteAttachmenForm.push(state.viewAttachmentForm[index])
    state.viewAttachmentForm.splice(index, 1)
  },
  delete_attachmen_form (state) {
    state.viewAttachmentForm = []
  }
}

const actions = {
  [actionsTypes.SelectChat]: (context, chat) => {
    context.commit('delete_attachmen_form')
    context.commit('set_chat', chat)
  },
  [actionsTypes.SelectBroadcast]: (context, selected = true) => {
    context.commit('set_broadcast', selected)
  },
  /* [actionsTypes.RequestPermission]: async context => {
    const { data, error } = await ChatApi.requestPermission(
      context.state.selectedChat.id
    )
    if (!error) {
      context.commit('update_chat', data)
    }
  }, */
  [actionsTypes.MarkRead]: context => {
    const chat = context.state.selectedChat
    if (chat) {
      ConversationsApi.checkRead(chat.id)
      context.commit('update_chat', {
        ...(chat.isRead = true)
      })
    }
  },
  [actionsTypes.UpdateChat]: async (context, attrs) => {
    const chat = context.state.selectedChat
    if (chat) {
      ConversationsApi.list(chat.id, attrs)
      context.commit('update_chat', attrs)
    }
  },
  [actionsTypes.UpdateAttributes]: async (context, attrs) => {
    const chat = context.state.selectedChat
    if (chat) {
      context.commit('update_chat', attrs)
    }
  },
  [actionsTypes.ShowSetting]: (context, show) => {
    context.commit('show_setting', show)
  },
  [actionsTypes.ShowGeneral]: (context, show) => {
    context.commit('show_general', show)
  },
  [actionsTypes.ShowChatSetting]: (context, show) => {
    context.commit('is_show_chat_setting', show)
  },
  [actionsTypes.ShowGeneralSetting]: (context, show) => {
    context.commit('is_show_general_setting', show)
  },
  [actionsTypes.ShowChatNew]: (context, show) => {
    context.commit('is_show_chat_new', show)
  },
  [actionsTypes.ShowDeleteChat]: (context, show) => {
    context.commit('is_show_delete', show)
  },
  [actionsTypes.ShowVideoChat]: (context, show) => {
    context.commit('is_show_video_chat', show)
  },
  [actionsTypes.ShowCarousel]: (context, show) => {
    context.commit('is_show_carousel', show)
  },
  [actionsTypes.CarouselItemsList]: (context, carousel) => {
    context.commit('carousel_items_list', carousel)
  },
  [actionsTypes.CarouselItemSelected]: (context, index) => {
    context.commit('carousel_item_selected', index)
  },
  [actionsTypes.RefreshConversation]: (context, value) => {
    context.commit('delete_attachmen_form')
    context.commit('refresh_chat', value)
  },
  [actionsTypes.SetAttachmentForm]: (context, value) => {
    const index = context.state.viewAttachmentForm.findIndex(
      f => f.id === value.id
    )
    if (index === -1) {
      context.commit('push_attachmen_form', value)
    } else {
      context.commit('pop_attachmen_form', index)
    }
  },
  [actionsTypes.DeleteAttachmentForm]: context => {
    context.commit('delete_attachmen_form')
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'chats',
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
