<template>
  <section>
    <h1>AVISO LEGAL</h1>
    <p><b>Le rogamos que antes de acceder y usar esta plataforma lea atentamente el presente documento (el "Aviso
      Legal")</b>, en el que se establecen las condiciones que rigen el acceso y uso de la misma.</p>

    <p>Cualquier persona que acceda, navegue o utilice esta plataforma estará sujeta a lo dispuesto en este Aviso Legal.
      El uso de la plataforma implica la aceptación expresa y sin reservas de todos los términos aquí establecidos,
      siendo igualmente válido que cualquier acuerdo escrito y firmado. Si no está de acuerdo con el contenido de este
      documento, por favor no acceda, navegue ni utilice la plataforma.</p>

    <h2>1. IDENTIFICACIÓN</h2>

    <ul>
      <li>Titular: ARTEMISA3000 TECH SOLUTIONS, S.L. (en adelante, "ARTEMISA")</li>
      <li>Domicilio social: Calle Goya nº47, Planta 7, Pta. 12, 28001 Madrid (España)</li>
      <li>N.I.F.: B-88466743</li>
      <li>Registro público: [[29 de Agosto de 2019, número 1741 de protocol, inscrita en el Registro Mercantil de
        Madrid, tomo 39620, folio 10, hoja número M-703152, inscripción primera]
      </li>
      <li>Teléfono: +34656897133</li>
      <li>E-mail: <a :href="`mailto:${project.locales.contact_email}`"
                     target="_blank">{{ project.locales.contact_email }}</a></li>
    </ul>
    <br>
    <h2>2. ADVERTENCIA SOBRE EL CONTENIDO</h2>

    <h3>2.1. Naturaleza del contenido</h3>
    <p>Parte del contenido de esta plataforma puede ser de naturaleza erótica o sexual. Accediendo a ella, el usuario
      acepta ver este tipo de contenido, sabiendo que en algunos casos puede considerarse ofensivo o inapropiado. Si
      este tipo de contenido no es de su agrado o cree que puede herir su sensibilidad, debe abandonar la plataforma
      inmediatamente.</p>

    <h3>2.2. Mayoría de edad</h3>
    <p>Mediante el acceso a esta plataforma, el usuario reconoce, bajo su propia responsabilidad, haber cumplido la
      mayoría de edad legal en su jurisdicción (18 años o más, según la región).</p>

    <p>El acceso a esta plataforma está terminantemente prohibido a menores de edad, por lo que si aún no ha cumplido la
      mayoría de edad en su jurisdicción (al menos 18 años), debe abandonar la plataforma inmediatamente, por favor.
      Continuar navegando por ella está bajo la responsabilidad del usuario y puede tener consecuencias penales.</p>

    <p>Del mismo modo, cualquier persona que considere que un usuario puede ser menor de edad, debe reportarlo a <a
      :href="`mailto:${project.locales.contact_email}`" target="_blank">{{ project.locales.contact_email }}</a>.</p>
    <br>
    <h3>2.3. Aviso de advertencia y prohibición</h3>
    <p>Está prohibido por ley publicar contenidos que muestren a menores o a adultos haciéndose pasar por menores, con el fin
      de evitar el abuso y el tráfico de menores.</p>

    <p>También queda absolutamente prohibido el uso de esta plataforma para promover, participar o favorecer la
      prostitución o el tráfico sexual. Por lo tanto, queda terminantemente prohibido intercambiar información personal
      con el fin de tener encuentros fuera de la plataforma. Tampoco se permite el cobro por servicios prestados de
      manera presencial. Cualquier incumplimiento de lo anterior será de la exclusiva responsabilidad del Usuario
      infractor.</p>

    <p>Al entrar en esta plataforma, el Usuario reconoce y acepta que ARTEMISA no genera contenido, sino que es el medio
      a través del cual los Creadores de Contenido pueden difundir su material legal, original y, en caso de que
      participen terceras personas, consentido.</p>

    <p>ARTEMISA dispone de recursos tecnológicos y humanos para cumplir la regulación básica sobre el contenido con el
      fin de favorecer la calidad y la legalidad del material accesible desde nuestra plataforma, y se reserva el
      derecho de emplear tales recursos para realizar revisiones periódicas y adoptar las medidas oportunas contra
      cualquiera que incumpla las normas de acceso de la plataforma, especialmente las contenidas en este Aviso Legal y
      en cualquier otro texto legal aplicable a cada usuario.</p>
    <br>
    <h3>3. OBJETO</h3>
    <p>El presente Aviso Legal establece las condiciones, derechos y obligaciones que rigen el acceso y uso de la
      plataforma ubicada en la URL <a :href="`${project.locales.site}`" target="_blank">{{ project.locales.site }}</a>
      (en adelante, la
      "Plataforma") y de los servicios y contenidos (incluidos logotipos y marcas) disponibles en ella, así como las
      responsabilidades que pueden derivarse de su uso.</p>

    <p>De este modo, el uso de la Plataforma implica la aceptación expresa y sin reservas de todos los términos del
      presente Aviso Legal, teniendo la misma validez y eficacia que cualquier contrato celebrado por escrito y firmado.
      En este sentido, ARTEMISA se reserva el derecho a modificar la presentación, configuración y contenido de la
      Plataforma, así como el presente Aviso Legal, por lo que recomendamos revisarlo frecuentemente.</p>

    <p>Este Aviso Legal será de aplicación a cualquier persona que acceda, navegue o utilice la Plataforma, con
      independencia de su condición como Usuario (anónimo o registrado) o Creador de Contenido. El mero acceso a la
      Plataforma constituye una aceptación tácita del contenido de este documento, por lo que cada persona que acceda a
      la misma deberá cumplir con lo aquí dispuesto, sin excepción.</p>

    <p>En particular, al usar la Plataforma, el Usuario o Creador de Contenido se compromete a utilizar los servicios y
      contenidos de esta de conformidad con la legislación vigente, con este Aviso Legal y con cualesquiera condiciones
      particulares aplicables a determinados productos o servicios, y reconoce y acepta que dicho uso será bajo su
      exclusivo riesgo y/o responsabilidad.</p>

    <p>En caso de llevar a cabo la contratación de cualquier producto o servicio ofrecido en la Plataforma, dicha
      contratación se regirá por el documento de <a href="/contract-conditions">Condiciones de Contratación para Usuarios</a>
      , que estos deberán aceptar
      expresamente mediante la marcación de la casilla habilitada al efecto, no siendo posible llevar a cabo la
      contratación si el Usuario no acepta las mismas.</p>

    <p>Asimismo, los Creadores de Contenido de la Plataforma quedarán sujetos en todo caso a los términos y condiciones
      estipulados en el Contrato de Creador de Contenido, el cual deberán firmar expresamente para poder darse de alta
      en la Plataforma en calidad de Creador de Contenido.</p>
    <br>
    <h3>4. DESCRIPCIÓN DE LA PLATAFORMA Y SERVICIOS OFRECIDOS</h3>

    <p>La Plataforma tiene por objetivo poner a disposición de los Creadores de Contenido y de los Usuarios una
      plataforma web a través de la cual puedan conectar entre ellos, los primeros para publicar u ofrecer sus
      contenidos (en adelante, los "Contenidos") y/o productos físicos o digitales (en adelante, los "Productos"), y los
      segundos para acceder a los mismos mediante un procedimiento de contratación o compra a distancia.</p>

    <p>Dichos Contenidos se encontrarán disponibles en distintas modalidades de acceso:</p>

    <ul>
      <li>Contenidos gratuitos, cuyo acceso no requiere una contratación expresa por parte del Usuario, estando
        disponibles para la generalidad de Usuarios de la Plataforma.
      </li>
      <li>Contenidos por suscripción mensual, que requieren que el Usuario adquiera una suscripción al canal del Creador
        de Contenido en cuestión.
      </li>
      <li>Contenidos de pago (pay-per-view), los cuales el Usuario contrata para su acceso o visualización mediante el
        pago de un precio unitario fijado para ese Contenido concreto, ya sea este exclusivo o no.
      </li>
    </ul>

    <p>Asimismo, los Creadores de Contenido podrán habilitar en sus respectivos perfiles un apartado de "tienda", a modo
      de Marketplace, donde podrán ofrecer en venta determinados bienes (físicos o digitales) (los "Productos").</p>

    <p>La contratación de los Contenidos sujetos a pago, así como la compra de los bienes ofrecidos por cada Creador de
      Contenido, se encuentra en todo caso sometida a la aceptación de las Condiciones de Contratación.</p>
    <br>
    <h3>5. ACCESO Y REGISTRO</h3>

    <p>El acceso a la Plataforma y a los Contenidos gratuitos disponibles en la misma no requieren el registro por parte
      del Usuario anónimo, quien podrá por tanto acceder a los mismos sin necesidad de registrarse.</p>

    <p>No obstante, la interacción (en cualquier forma) con las funcionalidades de la Plataforma, con los Contenidos y/o
      con los propios Creadores de Contenido sí requieren el registro, por lo que las personas anónimas deberán
      registrarse para poder interactuar con la Plataforma más allá de la mera visualización de los Contenidos
      gratuitos.</p>

    <p>Por este motivo, aunque el mero acceso a la URL donde se aloja la Plataforma solo mostrará una página de inicio
      en la que se requerirá que la persona anónima se registre o, si ya está registrada, que inicie sesión en su
      cuenta, ya sea como Usuario o como Creador de Contenido, el Usuario anónimo también podrá acceder a través de esa
      página de inicio a las secciones "gratuitas" de la Plataforma.</p>
    <br>
    <h4>5.1. Registro como Usuario</h4>

    <p>Para el registro como Usuario se deberá cumplimentar el formulario habilitado al efecto en la Plataforma. El
      registro en la Plataforma es, salvo que se indique lo contrario, gratuito. Sin embargo, el acceso y uso de
      determinados Contenidos ofrecidos a través de la Plataforma puede requerir la contratación concreta de los mismos,
      lo cual se encontrará sujeto a las Condiciones de Contratación establecidas en la Plataforma en cada momento, que
      el Usuario (registrado) deberá aceptar de forma previa a la contratación del Contenido o Servicio en cuestión.</p>

    <p>No nos responsabilizamos de la veracidad de los datos de registro facilitados por los Usuarios, por lo que cada
      uno de ellos es responsable de las posibles consecuencias, errores y fallos que posteriormente puedan derivarse de
      la falta de calidad de los datos.</p>

    <h5>5.1.1. Requisitos para el registro</h5>

    <p>En el momento de registro, el Usuario deberá introducir todos los datos, reales y veraces, que le sean
      solicitados en el formulario de registro, ya que será la única forma que tendremos de gestionar los servicios que
      prestamos y procesar las solicitudes que el Usuario emita en el futuro a través de su cuenta.</p>

    <p>Opcionalmente, ARTEMISA podrá ofrecer el registro al Usuario por medio de conectores de redes sociales como, por
      ejemplo, Twitter. En el caso de optar por esta vía de registro, el Usuario utilizará sus credenciales de acceso a
      dicha red social para darse de alta en la Plataforma.</p>

    <p>Para poder crear una cuenta de usuario, cada interesado deberá en todo caso cumplir los siguientes
      requisitos:</p>

    <ul>
      <li>Ser persona física.</li>
      <li>Tener al menos dieciocho (18) años de edad (o la mayoría legal en su jurisdicción).</li>
      <li>Completar el proceso de registro de la cuenta.</li>
      <li>Aceptar la Política de Privacidad de la Plataforma mediante la marcación de la casilla correspondiente.</li>
      <li>Proporcionar información de contacto y facturación verdadera, completa y actualizada.</li>
    </ul>

    <p>La cuenta de usuario en la Plataforma es, en todo caso, personal e intransferible.</p>

    <p>En el caso de que un Usuario registrado en la Plataforma desease darse de alta como Creador de Contenido, podrá
      conservar sus credenciales de acceso asignadas al registrarse como Usuario, pero en todo caso deberá completar el
      proceso de alta como Creador de Contenido según se describe en el siguiente apartado 4.2.</p>
    <br>
    <h5>5.1.2. Baja como Usuario registrado</h5>

    <p>Así como el Usuario puede registrarse siguiendo las instrucciones indicadas arriba y reuniendo las condiciones
      descritas, también puede solicitar su baja de la Plataforma en cualquier momento desde el panel de ajustes de su
      perfil de Usuario.</p>

    <p>Nos reservamos el derecho a rechazar, a nuestra discreción, cualquier nuevo registro del Usuario en el caso de
      que la baja se haya producido por alguno de los casos descritos en el apartado "Reglas de uso de la
      Plataforma".</p>

    <h4>5.2. Registro como Creador de Contenido</h4>

    <h5>5.2.1. Requisitos para el registro</h5>

    <p>El alta como Creador de Contenido está reservado a personas físicas que sean mayores de edad en su jurisdicción
      (debiendo tener, en todo caso, al menos dieciocho (18) años). En todo caso, se prohíbe el acceso a la Plataforma a
      Creadores de Contenido residentes en o procedentes de cualquier territorio donde la pornografía esté prohibida, en
      particular, los siguientes territorios: Arabia Saudita, Catar, China, Emiratos Árabes, Irán, Kuwait, Omán,
      Uzbekistan y Yemen.</p>

    <p>Para darse de alta como Creador de Contenido será necesario que el interesado facilite, por medio del formulario
      habilitado al efecto, los datos personales solicitados, que incluirán en todo caso nombre, apellidos, nº de
      identidad, correo electrónico y número de teléfono, así como la documentación identificativa en vigor que acredite
      su identidad (copia de su D.N.I., pasaporte o carnet de conducir). Además, deberá superar un reconocimiento facial
      durante el proceso de alta con el fin de confirmar que la persona realizando la solicitud de registro coincide con
      la que figura en la documentación facilitada.</p>

    <p>Una vez superado lo anterior, el solicitante deberá suscribir el Contrato que le otorga la condición de Creador
      de Contenido y que establece los términos y condiciones que regirán su uso de la Plataforma y la publicación de
      sus Contenidos y Productos.</p>
    <br>
    <h5>5.2.2. Baja como Creador de Contenido</h5>

    <p>El Creador de Contenido podrá solicitar su baja de la Plataforma en cualquier momento desde el panel de ajustes
      de su perfil de Creador de Contenido.</p>

    <p>Una vez efectuada la baja, podrá solicitar una nueva alta, aunque la misma podrá ser rechazada por ARTEMISA, a
      nuestra discreción, en el caso de que la baja se hubiera producido por alguno de los casos descritos en el
      apartado "Reglas de Uso de la Plataforma" o por un incumplimiento del Contrato.</p>

    <h4>5.3. Contraseña</h4>

    <p>La contraseña asociada a la cuenta de usuario será personal e intransferible, y deberá ser generada de
      conformidad con las reglas de robustez y complejidad establecidas en la Plataforma en cada momento. Si el Usuario
      o Creador de Contenido selecciona una contraseña que no cumpla con los requisitos mínimos, será avisado y deberá
      seleccionar otra que sea conforme.</p>

    <p>Al crear su cuenta de usuario, el Usuario o Creador de Contenido se obliga a hacer un uso diligente de la misma y
      a mantener su contraseña en secreto, no transmitiéndola ni revelándola a ningún tercero, ni siquiera a ARTEMISA ni
      a cualquier proveedor.</p>

    <p>El Usuario o Creador de Contenido será responsable de la adecuada custodia y confidencialidad de cualquier cuenta
      a la que tenga acceso y de cualquier actividad que ocurra en dicha cuenta, así como del uso ilícito de la
      Plataforma por parte de cualquier tercero ilegítimo que acceda a ella utilizando su contraseña. El Usuario o
      Creador de Contenido deberá notificarnos inmediatamente de cualquier acceso o uso no autorizado de su cuenta.
      ARTEMISA no será responsable de ninguna pérdida debido a contraseñas robadas o pirateadas. No tenemos acceso a la
      contraseña de los usuarios de nuestra Plataforma y, por razones de seguridad, es posible que solo proporcionemos
      instrucciones acerca de cómo restablecer la contraseña.</p>

    <p>En cualquier caso, la Plataforma cuenta con las funcionalidades necesarias para que cualquier Usuario o Creador
      de Contenido pueda cambiar su contraseña cuando lo considere oportuno, por ejemplo, en caso de sospecha o
      constatación de que se ha producido una vulneración de la confidencialidad de la contraseña.</p>

    <p>Nos reservamos el derecho de desactivar cualquier credencial de acceso de un Usuario o Creador de Contenido, en
      cualquier momento, si en nuestra opinión razonable el Usuario o Creador de Contenido en cuestión no ha cumplido
      con alguna de las disposiciones de este Aviso Legal, de las Condiciones de Contratación o del Contrato, según
      corresponda, o de cualquier otro documento o texto legal vinculante en el ámbito de la Plataforma.</p>
    <br>
    <h3>6. REGLAS DE USO DE LA PLATAFORMA</h3>

    <p>No está permitido, y por tanto sus consecuencias serán de la exclusiva responsabilidad del Usuario o Creador de
      Contenido, según corresponda, el uso de la Plataforma con fines ilegales o no autorizados, con o sin finalidad
      económica.</p>

    <h4>6.1. Prohibiciones sobre la Plataforma</h4>

    <p>En particular, y sin que el siguiente listado tenga carácter absoluto, queda prohibido:</p>

    <ol>
      <li>Usar la Plataforma en forma alguna que pueda provocar daños, interrupciones, ineficiencias o defectos en su
        funcionamiento o en dispositivos de terceros;
      </li>
      <li>Usar la Plataforma para la transmisión, instalación o publicación de cualquier virus, troyano, gusano, bomba
        lógica, código malicioso u otros programas o archivos perjudiciales;
      </li>
      <li>Usar la Plataforma para transmitir material con fines publicitarios o de promoción, incluidos spam, correos
        electrónicos en cadena o similares;
      </li>
      <li>Usar la Plataforma de cualquier forma que constituya una vulneración de los derechos de ARTEMISA o de
        cualquier tercero;
      </li>
      <li>Usar la Plataforma para transmitir o publicar cualquier material de carácter difamatorio, ofensivo, racista,
        denigrante o de naturaleza amenazadora o que pueda molestar, perjudicar o meramente afectar a cualquier persona;
      </li>
      <li>Usar la Plataforma para recoger datos de carácter personal de otros usuarios;</li>
      <li>Usar la Plataforma de forma ilegal, en contra de la buena fe, la moral y/o el orden público;</li>
      <li>Acceder o registrarse en la Plataforma con una identidad falsa, suplantando a terceros o utilizando un perfil
        o realizando cualquier otra acción que pueda confundir a otros usuarios sobre la identidad del origen de un
        mensaje;
      </li>
      <li>Acceder sin autorización a cualquier sección de la Plataforma, a otros sistemas, redes o servidores conectados
        a la misma, o a los servicios ofrecidos a través de la Plataforma por medio de pirateo, falsificación,
        extracción de contraseñas o cualquier otro medio ilegítimo;
      </li>
      <li>Quebrantar, o intentar quebrantar, las medidas de seguridad o autenticación de la Plataforma o de cualquier
        red conectada a ésta, o las medidas de seguridad o protección inherentes a los contenidos ofrecidos en la
        Plataforma;
      </li>
      <li>Llevar a cabo cualquier acción que provoque una saturación desproporcionada o innecesaria en la
        infraestructura de la Plataforma o en nuestros sistemas o redes, o en los sistemas y redes conectados a la
        misma.
      </li>
    </ol>
    <br>
    <h4>6.2. Prohibiciones sobre los contenidos</h4>

    <p>En todo caso queda terminantemente prohibido publicar, compartir o enlazar a contenidos:</p>

    <ol>
      <li>Que puedan ser considerados como una vulneración en cualquier forma de los derechos fundamentales al honor, a
        la intimidad personal y familiar o a la propia imagen de terceros y, muy especialmente, de menores de edad o
        personas vulnerables;
      </li>
      <li>Que recojan imágenes o datos personales de terceros sin haber obtenido el oportuno consentimiento de sus
        titulares;
      </li>
      <li>Que vulneren el secreto de las comunicaciones o que supongan una infracción de derechos de propiedad
        intelectual e industrial o de las normas reguladoras de la protección de datos de carácter personal;
      </li>
      <li>Que sean ilegales, abusivos, difamatorios, denigratorios, racistas, discriminatorios (ya sea por motivos de
        raza, religión, género, nacionalidad, orientación o identidad sexual, o por cualquier otra circunstancia
        personal, física, política o cultural), engañosos, fraudulentos o de cualquier forma contrarios a la moral o al
        orden público;
      </li>
      <li>Que constituyan pornografía infantil o bestialidad, promuevan la pedofilia o cualquier rol afín a esta;</li>
      <li>Que puedan englobarse en cualquiera de las siguientes categorías: drogas o incapacitación, violación o
        brutalidad, bestialidad, lesión, mutilación o sangre, pedofilia, incesto, necrofilia. En particular, sin que el
        siguiente listado tenga carácter absoluto, se prohíben todos aquellos contenidos (audiovisuales o de cualquier
        otra naturaleza) que incluyan, mencionen, simulen o promuevan, tanto en el contenido en cuestión como en sus
        textos descriptivos, cualquiera de los siguientes comportamientos o 'keywords', en cualquiera de sus variantes:
        abuso, acoso, alcohol, asfixia, bestialidad, brutalidad, cloroformo, drogas, embriaguez, fuerza, hipnosis,
        incapacitación, incesto, inconsciencia, intoxicación, lesión, minoría de edad, mutilación, necrofilia,
        pedofilia, sedación, violación, violencia;
      </li>
      <li>Que contengan "spam" y/o enlaces a sitios sin relación con la Plataforma;</li>
      <li>Que incluyan publicidad o comunicaciones comerciales, para la emisión de mensajes con finalidad publicitaria o
        para la captación de datos con el mismo fin.
      </li>
    </ol>
    <br>
    <h4>6.3. Código de conducta</h4>

    <p>Para el uso de los servicios disponibles a través de la Plataforma, todos los Usuarios y Creadores de Contenido
      deberán cumplir las siguientes normas básicas de conducta:</p>

    <ol>
      <li>Verificar la edad legal de cualquier persona que aparezca en los contenidos que publique o enlace y obtener un
        consentimiento escrito y firmado donde se advierta de la naturaleza del contenido para adultos y que éste puede
        ser difundido mundialmente. Además, deberá conservarse copia del DNI o Pasaporte como evidencia de la edad legal
        de todos los participantes, junto con el consentimiento fechado y firmado;
      </li>
      <li>No acosar a otros Usuarios o Creadores de Contenido;</li>
      <li>No hacerse pasar por otra persona ni falsificar la información disponible en el perfil con el fin de aparentar
        ser otro Usuario o Creador de Contenido;
      </li>
      <li>No utilizar la Plataforma con el fin de tener encuentros fuera de la misma. El Usuario o Creador de Contenido
        que infrinja lo aquí dispuesto asume un grave riesgo físico y/o psicológico. ARTEMISA declina cualquier
        responsabilidad si cualquier persona decide llevar a cabo este tipo de encuentros a pesar de las advertencias y
        el riesgo inherente;
      </li>
      <li>No publicar información privada que permita a alguien identificar la localización del Usuario o Creador de
        Contenido o deducir la manera de ponerse en contacto con él o con sus familiares fuera de la Plataforma. El
        objetivo de esta medida es evitar el acoso e incluso el encuentro físico con personas malintencionadas.
      </li>
    </ol>
    <br>
    <h4>6.4. Consecuencias del incumplimiento</h4>

    <p>El Usuario o Creador de Contenido que incumpla estas prohibiciones y/o normas de conducta será responsable de
      cualquier reclamación que se produzca como consecuencia de ello. Aunque no se produjera ninguna reclamación de un
      tercero, ARTEMISA se reserva el derecho a adoptar las medidas oportunas amparadas en Derecho en ejercicio de sus
      derechos y obligaciones. Dichas medidas podrán incluir, en particular, la suspensión del acceso a la Plataforma o
      de la posibilidad de participar en los espacios habilitados en la misma por parte del Usuario o Creador de
      Contenido infractor, sin que este pueda reclamar indemnización alguna por los daños y perjuicios causados.
      Asimismo, algunas de las anteriores acciones están tipificadas como delitos en el Código Penal español, y ARTEMISA
      pondrá toda acción contraria a lo anteriormente expuesto en conocimiento de las autoridades competentes,
      cooperando con ellas revelándoles la identidad del Usuario o Creador de Contenido infractor cuando así le fuese
      requerido.</p>
    <p>ARTEMISA no puede controlar el contenido publicado, compartido o enlazado por los Usuarios y Creadores de
      Contenido en la Plataforma y no asume responsabilidad alguna por dichos contenidos. No obstante, ARTEMISA se
      reserva la posibilidad de supervisar y/o moderar cualquier contenido publicado por los Usuarios o Creadores de
      Contenido y, en caso de que éste vulnere el presente Aviso Legal o cualquiera de los textos legales de la
      Plataforma, de editarlo o eliminarlo. A tal fin, ARTEMISA podrá, a su discreción, implementar medidas técnicas o
      utilizar recursos materiales o humanos para realizar comprobaciones periódicas de los contenidos publicados en la
      Plataforma tanto por sus Usuarios como por los Creadores de Contenido, sin que ello implique una asunción de
      responsabilidad por parte de ARTEMISA más allá de la que le reconoce la propia normativa vigente.</p>
    <p>Asimismo, si un Usuario o Creador de Contenido encuentra alguna información o contenido en la Plataforma que
      pueda ser no adecuado, contrario a la normativa vigente, o contrario a las condiciones dispuestas en la
      Plataforma, rogamos que lo ponga en conocimiento inmediato de ARTEMISA a través de cualquiera de los diferentes
      medios de contacto dispuestos a tal fin.</p>
    <br>

    <h3>7. SUSPENSIÓN DEL SERVICIO</h3>
    <p>Podremos suspender en cualquier momento y sin previo aviso la disponibilidad y utilización de la Plataforma
      respecto de todos (o algunos de) los Usuarios o Creadores de Contenido, lo cual podría implicar pérdida de datos o
      de información.</p>
    <p>En particular, si un Usuario o Creador de Contenido incumple este Aviso Legal o cualquier otra disposición,
      documento o texto legal aplicable en el marco de la Plataforma, podremos suspender o restringir su uso de la
      Plataforma, e incluso podremos cancelar su usuario asignado, y dichas acciones no le darán derecho a ningún tipo
      de compensación. Asimismo, podremos informar y colaborar con las autoridades competentes si detectamos alguna
      infracción de la ley aplicable o si sospechamos que cualquier Usuario o Creador de Contenido está cometiendo algún
      delito</p>
    <p>La falta de actuación contra un Usuario o Creador de Contenido que incumpliera cualquiera de las disposiciones
      establecidas en los textos legales de la Plataforma no implicará en ningún caso una renuncia a los derechos de
      ARTEMISA con respecto a dicho incumplimiento, pudiendo hacer uso y ejercitar sus derechos y recursos en cualquier
      otra situación en la que se incumplieran este Aviso Legal o cualesquiera otras condiciones aplicables.</p>
    <br>

    <h3>8. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
    <h4>8.1. Derechos sobre la Plataforma.</h4>
    <p>ARTEMISA es titular o, en su caso, cuenta con las licencias correspondientes sobre los derechos de propiedad
      intelectual e industrial de la Plataforma, así como los correspondientes a la información, materiales, productos y
      demás contenidos de la misma, incluidos, en otros, código fuente, diseños, estructura de navegación, imágenes,
      gráficos, textos, códigos de programación, bases de datos, marcas, logotipos y cualesquiera otros signos
      distintivos y elementos protegibles que se muestren o utilicen en la Plataforma.</p>
    <p>En ningún caso se entenderá que el acceso, navegación y utilización de la Plataforma por parte del Usuario o
      Creador de Contenido implica una renuncia, transmisión, licencia o cesión total o parcial de ningún derecho de
      propiedad intelectual o industrial por nuestra parte.</p>
    <p>En este sentido, quedan reservados todos los derechos de propiedad intelectual e industrial sobre la Plataforma
      y, en particular, queda prohibido modificar, copiar, reproducir, comunicar públicamente, transformar o distribuir,
      por cualquier medio y bajo cualquier forma, la totalidad o parte de la misma y/o de sus contenidos si no se cuenta
      con la autorización previa, expresa y por escrito de ARTEMISA o, en su caso, del titular de los derechos
      correspondientes.</p>
    <p>Con base en este Aviso Legal, ARTEMISA únicamente otorga al Usuario y al Creador de Contenido una licencia
      limitada, no transferible, revocable, no sublicenciable y no exclusiva para el acceso y uso de la Plataforma en el
      dispositivo del Usuario. Cualquier derecho que no se otorgue expresamente estará reservado por parte de
      ARTEMISA</p>
    <p>El Usuario, así como cualquier tercero, tendrán restringido en particular, sin carácter limitativo, llevar a cabo
      las siguientes acciones: (i) descompilar o realizar ingeniería inversa sobre la Plataforma; (ii) enlazar, reflejar
      o enmarcar cualquier parte de la misma y/o de sus contenidos; (iii) desplegar o lanzar cualquier tipo de programa
      para realizar cualquier actuación relacionada con la prospección de datos o acciones tendentes a dañar la
      operatividad y funcionabilidad de la Plataforma; y (iv) retirar, modificar o cualquier actuación análoga tendente
      a alterar los derechos de ARTEMISA sobre su/s marca/s registrada/s, la Plataforma o sus contenidos, o los de
      cualquier tercero.</p>
    <p>Asimismo, ningún contenido de la Plataforma podrá ser modificado, copiado, distribuido, enmarcado, reproducido,
      reutilizado, descargado, extraído, mostrado, transmitido o vendido en modo alguno por ningún medio, total o
      parcialmente, sin el previo consentimiento escrito de ARTEMISA o de su legítimo titular</p>
    <p>Asimismo, queda prohibido eliminar o manipular las indicaciones de derechos de autor o cualquier otro crédito que
      identifique a los titulares de los derechos sobre los contenidos de la Plataforma, así como los dispositivos
      técnicos de protección, las impresiones digitales o cualquier mecanismo de protección o información incorporada a
      la Plataforma.</p>
    <br>
    <h4>8.2. Derechos sobre los Contenidos.</h4>
    <p>Los derechos de propiedad intelectual, industrial y de imagen sobre cualesquiera contenidos que sean publicados
      por los Creadores de Contenido (contenidos audiovisuales y fotografías, textos o publicaciones que realicen) o por
      los Usuarios de la Plataforma (por ejemplo, comentarios) serán titularidad de éstos, según corresponda. Sobre
      dichos contenidos únicamente otorgan a ARTEMISA, tanto los Creadores de Contenido como los Usuarios, una licencia
      para su correcta publicación y explotación en la Plataforma, así como para su explotación comercial con fines
      promocionales de la Plataforma en el ámbito de Internet, concretamente en sitios web de ARTEMISA3000 TECH
      SOLUTIONS, S.L. y/o en sitios web de terceros alojados en servidores de esta. Dicha licencia tendrá carácter
      mundial y quedará limitada al tiempo que el Creador de Contenido o Usuario esté registrado en la Plataforma y/o
      hasta que el contenido en cuestión sea eliminado, ya sea por el propio Creador de Contenido o Usuario titular del
      mismo, o por ARTEMISA como consecuencia de cualquier infracción de derechos, de los términos aplicables a la
      Plataforma y/o de la legislación vigente. En el caso de los Contenidos de pago ‘pay-per-view’ o de Contenidos
      vendidos mediante el canal de mensajería de la Plataforma, los mismos se asignarán al Usuario que los hubiera
      adquirido y continuarán estando disponibles para este durante todo el tiempo que continúe registrado en la
      Plataforma, por lo que la cesión de derechos del Creador de Contenido se realiza, en estos casos, a perpetuidad.
      Sin perjuicio de ello, la disponibilidad de tales Contenidos se encontrará limitada a aquellos Usuarios que
      expresamente hubieran adquirido los mismos por el cauce habilitado al efecto y, en ningún caso, podrán ser
      utilizados, accedidos o visionados por la generalidad de Usuarios de la Plataforma.</p>
    <p>ARTEMISA no tiene intención de utilizar los contenidos generados por los Creadores de Contenido y por sus
      Usuarios para fines distintos de la efectiva prestación del servicio y de la promoción de la Plataforma. En este
      sentido, el Creador de Contenido y cada Usuario reconocen y aceptan que ARTEMISA podrá utilizar, total o
      parcialmente, (i) los Contenidos gratuitos, (ii) los comentarios de los Usuarios, y (iii) las capturas y
      comentarios de los Contenidos por suscripción o de pago, para fines promocionales de la Plataforma, desarrollados
      por sí misma o por terceros conforme indicado en el párrafo anterior</p>
    <p>A tal fin, y sin carácter limitativo, la antedicha licencia se realizará expresamente para el alojamiento de los
      contenidos en los servidores de ARTEMISA (o del tercero que esta contrate a estos efectos), así como para su
      puesta a disposición en la Plataforma conforme a su tipología y a la modalidad de acceso que se hubiera
      configurado para el contenido en cuestión.</p>
    <p>A su vez, cada Usuario o Creador de Contenido obtendrá un derecho limitado para la visualización de los
      contenidos en el ámbito de la Plataforma, otorgado por el titular de los mismos, durante el tiempo que estén
      disponibles en la misma, sin que por el acceso a la Plataforma se derive derecho alguno sobre los mismos más allá
      de lo expresamente contemplado en este párrafo. En particular, se prohíbe la copia, descarga o cualquier otra
      explotación de los contenidos distinta de su visualización en el marco de la Plataforma.</p>
    <p>Cada Creador de Contenido o Usuario reconoce y garantiza ser titular de todos los derechos sobre los contenidos
      que publique en la Plataforma, y que los mismos son plenamente conformes con el ordenamiento jurídico y no
      infringen ni menoscaban los derechos de terceros, incluidos los de imagen, ni supondrá ningún coste y/o gasto
      adicional para ARTEMISA, estando el Usuario o Creador de Contenido en cuestión capacitado para disponer de los
      mismos libremente. Asimismo, garantizan que los contenidos que publiquen no tienen carácter confidencial ni son
      perjudiciales para terceros.</p>
    <p>Cada Creador de Contenido o Usuario reconoce asumir la responsabilidad, dejando indemne a ARTEMISA por cualquier
      comunicación que suministre personalmente o a su nombre, alcanzando dicha responsabilidad sin restricción alguna
      la exactitud, legalidad, originalidad y titularidad de la misma.</p>
    <br>
    <h4>8.3. Notificación de infracciones.</h4>
    <p>Si el Usuario sospecha o es informado de la existencia de cualquier contenido ilícito o ilegal, incluyendo
      contenido que pudiera infringir derechos de propiedad intelectual de terceros, deberá notificarlo a ARTEMISA a
      través de la siguiente dirección de correo electrónico para poder tomar las medidas apropiadas:<a
        :href="`mailto:${project.locales.contact_email}`" target="_blank">{{ project.locales.contact_email }}</a></p>
    <p>Del mismo modo, si el Usuario considera que la Plataforma infringe sus propios derechos de propiedad intelectual,
      o cualquier otro derecho, deberá enviar un correo electrónico a ARTEMISA a la dirección indicada anteriormente,
      con la siguiente información:</p>
    <ul>
      <li>identificación del usuario y, en caso de persona jurídica, de su representante, incluida la información de
        contacto;
      </li>
      <li>documentación relevante que respalde la petición, identificando al titular de los derechos infringidos;</li>
      <li>relación detallada de los derechos que supuestamente han sido infringidos por ARTEMISA o por cualquiera de los
        usuarios, así como su ubicación exacta dentro de la Plataforma;
      </li>
      <li>declaración expresa y responsable de que el contenido ha sido utilizado sin el consentimiento de su titular.
      </li>
    </ul>
    <br>
    <h4>9. ENLACES</h4>
    <p>Queda prohibido establecer enlaces a la Plataforma desde otras páginas web o canales digitales que contengan
      materiales, información o contenidos ilícitos, ilegales, degradantes, obscenos y, en general, que contravengan las
      leyes, la moral o al orden público, o las normas sociales generalmente aceptadas.</p>
    <p>En ARTEMISA no tenemos la facultad ni medios humanos y técnicos para conocer, controlar ni aprobar toda la
      información, contenidos, productos o servicios facilitados por otros sitios web que tengan establecidos enlaces
      con destino a la Plataforma</p>
    <p>Por tanto, no asumimos ningún tipo de responsabilidad por cualquier aspecto relativo a las páginas web que
      establezcan ese enlace con destino a la Plataforma; en concreto, a título enunciativo y no limitativo, sobre su
      funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus
      propios enlaces y/o cualquiera de sus contenidos en general.</p>
    <p>En cualquier caso, desde ARTEMISA nos reservamos el derecho a prohibir los enlaces a la Plataforma y a exigir su
      retirada cuando estos no cumplan las condiciones exigidas en este apartado.</p>
    <br>
    <h3>10. RESPONSABILIDADES Y GARANTÍAS</h3>
    <p>El usuario se compromete a cumplir con sus obligaciones legales y contractuales generadas en virtud de este
      documento y responderá de las infracciones en que hubiese incurrido personalmente, manteniendo a ARTEMISA indemne
      frente a cualquier error, culpa o negligencia que no le fuera imputable, así como de los daños y perjuicios que se
      derivasen de dichas infracciones o errores.</p>
    <p>ARTEMISA no puede garantizar la fiabilidad, utilidad, actualidad o veracidad de absolutamente toda la información
      y/o de los servicios de la Plataforma, especialmente aquellos que son provistos o publicados por terceros.</p>
    <p>En consecuencia, ARTEMISA no garantiza ni se hace responsable de:</p>
    <ol>
      <li>la veracidad y continuidad de los contenidos de la Plataforma;</li>
      <li>la ausencia de errores en dichos contenidos;</li>
      <li>la ausencia de virus, troyanos, gusanos, bombas lógicas y/o demás componentes maliciosos o tecnológicamente
        dañinos en la Plataforma o en el servidor que lo suministra;
      </li>
      <li>los daños producidos en los equipos informáticos de los usuarios o de terceros durante la prestación del
        servicio de la Plataforma;
      </li>
      <li>la invulnerabilidad de la Plataforma y/o la imposibilidad de vulnerar las medidas de seguridad que se adopten
        en ella;
      </li>
      <li>la falta de utilidad o rendimiento de los contenidos de la Plataforma;</li>
      <li>los fallos de la Plataforma producidos por cualquier tipo de ataque a sus servidores o a los de terceros
        proveedores de la misma, así como los fallos o desconexiones en las redes de telecomunicaciones que produzcan la
        suspensión, cancelación o interrupción del servicio durante la prestación del mismo o con carácter previo;
      </li>
      <li>cualquier fallo técnico de cualquier tipo que dificulte, retrase o impida el correcto funcionamiento de la
        Plataforma;
      </li>
      <li>los daños o perjuicios que cause, a sí mismo o a un tercero, cualquier persona que infringiera las
        condiciones, normas e instrucciones que ARTEMISA establece en relación con la Plataforma o a través de la
        vulneración de sus sistemas de seguridad.
      </li>
    </ol>
    <p>No obstante, desde ARTEMISA hemos adoptado todas las medidas necesarias, dentro de nuestras posibilidades y del
      estado de la técnica, para garantizar el funcionamiento de la Plataforma y reducir al mínimo los errores del
      sistema, tanto desde el punto de vista técnico, como de los contenidos publicados por nosotros en la
      Plataforma.</p>
    <p>En este sentido, desde ARTEMISA no puede garantizar la fiabilidad, licitud y utilidad de los contenidos
      proporcionados por terceros a través de la Plataforma, y en consecuencia no será responsable de los mismos. Esto
      también se aplica a la información contenida en otras plataformas o sitios web que estén enlazados desde la
      Plataforma. No nos hacemos responsables de los daños que pudieran derivarse del uso de dicha información. Si el
      usuario tiene conocimiento de la existencia o publicación de algún dato inexacto, desactualizado y falso, o de la
      existencia de algún contenido ilegal, ilícito, contrario a las leyes o que pudiera suponer una infracción de los
      derechos de terceros (incluyendo, sin limitación, derechos de propiedad intelectual e industrial, la normativa
      aplicable en materia de protección de datos personales y/o de cualquier otro derecho), deberá notificarlo
      inmediatamente a ARTEMISA por las vías de contacto habilitadas en la Plataforma para que podamos tomar las medidas
      oportunas.</p>
    <br>
    <h4>11. ATENCIÓN AL USUARIO</h4>
    <p>Para cualquier consulta, queja o sugerencia, ponemos a disposición de los Usuarios de la Plataforma nuestro
      servicio de atención al usuario, disponible a través de las siguientes vías de contacto:</p>
    <ul>
      <li>Teléfono: +34 91 041 68 23</li>
      <li>E-mail: <a :href="`mailto:${project.locales.contact_email}`"
                     target="_blank">{{ project.locales.contact_email }}</a></li>
    </ul>
    <p>La atención telefónica se encuentra limitada al siguiente horario:</p>
    <ul>
      <li>L-V: 9:00 - 18:00 CET</li>
    </ul>
    <p>Haremos nuestros mejores esfuerzos para dar respuesta a las reclamaciones o consultas recibidas en el plazo más
      breve posible.</p>
    <h3>12. CONFIDENCIALIDAD Y PROTECCIÓN DE DATOS</h3>
    <p>De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de
      abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos
      personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE, y la Ley Orgánica
      3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, todos los datos
      de carácter personal que el usuario facilite durante su uso de la Plataforma serán tratados de conformidad con lo
      dispuesto en la <a href="/privacy" target="_blank">Política de Privacidad</a>, que el usuario deberá leer y, en su
      caso, aceptar.</p>
    <br>
    <h3>13. MISCELÁNEA</h3>
    <p>Los encabezamientos de las distintas cláusulas son meramente informativos y no afectan, califican ni fomentan la
      interpretación del Aviso Legal.</p>
    <p>Todas las cláusulas o extremos del presente Aviso Legal deben ser interpretadas de forma independiente, no
      viéndose afectadas el resto de las cláusulas en caso de que una de ellas haya sido declarada nula por sentencia
      judicial o resolución arbitral firme. Se sustituirá la cláusula o cláusulas afectadas por otra u otras que
      preserven los efectos perseguidos por este Aviso Legal.</p>
    <p>El usuario reconoce y acepta que podemos modificar los términos y condiciones aquí establecidos, en todo o en
      parte, en cualquier momento. Los cambios se publicarán en la Plataforma de forma similar a como está disponible
      este Aviso Legal. Si el usuario incumple las condiciones de uso aplicables a la Plataforma, nos reservamos el
      derecho de suspender orestringir su uso de la Plataforma, e incluso cancelar su cuenta de usuario sin previo
      aviso, y dichas acciones no darán derecho al usuario a ningún tipo de compensación por nuestra parte o por parte
      de nuestros proveedores.</p>
    <br>
    <h3>14. LEY APLICABLE Y JURISDICCIÓN COMPETENTE</h3>
    <p>Este Aviso Legal, la Plataforma y el uso que los usuarios hagan de la misma se rigen por la legislación española
      vigente en cada momento</p>
    <p>No obstante, en caso de controversia, acción o reclamación entre nuestros usuarios y nosotros, será la normativa
      vigente la que determinará las leyes que deban regir y la jurisdicción que deba conocer de dichas relaciones. En
      este sentido, siempre que dicha normativa prevea la posibilidad de someternos a un fuero determinado, para toda
      cuestión litigiosa, controversia, acción o reclamación relacionada con los servicios que prestamos a través de la
      Plataforma, será de aplicación la legislación española vigente en el momento del litigio, y conocerán de dicha
      cuestión los Juzgados y Tribunales de la ciudad de Madrid, con renuncia expresa y voluntaria a cualquier otro
      fuero, salvo disposición legal imperativa en contrario.</p>
    <p>Para presentar reclamaciones en relación con el uso de nuestra Plataforma, el usuario puede dirigirse por correo
      a la dirección electrónica o física indicada al inicio. Nos comprometemos a buscar en todo momento una solución
      amistosa en caso de conflicto.</p>
  </section>
</template>

<script>
import project from "@/project";

export default {
  name: "ES",
  data() {
    return {
      project
    }
  }
}
</script>

<style scoped>

</style>
