<template>
  <section class="audio-play center rows" :tabindex="1" @blur="stopAudio">
    <div v-show="playing" :class="classAudio">
      <IconButon :size="sizeIcon" type="stop" @handler="stopAudio" />
    </div>
    <div v-show="!playing" :class="classAudio">
      <IconButon :size="sizeIcon" type="audio" @handler="playAudio" />
    </div>
    <div v-if="iconBottom" class="icon-play">
      <IconButon :size="13" type="play" @handler="playAudioBootom" />
    </div>
  </section>
</template>

<script>
import IconButon from "@/components/chat/component/IconButton.vue";
export default {
  name: "AudioPlay",
  components: {
    IconButon
  },
  props: {
    src: { type: String },
    disabled: { type: Boolean, default: false },
    iconBottom: { type: Boolean, default: false },
    sizeIcon: { type: Number, default: 35 }
  },
  data() {
    return {
      audio: null,
      playing: false
    };
  },
  computed: {
    classAudio() {
      return {
        "center-audio": this.iconBottom
      };
    }
  },
  mounted() {
    this.audio = new Audio(this.src);
  },
  methods: {
    playAudio() {
      if (this.disabled) return;
      if (this.iconBottom) {
        return;
      }
      this.playing = true;
      this.audio.play();
    },
    stopAudio() {
      if (this.disabled) return;
      this.playing = false;
      this.audio.pause();
      this.audio.currentTime = 0;
    },
    playAudioBootom() {
      if (this.disabled) return;
      this.playing = true;
      this.audio.play();
    }
  }
};
</script>

<style lang="scss" scoped>
.audio-play {
  border: 1px solid gray;
  border-radius: 10px;
  background-color: #dfe3e9;
}
.center-audio {
  position: relative;
  left: 13px;
}
.icon-play {
  position: relative;
  top: 30px;
  left: 7px;
}
</style>
