var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"d-flex"},[_c('UserAvatar',{attrs:{"vertical":"","x-large":"","profile":"","user":_vm.user},nativeOn:{"click":function($event){return _vm.enableCarousel($event)}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('div',{staticClass:"title-text-2 bold white--text mx-2 center",attrs:{"slot":"name"},slot:"name"},[_vm._v(" "+_vm._s(_vm.user.displayName)+" ")]),(_vm.user.trustedProfile)?_c('img',{attrs:{"src":"/images/check5.svg","height":"16"}}):_vm._e(),_c('div',{staticClass:"regular-text white-light-text mx-2",attrs:{"slot":"username"},slot:"username"},[_vm._v(" "+_vm._s(("@" + (_vm.user.username)))+" ")])]),_c('v-row',{attrs:{"align":"center"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('c-btn',_vm._g(_vm._b({staticClass:"btnShared",attrs:{"icon":"","color":"white"}},'c-btn',attrs,false),on),[_c('img',{staticClass:"ml-auto",attrs:{"height":"40","src":((_vm.showAffilateLink
                        ? '/images/icon_shared_profile_cash.svg'
                        : '/images/icon_shared_profile.svg') + " ")}}),_c('div',{staticClass:"tooltip"},[_c('span',[_vm._v(_vm._s(_vm.$t("share.profile")))])])])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.itemsShared),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item',{on:{"click":item.click}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.title))]),_c('img',{staticClass:"ml-auto",attrs:{"height":"40","src":item.iconSrc}})])],1)}),1)],1),((!_vm.isCurrentUser && _vm.authenticated) || !_vm.edittingMode)?_c('v-btn',{attrs:{"icon":"","href":_vm.chatHrefIcon,"to":_vm.chatRoute,"target":_vm.redirect ? '_blank' : ''}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mail_outline")])],1):_vm._e(),(_vm.showBlock)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.swithcBlocked}},[_c('v-icon',{attrs:{"fill":_vm.blockUserColor}},[_vm._v("$vuetify.icons.block_user")]),_c('BlockUserWarningDialog',{attrs:{"user":_vm.user},model:{value:(_vm.showBlockDialog),callback:function ($$v) {_vm.showBlockDialog=$$v},expression:"showBlockDialog"}})],1):_vm._e(),((!_vm.isCurrentUser && _vm.authenticated) || !_vm.edittingMode)?_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('c-btn',_vm._g(_vm._b({staticClass:"my-auto",attrs:{"icon":"","color":"white"}},'c-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,false,2567285357)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":_vm.chatHref}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("users.profile.info.message")))])]),(_vm.notMasterUser)?_c('v-list-item',{on:{"click":_vm.toggleFollow}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.followingText)+" ")])]):_vm._e(),_c('v-list-item',{on:{"click":_vm.copyLink}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("users.profile.info.copy")))])])],1)],1):_vm._e()],1)],1),(_vm.showInfo)?_c('div',{staticClass:"row star-info"},[(_vm.isCurrentUser)?_c('div',{staticClass:"py-2 px-2"},[_vm._v(" "+_vm._s(_vm.followingsNumber)+" ")]):_vm._e(),_c('div',{staticClass:"py-2 px-2"},[_vm._v(" "+_vm._s(_vm.followersNumber)+" ")]),_c('v-chip',{staticClass:"py-2 px-2",attrs:{"color":"translucient"}},[_vm._v(" "+_vm._s(_vm.subscribersNumber)+" ")])],1):_vm._e(),(_vm.isMobile)?_c('v-col',{staticClass:"user-description",attrs:{"cols":"12","sm":""}},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('span',_vm._g({staticClass:"truncate two-lines",on:{"click":function($event){_vm.show = !_vm.show}}},on),[_vm._v(" "+_vm._s(_vm.user.description)+" ")])]}}],null,false,4168844652),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('span',[_vm._v(_vm._s(_vm.user.description))])])],1)]):_vm._e()],1)]),(!_vm.isMobile)?_c('v-col',{staticClass:"user-description",attrs:{"cols":"12","sm":""}},[_c('div',{staticClass:"truncate five-lines"},[_vm._v(" "+_vm._s(_vm.user.description)+" ")])]):_vm._e(),_c('Carousel',{attrs:{"resources":_vm.resources},model:{value:(_vm.showCarousel),callback:function ($$v) {_vm.showCarousel=$$v},expression:"showCarousel"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }