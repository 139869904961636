<template>
  <c-dialog persistent
    :key="dialogKey"
    width="684"
    :actions="false"
    v-bind:value="value"
    @input="close"
    @close="close">
    <template slot="title">
      <h1>{{ $t('chat_message.new_message') }}</h1>
    </template>
    <MultimediaMessageForm
      :attached="attached"
      :broadcast="broadcast"
      @submit="close"
      @close="close"
    />
  </c-dialog>
</template>
<script>
import MultimediaMessageForm from './MultimediaMessageForm'
import messages from '../locales/all.json'

export default {
  name: 'MultimediaMessageDialog',
  components: { MultimediaMessageForm },
  i18n: { messages },
  props: {
    value: Boolean,
    attached: { type: String, default: null },
    broadcast: Boolean
  },

  data () {
    return {
      dialogKey: new Date().getTime()
    }
  },

  methods: {
    close () {
      this.dialogKey = new Date().getTime()
      this.$emit('input', false)
    }
  }
}
</script>
