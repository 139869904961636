var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"accountForm",attrs:{"dark":false},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-col',{staticStyle:{"margin":"auto"},attrs:{"cols":_vm.isMobile ? 12 : 9}},[_c('v-row',{attrs:{"justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":"12"}},[_c('v-checkbox',{attrs:{"dark":_vm.isDark,"label":_vm.$t('models.user.payment_person.desc')},model:{value:(_vm.samePersonalData),callback:function ($$v) {_vm.samePersonalData=$$v},expression:"samePersonalData"}})],1),(!_vm.samePersonalData)?_c('v-col',{style:({
          backgroundColor: _vm.isDark
            ? '#333333 !important'
            : '#F2F2F2 !important'
        }),attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"12","justify":"center"}},[_c('v-row',{staticStyle:{"color":"#FFFFFF !important"},attrs:{"justify":"center","dense":""}},[_c('p',{staticClass:"campo-label",style:({
                color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
              }),domProps:{"innerHTML":_vm._s(_vm.$t('models.user.payment_person.info'))}}),_c('v-col',{style:(_vm.paddingRight),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 6}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.user.name'))+" ")]),_c('v-text-field',{attrs:{"outlined":"","persistent-hint":"","dense":"","dark":_vm.isDark,"placeholder":_vm.$t('models.user.name'),"rules":[_vm.$vRequired]},model:{value:(_vm.managerName),callback:function ($$v) {_vm.managerName=$$v},expression:"managerName"}})],1),_c('v-col',{style:(_vm.paddingLeft),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 6}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.user.surname'))+" ")]),_c('v-text-field',{attrs:{"outlined":"","persistent-hint":"","dense":"","dark":_vm.isDark,"placeholder":_vm.$t('models.user.surname')},model:{value:(_vm.managerSurname),callback:function ($$v) {_vm.managerSurname=$$v},expression:"managerSurname"}})],1),_c('v-col',{style:(_vm.paddingRight),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 6}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.influencer_request.id_document_type'))+" ")]),_c('v-select',{attrs:{"outlined":"","dense":"","placeholder":_vm.$t('models.influencer_request.id_document_type'),"dark":_vm.isDark,"items":_vm.documentTypes,"rules":[_vm.$vRequired]},model:{value:(_vm.managerDocumentType),callback:function ($$v) {_vm.managerDocumentType=$$v},expression:"managerDocumentType"}})],1),_c('v-col',{style:(_vm.paddingLeft),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 6}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.influencer_request.id_document_number'))+" ")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","persistent-hint":"","dark":_vm.isDark,"placeholder":_vm.$t('models.influencer_request.id_document_number'),"rules":[_vm.$vRequired ]},model:{value:(_vm.managerDocumentId),callback:function ($$v) {_vm.managerDocumentId=$$v},expression:"managerDocumentId"}})],1),_c('v-col',{style:(_vm.paddingRight),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 4}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.user.day'))+" ")]),_c('v-text-field',{attrs:{"outlined":"","persistent-hint":"","dense":"","dark":_vm.isDark,"placeholder":_vm.$t('models.user.day'),"type":"number","rules":[_vm.$vMin(1), _vm.$vMax(31)]},model:{value:(_vm.managerDay),callback:function ($$v) {_vm.managerDay=$$v},expression:"managerDay"}})],1),_c('v-col',{style:(_vm.paddingRight),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 4}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.user.month'))+" ")]),_c('v-text-field',{attrs:{"outlined":"","persistent-hint":"","dense":"","dark":_vm.isDark,"placeholder":_vm.$t('models.user.month'),"type":"number","rules":[_vm.$vMin(1), _vm.$vMax(12)]},model:{value:(_vm.managerMonth),callback:function ($$v) {_vm.managerMonth=$$v},expression:"managerMonth"}})],1),_c('v-col',{style:(_vm.paddingLeft),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 4}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.user.year'))+" ")]),_c('v-text-field',{attrs:{"outlined":"","persistent-hint":"","dense":"","dark":_vm.isDark,"placeholder":_vm.$t('models.user.year'),"type":"number","rules":[_vm.$vMin(1800), _vm.$vMax(_vm.getCurrentYear())]},model:{value:(_vm.managerYear),callback:function ($$v) {_vm.managerYear=$$v},expression:"managerYear"}})],1),_c('v-col',{style:(_vm.paddingRight),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 6}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.user.country'))+" ")]),_c('v-autocomplete',{attrs:{"outlined":"","persistent-hint":"","dense":"","dark":_vm.isDark,"placeholder":_vm.$t('models.user.country'),"items":_vm.countries,"item-text":"text","item-value":"value","rules":[_vm.$vRequired]},model:{value:(_vm.managerCountry),callback:function ($$v) {_vm.managerCountry=$$v},expression:"managerCountry"}})],1),_c('v-col',{style:(_vm.paddingLeft),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 6}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.user.city'))+" ")]),_c('v-text-field',{attrs:{"outlined":"","persistent-hint":"","dense":"","dark":_vm.isDark,"placeholder":_vm.$t('models.user.city')},model:{value:(_vm.managerCity),callback:function ($$v) {_vm.managerCity=$$v},expression:"managerCity"}})],1),_c('v-col',{style:(_vm.paddingRight),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 6}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.user.province'))+" ")]),_c('v-text-field',{attrs:{"outlined":"","persistent-hint":"","dense":"","dark":_vm.isDark,"placeholder":_vm.$t('models.user.province')},model:{value:(_vm.managerProvince),callback:function ($$v) {_vm.managerProvince=$$v},expression:"managerProvince"}})],1),_c('v-col',{style:(_vm.paddingLeft),attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":_vm.isMobile ? 12 : 6}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.user.postal_code'))+" ")]),_c('v-text-field',{attrs:{"outlined":"","persistent-hint":"","dense":"","dark":_vm.isDark,"placeholder":_vm.$t('models.user.postal_code')},model:{value:(_vm.managerPostalCode),callback:function ($$v) {_vm.managerPostalCode=$$v},expression:"managerPostalCode"}})],1),_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 11,"sm":"12"}},[_c('div',{staticClass:"campo-label",style:({
                  color: _vm.isDark ? '#FFFFFF !important' : '#000000 !important'
                })},[_vm._v(" "+_vm._s(_vm.$t('models.user.address'))+" ")]),_c('v-text-field',{attrs:{"outlined":"","persistent-hint":"","dense":"","dark":_vm.isDark,"placeholder":_vm.$t('models.user.address')},model:{value:(_vm.managerAddress),callback:function ($$v) {_vm.managerAddress=$$v},expression:"managerAddress"}})],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }