<template>
  <!-- USER WITH CARD -->
  <!--
  <section class="user-card-main">
    <div class="content-avatar-offer mx-auto">
      <div
        class="avatar-offer"
        :style="`background-image: url(${user.avatarS3Route})`"
      >
        <span class="online_card" v-if="onlineStatus"></span>
      </div>
    </div>
    <div class="user-card-background">
      <div
        class="user-card-background-wrapper"
        :style="
          `background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.3), #000000), url(${user.bannerS3Route})`
        "
      >
        <div class="white--text center position-relative">
          {{ subString(user.displayName, 10) }}
          <img
            v-if="user.trustedProfile"
            src="/images/check5.svg"
            height="15"
          />
        </div>

        <div class="white-light-text center position-relative">
          {{ `@${subString(user.username, 14)}` }}
        </div>
        <div class=" position-relative" style="width: 100%; margin-top:10%">
          <span class="price mt-1 center" v-html="subMonth"></span>
        </div>
        <div class=" position-relative center towallet mt-1 mb-1">
          <span class="" v-html="toWallet"> </span>
        </div>
        <div class="center position-relative content-buttom">
          <button class="button" @click="goProfile">
            <span class="text-button">{{ $t("landing.profile") }}</span>
          </button>
        </div>
      </div>
    </div>
  </section> -->
  <section class="card ">
    <div class="col-12 goTo" style="margin: auto; position: relative;width: fit-content;">
      <v-img @click="goProfile" :src="user.avatarS3Route" :height="mobileSize" :width="mobileSize" cover style="border-radius: 100%; border: 2px solid #FFFFFF;opacity: 1;;margin: auto"></v-img>
      <span class="online_card-no-card" v-if="onlineStatus" ></span>
    </div>
    <div class="row center">
      <div class="user-card-background-wrapper" style="width: -webkit-fill-available;">
        <div class="white--text center position-relative goTo">
          {{ subString(user.displayName, 18) }}
          <img
            v-if="user.trustedProfile"
            src="/images/check5.svg"
            height="15"
          />
        </div>

        <div class="white-light-text center position-relative goTo" style="font-weight: 400;">
          {{ `@${subString(user.username, 24)}` }}
        </div>
        <div class="position-relative-no-card goTo">
          <span class="mt-1 center" style="color: #FFFFFF; font-size: 12px;" v-html="subMonth"></span>
          <span class="white mt-1 center bonus" @click="goProfile">
            <span style="white-space: nowrap;font-family: Montserrat-Bold, sans-serif; font-size: 12px !important;"><b>+{{$currency(this.cashReturn)}}</b></span>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import UserAvatar from '@/components/users/UserAvatar'
import PaymentBtn from '@/components/payments/PaymentButton'
import FollowingBtn from '@/components/users/FollowingButton'
import Discount from './Discount'
import messages from '@/views/landing/specialCampaign/locales/all.json'

export default {
  name: 'UserCardCampaing',
  components: { UserAvatar, PaymentBtn, FollowingBtn, Discount },
  i18n: { messages },
  props: {
    user: Object,
    noCard: Boolean
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    ...mapState('application', ['isMobile']),
    isInfluencer () {
      return this.user.role === 'influencer'
    },
    toWallet () {
      return this.$t('landing.toWallet', {
        price: this.$currency(this.cashReturn)
      })
    },
    subMonth () {
      return `<b>${this.$currency(this.priceSubcriptions)}</b>/${this.$t(
        'words.month'
      )}`
    },
    onlineStatus () {
      return this.user.onlineStatus === 'online'
    },
    isSubscribable () {
      return this.user.subscribable
    },
    xLarge () {
      return !this.isMobile ? true : undefined
    },
    discount () {
      return this.monthSubscription.discount
    },
    monthSubscription () {
      return this.user.subscriptionOffers.find(s => s.type === 'month')
    },
    priceSubcriptions () {
      return this.monthSubscription.basePrice
    },
    cashReturn () {
      return (this.monthSubscription.basePrice * 25) / 100
    },
    mobileSize () {
      return this.isMobile ? 150 : 180
    }
  },
  methods: {
    goProfile () {
      console.log(`/${this.user.username}`)
      this.$router.push(`/${this.user.username}`)
    },
    subString (string, length = 14) {
      return string.length >= length
        ? `${string.substr(0, length)}...`
        : string
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import "../_styles"; */
@use 'src/scss/colors';
.user-no-card-main {
  height: 300px;
  margin: 5px;
  align-items: end;
  position: relative;
  .content-avatar-offer {
    position: absolute;
    display: flex;
    z-index: 2;
    /* border: solid 1px white; */
    align-items: center;
    width: -webkit-fill-available;
    width: -moz-available;
    .avatar-offer {
      border: 2px solid #ffffff;
      border-radius: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 180px;
      height: 180px;
      position: relative;
      bottom: 192px;
      margin: auto;
    }
  }
}
.user-card-main {
  height: 354px;
  margin: 5px;
  display: grid;
  align-items: end;
  position: relative;
  .user-card-background {
    height: 310px;
    height: 80%;
    box-shadow: 0px 8px 12px #0000008f;
    border-radius: 10px;
    border: 2px solid var(--unnamed-color-ffffff);
    background: transparent linear-gradient(180deg, #fcee21 0%, #009245 100%) 0%
      0% no-repeat padding-box;
    .user-card-background-wrapper {
      background-size: cover;
      background-position: center;
      margin: 2.5%;
      height: 95%;
      width: 95%;
      border-radius: 10px;
      border: 2px solid var(--unnamed-color-ffffff);
    }
  }
}
.content-avatar-offer {
  position: absolute;
  display: flex;
  z-index: 2;
  /* border: solid 1px white; */
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
  .avatar-offer {
    border: 2px solid #ffffff;
    border-radius: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 128px;
    height: 128px;
    position: relative;
    bottom: 192px;
    margin: auto;
  }
}

.content-buttom {
  width: 100%;
  margin-top: 3%;
}
.user-card {
  min-width: 100px;
  margin-top: 32px !important;
  height: 215px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  margin: 10px;
  max-width: 95%;
  width: 95%;

  &.expand {
    max-width: 95% !important;
    width: 95% !important;
  }
  .user-card-background {
    position: absolute;
    top: 48px !important;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    //background-image: linear-gradient(to bottom, #fcee21 0%, #009245 100%) 1;
  }

  .user-card-wrapper {
    max-width: 100%;
    width: 100%;
    height: 272px !important;
    padding: 12px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-width: 8px;
    border-style: solid;
    border-radius: 10px !important;
    border-color: white;
    //background: linear-gradient(to bottom, #fcee21 0%, #009245 100%) 1;
    border-image: linear-gradient(to bottom, #fcee21 0%, #009245 100%) 1;
    justify-content: space-between;
    z-index: 0;
    .user-card-actions {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      /* > * {
        margin-top: 5px;
      } */
      button {
        cursor: pointer;
        padding: 4px !important;
      }
    }
  }
}
.online_card {
  width: 12px;
  height: 12px;
  z-index: 2;
  position: absolute;
  border-radius: 14px;
  background: greenyellow;
  left: 16px;
    top: 8px;
}
.online_card-no-card {
  width: 12px !important;
  height: 12px !important;
  z-index: 2;
  position: absolute;
  border-radius: 14px;
  background: greenyellow;
  left: 30px;
  top: 30px;
}

.position-relative {
  position: relative;
  top: 32%;
}
.position-relative-no-card {
  position: relative;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 20px;
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.price {
  height: 32px;
  color: #ffffff;
  width: 100% !important;
  background: transparent
    linear-gradient(90deg, #b4071b 0%, #ff8496 52%, #e0001a 100%) 0% 0%
    no-repeat;
  color: var(--unnamed-color-ffffff);
  font: 22px var(--unnamed-font-family-montserrat);
  text-align: center;
}
.rank {
  min-height: fit-content;
  div {
    color: white;
    vertical-align: center;
    margin: auto;
  }
}
.button {
  width: 152px;
  height: 32px;
  z-index: 3;
  border-radius: 16px;
  background: var(--unnamed-color-ffffff);
  border: 1px solid var(--unnamed-color-ffffff);
  .text-button {
    font: 16px/26px var(--unnamed-font-family-montserrat);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: center;
  }
  .text-button:hover {
    color: white;
  }
}
.towallet {
  font: 14px/26px var(--unnamed-font-family-montserrat);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  padding: 0 2px;
}
.banda {
  font: 22px/46px var(--unnamed-font-family-montserrat);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
/* .user-card-background-base{
  border: solid blue;
} */
@media (max-width: 700px) {
  .price {
    font: 18px/46px var(--unnamed-font-family-montserrat);
  }
  .user-card-wrapper {
    font-size: 12px !important;
  }
  .content-avatar-offer {
    .avatar-offer {
      bottom: 202px;
      width: 110px;
      height: 110px;
    }
  }

  .button {
    width: 133px;
    height: 32px;
  }
  .towallet {
    font: 11px/21px var(--unnamed-font-family-montserrat);
  }
  .online_card {
    top: 8px;
    left: 8px;
  }
  .content-buttom {
    margin-top: 10%;
  }
}

.bonus {
  color: #31AE00;
  font-size: 9px;
  font-family: Montserrat-Bold;
  text-align: center;
  border-radius: 11px;
  width: 56px;
  margin-left: 20px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.card {
  min-width: 197px;
}

.goTo:hover {
  cursor: pointer;
}
</style>
