'user strict'

import api from '@/api/Api'

export default {

    timeline (query, options) {
        return api.get('/stream/timeline', query, options)
    },
}
