const WhiteListDomains = [
  'develop.loverfans', 'loverfans', 'darkfans', 'scatbook', 'loverachelle2poo', 'jordienp', 'kaitlynkatsarosofficial'
].join('|')

const mentionRegex = new RegExp('@[a-zA-Z0-9]\\w+[a-zA-Z0-9]\\b', 'g')
const postRegex = new RegExp(`(http(s)?:\\/\\/)?(www\\.)?(${WhiteListDomains})\\.[a-z]{2,6}[^\\s\\.\\n\\t]*`, 'gi')
const preMentionRegex = new RegExp(`(http(s)?:\\/\\/)?(www\\.)?(${WhiteListDomains})\\.[a-z]{2,6}\\/((?!products|posts|month_challenge))[a-zA-Z0-9_]{1,50}`, 'g')

export function replaceMention (text, project, currentUsername) {
  if (!text) return ''
  const paragraphs = text.replace(/<\/?[^>]+>/ig, '').split('\n')

  let textResult = ''

  paragraphs.forEach((paragraph) => {
    const p = replaceAtMentions(paragraph, project, currentUsername)
    textResult = textResult + `<p class="replaceMessage">${p}</p>`
  })

  // textResult = replaceAtMentions(textResult, project)
  // textResult = replaceHttpsMentions(textResult, project)
  // textResult = replaceHttpsLinks(textResult, project)

  return textResult
}

function replaceAtMentions (text, project, currentUsername) {
  const users = text.match(mentionRegex)

  if (users) {
    users.forEach((user) => {
      text = text.replace(user, `<router-link :to="{ name: 'influencer', params: { username: '${user.substr(1)}'}, query: { cr: '${currentUsername}' } }" style="color: ${project.project === 'loverfans' || project.project === 'darkfans' ? '#f16577' : '#f18247'} !important;">${user.substr(1)}</router-link>`)
    })
  }
  return replaceHttpsLinks(text, project)
}

function replaceHttpsLinks (text, project) {
  const links = text.match(postRegex)
  if (links) {
    links.forEach((link) => {
      const parser = document.createElement('a')
      parser.href = link

      const color = parser.href.includes('loverfans') || parser.href.includes('darkfans') || parser.href.includes('jordienp') ? '#f16577' : '#f18247'
      if (link.toLowerCase() === 'loverfans.com' || link.toLowerCase() === 'scatbook.com' || link.toLowerCase() === 'kaitlynkatsarosofficial.com' || link.toLowerCase() === 'loverachelle2poo.com' || link.toLowerCase() === 'jordienp.fans' || link.toLowerCase() === 'darkfans.com') {
        text = text.replace(link, `<a href="https://${link}" target="_blank" style="color: ${color} !important;">${link}</a>`)
      } else if (link.includes('trials')) {
        // link = link.startsWith('https://') ? link.substring(8) : link
        text = text.replace(link, `<a href="https://${link.startsWith('https://') ? link.substring(8) : link}" target="_blank" style="color: ${color} !important;">${link}</a>`)
      } else if (link.includes(project)) {
        const link2 = link.startsWith('https://') ? link.substring(8) : link
        text = text.replace(link, `<router-link to="${link2.substring(link2.indexOf('/'))}" style="color: ${color} !important;">${link}</router-link>`)
      } else {
        const link2 = link.startsWith('https://') ? link.substring(8) : link
        text = text.replace(link, `<a href="https://${link2}" target="_blank" style="color: ${color} !important;">${link}</a>`)
      }
    })
  }
  return text
  // return replaceHttpsMentions(text, project)
}

function replaceHttpsMentions (text, project, currentUsername) {
  const pastUsers = text.match(preMentionRegex)
  if (pastUsers) {
    pastUsers.forEach((user) => {
      const url = user.includes('loverfans') ? 'loverfans' : user.includes('darkfans') ? 'darkfans' : 'scatbook'
      if (url === project) {
        text = text.replace(user, `<router-link :to="{ name: 'influencer', params: { username: '${user.substring(user.lastIndexOf('/') + 1)}?cr=${currentUsername}' } }" style="color: ${project.project === 'loverfans' || project.project === 'darkfans' ? '#f16577' : '#f18247'} !important;">${user.substring(user.lastIndexOf('/') + 1)}</router-link>`)
      } else {
        text = text.replace(user, `<a href="${user}?cr=${currentUsername}" target="_blank" style="color: ${url === 'loverfans' || url === 'darkfans' ? '#f16577' : '#f18247'} !important;">${url}/${user.substring(user.lastIndexOf('/') + 1)}</a>`)
      }
    })
  }
  return text
}
