<template>
  <svg :height="`${size}px`" :width="`${size}px`" xmlns="http://www.w3.org/2000/svg" color="gray"
       class="icon icon-tabler icon-tabler-chevron-down" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
       fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path  stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <path  d="M6 9l6 6l6 -6"/>
  </svg>
</template>

<script>
export default {
  name: 'Down',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
