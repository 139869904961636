<script>
export default {
  name: 'RedirectComponent',
  props: {
    route: Object
  },
  mounted () {
    this.$router.push(this.route)
  }
}
</script>
