<template>
  <DummyLoader
    card
    :cols="12 / cols"
    :itemCount="dummyItems"
    :loading="loadingPage">
    <ListView
      :cols="cols"
      :items="items"
      :loading="loading"
      @bottom-reached="loadItems">
      <template #empty>
        <h1 class="title-text px-5">{{ $t('listview.no_sales')}}</h1>
      </template>
      <template #header>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">shopping_cart</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <span class="title-text semibold">{{ $t('market.sales') }}</span>
          </v-list-item-content>
          <v-list-item-action>
            <c-btn text @click="back">{{ $t('actions.back') }}</c-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <template #default="{ item }">
        <ProductCard extended ratio="1.3" :product="item" />
      </template>
    </ListView>
  </DummyLoader>
</template>
<script>
import { mapState } from 'vuex'
import CursorService from '@/components/mixins/cursor_api'
import ProductCard from '@/components/products/ProductCard'
import ProductApi from '@/api/ProductApi'

export default {
  name: 'SaleView',
  mixins: [CursorService(ProductApi)],
  components: { ProductCard },
  data () {
    return {
      loadingPage: false
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    cols () {
      return this.isMobile ? 12 : 3
    },
    dummyItems () {
      return this.isMobile ? 4 : 16
    },
    query () {
      return {
        visibility: 'sale',
        acquired: false,
        includeTags: true,
        ...this.$store.getters['preferences/selected_tags_ids']
      }
    }
  },

  methods: {
    back () {
      this.$router.back()
    }
  },
  async mounted () {
    this.loadingPage = true
    await this.loadItems()
    this.loadingPage = false
  }
}
</script>
