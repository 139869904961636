<template>
  <v-col :cols="isMobile ? 12 : 9" style="margin: auto">
    <div v-if="type.manual">
      <v-row>
        <v-col :cols="isMobile ? 1 : 1" :style="colorDest" style="font-size: 3em;">
          ❶
        </v-col>
        <v-col :cols="isMobile ? 9 : 11" class="campo-label step"
               style="margin-top: auto; margin-bottom: auto;font-family: 'Quicksand', sans-serif !important;  margin-left: auto">
          {{ $t('models.influencer_request.id_document_types.step_one') }}
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="auto" sm="6">
          <!--<v-img max-width="445" max-height="268" :src="dniImage1.preview || idDocumentS3Route1 || `/images/validation/documento-frontal.png`" block contain :minWidth="162" :minHeight="110" style="margin-bottom: 10px;"></v-img>
          <FileInput ref="inputFile"
                     button autoupload
                     color="#f71953"
                     fontColor="white"
                     :rounded="false"
                     :contain="true"
                     block
                     @file-added="dni1Uploaded">
            <span style="color: white">{{ $t('actions.upload_image') }}</span>
          </FileInput>-->

          <DocumentPreview :src="idDocumentS3Route1 || `/images/validation/documento-frontal.png`"
                           :title="false"
                           isHome
                           @file-added="dni1Uploaded"/>
        </v-col>
        <v-col cols="auto" sm="6">
          <!--<v-img max-width="445" max-height="268" :src="dniImage2.preview || idDocumentS3Route2 || `/images/validation/documento-trasero.png`" block contain :minWidth="162" :minHeight="110" style="margin-bottom: 10px;"></v-img>
          <FileInput ref="inputFile"
                     button autoupload
                     color="#f71953"
                     fontColor="white"
                     :rounded="false"
                     :contain="true"
                     block
                     @file-added="dni2Uploaded">
            <span style="color: white">{{ $t('actions.upload_image') }}</span>
          </FileInput>-->

          <DocumentPreview :src="idDocumentS3Route2 || `/images/validation/documento-trasero.png`"
                           :title="false"
                           isHome
                           @file-added="dni2Uploaded"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          :cols="isMobile ? 1 : 1" :style="colorDest" style="font-size: 3em;">
          ❷
        </v-col>
        <v-col
          :cols="isMobile ? 9 : 11" class="campo-label step"
          style="margin-top: auto; margin-bottom: auto;font-family: 'Quicksand', sans-serif !important; margin-left: auto">
          <p>{{ $t('models.influencer_request.id_document_types.step_two') }}</p>
          <p class="mt-2">{{ $t('models.influencer_request.id_document_types.step_two_bis') }}</p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="9" xl="6" style="margin: auto;">
          <!--<v-img max-width="445" max-height="268" :src="verificationImage.preview || verificationPhotoS3Route || `/images/validation/comprobante_frontal.jpg`" block contain :minWidth="162" :minHeight="110" style="margin-bottom: 10px;"></v-img>
          <FileInput ref="inputFile"
                     button autoupload
                     color="#f71953"
                     fontColor="white"
                     :rounded="false"
                     :contain="true"
                     block
                     @file-added="verificationUploaded">
            <span style="color: white">{{ $t('actions.upload_image') }}</span>
          </FileInput>-->

          <DocumentPreview
                           :src="verificationPhotoS3Route || `/images/validation/nif-nie.jpg`"
                           :title="false"
                           full-width
                           isHome
                           @file-added="verificationUploaded"/>
        </v-col>
      </v-row>

      <v-col :cols="isMobile ? 12 : 10" style="margin: auto">
        <v-row>
          <v-col :cols="6" style="text-align: left">
            <v-btn height="52"
                   rounded
                   :loading="submitting"
                   :block="isMobile"
                   color="#D5D5D5"
                   @click="$emit('back')"
                   style="color: white; padding: 17px 29px;">
              <span style="padding: 17px 29px;">{{ $t('actions.back') }}</span>
            </v-btn>
          </v-col>
          <v-spacer v-if="!isMobile"></v-spacer>
          <v-col cols="auto" style="text-align: right">
            <v-btn height="52"
                   rounded
                   :loading="submitting"
                   :disabled="!submittable"
                   :block="isMobile"
                   color="#41B849"
                   @click="requestPromotion">
              <span style="color: white; padding: 17px 29px;">{{ $t('actions.step', {count: 4}) }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
    <VerifyIdenty v-else @close="requestPromotion"/>
  </v-col>

</template>

<script>
import {mapState} from 'vuex'
import validatable from '@/components/mixins/validatable'
import DocumentPreview from '@/components/promote/DocumentPreview'
import project from '@/project'
import isDniNumber from '@/plugins/dni_format_validation.js'
import isPassportNumber from '@/plugins/passport_format_validation.js'
import isDriverNumber from '@/plugins/driver_license_format_validation.js'
import VerifyIdenty from '@/components/verifyIdenty/VerifyIdenty.vue'
import {ProfileActions} from "@/store";
import UserApi from "@/api/UserApi";

export default {
  name: 'StepThree',
  mixins: [validatable('form')],
  components: {
    VerifyIdenty,
    DocumentPreview
  },
  props: {
    isDark: Boolean,
    type: Object
  },
  data() {
    return {
      submitting: false,
      documentTypes: [
        {
          text: this.$t('models.influencer_request.id_document_types.passport'),
          value: 'passport'
        },
        {
          text: this.$t('models.influencer_request.id_document_types.id_document'),
          value: 'id_document'
        },
        {
          text: this.$t('models.influencer_request.id_document_types.driver_license'),
          value: 'driver_license'
        }
      ],
      dniImage1: {},
      dniImage2: {},
      verificationImage: {},
      idDocumentType: null,
      idDocumentNumber: null,
      idDocumentCountry: null,
      idDocumentExpiration: null,
      idDocumentS3Route1: null,
      idDocumentS3Route2: null,
      verificationPhotoS3Route: null,
      project
    }
  },

  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser', 'promotion', 'subscriptionPrice', 'yotiSessionId']),
    colorFont() {
      return {
        color: this.isDark ? '#FFFFFF' : '#000000'
      }
    },
    colorDest() {
      return {
        color: project.primary_color
      }
    },
    paddingRight() {
      if (this.isMobile) return
      return {
        paddingRight: '10px'
      }
    },
    paddingLeft() {
      if (this.isMobile) return
      return {
        paddingLeft: '10px'
      }
    },
    isDniUploaded() {
      return (this.dniImage1.uploaded && this.dniImage2.uploaded) || (this.idDocumentS3Route1 && this.idDocumentS3Route2) || (this.dniImage1.uploaded && this.idDocumentS3Route2) || (this.dniImage2.uploaded && this.idDocumentS3Route1)
    },
    isVerificationUploaded() {
      return this.verificationImage.uploaded || this.verificationPhotoS3Route
    },
    dniIcon() {
      return this.isDniUploaded ? 'check_circle' : 'check_circle_outline'
    },
    submittable() {
      return this.isDniUploaded && this.isVerificationUploaded
    },
    validDocument() {
      return true
      if (this.idDocumentType === 'passport') {
        return !!isPassportNumber(this.idDocumentNumber, this.idDocumentCountry) || this.$t('errors.invalidNif')
      } else if (this.idDocumentType === 'id_document') {
        return !!isDniNumber(this.idDocumentNumber, this.idDocumentCountry) || this.$t('errors.invalidNif')
      } else if (this.idDocumentType === 'driver_license') {
        return !!isDriverNumber(this.idDocumentNumber, this.idDocumentCountry) || this.$t('errors.invalidNif')
      } else {
        return false
      }
    }
  },

  methods: {
    nextData() {
      this.$emit('next')
    },
    idData() {
      return {
        idDocumentCountry: this.currentUser.documentCountry,
        idDocumentNumber: this.currentUser.documentNumber,
        idDocumentType: this.currentUser.documentType,
        idDocumentExpiration: this.currentUser.documentExpiration,
        subscriptionPrice: this.subscriptionPrice,
        yotiSessionId: this.yotiSessionId,
        idDocumentS3Route1: this.dniImage1.location || this.idDocumentS3Route1,
        idDocumentS3Route2: this.dniImage2.location || this.idDocumentS3Route1,
        verificationPhotoS3Route: this.verificationImage.location || this.verificationPhotoS3Route,
      }
    },
    dni1Uploaded(file) {
      this.idDocumentS3Route1 = null
      this.dniImage1 = file
    },
    dni2Uploaded(file) {
      this.idDocumentS3Route2 = null
      this.dniImage2 = file
    },
    verificationUploaded(file) {
      this.verificationPhotoS3Route = null
      this.verificationImage = file
    },
    skip() {
      this.$emit('skip')
    },
    async emitData() {
      const action = this.$listeners.next
      this.submitting = true
      try {
        await action(this.idData())
      } catch (ex) {
      }
      this.submitting = false
    },
    async requestPromotion() {
      await this.$store.dispatch(
        ProfileActions.UpdatePromotion, this.idData()
      )
      this.$emit('next')
    }
  },

  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    this.idDocumentType = this.currentUser.promotion.idDocumentType
    this.idDocumentCountry = this.currentUser.promotion.idDocumentCountry
    this.idDocumentNumber = this.currentUser.promotion.idDocumentNumber
    this.idDocumentExpiration = this.currentUser.promotion.idDocumentExpiration
    this.idDocumentS3Route1 = this.currentUser.promotion.idDocumentS3Route1
    this.idDocumentS3Route2 = this.currentUser.promotion.idDocumentS3Route2
    this.verificationPhotoS3Route = this.currentUser.promotion.verificationPhotoS3Route
    this.submitting = false
    this.$gtm('star_registration_step3', undefined, this.currentUser.email)
  }
}
</script>

<style lang="scss" scoped>

.step {
  font-size: 3em;
  vertical-align: middle;
  float: left;
}

.campo-label {
  text-align: left;
  font-size: 16px;
  font-family: 'Quicksand', sans-serif;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  font-family: Quicksand, sans-serif;
}

</style>

