<template>
  <section class="app-fullpage">
    <div class="border-bottom">
      <PublicationLauncher card v-if="isInfluencer"></PublicationLauncher>
      <InfluencerRecommendations v-if="!isLargeScreen" horizontal/>
      <h1 v-if="!isInfluencer" class="timeline-engagment semibold">
        {{ $t('timeline.engagment') }}
      </h1>
    </div>
    <PromotionalBanner
      v-if="promotionActive"
      :influencer="isInfluencer"
      style="display: contents"
    />
    <v-row no-gutters justify="end" align="center">
      <v-col cols="auto" class="px-2">
        <label class="pointer" @click="switchRecommended">{{
            $t('timeline.include_recommendations')
          }}</label>
      </v-col>
      <v-col cols="auto">
        <v-switch
          inset
          color="primary"
          :input-value="recommended"
          @change="switchRecommended"
        >
        </v-switch>
      </v-col>
    </v-row>
    <ListView
      no-gutters
      cols="12"
      :loading="cursor.loading"
      :items="cursor.items"
      :item-min-height="200"
      v-scroll="checkScrolling"
      @bottom-reached="cursor.loadItems"
    >
      <template #empty>
        <StarsDiscoveryMessage empty/>
      </template>
      <template #default="{ item }">
        <PostPage :post="item" fake-video/>
        <div
          class="post-divider border-bottom"
          :class="{ recommended: item.recommended }"
        ></div>
      </template>
    </ListView>
    <CampaingPromoDialog v-model="dialog" v-if="showCampaign" @close="closePromoDialog()"/>

    <warning
      :closable="false"
      :actions="false"
      :title="$t('application.contract.title')"
      :body="$t('application.contract.textModals')"
      v-model="showModal2Contract">
      <div :style="styleCss">
        <c-btn color="secondary" class="m-2" @click="showModal2Contract=false">
          {{ $t('application.contract.btnClose') }}
        </c-btn>
        <c-btn class="m-2" to="/contract">
          {{ $t('application.contract.btnSign') }}
        </c-btn>
      </div>
    </warning>
    <ModalAvn/>
  </section>
</template>
<script>
import {mapState} from 'vuex'
import {AppActions, PreferenceActions} from '@/store'
import PostService from '@/components/posts/mixins/post_service'
import PublicationLauncher from '@/components/custom/PublicationLauncher'
import PostPage from '@/components/posts/PostPage'
import StarsDiscoveryMessage from '@/views/stars/StarsDiscoveryMessage'
import InfluencerRecommendations from '@/components/widgets/InfluencerRecommendations'
import PromotionalBanner from '@/components/promotional/PromotionalBanner'
import dialog from '@/components/mixins/dialog'
import project from '@/project'
import cookies from '@/cookies'
import PromoModal from '@/components/custom/PromoModal'
import SpecialNotification from '@/components/custom/SpecialNotification'
import CampaingPromoDialog from '@/views/landing/specialCampaign/CampaingPromoDialog'
import Contract from "@/components/signContract/Contract.vue";
import SignCreatorsSummary from "@/components/signContract/SignCreatorsSummary.vue";
import ModalAvn from "@/views/landing/PromoCode/ModalAvn.vue";

const cursors = [{
  name: 'cursor',
  action: 'posts/timeline'
}]

export default {
  name: 'TimelineView',
  mixins: [PostService({cursors}), dialog],
  components: {
    ModalAvn,
    SignCreatorsSummary,
    Contract,
    SpecialNotification,
    PromoModal,
    InfluencerRecommendations,
    PublicationLauncher,
    PostPage,
    StarsDiscoveryMessage,
    PromotionalBanner,
    CampaingPromoDialog
  },
  data() {
    return {
      showCampaign: false,
      timelineValid: 0,
      loading: false,
      promotionActive: false,
      updateScroll: false,
      web: null,
      showSpecial: false,
      showModal2Contract: false,
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile', 'isLargeScreen']),
    ...mapState('profile', ['currentUser', 'isInfluencer', 'isFollower', 'isAdmin', 'isAccountManager']),
    ...mapState('preferences', ['recommended']),
    checkLanguage() {
      return this.$i18n.locale === 'es'
    },
    isES() {
      return this.$i18n.locale === 'es'
    },
    styleCss() {
      if (!this.isMobile) {
        return {
          'display': 'flex',
          'justify-content': 'center'
        }
      }
      return {
        'display': 'grid',
        'place-content': 'center'
      }
    },
    titleModalContract() {
      return this.isES ? 'Hemos modificado nuestros términos y nuestra política de privacidad' : 'We have modified our terms and our privacy policy'
    },

    query() {
      return {
        recommended: this.recommended,
        // DARKFANS
        darkfans: project.project === 'darkfans',
        includeTags: true,
        promotion: false,
        ...this.$store.getters['preferences/selected_tags_ids']
      }
    }
  },

  methods: {
    notShow() {
      cookies.set('notShowTrials', true)
      this.$store.dispatch(AppActions.UpdateCookies, true)
    },
    notShowContract() {
      cookies.set('notShowContract', true)
    },
    participatePromo() {
      this.$router.push({name: 'monthChallenge'})
    },
    checkScrolling(event) {
      if (!this.updateScroll) return
      this.scrollPosition = event.target.scrollingElement.scrollTop
    },
    switchRecommended() {
      this.$store.dispatch(
        PreferenceActions.UpdateRecommended,
        !this.recommended
      )
    },
    onLoadFinished(data) {
      this.timelineValid = data.metadata.validUntil
    },
    onPostCreated(post) {
      const publishTime = new Date(post.publishedAt).getTime()
      this.cursor.addItem(p => new Date(p.publishedAt).getTime() < publishTime)
    },
    onPostDeleted(postId) {
      this.cursor.removeItem(p => p.id === postId)
    },
    accept() {
      this.dialog = false
    },
    eventStarted(today) {
      // LOS MESES SON DE 0 - 11)
      const users = this.project.campaign.users
      let activeUser = true
      if (users.length > 0) {
        activeUser = false
        const index = users.findIndex(u => u === this.currentUser.username)
        activeUser = index !== -1
      }
      const eventStarted = this.project.campaign.started
      const activeDate = today - eventStarted > 0
      return activeDate && activeUser
    },
    eventEnded(today) {
      // LOS MESES SON DE 0 - 11)

      var eventEnded = this.project.campaign.endDate
      return eventEnded - today < 0
    },
    closeSpecialDialog() {
      this.showSpecial = false
    },
    closePromoDialog() {
      if (!this.showSpecial) {
        this.showCampaign = false
      }
    },
    testCamp() {
      if (this.project.isDevelop) {
        return true
      } else {
        return this.currentUser.username === 'antomar' || this.currentUser.username === 'follower'
      }
    }
  },
  created() {
    this.subscribePostCreated()
    this.subscribePostDeleted()
  },

  beforeMount() {
    this.cursor.onLoadFinished = this.onLoadFinished
  },

  activated() {
    const now = new Date().getTime()
    if (this.timelineValid < now) {
      this.cursor.resetCursor(true)
    }
    this.$nextTick(() => {
      document.scrollingElement.scrollTop = this.scrollPosition
      this.updateScroll = true
    })
  },
  deactivated() {
    this.updateScroll = false
  },
  mounted() {
    this.dialog = false
    const spQueryParam = this.$route.query.sp
    const shouldShowModal = !this.currentUser.signedDocument && this.isInfluencer

    if (spQueryParam === 'promo') {
      this.showModal2Contract = false
    } else {
      if (shouldShowModal) {
        this.showModal2Contract = true
      }
    }
    if (!cookies.get(this.project.campaign.cookieModal) &&
      !this.eventEnded(new Date()) &&
      this.eventStarted(new Date())
    ) {
      this.showCampaign = true
      this.dialog = true
    }
  }
}
</script>
<style lang="scss" scoped>
@use 'src/scss/colors.scss';
@use 'src/scss/styles.scss';

.timeline-engagment {
  min-height: 65px;
  line-height: 65px;
  padding: 8px;
}

.post-divider {
  height: 1px;

  &.recommended {
    margin-top: 20px;
  }
}

.avatar.transparent {
  margin: auto;
}

.online {
  .circle {
    background-color: colors.$light-red !important;
  }

  .user-live {
    height: 1.1em;
    border-radius: 1.1em;
    font-size: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: colors.$red;

    &.large {
      font-size: 10px;
      height: 0.8em;
      border-radius: 0.8em;
    }

    &.small {
      font-size: 8px;
    }

    &.x-large {
      font-size: 14px;
      height: 1.5em;
      border-radius: 1.5em;
    }
  }
}
</style>
