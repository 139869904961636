<template>
  <section>
    <div class="call-panel">
      <IconButton
        v-if="inVideoCall"
        type="end-call"
        disabledDarkTheme
        disabledEffectAction
        class="btn"
        :size="40"
        @handler="hang"
      />
      <div v-else>
        <div
          v-if="!showInputAudio && !showInputVideo"
          class="tooltip"
          :style="
            joinVideoCall ? 'margin-bottom: 35px;' : 'margin-bottom: 10px;'
          "
        >
          <span class="tooltiptext">{{
              $t('chats.video_chat.tooltip_call')
            }}</span>
        </div>
        <span v-if="joinVideoCall" class="join-call" @click="call">
          {{ $t('actions.join') }}
        </span>
        <IconButton
          v-else
          type="call"
          disabledDarkTheme
          disabledEffectAction
          class="btn"
          :size="40"
          @handler="call"
        />
      </div>
    </div>
    <div class="control-panel">
      <div class="icon-setting warn">
        <Dialog
          :show="reportDialog"
          @hide="close"
        >
          <template #bodyDialog>
            <ReportForm @close="close"></ReportForm>
          </template>
        </Dialog>
        <c-btn icon color="secondary" @click="reportDialog=true" v-if="inVideoCall">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-triangle" width="30"
               height="30" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
               stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z"></path>
            <path d="M12 9v4"></path>
            <path d="M12 17h.01"></path>
          </svg>
        </c-btn>
      </div>
      <div class="icon-setting">
        <IconButton
          v-if="micActive"
          type="active-mic"
          :size="30"
          @handler="hidenShowMic"
        />
        <IconButton v-else type="mute-mic" :size="30" @handler="hidenShowMic"/>
        <IconButton
          v-if="getAudioInput"
          type="arrow-bottom"
          :size="30"
          class="btn"
          disabledEffectAction
          :class="iconShowRotateCssInputAudio"
          @handler="onShowInputAudio"
        />
      </div>
      <div v-if="false" class="icon-setting">
        <IconButton
          v-if="soundActive"
          type="active-audio"
          :size="30"
          @handler="switchAudio"
        />
        <IconButton
          v-else
          type="mute-audio"
          :size="30"
          @handler="switchAudio"
        />
        <IconButton
          v-if="getAudioInput > 0"
          type="arrow-bottom"
          :size="30"
          class="btn"
          disabledEffectAction
          :class="iconShowRotateCssOutputAudio"
          @handler="onShowOutputAudio"
        />
      </div>
      <div class="icon-setting">
        <IconButton
          v-if="camActive"
          type="active-cam"
          :size="30"
          @handler="hidenShowCam"
        />
        <IconButton v-else type="mute-cam" :size="30" @handler="hidenShowCam"/>
        <IconButton
          v-if="getVideoInput"
          type="arrow-bottom"
          :size="30"
          disabledEffectAction
          class="btn"
          :class="iconShowRotateCssInputVideo"
          @handler="onShowInputVideo"
        />
      </div>
    </div>
    <div v-if="micActive" class="icon-micro-active">
      <div class="line1"></div>
      <div class="line2"></div>
      <div class="line3"></div>
    </div>

    <div
      v-if="showInputAudio"
      class="input-list audio-input"
      :class="darkClass"
    >
      <div v-for="(device, index) in getAudioInput" :key="`A-${index}`">
        <p
          :class="{ selected: device.selected }"
          @click="selectedInputAudio(device, index)"
        >
          {{ device.label }}
        </p>
      </div>
    </div>

    <div
      v-if="showInputVideo"
      class="input-list video-input"
      :class="darkClass"
    >
      <div v-for="(device, index) in getVideoInput" :key="`C-${index}`">
        <p
          :class="{ selected: device.selected }"
          @click="selectedInputVideo(device, index)"
        >
          {{ device.label }}
        </p>
      </div>
    </div>

  </section>
</template>

<script>
import IconButton from '@/components/chat/component/IconButton'
import OT from '@opentok/client'
import ComplaintApi from '@/api/ComplaintApi'
import Dialog from '@/components/chat/component/Dialog.vue'
import ReportForm from '@/components/chat/components/videoChat/ReportForm.vue'
import { mapState } from 'vuex'

export default {
  name: 'PanelControl',
  components: {
    ReportForm,
    Dialog,
    IconButton
  },
  props: {
    micActive: {
      type: Boolean,
      default: true
    },
    camActive: {
      type: Boolean,
      default: true
    },
    soundActive: {
      type: Boolean,
      default: true
    },
    inVideoCall: {
      type: Boolean,
      default: false
    },
    joinVideoCall: {
      type: Boolean,
      default: false
    }
  },
  async mounted () {
    await this.getMedia()
  },
  computed: {
    ...mapState('profile', ['isAdmin', 'isAccountManager', 'isContentManager', 'isController']),
    getAudioInput () {
      const audioInput = this.devices.filter(d => d.kind === 'audioInput')
      audioInput.map((i, index) => {
        i.selected = index === 0
      })
      return audioInput
    },
    getAudioOutput () {
      return this.devices.filter(d => d.kind === 'audiooutput')
    },
    getVideoInput () {
      const videoInput = this.devices.filter(d => d.kind === 'videoInput')
      videoInput.map((i, index) => {
        i.selected = index === 0
      })
      return videoInput
    },
    iconShowRotateCssInputAudio () {
      return {
        'icon-list-show': !this.showInputAudio,
        'icon-list-hidden': this.showInputAudio
      }
    },
    iconShowRotateCssOutputAudio () {
      return {
        'icon-list-show': !this.showOutputAudio,
        'icon-list-hidden': this.showOutputAudio
      }
    },
    iconShowRotateCssInputVideo () {
      return {
        'icon-list-show': !this.showInputVideo,
        'icon-list-hidden': this.showInputVideo
      }
    },
    darkClass () {
      return {
        dark: this.$vuetify.theme.dark
      }
    }
  },
  data () {
    return {
      devices: [],
      showInputAudio: false,
      showOutputAudio: false,
      showInputVideo: false,
      reportDialog: false
    }
  },
  methods: {
    close () {
      this.reportDialog = !this.reportDialog
    },
    async getMedia () {
      await OT.getDevices((error, devices) => {
        if (!error) {
          this.devices = devices
        } else {
          console.log('error al cargar devices', error, devices)
        }
      })
    },
    hidenShowMic () {
      this.$emit('hidenShowMic', this.getAudioInput[0].deviceId)
    },
    hidenShowCam () {
      this.$emit('hidenShowCam', this.getVideoInput[0].deviceId)
    },
    switchAudio () {
      this.$emit('switchAudio')
    },

    selectedInputAudio (device, index) {
      this.getAudioInput.map(i => (i.selected = false))
      this.getAudioInput[index].selected = true

      this.$emit('switchMicro', device.deviceId)
      this.hiddenShowList()
    },
    selectedOutputAudio (deviceId) {
      this.$emit('output-audio', deviceId)
      this.hiddenShowList()
    },
    selectedInputVideo (device, index) {
      this.getVideoInput.map(i => (i.selected = false))
      this.getVideoInput[index].selected = true

      this.$emit('switchCamera', device.deviceId, device.label)
      this.hiddenShowList()
    },
    call () {
      this.$emit('call')
    },
    hang () {
      this.$emit('hang')
    },
    onShowInputAudio () {
      this.showOutputAudio = false
      this.showInputVideo = false
      this.showInputAudio = !this.showInputAudio
    },
    onShowOutputAudio () {
      this.showInputAudio = false
      this.showInputVideo = false
      this.showOutputAudio = !this.showOutputAudio
    },
    onShowInputVideo () {
      this.showInputAudio = false
      this.showOutputAudio = false
      this.showInputVideo = !this.showInputVideo
    },
    hiddenShowList () {
      this.showInputAudio = false
      this.showOutputAudio = false
      this.showInputVideo = false
    },
    async report () {
      await ComplaintApi.create({
        resource: this.resource,
        resourceId: this.id,
        comment: this.comment
      })
      this.showMenu = false
      this.hideDialog()
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.report_success')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.call-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(128, 128, 128, 0.7);
  width: fit-content;
  margin: auto;
  position: absolute;
  bottom: 0;
  z-index: 13;
  left: 0;
  right: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  .btn {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.join-call {
  background-color: #29d331;
  padding: 10px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  font-family: "Quicksand";
  cursor: pointer;
}

.icon-setting {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin-top: 5px;

  &.warn {
    justify-content: start;
    margin-left: 12px;
  }
}

.control-panel {
  z-index: 3;
  position: absolute;
  position: absolute;
  bottom: 80px;
  padding: 0;
  margin: 0;
  background-color: rgba(128, 128, 128, 0.7);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.input-list {
  position: absolute;
  background: white;
  z-index: 13;
  left: 80px;
  width: fit-content;
  border-radius: 5px;
  padding: 5px;

  &.dark {
    background-color: #878787;
  }

  :hover {
    /* background: white; */
    color: var(--v-primary-base);
  }
}

.selected {
  font-weight: bold;
  color: var(--v-primary-base);
}

.audio-input {
  bottom: 95px;
}

.audio-output {
  bottom: 90px;
}

.video-input {
  bottom: 80px;
}

.icon-list-show {
  transform: rotate(272deg);
}

.icon-list-hidden {
  transform: rotate(90deg);
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  width: 180px;
  background-color: #28d332;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 13;
  bottom: 150%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #28d332 transparent transparent transparent;
}

.icon-micro-active {
  position: absolute;
  bottom: 28%;
  background: #1a73e8c4;
  opacity: 0.9;
  border: solid 2px white;
  left: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: white;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  transform: rotate(180deg);
}

.line1 {
  width: 2px;
  height: 4px;
  border-top-left-radius: 11%;
  border-top-right-radius: 11%;
  /* height: 4px;
  border-radius: 50%; */
  position: absolute;
  left: 20%;
  top: 35%;
  background-color: white;
  animation: uno 1s infinite cubic-bezier(0.4, 0, 1, 1);
}

.line2 {
  width: 2px;
  height: 4px;
  border-top-left-radius: 11%;
  border-top-right-radius: 11%;
  /*  height: 4px;
  border-radius: 2px; */
  background-color: white;
  position: absolute;
  left: 50%;
  top: 35%;
  animation: dos 1s infinite ease-out;
}

.line3 {
  width: 2px;
  height: 4px;
  background-color: white;
  position: absolute;
  left: 75%;
  top: 35%;
  animation: tres 1s infinite ease-in-out;
}

@keyframes uno {
  50% {
    height: 7px;
  }
  100% {
    height: 10px;
  }
}

@keyframes dos {
  50% {
    height: 7px;
  }
  100% {
    height: 14px;
  }
}

@keyframes tres {
  50% {
    height: 7px;
  }
  100% {
    height: 10px;
  }
}
</style>
