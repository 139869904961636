<template>
  <section>
    <div>
      <strong>{{ $t(`withdraws.withdraw_methods.types.${method.type}`) }}</strong>
    </div>
    <template v-if="isBank">
      <div>{{ $t('withdraws.bank.name', { name: method.info.bankName }) }}</div>
      <div>{{ $t('withdraws.bank.country', { country: method.info.bankCountry }) }}</div>
      <div>{{ $t('withdraws.bank.currency', { currency: method.info.bankCurrency }) }}</div>
      <div>{{ $t('withdraws.bank.account', { account: method.info.bankAccount }) }}</div>
      <div>{{ $t('withdraws.bank.swift', { swift: method.info.swiftAccount }) }}</div>
      <div>{{ $t('withdraws.bank.routing_number', { aba: method.info.bankRoutingNumber }) }}</div>
    </template>
    <template v-else>
      <div>{{ $t('withdraws.method_account', { account: method.accountId }) }}</div>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { WithdrawActions } from '@/store'

export default {
  name: 'WithdrawDataInfo',
  props: {
    noLoad: Boolean
  },
  computed: {
    ...mapState('withdraws', ['withdrawMethods']),
    method () {
      return this.withdrawMethods[0] || {}
    },
    isBank () {
      return this.method.type === 'bank'
    }
  },
  beforeMount () {
    if (!this.noLoad) {
      this.$store.dispatch(WithdrawActions.FetchMethods)
    }
  }
}
</script>
