<template>
  <section>
    <h1>
      {{ $t('settings.payments.subscription_price') }}
    </h1>
    <p>{{ $t('settings.payments.subscription_price_description') }}</p>
    <OffersInfo @open-config="showConfigDialog" />
    <TrialInfoRow @open-config="showConfigDialog"  />
    <SubscriptionConfigDialog v-model="dialog" :tab="selectedTab"
      @submitted="selectTrial"/>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import dialog from '@/components/mixins/dialog'
import OffersInfo from './OffersInfo'
import TrialInfoRow from './TrialInfoRow'
import SubscriptionConfigDialog from './SubscriptionConfigDialog.vue'

export default {
  name: 'SubscriptionInfo',
  mixins: [dialog],
  components: { OffersInfo, TrialInfoRow, SubscriptionConfigDialog },
  props: {
    dark: { type: Boolean, default: null }
  },
  data () {
    return {
      selectedTab: 'offers',
      createdTrial: null
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    enabledOffers () {
      return this.currentUser.subscriptionOffers.filter(o => o.enabled)
    }
  },
  methods: {
    offerPrice (offer) {
      const discountPrice = (parseFloat(offer.discount) / 100 * this.currentUser.subscriptionPrice)
      const price = offer.months * (this.currentUser.subscriptionPrice - discountPrice)
      return this.$currency(price, this.currentUser.billing.currency)
    },
    showConfigDialog (tab) {
      this.selectedTab = tab
      this.showDialog()
    },
    selectTrial ({ trial } = {}) {
      if (!trial) {
        return
      }
      this.$store.dispatch(ProfileActions.SelectTrial, trial)
    }
  }
}
</script>
