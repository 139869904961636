import Config from '@/project'

export default {
  data () {
    return {
      currentTime: new Date(),
      timeout: null
    }
  },

  computed: {
    isLaboralDay() {
      const day = this.currentTime.getUTCDay()
      return day >= 1 && day <= 5
    },

    isLaboralHour() {
      const hours = this.currentTime.getUTCHours()
      return hours >= 6 && hours <= 18
    },
  
    isCreditCardEnabled() {
      return true
    }
  },
  methods: {
    startTimer() {
      if (Config.project !== 'scatbook') return
      this.timeout = setTimeout(this.step.bind(this), 10000)
    },

    step () {
      this.currentTime = new Date()
      this.timeout = setTimeout(this.step.bind(this), 10000)
    }
  },
 
  mounted() {
    this.startTimer()
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  
}
