<template>
  <section class="border-top">
    <div>
      <section class="head-modals rows">
        <div class="cols">
          <IconButton :size="10" type="close" @handler="close" />
        </div>
      </section>
      <TitleOptionTab :attrs="title" :defaultSelected="1" @selectTab="onTab" />
      <!-- <div class="content-adjuntar-mediafile active" :class="cssClass">
        <div @click="() => (selesctTab = 1)">
          <p>FREE</p>
        </div>
        <div @click="() => (selesctTab = 2)">
          SET NEW PRICE
        </div>
      </div> -->
      <div v-if="priceTab">
        <div class="spacer"></div>
        <div class="center row ">
          <p class="mr-1">{{ $t("messages.premium.title").toUpperCase() }}</p>
          <InputCurrency
            :currency="currency"
            :defaultPrice="currentPrice"
            v-on:newPrice="setPriceConversations"
          />
          <Button
            color="#41b849"
            :text="$t('actions.save').toUpperCase()"
            @handler="saveNewPrice"
            :loading="loadingButton"
          />
        </div>
      </div>
      <div v-if="free">
        <div class="spacer"></div>
        <div class="center row">
          <p>{{ $t("chats.price_conversations") }}</p>
          <Button
            color="#41b849"
            :text="$t('actions.save').toUpperCase()"
            @handler="onFree"
            :loading="loadingButton"
          />
        </div>
      </div>
      <div class="spacer"></div>
    </div>
  </section>
</template>

<script>
import ConversationsApi from "@/api/conversations/ConversationsApi";
import { mapState, mapGetters } from "vuex";
import { ChatActions } from "@/store";
import InputCurrency from "../../component/InputCurrency.vue";
import Button from "../../component/Buttons";
import IconButton from "../../component/IconButton";
import TitleOptionTab from "@/components/chat/component/TitleOptionTab";
export default {
  name: "PriceConversation",
  components: {
    Button,
    InputCurrency,
    IconButton,
    TitleOptionTab
  },
  data() {
    return {
      title: [
        {
          iconType: "upload",
          title: "chats.price_conversations_preference.title_1",
          call: "free",
          icon: false,
          show: true
        },
        {
          iconType: "upload",
          title: "chats.price_conversations_preference.title_2",
          call: "price",
          icon: false,
          show: true
        }
      ],
      price: 0,
      loadingButton: false,
      priceTab: false,
      free: false,
      tab: 2
    };
  },
  computed: {
    ...mapState("profile", ["currentUser"]),
    ...mapState("chats", ["selectedChat"]),
    ...mapGetters("chats", ["getPreferences"]),
    currency() {
      return this.currentUser.wallet.currency === "EUR" ? "€" : "$";
    },
    currentPrice() {
      return `${this.selectedChat.sender.price}`;
    },
    darkTheme() {
      return this.$vuetify.theme.dark;
    },
    cssClass() {
      return {
        dark: this.darkTheme
      };
    },
    selesctTab: {
      get() {
        return this.tab;
      },
      set(select) {
        this.tab = select;
      }
    }
  },
  methods: {
    setPriceConversations(price) {
      this.price = price;
    },
    onTab(name) {
      this.priceTab = name === "price";
      this.free = name === "free";
    },
    onFree() {
      this.price = 0;
      this.saveNewPrice();
    },
    async saveNewPrice() {
      this.loadingButton = true;
      const id = this.selectedChat.id;
      const preferences = Object.assign({}, this.getPreferences);

      const sender = Object.assign({}, this.selectedChat.sender);
      const price = this.price;
      preferences.price = price;
      preferences.notified = false;

      const { data, error } = await ConversationsApi.preferences(
        id,
        preferences
      );
      if (!error) {
        this.loadingButton = false;
        sender.price = price;
        this.$store.dispatch(ChatActions.UpdateAttributes, { sender: sender });
        this.$emit("changePrice");
      }
    },
    close() {
      this.$emit("reset");
    }
  }
};
</script>
<style lang="scss">
.no-outline:focus {
  outline: none;
  font-size: 16px !important;
}
.spacer {
  padding: 8px;
}
.active {
  cursor: pointer;
}
</style>
