<template>
  <v-row no-gutters align="center">
    <v-col cols="auto" class="mx-2">
      <div class="recommended-banner">
        <img class="logo"
          src="/images/logo_white.svg?dfk23" />
      </div>
      <div class="arrow-bottom"></div>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="auto">
      <div class="text-title semibold">
        {{ $t('posts.recommended') }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'PostRecommendedBanner'
}
</script>
<style lang="scss" scoped>
.recommended-banner {
  background-color: var(--v-primary-base);
  width: 42px;
  height: 30px;
  .logo {
    margin-top: 5px;
    margin-left: 9px;
    width: 24px;
    height: 24px;
  }
}
.arrow-bottom {
  width: 0;
  height: 0;
  border-left: 21px solid transparent;
  border-right: 21px solid transparent;
  border-top: 20px solid var(--v-primary-base);
}
</style>
