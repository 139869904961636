<template>
  <section class="header-video-call">
    <AvatarUser
      :avatar="avatar"
      :height="34"
      :width="34"
      :username="displayNameUser"
      class="user-avatar"
    />
    <div class="price-close-div">
      <span class="price" v-if="price > 0">{{
        $t("chats.video_chat.notification.price", {
          price: $currency(price)
        })
      }}</span>
      <IconButton :size="20" type="close" @handler="closeVideoChat" />
    </div>
  </section>
</template>
<script>
import AvatarUser from "@/components/chat/component/AvatarUser";
import IconButton from "@/components/chat/component/IconButton";
import {mapState} from 'vuex'
export default {
  name: "HeaderVideoChat",
  components: {
    AvatarUser,
    IconButton
  },
  props: {
    avatar: { type: String, default: "/images/no_content.png" },
    displayNameUser: { type: String, default: "" },
    price: { type: Number, default: 0 }
  },
  computed:{
    ...mapState("profile",['isFollower']),
  },
  methods: {
    closeVideoChat() {
      this.$emit("close-video-chat");
    }
  }
};
</script>

<style lang="scss" scoped>
.header-video-call {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  flex-direction: row;
  align-content: space-around;
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  background-color: rgba(128, 128, 128, 0.7);
}
.price-close-div {
  display: flex;
  .price {
    /*  color: white; */
    font-weight: bold;
    padding-right: 10px;
  }
}
.user-avatar {
  border-radius: 20px;
  color: white !important;
}
</style>
