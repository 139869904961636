<template>
  <svg id="Grupo_22" data-name="Grupo 22" xmlns="http://www.w3.org/2000/svg" width="25.6" height="22" viewBox="0 0 25.6 22">
    <g id="person-24px" fill="none">
      <path id="Trazado_15" data-name="Trazado 15" d="M0,0H22V22H0Z" />
      <path
        :fill="fillColor"
        id="Trazado_16"
        data-name="Trazado 16"
        d="M10.6,10.6A3.3,3.3,0,1,0,7.3,7.3,3.3,3.3,0,0,0,10.6,10.6Zm0,1.65c-2.2,0-6.6,1.106-6.6,3.3V17.2H17.2V15.55C17.2,13.355,12.8,12.25,10.6,12.25Z"
        transform="translate(0.4 0.4)"/>
    </g>
    <g id="block-24px" transform="translate(15.29 2.69)"  fill="none">
      <path id="Trazado_4" data-name="Trazado 4" d="M0,0H10.31V10.31H0Z" />
      <path
        :fill="fillColor"
        id="Trazado_5"
        data-name="Trazado 5"
        d="M6.3,2a4.3,4.3,0,1,0,4.3,4.3A4.3,4.3,0,0,0,6.3,2ZM2.859,6.3A3.436,3.436,0,0,1,6.3,2.859a3.4,3.4,0,0,1,2.1.726L3.585,8.4A3.4,3.4,0,0,1,2.859,6.3ZM6.3,9.733a3.4,3.4,0,0,1-2.1-.726L9.007,4.191a3.4,3.4,0,0,1,.726,2.1A3.436,3.436,0,0,1,6.3,9.733Z"
        transform="translate(-1.141 -1.141)"/>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'BlockUserIcon',
  props: {
    fill: String
  },
  computed: {
    fillColor () {
      return this.fill
        ? this.fill === 'primary'
          ? this.$vuetify.theme.themes.light.primary
          : this.fill
        : 'white'
    }
  }
}

</script>
