<template>
  <c-dialog persistent
            v-model="dialog"
            width="520"
            @close="closeDialog">
    <v-col class="sec-form p-0">
    <span v-if="showSignup">
      <SimpleSignUpForm @complete="closeDialog" :noRedirect="noRedirect"></SimpleSignUpForm>
    </span>
      <span v-else>
      <SimpleLoginForm @complete="closeDialog" :small="noRedirect"/>
    </span>

      <div class="text-center mt-4">
        <a v-if="showSignup" @click="showSignup = !showSignup"
           style="font-family: BenchNine, sans-serif; font-size: 20px;">{{ $t(`signup.already_registered`) }}</a>
        <a v-else @click="showSignup = !showSignup"
           style="font-family: BenchNine, sans-serif; font-size: 20px;">{{ $t(`signup.not_member`) }}</a>
      </div>
    </v-col>
  </c-dialog>
</template>
<script>
import SimpleSignUpForm from '@/components/sessions/SimpleSignUpForm.vue'
import SimpleLoginForm from '@/components/sessions/SimpleLoginForm.vue'
import dialog from '@/components/mixins/dialog'
export default {
  name: 'SimpleSessionForm',
  mixins: [dialog],
  components: { SimpleSignUpForm, SimpleLoginForm },
  data () {
    return {
      showSignup: true
    }
  },
  methods: {
    closeDialog () {
      this.$emit('input', null)
      this.$emit('close')
    }
  },
  props: {
    noRedirect: Boolean
  },
  mounted() {
    this.dialog = true
  }
}
</script>
<style scoped lang="scss">

</style>
