<template>
  <section class="notification">
    <div class="notif-content" :class="cssClass">
      <span class="username">{{ dataCall.username }}</span>
      <img :src="getAvatar" class="avatar" alt="" />
      <span class="price" v-if="isFollower">{{
        $t("chats.video_chat.notification.price", {
          price: $currency(dataCall.price)
        })
      }}</span>
      <div class="call-buttons">
        <icon-button
          class="btn-call "
          type="call"
          :size="55"
          disabledEffectAction
          disabledDarkTheme
          @handler="answerCall"
        />

        <icon-button
          class="btn-end-call"
          type="end-call"
          :size="55"
          disabledEffectAction
          disabledDarkTheme
          @handler="hang"
        />
      </div>
    </div>
    <audio
      v-show="false"
      controls
      autoplay
      loop
      :src="getRingtones"
      ref="audioNotifications"
      @click="playRingtones"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ChatActions, AppActions } from '@/store'
import IconButton from '@/components/chat/component/IconButton'
import VideoChatApi from '@/api/conversations/VideoChatApi'

export default {
  name: 'NotificationsCall',
  components: { IconButton },
  data () {
    return {
      currency: 'USD',
      price: 0,
      username: 'follower',
      videochatId: 817
    }
  },
  computed: {
    ...mapState('application', ['isMobile', 'dataCall']),
    ...mapState('profile', ['isFollower']),
    cssClass () {
      return {
        dark: this.$vuetify.theme.dark
      }
    },
    getRingtones () {
      return require('@/components/chat/assets/audio/ringtones1.mp3')
    },
    getAvatar () {
      return this.dataCall.avatar || '/images/no_content.png'
    }
  },
  mounted () {
    this.$refs.audioNotifications.click()
  },
  methods: {
    playRingtones () {
      this.$refs.audioNotifications.play()
    },
    answerCall () {
      this.$store.dispatch(AppActions.ShowNotificationsCall, false)
      this.$store.dispatch(ChatActions.ShowChatNew, false)
      this.$store.dispatch(ChatActions.ShowChatSetting, false)
      this.$store.dispatch(ChatActions.ShowVideoChat, true)
      this.$router.push({
        name: 'messages',
        query: { username: this.dataCall.username, call: true }
      })
    },
    async hang () {
      this.$store.dispatch(AppActions.ShowNotificationsCall, false)
      await VideoChatApi.rejectCall(this.dataCall.username)
    }
  }
}
</script>

<style lang="scss" scoped>
.username {
  padding: 0 10px;
  font-weight: bold;
}
.price {
  font-size: 15px;
  padding: 0 10px;
  color: red;
  margin: 5px 0 0 0;
  background-color: pink;
}
.avatar {
  border-radius: 66px;
  height: 130px;
  width: 130px;
  object-fit: cover;
}
.notification {
  position: fixed;
  width: 100% !important;
  top: 7%;
  display: flex;
  z-index: 13;
  justify-content: flex-end;
  padding-right: 15px;
  .notif-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    /* flex-direction: row; */
    text-align: center;
    background: white;
    border: solid 0.5px gray;
    /* height: 60px; */
    padding: 5px 10px;
    width: fit-content;
    border-radius: 25px;
    &.dark {
      background-color: #272727 !important;
    }
  }
}
/* .btn-call {
  animation: shake 0.8s infinite;
}
@keyframes shake {
  0%,
  30%,
  60%,
  90% {
    transform: rotate(0deg);
  }
  10%,
  40%,
  70%,
  100% {
    transform: rotate(-5deg);
  }
  20%,
  50%,
  80% {
    transform: rotate(5deg);
  }
} */
.btn-end-call {
  z-index: 10;
}

.call-buttons {
  margin-top: 10px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
}
</style>
