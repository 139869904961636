import { mapState } from 'vuex'

export default (resource, api) => {
  return {
    computed: {
      ...mapState('session', ['authenticated']),
      likeColorClass () {
        return this[resource].liked ? 'pink' : ''
      },
      saveColorClass () {
        return this[resource].saved ? 'deep-purple' : ''
      },
      sharedColorClass () {
        return this[resource].shared ? 'blue' : ''
      }
    },
    methods: {
      async postAction (prop, method) {
        if (!this.authenticated) {
          return
        }
        const value = this[resource][prop]
        const action = value ? `un${method}` : method
        if (!action) {
          return
        }
        this[resource][prop] = !value

        const { data, error } = await api[action](this[resource].id)
        if (error) {
          this[resource][prop] = value
        }
        this.$eventBus.publish(`${resource}-updated`, this[resource])
        this.afterPostAction(!error, prop, method, data[prop])
      },

      toogleSave () {
        this.postAction('saved', 'save')
      },

      toogleLike () {
        this.postAction('liked', 'like')
      },

      toogleShare () {
        this.postAction('shared', 'share')
      },

      afterPostAction () {

      }
    }
  }
}
