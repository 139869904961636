<template>
  <div v-if="product.purchases.length"
    class="title-text-3 roboto">
    <h2>{{ $t(`products.purchases`) }}</h2>
    <v-row
      v-for="purchase, index in product.purchases"
      :key="`purchase-${index}`"
      align="center">
      <v-col class="flex-grow-1">
        {{ purchase.ref }} - {{ $date(purchase.date) }}
      </v-col>
      <v-col cols="auto">
        <DeliveryForm v-if="!purchase.completed"
          :product="product"
          :purchase="purchase" />
      </v-col>
      <v-col cols="auto">
        <c-btn small @click="selectPurchase(purchase)">{{ $t('actions.show') }}</c-btn>
      </v-col>
    </v-row>
    <c-dialog v-model="dialog">
      <PurchaseInfo
        :product="product"
        :purchase="selected" />
    </c-dialog>
  </div>
</template>
<script>
import PurchaseInfo from './PurchaseInfo.vue'
import DeliveryForm from './DeliveryForm.vue'
import dialog from '@/components/mixins/dialog'

export default {
  name: 'PurchaseList',
  components: { PurchaseInfo, DeliveryForm },
  mixins: [dialog],
  props: {
    product: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    selectPurchase(acq) {
      this.selected = acq
      this.showDialog()
    }
  }
}
</script>
