<template>
  <section v-if="show" class="background-modals show">
    <div class="sombra" :class="classModals">
      <section class="head-modals rows">
        <div class="cols">
          <IconButton :size="15" type="close" @handler="onClose" />
        </div>
      </section>
      <span class="rows title"
        ><h1>{{ title.toUpperCase() }}</h1></span
      >
      <section class="body-modals" :class="classModalsBoddy">
        <slot name="bodyDialog" />
      </section>
      <section
        v-if="showBotton"
        class="footer-modals"
        :class="classModalsFooter"
      >
        <Button
          v-if="!hidenButton"
          :text="textButAcept"
          :color="colorButtonOK"
          @handler="onAcept"
        />
        <Button
          v-if="!hideCancelButton"
          :text="textButCancel"
          :color="colorButtonCancel"
          @handler="onClose"
        />
      </section>
    </div>
  </section>
</template>
<script>
import IconButton from './IconButton.vue'
import Button from './Buttons.vue'
export default {
  name: 'Dialog',
  components: {
    IconButton,
    Button
  },
  props: {
    show: { type: Boolean, default: false },
    modeMobile: { type: Boolean, default: false },
    showBotton: { type: Boolean, default: false },
    hide: { type: Function },
    title: { type: String, default: '' },
    textButCancel: { type: String, default: 'Cancelar' },
    textButAcept: { type: String, default: 'Aceptar' },
    colorButtonCancel: { type: String, default: '#f44336' },
    colorButtonOK: { type: String, default: '#4fbf39' },
    hidenButton: { type: Boolean, default: false },
    hideCancelButton: { type: Boolean, default: false },
  },
  computed: {
    classModals () {
      return {
        dark: this.$vuetify.theme.dark,
        'mode-mobile': this.modeMobile,
        modals: !this.modeMobile
      }
    },
    classModalsBoddy () {
      return {
        'body-modals-mobile': this.modeMobile
      }
    },
    classModalsFooter () {
      return {
        'footer-modals-mobile': this.modeMobile
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    onClose () {
      this.$emit('close-dialog')
       this.$emit('hide')
    },
    onAcept () {
      this.$emit('acept-dialog')
      this.$emit('hide')
    }
  }
}
</script>

<style lang="scss" scoped>
.background-modals {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  z-index: 11000;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.modals {
  width: 500px;
  max-width: 85%;
  background: #fff;
  position: absolute;
  z-index: 12000;
  overflow: hidden;
  border-radius: 8px;
  &.dark {
    background-color: #272727 !important;
    border: 0.2px solid #fff;
  }
}
.head-modals {
  margin: auto;
  margin-top: 6px;
  margin-bottom: 6px;
  flex-direction: row-reverse !important;
}
.title {
  font-size: 1.5em;
  margin: 0 auto;
  padding: 0 10px;
  display: block !important;
  text-align: center;
  float: none;
}
.body-modals {
  padding: 1% 3%;
  margin: auto;
  font-size: 0.9em;
  line-height: 1.4em;
  text-align: left;
}
.body-modals-mobile {
  padding: 0;
  min-height: 80%;
}
.footer-modals-mobile {
  position: absolute;
  bottom: 0;
  width: inherit;
  min-width: 2rem !important;
}
.footer-modals {
  margin: auto;
  display: flex;
  margin-top: 2rem;
  min-width: 40rem;
  justify-content: center;
  padding: 10px;
}
.button {
  margin: 0 2%;
}
.mode-mobile {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 12000;
  background: #fff;
  overflow: auto;
  &.dark {
    background-color: #272727 !important;
    border: 0.2px solid #fff;
  }
}
</style>
