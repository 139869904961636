<template>
  <v-menu
    v-model="showPicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field outlined
                    v-bind="$attrs"
                    readonly
                    :value="formattedDate"
                    :label="inputLabel"
                    :hide-details="hideDetails"
                    clearable
                    append-icon="event"
                    v-on="on"
                    @click:clear="clearDates">
      </v-text-field>
    </template>
    <v-date-picker
      no-title
      :persistent-hint="persistentHint"
      :type="type"
      :range="range"
      :min="min"
      :max="max"
      v-model="pickerDates"
      @input="onSelectedDate">
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: 'DatePickerInput',
  props: {
    value: [Object, String, Date, Array],
    min: String,
    max: String,
    range: Boolean,
    type: String,
    label: String,
    noLabel: Boolean,
    firstDayOfWeek: Number,
    hideDetails: Boolean,
    persistentHint: Boolean
  },

  data() {
    return {
      showPicker: false,
      internalDates: this.value || this.emptyValue(),
      pickerDates: this.value
    }
  },

  computed: {
    inputLabel() {
      if (this.noLabel) {
        return ''
      }
      return this.label || this.$t('types.date.name')
    },

    getLocale() {
      return this.firstDayOfWeek || 0
    },

    formattedDate() {
      let values = this.internalDates
      if (!this.range) {
        values = [values]
      }
      const dates = values.map(d => this.$date(d))
      return dates.join(' - ')
    }
  },

  watch: {
    value(dates) {
      this.internalDates = dates
    },

    showPicker(value) {
      if (!value) {
        this.pickerDates = this.internalDates
      }
    }
  },

  methods: {
    emptyValue() {
      return this.range ? [] : ''
    },

    onSelectedDate(value) {
      this.showPicker = this.range && value.length !== 2
      if (!this.showPicker) {
        this.changeValue(value)
      }
    },

    clearDates() {
      this.pickerDates = this.emptyValue()
      this.changeValue(this.emptyValue())
    },

    changeValue(value) {
      this.internalDates = value
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }
}
</script>
