<template>
  <section>
    <div class="center" :style="{display: isMobile ? 'grid' : 'flex'}">
      <img src="/images/logo_horizontal_white_color.png" height="32" width="211" alt="bucharest_summit" />
      <span class="cross center">X</span>
      <img src="./resources/bucharest-summit.png" height="64" width="211" alt="bucharest_summit" />
    </div>
    <div class="col center" style="margin: 24px 0;">
      <img src="./resources/bsummit-separator.png" height="16" width="160" alt="bsummit_separator" />
    </div>

    <div class="col center">
      <span class="free-bonus">
        <span class="text-green">FREE</span> 500€ BONUS!!
      </span>
    </div>

    <div class="mx-auto" :class="`col-${isMobile ? 11 : 8}`" :style="{marginTop: isMobile ? '0px' : '32px' }">
      <SignUpPromos promo="BSUMMIT"></SignUpPromos>
    </div>

  </section>
</template>

<script>
import SignUpPromos from './SignUpPromos'
import { mapState } from 'vuex'

export default {
  name: 'bsummit',
  components: { SignUpPromos },
  computed: {
    ...mapState('application', ['isMobile', 'darkTheme']),
  }
}
</script>

<style lang="scss" scoped>

  .cross {
    margin-left: 32px;
    margin-right: 32px;
    color: white;
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
  }

  .free-bonus {
    font: normal normal bold 72px/72px Montserrat;
    color: white;
    .text-green {
      color: #88FF00;
    }
  }

  @media (max-width: 760px) {
    .free-bonus {
      font: normal normal bold 60px/72px Montserrat;
      color: white;
      text-align: center;
      .text-green {
        color: #88FF00;
      }
    }
  }

  @media (max-width: 430px) {
    .free-bonus {
      font: normal normal bold 50px/72px Montserrat;
      color: white;
      text-align: center;
      .text-green {
        color: #88FF00;
      }
    }
  }
</style>
