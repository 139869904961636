<template>
  <section>
    <ProductActions :product="product">
      <currency :price="product.price"></currency>
      <div v-if="product.shippingCost"
        class="normal-text small-text">
        {{ $t('products.buy_product.shipping_cost_description', { price: shippingCostPrice })}}
      </div>
    </ProductActions>
    <v-row class="title-text-3 roboto semibold" v-if="hasBeenAcquired">
      <v-col cols="12">
        <span class="upper">{{ acquiredText }}</span>
      </v-col>
    </v-row>
    <div v-if="isCountryForbidden">
      {{ $t('products.forbidden_country') }}
    </div>
    <PaymentButton v-if="isPurchasable"
      block
      :product="product" />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import ProductActions from '@/components/products/ProductActions'
import PaymentButton from './payment/PaymentButton'

export default {
  name: 'SaleDescription',
  components: { ProductActions, PaymentButton },
  props: {
    product: Object
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    author () {
      return this.product.author
    },
    isCountryForbidden () {
      return !this.isPublisher && this.product.countryForbidden
    },
    isPublisher () {
      return this.author.username === this.currentUser.username
    },
    isPurchasable () {
      return !this.product.countryForbidden && !this.isPublisher && (this.product.infiniteStock || !this.product.acquired) 
    },
    hasBeenAcquired () {
      return !!this.product.purchases.length || !!this.product.sales.length
    },
    acquiredText () {
      const key = this.product.purchases.length ? 'user_acquired' : 'acquired'
      return this.$t(`products.sale.${key}`)
    },
    shippingCostPrice () {
      return this.$currency(this.product.shippingCost)
    }
  }
}
</script>
