import api from '@/api/Api'

export default {

  list (query) {
    return api.get('/admin/posts', query)
  },

  update (post) {
    return api.put(`/admin/posts/${post.id}`, post)
  },

  updatePromo (post) {
    return api.put('/admin/posts/promotionSelected', post)
  }

}
