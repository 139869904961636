<template>
  <v-card flat tile
    class="pa-2"
    :class="{marginMobile: isMobile}"
    v-bind="$attrs">
    <ProductAuthor :product="product"
      @edit="showDialog" />
    <Swiper
      preview-height="500"
      :resources="product.resources" />
    <ProductComments :product="product" />
    <c-dialog v-model="dialog"
      :closable="false"
      :actions="false">
      <template slot="title">
        <h1>{{ $t('products.edit.title') }}</h1>
      </template>
      <EditProduct
        ref="editForm"
        :product="product"
        @close="hideDialog"
        @submit="hideDialog" />
    </c-dialog>
  </v-card>
</template>
<script>
import dialog from '@/components/mixins/dialog'
import ProductAuthor from './ProductAuthor.vue'
import ProductComments from './ProductComments.vue'
import EditProduct from './EditForm.vue'
import {mapState} from "vuex";

export default {
  name: 'ProductPage',
  mixins: [dialog],
  components: { ProductAuthor, ProductComments, EditProduct },
  props: {
    product: Object
  },
  computed: {
    ...mapState('application', ['isMobile']),
  }
}
</script>
<style lang="scss" scoped>

.marginMobile {
  margin-bottom: 50px !important;
}

</style>
