<template>
  <v-form ref="form" v-model="validForm">
    <v-card-text style="margin-top: 25px">
      <h3>{{ $t('withdraws.withdraw_methods.types.e_pay_services') }}</h3>
      <v-card-text>
        <v-text-field outlined
                      :label="$t('models.withdraw_method.e_pay_account')"
                      type="text"
                      v-model="accountId"
                      :rules="[$vRequired]"></v-text-field>
      </v-card-text>
      <v-row justify="end">
        <v-col cols="auto">
          <c-btn
            text color="secondary"
            @click="cancel">
            {{ $t('actions.cancel')}}
          </c-btn>
        </v-col>
        <v-col cols="auto">
          <c-btn
            :disabled="!validForm"
            @click="submit">
            {{ $t('actions.save')}}
          </c-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import { WithdrawActions } from '@/store'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'EPayMethodForm',
  mixins: [validatable('form')],
  props: {
    method: { type: Object, default: null },
    dark: { type: Boolean, default: null }
  },
  data () {
    return {
      currency: null,
      currencyOptions: ['EUR', 'USD'],
      accountId: null
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    methodAttrs () {
      return {
        type: 'e_pay_services',
        accountId: this.accountId,
        withdrawMethodApproved: true
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    onSubmit () {
      this.$store.dispatch(WithdrawActions.CreateMethod, this.methodAttrs)
      this.$emit('submitted')
    }
  },
  mounted () {
    if (!this.method) {
      return
    }
    this.accountId = this.method.accountId
    this.currency = this.method.currency
  }
}
</script>
