<template>
  <section>
    <div class="title-row border-bottom pa-2">
    </div>
    <p class="pa-2 mt-2 bold">{{ $t('products.description') }}</p>
    <component :is="description2" />
    <v-divider class="my-5"></v-divider>
    <div class="pa-2">
      <p>{{ $t(`products.delivery.description.${product.deliveryType}`) }}</p>
      <p v-if="isPacket && !product.shippingCost">
        {{ $t(`products.delivery.description.free_shipping`) }}
      </p>
    </div>
    <v-divider class="my-2"></v-divider>
    <div class="pa-1 mb-4">
      <SaleDescription v-if="isSale"
        :product="product"
        @payment-success="$emit('payment-success')" />
      <AuctionDescription v-else
        :product="product"
        @payment-success="$emit('payment-success')" />
      <SaleList :product="product" />
      <PurchaseList :product="product" />
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import AuctionDescription from '@/components/products/AuctionDescription'
import SaleDescription from '@/components/products/SaleDescription'
import SaleList from '@/components/products/acquisitions/SaleList'
import PurchaseList from '@/components/products/acquisitions/PurchaseList'
import { replaceMention } from '@/components/scripts/ReplaceMentions.js'
import project from '@/project'

export default {
  name: 'ProductDescription',
  components: { SaleDescription, AuctionDescription, SaleList, PurchaseList },
  props: {
    product: { type: Object, default: () => ({}) }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isFollower']),
    author () {
      return this.product.author || {}
    },
    description () {
      const desc = this.product && this.product.description
      return replaceMention(desc, project.project, this.product.author.username)
    },
    description2 () {
      return { template: `<div class="pa-2">${this.description}</div>` }
    },
    acquisitions () {
      return this.product.acquisitions || []
    },
    acquisition () {
      return this.product.acquisition || {}
    },
    hasAcquisitions () {
      return !!(this.product.acquisitions || this.product.acquisition)
    },
    isPublisher () {
      return this.author.username === this.currentUser.username
    },
    isSale () {
      return this.product.productType === 'sale'
    },
    isAuction () {
      return !this.isSale
    },
    isPacket () {
      return this.product.deliveryType === 'packet'
    }
  }
}
</script>
<style lang="scss" scoped>
.title-row {
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  display: flex;
  align-items: center;
}

  .dark {
    background-color: black !important;
    color: #b92e50 !important;
  }
</style>
