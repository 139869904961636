<template>
  <section>
    <v-form v-model="validForm"  ref="form"
      @submit.prevent="submit">
      <ListViewTitle
        :title="$t('affiliate_preferences.title')"
        icon="monetization_on"
      />
      <v-row no-gutters>
        <v-col cols="12" v-if="isInfluencer">
          <v-checkbox color="primary"
            hide-details
            :label="$t('affiliate_preferences.enable_affiliation')"
            v-model="config.affiliationEnabled"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <div class="px-2 small-text">{{ $t('affiliate_preferences.description') }}</div>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <c-btn @click.stop.prevent="submit">{{ $t('actions.save') }}</c-btn>
      </v-card-actions>
    </v-form>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import PreferencesApi from '@/api/PreferencesApi'
import validatable from '@/components/mixins/validatable'
import messages from '../locales/all.json'

export default {
  name: 'MailPreferencesForm',
  mixins: [validatable('form')],
  i18n: { messages },
  data () {
    return {
      config: {}
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer'])
  },
  watch: {
    currentUser (user) {
      this.config = { ...user.affiliatePreferences }
    }
  },
  methods: {
    async onSubmit () {
      this.loading = true
      await PreferencesApi.updateAffiliatePreferences(this.config)
      this.loading = false
    }
  },
  mounted () {
    this.config = { ...this.currentUser.mailPreferences }
    this.$store.dispatch(ProfileActions.Fetch)
  }
}
</script>
