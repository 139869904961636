<template>
  <section>
   
    <div v-if="!isVisible" class="payment">
      <FilePayment :message="message" @refresh-message="emitRefreshMesage" />
    </div>
     
    <div
      class="resource-contain"
      v-else
      v-for="(f, id) in message.attachments.slice(0, 1)"
      :key="id"
      @click="selectedResource(id)"
    >
      <!-- <Box ">
        <img
          id="imgRes"
          :src="f.url"
          class="message-img"
          alt=""
          :class="resourceClass"
        />
      </Box> -->

      <audio
        v-if="f.type === 'audio' && message.attachments.length === 1"
        controls
        :src="f.url"
        class="message-audio"
        :class="resourceClass"
      />
      <MessageRowResourcePlus
        :class="resourceClass"
        :attachments="message.attachments"
        v-else
      />
      <!--  <video
        v-if="f.type === 'video'"
        :src="f.url"
        class="message-video"
      ></video> -->
    </div>
    <div
      class="rows purchased"
      :class="{ 'with-attachments': withAttachments }"
      v-if="sale"
    >
      <p>{{ $currency(countSale) }}</p>
      <p class="check my-auto" :class="{ checkSale: checkSale }">✓</p>
    </div>
    <div v-else class="spacer"></div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import IconButton from '@/components/chat/component/IconButton'
import { PostActions, ChatActions } from '@/store'
import FilePayment from '../components/viewFiles/FilePayment'
import PaymentButton from '@/components/payments/PaymentButton'
import MessageRowResourcePlus from '@/components/chat/components/MessageRowResourcePlus'

export default {
  name: 'MessageRowResource',
  components: {
    PaymentButton,
    FilePayment,
    IconButton,
    MessageRowResourcePlus,
  },
  props: {
    message: { type: Object }
  },
  data () {
    return {
      reference: null,
      loading: false,
      checkSale: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer', 'idAdmin']),
    ...mapState('chats', ['selectedChat']),
    isAuthor () {
      return this.currentUser.username === this.message.is
    },
    countSale () {
      let count = 0
      const price = this.message.price
      this.message.receivers.forEach(r => {
        if (r.selled && price > 0) {
          count += price
          this.checkSale = true
        }
      })
      if (count == 0) {
        return (count = price)
      }
      return count
    },
    sale () {
      return this.message.price > 0 && this.isAutorId
    },
    isVisible () {
      return this.message && (!this.message.price || this.message.isPurchased)
    },
    isAutorId () {
      return this.currentUser.id == this.message.userId
    },
    withAttachments () {
      return this.message.attachments.length > 1
    },
    isPurchased () {
      return (
        this.isAutorId &&
        this.message &&
        this.message.isPurchased &&
        this.message.price > 0
      )
    },
    rowClass () {
      return { 'flex-row-reverse': this.isAuthor }
    },
    hasMoney () {
      return this.message.price > 0
    },

    hasReference () {
      return this.message.resource
    },
    resourceClass () {
      return {
        'no-visible': !this.isVisible,
        multi: this.message.attachments.length > 1
      }
    },
    image () {
      if (!this.reference) {
        return '/images/no_content.png'
      }
      const { preview } = this.reference
      if (preview.type === 'image') {
        return preview.preview
      }
      return preview.cover
    }
  },
  methods: {
    async loadReference () {
      if (!this.hasReference) {
        return
      }
      this.loading = true
      this.reference = await this.$store.dispatch(PostActions.Fetch, {
        id: this.message.resourceId,
        force: true
      })
      this.loading = false
      this.$emit('resource-loaded')
    },
    selectedResource (index = 0) {
      this.$store.dispatch(
        ChatActions.CarouselItemsList,
        this.message.attachments
      )
      this.$store.dispatch(ChatActions.CarouselItemSelected, index)
      this.$store.dispatch(ChatActions.ShowCarousel, true)
    },
    goToResource () {
      if (!this.hasReference) {
        return
      }
      if (this.isVisible) {
        this.$router.push({ name: 'post', params: { uuid: this.reference.id } })
      }
    },
    emitRefreshMesage (message) {
      this.$emit('refresh-message', message)
    }
  },
  mounted () {
    this.loadReference()
  }
}
</script>
<style lang="scss" scoped>
::v-deep .overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  &.no-visible {
    ::v-deep .v-image__image {
      filter: blur(5px);
    }
  }
}

.message-audio {
  display: flow-root;
  max-width: 100%;
}

.payment {
  height: 150px !important;
  width: 150px !important;
}

.resource-contain {
  max-width: 360px;
  padding: 5px;
}
.purchased {
  margin-top: 3px;
  display: flex;
  color: dimgrey;
  justify-content: flex-end;
}
.with-attachments {
  margin-top: 5px;
  margin-bottom: 3px;
}
.check {
  font-size: 0.7em;
  margin-left: 5px;
  font-weight: bold;
}
.checkSale {
  color: #31ae00;
}
</style>
