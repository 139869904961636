<template>
  <c-dialog persistent
    width="684"
    class="publication-dialog"
    :actions="false"
    v-bind:value="value"
    @input="close">
    <template #title>
      <h1 class="semibold">{{ $t('subscriptions.config.title') }}</h1>
    </template>
    <v-tabs v-model="selectedTab" class="mb-2">
      <v-tab v-for="tab in tabs"
        :key="`tab-${tab.key}`">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item v-for="tab in tabs"
        :key="`component-${tab.key}`">
        <component :is="tab.component"
          @cancel="close"
          @submitted="submitted" />
      </v-tab-item>
    </v-tabs-items>
  </c-dialog>
</template>
<script>
import OffersForm from './OffersForm'
import TrialForm from './TrialForm.vue'
import messages from '../locales/all.json'

export default {
  name: 'SubscriptionConfigDialog',
  components: { OffersForm, TrialForm },
  i18n: { messages },
  props: {
    value: Boolean,
    tab: { type: String, default: 'offers' }
  },
  data () {
    return {
      selectedTab: 0,
    }
  },
  computed: {
    tabs() {
      return [
        { key: 'offers', name: this.$t('subscriptions.offers.title'), component: OffersForm },
        { key: 'trials', name: this.$t('subscriptions.trials.title'), component: TrialForm }
      ]
    }
  },
  watch: {
    tab (value) {
      this.selectTab()
    }
  },
  methods: {
    selectTab () {
      let tab = this.tabs.findIndex(t => t.key === this.tab)
      if (tab === -1) {
        tab = 0
      }
      this.selectedTab = tab
    },
    submitted (data) {
      this.$emit('submitted', data)
      this.close()
    },
    close () {
      this.$emit('input', false)
    }
  },
  mounted () {
    this.selectTab()
  }
}
</script>
