<template>
  <v-card flat tile
    class="recommendation-widget">
    <v-card-text>
      <h2>{{ $t("widgets.recommendations.product.title") }}</h2>
    </v-card-text>
    <template v-for="product in products">

      <v-hover v-slot:default="{ hover }"
        :key="product.id"
        class="mb-2 rounded">
        <v-card
          :to="{ name: 'product', params: { uuid: product.id} }"
          :elevation="hover ? 2 : 0">
          <ProductAvatar v-bind="product" />
        </v-card>
      </v-hover>

    </template>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import ProductAvatar from '@/components/products/ProductAvatar'
import ProductApi from '@/api/ProductApi'
import project from '@/project'

export default {
  name: 'ProductRecommendations',
  components: { ProductAvatar },
  props: {
    empty: Boolean
  },
  data () {
    return {
      products: []
    }
  },

  watch: {
    tags() {
      this.loadRecommendations()
    }
  },

  computed: {
    ...mapState('profile', ['currentUser']),
    tags () {
      return this.$store.getters['preferences/selected_tags_ids']
    }
  },
  methods: {
    async loadRecommendations () {
      const tags = this.$store.getters['preferences/selected_tags_ids']
      const { data, error } = await ProductApi.recommendations({
        ...tags, perPage: 3,
      })
      if (!error) {
        this.products = data.data
      }
    }
  },
  async mounted () {
    this.loadRecommendations()
  }
}
</script>
