<template>
  <div>
    <v-col :cols="isMobile ? 12 : 10" style="margin: auto" class="mb-5">
      <Contract no-back style="height: 400px; overflow: auto" noRedirect @signedDocument="$emit('next')"></Contract>
    </v-col>
  </div>
</template>
<script>
import Contract from '@/components/signContract/Contract.vue'
import {mapState} from 'vuex'
import {ProfileActions} from "@/store";

export default {
  name: 'StepFrour',
  components: {Contract},
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser'])
  },
  mounted() {
    this.$gtm('star_registration_step4', undefined, this.currentUser.email)
  }
}
</script>
