<template>
  <v-row>
    <v-col cols="12">
      <c-btn
        css="mb-2"
        to="/">
        {{ $t('actions.back') }}
      </c-btn>
    </v-col>
    <v-col cols="12">
      <span class="title-text">{{ $t('project')}}</span>
    </v-col>
    <v-col cols="12" md="6">
      <v-card>
        <v-card-title>{{ $t('application.imprint') }}</v-card-title>
        <v-card-text>
          <img src="images/artemisa3000.png" style="margin-top: 5px; width: 15rem; height: 1rem" v-if="project.showFooterImages"/>
          <span v-else> Artemisa 3000 Tech Solutions SL</span>
          CIF:<img src="images/cif_white.png" style="margin-top: 3px; margin-right: 3px; margin-left: 3px; width: 6rem; height: 12px" v-if="project.showFooterImages"/>
          <span v-else> B88466743 </span>
          <p>{{ $t('application.company_address') }}</p>
          <p>{{ $t('application.company_city') }}</p>
          <p>{{ $t('application.company_country') }}</p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card>
        <v-card-title>{{ $t('application.contact') }}</v-card-title>
        <v-card-text>
          <p><v-icon class="mr-1">mail_outline</v-icon>{{ $t('application.contact_email') }}</p>
        </v-card-text>
      </v-card>
    </v-col>
    <template v-if="hasPaymentImprint">
      <v-col cols="12">
        <span class="title-text">{{ $t('application.payments_imprint')}}</span>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-text>
            <p><strong>{{ paymentImprint.company }}</strong></p>
            <p>{{ paymentImprint.address }}</p>
            <p>{{ paymentImprint.city }}</p>
            <p>{{ paymentImprint.country }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>{{ $t('application.contact') }}</v-card-title>
          <v-card-text>
            <p><v-icon class="mr-1">phone</v-icon>{{ paymentImprint.contact.phone }}</p>
            <p><v-icon class="mr-1">mail_outline</v-icon>{{ paymentImprint.contact.email }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <div id="imprint">
    </div>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import project from '@/project'

export default {
  name: 'Imprint',
  metaInfo () {
    return {
      title: 'Imprint'
    }
  },

  data () {
    return {
      project
    }
  },

  computed: {
    ...mapState('session', ['authenticated']),
    hasPaymentImprint () {
      return !!this.project.locales.payments_imprint && !!this.project.locales.payments_imprint.address
    },
    paymentImprint () {
      return this.project.locales.payments_imprint
    }
  },
  mounted () {
    if (!project.show_imprint) {
      return
    }
    window.location.href = project.show_imprint
  }
}
</script>
