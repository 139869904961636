<template>
  <section>
    <v-card
      v-for="(offer, index) in reverseOffer"
      :key="`sub-${index}`"
      flat
      class="bordered text-center pointer subscription-type justify-center pa-1 my-1"
      :class="{ selected: isOfferSelected(offer), best: isBestOffer(offer) }"
      @click="selectOffer(offer.type)">
      <div class="d-flex align-center justify-center">
        <div class="monthly-payment mr-1"
          :class="{ mobile: isMobile }">
          {{ $tc('subscriptions.descriptions_2.monthly_payment', offer.months, { amount: offerPrice(offer), months: offer.months }) }}
        </div>
        <v-chip class="unique-payment"
          color="white"
          :outlined="darkTheme">
          {{ $t('subscriptions.descriptions_2.unique_payment', { amount: totalOffer(offer) }) }}
        </v-chip>
      </div>
    </v-card>
    <VatExplain class="mx-auto" :price="vatPrice" />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { UserActions } from '@/store'
import VatExplain from '../../payments/VatExplain.vue'

export default {
  name: 'SubscriptionForm',
  components: { VatExplain },
  props: {
    user: Object,
    value: String
  },
  data () {
    return {
      loading: false,
      offers: [],
      paymentFormUrl: '',
      errorMessage: ''
    }
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    ...mapState('profile', ['currentUser']),
    ...mapState('application', ['isMobile', 'affiliate', 'affiliateSource']),
    selectedOffer () {
      return this.offers.find(o => o.type === this.value)
    },

    vatPrice () {
      if (!this.selectedOffer) {
        return
      }
      const offer = this.selectedOffer
      const vat = (this.currentUser.billing.vat || 22) / 100
      const exes = this.currentUser.billing.exes / 100
      const price = offer.basePrice - ((offer.basePrice * offer.discount) / 100)
      const totalAmount = price * offer.months
      const expenses = totalAmount * exes
      const vatAmount = (totalAmount + expenses) * vat
      return expenses + vatAmount
    },
    reverseOffer () {
      return [...this.offers].reverse()
    }
  },
  methods: {
    selectOffer (type) {
      this.$emit('input', type)
      this.$emit('input-select-price')
    },
    isOfferSelected (offer) {
      return this.offers.length === 1 ? true : this.value === offer.type
    },

    basePrice (offer) {
      return this.$currency(offer.basePrice * offer.months, this.user.currency)
    },

    offerPrice (offer) {
      const price = offer.basePrice - ((offer.basePrice * offer.discount) / 100)
      return this.$currency(price, this.user.currency)
    },

    totalOffer (offer) {
      const price = offer.basePrice - ((offer.basePrice * offer.discount) / 100)
      return this.$currency(price * offer.months, this.user.currency)
    },

    isBestOffer (offer) {
      if (this.offers.length < 2) {
        return true
      }
      return offer === this.getBestOffer()
    },
    getBestOffer () {
      let bestOffer = this.offers[0]
      for (const offer of this.offers) {
        if (offer.discount > bestOffer.discount) {
          bestOffer = offer
        } else if (offer.discount === bestOffer.discount && offer.months > bestOffer.months) {
          bestOffer = offer
        }
      }
      return bestOffer
    },
    async load () {
      this.loading = true
      const user = await this.$store.dispatch(UserActions.Fetch, { username: this.user.username, force: true })
      const enabled = user.subscriptionOffers.filter(o => o.type === 'month' || o.enabled)
      if (!enabled.length || enabled[0].type !== 'month') {
        enabled.splice(0, 0, { type: 'month', basePrice: user.subscriptionPrice, months: 1, discount: 0, enabled: true })
      }
      this.offers = enabled.map(o => ({ ...o, basePrice: user.subscriptionPrice }))
      // this.selectOffer(this.getBestOffer().type)
      this.loading = false
    }
  },
  async mounted () {
    this.offers = [{ type: 'month', basePrice: this.user.subscriptionPrice, months: 1, discount: 0, enabled: true }]
    await this.load()
  }
}
</script>
<style lang="scss" scoped>
@use 'src/scss/colors';
.monthly-payment {
  text-align: right;
  font-size: 20pt;
  width: 60%;
  &.mobile {
    font-size: inherit;
  }
}
.unique-payment {
  text-align: left;
  min-width: 150px;
}
.selected {
  background-color: #35cf95 !important;
  border-radius: 4px !important;
  border: solid 3px #2fa478 !important;
  color: #FFFFFF !important;

  &.dark{
    background-color: #35cf95 !important;
    border-radius: 4px !important;
    border: solid 3px #2fa478 !important;
  }
  .sub-type {
    color: #DF3856;
  }
  .unique-pay {
    color: #DF3856;
  }
  .price {
    color: #DF3856!important;
    .text {
      color: #DF3856;
    }
  }
}

.sub-type {
  font-size: 20px;
  font-family: Montserrat, sans-serif;
  text-align: left;
  color: #df3856;
}

.dark {
  .unique-pay {
    color: #FFFFFF !important;
  }
}

.unique-pay {
  margin-left: 15px;
  font-size: 13px;
  line-height: 16px;
  color: #545454;
}

.price {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;

  .text {
    font-weight: normal;
    font-size: 22px;
  }
}

::v-deep.subscription-type {
  &.selected {
    background: #35cf95 !important;

    .sub-type {
      color: #FFFFFF !important;
    }
    .price {
      color: #FFFFFF !important;
    }
    .text {
      color: #FFFFFF !important;
    }
    .unique-pay {
      color: #FFFFFF !important;
    }

    &.dark {
      background-color: #35cf95 !important;
      border-radius: 4px !important;
      border: solid 3px #2fa478 !important;
      .light-text {
        color: #FFFFFF !important;
      }
    }
  }
  .duration {
    font-size: 20px;
    line-height: 20px;
  }
  .price {
    .price {
      font-size: 30px;
      vertical-align: text-top;
    }
    .point {
      vertical-align: text-bottom;
    }
    .discount {
      font-size: 20px;
      vertical-align: text-top;
    }
  }
  .price-info {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
</style>
