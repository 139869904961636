<template>
  <v-app :class="isDarkTheme" :style="rootStyle">
    <v-navigation-drawer
      v-if="isMobile && $route.fullPath !== '/month_challenge'"
      v-model="drawer"
      style="height: 100%;"
      app
      right
    >
      <AppNavigation  :maintance="maintance" />
    </v-navigation-drawer>
    <app-bar v-if="$route.fullPath !== '/month_challenge'">
      <template #prepend v-if="isMobile">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      </template>
    </app-bar>
    <span v-if="showVideoChat" class="background-call"></span>
    <v-content :class="isDarkTheme">
      <v-container class="app-wrapper">
        <v-row no-gutters class="fill-height">
          <v-col
            v-if="!isMobile && $route.fullPath !== '/month_challenge'"
            cols="2"
          >
            <AppNavigation sticky :maintance="maintance" />
          </v-col>
          <v-col
            :cols="contentCols"
            class="px-2"
            :class="{ 'border-x': !isMobile }"
          >
            <Maintance v-if="maintance" />
            <template v-else>
              <PaymentBanner v-if="alert" :alert="alert" />
              <v-alert
                v-if="needsEmailValidation"
                prominent
                text
                type="info"
                color="blue"
                class="app-fullpage"
              >
                <v-row align="center">
                  <v-col class="grow">{{ $t('email_validation.title') }}</v-col>
                  <v-col class="shrink">
                    <c-btn
                      outlined
                      small
                      color="blue"
                      @click.stop="requestEmail"
                    >
                      {{ $t('actions.request_email') }}
                    </c-btn>
                  </v-col>
                </v-row>
              </v-alert>
              <UserTwitterForm v-if="isTwitterSignup" />
              <keep-alive>
                <router-view v-if="cachePage" :key="$route.fullPath" />
              </keep-alive>
              <router-view
                v-if="!isTwitterSignup && !cachePage"
                :key="$route.fullPath"
              />
            </template>
          </v-col>
          <v-col v-if="showSidebar" cols="3" class="px-2">
            <router-view v-if="!maintance" name="sidebar" />
          </v-col>
        </v-row>
      </v-container>
      <PaymentResponseDialog />
      <v-snackbar v-model="showNotification">
        <v-row align="center">
          <v-icon :color="notification.iconColor" class="mx-2">
            {{ notification.icon }}
          </v-icon>
          {{ notification.message }}
        </v-row>
      </v-snackbar>
      <TagProfileDialog v-if="showTagsDialog" persistent />
      <InstallDialog v-else />
    </v-content>

    <LegalAgeMessage />
    <NotificationAlert v-if="showNewNotification" />
    <NotificationsCall v-if="showNotificationsCall" />
    <ScatRenewModal />
    <BottomNav v-if="isLoverfans"/>

   <!--<PaymentWarning v-if="showWarning" />-->
    <!--cookies--modal></cookies-modal>-->
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import AccountApi from '@/api/AccountApi'
import AppBar from '@/components/layouts/AppBar'
import AppNavigation from '@/components/layouts/AppNavigation'
import UserTwitterForm from '@/components/users/UserTwitterForm'
import TagProfileDialog from '@/components/tags/TagProfileDialog'
import PaymentBanner from '@/components/payments/alerts/PaymentBanner'
import PaymentResponseDialog from '@/components/payments/dialogs/PaymentResponseDialog'
import ScatRenewModal from '@/components/scatModal/ScatRenewModal'
import InstallDialog from '@/components/layouts/InstallDialog'
import LegalAgeMessage from '@/components/layouts/LegalAgeMessage'
import Maintance from '@/views/Maintance'
import BottomNav from '@/components/footer/BottomNav'
import config from '@/project'
import NotificationAlert from '@/components/chat/NotificationAlert'
import NotificationsCall from '@/components/chat/components/videoChat/NotificationsCall'
import cookies from '@/cookies'

export default {
  name: 'App',
  components: {
    NotificationAlert,
    AppBar,
    AppNavigation,
    UserTwitterForm,
    TagProfileDialog,
    PaymentBanner,
    PaymentResponseDialog,
    InstallDialog,
    Maintance,
    LegalAgeMessage,
    ScatRenewModal,
    NotificationsCall,
    BottomNav
  },
  data () {
    return {
      maintance: false,
      drawer: false,
      showNotification: false,
      notification: {},
      paymentSucess: '',
      notifyScat: !!config.enable_scat_modal,
      warning: true,
      creatorMail: config.locales.contact_email_creator,
      financialMail: config.locales.contact_email_financial,
      config
    }
  },
  computed: {
    ...mapState('application', [
      'isLargeScreen',
      'isMobile',
      'showNewNotification',
      'showNotificationsCall'
    ]),
    ...mapState('chats', ['showVideoChat']),
    ...mapState('preferences', ['darkTheme']),
    ...mapState('profile', ['currentUser', 'isInfluencer', 'withdraw']),
    // ...mapState('withdraws', ['withdrawData', 'withdrawMethods']),
    ...mapState('session', ['authenticated']),
    theme () {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    rootStyle () {
      return {
        background: this.$vuetify.theme.themes[this.theme].background
      }
    },
    alert () {
      const alerts = this.currentUser && this.currentUser.alerts
      return alerts && alerts.hasUncompletedPayments[0]
    },
    cachePage () {
      return this.$route.meta.cache
    },
    contentStyle () {
      if (!this.showSidebar) {
        return 'max-width: 100%;'
      }
      return ''
    },

    availableSections () {
      return this.items.filter(
        item =>
          !item.profile ||
          (this.currentUser && item.profile === this.currentUser.role)
      )
    },

    hasSidebar () {
      var hasSideBar = this.$route.matched[0].meta.sidebar

      if (hasSideBar) {
        hasSideBar = !this.$route.matched.find(m => m.meta.sidebar === false)
      }

      return hasSideBar
    },

    contentCols () {
      return this.isMobile
        ? 12
        : this.showSidebar || this.maintance
          ? 7
          : this.$route.fullPath !== '/month_challenge'
            ? 10
            : 12
    },

    showSidebar () {
      return this.isLargeScreen && this.hasSidebar
    },

    showTagsDialog () {
      if (!this.isInfluencer) return false
      if (!this.currentUser.tags) return true
      return this.currentUser.tags.length < 3
    },

    isTwitterSignup () {
      return this.currentUser.isTwitterSignup
    },

    needsEmailValidation () {
      return (
        !this.isTwitterSignup &&
        this.$route.meta.validated !== false &&
        this.currentUser.email &&
        !this.currentUser.emailValidated
      )
    },
    changeTheme () {
      return {
        dark: this.theme
      }
    },

    isDarkTheme () {
      return {
        'app-page': !this.darkTheme,
        'app-page-dark':
          this.darkTheme || this.$route.fullPath === '/month_challenge',
        'app-bar-dark': this.darkTheme
      }
    },
    isEurope () {
      const bankCountry = this.withdrawMethods.length > 0 ? (this.withdrawMethods[0].info ? this.withdrawMethods[0].info.bankCountry : '') : ''
      const country = this.$t('countries_v2').filter(c => (c.value === bankCountry || c.value3 === bankCountry))
      return country.length > 0 ? country[0].region.toLowerCase() === 'europe' || country[0].region.toLowerCase() === 'europa' : false
    },
    isWireTransfer () {
      return this.withdrawMethods.length > 0 ? this.withdrawMethods[0].type === 'bank' : false
    },
    isPaypal () {
      const method = this.currentUser.withdrawMethod
      return method && method.type === 'paypal'
    },
    showWarning () {
      const dontShowAgain = cookies.get('notShowPaypal2023')
      return this.isPaypal && !dontShowAgain
    },
    isLoverfans () {
      return config.project === 'loverfans'
    }
  },
  methods: {
    async requestEmail () {
      const { error } = await AccountApi.requestValidation()
      const notification = {
        error: false,
        message: this.$i18n.t('snackbar.email_sent_success')
      }
      if (error) {
        notification.error = true
        notification.message = this.$i18n.t('snackbar.email_sent_failed')
      }
      this.$eventBus.publish('notification', notification)
    }
  },

  async mounted () {
    this.$vuetify.theme.dark = this.darkTheme
    this.$eventBus.subscribe('notification', notification => {
      if (notification.error) {
        notification.icon = 'clear'
        notification.iconColor = 'error'
      } else {
        notification.icon = 'check'
        notification.iconColor = 'success'
      }
      this.notification = notification
      this.showNotification = true
    })
    /* if (this.authenticated) {
      this.$store.dispatch(WithdrawActions.FetchData);
      this.$store.dispatch(WithdrawActions.FetchMethods);
    }
    */
  }

}
</script>
<style lang="scss" scoped>
.background-call {
  z-index: 10;
  color: blue;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0 0 0 / 40%);
}
</style>
