import cookies from '@/cookies'
import store, {AppActions} from "@/store";

const getQueryParamValue = (url, param) => {
  const params = new URLSearchParams(url.slice(url.indexOf('?') + 1));
  return params.get(param);
}

export default async (to) => {
  const queryParams = to.query;
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

  if (queryParams.ref) {
    store.dispatch(AppActions.UpdateReferral, queryParams.ref)
  }
  for (const param of utmParams) {
    if (queryParams[param]) {
      cookies.expiration(param, getQueryParamValue(to.fullPath, param), 1)
    }
  }
}
