import i18n from '@/i18n'
import {existBannedWords} from '@/plugins/form_validations'
import config from '@/project'
import cookies from '@/cookies'

const forbWord = [
  'scat',
  'poo',
  'vomit',
  'vomitar',
  'vomito',
  'puke',
  'puking',
  'shit',
  'poop',
  'farts',
  'pooping',
  'smearing',
  'toilet',
  'diapers',
  'piss',
  'enema',
  'mierda',
  'caca',
  'caquita',
  'pedo',
  'cagar',
  'baño',
  'pañales',
  'mear',
  'pis',
  'enema']

const padTo = (elem, count, char) => ('' + elem).padStart(count, char)

const imageTypes = ['webp', 'jpg', 'jpeg', 'png', 'gif']
const videoTypes = ['m3u8', 'mov', 'mpg', 'mpeg', 'mp4', 'wmv', 'avi', 'webm', 'quicktime']
const audioTypes = ['mp3', 'wav', 'mpeg', 'm4a', 'ogg']

const Utils = {
  extractFilename: (name) => {
    const parts = name.split('/')
    const filename = parts[parts.length - 1]
    const extIndex = filename.lastIndexOf('.')
    if (extIndex === -1) {
      return filename
    }

    return name.substring(0, extIndex)
  },
  extractExtension: (name) => {
    const extIndex = name.lastIndexOf('.')
    if (extIndex === -1) {
      return false
    }

    return name.substring(extIndex).slice(1).toLowerCase()
  },
  isGif(name) {
    return Utils.extractExtension(name) === 'gif'
  },
  isImage(name) {
    return imageTypes.includes(Utils.extractExtension(name))
  },
  isAudio(name) {
    return audioTypes.includes(Utils.extractExtension(name))
  },
  isVideo(name) {
    return videoTypes.includes(Utils.extractExtension(name))
  },
  formatNumber: (number, decimals) => Utils.maskNumber(number, decimals || 0, ''),
  maskNumber: (value, decimals, symbol) => {
    if (decimals !== void (0)) {
      var exp = Math.pow(10, decimals)
      value = value.toFixed(Math.round(decimals * exp) / exp)
    } else {
      value = value.toString()
    }
    value = value.replace('.', ',')
    while (true) {
      var valueSep = value.replace(/(\d)(\d{3})($|,|\.)/g, '$1.$2$3')

      if (value === valueSep) {
        break
      }

      value = valueSep
    }

    return value + ' ' + symbol
  },
  formatUTCDate(date, format) {
    if (!date) {
      return ''
    }
    const dateObject = new Date(date)
    const dateFormat = format || i18n.t('types.date.format')
    return dateFormat
      .replace('YYYY', dateObject.getFullYear())
      .replace('MM', padTo(dateObject.getMonth() + 1, 2, '0'))
      .replace('DD', padTo(dateObject.getUTCDate(), 2, '0'))
  },
  formatDate(date, format) {
    if (!date) {
      return ''
    }
    const dateObject = new Date(date)
    const dateFormat = format || i18n.t('types.date.format')
    return dateFormat
      .replace('YYYY', dateObject.getFullYear())
      .replace('MM', padTo(dateObject.getMonth() + 1, 2, '0'))
      .replace('DD', padTo(dateObject.getDate(), 2, '0'))
  },
  formatDateHours(date, format) {
    if (!date) {
      return ''
    }
    const dateObject = new Date(date)
    const dateFormat = format || i18n.t('types.datetime.format')
    return dateFormat
      .replace('HH', dateObject.getHours() + 1, 2, '0')
      .replace('MM', padTo(dateObject.getMinutes() + 1, 2, '0'))
  },
  formatCurrency(amount, cents = true) {
    const newAmount = cents ? Utils.formatNumber(parseInt(amount, 10) / 100, 2) : amount
    return i18n.t('types.currency', {amount: newAmount})
  },
  capitalize(value) {
    const lower = value.toLowerCase()
    return value.charAt(0).toUpperCase() + lower.slice(1)
  },
  getUrlParameter(url, parameter) {
    const parameters = new URL(url).searchParams
    return parameters.get(parameter)
  },
  structuredUtmObj() {
    return {
      utm_source: cookies.get('utm_source'),
      utm_medium: cookies.get('utm_medium'),
      utm_campaign: cookies.get('utm_campaign'),
      utm_term: cookies.get('utm_term'),
      utm_content: cookies.get('utm_content')
    }
  },
  bannedWords(search) {
    let bannedWord = false
    const text = search.split(' ')
    if (config.project === 'loverfans') {
      text.forEach((word) => {
        if (forbWord.includes(word.toLowerCase())) {
          bannedWord = true
        }
      })
    }
    if (existBannedWords(search.toLowerCase())) bannedWord = true
    return bannedWord
  },
  validValue(v) {
    return v !== undefined && v !== null
  }
}

export default Utils
