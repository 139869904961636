<template>
  <section class="list-album" :class="classListActive">
    <div @click="selectedResource">
      <div class="row-album">
        <div>
          <h5 class="bold" v-if="mini">{{ row.name }}</h5>
          <span class="bold" v-else>{{ row.name }}</span>

          <p class="date-item-list-bulk" v-if="!mini">
            {{ $t('application.chat_setting.albums.row.createAt') }}
            {{ $date(row.updatedAt) }}
          </p>
          <p class="date-item-list-bulk" v-if="!mini">
            {{ $t('application.chat_setting.albums.row.attach') }}
            {{ row.mediaIds.length }}
          </p>
        </div>
        <div class="icon-bulk">
          <IconButton
            v-if="!withCheck"
            type="edit"
            :size="17"
            @handler="editAlbum"
          />
          <IconButton
            v-if="!withCheck"
            type="delete"
            :size="17"
            @handler="showWarning"
          />
        </div>
      </div>
      <div class="atach-list-view" @click="showCarrousel">
        <MessageRowResourcePlus :attachments="row.mediaIds" :mini="true" />
      </div>
      <hr v-if="!withCheck" class="hr" />
    </div>

    <WarningDialog
      :show="showWarningDelete"
      :textWarning="textWarning"
      :textButtonOK="textButtonOK"
      @handlerButtonCancel="showWarning"
      @handlerButtonOK="deleteAlbum"
    />
  </section>
</template>

<script>
import { ChatActions } from '@/store'
import IconButton from '@/components/chat/component/IconButton'
import WarningDialog from '@/components/chat/component/WarningDialog.vue'
import MessageRowResourcePlus from '@/components/chat/components/MessageRowResourcePlus'
export default {
  name: 'ListAlbumsRow',
  components: { IconButton, MessageRowResourcePlus, WarningDialog },
  props: {
    row: { type: Object },
    withCheck: { type: Boolean, default: false },
    mini: { type: Boolean, default: false }
  },
  data () {
    return {
      showWarningDelete: false
    }
  },
  computed: {
    textWarning () {
      return this.$t('application.chat_setting.albums.deleteWarning', {
        nameList: this.row.name
      })
    },
    textButtonOK () {
      return this.$t('actions.ok').toUpperCase()
    },
    classListActive () {
      return {
        'list-album-active': this.withCheck
      }
    }
  },
  methods: {
    showWarning () {
      this.showWarningDelete = !this.showWarningDelete
    },
    editAlbum () {
      this.$emit('editAlbum', this.row)
    },
    deleteAlbum () {
      const id = this.row.id
      this.$emit('deleteAlbum', id)
      this.showWarning()
    },
    selectedResource () {
      if (!this.withCheck) return
      this.row.mediaIds.map(f => {
        f.description = ''
        f.path = ''
        this.$store.dispatch(ChatActions.SetAttachmentForm, f)
      })
    },
    showCarrousel () {
      this.$store.dispatch(ChatActions.CarouselItemsList, this.row.mediaIds)
      this.$store.dispatch(ChatActions.ShowCarousel, true)
    }
  }
}
</script>

<style lang="scss" scoped>
.row-album {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.icon-bulk {
  display: flex;
}
.date-item-list-bulk {
  font-size: 0.8em;
  line-height: 1em;
  color: #a5a5a5;
}

.hr {
  overflow: hidden;
  margin-top: 5px;
  border-color: rgb(0 0 0 / 14%);
  border-width: 1px;
}
.selected {
  border: solid var(--v-primary-base) 1px;
}
.atach-list-view {
  margin-left: 7%;
}
.list-album-active {
  &:active {
    border: var(--v-primary-base) solid 1px;
  }
}
.list-album {
  // border: solid red 1px;
  padding: 0 8px;
  min-width: 150px;

  &.atach-list-view {
    padding: 0 4px;
    &.div-image &.mini {
      max-width: 150px !important;
      max-height: 70px !important;
      //max-height: 150px !important;
    }
  }
}
.check {
  width: 15px;
  height: 15px;
  accent-color: var(--v-primary-base);
}
.bold {
  font-weight: bold;
}
</style>
