<template>
  <section>
    <BlockedCountryInfo editable
      @unblock="unblockCountry" />
    <h1>{{ $t('blocked_countries.select_country')}}</h1>
    <v-row align="center">
      <v-col>
        <CountrySelect :dark="dark"
          v-model="selectedCountry" />
      </v-col>
      <v-col cols="auto">
        <c-btn :disabled="!selectedCountry"
          @click="blockCountry">
          {{ $t('actions.block')}}
        </c-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="end">
      <v-col cols="auto">
        <c-btn color="secondary"
          @click="$emit('close')">
          {{ $t('actions.close')}}
        </c-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import BlockedCountryInfo from '@/components/countries/BlockedCountryInfo'

export default {
  name: 'CountryProfile',
  components: { BlockedCountryInfo },
  props: {
    dark: { type: Boolean, default: null }
  },
  data () {
    return {
      loading: false,
      blockedCountries: [],
      selectedCountry: ''
    }
  },
  computed: {
    ...mapState('profile', ['bannedCountries'])
  },

  methods: {
    blockCountry () {
      this.$store.dispatch(ProfileActions.BlockCountry, this.selectedCountry)
      this.selectedCountry = ''
    },

    unblockCountry (country) {
      this.$store.dispatch(ProfileActions.UnblockCountry, country)
    }
  }

}
</script>
