<template>
  <video ref="videoPlayer"
         v-bind="$attrs"
         playsinline
         :poster="poster"
         @loadeddata="resizeVideo"
         @ended="onEnd">
    <source v-for="(source, index) in sourcesWithMime"
            :key="`source-${index}`"
            :src="source.src"
            :type="source.type">
  </video>
</template>

<script>
import Hls from 'hls.js'
import Utils from '@/utils'

const Mime = {
  mp4: 'video/mp4',
  m3u8: 'application/x-mpegURL',
  extensionFromUrl: (url) => {
    const [path] = url.split('?')
    return Utils.extractExtension(path)
  },
  fromUrl: (url) => {
    return Mime[Mime.extensionFromUrl(url)]
  }
}

export default {
  name: 'AwsVideo',
  inject: ['viewport'],
  props: {
    resource: Object,
    height: [Number, String],
    width: [Number, String],
    contain: Boolean,
    autoload: Boolean,
    poster: String
  },
  data () {
    return {
      hls: null,
      player: null
    }
  },
  computed: {
    sources () {
      const sourcesUrls = [this.resource.url, this.resource.alternative]
        .filter(url => !!url)
      return this.clip && this.resource.clip ? [this.resource.clip] : sourcesUrls
    },
    cover () {
      return !this.contain
    },
    sourcesWithMime () {
      return this.sources.map(s => ({
        src: s,
        type: Mime.fromUrl(s)
      }))
    },
    videoSources () {
      const sources = { others: [] }
      this.sources.filter(f => f).forEach(source => {
        const ext = Mime.extensionFromUrl(source)
        if (ext === 'm3u8') {
          sources.m3u8 = source
        } else {
          sources.others.push(source)
        }
      })
      return sources
    }
  },
  methods: {
    play () {
      this.player.play()
    },
    pause () {
      this.player.pause()
    },
    onEnd () {
      this.$emit('videoEnded')
    },

    resizeVideo (event) {
      if (!this.width || !this.height) {
        return
      }
      if (this.width <= this.viewport && this.viewport.offsetWidth && this.height <= this.viewport.offsetHeight) {
        return
      }
      this.$nextTick(() => {
        this.finishResize()
      })
    },

    finishResize () {
      setTimeout(() => {
        if (this.width > this.height) {
          this.centerHorizontal()
        } else {
          this.centerVertical()
        }
      }, 500)
    },

    centerHorizontal () {
      if (!this.viewport) return
      const scrolling = (this.player.offsetWidth - this.viewport.offsetWidth) / 2
      this.viewport.scrollTo(scrolling, 0)
    },

    centerVertical () {
      if (!this.viewport) return
      const scrolling = (this.player.offsetHeight - this.viewport.offsetHeight) / 2
      this.viewport.scrollTo(0, scrolling)
    },
    prepareHls () {
      if (this.videoSources.m3u8 && Hls.isSupported()) {
        const hls = new Hls()
        hls.loadSource(this.videoSources.m3u8)
        hls.attachMedia(this.player)
        let firstLoad = true
        hls.on(Hls.Events.FRAG_PARSING_INIT_SEGMENT, () => {
          !this.autoload && firstLoad && this.stopInitLoading(hls)
          firstLoad = false
        })
        this.player.onplay = () => hls.startLoad(-1)
        this.player.onpause = () => hls.stopLoad()
      }
    },
    stopInitLoading (hls) {
      setTimeout(() => {
        hls.stopLoad()
      }, 150)
    }
  },
  mounted () {
    this.player = this.$refs.videoPlayer
    this.prepareHls()
    if (this.cover) {
      this.resizeVideo()
    }
  },
  beforeDestroy () {
    if (this.hls) {
      this.hls.destroy()
    }
  }
}
</script>
