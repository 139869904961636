import api from '@/api/Api'

export default {

  list (query, options) {
    return api.get('/notifications', query, options)
  },

  hasNew () {
    return api.get('/notifications/pending')
  },

  read (id) {
    return api.get(`/notifications/${id}`)
  },

  delete (id) {
    return api.delete(`/notifications/${id}`)
  }
}
