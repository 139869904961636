<template>
  <div class="user-avatar" :class="userAvatarClass">
    <div
      class="prueba"
      style="display: flex;"
      :style="{ width: innerUser.promotedProfile ? 'inherit' : '' }"
      v-if="xLarge"
    >
      <div class="status-top" v-if="innerUser.promotedProfile">
        <img
          src="/images/icon_top.png"
          width="20"
          height="40"
          v-if="!profile"
        />
      </div>
      <span v-if="showStatus">
        <div class="status-text" v-if="user">
          {{ $t(`models.user.status.${displayOnlineStatus}`) }}
        </div>
    <div v-else class="status"></div>
      </span>
    </div>

    <div class="avatar transparent" style="position: relative; border-radius: 100%;">
      <div class="boxshadow circle">
        <component
          :is="userLinkComponent"
          :href="href"
          :to="link"
          :target="mustRedirect ? '_blank' : ''"
        >
          <v-avatar :size="avatarSize">
            <c-img
              placeholder="false"
              :height="avatarSize"
              :src="avatar"
            ></c-img>
          </v-avatar>
        </component>
      </div>
      <div
        v-if="innerUser.online"
        class="user-live bold upper"
        :class="liveFontClass"
      >
        {{ $t("models.conferences.singular_name") }}
      </div>
    </div>
    <template v-if="!mini && !profile">
      <component
        :is="userLinkComponent"
        class="user truncatable"
        :href="href"
        :to="link"
        :target="mustRedirect ? '_blank' : ''"
      >
        <div class="name truncate">
          <slot name="name" :user="innerUser">
            <div style="align-items: center; display: flex;">
              <span>{{ displayName }}</span>
              <img
                v-if="innerUser.trustedProfile"
                src="/images/check5.svg"
                height="12"
                style="margin-left:1px"
              />
            </div>
          </slot>
        </div>
        <div class="username truncate lightest-text">
          <slot name="username" :user="innerUser">{{
            `@${innerUser.username}`
          }}</slot>
        </div>
      </component>
      <slot :user="innerUser" />
    </template>
    <template v-else-if="!mini">
      <slot :user="innerUser" />
    </template>
  </div>
</template>

<script>
import { UserActions } from '@/store'
import { mapState } from 'vuex'
import config from '@/project'

export default {
  name: 'UserAvatar',
  props: {
    user: Object,
    username: String,
    small: Boolean,
    vertical: Boolean,
    center: Boolean,
    mini: Boolean,
    large: Boolean,
    xLarge: Boolean,
    linkable: { type: Boolean, default: true },
    profile: Boolean,
    showStatus: { type: Boolean, default: true },
    showtop: { type: Boolean, default: true }
  },
  data () {
    return {
      localUser: null,
      postPercentile: 0,
      earningPercentile: 0,
      fansPercentile: 0,
      followersPercentile: 0
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isFollower']),
    isCurrentUser () {
      const username = this.username || this.innerUser.username
      return this.currentUser && this.currentUser.username === username
    },
    innerUser () {
      return this.user || this.localUser || { username: '' }
    },
    getPath () {
      return this.$route.path ? this.$route.path.toLocaleLowerCase() : ''
    },
    mustRedirect () {
      const path = this.$route.path.toLocaleLowerCase()
      if (this.innerUser.username && path.includes(this.innerUser.username.toLocaleLowerCase())) { return false }
      return (
        this.linkable &&
        config.payments.darkfans_redirect &&
        this.user.subscribed
      )
    },
    userLinkComponent () {
      if (!this.linkable) return 'span'
      if (this.mustRedirect) return 'a'
      return 'router-link'
    },
    link () {
      if (!this.linkable || this.mustRedirect) return undefined
      return {
        name: 'influencer',
        params: { username: this.innerUser.username }
      }
    },
    href () {
      if (this.mustRedirect) { return `https://darkfans.com/${this.innerUser.username}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}` }
      return undefined
    },
    userAvatarClass () {
      return {
        small: this.small,
        'x-small-text': this.small,
        vertical: this.vertical,
        center: this.center,
        large: this.large,
        'x-large': this.xLarge,
        'live-on': this.innerUser.online,
        online: this.user ? this.user.onlineStatus === 'online' : false,
        offline: this.user ? this.user.onlineStatus === 'offline' : false,
        ausent: this.user ? this.user.onlineStatus === 'absent' : false
      }
    },
    avatarSize () {
      return this.large ? 77 : this.small ? 32 : this.xLarge ? 124 : 48
    },
    liveFontClass () {
      return {
        large: this.large,
        small: this.small,
        'x-large': this.xLarge
      }
    },
    isBlockedCountry () {
      return !this.isCurrentUser && this.innerUser.blockedCountry
    },
    avatar () {
      return (
        this.innerUser &&
        !this.isBlockedCountry &&
        (this.innerUser.avatarS3Route || this.innerUser.avatar)
      )
    },
    displayName () {
      return this.innerUser.displayName
    },
    displayOnlineStatus () {
      return this.user.onlineStatus
    }
  },
  methods: {
    async reloadUser () {
      if (this.user) {
        return
      }
      this.localUser = await this.$store.dispatch(UserActions.Fetch, {
        username: this.username
      })
    }
  },
  mounted () {
    this.reloadUser()
  }
}
</script>
<style lang="scss">
@use 'src/scss/colors.scss';
@use 'src/scss/styles.scss';

.user-avatar {
  position: relative;
  align-items: center;
  display: flex;
  height: 54px;
  line-height: 22px;
  max-width: 100%;
  text-decoration: none;

  &:visited {
    color: rgba(0, 0, 0, 0.6);
  }

  .circle {
    background-color: white;
  }

  .avatar {
    height: 52px;
    width: 52px;
    margin-right: 3px;
    background-color: white;
  }

  .user {
    flex: 1;
    text-decoration: none;
    min-width: 0;
    padding: 0 8px;
    .name,
    .username {
      height: 22px;
    }
  }
  .status {
    position: relative;
    width: 8px;
    z-index: 1;
    height: 8px;
    border-radius: 5px;
    top: -18px;
    left: 11px;
  }
  .status-text {
    border-radius: 9px;
    font-size: 11px;
    top: 18px;
    left: -23px;
    padding-right: 5px;
    padding-left: 5px;
    z-index: 1;
    position: relative;
    font-weight: bold;
  }
  .status-top {
    border: none 0px;
    width: 50px;
    height: 18px;
    font-size: 11px;
    top: 18px;
    left: -23px;
    padding-right: 5px;
    padding-left: 5px;
    z-index: 1;
    position: relative;
    font-weight: bold;
  }
  &.small {
    height: 38px;
    line-height: 16px;
    .avatar {
      height: 36px;
      width: 36px;
    }
    .user {
      .name,
      .username {
        height: 16px;
      }
    }
  }

  &.large {
    height: 83px;
    .avatar {
      height: 81px;
      width: 81px;
    }
  }

  &.x-large {
    height: 130px;
    .avatar {
      height: 128px;
      width: 128px;
    }
  }

  &.vertical {
    align-items: center;
    height: auto;
    width: 50px;
    flex-direction: column;
    .avatar {
      margin: 0 0 5px 0;
      display: flex;
    }
    .user {
      max-width: 100%;
      padding: 0;
      .name {
        @extend .textshadow;
      }
    }
    &.small {
      width: 40px;
    }
    &.large {
      width: 83px;
    }
    &.x-large {
      width: 130px;
    }
  }

  &.center {
    text-align: center;
    .user {
      text-align: center;
      width: 100%;
    }
    .avatar {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.rank {
  min-height: 100%;
  min-width: 100%;
  .auto {
    max-width: 25px;
  }
}

.dark {
  color: white !important;
}
.online .status {
  border: #00ff00 solid 1px;
  background-color: #00ff00;
}
.offline .status {
  border: transparent solid 1px;
  background-color: transparent;
}
.ausent .status {
  border: #00ff00 solid 1px;
  background-color: white;
}
.online .status-text {
  color: gray;
  border: #00ff00 solid 1px;
  background-color: #00ff00;
}
.offline .status-text {
  border: transparent solid 1px;
  background-color: transparent;
  color: transparent;
}
.ausent .status-text {
  color: gray;
  width: 18px;
  height: 18px;
  border: #00ff00 solid 3px;
  background-color: white;
}

.live-on {
  .circle {
    background-color: colors.$light-red !important;
  }
  .user-live {
    height: 1.1em;
    border-radius: 1.1em;
    font-size: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: colors.$red;
    &.large {
      font-size: 10px;
      height: 0.8em;
      border-radius: 0.8em;
    }

    &.small {
      font-size: 8px;
    }
    &.x-large {
      font-size: 14px;
      height: 1.5em;
      border-radius: 1.5em;
    }
  }
}

.name.truncate.dark {
  background-color: transparent !important;
}
</style>
