<template>
  <v-form v-model="validForm" ref="form" @submit.stop.prevent="submit">
    <!-- <v-row align="center">
      <v-col cols="auto" class="label small-text">
        <v-icon left>person</v-icon>
        <span>{{ $t('models.post.selected_users') }}</span>
      </v-col>
      <v-select
        v-if="broadcast"
        outlined
        dense
        hide-details
        :items="options"
        v-model="receivers"
      />
      <v-chip v-else :dark="darkTheme" class="mx-1" dense outlined filter>
        {{ attached }}
      </v-chip>
    </v-row> -->
    <v-row>
      <v-col cols="auto">
        {{ $t('chat_message.message') }}
      </v-col>
    </v-row>
    <EmojiInput
      outlined
      required
      :dark="darkTheme"
      v-model="content"
      max-length="1500"
      rows="3"
      :placeholder="$t('messages.placeholder')"
      :rules="[$vRequired, $vBannedWords(content)]"
    >
    </EmojiInput>
    <v-row>
      <v-col cols="auto">
        {{ $t('chat_message.attachtments') }}
      </v-col>
    </v-row>
    <FileListUploader
      ref="uploader"
      class="mb-5"
      autoupload
      v-model="files"
      permitTypeAudio
      max-videos="1"
      max-images="20"
    />
    <v-row align="center" v-if="files.length">
      <v-col cols="auto" class="label small-text">
        <v-icon left>monetization_on</v-icon>
        <span>{{ $t('models.post.price') }}</span>
      </v-col>
      <v-col>
        <CurrencyInput
          outlined
          color="orange"
          v-model="price"
          :rules="[$vRequired]"
          minValue="10"
        />
      </v-col>
    </v-row>
    <!--  <v-card-actions
      class="justify-end">
      <c-btn text color="secondary"
        @click="close('close')">
          {{ $t('actions.close') }}
      </c-btn>
      <c-btn
        :disabled="!submitable"
        @click.stop.prevent="submit">
        {{ $t("actions.publish") }}
      </c-btn>
    </v-card-actions> -->
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import { PostActions } from '@/store'
import ChatApi from '@/api/ChatApi'
import validatable from '@/components/mixins/validatable'
import messages from '../locales/all.json'

export default {
  name: 'MultimediaMessageForm',
  mixins: [validatable('form')],
  i18n: { messages },
  props: {
    attached: { type: String, default: null },
    broadcast: Boolean
  },
  data () {
    return {
      content: '',
      selectedUsers: [],
      price: 0,
      files: [],
      receivers: 'fans',
      options: [
        { text: this.$t('chat_broadcast.fans'), value: 'fans' },
        { text: this.$t('chat_broadcast.followers'), value: 'followers' },
        { text: this.$t('chat_broadcast.all'), value: 'all' }
      ]
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
     darkTheme(){
      return this.$vuetify.theme.dark
    },
    submitable () {
      const validFiles =
        !!this.files.length && !this.files.find(f => !f.uploaded)
      return this.validForm && (this.broadcast || validFiles)
    },
    selectedAllFans () {
      return this.broadcast && ['fans', 'all'].includes(this.receivers)
    },
    selectedAllFollowers () {
      return this.broadcast && ['followers', 'all'].includes(this.receivers)
    },
    formData () {
      return {
        content: this.content,
        visibility: 'exclusive',
        selectedUsers: [this.attached].filter(f => f),
        selectedAllFans: this.selectedAllFans,
        selectedAllFollowers: this.selectedAllFollowers,
        price: this.price,
        resources: this.files.map(f => f.location)
      }
    },
    attrs () {
      return {
        receivers: this.receivers,
        message: this.content
      }
    }
  },

  methods: {
    async onSubmit () {
      let data
      if (this.files.length) {
        data = await this.createPost()
      } else {
        data = await this.createBroadcast()
      }
      this.close('submit', data)
    },

    async createBroadcast () {
      //await ChatApi.sendBroadcast(this.attrs)
    },

    async createPost () {
      const { data, error } = await this.$store.dispatch(
        PostActions.Create,
        this.formData
      )
      // TODO notify error
      this.$eventBus.publish('notification', {
        error,
        message: this.$t('snackbar.create_success')
      })
      this.post = data
      this.$router.push({ name: 'post', params: { uuid: data.id } })
    },

    close (event, value) {
      this.$emit(event, value)
    }
  },
  async mounted () {
    if (this.attached) {
      this.selectedUsers.push(this.attached)
    }
  }
}
</script>
<style lang="scss" scoped>
.label {
  width: 120px;
}
</style>
