<template>
  <section class="pa-4">
    <h2 class="my-4">{{ $t('scat_faq.title') }}</h2>

    <v-expansion-panels multiple>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_1') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_1')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_2') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_2')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_3') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_3')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_4') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_4')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_5') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_5')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_6') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_6')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_7') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_7')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_8') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_8')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_9') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_9')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_10') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_10')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header>{{ $t('scat_faq.question_11') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-html="$t('scat_faq.answer_11')"></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>
<script>

export default {
  name: 'ScatFaq',
  metaInfo () {
    return {
      title: this.$t('meta.faq.title')
    }
  }
}
</script>
