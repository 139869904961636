<template>
  <v-btn icon
    color="blue darken-1"
    :tooltip="$t('actions.share')"
    @click.stop.prevent="showDialog">
    <v-icon>share</v-icon>
    <c-dialog persistent
      v-model="dialog"
      :actions="false">
      <template #title><ListViewTitle :title="$t('share.title')" /></template>
      <v-card flat>
        <v-card-text>
          <v-row no-gutters align="center" v-if="canShare" class="pointer mb-1"
            @click="share">
            <v-col cols="auto" class="mr-4">
              <v-icon class="brand-icon">apps</v-icon>
            </v-col>
            <v-col cols="auto" class="title-text-2 semibold">{{ $t('share.via_app')}}</v-col>
          </v-row>
          <a :href="twitterUrl" target="_blank" class="no-decoration">
            <v-row no-gutters align="center">
              <v-col cols="auto" class="mr-4"><img class="brand-icon" src="/images/twitter_button.svg"/></v-col>
              <v-col cols="auto" class="title-text-2 semibold">Twitter</v-col>
            </v-row>
          </a>
          <a :href="whatsappUrl" target="_blank" class="no-decoration">
            <v-row no-gutters align="center">
              <v-col cols="auto" class="mr-4"><img class="brand-icon" src="/images/whatsapp_button.png"/></v-col>
              <v-col cols="auto" class="title-text-2 semibold">WhatsApp</v-col>
            </v-row>
          </a>
        </v-card-text>
      </v-card>
    </c-dialog>
  </v-btn>
</template>
<script>
import dialog from '@/components/mixins/dialog'

export default {
  name: 'ShareButton',
  mixins: [dialog],
  props: {
    text: String,
    url: String
  },

  computed: {
    encodedUrl () {
      return encodeURIComponent(this.url)
    },
    completeText () {
      return encodeURIComponent(`${this.text} via ${this.url}`)
    },
    twitterUrl () {
      return `https://twitter.com/intent/tweet?text=${this.text}&url=${this.url}`
    },
    whatsappUrl () {
      return `https://api.whatsapp.com/send?text=${this.completeText}`
    },
    canShare () {
      return !!navigator.share
    }
  },
  methods: {
    share () {
      navigator.share({
        title: this.$t('project'),
        text: this.text,
        url: this.url
      })
    }
  }
}
</script>
<style scoped>
.brand-icon {
  height: 45px;
  width: 45px;
  border-radius: 5px;
}
</style>
