import Box from '@/components/custom/Box'
import Currency from '@/components/custom/Currency'
import EmojiInput from '@/components/custom/EmojiInput'
import CurrencyInput from '@/components/custom/CurrencyInput'
import PasswordInput from '@/components/custom/PasswordInput'
import CountrySelect from '@/components/custom/CountrySelect'
import ConnecTwitter from '@/components/twitter/ConnecTwitter'
import ShareButton from '@/components/custom/ShareButton'
import Carousel from '@/components/custom/Carousel'
import Swiper from '@/components/custom/Swiper'
import DummyLoader from '@/components/custom/DummyLoader'
import Slider from '@/components/custom/Slider'
import Dialog from '@/components/custom/Dialog'
import TimePickerInput from '@/components/custom/TimePickerInput'
import DatePickerInput from '@/components/custom/DatePickerInput'
import DateInput from '@/components/custom/DateInput'
import Button from '@/components/custom/Button'
import ConfirmButton from '@/components/custom/ConfirmButton'
import ListView from '@/components/custom/ListView'
import ListViewTitle from '@/components/custom/ListViewTitle'
import LocaleSelect from '@/components/custom/LocaleSelect'
import Image from '@/components/custom/Image'
import BackRow from '@/components/custom/BackRow'
import FileInput from '@/components/custom/FileInput'
import FileListUploader from '@/components/custom/FileListUploader'
import FileUploader from '@/components/custom/FileUploader'
import VBirthday from '@/components/custom/VBirthday'
import UsernameInput from '@/components/custom/UsernameInput'
import Timer from '@/components/custom/Timer'
import SwitchThemeButton from '@/components/custom/SwitchThemeButton'

import ButtonVideoCall from '@/components/chat/components/videoChat/ButtonVideoCall'
import Warning from '@/components/custom/warnings/Warning'
import UsernameLink from '@/components/custom/UsernameLink.vue'
import Icon from '@/components/icons/index.vue'
import NDLocaleSelect from '@/components/custom/newDesign/LocaleSelect.vue'
import CDBtn from '@/components/custom/newDesign/Button.vue'
import AppHeader from '@/components/custom/newDesign/AppHeader.vue'

export default {
  install (Vue) {
    Vue.component(Box.name, Box)
    Vue.component(Currency.name, Currency)
    Vue.component(PasswordInput.name, PasswordInput)
    Vue.component(EmojiInput.name, EmojiInput)
    Vue.component(CurrencyInput.name, CurrencyInput)
    Vue.component(CountrySelect.name, CountrySelect)
    Vue.component(ConnecTwitter.name, ConnecTwitter)
    Vue.component(ShareButton.name, ShareButton)
    Vue.component(Carousel.name, Carousel)
    Vue.component(Swiper.name, Swiper)
    Vue.component(DummyLoader.name, DummyLoader)
    Vue.component(Slider.name, Slider)
    Vue.component(Dialog.name, Dialog)
    Vue.component(TimePickerInput.name, TimePickerInput)
    Vue.component(DatePickerInput.name, DatePickerInput)
    Vue.component(DateInput.name, DateInput)
    Vue.component(Button.name, Button)
    Vue.component(ConfirmButton.name, ConfirmButton)
    Vue.component(ListView.name, ListView)
    Vue.component(ListViewTitle.name, ListViewTitle)
    Vue.component(LocaleSelect.name, LocaleSelect)
    Vue.component(Image.name, Image)
    Vue.component(BackRow.name, BackRow)
    Vue.component(FileInput.name, FileInput)
    Vue.component(FileListUploader.name, FileListUploader)
    Vue.component(FileUploader.name, FileUploader)
    Vue.component(VBirthday.name, VBirthday)
    Vue.component(UsernameInput.name, UsernameInput)
    Vue.component(Timer.name, Timer)
    Vue.component(SwitchThemeButton.name, SwitchThemeButton)

    Vue.component(ButtonVideoCall.name, ButtonVideoCall)
    Vue.component(Warning.name, Warning)
    Vue.component(UsernameLink.name, UsernameLink)
    Vue.component(Icon.name, Icon)
    // new Desing
    Vue.component(NDLocaleSelect.name, NDLocaleSelect)
    Vue.component(CDBtn.name, CDBtn)
    Vue.component(AppHeader.name, AppHeader)
  }
}
