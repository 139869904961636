<template>
  <v-progress-circular
    class="progress-circular"
    :style="cssStyle"
    :color="color"
    indeterminate
  ></v-progress-circular>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    color: { type: String, default: 'white' },
    size: { type: Number, default: 20 }
  },
  computed: {
    cssStyle () {
      return {
        width: `${this.size}px !important`,
        height: `${this.size}px !important`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-circular {
   margin: auto auto !important;
}
</style>
