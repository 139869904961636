<template>
  <v-img
    v-bind="$attrs"
    :height="imageHeight"
    :src="imageSrc"
    :contain="contain"
    :min-width="minWidth"
    :min-height="minHeight"
    @mouseover="emitHover(true)"
    @mouseleave="emitHover(false)">
    <template v-if="showProgress" v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-img src="/images/no_content.png" />
      </v-row>
    </template>
    <slot />
  </v-img>
</template>
<script>

export default {
  name: 'CImg',
  props: {
    src: String,
    height: [String, Number],
    noResize: Boolean,
    contain: Boolean,
    minWidth: [String, Number],
    minHeight: [String, Number],
    placeholder: { type: [String, Boolean], default: true },
    hover: Boolean
  },
  data () {
    return {
      naturalHeight: 0, naturalWidth: 0
    }
  },
  computed: {
    imageSrc () {
      return this.src || '/images/no_content.png'
    },
    imageHeight () {
      return this.noResize
        ? ''
        : this.height
          ? this.height
          : this.naturalHeight
    },
    showProgress () {
      return this.placeholder === true || this.placeholder === 'true'
    }
  },

  watch: {
    imageSrc (value) {
      this.calculateHeight(value)
    }
  },
  methods: {
    emitHover (value) {
      this.$emit('update:hover', value)
      if (value) {
        this.$emit('mouseover')
      } else {
        this.$emit('mouseleave')
      }
    },
    calculateHeight (imgSrc) {
      const src = imgSrc || this.imageSrc
      if (!src) {
        return
      }

      const img = new Image()
      img.onload = () => {
        this.naturalHeight = img.naturalHeight
        this.naturalWidth = img.naturalWidth
      }
      img.src = src
    }
  },
  mounted () {
    this.calculateHeight()
  }
}
</script>
