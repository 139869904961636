<template>
  <div style="min-height: 80vh;">
    <div class="row py-12 center" v-if="loading">
      <v-progress-circular color="#E400A0" indeterminate class="p-4">
      </v-progress-circular>
    </div>
    <v-container v-else>
      <v-row v-if="isDevelop">
        <c-btn class="mx-1" color="secondary" @click="switchType(true,false,false)">auto</c-btn>
        <c-btn class="mx-1" color="secondary" @click="switchType(false,true,false)">manual</c-btn>
        <c-btn class="mx-1" color="secondary" @click="switchType(false,false,true)">yoti</c-btn>
        <!--        <input type="number" v-model="section">-->
      </v-row>
      <v-row class="center mt-3">
        <router-link :to="{ name: 'home' }">
          <img
            :src="`/images/${brandingLogo}.svg?dfk23`"
            width="300"
            height="48"
          />
        </router-link>
      </v-row>
      <v-row style="margin-top: 50px;" v-if="section < 4">
        <v-col cols="9" style="margin: auto;  margin-top: 15px; line-height: 30px;"
               :style="{ textAlign: section < 4 ? 'left' : 'center' }">
          <span class="tit-seccion fix fuente-destacada" v-if="section < 4">
            <span
              class="tit-seccion fuente-destacada"
              :style="{ color: project.primary_color }">
              ON
            </span>
            BOARDING
          </span>
          <br/>
          <span class="tit-seccion fuente-destacada">
            <span
              class="tit-seccion fuente-destacada"
              :style="{ color: project.primary_color }">
            {{ $t(`models.user.on_board.${getSection.name}.title`) }}
          </span>
             {{ $t(`models.user.on_board.${getSection.name}.subtitle`) }}
          </span>
          <br/>
          <br/>
          <p class="tit-desc">
            {{ $t(`models.user.on_board.${getSection.name}.desc`) }}
          </p>
        </v-col>
      </v-row>
      <component
        :is="getSection.component"
        :isDark="isDark"
        :type="type"
        home-button
        @back="backStep"
        @next="nextStep"
      ></component>
    </v-container>
  </div>
</template>

<script>
import StepOne from '@/views/onBoarding/StepOne.vue'
import {mapState} from 'vuex'
import project from '@/project'
import StepTwo from '@/views/onBoarding/StepTwo.vue'
import StepThree from '@/views/onBoarding/StepThree.vue'
import StepFour from '@/views/onBoarding/StepFour.vue'
import StepFive from '@/views/onBoarding/StepFive.vue'
import UserApi from "@/api/UserApi";

export default {
  name: 'OnBoarding',
  components: {StepOne},
  data() {
    return {
      project,
      loading: false,
      type: {
        auto: true,
        manual: false,
        yoti: false
      },
      sections: [
        {
          name: 'step_one',
          component: StepOne,
          method: ''
        },
        {
          name: 'step_two',
          component: StepTwo,
          method: ''
        },
        {
          name: 'step_three',
          component: StepThree,
          method: ''
        },
        {
          name: 'step_four',
          component: StepFour,
          method: ''
        },
        {
          name: 'step_five',
          component: StepFive,
          method: ''
        }
      ],
      section: 0
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser']),
    brandingLogo() {
      return this.isDark ? 'logo_horizontal_white' : 'logo_horizontal'
    },
    getSection() {
      return this.sections[this.section]
    },
    isDark() {
      return !project.project.includes('loverfans')
    },
    isDevelop() {
      return project.isDevelop
    }
  },
  methods: {
    goToHome() {
      this.$router.replace('/home')
    },
    backStep() {
      if (this.section === 0) {
        this.$router.replace('/login')
      }
      this.section--
    },
    async nextStep() {
      if (this.section === 1)  {
        // we are on step 2, we have personal data so we will check now yoti
        await this.getActualMode()
      }
      this.section++
    },
    switchType(auto, manual, yoti) {
      this.type.auto = auto
      this.type.manual = manual
      this.type.yoti = yoti
    },
    async getActualMode () {
      const { data, error } = await UserApi.yotiGetConfig()
      if (!error) {
        this.type.manual = data.manual
        this.type.yoti = data.yoti
      } else {
        this.type.manual = false
        this.type.yoti = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tit-seccion {
  font-size: 32px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
}

.fuente-destacada {
  font-family: "Quicksand", sans-serif;
}

.tit-desc {
  line-height: 20px;
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
}

.campo-label {
  text-align: left;
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  font-family: Quicksand, sans-serif;
}
</style>
