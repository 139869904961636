<template>
  <section>
    <v-progress-circular v-if="loading"
      indeterminate color="orange" size="48">
    </v-progress-circular>
    <TrialClaimDialog v-if="showDialog"
      v-model="showDialog"
      :trial="trial" />
  </section>
</template>
<script>
import SubscriptionApi from '@/api/SubscriptionApi'
import TrialClaimDialog from '../components/TrialClaimDialog'
import messages from '../locales/all.json'

export default {
  name: 'ClaimTrialView',
  components: { TrialClaimDialog },
  i18n: { messages },
  props: {
    token: String
  },
  data () {
    return {
      loading: false,
      showDialog: false,
      trial: {}
    }
  },

  async mounted () {
    this.loading = true
    const { data, error } = await SubscriptionApi.findTrialByToken(this.token)
    this.trial = data
    if (error) {
      this.trial = { disabled: true }
    }
    this.loading = false
    this.showDialog = true
  }
}
</script>
