<template>
  <PaymentForm
    :disable-next-button="noValidAmount"
    :provider="provider"
    :payment-form-url="paymentFormUrl"
    resource="wallets"
    :error="error"
    :error-message="errorMessage"
    :price="totalPrice"
    :vat-post-price="vatPrice"
    @payment="doPayment"
    @close="$emit('close')">
    <h3 class="title-text-2 mb-5">{{ $tc('wallet.reload.amount', 0) }}</h3>
    <v-form
      v-model="validForm" ref="form"
      @submit.prevent="submit">
      <v-radio-group v-model="amountOption"
                     class="ma-0" :row="!isMobile"
                     @change="visibleInput = false">
        <v-radio v-for="option in options"
                 color="primary"
                 :key="option.value"
                 :label="option.text"
                 :value="option.value">
        </v-radio>
        <c-btn small
               :outlined="!visibleInput"
               @click="showInput">
          {{ $t('actions.other') }}
        </c-btn>
      </v-radio-group>
      <CurrencyInput v-if="visibleInput"
                     outlined
                     v-model="inputValue"
                     :rules="[minAmount, maxAmount]"/>
    </v-form>
  </PaymentForm>
</template>
<script>
import { mapState } from 'vuex'
import validatable from '@/components/mixins/validatable'
import PaymentForm from '@/components/payments/forms/PaymentForm'
import WalletApi from '@/api/WalletApi'
import payments from '@/components/mixins/payments'
import config from '@/project'

const MinAmount = 1000
const MaxAmount = 20000

export default {
  name: 'WalletPayment',
  components: { PaymentForm },
  mixins: [validatable('form'), payments],
  data () {
    return {
      validForm: false,
      visibleInput: false,
      amountOptions: [1000, 2500, 5000, 7500],
      amountOption: '',
      inputValue: '',
      provider: '',
      paymentFormUrl: '',
      error: false,
      errorMessage: ''
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapState('application', ['isMobile']),
    vatPrice () {
      const price = this.amountOption
      let vat = this.currentUser.billing.vat
      let expenses = (price * this.currentUser.billing.exes) / 100
      const vatAmount = Math.ceil((price + expenses) * vat / 100)

      return Math.ceil(vatAmount + expenses)
    },
    totalPrice () {
      return this.amountOption
    },
    origin () {
      if (config.payments.darkfans_redirect) return `https://darkfans.com?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
      return `${window.location.origin}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
    },
    options () {
      return this.amountOptions.map(opt => ({
        value: opt,
        text: this.$currency(opt)
      }))
    },
    amount () {
      return this.visibleInput
        ? parseInt(this.inputValue || 0, 10)
        : parseInt(this.amountOption || 0, 10)
    },
    noValidAmount () {
      return this.amount < MinAmount || this.amount > MaxAmount
    }
  },
  methods: {
    resetState () {
      this.validForm = false
      this.visibleInput = false
      this.amountOption = ''
      this.inputValue = ''
      this.paymentFormUrl = ''
      this.error = false
      this.errorMessage = ''
    },
    minAmount () {
      const amount = this.$currency(MinAmount, this.currentUser.billing.currency)
      return this.amount >= MinAmount || this.$t('errors.no_min_amount', { amount })
    },
    maxAmount () {
      const amount = this.$currency(MaxAmount, this.currentUser.billing.currency)
      return this.amount <= MaxAmount || this.$t('errors.no_max_amount', { amount })
    },
    showInput () {
      this.visibleInput = true
      this.amountOption = ''
    },
    async doPayment ({ name }) {
      const {
        data,
        error
      } = await WalletApi.reload(this.currentUser.id, {
        name,
        amount: this.amount,
        origin: this.origin
      })
      if (error) {
        this.error = !!error
        this.errorMessage = ''
        this.errorMessage = this.$t(`remote_errors.${data.errorCode}`, {
          reload: this.$currency(20000, this.currentUser.billing.currency),
          daily: this.$currency(25000, this.currentUser.billing.currency)
        })

      } else if (data.redirectUrl) {
        const slash = data.redirectUrl.includes('?') ? '&' : '?'
        this.$gtm('reload_wallet_step2')
        window.location = `${data.redirectUrl}${slash}provider=${data.project}`
      } else {
        this.provider = data.provider
        this.paymentFormUrl = data.url
      }
    }
  },
  mounted () {
    this.$gtm('reload_wallet_step1')
    this.visibleInput = false
    this.amountOption = ''
    this.inputValue = ''
  }
}
</script>
