<template>
  <v-card flat>
    <v-card-text>
      <v-form v-model="validForm" ref="form" @submit.prevent="submit">
        <div style="font-size: 14px;color: #5f5f5f;" v-html="msgMinValue"></div>
        <CurrencyInput
          outlined
          v-model="amount"
          :currency="wallet.currency"
          :min-value="minValue"
          :max-value="wallet.unlockedAmount"
          :rules="[$vRequired]"
        />
        <div
          style="display: flex; flex-direction: row; justify-content: space-between; width: 100%"
        >
          <div style="font-size: 14px; font-weight: bold;width: 100%">
            <p class="box-fund-available" :class="classModals">
              {{ $t('models.withdraw.available_today') }}
              <strong class="fund-available" :class="classModals">{{
                  $currency(getWalletData.unlockedAmount, wallet.currency)
                }}</strong>
            </p>

            <p
              class="box-fund-all"
              :class="classModals"
              v-if="payout.newPayout"
            >
              {{ $t('models.withdraw.pending_week') }}
              <strong class="funds-all" :class="classModals">{{
                  $currency(getWalletData.lockedAmount, wallet.currency)
                }}</strong>
            </p>

            <v-tooltip v-model="show" bottom v-if="currentUser.wallet.bonus">
              <template v-slot:activator="{ on }">
                  <span v-on="on" class="truncate ml-auto two-lines" @click="show = !show">
                    <div class="col-12 center bonus-warning">
                      {{ $t('models.withdraw.pending_bonus') }}
                      <v-icon class="mx-4" solo :dark="darkTheme">help_outline </v-icon>
                    </div>
                  </span>
              </template>
              <div class="tooltip-bonus">
                <div class="col">
                  {{
                    $t(`promo_code.${bonusCode}_info`, {
                      amount: $currency(requiredAmount, 'EUR'),
                      bonus: $currency(bonusAmount, 'EUR'),
                      code: bonusCode
                    })
                  }}
                </div>
                <div class="col">
                  {{
                    $t(`promo_code.${bonusCode}_condition`, {
                      minAmount: $currency(minAmount, 'EUR'),
                      bonus: $currency(bonusAmount, 'EUR')
                    })
                  }}
                </div>
              </div>
            </v-tooltip>

            <div
              style="display: flex; flex-direction: column;    justify-content: center; margin-left: 2px;  padding: 10px;"
            >
              <button
                type="button"
                class="button-blue"
                style="padding: 3px 6px; font-size: 12px"
                @click="gotoAcademy"
              >
                {{ $t('models.withdraw.button_tutorial') }}
              </button>
            </div>
          </div>
        </div>
        <PaymentDetail/>
      </v-form>
    </v-card-text>
    <!-- <warning
      v-if="showWarningWT"
      v-model="warningWT"
      :title="$t('withdraws.warningWireTransferTitle')"
      :body="
        $t('withdraws.warningWireTransferText', {
          email: getEmails
        })
      "
      @accept="aceptWarningWT=true"
      @close="warningWT = false"
    >
    </warning>
    -->
    <div class="center">
      <c-btn
        @click="submit"
        :disabled="!canWithdraw"
        :rounded="false"
        :css="cssButton"
        color="green"
      >
        {{ $t('actions.request_witdraw') }}
      </c-btn>
    </div>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { WithdrawActions, ProfileActions } from '@/store'
import validatable from '@/components/mixins/validatable'
import WithdrawDataInfo from '@/components/withdraws/infos/WithdrawDataInfo'
import WithdrawMethodInfo from '@/components/withdraws/infos/WithdrawMethodInfo'
import PaymentDetail from './PaymentDetail'
import config from '@/project'

export default {
  name: 'WalletWithdraw',
  mixins: [validatable('form')],
  components: {
    WithdrawDataInfo,
    WithdrawMethodInfo,
    PaymentDetail
  },
  props: {
    wallet: Object
  },
  data () {
    return {
      amount: 0,
      warningWT: false,
      config,
      show: false,
      aceptWarningWT: false
    }
  },
  watch: {
    aceptWarningWT () {
      this.onSubmit()
    }
  },
  computed: {
    ...mapState('withdraws', ['withdrawData', 'withdrawMethods']),
    ...mapState('application', ['darkTheme']),
    ...mapState('profile', ['currentUser', 'eurWallet', 'usdWallet', 'payout']),
    minValue () {
      return this.currentUser.withdrawData.country === 'CO' ? 2000 : 10000
    },
    bonusCode () {
      return this.currentUser.wallet.bonus.bonusCode
    },
    minAmount () {
      return this.currentUser.wallet.bonus.requiredBillingAmount
    },
    bonusAmount () {
      return this.currentUser.wallet.bonus.amount
    },
    requiredAmount () {
      return this.minAmount - (this.getWalletData.lockedAmount + this.getWalletData.unlockedAmount)
    },
    withdrawMethod () {
      return this.withdrawMethods[0] || {}
    },
    getWalletData () {
      const wallet =
        this.wallet.currency === 'EUR' ? this.eurWallet : this.usdWallet
      return wallet
    },
    mustCompleteData () {
      return !this.withdrawData.isCompleted || !this.withdrawMethods.length
    },
    hasFunds () {
      return this.wallet.amount >= this.minValue
    },
    canWithdraw () {
      return !this.mustCompleteData && this.hasFunds && this.validForm
    },
    fee () {
      const fee = this.withdrawMethod.fee || 0
      const feePercent = fee / 100
      return this.amount * feePercent
    },
    total () {
      return this.amount - this.fee
    },
    cssButton () {
      return {
        'text-color-button': true
      }
    },
    msgMinValue () {
      return `${this.$t(
        'models.withdraw.min_withdraw_msg'
      )} <strong>${this.$currency(this.withdrawData.country === 'CO' ? 2000 : 10000, this.wallet.currency)}</strong>`
    },
    classModals () {
      return {
        dark: this.$vuetify.theme.dark
      }
    },
    isEurope () {
      const bankCountry = this.withdrawMethods[0] ? (this.withdrawMethods[0].info ? this.withdrawMethods[0].info.bankCountry : '') : ''
      if (!bankCountry) return false
      const country = this.$t('countries_v2').filter(c => (c.value === bankCountry || c.value3 === bankCountry))
      return country[0].region.toLowerCase() === 'europe' || country[0].region.toLowerCase() === 'europa'
    },
    isWireTransfer () {
      return this.withdrawMethod && this.withdrawMethod.type === 'bank'
    },
    showWarningWT () {
      return false // !this.isEurope && this.isWireTransfer;
    },
    getEmails () {
      return `<a
          :href="mailto:stars@${config.project}.com"
          style="color:var(--unnamed-color-base)"
        >
          stars@${config.project}.com, financial@artemisa3000.com
        </a>`
    }
  },
  methods: {
    async onSubmit () {
      if (this.showWarningWT && !this.aceptWarningWT) {
        this.warningWT = true
        return
      }
      const { error } = await this.$store.dispatch(WithdrawActions.Request, {
        currency: this.wallet.currency,
        amount: this.amount,
        withdrawMethodId: this.withdrawMethod.id
      })
      let key = 'request_success'
      if (error) {
        key = 'cannot_process_request'
      }
      this.$eventBus.publish('notification', {
        message: this.$t(`snackbar.${key}`),
        error: error
      })
      this.$store.dispatch(ProfileActions.Fetch)
      this.emitClose()
    },
    emitClose () {
      this.amount = 0
      this.$emit('close')
    },
    gotoAcademy () {
      const site = this.$t('site')
      window.open(`https://${site}/academy/payments.html`)
    },
    showWarning () {
      if (this.showWarningWT) {
        return (this.warningWT = true)
      } else {
        this.onSubmit()
      }
    }
  },
  beforeMount () {
    this.$store.dispatch(WithdrawActions.FetchData)
    this.$store.dispatch(WithdrawActions.FetchMethods)
  }
}
</script>
<style lang="scss" scoped>
.text-color-button {
  color: #fff !important;
}

.button-blue {
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 5px;
  color: #fff !important;
  background-color: #3b7ddd;
}

.box-fund-all {
  padding: 4px 10px;
  background-color: #f1f1f1;
  font-size: 1.1em;

  &.dark {
    background-color: rgb(93, 93, 93) !important;
  }
}

.box-fund-available {
  padding: 4px 10px;
  margin-bottom: 1px;
  background-color: #cbf2f3;
  font-size: 1.1em;

  &.dark {
    background-color: #76c3c6 !important;
  }
}

.fund-available {
  font-size: 1.5em;
  line-height: 1.5em;
  color: #008000;
  background-color: white;
  padding: 3px 20px;
  border-radius: 3px;
  border: 1px solid #e3e3e3;
  border-radius: 50px;

  &.dark {
    background-color: rgb(170, 170, 170) !important;
    color: #008000 !important;
  }
}

.funds-all {
  font-size: 1.1em;
  line-height: 2em;
  color: #d70000;
  background-color: white;
  padding: 3px 20px;
  border-radius: 3px;
  border: 1px solid #e3e3e3;
  border-radius: 50px;

  &.dark {
    background-color: rgb(170, 170, 170) !important;
    color: #d70000 !important;
  }
}

.bonus-warning {
  margin: 16px 0;
  border: 1px solid #F7195366;
  border-radius: 8px;
  opacity: 1;
}

.v-tooltip__content {
  background-color: black !important;
  opacity: 1;
  max-width: 585px !important;
}

@media (max-width: 760px) {
  .v-tooltip__content {
    background-color: black !important;
    left: 0% !important;
    opacity: 1;
    max-width: 760px !important;
  }

}
</style>
