import {events} from "@/plugins/gtm/events";

const custom_gtm = (trackAction, price, email) => {
  const event = events.find(e => e.trackAction === trackAction)
  if (event != undefined) {
    if (price != undefined) {
      event.price = price;
    }
    if (email != undefined) {
      event.email = email;
    }
    window.dataLayer.push(event);
  }
}

const GTM = {
  install(Vue, options) {
    Vue.prototype.$gtm = custom_gtm
  }
}
export default GTM
