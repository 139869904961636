<template>
  <v-autocomplete
    v-bind="$attrs"
    :label="computedLabel"
    :items="$t('countries')"
    :value="value"
    :rules="rules"
    @input="updateValue">
  </v-autocomplete>
</template>
<script>
export default {
  name: 'CountrySelect',
  props: {
    value: String,
    label: String,
    rules: { type: Array, default: () => [] },
    dark: { type: Boolean, default: null }
  },
  computed: {
    computedLabel () {
      return this.label || this.$t('models.user.country')
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}

</script>
