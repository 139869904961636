var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tabs-infochat",class:_vm.ccsClass},[_c('div',{ref:"1",staticClass:"tab-infochat",class:_vm.ccsClass,on:{"click":function($event){return _vm.selectTab(1, 'image')}}},[_vm._v(" "+_vm._s(_vm.$t("chats.gallery.images"))+" ")]),_c('div',{ref:"2",staticClass:"tab-infochat",class:_vm.ccsClass,on:{"click":function($event){return _vm.selectTab(2, 'video')}}},[_vm._v(" "+_vm._s(_vm.$t("chats.gallery.videos"))+" ")]),_c('div',{ref:"3",staticClass:"tab-infochat",class:_vm.ccsClass,on:{"click":function($event){return _vm.selectTab(3, 'audio')}}},[_vm._v(" "+_vm._s(_vm.$t("chats.gallery.audios"))+" ")])]),_c('div',{staticClass:"content-tab",class:_vm.ccsClass},[_c('ListView',{attrs:{"cols":4,"items":_vm.items,"loading":_vm.loading,"empty-text":_vm.$t('listview.no_content')},on:{"bottom-reached":_vm.loadItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('Box',{attrs:{"ratio":2 / 2},on:{"click":function($event){return _vm.selectedResource(item, index)}}},[_c('div',{},[(item.type === 'audio' && _vm.activeTab === 3)?_c('AudioPlayer',{staticClass:"attachment-img",attrs:{"src":item.url,"sizeIcon":60}}):_vm._e(),(item.type === 'video' && _vm.activeTab === 2)?_c('video',{staticClass:"attachment-img",attrs:{"src":item.url,"controlslist":"nodownload"}}):_vm._e(),(!item.isPurchased)?_c('div',{staticClass:"attachment-img"},[_c('FilePayment',{attrs:{"message":{
                  messageId: item.conversationMessageId,
                  url: item.url,
                  conversationId: item.conversationId,
                  searchMessage: true
                }},on:{"refresh-message":_vm.refreshMessage}})],1):(item.type === 'image' && _vm.activeTab === 1)?_c('img',{staticClass:"attachment-img",attrs:{"src":item.url,"alt":""}}):_vm._e()],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }