import VueTimeago from 'vue-timeago'
import i18n from '@/i18n'
import toNow from 'date-fns/distance_in_words_to_now'

const options = {
  name: 'Timeago',
  locale: i18n.locale,
  locales: {
    es: require('date-fns/locale/es'),
    en: require('date-fns/locale/en'),
    de: require('date-fns/locale/de'),
    fr: require('date-fns/locale/fr'),
    it: require('date-fns/locale/it'),
    pt: require('date-fns/locale/pt')
  }
}

export default {
  install (Vue) {
    Vue.use(VueTimeago, options)
    Vue.prototype.$i18nTimeago = (date) => toNow(new Date(date), { locale: i18n.locale, addSuffix: true })
  }
}
