<template>
  <div class="row mx-1" style="padding-bottom: 50px">
    <div class="col-12">
      <UsersNewBroadcast
        @list-included-user="listIncludedUser"
        @list-excluded-user="listExcludedUser"
        @messageBy="messageBy"
        :mediaAdded="mediaAdd"
        :showError="showError"
        :noDestinationSelected="noDestinationSelected"
      />
    </div>
    <span class="seeErrors" v-if="noDestinationSelected && showError">{{
      $t(`errors.required`)
    }}</span>
    <div class="col-12">
      <h5>{{ $t('chats.new_broadcast.message') }}</h5>
      <InputText
        right
        :rows="4"
        noResize
        v-on:newText="saveMessage"
        @hasBannedWord="isBannedWord"
        @required="isRequired"
        @hasHtmlLink="isHtmlTags"
        @hasPhone="isPhone"
        @hasLink="isLink"
        :first-load.sync="firstLoad"
        :has-error="hasError"
      ></InputText>
    </div>
    <div class="col-12" v-if="editFile.length > 0">
      <ViewMultipleFile :mediaIds="editFile" @delete-id="deleteAttach" />
    </div>
    <div class="col-12">
      <button class="btn-att" @click="onShowAttach">
        {{ $t('chats.attachment.title') }}
      </button>
    </div>

    <div class="row" style="margin: 0px 5px;" v-if="filesLocal.length > 0">
      <div class="col-7">
        {{ $t('actions.set_price') }}
      </div>
      <div class="col-5" v-if="!waitingForMedia">
        <InputCurrency
          :currency="currency"
          :defaultPrice="defaultPrice"
          @newPrice="price"
        />
      </div>
    </div>
    <Dialog
      :show="showAttach"
      @hide="onShowAttach"
      :showBotton="true"
      :modeMobile="isMobile"
      :title="$t('chats.attachment.title').toUpperCase()"
      @acept-dialog="aceptAtt"
    >
      <template slot="bodyDialog">
        <div>
          <MessageAttachmentsForm
            :noClose="true"
            :noPrice="true"
            :noAlbums="true"
            @reset="reset"
            @new-price="price"
            @loading="waitForMedia"
            :defaultSelectedTab="1"
            :files="[]"
          />
        </div>
      </template>
    </Dialog>
    <div class="col-12 dates">
      <icon-button
        style="display: flex; margin-bottom: 20px;"
        type="schedule"
        class="icons"
        left
        :size="22"
        @handler="scheduleMessage"
        :text="$t('chats.new_broadcast.schedule_message')"
      />
      <!--<Calendar
        v-if="showSchedule"
        :min-date="today"
        :sel-date="schedule"
        @update-date="setDate" /> -->
      <input
        v-if="showSchedule"
        type="datetime-local"
        v-model="schedule"
        name="schedule"
        placeholder="Select Date time"
        :min="today"
      />
    </div>
    <div class="col-11" style="margin: 0 auto auto; text-align: center;">
      <Buttons
        :disabled="hasError.length > 0"
        class="saveBtn"
        @handler="send"
        :text="$t('actions.send')"
        :loading="loading || waitingForMedia"
      ></Buttons>
    </div>
  </div>
</template>

<script>
import UserApi from '@/api/UserApi'
import InputCurrency from '@/components/chat/component/InputCurrency'
import InputText from '@/components/chat/component/InputText'
import Attachments from '@/components/chat/component/Attachments'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import Buttons from '@/components/chat/component/Buttons'
import Spinner from '@/components/chat/component/Spinner'
import IconButton from '@/components/chat/component/IconButton.vue'
import ViewListUser from '@/components/chat/components/listUser/ViewListUser'
import TitleOptionTab from '@/components/chat/component/TitleOptionTab'
import Dialog from '@/components/chat/component/Dialog'
import ViewMultipleFile from '@/components/chat/components/viewFiles/ViewMultipleFile'
import MessageAttachmentsForm from '@/components/chat/components/newMessage/MessageAttachmentsForm'
import UsersNewBroadcast from '@/components/chat/components/newChat/UsersNewBroadcast'

import { mapState } from 'vuex'
import { AppActions } from '@/store'
import Calendar from '../../component/Calendar'

export default {
  name: 'NewBroadcastMessage',
  components: {
    Calendar,
    InputText,
    InputCurrency,
    IconButton,
    Buttons,
    Attachments,
    Spinner,
    ViewListUser,
    TitleOptionTab,
    MessageAttachmentsForm,
    Dialog,
    ViewMultipleFile,
    UsersNewBroadcast
  },
  data () {
    return {
      hasBannedWord: false,
      hasRequired: false,
      hasHtmlTags: false,
      hasPhone: false,
      hasLink: false,
      showError: false,
      waitingForMedia: false,
      loading: false,
      message: null,
      amount: 0,
      search: '',
      schedule: null,
      today: '',
      excluded: [],
      included: [],
      roles: null,
      filesLocal: [],
      all: false,
      followers: false,
      fans: false,
      creators: false,
      showSchedule: false,
      showAttachments: false,
      firstLoad: true,
      defaultPrice: '0',
      files: [],
      showAttach: false,
      editFile: [],
      mediaAdd: false
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser']),
    ...mapState('chats', ['viewAttachmentForm']),
    currency () {
      return this.currentUser.wallet.currency === 'EUR' ? '€' : '$'
    },
    reset () {
      return this.filesLocal.length === 0
    },
    hasError () {
      const errors = []
      if (this.hasBannedWord) {
        errors.push({ error: 'bannedWord', field: this.hasBannedWord })
      }
      if (!this.hasRequired) {
        errors.push({ error: 'textFieldRequired', field: true })
      }
      if (this.noDestinationSelected && !this.included.length > 0) {
        errors.push({ error: 'noTextFieldRequired', field: true })
      }
      if (this.hasHtmlTags) {
        errors.push({ error: 'noHtmlTags', field: true })
      }
      if (this.hasPhone) {
        errors.push({ error: 'noPhoneNumber', field: true })
      }
      if (this.hasLink) {
        errors.push({ error: 'noLinksAllowed', field: true })
      }
      if (this.viewAttachmentForm.length > 0) return errors
      return errors
    },
    noDestinationSelected () {
      return !this.followers && !this.fans
    }
  },
  watch: {
    filesLocal () {
      this.mediaAdd = this.filesLocal.length > 0
    }
  },
  methods: {
    onShowAttach () {
      this.showAttach = !this.showAttach
    },
    attrs () {
      const sch = new Date(this.schedule)
      return {
        message: this.message,
        price: this.getMedias().length === 0 ? 0 : this.amount,
        followers: this.followers,
        fans: this.fans,
        creators: this.creators,
        sendDate: this.showSchedule
          ? new Date(
            sch.getFullYear(),
            sch.getMonth(),
            sch.getDate(),
            sch.getHours(),
            sch.getMinutes()
          )
          : new Date(),
        mediaIds: this.getMedias(),
        excludedUsers: this.excluded,
        includedUsers: this.included
      }
    },
    setDate (value) {
      this.schedule = value
    },
    isBannedWord (value) {
      this.hasBannedWord = value
    },
    isRequired (value) {
      this.hasRequired = value
    },
    isHtmlTags (value) {
      this.hasHtmlTags = value
    },
    isPhone (value) {
      this.hasPhone = value
    },
    isLink (value) {
      this.hasLink = value
    },
    waitForMedia (value) {
      this.waitingForMedia = value
    },
    getMedias () {
      const filesIds = []
      for (const file of this.filesLocal) {
        filesIds.push(file.id)
      }
      return filesIds
    },

    saveMessage (newMessage) {
      this.message = newMessage
      this.firstLoad = false
    },
    scheduleMessage () {
      this.showSchedule = !this.showSchedule
    },
    async send () {
      this.showError = true
      this.firstLoad = false
      if (this.hasError.length === 0) {
        this.loading = true
        const { data, error } = await ConversationsApi.createBroadcast(
          this.attrs()
        )
        if (!error) {
          this.$emit('createBroadcast')
          this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
          this.firstLoad = true
        } else {
          this.$store.dispatch(AppActions.SetNotification, {
            type: 'error',
            error: data
          })
        }
        this.$store.dispatch(AppActions.ShowNotification, true)
        this.loading = false
      }
    },
    price (price) {
      this.amount = price
    },
    async priceSetting () {
      const { data, error } = await ConversationsApi.settings()
      if (!error) {
        this.defaultPrice = `${data.mediaPrice}`
      }
    },
    aceptAtt () {
      const att = this.viewAttachmentForm.slice()
      att.map(f => {
        f.attachmentType = f.type
        delete f.path
        f.path = f.url
        f.mediaId = f.id
        this.editFile.push(f)
      })
      this.filesLocal = this.viewAttachmentForm
    },
    deleteAttach (id) {
      const indexEdit = this.editFile.findIndex(f => f.id === id)
      if (indexEdit !== -1) {
        this.editFile.splice(indexEdit, 1)
      }
      this.filesLocal = this.editFile
      this.mediaAdd = this.filesLocal.length > 0
    },
    listIncludedUser (listUser) {
      this.included = listUser
    },
    listExcludedUser (listUser) {
      this.excluded = listUser
    },
    messageBy (all, followers, fans, creators) {
      this.all = all
      this.followers = followers
      this.fans = fans
      this.creators = creators
    }
  },

  mounted () {
    const today = new Date()
    const month = today.getMonth() + 1
    const day1 = today.getDate()
    const tomorrow = today.getDate() + 1
    const hours = today.getHours()
    const minutes = today.getMinutes()
    this.priceSetting()
    this.schedule =
      today.getFullYear() +
      '-' +
      (month < 10 ? '0' + month : month) +
      '-' +
      (tomorrow < 10 ? '0' + tomorrow : tomorrow) +
      'T' +
      (hours < 10 ? '0' + hours : hours) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes)
    this.today =
      today.getFullYear() +
      '-' +
      (month < 10 ? '0' + month : month) +
      '-' +
      (day1 < 10 ? '0' + day1 : day1) +
      'T' +
      (hours < 10 ? '0' + hours : hours) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes)
  }
}
</script>

<style lang="scss" scoped>
.search-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  margin-top: 0;
  height: 37px;
  input {
    width: 100%;
    display: inline-block;
    border: 1px solid #d7d7d7;
    height: 40px;
    padding: 7px 15px;
    margin: 4px;
    margin-top: 0;
    font-size: 0.9375em;
    border-radius: 30px;
  }
}
.dates input {
  width: 100%;
  display: inline-block;
  border: 1px solid #d7d7d7;
  height: 40px;
  padding: 7px 15px;
  margin: 4px;
  font-size: 0.9375em;
  border-radius: 30px;
}

select {
  width: 100%;
  display: inline-block;
  border: 1px solid #d7d7d7;
  height: 40px;
  padding: 7px 15px;
  margin: 4px;
  font-size: 0.9375em;
  border-radius: 30px;
}
.saveBtn {
  padding: 5px 20px;
  background-color: #41b849;
  width: 100%;
  font-family: Quicksand, sans-serif !important;
}

.excludedList {
  max-height: 120px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.chip {
  white-space: nowrap;
  background-color: var(--v-primary-base);
  border-radius: 100px;
  color: white;
  font-size: 13px;
  font-family: 'Montserrat-Bold', sans-serif;
  padding: 1px 5px;
  margin-right: 2px;
}

fieldset.errorField {
  border-color: red;
  background-color: #ff00001a;
}
.btn-att {
  text-decoration: underline;
}
</style>
