<template>
  <section style="width: 100%;">
    <div class="d-flex justify-space-between align-center">
      <v-img
        max-width="350"
        src="/images/logo_horizontal.svg?dfk23" alt="logo" title="Logo">
      </v-img>

      <router-link to="/login" class="capital">{{ $t('signup.already_registered')}}</router-link>
    </div>
    <SignupForm :origin="origin" @submit="goToPromote" />
  </section>
</template>

<script>
import SignupForm from '@/components/sessions/SignupForm'

export default {
  name: 'SignupView',
  components: { SignupForm },
  props: {
    origin: String
  },
  metaInfo () {
    return {
      title: this.$t('meta.signup.title'),
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.$t('meta.signup.description')
      }]
    }
  },
  methods: {
    goToPromote () {
      this.$router.replace('/promote')
    },
    skip () {
      this.$router.replace('/home')
    }
  }
}
</script>
