<template>
  <section>
    <TitleOptionTab :attrs="title" @selectTab="onTab" :forceSelected="forceSelected" />
    <CreateAlbum
      v-if="create"
      :editAlbum="album"
      :isEdit="isEdit"
      @removeDefaultContent="removeDefaultContent"
      @cancel-edit-album="onTab('list')"
    />
    <ListAlbums v-if="list" @editAlbum="editAlbum" />
  </section>
</template>

<script>
import TitleOptionTab from '@/components/chat/component/TitleOptionTab'
import CreateAlbum from '@/components/chat/components/albums/CreateAlbum'
import ListAlbums from '@/components/chat/components/albums/ListAlbums'
export default {
  name: 'Albums',
  components: { TitleOptionTab, CreateAlbum, ListAlbums },
  data () {
    return {
      title: [
        {
          iconType: 'newMessage',
          title: 'application.chat_setting.albums.title_1',
          call: 'create',
          icon: false,
          show: true
        },
        {
          iconType: '',
          title: 'application.chat_setting.albums.title_2',
          call: 'list',
          icon: false,
          show: true
        }
      ],
      forceSelected: null,
      create: false,
      list: false,
      isEdit: false,
      album: null
    }
  },
  methods: {
    onTab (name) {
      this.create = name === 'create'
      this.list = name === 'list'
      if (name === 'list') {
        this.forceSelected = this.title[1]
        this.removeDefaultContent()
      }
    },
    editAlbum (album) {
      this.isEdit = true
      album.mediaIds.map(a => {
        a.path = a.poster || a.url
        a.attachmentType = a.type
        a.mediaId = a.id
      })
      this.album = album
      this.title[0].title = 'application.chat_setting.albums.title_3'
      this.forceSelected = this.title[0]
      this.onTab('create')
    },
    removeDefaultContent () {
      this.isEdit = false
      this.title[0].title = 'application.chat_setting.albums.title_1'
      this.album = null
    }
  }
}
</script>
