<template>
  <c-dialog persistent
            width="684"
            :value="value"
            @input="closedialog"
  >
    <div class="text-center mb-6">
      <h1>{{ $t('promotion.blocked_country.title') }}</h1>
      <p class="my-4">
        {{ $t('promotion.blocked_country.text') }}
      </p>
    </div>

  </c-dialog>
</template>

<script>
export default {
  name: 'BlockedCountryDialog',
  props: {
    value: { type: Boolean, default: false }
  },
  methods: {
    closedialog (value) {
      this.$emit('input', value)
    }
  }
}
</script>