<template>
  <section class="d-flex flex-column flex-grow-1">
    <ChatMessageList :blocked="isBlocked"/>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ChatActions } from '@/store'
import ChatMessageList from './components/ChatMessageList'

export default {
  name: 'ChatPage',
  components: {
    ChatMessageList
  },
  computed: {
    ...mapState('chats', ['selectedChat', 'showCarousel']),
    /*  showRequestPermission () {
       return !this.selectedChat.receiver.approved
     }, */
    isBlocked () {
      return !this.selectedChat.sender.approved
    }
  },
  /*  methods: {
     async requestChat () {
       this.$store.dispatch(ChatActions.RequestPermission)
     }
   } */
}
</script>
