<template>
  <section>
    <h1 class="montserrat giant-text bold my-2">{{ $t("signup.social_media.title") }}</h1>
    <h2 class="title-text-2 roboto my-2">{{ $t("signup.social_media.description") }}</h2>

    <v-row class="border-bottom" align="center">
      <v-col cols="auto"><img class="brand-icon" src="/images/twitter_blue.svg"/></v-col>
      <v-col cols="auto" class="title-text-2 semibold">Twitter</v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <ConnectTwitter color="secondary" />
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="auto">
        <c-btn
          @click="submit">
          {{ $t('actions.finish') }}
        </c-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
export default {
  name: 'SocialMediaForm',
  methods: {
    submit () {
      this.$emit('skip')
    }
  }
}
</script>
<style scoped>
.brand-icon {
  height: 45px;
  width: 45px;
}
</style>
