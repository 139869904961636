<template>
  <section>
    <Dialog
      :show="value"
      :modeMobile="isMobile"
      :title="$t('models.withdraw.move_funds_title')"
      @hide="close"
    >
    <template slot="bodyDialog">
      <MoveFundsForm
        :wallet="wallet"
        @close="close"
        :initCurrency="selectCahnegeCurrency"
      />
      </template>
    </Dialog>
  </section>
</template>
<script>
import MoveFundsForm from "./MoveFundsForm.vue";
import Dialog from "@/components/chat/component/Dialog";
import { mapState } from "vuex";

export default {
  name: "MoveFunds",
  components: { MoveFundsForm, Dialog },
  props: {
    value: Boolean,
    wallet: Object,
    initCurrency: String
  },
  computed: {
    ...mapState("application", ["isMobile"]),
    selectCahnegeCurrency() {
      return this.initCurrency.includes("USD");
    },
    otherCurrencyText() {
      const other = this.wallet.currency === "EUR" ? "USD" : "EUR";
      return this.$t("wallet.move_funds", { currency: other });
    }
  },
  methods: {
    close() {
      this.$emit("input", false);
    }
  }
};
</script>
