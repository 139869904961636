<template>
  <component :is="cardComponent"
    :user="user"
    :isProfile="isProfile">
  </component>
</template>
<script>
import LargeCard from '@/components/users/cards/LargeCard'
import HorizontalCard from '@/components/users/cards/HorizontalCard'
import VerticalCard from '@/components/users/cards/VerticalCard'

export default {
  name: 'UserCard',
  components: { LargeCard, HorizontalCard, VerticalCard },
  props: {
    user: Object,
    isProfile: { type: Boolean, default: true },
    large: { type: Boolean, default: true },
    horizontal: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false }
  },
  computed: {
    cardComponent () {
      return this.vertical ? VerticalCard
        : this.horizontal ? HorizontalCard
          : LargeCard
    }
  }
}
</script>
