 <template>
  <v-card-actions>
    <v-spacer />
<!--    <c-btn
      text color="secondary"
      @click="$listeners.close">
        {{ $t('actions.close') }}
    </c-btn>-->
    <c-btn
      :disabled="disableNext"
      @click="$listeners.next">
      {{ $t('actions.next') }}
    </c-btn>
  </v-card-actions>
</template>
<script>
export default {
  name: 'PaymentActions',
  props: {
    disableNext: Boolean
  }
}
</script>
