import Vue from "vue";
import Router from "vue-router";
import project from "@/project";

import AdminRoutes, { AdminRouteNames } from "./routes/admin_routes";
import LandingRoutes, { LandingRouteNames } from "./routes/landing_routes";
import AuthenticatedRoutes, { AuthenticatedRouteNames } from "./routes/authenticated_routes";

Vue.use(Router);

// window.location.href = window.location.origin.concat("/wip/maintenance.html");
const validOldRoutes = [
  ...AdminRouteNames,
  ...AuthenticatedRouteNames,
  ...LandingRouteNames,
];
const routes = LandingRoutes.concat(AdminRoutes).concat(AuthenticatedRoutes);

const paths = {
  mode: "history",
  routes: routes.concat([
    {
      path: '*',
      name: 'catchall',
      redirect: "/home"
    }
  ]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  }
};

// global middlewares
const middlewares = [
  require("./middlewares/utm").default,
  require("./middlewares/locale").default,
  require("./middlewares/affiliation").default,
  require("./middlewares/authentication").default,
  require("./middlewares/abilities").default,
  require("./middlewares/layout").default,
];

// Fixing routing same url navigation
const doubleBackslash = window.location.pathname.includes("//");
if (doubleBackslash) {
  window.location.pathname = window.location.pathname.replace("//", "/");
}

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => {
    if (Router.isNavigationFailure(err)) {
      // resolve err

      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const router = new Router(paths);

router.beforeEach(async function (to, from, next) {
  for (let i = 0; i < middlewares.length; i++) {
    const middyResult = await middlewares[i](to, from);
    if (middyResult && middyResult.redirect) {
      next(middyResult.redirect);
      return;
    }
  }
  if (project.redirect_new_frontend) {
    const exists = validOldRoutes.indexOf(to.name) !== -1
    if (!exists) {
      window.location = `${window.location.origin}${to.path}`;
      return;
    }
  }


  next();
})

export default router;
