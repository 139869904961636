<template>
  <v-card-text>
    <p>{{ $t('payments.products.success_message_1', { name: product.name, username: product.username }) }}</p>
    <p>{{ $t('payments.products.success_message_2', { username: product.username }) }}</p>
    <v-row>
      <v-col cols="6">
        <c-img class="rounded product mini"
          :src="product.preview.preview">
        </c-img>
      </v-col>
      <v-col cols="6">
        <UserAvatar :profile="false" :user="product.author" />
        <div class="my-8">
          <v-icon class="mr-1">shopping_basket</v-icon>
          <span>{{ product.name }}</span>
        </div>
        <div class="my-8">
          <v-icon class="mr-1">attach_money</v-icon>
          <Currency :amount="price" />
        </div>
        <c-btn @click="close" :target="redirect ? '_blank' : ''">{{ $t('actions.show_product') }}</c-btn>
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
import { mapState } from 'vuex'
import { ProductActions } from '@/store'
import UserAvatar from '@/components/users/UserAvatar'
import config from '@/project'

export default {
  name: 'ProductInfo',
  components: { UserAvatar },
  props: {
    id: [String, Number]
  },
  data () {
    return {
      product: { preview: '' },
      redirect: config.payments.darkfans_redirect
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    price () {
      return this.product.totalPrice + this.vatPrice
    },
    vatPrice () {
      return Math.ceil(this.product.totalPrice * this.currentUser.billing.vat / 100)
    }
  },
  methods: {
    close () {
      if (config.payments.darkfans_redirect) {
        window.location = `https://darkfans.com/products/${this.id}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
        return
      }
      this.$router.replace({ name: 'product', params: { uuid: this.id } })
      this.$emit('close')
    }
  },
  async mounted () {
    this.product = await this.$store.dispatch(ProductActions.Fetch, { id: this.id, force: true })
  }
}
</script>
<style lang="scss" scoped>
.product.mini {
  max-height: 224px;
  max-width: 224px;
}
</style>
