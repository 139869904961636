<template>
  <v-list-item>
    <v-list-item-icon v-if="hasIcon">
      <slot name="icon"><v-icon color="primary" :my-icon="icon"></v-icon></slot>
    </v-list-item-icon>
    <v-list-item-content v-if="isTitle">
      <span class="title-text semibold">{{ title }}</span>
    </v-list-item-content>
    <v-list-item-content v-if="!isTitle">
      <span class="title-text-2 semibold">{{ title }}</span>
    </v-list-item-content>
    <v-list-item-action v-if="backAction">
      <c-btn text @click="back">{{ $t('actions.back') }}</c-btn>
    </v-list-item-action>
    <v-list-item-action v-else-if="action">
      <c-btn text @click="action.action">{{ action.text }}</c-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>

export default {
  name: 'ListViewTitle',
  props: {
    icon: String,
    title: String,
    backAction: Boolean,
    isTitle: { type: Boolean, default: true },
    action: {
      type: Object,
      validator: (object) => !!object.text && !!object.action
    }
  },
  computed: {
    hasIcon () {
      return this.icon || this.$slots.icon
    }
  },
  methods: {
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss">
  .material-icons:before {
    content: attr(my-icon);
  }
</style>
