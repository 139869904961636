<template>
  <div>
    <iframe ref="myIframe" :src="campaignModalUrl"
            style="position:fixed; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe>
  </div>
</template>

<script>
import Logo from '@/views/landing/specialCampaign/components/Logo'
import dialog from '@/components/mixins/dialog'
import Dialog from '@/views/landing/specialCampaign/components/Dialog'
import CountdownTime from '@/views/landing/specialCampaign/components/CountdownTime'
import project from '@/project'
import messages from '@/views/landing/specialCampaign/locales/all.json'
import cookies from '@/cookies'
import {mapState} from 'vuex'

export default {
  name: 'SpecialCampaingDialog',
  mixins: [dialog],
  components: {
    Logo,
    Dialog,
    CountdownTime
  },
  i18n: {messages},
  props: {},
  data() {
    return {
      project
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isFollower']),
    ...mapState('preferences', ['darkTheme']),
    ...mapState('application', ['isMobile']),
    campaignModalUrl() {
      return `${project.domains.baseUrl}/campaigns/promo/${project.campaign.fileName}/modal.html?follower=${this.isFollower}`
    },
    /*getSrc() {
      return require(`./resources/cabecera_${this.isMobile}_${this.$i18n.locale}.svg`)
    },*/
    /*isFemale() {
      return this.currentUser.tags.filter((t) => t.section === 'gender')[0].name === 'female'
    },*/
    isDark() {
      return this.project.theme === 'dark' || this.darkTheme
    },
    /*bg() {
      const lang = this.$i18n.locale === 'es' ? 'es' : 'en'
      const device = this.isMobile ? 'mb' : 'pc'
      const typeProfile = this.isFollower ? 'follower' : 'creator'
      return `bg_${lang}_${device}_${typeProfile}`
    }*/
  },
  /*created() {
    const root = document.documentElement
    var baseColor = '#f71953'
    if (project.project === 'scatbook') {
      baseColor = '#f47b03'
    }
    if (project.project === 'loverfans') {
      baseColor = '#f71953'
    }
    if (project.project === 'darkfans') {
      baseColor = '#f40303'
    }
    root.style.setProperty('--unnamed-color-base', baseColor)
  },*/
  methods: {
    controllerMessage(event) {
      if (event.data === 'doNotShowMore') {
        this.notShow()
      }
      if (event.data === 'close') {
        this.close()
      }
    },
    close() {
      this.$emit('close')
    },
    goTo() {
      if (this.isFollower) {
        window.location.href = `https://${this.project.project}.com/campaigns/promoverano/index.html`
        /* if (this.project.project === 'loverfans') {
          this.$router.push('/special/nextDoor/home')
        } else {
          this.$router.push({ name: 'stars_discover' })
        } */
      } else {
        window.location.href = `https://${this.project.project}.com/campaigns/academy/promoverano/index.html`
        /*  if (this.project.project === 'loverfans') {
           this.$router.push('/academy/chick_next_door')
         } else {
           this.$router.push({ name: 'resources' })
         } */
      }
    },
    notShow() {
      const cookie = this.project.campaign.cookieModal
      cookies.set(cookie, true)
      this.close()
    }
  },
  mounted() {
    const message = {
      type: 'profile',
      data: this.currentUser
    }
    const iframe = this.$refs.myIframe
    iframe.onload = function () {
      iframe.contentWindow.postMessage(message, '*')
    }
    window.addEventListener('message', this.controllerMessage)
  },
  destroyed() {
    window.removeEventListener('message', this.controllerMessage)
  }
}
</script>
