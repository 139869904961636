<template>
  <v-row class="light-text x-small-text">
    <v-col cols="12" v-if="product.finished">
      <v-icon x-small>timer_off</v-icon>
      <span class="upper">{{ $t('products.auction.finished') }}</span>
    </v-col>
    <v-col cols="12" v-else>
      <v-icon x-small>timer</v-icon>
      <timeago :datetime="finishDate"></timeago>
      (<span>{{ $d(finishDate, 'long') }}</span>)
    </v-col>
    <v-col cols="12" class="py-0">
      (<span>{{ $tc('products.auction.bid_count', product.bidsCount) }}</span>)
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'AuctionTimeDescription',
  props: {
    product: Object
  },
  computed: {
    finishDate () {
      return new Date(this.product.finishAt)
    }
  }
}
</script>
