<template>
  <section>
    <section class="fixed" :class="{dark:isDark}">
      <div class="container camp">
        <div>
          <c-btn icon v-bind="$attrs" :color="isDark?'white':'secondary'" @click.stop="backClicked">
            <v-icon>arrow_back</v-icon>
          </c-btn>
        </div>
        <Logo/>
        <div class="d-flex">
          <c-btn v-if="themesEnabled" :color="isDark?'white':'secondary'" icon @click.stop="changeTheme">
            <v-icon center>compare</v-icon>
          </c-btn>
          <LocaleSelect
            dense
          />
        </div>
      </div>
    </section>
    <div :tabindex="0" v-intersect="handleScroll" v-if="fixed">
      <div></div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Logo from '@/components/custom/newDesign/headers/Logo.vue'
import { StoriesActions, PreferenceActions } from '@/store'
import LocaleDialog from '@/components/custom/LocaleDialog.vue'
import config from '@/project'

export default {
  name: 'CampaignHeader',
  components: {
    LocaleDialog,
    Logo
  },
  props: {
    fixed: Boolean
  },
  data () {
    return {
      visibilityHeader: true,
      themesEnabled: config.enable_themes
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('session', ['authenticated']),
    ...mapState('preferences', ['darkTheme']),
    isDark () {
      return this.$vuetify.theme.dark
    }
  },
  methods: {
    changeTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$store.dispatch(PreferenceActions.UpdateDarkTheme, this.$vuetify.theme.dark)
    },
    backClicked () {
      this.$router.back()
      this.$emit('back')
    },
    handleScroll () {
      const meScroll = window.scrollY
      if (meScroll < 10) {
        this.visibilityHeader = false
      } else {
        this.visibilityHeader = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
  border-bottom: #80808036 solid 1px;
  top: 0;
  z-index: 100;
  background: white;
  width: -webkit-fill-available;
  width: -moz-available;

  &.dark {
    background: #2d3237 !important;
  }
}

.c-slot {
  display: flex;
  align-items: center;
}

.camp {
  width: 100%;
  max-width: 1100px;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}
</style>
