<template>
  <Cover class="white-text">
    <template v-if="!freePost">
      <div class="bold upper">
        <i18n path="posts.card_overlay.signup_text_1">
          <template #time>
            <v-chip class="mx-2" color="info">{{ $t('posts.card_overlay.signup_time')}}</v-chip>
          </template>
        </i18n>
      </div>
      <div class="bold upper mb-2">
        {{ $t('posts.card_overlay.signup_text_2') }}
      </div>
    </template>
    <template v-else>
      <div class="bold upper">
        <i18n path="posts.card_overlay.free_signup_text_1">
          <template #free>
            <v-chip class="mx-2" color="info">{{ $t('posts.card_overlay.free')}}</v-chip>
          </template>
        </i18n>
      </div>
      <div class="bold upper mb-2">
        {{ $t('posts.card_overlay.free_signup_text_2', { username: user.username }) }}
      </div>
    </template>
    <SessionForm @close="emitClose" class="mt-2" />
  </Cover>
</template>
<script>
import Cover from './Cover'
import SessionForm from '../../sessions/SessionForm.vue'
export default {
  name: 'RegisterOverlay',
  components: { Cover, SessionForm },
  props: {
    freePost: Boolean,
    user: String
  },
  methods: {
    emitClose () {
      this.$emit('close')
    }
  },
  beforeDestroy() {
    this.$emit('close')
  }
}
</script>
