<template>
  <c-dialog
    :value="value"
    :actions="actions"
    :closable="closable"
    :fullscreen="fullscreen"
    v-bind="$attrs"
    v-on:input="$emit('input', $event)"
  >
    <template #title>
      <h1>
        {{ title }}
      </h1>
    </template>
    <div v-html="body"></div>
    <template #actions v-if="actions">
      <c-btn :loading="loading" @click="accept">
        {{ textActions ? textActions : $t('actions.ok') }}
      </c-btn>
    </template>
    <div class="pt-5">
      <slot/>
    </div>
  </c-dialog>
</template>
<script>
export default {
  name: 'warning',
  props: {
    title: String,
    textActions: String,
    actions: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: true
    },
    value: Boolean,
    body: String,
    fullscreen: Boolean,
    loading:Boolean
  },
  methods: {
    close() {
      this.$emit('close')
    },
    accept() {
      this.$emit('accept')
      this.$emit('close')
    }
  }
}
</script>
