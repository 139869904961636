<template>
  <section>
    <v-card-text v-if="twitterEnabled">
      <v-row no-gutters align="center">
        <v-col cols="auto"><img class="brand-icon" src="/images/twitter_blue.svg"/></v-col>
        <v-col cols="auto" class="title-text-2 semibold">Twitter</v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <ConnectTwitter />
        </v-col>
      </v-row>
    </v-card-text>
    <v-form v-model="validForm" ref="form"
      @submit.prevent="submit">

      <v-alert type="error"
        v-if="saveError"
        icon="warning"
        dense outlined>
        {{ saveError }}
      </v-alert>

      <v-card-text>
        <v-text-field outlined
          disabled
          persistent-hint
          class="my-4"
          :label="$t('models.user.username')"
          :hint="profileUrl"
          prefix="@"
          type="text"
          v-model="username"></v-text-field>

        <v-text-field outlined class="mb-4"
          persistent-hint
          :label="$t('models.user.displayName')"
          type="text"
          v-model="displayName"
          :rules="[$vBannedWords(displayName),$vMaxLength(50)]"
          :counter="30"
          :hint="$t('models.user.displayName_hint')"></v-text-field>

        <v-text-field outlined
          ref="email"
          persistent-hint
          class="mb-4"
          :label="$t('models.user.email')"
          prepend-inner-icon="email"
          :hint="emailHint"
          type="text"
          :rules="[$vRequired, $vEmail]"
          v-model="email">
        </v-text-field>

        <v-checkbox v-if="isInfluencer"
          :label="$t('models.users.showCount.label')"
          v-model="showStats"
        >
        </v-checkbox>

      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <c-btn @click="submit">{{ $t('actions.save') }}</c-btn>
      </v-card-actions>
      <v-expansion-panels flat style="z-index: 0;">
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('settings.account.password') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <UserPasswordForm />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('settings.account.delete_account') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <UserDeleteAccountForm />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import AccountApi from '@/api/AccountApi'
import validatable from '@/components/mixins/validatable'
import UserDeleteAccountForm from '@/components/users/UserDeleteAccountForm'
import UserPasswordForm from '@/components/users/UserPasswordForm'
import config from '@/project'

export default {
  name: 'UserAccountSettings',
  mixins: [validatable('form')],
  components: { UserDeleteAccountForm, UserPasswordForm },
  data () {
    return {
      saveError: false,
      displayName: null,
      username: null,
      email: null,
      emailValidated: false,
      deleteUsername: '',
      showStats: false,
      twitterEnabled: config.twitter_enabled
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer']),
    profileUrl () {
      const location = window.location
      return `${location.origin}/${this.username}`
    },
    emailHint () {
      const key = this.emailValidated ? 'email_validated_hint' : 'email_not_validated_hint'
      return this.$t(`settings.account.${key}`)
    }
  },
  methods: {
    requestEmail () {
      AccountApi.requestValidation()
    },

    attrs () {
      const attrs = {
        displayName: (this.displayName || '').trim(),
        username: this.username.trim(),
        email: (this.email || '').trim(),
        showStats: this.showStats
      }
      return attrs
    },

    async onSubmit () {
      this.saveError = false
      const { data, error } = await this.$store.dispatch(ProfileActions.UpdateAccountData, this.attrs())
      if (!error) {
        this.$eventBus.publish('notification', {
          message: this.$t('snackbar.save_success')
        })
      } else {
        if (data.data && data.data.field) {
          const field = this.$t(`models.user.${data.data.field}`)
          const error = this.$t(`remote_errors.${data.errorCode}`, { field, value: data.data.value })
          this.saveError = error
          this.$vuetify.goTo(0)
        }
      }
    }
  },
  created () {
    const profile = this.currentUser
    this.displayName = profile.displayName
    this.username = profile.username
    this.email = profile.email
    this.emailValidated = profile.emailValidated
    this.showStats = profile.showStats
  }
}
</script>

<style scoped>
.brand-icon {
  height: 45px;
  width: 45px;
}
</style>
