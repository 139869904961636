<template>
  <PaymentForm
    allow-wallet
    resource="products"
    :resource-id="resource.id"
    :price="totalPrice"
    :wallet-payment-success="walletPaymentSuccess"
    :provider="provider"
    :payment-form-url="paymentFormUrl"
    :error="error"
    :error-message="errorMessage"
    @payment-selected="changePaymentType"
    @payment="doPayment"
    @close="$listeners.close">
    <v-row>
      <v-col cols="12" sm="6">
        <c-img class="rounded thumb"
          :src="previewRoute">
        </c-img>
      </v-col>
      <v-col cols="12" sm="6">
        <UserAvatar :profile="false" :user="resource.author" />
        <div class="my-4 semibold">
          {{ paymentPrice }}
        </div>
        <div class="semibold">
          {{ resource.name }}
        </div>
        <div>
          {{ resource.description }}
        </div>
      </v-col>
    </v-row>
    <template #wallet-payment-success>
      <ProductInfo :id="resource.id" @close="refreshPage" />
    </template>
  </PaymentForm>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions, ProductActions } from '@/store'
import ProductInfo from '@/components/payments/dialogs/ProductInfo'
import PaymentForm from '@/components/payments/forms/PaymentForm'
import UserAvatar from '@/components/users/UserAvatar'
import ProductApi from '@/api/ProductApi'
import config from '@/project'

export default {
  name: 'ProductPayment',
  components: { ProductInfo, PaymentForm, UserAvatar },
  props: {
    resource: Object
  },
  data () {
    return {
      paymentType: '',
      walletPaymentSuccess: false,
      provider: '',
      paymentFormUrl: '',
      error: false,
      errorMessage: ''
    }
  },
  computed: {
    ...mapState('application', ['billing', 'affiliate', 'affiliateSource']),
    ...mapState('profile', ['currentUser']),
    origin () {
      if (config.payments.darkfans_redirect) return `https://darkfans.com?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
      return `${window.location.origin}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
    },
    paymentPrice () {
      const key = this.billing.code === 'CA' ? 'payments.include_ca_vat' : 'payments.include_vat'
      const price = this.$currency(this.totalPrice, this.resource.currency)
      const count = this.currentUser.billing.vat ? 2 : 1
      return this.$tc(key, count, { price })
    },

    isWallet () {
      return this.paymentType === 'wallet'
    },
    vatPrice () {
      const price = this.resource.price + this.resource.shippingCosts
      let vat = this.currentUser.billing.vat
      let expenses = (price * this.currentUser.billing.exes) / 100
      const vatAmount = (price + expenses) * vat / 100

      return Math.ceil(vatAmount + expenses)
    },
    totalPrice () {
      return this.resource.price + this.resource.shippingCosts + this.vatPrice
    },

    previewRoute () {
      return this.resource.resources && this.resource.resources[0].preview
    }
  },
  methods: {
    resetState () {
      this.walletPaymentSuccess = false
      this.paymentFormUrl = ''
      this.error = false
      this.errorMessage = ''
    },
    changePaymentType (payment) {
      this.paymentType = payment
    },

    async doPayment ({ name }) {
      const action = this.isWallet ? this.walletPayment(name) : this.creditCardPayment(name)
      const { data, error } = await action
      if (error) {
        this.error = error
        this.errorMessage = data.error
      }
    },
    async walletPayment (name) {
      const result = await this.$store.dispatch(ProductActions.Acquire, {
        id: this.resource.id, attrs: { name, affiliate: this.affiliate }
      })
      if (!result.error) {
        this.$store.dispatch(ProfileActions.Fetch)
        this.walletPaymentSuccess = true
        this.$emit('payment-success')
      }
      return result
    },
    async creditCardPayment (name) {
      const { data, error } = await ProductApi.requestPayment(this.resource.id, {
        name, affiliate: this.affiliate, affiliateSource: this.affiliateSource, origin: this.origin
      })
      if (!error) {
        if (data.redirectUrl) {
          const slash = data.redirectUrl.includes('?') ? '&' : '?';
          window.location = `${data.redirectUrl}${slash}provider=${data.project}`
        } else {
          this.provider = data.provider
          this.paymentFormUrl = data.url
        }
      }
      return { data, error }
    },
    refreshPage () {
      location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.thumb {
  height: 224px;
  width: 224px;
  max-height: 224px;
  max-width: 224px;
  .v-image__image {
    filter: blur(1rem);
  }
}
</style>
