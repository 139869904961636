<template>
  <div v-if="isOnlineVideoCall" :class="css" @click.stop="videoCall">
    <div v-if="small">
      <img
        src="/chat/ico-video-call.svg"
        alt=""
        class="img-ico"
        :width="width"
        :height="height"
      />
    </div>
    <div v-else class="buton-call">
      <img
        src="/chat/ico-video-call.svg"
        alt=""
        class="img-ico"
        :width="width"
        :height="height"
      />
      {{ $t("actions.private") }}
    </div>
  </div>
</template>
<script>
import { ChatActions } from "@/store";
import { mapState } from "vuex";
export default {
  name: "BtnVideoCall",
  props: {
    user: { type: Object },
    css: [Object, String],
    small: { type: Boolean, default: false },
    width: { type: Number, default: 20 },
    height: { type: Number, default: 20 }
  },
  computed: {
    ...mapState("application", ["isMobile"]),
    ...mapState("profile", ["currentUser"]),
    isOnlineVideoCall() {
      const isOnline = this.userLocal.onlineStatus === "online";
      const noCurrentUser = this.currentUser.username !== this.user.username;
      let videochatEnabled = true;
      if (!this.user.videochatEnabled) {
        videochatEnabled = false;
      }
      return isOnline && noCurrentUser && videochatEnabled;
      //return this.userLocal.isOnline;
    }
  },
  data() {
    const user = this.user
      ? this.user
      : { username: this.currentUser.username, onlineStatus: "" };
    return {
      userLocal: user
    };
  },
  methods: {
    videoCall() {
      this.$router.push({
        name: "messages",
        query: { username: this.userLocal.username, call: true }
      });
      this.$store.dispatch(ChatActions.ShowVideoChat, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.buton-call {
  background-color: #77f8da;
  color: #1cb78a;
  border-radius: 20px;
  padding: 3px;
  cursor: pointer;
  display: flex;
  margin-right: 5px;
  width: fit-content;
  align-items: center;
}
.img-ico {
  object-fit: fill;
}
</style>
