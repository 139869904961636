<template>
  <section>
    <WarningDialog
      :show="showWarning"
      :textWarning="text"
      :textButtonOK="textButtonOk"
      @handlerButtonCancel="close"
      @handlerButtonOK="setPreferences"
      :loading="loadingButton"
    />
    <div class="row mt-2 notification" :class="cssClass">
      <div class="col">
        <span v-if="isTip">
          <icon-button type="notif-tip" disabled disabledDarkTheme />
          <span
            v-html="
              $t(
                'new_chat.notifications.tip.' +
                  (isSameUser ? 'user' : 'influencer'),
                { username: selectedChat.receiver.username, amount: amount }
              )
            "
          />
        </span>
        <span v-if="isPurchase">
          <icon-button type="notif-unblock" disabled disabledDarkTheme />
          <span
            v-html="
              $t(
                'new_chat.notifications.purchase.' +
                  (isSameUser ? 'user' : 'influencer'),
                { username: selectedChat.receiver.username, amount: amount }
              )
            "
          />
        </span>
        <span v-if="isNewFan">
          <icon-button type="notif-fan" disabled disabledDarkTheme />
          <span
            v-html="
              $t(
                'new_chat.notifications.fan.' +
                  (isSameUser ? 'user' : 'influencer'),
                { username: selectedChat.receiver.username }
              )
            "
          />
        </span>
        <span v-if="isFree">
          <icon-button type="notif-free" disabled disabledDarkTheme />
          <span
            v-html="
              $t(
                'new_chat.notifications.free.' +
                  (isSameUser ? 'user' : 'influencer')
              )
            "
          />
        </span>
        <span v-if="isPayment">
          <icon-button type="notif-payment" disabled disabledDarkTheme />
          <span
            v-html="
              $t(
                'new_chat.notifications.payment.' +
                  (isSameUser ? 'user' : 'influencer'),
                { amount: amount }
              )
            "
          />
        </span>
        <span v-if="isLostCall">
          <icon-button type="lost-call" disabled disabledDarkTheme />
          <span
            v-html="
              $t('new_chat.notifications.lost_call.called_me', {
                user: username
              })
            "
          />
        </span>
        <span v-if="isRejectedCall">
          <icon-button type="rejected-call" disabled disabledDarkTheme />
          <span
            v-html="
              $t('new_chat.notifications.lost_call.called', {
                user: username
              })
            "
          />
        </span>
        <span v-if="isCall">
          <icon-button type="in-call" disabled disabledDarkTheme />
          <span
            v-html="
              $tc('new_chat.notifications.call', timeInCall, {
                user: username,
                time: timeInCall
              })
            "
          />
        </span>

        <span v-if="isTranslated" @click="switchTranslate" class="canClick">
          <icon-button type="notif-info" disabledDarkTheme />
          <span v-html="$t('new_chat.notifications.translated')" />
        </span>
        <span v-if="isSubscriptionMessage">
          <icon-button type="notif-info" disabledDarkTheme class="subscriptionOfferMessage" />
          <p v-html="decodeMessage(notification.message)" />
          <PaymentButton
            class="ml-auto"
            v-if="showSubscriptionButton"
            :available="user.subscribable"
            :color="subscribeButtonColor"
            :show-init="showPaymentButton"
            small
            subscription
            :resource="user"
          />
        </span>
        <span v-if="isNotTranslated" @click="switchTranslate" class="canClick">
          <icon-button type="notif-info" disabled disabledDarkTheme />
          <span v-html="$t('new_chat.notifications.not_translated')" />
        </span>
      </div>
      <icon-button
        :size="14"
        style="margin-left: auto"
        type="close"
        disabledDarkTheme
        @handler="deleteNotif"
      />
    </div>
  </section>
</template>

<script>
import IconButton from "@/components/chat/component/IconButton.vue";
import { mapGetters, mapState } from "vuex";
import ConversationsApi from "@/api/conversations/ConversationsApi";
import project from "@/project";
import WarningDialog from "@/components/chat/component/WarningDialog";
import {ChatActions} from "@/store";
import PaymentButton from "@/components/payments/PaymentButton.vue";

export default {
  name: "NotificationMessage",
  components: {PaymentButton, IconButton, WarningDialog },
  props: {
    notification: Object,
    username: String,
    messageId: Number,
    message: Object,
    user: Object
  },
  data() {
    return {
      showWarning: false,
      text: "",
      textButtonOk: "",
      type: "",
      loadingButton: false,
      showPaymentButton: false,
      project
    };
  },
  computed: {
    ...mapState("chats", ["selectedChat"]),
    ...mapState("profile", ["currentUser", "isFollower"]),
    ...mapGetters("chats", ["getPreferences"]),
    showSubscriptionButton () {
      return !this.isCurrentUser
    },
    subscribeButtonColor () {
      return this.canSubscribe && this.user.subscribed ? 'white' : 'primary'
    },
    cssClass() {
      return {
        tip: this.isTip,
        content: this.isPurchase,
        fan: this.isNewFan,
        info: this.isTranslated || this.isNotTranslated,
        free: this.isFree,
        payment: this.isPayment,
        subscriptionOffer: this.isSubscriptionMessage,
        "lost-call": this.isLostCall,
        "reject-call": this.isRejectedCall,
        call: this.isCall,
        dark: this.$vuetify.theme.dark
      };
    },
    isSameUser() {
      return this.message.userId === this.currentUser.id;
    },
    isTip() {
      return this.notification.code === "tip";
    },
    isPurchase() {
      return this.notification.code === "purchase";
    },
    isNewFan() {
      return this.notification.code === "fan";
    },
    isTranslated() {
      return this.notification.code === "translate_on";
    },
    isNotTranslated() {
      return this.notification.code === "translate_off";
    },
    isPriceChange() {
      return this.notification.code === "price_changed";
    },
    isSubscriptionMessage() {
      return this.notification.code === "influencerMessage";
    },
    isLostCall() {
      return this.notification.code === "lost_call" && !this.imCalleded;
    },
    imCalleded() {
      const user = this.currentUser.username;
      return this.notification.userStarter === user
    },
    isRejectedCall() {
      return this.notification.code === "lost_call" && this.imCalleded;
    },
    isCall() {
      return this.notification.code === "call";
    },
    timeInCall() {
      return Math.ceil(this.notification.time / 60000);
    },
    receiverAmount() {
      return this.notification.receiver.amount;
    },
    senderAmount() {
      return this.notification.sender.amount;
    },
    senderAmountCurrency() {
      return this.$currency(
        this.notification.sender.amount,
        this.notification.sender.currency
      );
    },
    receiverAmountCurrency() {
      return this.$currency(
        this.notification.receiver.amount,
        this.notification.receiver.currency
      );
    },
    amountCurrency() {
      return this.$currency(
        this.notification.amount,
        this.notification.currency
      );
    },
    isOldNotificationPrice() {
      return !this.notification.receiver;
    },
    isFree() {
      if (!this.isPriceChange) return;
      if (this.isOldNotificationPrice) {
        return this.notification.amount === 0;
      }
      if (this.isFollower) {
        return this.receiverAmount === 0;
      } else {
        return this.senderAmount === 0;
      }
    },
    isPayment() {
      if (!this.isPriceChange) return;
      return !this.isFree;
    },
    amount() {
      if (this.isOldNotificationPrice) {
        return this.amountCurrency;
      }
      if (this.isFollower) {
        if (this.isTip) {
          return this.senderAmountCurrency;
        } else {
          return this.receiverAmountCurrency;
        }
      } else {
        if (this.isTip) {
          return this.receiverAmountCurrency;
        } else {
          return this.senderAmountCurrency;
        }
      }
    }
  },
  methods: {
    switchTranslate() {
      const action = this.getPreferences.translate
        ? this.$t("actions.nottranslate")
        : this.$t("actions.translate");
      this.type = this.getPreferences.translate ? "nottranslate" : "translate";
      this.createWarning(action);
    },
    decodeMessage (message) {
      try {
        return decodeURIComponent(decodeURIComponent(decodeURIComponent(message)))
      } catch (ex) {
        try {
          return decodeURIComponent(decodeURIComponent(message))
        } catch (ex) {
          try {
            return decodeURIComponent(message)
          } catch (ex) {
            return message
          }
        }
      }
    },
    async setPreferences() {
      this.loadingButton = true;
      const id = this.selectedChat.id;
      const preferences = Object.assign({}, this.getPreferences);
      if (this.type === "nottranslate") {
        preferences.translate = false;
      }
      if (this.type === "translate") {
        preferences.translate = true;
      }
      const { data, error } = await ConversationsApi.preferences(
        id,
        preferences
      );
      if (!error) {
        const selectedChat = Object.assign({}, this.selectedChat);
        selectedChat.sender.translate = preferences.translate;

        this.$store.dispatch(ChatActions.UpdateAttributes, selectedChat);
        this.$store.dispatch(ChatActions.RefreshConversation, {
          action: "updated",
          value: true,
          idChat: this.selectedChat.id
        });
        this.$emit("reload-conversations");
        this.loadingButton = false;
      }
      this.close();
    },
    createWarning(action) {
      const user = this.selectedChat.receiver.username.toUpperCase();
      this.text = this.$t("chats.want", { action: action, user: user });
      this.textButtonOk = action.toUpperCase();
      this.showWarning = true;
    },
    close() {
      this.showWarning = !this.showWarning;
    },
    async deleteNotif() {
      const conversationId = this.selectedChat.id;
      const conversationMessageId = this.messageId;
      const { data, error } = await ConversationsApi.deleteNotification(
        conversationId,
        conversationMessageId
      );
      if (!error) {
        this.$emit("deleteNotif", this.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.notification {
  max-width: 95%;
  width: 95% !important;
  margin: auto;
  font-weight: 500;
  font-family: Quicksand, sans-serif;
  font-size: 1em;
  border-left: #7c7c7c;
  border-left-style: groove;
  border-left-width: 5px;
  padding: 4px 0px;
  .col {
    padding: 0;
  }
  span {
    display: flex;
    max-width: 95%;
    align-items: center;
  }
  &.tip {
    background-color: #afff00;
    &.dark {
      span {
        color: black !important;
      }
    }
  }
  &.content {
    background-color: #00faff;
    &.dark {
      span {
        color: black !important;
      }
    }
  }
  &.fan {
    background-color: #ffd92b;
    &.dark {
      span {
        color: black !important;
      }
    }
  }
  &.free {
    background-color: #ff9bf1;
    &.dark {
      span {
        color: black !important;
      }
    }
  }
  &.payment {
    background-color: #ffd92b;
    &.dark {
      span {
        color: black !important;
      }
    }
  }
  &.info {
    background-color: #ffffff !important;
    &.dark {
      span {
        color: black !important;
      }
    }
  }
  &.lost-call {
    background-color: #fe924a !important;
    &.dark {
      span {
        color: black !important;
      }
    }
  }
  &.reject-call {
    background-color: #ffd000 !important;
    &.dark {
      span {
        color: black !important;
      }
    }
  }
  &.call {
    background-color: #ffffff !important;
    border-top: solid #7c7c7c 1px;
    border-right: solid #7c7c7c 1px;
    border-bottom: solid #7c7c7c 1px;
    &.dark {
      span {
        color: black !important;
      }
    }
  }
}

.canClick:hover {
  cursor: pointer;
}

.subscriptionOffer {
  background-color: #00a643;
  color: black !important;
}
</style>
