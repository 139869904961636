<template>
  <ListView no-gutters
    cols="4" sm="3"
    :loading="cursor.loading"
    :items="cursor.items"
    item-class="dynamic-padding"
    :empty-text="$t('listview.no_purchases')"
    @bottom-reached="cursor.loadItems">
    <template #default="{ item }">
      <ProductCard :product="item" />
    </template>
  </ListView>
</template>

<script>
import ProductService from '@/components/products/mixins/product_service'
import ProductCard from '@/components/products/ProductCard'

const cursor = () => [{
  name: 'cursor', action: 'products/list_acquired'
}]

export default {
  name: 'UserShopping',
  mixins: [ProductService({ cursors: cursor() })],
  components: { ProductCard }
}
</script>
<style lang="scss" scoped>
.dynamic-padding {
  padding: 0.5% !important;
}
</style>
