<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500">
    <template #activator="{ on }">
      <v-btn
        v-bind="$attrs"
        v-on="on"
        depressed
        :rounded="rounded"
        :class="css"
        :style="buttonContentStyle"
        @click.stop="showDialog"
        @mouseover="hover = true"
        @mouseleave="hover = false">
        <slot />
      </v-btn>
    </template>
    <v-toolbar flat>
      <slot name="title"></slot>
      <v-spacer></v-spacer>
      <v-btn icon
        :disabled="isRequesting"
        @click.stop="hideDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card flat tile>
      <v-card-text>
        <slot name="question">{{ confirmationQuestion }}</slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <c-btn
          text color="secondary"
          :disabled="isRequesting"
          @click="hideDialog">
          {{ $t('actions.close') }}
        </c-btn>
        <c-btn
          :disabled="isRequesting"
          @click="confirm">
          {{ $t('actions.ok') }}
        </c-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dialog from '@/components/mixins/dialog'

export default {
  name: 'ConfirmBtn',
  mixins: [dialog],
  props: {
    transform: { type: String, default: 'uppercase' },
    hoverColor: { type: String, default: null },
    css: [Object, String],
    rounded: { type: Boolean, default: true },
    hoverOutlined: { type: Boolean, default: null },
    question: { type: String }
  },
  data () {
    return {
      isRequesting: false,
      hover: false
    }
  },
  computed: {
    confirmationQuestion () {
      return this.question || this.$t('hints.action_confirmation')
    },
    buttonContentStyle () {
      return { 'text-transform': this.transform }
    },
    isDark () {
      if (this.dark === null) {
        return null
      }
      return this.dark
    }
  },
  methods: {
    async confirm () {
      this.isRequesting = true
      const listener = this.$listeners.confirmed
      try {
        await listener()
      } catch(err) {
      } finally {
        this.hideDialog()
        this.isRequesting = false
      }
    }
  }
}
</script>
