<template>
  <section>
    <ListView v-if="loading"
      :cols="listViewCols"
      :items="skeletons"
      :class="css">
      <template #default>
        <v-skeleton-loader :type="dummyType">
        </v-skeleton-loader>
      </template>
    </ListView>
    <slot v-else />
  </section>
</template>
<script>

export default {
  name: 'DummyLoader',
  props: {
    grid: Boolean,
    card: Boolean,
    text: Boolean,
    page: Boolean,
    loading: Boolean,
    css: String,
    cols: { type: [String, Number], default: 1 },
    itemCount: { type: [String, Number], default: 1 },
    skeleton: String
  },
  computed: {
    listViewCols () {
      return parseInt(12 / parseInt(this.cols))
    },
    skeletons () {
      return new Array(parseInt(this.itemCount))
    },
    dummyType () {
      return this.skeleton
        ? this.skeleton
        : this.card
          ? 'image, list-item-avatar-two-line'
          : this.text
            ? 'article'
            : 'list-item-avatar-two-line, image, sentences'
    }
  }
}

</script>
