<template>
  <div :class="hiddenClass">
    <c-btn v-bind="$attrs" transform="none" @click.stop="toggleFollow" :disabled="isHidden">
      {{ followingText }}
      <span v-if="user.following" slot="hover">
        {{ $t("actions.unfollow") }}
      </span>
    </c-btn>
  </div>
</template>
<script>
import { UserActions } from "@/store";
import cookies from "@/cookies";
import { mapState } from "vuex";

export default {
  name: "FollowingButton",
  props: { user: Object },
  data () {
    return {
      notShowUsers: ['Loverfans', 'Scatbook', 'Darkfans']
    }
  },
  computed: {
    ...mapState("session", ["authenticated"]),
    followingText() {
      return this.user.following
        ? this.$t("users.following")
        : this.$t("actions.follow");
    },
    hiddenClass () {
      return {
        hidden: this.notShowUsers.find(x => x === this.user.username)
      }
    },
    isHidden () {
      return this.notShowUsers.find(x => x === this.user.username)
    }
  },
  methods: {
    toggleFollow() {
      if (!this.authenticated) {
        sessionStorage.setItem("origin", this.$router.currentRoute.fullPath);
        sessionStorage.setItem("autoFollow", this.user.username);
        this.$router.push("/login");
      }
      return this.$store.dispatch(UserActions.ToggleFollow, this.user.username);
    }
  },
  mounted() {
    if (sessionStorage.getItem("autoFollow") === this.user.username) {
      this.toggleFollow();
    }
    sessionStorage.removeItem("autoFollow");
  }
};
</script>
<style lang="scss" scoped>
.hidden {
  display: none;
}
</style>

