<template>
  <section style="overflow: hidden">
    <v-app-bar app flat style="min-width: 265px !important; min-height: 65px;">
      <slot name="prepend" />
      <v-col :cols="isMobile? 12 : 10" class="app-wrapper d-flex align-center" style="margin:auto" >
        <router-link :to="{ name: 'home' }" style="margin-right: auto">
          <img
            :src="`/images/${brandingLogo}.svg?dfk23`" :alt="project.project"
            :width="isMobile ? 135 : 200" :height="isMobile ? 21 : 32" />
        </router-link>
        <v-spacer></v-spacer>
        <v-row>
          <v-col cols="1" style="margin-left: auto; margin-right: 15px;">
            <v-menu
              top
              close-on-content-click
            >
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  :style="{marginTop: isLarge ? '5px' : '15px'}"
                >
                  <img width="24" height="17" :src="lang.image" :alt="lang.value">
                </v-btn>
              </template>

              <v-list style="overflow: hidden;" class="locale">
                <v-list-item
                  style="margin-bottom: -15px;margin-top: -15px;"
                  v-for="(item, index) in langs"
                  :key="index"
                >
                  <v-list-item-title @click="setLocale(item)" style="font-family: Quicksand, sans-serif;"><span class="flag"><img width="20" height="13" :src="item.image" style="margin-right: 10px;" :alt="item.value"></span>{{item.value}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="auto">
            <v-row>
              <v-col v-if="isMobile || !isLarge">
                <v-btn icon @click="drawer = !drawer"><v-icon>reorder</v-icon></v-btn>
              </v-col>
              <v-col v-if="!isMobile && isLarge">
                <NewConnectTwitter :callbackUrl="project.domains.baseUrl + '/login'" small style="margin-right: 20px;" color="#ffffff" :txtcolor="'#000000'" :fontSize="'19.2px'"
                                   :connecting.sync="connectingTwitter" :fontFamily="'BenchNine, sans-serif'"
                                   @twitter-connected="redirect"></NewConnectTwitter>
              </v-col>
              <v-col v-if="!isMobile && isLarge">
                <v-btn :color="'#ffffff'" small style="margin-right: 20px;" :to="{ name: 'login', params: { home: 'fan' } }"><img src="images/seconds.svg" height="20" width="20" /> <span style="color: #31AE00; font-family: BenchNine, sans-serif; font-size: 19.2px">30"REGISTER</span></v-btn>
              </v-col >
              <v-col v-if="!isMobile && isLarge">
                <v-btn :color="'#ffffff'" small :to="{ name: 'login', params: { home: 'login' } }"><strong style="color: black; font-family: BenchNine, sans-serif; font-size: 19.2px">LOGIN ➲</strong></v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-bottom-navigation
          style="position: absolute; bottom: -175px;height: 175px;background-color: #f2f2f2"
          v-if="drawer"
          v-model="drawer"
        >
          <v-list style="width: inherit">
            <v-list-item>
              <NewConnectTwitter :isBtn="false" style="margin-right: 19px;" color="#ffffff" :fontSize="'19.2px'"
                                 :connecting.sync="connectingTwitter" :fontFamily="'Quicksand, sans-serif'"
                                 @twitter-connected="redirect"></NewConnectTwitter>
            </v-list-item>
            <v-list-item :to="{ name: 'login', params: { home: 'fan' } }">
              <v-col><img src="images/seconds.svg" height="32" width="32" alt="seconds" /><strong style="color: #31AE00; font-family: Quicksand, sans-serif; font-size: 19.2px; margin-left: 12px;">30"REGISTER</strong></v-col>
            </v-list-item>
            <v-list-item :to="{ name: 'login', params: { home: 'login' } }">
              <v-col><strong style="color: #F71953; font-family: Quicksand, sans-serif; font-size: 19.2px; margin-left: 12px;">LOGIN ➲</strong></v-col>
            </v-list-item>
          </v-list>
        </v-bottom-navigation>
      </v-col>
    </v-app-bar>

    <v-col cols="12" class="align-center" style="margin: auto;text-align: center">

      <p class="header" v-html="$t(`index.header.${project.project}`)"></p>

      <p class="counter"><span class="dest-text" style="font-family: 'BenchNine', sans-serif;">{{formatNumber(influencersCount)}}</span> {{$t(`index.counter.creators`) }} <span class="dest-text" style="font-family: 'BenchNine', sans-serif;">| {{formatNumber(videos)}}</span> {{$t(`index.counter.videos`) }} <span class="dest-text" style="font-family: 'BenchNine', sans-serif;">| {{formatNumber(photos)}}</span> {{$t(`index.counter.photos`) }}
      </p>

      <v-row style="margin-bottom: 30px;">
        <v-col cols="11" style="margin: 0px auto auto auto;max-width: 1265px;min-width: 256px;">
          <v-row :style="{marginLeft: isMobile ? 'null' : 'null'}">
            <v-col cols="12" style="text-align: left; padding: 0px 0px 0px 10px;">
              <strong style="font-size: 2em; font-family: 'Quicksand', sans-serif;font-size: 32px;">{{ $t('discover.new')}}</strong>
              <v-row>
                <v-col cols="auto" style=" padding: 0px 0px 0px 12px;">
                  <v-btn color="primary" style="max-height: 30px" tile depressed @click="order = 'featured:desc,relevance:desc'"><span class="buttons">{{ $t('discover.top')}}</span></v-btn>
                </v-col>
                <v-col cols="auto" style=" padding: 0px 0px 0px 8px;">
                  <v-btn color="primary" style="max-height: 30px" tile depressed @click="order = 'featured:desc,verifiedAt:desc'"><span class="buttons">{{$t('discover.newest')}}</span></v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        <v-row>
          <v-col cols="12" class="text-center" style="height: 100%" v-if="cursor.loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" v-for="item of cursor.items" :key="item.username">
            <Box ratio="1">
              <router-link :to="item.username" class="card-star">
                <v-img
                  :src="item.avatarS3Route || '/images/no_content.png'" style="min-height: 100%">
                  <div v-if="item.postsStatistics.open" class="free-content">
                    FREE CONTENT
                  </div>
                  <v-row no-gutters class="bottom">
                    <v-btn rounded class="starName">
                      <span v-if="item.username.length < 12" style="font-size: 14px; font-family: 'Quicksand', sans-serif;">{{item.username}}</span>
                      <span v-else style="font-size: 14px; font-family: 'Quicksand', sans-serif;">{{ item.username.substring(0,12)}}...</span>
                    </v-btn>
                    <div class="stats">
                        <img :src="`/images/landing/ico-fotos.svg`" alt="photo" />
                        <span style="font-family: Quicksand, sans-serif !important"> {{ item.postsStatistics.image || 0 }} </span>
                        <v-spacer />
                        <img :src="`/images/landing/ico-video.svg`" alt="video"/>
                        <span style="font-family: Quicksand, sans-serif !important"> {{ item.postsStatistics.video || 0 }} </span>
                    </div>
                  </v-row>
                </v-img>
              </router-link>
            </Box>
          </v-col>

          <v-col cols="12">
            <v-row justify="end" >
              <v-col cols="auto">
                <v-btn color="primary" tile depressed @click="cursor.loadItems">
                  <span style="font-family: Arial; font-size: 16px; color: white" v-if="!cursor.loading">{{ $t('actions.see_more') }}➤</span>

                  <v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        </v-col>
      </v-row>
    </v-col>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import project from '@/project'
import UserService from '@/components/users/mixins/user_service'
import dialog from '@/components/mixins/dialog'
import AppFooter from '@/components/layouts/AppFooter'
import PostApi from '@/api/PostApi'
import UserApi from '@/api/UserApi'
import cookies from '@/cookies'
import NewConnectTwitter from '@/components/twitter/NewConnectTwitter'

export default {
  name: 'Discover',
  mixins: [UserService({ cursor: true }), dialog],
  components: { AppFooter, NewConnectTwitter },
  data () {
    return {
      order: 'relevance:desc',
      drawer: false,
      influencersCount: 4500,
      videos: 3.3,
      photos: 5.5,
      portrait: false,
      country: { image: '/images/ico-world.svg', el: '', value: 'WorldWide' },
      worldWide: { image: '/images/ico-world.svg', el: '', value: 'WorldWide' },
      locales: ['en', 'es', 'de', 'fr', 'it', 'pt'],
      lang: '',
      connectingTwitter: false,
      windowWidth: window.innerWidth,
      perPage: 12,
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    validCountries () {
      const cs = ['US', 'ES', 'GB', 'FR' ,'IT', 'BR', 'JP', 'DE', 'PT'];
      return this.$t('countries').filter(c => cs.includes(c.value))
    },
    countries () {
      return this.validCountries.map(c => ({
          image: `https://flagcdn.com/28x21/${c.value.toLowerCase()}.png`,
          el: c.value,
          value: c.text
      }))
    },
    langs () {
      return this.locales.map(locale => ({
          image: locale === 'en' ? '/images/usa-flag-icon-20.jpg' : `https://flagcdn.com/28x21/${locale}.png`,
          text: this.$t(`langs.names.${locale}`),
          value: locale
      }))
    },
    brandingLogo () {
      return this.$vuetify.theme.dark ? 'logo_horizontal_white' : 'logo_horizontal'
    },
    orderOptions () {
      return [{
        text: this.$t('models.users.order.ranking'),
        value: 'relevance:desc'
      }, {
        text: this.$t('models.users.order.verifiedAt'),
        value: 'verifiedAt:desc'
      }]
    },
    query () {
      return {
        role: 'influencer',
        includeSelf: false,
        order: this.order,
        includeTags: true,
        ...this.$store.getters['preferences/selected_tags_ids'],
        limit: this.perPage,
        country: this.country.el,
        // DARKFANS
        darkfans: project.project === 'darkfans',
        lang: null
      }
    },
    isLarge () {
      return this.windowWidth > 1160
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    setCountry (item) {
      this.country = item
    },
    setLocale (item) {
      this.$i18n.loadLocale(item.value)
      this.$timeago.locale = item.value
      this.$vuetify.lang.current = item.value
      this.lang = item
      cookies.set('locale', item.value)
    },
    changeOrder (index) {
      this.dialog = false
      this.order = this.orderOptions[index].value
      this.cursor.onLoadItems = !this.dialog
    },
    handleOrientationChange () {
      const orientation = window.screen.orientation.type
      if (orientation === 'portrait-primary') {
        this.portrait = true
      } else if (orientation === 'landscape-primary') {
        this.portrait = false
      }
    },
    freeContent (item) {
      return {
        'free-content': item.postsStatistics.open
      }
    },
    canLoadStars () {
      return !this.dialog
    },
    async loadTotals () {
      const { data, error } = await UserApi.count()
      if (!error) {
        this.influencersCount = data.find(d => d.role === 'influencer').total
        this.followersCount = data.find(d => d.role === 'follower').total
      }
    },
    redirect () {
      const origin = sessionStorage.getItem('origin')
      if (origin) {
        this.$router.replace(origin)
        sessionStorage.removeItem('origin')
      } else {
        this.$router.replace('/home')
      }
    },
    formatNumber (value) {
      var newValue = value
      if (value >= 1000) {
        var suffixes = ['', 'K', 'M', 'B', 'T']
        var suffixNum = Math.floor(('' + value).length / 3)
        var shortValue = ''
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision))
          var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
          if (dotLessShortValue.length <= 2) { break }
        }
        if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1)
        newValue = shortValue < 1 ? Math.floor(value / 1000) + suffixes[suffixNum - 1] : shortValue + suffixes[suffixNum]
      }
      return newValue
    }
  },
  async mounted () {
    window.addEventListener(
      'orientationchange',
      this.handleOrientationChange
    )

    window.addEventListener('resize', this.onResize)

    this.loadTotals()
    this.lang = this.langs.find(lang => lang.value === cookies.get('locale')) || this.langs[0]

    const { data, error } = await PostApi.count()
    if (!error) {
      this.photos = data.image
      this.videos = data.video
    }

    this.cursor.onLoadItems = this.canLoadStars
  }
}
</script>

<style lang="scss" scoped>

.header {
  font-family: 'Quicksand', sans-serif !important;
  font-size: 16px;
}

p.counter {
  font-family: 'BenchNine-Light', sans-serif;
  font-size: 20.8px;
  line-height: 1.2em;
  border-radius: 20px;
  .dest-text {
    color: var(--v-primary-base);
  }
}

.buttons {
  font-size: 13px;
  font-family: Arial;
}

.starName {
  background-color: white;
  color: black;
  margin: auto;
  width: fit-content;
  max-width: 167px;
  max-height: 25px;
  text-transform: capitalize;
}

.stats {
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 16px;
  img {
    margin-right: 5px;
  }
}

.free-content {
  position: absolute;
  top: 21px;
  right: -28px;
  color: #ffffff;
  background-color: #35dd3d;
  padding: 0px 20px;
  font-size: 0.7em;
  line-height: 25px;
  transform: rotate(45deg);
}

.card-star {
  margin: auto;
  display: flex;
  position: relative;
  max-height: 430px;
  min-height: 220px;
  height: 100%;
  width: 100%;
  .bottom {
    font-size: 20px;
    position: absolute;
    height: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgb(0, 0, 0) 24%, rgba(18, 9, 1, 0) 100%);
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.locale :hover{
  background-color: #F2F2F2 !important;
}

</style>
