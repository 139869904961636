<template>
  <v-row no-gutters align="center" class="mb-2">
    <v-row no-gutters align="center">
      <v-col class="flex-grow">
        <UserAvatar :user="product.author">
          <template #name>
            <span class="semibold mr-3">
              {{ author.displayName }}</span>
            <span class="roboto">
              {{ `@${author.username}` }}</span>
          </template>
          <template #username>
            <span class="roboto small-text italic">
              {{ $datetime(product.publishedAt) }}
            </span>
          </template>
        </UserAvatar>
      </v-col>
      <v-col cols="auto">
        <v-btn v-if="isAuthor"
          icon
          @click="$emit('edit')">
          <v-icon color="primary">edit</v-icon>
        </v-btn>
        <ReportMenu
          copy-link
          :id="product.id"
          resource="products"
          :item-link="link"
          :remove-item="isAuthor"
          :report-item="!isAuthor"
          @remove-item="onRemoveItem" />
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import ProductService from '@/components/products/mixins/product_service'
import UserAvatar from '@/components/users/UserAvatar'
import ReportMenu from '@/components/custom/ReportMenu'

export default {
  name: 'ProductAuthor',
  mixins: [ProductService()],
  components: { UserAvatar, ReportMenu },
  props: {
    product: { type: Object, default: () => ({}) }
  },

  computed: {
    ...mapState('profile', ['currentUser']),
    author () {
      return this.product.author || { username: '' }
    },
    isAuthor () {
      return this.author.username === this.currentUser.username
    },
    link () {
      const url = this.$router.resolve({ name: 'product', params: { uuid: this.product.id } })
      const relativePath = url.href
      const origin = window.location.origin
      return this.fullPath || `${origin}${relativePath}`

    }
  },
  methods: {
    async onRemoveItem () {
      const { data, error } = await this.deleteProduct(this.product.id)
      this.showNotification(data, error)
      if (this.$route.path.includes('/products/')) {
        this.$router.back()
      }
    },

    showNotification (data, error) {
      const notification = {}
      if (error) {
        notification.message = this.$t(`remote_errors.${data.errorCode}`)
        notification.error = true
      } else {
        notification.message = this.$t('snackbar.delete_success')
      }
      this.$eventBus.publish('notification', notification)
    }
  }
}
</script>
