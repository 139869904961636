<template>
  <section>
    <v-data-table
      class="elevation-2"
      :headers="visibleHeaders"
      :items="items"
      :loading="loading"
      item-key="id"
      :page.sync="page"
      :items-per-page.sync="perPage"
      :server-items-length="totalItems"
      @update:page="loadItems">
      <template #top>
        <v-row no-gutters align="center">
          <v-col cols="12" sm="3" class="pa-2">
            <date-picker-input range :label="$t('labels.filter_dates')"
                               v-model="filterDates">
            </date-picker-input>
          </v-col>
          <v-col v-if="isInfluencer"
            cols="12" sm="auto" class="pa-2">
            <span class="mr-2">EUR Sales: <b>{{ salesAmount.eur }}</b></span>
            <span class="mr-2">USD Sales: <b>{{ salesAmount.usd }}</b></span>
          </v-col>
          <v-col v-if="isInfluencer"
            cols="12" sm="auto" class="pa-2">
            <c-btn color="secondary" small :to="{ name: 'wallet_withdraws' }">{{ $t('models.withdraws.name')}}</c-btn>
          </v-col>
        </v-row>
      </template>

      <template #item.source="{ item }">
        <span v-if="!hasSource(item)">{{ item.resource || '-' }}</span>
        <router-link v-else-if="isSale(item)"
          :class="{ 'indigo--text': isAffiliate(item)}"
          :to="`/${item.resource}/${item.resourceId}`">
          {{ $t('actions.show_item', { item: $t(`models.${item.resource}.singular_name`).toLowerCase() }) }}
        </router-link>
        <span v-else-if="isReload(item)">-</span>
        <router-link v-else-if="isReferral(item)" :to="referralLink(item)">
          {{ $t(`wallet.referral.${item.resource}`, { username: item.username }) }}
        </router-link>
        <a v-else-if="isCart(item)"
          @click="showDetails(item)">{{ $t('actions.show_details') }}</a>
        <router-link v-else
          :class="{ 'indigo--text': isAffiliate(item)}"
          :to="{ name: 'influencer', params: { username: item.resource } }">
          {{ `@${item.resource}` }}
        </router-link>
        <div v-if="isSFSRevolution(item)" class="success--text">{{ $t('wallet.events.sfs_revolution') }}</div>
        <div v-else-if="isAffiliate(item)" class="success--text">{{ $t('wallet.events.affiliate_user') }}</div>
      </template>

      <template #item.amount="{ item }">
        <span class="large-text" :class="amountColor(item.amount)">
          {{ amountSymbol(item.amount) }}
        </span>
        <Currency unsigned :amount="item.amount" :currency="item.currency"></Currency>
        <span v-if="item.creditCard">({{ $t('wallet.events.credit_card') }})</span>
      </template>

      <template #item.freeAmount="{ item }">
        <template v-if="item.freeAmount">
          <span class="large-text" :class="amountColor(item.freeAmount)">
            {{ amountSymbol(item.freeAmount) }}
          </span>
          <Currency unsigned :amount="item.freeAmount" :currency="item.currency"></Currency>
        </template>
        <span v-else>N/A</span>
      </template>

      <template #item.vat="{ item }">
        <template v-if="item.vat > 0">
          <span class="large-text error--text">-</span>
          <Currency :amount="item.vat" :currency="item.currency"></Currency>
        </template>
        <span v-else>N/A</span>
      </template>

      <template #item.exes="{ item }">
        <span v-if="item.exes > 0"
            class="large-text error--text">-</span>
        <Currency :amount="item.exes" :currency="item.currency"></Currency>
      </template>

      <template #item.fee="{ item }">
        <span v-if="item.fee > 0"
            class="large-text error--text">-</span>
        <Currency :amount="item.fee" :currency="item.currency"></Currency>
      </template>

      <template #item.total="{ item }">
        <Currency :amount="item.total" :currency="item.currency"></Currency>
        <div v-if="item.reservedAmount" class="small-text success--text">
          {{ $t('wallet.events.reserved_amount', { amount: $currency(item.reservedAmount, item.currency) }) }}
        </div>
      </template>

    </v-data-table>
    <c-dialog class="dialog"
              width="640"
              max-width="1280"
              v-model="showDetailDialog">
      <template slot="title">
        <h1>{{ $t('payments.detail') }}</h1>
      </template>
      <SaleDetail
        :payment-id="selectedItem.paymentId"
        :items="selectedItem.items"
        :details="selectedItem.details"
      />
    </c-dialog>

  </section>
</template>
<script>
import { mapState } from 'vuex'
import WalletApi from '@/api/WalletApi'
import table from '@/components/mixins/table'
import dialog from '@/components/mixins/dialog'
import WithdrawBill from '@/components/wallet/WithdrawBill'
import SaleDetail from '@/components/wallet/SaleDetail'
import BuyBill from '@/components/wallet/BuyBill'

export default {
  name: 'WalletEventList',
  components: { BuyBill, WithdrawBill, SaleDetail },
  mixins: [table(WalletApi, 'events'), dialog],
  data () {
    return {
      filterDates: [],
      showModal: false,
      dialogKey: new Date().getTime(),
      isWithdraw: false,
      selectedItem: {},
      showDetailDialog: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer']),
    headers () {
      return [
        { text: this.$t('models.wallet.date'), value: 'date', sortable: false },
        { text: this.$t('models.wallet.type'), value: 'type', sortable: false },
        { text: this.$t('models.wallet.source'), value: 'source', sortable: false },
        { text: this.$t('models.wallet.base'), value: 'amount', sortable: false },
        { text: this.$t('models.wallet.added_amount'), value: 'freeAmount', sortable: false },
        { text: this.$t('models.wallet.vat'), value: 'vat', sortable: false },
        { text: this.$t('models.wallet.fee'), value: 'fee', role: 'influencer', sortable: false },
        { text: this.$t('models.wallet.exes'), value: 'exes', role: 'follower', sortable: false },
        { text: this.$t('models.wallet.total_price'), value: 'total', sortable: false },
        { text: this.$t('models.wallet.total'), value: 'walletTotal', sortable: false }
      ]
    },
    salesAmount () {
      const { eur, usd } = this.metadata.sales || {}
      return {
        eur: this.$currency(eur, 'EUR'),
        usd: this.$currency(usd, 'USD')
      }
    },
    visibleHeaders () {
      return this.headers.filter(h => !h.role || h.role === this.currentUser.role)
    },
    query () {
      const [start, end] = this.filterDates
      return {
        afterDate: start,
        beforeDate: end,
        userId: this.currentUser.id
      }
    },
    bill () {
      return this.isWithdraw ? WithdrawBill : BuyBill
    }
  },
  methods: {
    amountColor (amount) {
      return amount >= 0 ? 'success--text' : 'error--text'
    },
    startDownloading (item) {
      // Download Item
      this.hideDialog()
    },
    amountSymbol (amount) {
      return amount > 0
        ? '+'
        : amount < 0
          ? '-'
          : ''
    },

    mapItem (event) {
      const amount = Math.abs(event.amount + event.freeAmount);
      const date = this.$date(event.date)
      const type = this.$t(`wallet.events.type_${event.eventType}`)
      const exes = Math.ceil(amount * (event.exes / 100));
      const vat = Math.ceil((amount + exes) * (event.vat / 100))
      const fee = event.fee + event.affiliateFee + event.agentFee || 0
      let total = amount + vat + exes - fee
      const walletTotal = this.$currency(event.totalAmount, event.currency)
      return {
        ...event, date, type, vat, exes, fee, total, walletTotal
      }
    },

    hasSource (item) {
      return !this.isRefund(item) && !this.isBonus(item) && !this.isMovement(item)
    },

    isPurchase (item) {
      return item.eventType === 'purchase' || item.eventType === 'cart'
    },

    isSale (item) {
      return item.eventType === 'purchase' || item.eventType === 'sale' || item.eventType === 'reserve' || item.eventType ==='penalized_auction' || item.eventType ==='auction'
    },

    isCart (item) {
      return item.eventType === 'cart' || item.eventType === 'cart_sale'
    },

    isReload (item) {
      return item.eventType === 'reload'
    },

    isAffiliate (item) {
      return item.affiliateFee > 0
    },

    isSFSRevolution (item) {
      return item.eventType === 'affiliate' || item.sfsRevolution
    },

    isReferral (item) {
      return item.eventType === 'referral'
    },

    isBonus (item) {
      return item.eventType === 'bonus'
    },

    isRefund (item) {
      return item.eventType === 'refund'
    },

    isMovement (item) {
      return item.eventType === 'wallet_transfer' || item.eventType === 'scatbook_transfer' || item.eventType === 'withdraw'
    },
    showDetails (item) {
      this.selectedItem = item
      this.showDetailDialog = true
    },

    referralLink (item) {
      if (item.resource === 'posts' || item.resource === 'products') {
        return `/${item.resource}/${item.resourceId}`
      } else {
        return { name: 'influencer', params: { username: item.username } }
      }
    },

    close () {
      this.dialogKey = new Date().getTime()
      this.hideDialog()
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    font-size: medium;
    padding: 15px 25px 5px 25px;
    width: fit-content;
    text-align: justify;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header {
    border-bottom-style: inset;
    border-bottom-width: thick;
  }

  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .body {
    margin: auto;
    text-align: center;
  }

  body.modal-open {
    overflow: hidden;
  }
</style>
