<template>
  <div class="d-flex chat-row" v-if="user" @click="openChat">
    <div class="col-2 mx-1" style="min-width: fit-content !important;">
      <img
        :src="getAvatar"
        height="34"
        width="34"
        style="border-radius: 20px; object-fit: cover"
        alt=""
      />
    </div>
    <div class="pl-1 row">
      <span class="user truncatable">
        <div class="name truncate" :class="{ dark: darkTheme }">
          <span>{{ user.name }}</span>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { ChatActions } from '@/store'

export default {
  name: 'UserSearch',
  props: {
    user: Object,
    eventSelectUser: { type: Boolean, default: false }
  },
  computed: {
    darkTheme(){
      return this.$vuetify.theme.dark
    },
    getAvatar () {
      return this.user.avatar || '/images/no_content.png'
    }
  },
  methods: {
    openChat () {
      if (this.eventSelectUser) {
        this.$emit('selectUser', this.user)
        return
      }
      this.$router.push({
        name: 'messages',
        query: { username: this.user.name }
      })
      this.$store.dispatch(ChatActions.ShowChatNew, false)
      this.$store.dispatch(ChatActions.ShowChatSetting, false)
    }
  }
}
</script>

<style lang="scss" scoped>
.truncatable {
  margin: auto;
}

.chat-row {
  background-color: transparent !important;
  border: none;
}

.dark.light-text {
  color: white !important;
}
</style>
