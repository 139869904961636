<template>
  <c-dialog persistent
    ref="dialog"
    width="684"
    class="publication-dialog"
    :retain-focus="false"
    :value="true"
    @input="close">
    <template #title>
      <h1 class="semibold ">
        {{ $tc('subscriptions.trials.info_title', selectedTrial.duration) }}
      </h1>
    </template>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="6">
            {{ $t('subscriptions.trials.attributes.created_at')}}
          </v-col>
          <v-col cols="6" class="bold">
            {{ $date(selectedTrial.createdAt) }}
          </v-col>
          <v-col cols="6">
            {{ $t('subscriptions.trials.attributes.expires_at')}}
          </v-col>
          <v-col cols="6" class="bold">
            {{ $date(selectedTrial.expiresAt) }}
          </v-col>
          <v-col cols="6">
            {{ $t('subscriptions.trials.attributes.credit_card')}}
          </v-col>
          <v-col cols="6" class="bold">
            {{ $t(`boolean.${!!selectedTrial.creditCard}`)}}
          </v-col>
          <v-col cols="6">
            {{ $t('subscriptions.trials.attributes.max_fans')}}
          </v-col>
          <v-col cols="6" class="bold">
            {{ selectedTrial.maxFans }}
          </v-col>
          <v-col cols="6">
            {{ $t('subscriptions.trials.attributes.current_fans')}}
          </v-col>
          <v-col cols="6" class="bold">
            {{ selectedTrial.currentFans }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <c-btn color="secondary" @click.stop="disableTrial">
          {{ $t('subscriptions.trials.delete') }}
        </c-btn>
      </v-col>
      <v-col cols="auto">
        <c-btn @click.stop="copyLink">
          {{ $t('subscriptions.trials.copy_link') }}
        </c-btn>
      </v-col>
    </v-row>
  </c-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import messages from '../locales/all.json'

export default {
  name: 'TrialInfoDialog',
  i18n: { messages },
  computed: {
    ...mapState('profile', ['selectedTrial']),
    linkUrl () {
      const origin = window.location.origin
      const path = this.$router.resolve({ name: 'trial', params: { token: this.selectedTrial.token } }).href
      return `${origin}${path}`
    }
  },
  methods: {
    async disableTrial () {
      await this.$store.dispatch(ProfileActions.DisableTrial, this.selectedTrial.id)
      this.close()
    },
    copyLink () {
      const copyText = document.createElement('textarea')
      copyText.addEventListener('focusin', e => e.stopPropagation())
      copyText.value = this.linkUrl
      copyText.setAttribute('readonly', '')
      copyText.style.position = 'absolute'
      copyText.style.left = '-9999px'
      document.body.appendChild(copyText)
      copyText.select()
      document.execCommand('copy')
      document.body.removeChild(copyText)
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.clipboard_copied')
      })
    },
    close () {
      this.$store.dispatch(ProfileActions.SelectTrial, null)
    }
  }
}
</script>
