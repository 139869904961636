<template>
  <section>
    <input
      type="text"
      :placeholder="$t('actions.search')"
      class="input-text-albums"
      v-model="query.search"
      @input="loadItems"
      :class="darkTheme"
    />
    <Paginations
      :loading="loading"
      :items="items"
      :perPage="perPage"
      :totalItems="totalItems"
      @pageSelected="pageSelected"
      :grid="listGrid"
    >
      <template #default="{ item }">
        <ListAlbumsRow
          :row="item"
          @deleteAlbum="deleteAlbum"
          @editAlbum="editAlbum"
          :withCheck="withCheck"
        />
        <!--  <ViewListUserRow :row="item" @deleteItemList="deleteItemList"  @editItemList="editItemList"/> -->
      </template>
    </Paginations>
  </section>
</template>

<script>
import ConversationsApi from '@/api/conversations/ConversationsApi'
import Paginations from '@/components/chat/component/Paginations'
import table from '@/components/mixins/table'
import ListAlbumsRow from '@/components/chat/components/albums/ListAlbumsRow'
export default {
  name: 'ListAlbums',
  mixins: [table(ConversationsApi, 'findAlbum')],
  components: { ListAlbumsRow, Paginations },
  props: {
    listGrid: { type: Boolean, default: false },
    per_page: { type: Number, default: 3 },
    withCheck: { type: Boolean, default: false },
    username: { type: String }
  },
  data () {
    return {
      perPage: this.per_page,
      query: {
        search: '',
        username:this.username
      }
    }
  },
  computed:{
     darkTheme(){
      return {dark: this.$vuetify.theme.dark}
    },
  },
  methods: {
    pageSelected (pag) {
      this.page = pag
    },
    async deleteAlbum (id) {
      const { error } = await ConversationsApi.deleteAlbum(id)
      if (!error) {
        this.loadItems()
      }
    },
    editAlbum (album) {
      this.$emit('editAlbum', album)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-text-albums {
  width: -webkit-fill-available;
  display: inline-block;
  border: 1px solid #d7d7d7;
  height: 30px;
  padding: 7px 15px;
  margin: 4px;
  font-size: 0.9375em;
  border-radius: 30px;
  background: white;
  &.dark{
      background-color: #272727 !important;
    }
}
</style>
