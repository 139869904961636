<template>
  <v-app :style="rootStyle">
    <v-content :class="cssClass">
      <v-container>
        <router-view :key="$route.fullPath"/>
      </v-container>
      <app-footer app></app-footer>
    </v-content>
    <LegalAgeMessage/>
    <cookies-modal></cookies-modal>
  </v-app>
</template>

<script>
import project from '@/project'
import AppFooter from '@/components/layouts/AppFooter'
import CookiesModal from '@/components/cookies/cookies'
import LegalAgeMessage from '@/components/layouts/LegalAgeMessage'
import SpecialCampaign from '@/views/landing/specialCampaign/SpecialCampaign'

import { mapState } from 'vuex'

export default {
  name: 'LandingPage',
  components: {
    AppFooter,
    LegalAgeMessage,
    SpecialCampaign,
    CookiesModal
  },
  data () {
    return {
      project
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isFollower']),
    ...mapState('application', ['isMobile', 'showNewNotification']),
    cssClass () {
      if (this.$route.name !== 'specialCampaign') {
        return
      }
      return {
        'bg-loverfans': !this.isMobile,
        'bg-loverfans-mobile': this.isMobile,
        isMobile: this.isMobile
      }
    },
    theme () {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    rootStyle () {
      return {
        background: this.$vuetify.theme.themes[this.theme].background
      }
    },
    showDisclaimer () {
      return !project.project.includes('loverfans')
    },
    isScatbook () {
      return this.project.project === 'scatbook'
    },
    isLoverfans () {
      return this.project.project === 'loverfans'
    },
    isDarkfans () {
      return this.project.project === 'darkfans'
    }
  },
  mounted () {
    this.$vuetify.theme.dark = project.theme === 'dark'
  }
}
</script>
<style lang="scss" scoped>

.masks {
  width: -moz-available;
  width: -webkit-fill-available;
  height: 300px !important;
  position: absolute;

  &.mobile {
    height: 72px !important;
    width: -moz-available !important;
    width: -webkit-fill-available !important;
  }

  .left-mask {
    background-image: url(/images/landing_left_mask.svg);
    height: inherit;
    width: 235px;
    position: absolute;
    background-position-x: right;
    background-position-y: bottom;

    &.mobile {
      background-size: cover !important;
      width: 98px !important;
      height: 75px !important;
    }
  }

  .right-mask {
    background-image: url(/images/landing_right_mask.svg);
    height: inherit;
    width: 235px;
    right: 0;
    position: absolute;
    background-position-x: left;
    background-position-y: bottom;

    &.mobile {
      background-size: cover !important;
      width: 98px !important;
      height: 75px !important;
    }
  }
}
</style>
