<template>
  <ListView no-gutters
    cols="12" sm="6" md="3"
    :items="items"
    :loading="loading"
    @bottom-reached="loadItems">
    <template #default="{ item }">
      <section class="pa-2">
        <UserCard :user="item" :isProfile="isProfile" />
      </section>
    </template>
    <template #footer>
      <v-row justify="center">
        <StarsDiscoveryMessage :empty="!items.length" />
      </v-row>
    </template>
  </ListView>
</template>
<script>
import UserCard from '@/components/users/UserCard'
import StarsDiscoveryMessage from '@/views/stars/StarsDiscoveryMessage'
import UserApi from '../../api/UserApi'
import CursorService from '@/components/mixins/cursor_api'

export default {
  name: 'UserSubscriptions',
  mixins: [CursorService(UserApi, 'activeSubscriptions')],
  components: { UserCard, StarsDiscoveryMessage },
  props: {
    isProfile: { type: Boolean, default: true }
  },
  computed: {
    query () {
      return {
        subscribed: true
      }
    }
  }
}
</script>
