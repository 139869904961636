<template>
  <div class="slave-container">
    <img class="img-responsive" src="/images/landing/slave.png"/>
  </div>
</template>

<script>
export default {
  name: 'SlaveWidget'
}
</script>

<style lang="scss" scoped>
.slave-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  min-width: 40px;
}
</style>
