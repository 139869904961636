<template>
  <section>
    <div style="text-align: center" v-if="loadingComponent">
      <Spinner color="primary" />
    </div>
    <span v-else>
      <div class="row" v-for="(item,index) in autoMessages" :key="index">
        <AutoMessage
          v-if="item.autoMessageObject"
          :ref="item.ref"
          :title="item.title"
          :auto-message-object="item.autoMessageObject"
          :currency="currency"
          :loading="loading"
          :saving="saving"
          :reset="reset"
          @errors="setErrors"
        ></AutoMessage>
        <div class="col-11" style="margin: 0 auto auto; text-align: center;">
          <Buttons
            :disabled="loading || hasErrors "
            class="saveBtn"
            @handler="save"
            :text="$t('actions.save')"
            :loading="saving"
          ></Buttons>
        </div>
        <hr class="mx-5" style="width: -webkit-fill-available" />
      </div>
    </span>
  </section>
</template>

<script>
import Buttons from '@/components/chat/component/Buttons'
import Spinner from '@/components/chat/component/Spinner'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import { mapState } from 'vuex'
import { AppActions } from '@/store'
import AutoMessage from '@/components/chat/components/MessageSettings/AutoMessages/AutoMessage'

export default {
  name: 'AutoMessageSettings',
  components: { AutoMessage, Buttons, Spinner },
  data () {
    return {
      autoMessages: [
        { ref: 'followerRef', title: this.$t('application.chat_setting.schedule.new_follower'), autoMessageObject: null },
        { ref: 'fanRef', title: this.$t('application.chat_setting.schedule.new_fan'), autoMessageObject: null },
        { ref: 'purchaseRef', title: this.$t('application.chat_setting.schedule.new_content'), autoMessageObject: null },
        { ref: 'tipRef', title: this.$t('application.chat_setting.schedule.new_tip'), autoMessageObject: null }
      ],
      loading: false,
      saving: false,
      currentPref: {},
      reset: false,
      hasErrors: false,
      loadingComponent: true
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    currency () {
      return this.currentUser.wallet.currency === 'EUR' ? '€' : '$'
    }
  },
  methods: {
    isNotEmpty (object) {
      return object.message.length > 0 || object.mediaIds.length > 0
    },
    async loadPreferences () {
      this.loading = true
      this.reset = true
      const def = {
        message: null,
        mediaIds: [],
        price: 0
      }
      const { data, error } = await ConversationsApi.getAutomessage()
      if (!error) {
        this.autoMessages[0].autoMessageObject = data.follow || def
        this.autoMessages[1].autoMessageObject = data.fan || def
        this.autoMessages[2].autoMessageObject = data.purchase || def
        this.autoMessages[3].autoMessageObject = data.tip || def
      }
      this.reset = false
      this.loading = false
      this.loadingComponent = false
    },
    async save () {
      if (this.hasErrors) {
        return false
      }
      const follow = this.$refs.followerRef[0].save()
      const fan = this.$refs.fanRef[0].save()
      const purchase = this.$refs.purchaseRef[0].save()
      const tip = this.$refs.tipRef[0].save()
      this.saving = true
      const obj = {}
      if (this.isNotEmpty(follow)) {
        obj.follow = follow
      }
      if (this.isNotEmpty(fan)) {
        obj.fan = fan
      }
      if (this.isNotEmpty(purchase)) {
        obj.purchase = purchase
      }
      if (this.isNotEmpty(tip)) {
        obj.tip = tip
      }
      const { data, error } = await ConversationsApi.createAutomessage(obj)
      if (!error) {
        this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
        this.loadPreferences()
      } else {
        this.$store.dispatch(AppActions.SetNotification, {
          type: 'error',
          error: data
        })
      }
      this.$store.dispatch(AppActions.ShowNotification, true)
      this.saving = false
    },
    setErrors (errors) {
      this.hasErrors = errors.length > 0
    }
  },
  async mounted () {
    this.loading = true
    await this.loadPreferences()
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.saveBtn {
  padding: 5px 20px;
  background-color: #41b849;
  width: 100%;
  font-family: Quicksand, sans-serif !important;
}
textarea:disabled {
  background-color: #9b9b9b3b;
}
button:disabled {
  background-color: #9b9b9b3b;
}
.input-area {
  color: black !important;
  border: 1px solid grey;
}
</style>
