<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-tabs grow show-arrows
                background-color="transparent"
                class="profile-sections border-bottom">
          <v-col cols="6" v-for="item in filterResult" :key="item.key">
              <v-tab
                :key="item.key"
                replace
                @click="changeType(item.key)">
                <span>{{ item.text }}</span>
              </v-tab>
          </v-col>
          <v-divider
            vertical
          ></v-divider>
        </v-tabs>
      </v-col>
    </v-row>
    <ListView no-gutters v-if="!onlyPosts"
      cols="12" sm="6" md="3"
      :loading="loadingUsers"
      :items="users"
      @bottom-reached="loadMoreUsers">
      <template #empty>
        <h1 class="title-text pa-5">{{ $t('listview.no_users_found', { query: search })}}</h1>
      </template>
      <template #header>
        <ListViewTitle
          icon="stars"
          :title="$t('users.search', { query: search })"
          :action="showUsersAction" />
      </template>
      <template #default="{ item }">
        <section class="px-2 py-2">
          <UserCard :user="item" />
        </section>
      </template>
    </ListView>

    <ListView no-gutters v-if="!onlyUsers"
      cols="12"
      :loading="loadingPosts"
      :items="posts"
      :item-min-height="200"
      :item-class="postsClass"
      @bottom-reached="loadMorePosts">
      <template #empty>
        <h1 class="title-text pa-5">{{ $t("listview.no_posts_found", { query: search }) }}</h1>
      </template>
      <template #header>
        <ListViewTitle
          icon="burst_mode"
          :title="$t('posts.search', { query: search })"
          :action="showPostsAction" />
      </template>
      <template #default="{ item }">
        <PostPage :post="item" fake-video/>
      </template>
    </ListView>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { UserActions } from '@/store'
import { existBannedWords } from '@/plugins/form_validations'
import UserCard from '@/components/users/UserCard.vue'
import PostPage from '@/components/posts/PostPage.vue'
import PostApi from '@/api/PostApi'
import config from '@/project'

export default {
  name: 'SearchView',
  components: { UserCard, PostPage },
  props: {
    search: String
  },
  data () {
    return {
      onlyUsers: true,
      onlyPosts: false,
      showContent: 'users',
      users: [],
      userLimit: 4,
      nextUserCursor: '',
      posts: [],
      postLimit: 4,
      nextPostCursor: '',
      loadingUsers: false,
      loadingPosts: false,
      forbWord: ['scat',
        'shit',
        'poop',
        'farts',
        'pooping',
        'smearing',
        'toilet',
        'diapers',
        'piss',
        'enema',
        'mierda',
        'caca',
        'pedo',
        'cagar',
        'baño',
        'pañales',
        'mear',
        'pis',
        'enema'],
      config
    }
  },
  computed: {
    ...mapState('application', ['isMediumScreenOrGreater', 'isMobile']),
    filterResult () {
      return [
        { key: 'user', text: 'users' },
        { key: 'posts', text: 'posts' }
      ]
    },
    postsClass () {
      return {
        'posts-container': true,
        'full-width': !this.isMediumScreenOrGreater
      }
    },
    backAction () {
      return {
        text: this.$t('actions.back'),
        action: this.resetState
      }
    },
    showUsersAction () {
      return this.onlyUsers
        ? this.backAction
        : { text: this.$t('actions.show_all'), action: this.showOnlyUsers }
    },
    showPostsAction () {
      return this.onlyPosts
        ? this.backAction
        : { text: this.$t('actions.show_all'), action: this.showOnlyPosts }
    },
    bannedWords () {
      const text = this.search.split('')
      if (config.project === 'loverfans') {
        text.forEach((word) => {
          if (this.forbWord.includes(word.toLowerCase())) {
            return true
          }
        })
      }
      return existBannedWords(this.search.toLowerCase())
    },
  },

  methods: {
    changeType () {
      this.onlyUsers = !this.onlyUsers
      this.onlyPosts = !this.onlyPosts
    },
    showOnlyUsers () {
      this.nextUserCursor = ''
      this.userLimit = 26
      this.onlyUsers = true
      this.users = []
      this.loadUsers()
    },

    showOnlyPosts () {
      this.nextPostCursor = ''
      this.postLimit = 10
      this.onlyPosts = true
      this.posts = []
      this.loadPosts()
    },

    async loadUsers () {
      if (this.loadingUsers) {
        return
      }
      if (this.bannedWords) {
        this.users = []
        return
      }
      this.loadingUsers = true
      const query = { username: this.search, includeTags: true, role: 'influencer', nextCursorToken: this.nextUserCursor, limit: this.userLimit }
      const { data, error } = await this.$store.dispatch(UserActions.Search, query)
      if (!error) {
        this.users.splice(this.users.length, 0, ...data.data)
        if (data.metadata.nextCursorToken) {
          this.nextUserCursor = data.metadata.nextCursorToken
        }
      }
      this.loadingUsers = false
    },

    loadMoreUsers () {
      if (this.onlyUsers) {
        this.loadUsers()
      }
    },

    async loadPosts () {
      if (this.loadingPosts) {
        return
      }
      if (this.bannedWords) {
        this.posts = []
        return
      }
      this.loadingPosts = true
      const query = { search: this.search, includeTags: true, nextCursorToken: this.nextPostCursor, limit: this.postLimit }
      const { data, error } = await PostApi.list(query)
      if (!error) {
        this.posts.splice(this.posts.length, 0, ...data.data)
        if (data.metadata.nextCursorToken) {
          this.nextPostCursor = data.metadata.nextCursorToken
        }
      }
      this.loadingPosts = false
    },

    loadMorePosts () {
      if (this.onlyPosts) {
        this.loadPosts()
      }
    },
    resetState () {
      this.users = []
      this.userLimit = 4
      this.nextUserCursor = ''
      this.posts = []
      this.postLimit = 4
      this.nextPostCursor = ''
      this.onlyUsers = true
      this.onlyPosts = false
      this.loadUsers()
      this.loadPosts()
    }
  },
  mounted () {
    this.resetState()
  }
}
</script>
<style>
.posts-container {
  padding-right: 20% !important;
}
.full-width {
  padding-right: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}
</style>
