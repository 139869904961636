import ProductApi from '@/api/ProductApi'

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const actionsTypes = {
  Set: 'set',
  Search: 'search',
  ListByUser: 'list_by_user',
  ListAcquired: 'list_acquired',
  Recommended: 'recommended',
  Fetch: 'fetch',
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Acquire: 'acquire',
  Bid: 'bid',
  MarkAsSent: 'mark_as_sent',
  UpdateDeliveryInfo: 'update_delivery_info'
}
const state = {
  products: {},
  promises: {}
}

const mutations = {
  reset: (state) => {
    state.products = {}
    state.promises = {}
  },

  insert_product (state, product) {
    this.commit('products/set_product', product)
  },

  remove_product (state, productId) {
    state.promises[productId] = null
    state.products[productId] = null
  },

  set_product (state, product) {
    state.promises[product.id] = null
    if (state.products[product.id]) {
      Object.keys(product).forEach(key => {
        state.products[product.id][key] = product[key]
      })
    } else {
      state.products[product.id] = product
    }
  },

  set_product_promise (state, id, promise) {
    state.promises[id] = promise
  }
}

const fetchProduct = (context, productId) => {
  const promise = ProductApi.find(productId).then(({ data, error }) => {
    if (!error) {
      context.commit('set_product', data)
      return context.state.products[productId]
    } else {
      context.commit('set_product_promise', productId, null)
    }
    return data
  })
  context.commit('set_product_promise', productId, promise)
  return promise
}

const actions = {
  [actionsTypes.Set]: (context, product) => {
    context.commit('set_product', product)
  },

  [actionsTypes.Fetch]: (context, { id, force }) => {
    if (context.state.promises[id]) {
      return context.state.promises[id]
    }
    const product = context.state.products[id]
    if (!product || force) {
      return fetchProduct(context, id)
    }

    return product
  },

  [actionsTypes.Search]: async (context, query) => {
    const response = await ProductApi.list(query)
    if (!response.error) {
      response.data.data = response.data.data.map(product => {
        context.commit('set_product', product)
        return context.state.products[product.id]
      })
    }
    return response
  },

  [actionsTypes.ListByUser]: async (context, query) => {
    const response = await ProductApi.listByUser(query)
    if (!response.error) {
      response.data.data = response.data.data.map(product => {
        context.commit('set_product', product)
        return context.state.products[product.id]
      })
    }
    return response
  },

  [actionsTypes.ListAcquired]: async (context, query) => {
    const response = await ProductApi.listAcquired(query)
    if (!response.error) {
      response.data.data = response.data.data.map(product => {
        context.commit('set_product', product)
        return context.state.products[product.id]
      })
    }
    return response
  },

  [actionsTypes.Recommended]: async (context, query) => {
    const response = await ProductApi.recommended(query)
    if (!response.error) {
      response.data.data = response.data.data.map(product => {
        context.commit('set_product', product)
        return context.state.products[product.id]
      })
    }
    return response
  },

  [actionsTypes.Create]: (context, product) => {
    return ProductApi.create(product)
      .then(({ data, error }) => {
        if (!error) {
          context.commit('insert_product', data)
        }
        return { data: context.state.products[data.id], error }
      })
  },

  [actionsTypes.Update]: async (context, product) => {
    const response = await ProductApi.update(product)
    if (!response.error) {
      await timeout(5000)
      await context.dispatch(actionsTypes.Fetch, { id: product.id, force: true })
    }
    return response
  },

  [actionsTypes.Delete]: async (context, productId) => {
    const response = await ProductApi.delete(productId)
    if (!response.error) {
      context.commit('remove_product', productId)
    }
    return response
  },

  [actionsTypes.Acquire]: async (context, payload) => {
    const response = await ProductApi.buy(payload.id, payload.attrs)
    if (!response.error) {
      context.dispatch(actionsTypes.Fetch, { id: payload.id, force: true })
    }
    return response
  },

  [actionsTypes.Bid]: async (context, payload) => {
    const response = await ProductApi.bid(payload.id, payload.amount)
    if (!response.error) {
      context.dispatch(actionsTypes.Fetch, { id: payload.id, force: true })
    }
    return response
  },

  [actionsTypes.MarkAsSent]: async (context, sale) => {
    const response = await ProductApi.updateStatus(sale.productId, { paymentId: sale.paymentId })
    if (!response.error) {
      context.dispatch(actionsTypes.Fetch, { id: sale.productId, force: true })
    }
    return response
  },

  [actionsTypes.UpdateDeliveryInfo]: async (context, { productId, deliveryInfo }) => {
    const response = await ProductApi.updateDelivery(productId, deliveryInfo)
    if (!response.error) {
      context.dispatch(actionsTypes.Fetch, { id: productId, force: true })
    }
    return response
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'products',
  namespaced: true,
  state,
  mutations,
  actions
}
