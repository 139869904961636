<template>
  <c-btn v-if="twitterEnabled"
    :css="twitterLogin"
    v-bind="$attrs"
    :loading="loading"
    :outlined="isConnected"
    @click="connectTwitter">
    <slot>{{ buttonText }} </slot>
  </c-btn>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions, SessionActions } from '@/store'
import TwitterApi from '@/api/TwitterApi'
import config from '@/project'

export default {
  name: 'ConnectTwitter',
  props: {
    callbackUrl: String,
    connecting: Boolean
  },
  data () {
    return {
      loading: false,
      twitterEnabled: config.twitter_enabled
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    buttonText () {
      return this.isConnected
        ? this.$t('actions.disconnect')
        : this.$t('actions.connect')
    },
    isConnected () {
      return this.currentUser && this.currentUser.isTwitterConnected
    },
    twitterLogin () {
      return {
        twitterLogin: true
      }
    }
  },
  methods: {
    async connectTwitter () {
      this.loading = true
      if (this.isConnected) {
        await this.disconnect()
      } else {
        await this.connect()
      }
      this.loading = false
    },

    async connect () {
      const path = this.callbackUrl || `${window.location.origin}${window.location.pathname}`
      const lang = this.$i18n.locale
      const { data, error } = await TwitterApi.requestToken(path, lang)
      if (!error) {
        window.location = data.url
      }
    },

    async disconnect () {
      await TwitterApi.disconnect()
      this.$store.dispatch(ProfileActions.Fetch)
    }
  },
  async mounted () {
    if (this.isConnected) {
      return
    }
    const params = this.$route.query
    if (params.oauth_token && params.oauth_verifier) {
      this.loading = true
      this.$emit('update:connecting', true)
      const { data, error } = await TwitterApi.requestAccess(
        params.oauth_token,
        params.oauth_verifier,
        this.$i18n.locale
      )
      if (!error) {
        this.$store.dispatch(SessionActions.SetSession, data)
        this.$emit('twitter-connected', data)
        this.$gtm('twitter_log_in')
      }
      this.$emit('update:connecting', false)
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
  .twitterLogin {
    color: white !important;
  }
</style>
