<template>
  <svg xmlns="http://www.w3.org/2000/svg" :height="`${size}px`" :width="`${size}px`" viewBox="0 0 24 24">
    <path :fill="color" id="comentarios"
          d="M5.138,2H22.86a3.058,3.058,0,0,1,3.127,2.976L26,26l-5.737-5.441H5.138A3.064,3.064,0,0,1,2,17.582V4.976A3.064,3.064,0,0,1,5.138,2ZM24.151,21.771l-.01-16.794A1.256,1.256,0,0,0,22.86,3.751H5.138A1.262,1.262,0,0,0,3.846,4.976V17.582a1.262,1.262,0,0,0,1.292,1.226H21.028Z"
          transform="translate(-2 -2)"/>
  </svg>
</template>

<script>
export default {
  name: 'Comments',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
