<template>
  <svg xmlns="http://www.w3.org/2000/svg" :height="`${size}px`" :width="`${size}px`" class="icon icon-tabler icon-tabler-dots-vertical" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path :fill="color" d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
    <path :fill="color" d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
    <path :fill="color" d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
  </svg>
</template>

<script>
export default {
  name: 'Dots',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
