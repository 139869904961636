<template>
  <v-card flat>
    <div class="row my-8 center" v-if="loading">
      <v-progress-circular color="#E400A0" indeterminate class="p-4">
      </v-progress-circular>
    </div>
    <span v-else>
      <v-row v-if="isDevelop">
        <c-btn class="mx-1" color="secondary" @click="switchAuto">auto</c-btn>
        <c-btn class="mx-1" color="secondary" @click="switchManual">manual</c-btn>
        <c-btn class="mx-1" color="secondary" @click="switchYoti">yoti</c-btn>
      </v-row>
      <v-card-text>
        <UserBankView v-if="isInfluencer" />
        <span v-else>
          <PromotionRequestedFinished v-if="isPending" />
          <UserVerificationView v-else-if="manual" />
          <VerifyIdenty v-else/>
        </span>
        <!-- <PromotionRequestedFinished v-else-if="isPending" /> -->
        <!-- <VerifyIdenty v-else/> -->
        <!-- <v-row justify="center" style="margin-bottom: 90px;margin-top: 40px;" v-else>
        <v-btn
          @click="goTo"
          :style="{
            backgroundColor: isDark ? '#B0601E' : '#f71953',
            color: '#FFFFFF'
          }"
          >{{ $t("actions.go_to_item", { item: $t("settings.tabs.promo_star") }) }}</v-btn
        >
      </v-row> -->
      </v-card-text>
    </span>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import UserApi from '@/api/UserApi'
import UserVerificationView from '@/components/users/UserVerificationView'
import VerifyIdenty from '@/components/verifyIdenty/VerifyIdenty'
import UserBankView from '@/components/users/UserBankView'
import PromotionRequestedFinished from '@/components/promote/PromotionRequestedFinished'
import project from '@/project'

export default {
  name: 'UserPaymentSettings',
  components: { UserBankView, PromotionRequestedFinished, UserVerificationView, VerifyIdenty },
  data () {
    return {
      manual: false,
      yoti: false,
      loading: true,
      project
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer', 'promotion']),
    isDevelop () {
      return project.isDevelop
    },
    isPending () {
      return this.promotion.status === 'pending'
    },
  },
  methods: {
    goTo () {
      this.$router.push('/become_star')
    },
    switchAuto () {
      this.manual = false
      this.yoti = false
      this.getActualMode()
    },
    switchManual () {
      this.manual = !this.manual
      this.yoti = false
    },
    switchYoti () {
      this.manual = false
      this.yoti = !this.yoti
    },
    async getActualMode () {
      if (this.$route.query && this.$route.query.yotiManual) {
        this.manual = true
        this.yoti = false
        return
      }
      const { data, error } = await UserApi.yotiGetConfig()
      if (!error) {
        this.manual = data.manual
        this.yoti = data.yoti
      } else {
        this.manual = false
        this.yoti = true
      }
    }
  },
  async mounted () {
    this.loading = true
    await this.getActualMode()
    this.loading = false
  }
}
</script>
