<template>
  <v-menu
    v-model="showPicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field outlined
        readonly
        :disabled="disabled"
        :value="pickerTime"
        :label="inputLabel"
        hide-details
        clearable
        append-icon="event"
        v-on="on">
      </v-text-field>
    </template>
    <v-time-picker
      :allowed-hours="allowedHours"
      format="24hr"
      v-model="pickerTime"
      @click:minute="onSelectedTime">
    </v-time-picker>
  </v-menu>
</template>
<script>
export default {
  name: 'TimePickerInput',
  props: {
    value: [Object, String, Array],
    allowedHours: Array,
    label: String,
    disabled: { type: Boolean, default: false }
  },

  data () {
    return {
      showPicker: false,
      pickerTime: this.value
    }
  },

  computed: {
    inputLabel () {
      return this.label || this.$t('types.time.name')
    }
  },

  watch: {
    value (times) {
      this.pickerTime = times
    }
  },

  methods: {
    onSelectedTime () {
      this.$emit('input', this.pickerTime)
      this.$emit('change', this.pickerTime)
      this.showPicker = false
    }
  }
}
</script>
