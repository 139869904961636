<template>
  <div :class="classActivate">
    <div class="img-ico d-flex" :class="cssClass" @click="onClickIcon">
      <div v-if="text && left" style="text-decoration: underline;">
        {{ text }}
      </div>
      <img
        v-if="image"
        :src="icon.src"
        alt=""
        class="img-ico"
        :class="cssClass"
        :style="
          `
        width: ${size}px;
        height: ${size}px;
        margin-${left ? 'left' : 'right'}: 5px;
        ${
          white
            ? 'filter: brightness(0) saturate(100%) invert(98%) sepia(100%) saturate(7%) hue-rotate(186deg) brightness(101%) contrast(101%);'
            : ''
        }
        `
        "
      />
      <div v-if="text && !left" class="text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
import typeIcon from "@/components/chat/assets/js/typeIcons";
export default {
  name: "IconButton",
  props: {
    size: { type: Number, default: 25 },
    type: { type: String, default: "" },
    text: { type: String, default: null },
    left: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    image: { type: Boolean, default: true },
    white: { type: Boolean, default: false },
    disabledDarkTheme: { type: Boolean, default: false },
    disabledEffectAction: { type: Boolean, default: false }
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
    classActivate() {
      return {
        active: !this.disabledEffectAction
      };
    },
    cssClass() {
      return {
        dark: this.isDark && !this.disabledDarkTheme,
        disabled: this.disabled
      };
    },
    icon() {
      return typeIcon.data.find(ico => ico.name === this.type);
    }
  },
  methods: {
    onClickIcon() {
      if (this.disabled) return;
      this.$emit("handler");
    }
  }
};
</script>
<style lang="scss">
.img-ico {
  align-items: center;
  margin-left: 3px;
  &.dark {
    filter: brightness(0) saturate(100%) invert(95%) sepia(5%) saturate(0%)
      hue-rotate(53deg) brightness(105%) contrast(106%) !important;
    background-color: transparent;
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
.text-link {
  text-decoration: underline;
}
text {
  text-decoration: none;
  margin-left: 2%;
}

.img-ico.disabled:hover {
  cursor: default;
}
.active {
  padding: 3px;
  &:active {
    border-radius: 20px;
    opacity: 0.7;
    background-color: #ebebeb;
    background: radial-gradient(#d3d3d3, #ebebeb);
    animation-name: anim;
    animation-duration: 1s;
  }
}
@keyframes anim {
  0% {
    background-color: #9e9e9e;
  }
  100% {
    background-color: #ebebeb;
  }
}
</style>
