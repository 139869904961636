<template>
  <v-app
    style="overflow: hidden"
    :style="{
      backgroundColor: isDark ? '#000000' : '#FFFFFF',
      color: isDark ? '#FFFFFF' : '#333333'
    }"
  >
    <v-container>
      <span>
        <v-row style="margin-bottom: 15px;">
          <AccountData
            no-telegram
            no-twitter
            rounded
            @validForm="switchValidForm"
            @dataForm="dataForm">
            <v-col cols="12" sm="6">
              <!--  <div class="campo-label">
                  {{ $t("models.user.subscription_price") }}
                </div> -->
              <v-form ref="form" v-model="validForm">
                <div class="campo-label">{{ $t('models.user.subscription_price') }}</div>
              <CurrencyInput
                rounded
                outlined
                dense
                v-model="price"
                hint="XXX,YY"
                :rules="[$vRequired]"
                min-value="500"
              />
                  </v-form>
            </v-col>
          </AccountData>
           <v-col :cols="isMobile ? 12 : 9" style="margin: auto">
      <v-row>
        <v-col :cols="2" style="text-align: left">
          <v-btn
            height="52"
            rounded
            :loading="submitting"
            :block="isMobile"
            color="#D5D5D5"
            @click="$router.back()"
            style="color: white; padding: 17px 29px;"
          >{{ $t('actions.back') }}
          </v-btn
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" style="text-align: right">
          <v-btn
            height="52"
            rounded
            :loading="submitting"
            :block="isMobile"
            :dark="isDark"
            color="#41B849"
            :disabled="!isValidAllForm"
            @click="emitData"
          >
            <span style="color: white; padding: 17px 29px;">{{
                $t('actions.step', {count: 2})
              }}</span>
          </v-btn>
        </v-col>
      </v-row>
          <v-col>
    </v-col>
      </v-col>
        </v-row>
      </span>
    </v-container>
  </v-app>
</template>

<script>
import {mapState} from 'vuex'

import {ProfileActions, WithdrawActions} from '@/store'
import project from '@/project'
import AccountData from '@/components/users/account/AccountData.vue'

export default {
  name: 'StepOne',
  components: {
    AccountData,
  },
  props: {
    /* username: {
       type: String,
       default: null
     },*/
    email: {
      type: String,
      default: null
    },
    password: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      username: '',
      submitting: false,
      price: 0,
      validForm: false,
      section: 0,
      formAccount: {},
      loading: true,
      form: {},
      validFormAccountData: false,
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser', 'isInfluencer', 'subscriptionPrice']),
    brandingLogo() {
      return this.isDark ? 'logo_horizontal_white' : 'logo_horizontal'
    },
    getSection() {
      return this.sections[this.section]
    },
    isDark() {
      return !project.project.includes('loverfans')
    },
    isDevelop() {
      return project.isDevelop
    },
    isValidAllForm(){
      return this.validFormAccountData && this.validForm && this.price > 5
    }
  },
  methods: {
    async emitData() {
      if (this.validForm) {
        this.submitting = true
        await this.updateFormAccount(this.getForm())
        this.submitting = false
      }
    },
    getForm() {
      return {
        username: this.username,
        email: this.form.email,
        /*  password: this.password, */
        displayName: this.form.displayName,
        subscriptionPrice: this.price,
        phonePrefix: this.form.phonePrefix,
        phone: this.form.phone
      }
    },
    switchValidForm(isvValidForm) {
      this.validFormAccountData = isvValidForm
      //this.validForm = (isvValidForm && this.validForm && this.price > 5)
    },
    async updateFormAccount(form) {
      this.formAccount = form
      await this.$store.dispatch(
        ProfileActions.UpdateAccountData,
        this.formAccount
      )
      this.$store.dispatch(
        ProfileActions.UpdateSubscriptionPrice,
        this.price
      )
      this.$emit('next')
    },
    dataForm(attrsForm) {
      this.username = this.currentUser.username
      this.form.email = attrsForm.email
      /* this.form.password = attrsForm.password */
      this.form.displayName = attrsForm.displayName
      this.form.phonePrefix = attrsForm.phonePrefix
      this.form.phone = attrsForm.phone
      /*  this.telegram = attrsForm.telegram
       this.twitter = attrsForm.twitter */
    }
  },
  async mounted() {
    if (this.isInfluencer) {
      this.$router.replace('/home')
    } else {
      this.loading = true
      this.price = this.subscriptionPrice
      this.loading = false
      this.$gtm('star_registration_step1', undefined, this.currentUser.email)
    }

  }
}
</script>

<style lang="scss" scoped>
.tit-seccion {
  font-size: 32px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
}

.fuente-destacada {
  font-family: "Quicksand", sans-serif;
}

.tit-desc {
  line-height: 20px;
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
}

.campo-label {
  text-align: left;
  font-size: 16px;
  padding-left: 10px;
  font-family: "Quicksand", sans-serif;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  font-family: Quicksand, sans-serif;
}
</style>
