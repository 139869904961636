<template>
  <svg xmlns="http://www.w3.org/2000/svg" :height="`${size}px`" :width="`${size}px`" viewBox="0 0 24 24">
    <g id="perfil-compartir-gris" transform="translate(-220.758 -158.535)">
      <path :fill="color" id="Trazado_698" data-name="Trazado 698"
            d="M343.4,80.711l-1.313-1.3a1.135,1.135,0,0,1,.01-1.619,1.166,1.166,0,0,1,1.632-.01l3.3,3.273a1.154,1.154,0,0,1,.338.8l-.005.01a1.129,1.129,0,0,1-.333.8l-3.3,3.273a1.156,1.156,0,0,1-1.632-.01,1.144,1.144,0,0,1-.01-1.619l1.309-1.3h-4.055a2.336,2.336,0,0,0-2.368,2.3v3.461h-.005v4.014a1.184,1.184,0,0,1-2.368,0v-7.47a4.674,4.674,0,0,1,4.746-4.6H343.4Z"
            transform="translate(-102.608 81.085)" fill-rule="evenodd"/>
      <path :fill="color" id="Trazado_699" data-name="Trazado 699"
            d="M130.854,108.418a1.15,1.15,0,0,0-1.159,1.15v8.062H111.118V99.2H118.3a1.15,1.15,0,1,0,0-2.3h-8.348a1.092,1.092,0,0,0-.313.044,1.146,1.146,0,0,0-.841,1.1V118.8a1.127,1.127,0,0,0,.1.464,1.2,1.2,0,0,0,.239.346,1.168,1.168,0,0,0,.7.331.962.962,0,0,0,.119,0h20.9a1.176,1.176,0,0,0,.821-.336,1.137,1.137,0,0,0,.338-.81v-9.227A1.16,1.16,0,0,0,130.854,108.418Z"
            transform="translate(111.958 62.594)" fill-rule="evenodd"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Shared',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
