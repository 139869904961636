<template>
  <section>
    <ListView
      no-gutters
      :loading="loading"
      :items="items"
      cols="12"
      item-class="border-bottom"
      align="start"
      justify="start"
      @bottom-reached="loadItems"
    >
      <template #default="{ item }">
        <div class="row mx-auto" @click="idSelectedMessage = item.id">
          <div class="col-10">
            <span v-if="!item.sent"
              >{{ $t("chats.schedule_message.programmed") }}
              {{ formatDate(item) }}</span
            >
            <span v-else
              >{{ $t("chats.schedule_message.sended")
              }}{{ formatDate(item) }}</span
            >
            <br />
            <span class="sub"
              >{{ $t("chats.schedule_message.send_to") }}
              <span >
                <strong class="spacer" v-for="(item, index) in getSendTo(item)" :key="index"
                  >{{ $t(`chats.new_broadcast.type.${item}`) }}
                </strong>
                <icon-button
                  v-if="item.includedUsers.length > 0"
                  type="user"
                  disabledDarkTheme
                  class="icons"
                  :size="12"
                  @handler="showModal(item, 'includedUsers')"
                ></icon-button>
              </span>
              | {{ $t("chats.schedule_message.price") }}
              <strong><Currency :amount="item.price"></Currency></strong> |
              <span
                >{{ $t("chats.schedule_message.exclude")
                }}<icon-button
                  type="ban"
                  disabledDarkTheme
                  class="icons"
                  :size="12"
                  @handler="showModal(item, 'excludedUsers')"
                ></icon-button></span
            ></span>
            <div
              class="resource-contain"
              v-for="(f, id) in item.mediaIds.slice(0, 1)"
              :key="id"
              @click="selectedResource(item, id)"
            >
              <MessageRowResourcePlus
                :mini="true"
                :attachments="item.mediaIds"
                :type="f.type"
              />
            </div>
          </div>
          <div class="col-1" style="margin: auto">
            <icon-button
              type="delete"
              @handler="showDeleteModal"
              class="icons"
              :size="18"
            ></icon-button>
          </div>
        </div>
      </template>
    </ListView>

    <WarningDialog
      :show="showWarning"
      textWarning=""
      :textButtonOK="$t('actions.ok')"
      hideCancelButton
      @handlerButtonOK="showWarning = false"
      ><span v-html="setExcludedIncludedUsers"></span
    ></WarningDialog>
    <WarningDialog
      :show="showDeleteWarning"
      :textWarning="showDeleteText"
      :textButtonOK="$t('actions.ok')"
      :loading="loading"
      @handlerButtonCancel="showDeleteModal"
      @handlerButtonOK="removeSchedule"
    />
  </section>
</template>

<script>
import IconButton from "@/components/chat/component/IconButton";
import WarningDialog from "@/components/chat/component/WarningDialog";
import ConversationsApi from "@/api/conversations/ConversationsApi";
import CursorService from "@/components/mixins/cursor_api";
import MessageRowResourcePlus from "@/components/chat/components/MessageRowResourcePlus";
import { ChatActions } from "@/store";

export default {
  name: "ScheduleMessages",
  mixins: [CursorService(ConversationsApi, "getBroadcast")],
  components: { IconButton, WarningDialog, MessageRowResourcePlus },
  data() {
    return {
      loading: false,
      scheduledMessages: null,
      showWarning: false,
      showDeleteWarning: false,
      idSelectedMessage: null,
      showExcludedUsers: null,
      showDeleteText: null,
      selectedItem: null,
      typeWarning: "excludedUsers"
    };
  },
  computed: {
    setExcludedIncludedUsers() {
      const item = this.selectedItem;
      const type = this.typeWarning;
      if (!item) return;
      let text = this.$t("chats.schedule_message.excludeMessage");
      if (type === "includedUsers") {
        text = this.$t("chats.schedule_message.includeMessage");
      }
      text += "<br>";
      if (item[type].length > 0) {
        text += '<ul style="margin: auto">';
        item[type].map(user => {
          text += `<li>${user.displayName} - <strong>${user.username}</strong></li>`;
        });
        text += "</ul>";
      }
      return text;
    }
  },
  methods: {
    showModal(item, type) {
      this.typeWarning = type;
      this.selectedItem = item;
      this.showWarning = !this.showWarning;
    },
    showDeleteModal() {
      this.showDeleteText = this.$t("chats.schedule_message.deleteSchedule");
      this.showDeleteWarning = !this.showDeleteWarning;
    },
    cssSchedule(item) {
      return {
        pending: item.sent,
        send: !item.sent
      };
    },
    selectedResource(item, index = 0) {
      this.$store.dispatch(ChatActions.CarouselItemsList, item.mediaIds);
      this.$store.dispatch(ChatActions.CarouselItemSelected, index);
      this.$store.dispatch(ChatActions.ShowCarousel, true);
    },
    getSendTo(item) {
      const sendTo = [];
      if (item.creators) sendTo.push("creators");
      if (item.fans) sendTo.push("fans");
      if (item.followers) sendTo.push("followers");
      return sendTo;
    },
    formatDate(item) {
      const date = this.$datetime(new Date(item.sendDate));
      return date.substring(0, date.length - 3);
    },
    async removeSchedule() {
      this.loading = true;
      const idSelected = this.idSelectedMessage;
      const { data, error } = await ConversationsApi.removeBroadcast(
        idSelected
      );
      if (!error) {
        const index = this.items.findIndex(i => i.id === idSelected);
        if (index !== -1) {
          this.items.splice(index, 1);
        }
      }
      this.showDeleteWarning = false;
      this.showWarning = false;
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.schedule {
  font-family: Quicksand, sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  &.pending {
    background-color: #fff6d5;
  }
  &.send {
    background-color: #f6f6f6;
  }
}

.spinner {
  height: 150px;
}

.sub {
  font-size: 10px;
  span{
    display: inline-flex
  }
}

.message-video-1 {
  max-width: 70% !important;
}
.resource-contain {
  margin-top: 3px;
  margin-left: 20px;
  margin-bottom: 3px;
}
.spacer{
  padding: 0 2px;
}

</style>
