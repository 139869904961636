<template>
  <section>
    <div class="currency" :class="cssClass">
      <span style="margin: auto">{{ currency }}</span>
      <input
        type="number"
        ref="currency"
        class="no-border-blue"
        user-scalable="no"
        :value="maskedValue"
        style="margin-top: 1px;"
        step="any"
        min="0"
        max="5000"
        :disabled="disabled"
        :placeholder="placeholder"
        oninput="validity.valid||(value='');"
        @keyup.enter="formatCurrency"
        @focusout="formatCurrency"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'InputCurrency',
  props: {
    defaultPrice: [Number, String],
    placeholder: { type: String, default: 'XX,YY' },
    disabled: { type: Boolean, default: false },
    canReset: { type: Boolean, default: false }
  },
  watch: {
    disabled () {
      if (this.canReset) {
        this.currencyValue = 0
        this.maskedValue = parseFloat(this.currencyValue).toFixed(2)
        this.$emit('newPrice', parseInt(this.currencyValue))
      }
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    /* ...mapState('preferences', ['darkTheme']), */
    currency () {
      return this.currentUser.wallet.currency === 'EUR' ? '€' : '$'
    },
    cssClass () {
      return {
        dark: this.$vuetify.theme.dark,
        disabled: this.disabled
      }
    }
  },
  data () {
    return {
      maskedValue: 154,
      currencyValue: 154
    }
  },
  methods: {
    formatCurrency (event) {
      const current = event.target.value
      if (current === '') {
        this.init()
      }
      if (current.indexOf('.') || current.indexOf(',')) {
        this.maskedValue = parseFloat(current).toFixed(2)
      }
      this.currencyValue = current * 100
      this.$emit('newPrice', parseInt(this.currencyValue))
    },
    init () {
      if (this.defaultPrice !== null) {
        this.currencyValue = this.defaultPrice
        this.maskedValue = parseFloat(this.currencyValue / 100).toFixed(2)
        this.$emit('newPrice', parseInt(this.currencyValue))
      } else {
        this.$emit('newPrice', 0)
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.currency {
  display: inline-flex;
  max-width: 100%;
  max-height: 100%;
  margin: 3%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  &.disabled {
    background-color: #9b9b9b3b;
  }
  &.dark {
    background-color: #9b9b9b3b;
  }
}
.currency input {
  max-width: 90%;
  max-height: 90%;
  border: none;
  margin-left: 1%;
}
/* .no-outline:focus {
  outline: none;
  font-size: 16px !important
} */
.no-border-blue {
  outline: none;
}
/* input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
} */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] { -moz-appearance:textfield; }
</style>
