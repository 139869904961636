<template>
  <section>
    <h3 class="title-text-2 mb-5">{{ $t('payments.cascade.description') }}</h3>
    <v-progress-circular v-if="isLoading"
      class="loading"
      indeterminate color="primary" size="48"></v-progress-circular>
    <template v-else-if="isTruspay">
      <TruspayForm class="form-container" :url="paymentUrl" />
    </template>
    <template v-else-if="!error">
      <IframedForm class="form-container" :url="paymentUrl" />
    </template>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import PaymentApi from '@/api/PaymentApi'
import IframedForm from '@/components/payments/forms/partials/IframedForm'
import TruspayForm from '@/components/payments/forms/partials/TruspayForm'
import config from '@/project'

export default {
  name: 'CascadeForm',
  components: { TruspayForm, IframedForm },
  props: {
    payment: Object,
    provider: String
  },
  data () {
    return {
      paymentUrl: null,
      error: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapState('application', ['isMobile', 'affiliate', 'affiliateSource']),
    isLoading () {
      return !this.error && !this.paymentUrl
    },
    isTruspay () {
      return this.paymentUrl && this.provider === 'trustpay'
    },
    origin () {
      if (config.payments.darkfans_redirect) return `https://darkfans.com/${this.$route.path}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
      return `${window.location.origin}${this.$route.path}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
    }
  },
  methods: {
    async requestPayment () {
      const result = await PaymentApi.fallback({
        paymentId: this.payment.uuid, origin: this.origin,
      })

      if (!result.error) {
        this.paymentUrl = result.data.url
      } else {
        this.error = true
      }
    }
  },
  mounted () {
    const { backup } = this.payment
    if (config.payments.enableGateway && backup && backup.gatewayUrl) {
      window.location = `${backup.gatewayUrl}?paymentId=${this.payment.uuid}&provider=${backup.project}`
      return
    }
    this.requestPayment()
  }
}
</script>
<style scoped>
.loading {
  display: block;
  margin: 8px auto;
}
</style>
