<template>
  <section class="border-top">
    <section class="head-modals rows">
      <div class="cols">
        <IconButton :size="10" type="close" @handler="close" />
      </div>
    </section>
    <div class="content-adjuntar-mediafile" :class="{dark: darkTheme}">
      <div>
        <icon-button
          type="upload"
          :text="$t('actions.upload_new')"
          :size="20"
          :disabled="true"
        />
      </div>
    </div>
    <div class="spacer"></div>

    <div class="center rows" :class="{ disabled: disabled }">
      <div class=" center rows">
        <div>
          <v-progress-circular
            v-if="loading"
            class="progress-circular"
            color="secondary"
            indeterminate
          ></v-progress-circular>
          <div v-else class="center rows">
            <icon-button
              type="stop-record"
              :size="20"
              @handler="stopRecorder"
              v-if="!onpreviewAudio"
            />
            <p class="time">{{ countTextOrTimeRecording }}</p>
          </div>
        </div>
        <div v-if="onpreviewAudio">
          <div class="center rows">
            <audio class="audio-preview" controls :src="previewAudio"></audio>
            <icon-button
              type="delete"
              :size="20"
              @handler="close"
            />
          </div>
          <div class="center rows" v-if="isAdmin || isAccountManager || isInfluencer">
            <p class="title-input-price">
              {{ $t('actions.set_price').toUpperCase() }}
            </p>
            <InputCurrency
              :currency="currency"
              :defaultPrice="mediaPrice"
              @newPrice="newPrice"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="spacer"></div>
  </section>
</template>

<script>
import IconButton from '../../component/IconButton.vue'
import { mapState, mapGetters } from 'vuex'
import S3Api from '@/api/S3Api'
import MediaFileApi from '@/api/conversations/MediaFileApi'
import FileManager from '@/components/mixins/file_manager'
import InputCurrency from '../../component/InputCurrency.vue'

export default {
  name: 'MessageAudioForm',
  mixins: [FileManager],
  components: {
    IconButton,
    InputCurrency
  },
  props: {
    fileAudio: { type: Function },
    disabled: { type: Boolean, default: false },
    initRecord: { type: Boolean, default: false }
  },
  data () {
    return {
      timeRecording: '00:00',
      isRecording: false,
      recorder: null,
      fileName: '',
      stopRecord: false,
      audioStream: null,
      file: null,
      initTime: null,
      loading: false,
      previewAudio: ''
    }
  },
  computed: {
    
    ...mapState('chats', ['selectedChat']),
    ...mapState('profile', ['currentUser', 'isAdmin', 'isInfluencer', 'isAccountManager']),
     darkTheme(){
      return this.$vuetify.theme.dark
    },
    countTextOrTimeRecording () {
      if (this.isRecording) {
        return this.timeRecording
      } else return ''
    },
    onpreviewAudio () {
      return this.previewAudio !== ''
    },
    currency () {
      return this.currentUser.wallet.currency === 'EUR' ? '€' : '$'
    },
    mediaPrice () {
      return `${this.selectedChat.sender.mediaPrice}`
    }
  },
  watch: {
    initRecord () {
      if (this.initRecord) {
        this.$emit('loading', false)
        this.initRecording()
      }
    }
  },
  methods: {
    close () {
      this.stopRecorder()
      this.$emit('reset')
    },
    initRecording () {
      if (this.disabled) return
      this.previewAudio = ''
      this.isRecording = !this.isRecording
      navigator.mediaDevices.enumerateDevices().then(devices => {
        devices.forEach((dev, index) => {
          if (dev.kind === 'audioinput') {
            this.devicesAudio = dev
          }
        })
      })
      navigator.mediaDevices
        .getUserMedia({
          audio: true
        })
        .then(stream => {
          this.gumStream = stream
          this.recorder = new MediaRecorder(stream)
          this.recorder.start()
          this.timeRecordingRange()
        })
    },
    stopRecorder () {
      if (this.recorder && this.recorder.state == 'recording') {
        this.recorder.stop()
        this.stopCountTime()
        this.isRecording = !this.isRecording
        this.gumStream.getAudioTracks()[0].stop()
        // Convertir los fragmentos a un objeto binario
        this.recorder.ondataavailable = e => {
          var blob = new Blob([e.data], { type: 'audio/mp3' })
          this.$emit('loading', true)
          this.loading = true
          this.directUpload(blob)
        }
      }
    },
    async directUpload (blob) {
      this.file = null
      this.changingFormat = true
      const file = await this.wrappedFile(
        new File([blob], 'messageAudio.mp3', { type: 'mp3' })
      )
      const { data, error } = await S3Api.directUpload(file)
      if (!error) {
        file.location = data.filename
        this.file = file
        this.upLoadMedia()
      }
      this.changingFormat = false
    },
    async upLoadMedia () {
      const file = [this.file]
      const { data, error } = await MediaFileApi.create(file[0].location)
      if (!error) {
        this.previewAudio = data.url
        this.loading = false
        this.$emit('loading', false)
        this.$emit('save-file', data)
      }
    },
    newPrice (price) {
      this.$emit('new-price', price)
    },
    timeRecordingRange () {
      const startTime = Date.now()
      this.initTime = setInterval(() => {
        const elapsedTime = Date.now() - startTime
        this.timeToString(elapsedTime)
      }, 10)
    },
    stopCountTime () {
      this.timeRecording = '00:00'
      clearInterval(this.initTime)
    },
    timeToString (time) {
      const diffInHrs = time / 3600000
      const hh = Math.floor(diffInHrs)

      const diffInMin = (diffInHrs - hh) * 60
      const mm = Math.floor(diffInMin)

      const diffInSec = (diffInMin - mm) * 60
      const ss = Math.floor(diffInSec)

      const diffInMs = (diffInSec - ss) * 100
      const ms = Math.floor(diffInMs)

      const formattedMM = mm.toString().padStart(2, '0')
      const formattedSS = ss.toString().padStart(2, '0')

      this.timeRecording = `${formattedMM}:${formattedSS}`
    }
  }
}
</script>

<style lang="scss">
.time {
  color: red;
  margin-left: 5px;
}
.audio-preview {
  height: 30px;
}
.progress-circular {
  height: 20px !important;
  width: 20px !important;
  margin-left: 10px;
}
.disabled:hover {
  cursor: not-allowed;
}

.content-adjuntar-mediafile.dark {
  background-color: gray;
}
</style>
