<template>
<div class="content-setting" :class="classDark">
    <div class="box-scroll" style="height: auto;">
      <div class="box">
        <div class="icons-setting" :class="classDark">
          <icon-button type="close" @handler="showSetting" />
        </div>
        <SettingChat />
        <MultimediaChat />
      </div>
    </div>
  </div>
</template>
<script>
import MultimediaChat from './MultimediaChat'
import SettingChat from './SettingChat'
import { ChatActions } from '@/store'
import { mapState } from 'vuex'
import IconButton from '../component/IconButton.vue'

export default {
  components: {
    MultimediaChat,
    SettingChat,
    IconButton,
  },
  computed: {
    ...mapState('chats', ['selectedChat']),
     darkTheme(){
      return this.$vuetify.theme.dark
    },
    classDark () {
      return {
        dark: this.darkTheme
      }
    }
  },
  methods: {
    showSetting () {
      this.$store.dispatch(ChatActions.ShowSetting)
      this.$store.dispatch(ChatActions.ShowCarousel, false)
    }
  }
}
</script>
<style lang="scss">
.content-setting {
   height: 100%;
  font-size: 1em;
  color: #6c757d;
  &.dark{
    background-color: #121b22  !important;
  }
}
.icons-setting {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 9px;
  height: 47px;
  background-color: white;
  &.dark {
    background-color: #1f2c34 !important;
  }
}
.box-scroll {
  height: 100%;
  display: flex;
  flex-flow: column;
  flex: 0 0 10%;
  background-color: #ffffff;
}
.box {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
}
</style>
