<template>
  <v-select
    block
    solo
    flat
    hide-details
    :dark="darkTheme"
    v-bind="$attrs"
    :dense="dense"
    class="locale-select"
    :class="classes"
    v-model="$i18n.locale"
    :items="locales"
    append-icon="language"
    @change="updateLocale">
    <template v-slot:prepend>
      <span></span>
    </template>
    <template #item="{ item }">
      <span>{{ $t(`langs.names.${item}`) }}</span>
    </template>
    <template #selection="{ item }" >
      <span v-if="dense">{{ $t(`langs.${item}`) }}</span>
      <span v-else>{{ $t(`langs.names.${item}`) }}</span>
    </template>
  </v-select>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import i18n from '@/i18n'

export default {
  name: 'LocaleSelect',
  props: {
    dense: Boolean
  },
  data () {
    return {
      locales: ['en', 'es', 'de', 'fr', 'it', 'pt']
    }
  },

  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('preferences', ['darkTheme']),
    classes () {
      return { dense: this.dense }
    }
  },

  methods: {
    updateLocale (value) {
      this.$i18n.loadLocale(value)
      this.$timeago.locale = value
      this.$vuetify.lang.current = value
      if (this.authenticated) {
        this.$store.dispatch(ProfileActions.UpdateAccountData, { lang: value })
      }
    }
  },
  mounted () {
    this.$vuetify.lang.current = i18n.locale
  }
}
</script>
<style lang="scss">
@use 'src/scss/colors';

.locale-select {
  background-color: transparent;
  .v-input__control, .v-input__slot {
    background-color: transparent !important;
  }
  .v-input__slot {
    padding: 0 !important;
  }

  &.dense {
    width: 20px;
  }
}

.v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
  display: none !important;
}

</style>
