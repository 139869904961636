<template>
  <section v-if="influencer">
    <c-dialog persistent
      max-width="850"
      v-model="dialog"
      width="850">
      <v-card-text>{{ $t('scat_modal.prorrogated_subscription.greetings', greetingsAttrs) }}</v-card-text>
      <div class="purchase-row">
        <div class="avatar circle">
          <v-avatar>
              <v-img
                placeholder="false"
                :src="influencer.avatarS3Route"
              />
            </v-avatar>
        </div>
        <button class="purchase-button" @click="makeSubscription">
          {{ $t('scat_modal.prorrogated_subscription.button_text', greetingsAttrs) }}
        </button>
      </div>
      <v-card-text>{{ $t('scat_modal.prorrogated_subscription.extra_text_1') }}</v-card-text>
      <v-card-text>{{ $t('scat_modal.prorrogated_subscription.extra_text_2') }}</v-card-text>
      <v-card-text>
        <v-checkbox :label="$t('modal.not_show_again')"
          @change="dontShowAgain">
          <p>{{ $t(`modal.not_show_again`)}}</p>
        </v-checkbox>
      </v-card-text>
    </c-dialog>
    <label ref="paymentRef" v-show="false">
      <PaymentButton
        subscription
        :resource="influencer"
      />
    </label>
  </section>
</template>

<script>
import { UserActions } from '@/store'
import { mapState } from 'vuex'
import PaymentButton from '@/components/payments/PaymentButton'
import cookies from '@/cookies'

export default {
  name: 'ScatRenewModal',
  components: { PaymentButton },
  data () {
    return {
      dialog: false,
      influencer: null
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser']),
    greetingsAttrs () {
      return {
        follower: this.currentUser.username,
        influencer: this.influencer.username
      }
    }
  },
  methods: {
    makeSubscription () {
      this.dialog = false
      this.$refs.paymentRef.click()
    },
    dontShowAgain(value) {
      if (value) cookies.set('dontShowRenew', true)
      else cookies.set('dontShowRenew', '')
      
    }
  },
  async mounted() {
    const showRenew = !cookies.get('dontShowRenew')
    this.dialog = showRenew && !!(this.currentUser.prorrogatedSubscriptions || []).length
    if (this.dialog) {
      const username = this.currentUser.prorrogatedSubscriptions[0].influencer
      this.influencer = await this.$store.dispatch(UserActions.Fetch, { username })
    }
  }
}
</script>
<style lang="scss" scoped>
.purchase-row {
    position: relative;
    align-items: center;
    display: flex;
    height: 54px;
    max-width: 450px;
    margin: 25px auto;
  .avatar {
    height: 52px;
    width: 52px;
    margin-right: 3px;
    background-color: white;
  }

  .purchase-button {
    background-color: #00a643;
    color: #ffffff;
    display: block;
    padding: 10px 30px;
    margin: 15px;
    font-weight: normal;
    text-decoration: none;
    text-align: center;
    border-radius: 10px;
  }
}
</style>