<template>
  <section>
    <div v-if="attachments.length == 1">
      <div class="div-image" :class="{ mini: mini }">
        <div class="video-rep" v-if="attachments[0].type === 'video'">
          <IconButon :size="getSize" type="play" class="icon-play" />
          <img class="message-video-1" :src="attachments[0].poster" />
        </div>
        <img
          v-if="attachments[0].type === 'image'"
          :src="attachments[0].url"
          class="message-img"
          alt=""
        />
      </div>
    </div>
    <div v-else class="row">
      <div class="div-image-two" :class="{ mini: mini }">
        <div class="video-rep" v-if="attachments[0].type === 'video'">
          <IconButon :size="30" type="play" class="icon-play" />
          <img class="message-video" :src="attachments[0].poster" />
        </div>
        <img
          v-if="attachments[0].type === 'image'"
          :src="attachments[0].url"
          class="message-img"
          alt=""
        />
        <AudioPlayer
          class="message-audio"
          v-if="attachments[0].type === 'audio'"
          :src="attachments[0].url"
          :disabled="true"
        />
      </div>
      <div class="div-image-two " :class="{ mini: mini }">
        <img
          v-if="attachments[1].type === 'video'"
          class="message-video blur-count"
          :src="attachments[1].poster"
        />
        <img
          v-if="attachments[1].type === 'image'"
          :src="attachments[1].url"
          class="message-img-two blur-count"
          alt=""
        />
        <AudioPlayer
          class="message-audio blur-count"
          v-if="attachments[1].type === 'audio'"
          :src="attachments[1].url"
          :disabled="true"
        />
      </div>
    </div>
    <div v-if="attachments.length > 1" class="count-add-att">
      <span :class="{ mini: mini }">+{{ attachments.length - 1 }}</span>
    </div>
  </section>
</template>

<script>
import IconButon from '../component/IconButton.vue'
import AudioPlayer from '@/components/chat/components/viewFiles/AudioPlayer'

export default {
  name: 'MessageRowResourcePlus',
  components: { IconButon, AudioPlayer },
  props: {
    attachments: { type: Array },
    mini: { Type: Boolean, default: false }
  },
  computed: {
    getSize () {
      return this.mini ? 20 : 30
    }
  }
}
</script>
<style lang="scss" scoped>
.div-image {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.mini {
    max-width: 70px;
    max-height: 90px;
    overflow: hidden;
  }
}
.message-audio {
  width: 100%;
  height: 100%;
}
.message-video {
  width: 100%;
  height: 100%;
}
.message-video-1 {
  max-width: 100%;
  height: 150px;
}
.video-rep {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.icon-play {
  position: absolute;
  filter: drop-shadow(2px 4px 6px white);
  // filter: invert(97%) sepia(1%) saturate(6645%) hue-rotate(199deg) brightness(134%) contrast(100%);
}
.div-image-two {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  margin: 0;
  .blur-count {
    filter: blur(1px);
  }
  &.mini {
    max-width: 70px;
    max-height: 70px;
  }
}
.message-img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: scale-down;
  /* object-fit: contain; */
  &.no-visible {
    ::v-deep .v-image__image {
      filter: blur(5px);
    }
  }
  &.mini {
    max-width: 50px;
    max-height: 50px;
  }
}
.message-img-two {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: scale-down;
  /* object-fit: contain; */
  &.no-visible {
    ::v-deep .v-image__image {
      filter: blur(5px);
    }
  }
  &.mini {
    max-width: 50px;
    max-height: 50px;
  }
}
.count-add-att {
  position: relative;
  height: 0px;
  span {
    font-family: 'Open Sans', sans-serif;
    font-size: 10vw;
    -webkit-text-stroke: 1px #494949;
    color: transparent;
    position: relative;
    left: 120px;
    top: -75px;
    color: rgb(251, 251, 252);
    font-size: 30px;
    font-weight: bold;
    &.mini {
      left: 75px;
      top: -55px;
      font-size: 25px;
    }
  }
}
</style>
