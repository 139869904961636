<template>
  <v-text-field
    ref="username"
    v-bind="$attrs"
    prefix="@"
    :loading="loadingValidUsername"
    :label="$t('models.user.username')"
    v-model="username"
    :rules="allRules"
    :hide-details="hideDetails"
    :dark="dark"
    :background-color="dark ? (!isPromo ? 'white' : '') : ''"
    :error-messages="validUsernameError"
    @input="checkUsername">
    <template #append>
      <v-icon :color="usernameIconColor">{{ usernameIcon }}</v-icon>
    </template>
  </v-text-field>
</template>
<script>
import UserApi from '@/api/UserApi'

export default {
  name: 'UsernameInput',
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    value: String,
    dark: Boolean,
    isPromo: Boolean,
    hideDetails: String
  },
  data () {
    return {
      loadingValidUsername: false,
      validUsername: null,
      username: ''
    }
  },
  computed: {
    allRules () {
      return [...this.rules, this.$vMinMaxLength(3, 25), this.$vOnlyAlphanumeric, this.$vNoHtml(this.username)]
    },
    usernameIcon () {
      return !this.loadingValidUsername && this.validUsername && !this.$refs.username.hasError
        ? 'done'
        : !this.loadingValidUsername && this.validUsername === false
          ? 'block'
          : ''
    },
    usernameIconColor () {
      return this.validUsername ? 'success' : 'error'
    },
    validUsernameError () {
      return this.validUsername === false
        ? this.$t('errors.already_taken_username')
        : null
    }
  },

  methods: {
    checkUsername () {
      clearTimeout(this.timeout)
      this.$emit('input', this.username)
      return new Promise((resolve, reject) => {
        this.timeout = setTimeout(async () => {
          this.loadingValidUsername = true
          const {
            data,
            error
          } = await UserApi.checkUsername(this.username)
          if (!error) {
            this.validUsername = data.success
            resolve()
          } else {
            this.validUsername = null
          }
          this.loadingValidUsername = false
        }, 500)
      })
    }
  },
  mounted () {
    this.username = this.value
  }
}
</script>
