import api from '@/api/Api'

export default {

  agents(query) {
    return api.get('/agents', query)
  },

  addAgent(username) {
    return api.post('/agents', {username})
  },

  removeAgent(agent) {
    return api.delete(`/agents/${agent}`)
  },

  listPartners(query) {
    return api.get('/agents/users', query)
  },

  /* listPartnersAdmin(query) {
    const filter = { ...query }
    const agent = filter.agent
    delete filter.agent
    return api.get(`/agents/${agent}/partners`, filter)
  },
*/
  addPartner(agent, {partner, fee, agentPayout, force}) {
    return api.post(`/agents/${agent}/partners`, {partner, fee, agentPayout, force})
  },

  partnerStatistics({agent, partner, afterDate, beforeDate}) {
    return api.get(`/agents/${agent}/partners/${partner}/statistics`, {afterDate, beforeDate})
  },

  updatePartner({partner, fee}) {
    return api.put(`/agents/partners/${partner}`, {fee})
  },

  updateAdminPartner(agent, {partner, fee, agentPayout}) {
    return api.put(`/agents/${agent}/partners/${partner}`, {fee, agentPayout})
  },

  removePartner(agent, partner) {
    return api.delete(`/agents/${agent}/partners/${partner}`)
  },

  find(username) {
    return api.get(`/agents/users/${username}`)
  }
}
