<template>
  <iframe
    ref="iframe"
    class="form-container"
    sandbox="allow-forms allow-scripts allow-modals allow-popups allow-same-origin allow-top-navigation"
    :src="url">
  </iframe>
</template>
<script>
export default {
  name: 'PaymentForm',
  props: {
    url: String,
  }
}
</script>
