<template>
  <div class="d-flex align-items-center">
    <v-text-field outlined
      persistent-hint
      prefix="@"
      class="mb-4"
      :label="$t('models.user.username')"
      :hint="$t('settings.account.delete_account_hint')"
      type="text"
      v-model="deleteUsername" />
    <v-spacer />
    <c-btn :disabled="!sameDeleteUsername"
      @click.stop.prevent="showDialog">
      {{ $t('actions.delete') }}
    </c-btn>
    <c-dialog persistent
      width="684"
      v-model="dialog"
      :actions="false">
      <template #title>
        <h1>{{ $t('settings.account.delete_account') }}</h1>
      </template>
      <UserDeleteAccount @cancel="hideDialog" />
    </c-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import dialog from '@/components/mixins/dialog'
import UserDeleteAccount from '@/components/users/UserDeleteAccount'

export default {
  name: 'UserDeleteAccountForm',
  mixins: [dialog],
  components: { UserDeleteAccount },
  data () {
    return {
      deleteUsername: ''
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer']),
    sameDeleteUsername () {
      return this.currentUser.username === this.deleteUsername
    }
  }
}
</script>
