<template>
  <v-autocomplete
    :value="value"
    :items="orderCategories"
    :item-text="$i18n.locale"
    item-value="id"
    outlined
    dense
    chips
    small-chips
    :label="$t('models.categories.name')"
    multiple
    :error-messages="errorText"
    @input="emitInput"
  >

    <template v-slot:item="{ item }">
      {{item[getLocale].charAt(0).toUpperCase() + item[getLocale].slice(1)}}
    </template>
  </v-autocomplete>
</template>
<script>
import { mapState } from 'vuex'
import i18n from '@/i18n'
export default {
  name: 'CategoriesInput',
  props: {
    value: Array,
    max: Number
  },
  data () {
    return {
      orderCategories: []
    }
  },
  computed: {
    ...mapState('application', ['categories']),
    errorText () {
      if (!this.max) return
      return this.value.length > this.max ? 'No puedes seleccionar más de 5 categorías' : ''
    },
    getLocale () {
      return i18n.locale
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    },
    orderArray (array) {
      const that = this
      return array.sort(function (a, b) {
        var nameA = a[that.getLocale].toUpperCase()
        var nameB = b[that.getLocale].toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    }
  },
  mounted () {
    const categories = this.categories
    this.orderCategories = this.orderArray(categories)
  }
}

</script>
