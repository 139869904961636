<template>
  <div>
    <div
      style="z-index: 1;"
      id="g-recaptcha-v2"
      data-sitekey="6LfduIMpAAAAAJpZY0EEQF8V6ZlrFEtxvBv3rYHM"
      class="g-recaptcha"
    />
    <div
      style="z-index: 1;"
      id="g-recaptcha-v3"
      data-sitekey="6LeYaXopAAAAALkUQsj7-khKtEzDsNObESnAmJ-2"
      data-size="invisible"
      class="g-recaptcha"
    />
  </div>
</template>
<script>
export default {
  props: {
    captchaVersion: Number,
  },
  data() {
    return {
      widgetId: 0
    }
  },
  computed: {
    size() {
      return this.captchaV === 2 ? '' : 'invisible'
    }
  },
  methods: {
    execute() {
      window.grecaptcha.execute(this.widgetId)
    },
    reset() {
      window.grecaptcha.reset(this.widgetId)
    },
    async renderV2() {
      if (window.grecaptcha) {
        this.widgetId = await window.grecaptcha.render('g-recaptcha-v2', {
          callback: (response) => {
            this.$emit('verify', response)
            /*this.reset()*/
          }
        })
      }
    },
    async renderV3() {
      if (window.grecaptcha) {
        this.widgetId = await window.grecaptcha.render('g-recaptcha-v3', {
          callback: (response) => {
            this.$emit('verify', response)
            this.reset()
          }
        })
      }
    }
  },
  mounted() {
    if (this.captchaVersion === 2) {
      this.renderV2()
    } else {
      this.renderV3()
    }
  }
}
</script>
