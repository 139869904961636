<template>
  <section>
    <h2 class="header">{{ $t('payments.chose_payment_type') }}:</h2>
    <v-card-text>
      <v-radio-group
        :value="value"
        @change="$emit('input', $event)"
        class="ma-0" row>
        <v-radio color="primary"
          :disabled="disableWallet"
          :label="$t(`payments.types.wallet`)"
          value="wallet">
        </v-radio>
        <v-radio color="primary"
          :disabled="disableCreditCard"
          :label="$t(`payments.types.credit`)"
          value="card">
        </v-radio>
      </v-radio-group>
    </v-card-text>

    <h1 class="small-text primary--text" v-if="disableCreditCard">
      {{ $t('payments.credit_not_enought_amount_min_max', { amountMin: $currency(creditCardMin),amountMax: $currency(42000) }) }}
    </h1>

    <!--
    <div class="my-1 small-text">
      *{{ $t('payments.info.charge_explain') }}
    </div>
    -->
    <h1 class="small-text">
      {{ $t('wallet.reload.remember') }}
      <router-link :to="{ name: 'wallet' }"
        class="no-decoration">
        {{ $t('words.here') }}
      </router-link>
    </h1>
  </section>
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import payments from '@/components/mixins/payments'

export default {
  name: 'PaymentSource',
  mixins: [payments],
  props: {
    value: String,
    error: Boolean,
    price: Number,
    creditCardMin: { type: Number, default: 1000 },
    creditCardMax: { type: Number, default: 42000 }
  },
  computed: {
    ...mapGetters('profile', ['currentWallet']),
    ...mapState('profile', ['currentUser']),
    disableWallet () {
      return this.currentWallet.amount < this.price
    },
    priceValidCreditCard(){
      return this.price >= this.creditCardMin && this.price <= this.creditCardMax
    },
    disableCreditCard () {
      return !this.isCreditCardEnabled || !this.priceValidCreditCard
    },
    isPaymentDisabled () {
      return this.disableWallet && this.disableCreditCard
    },
    isCreditCard () {
      return this.value === 'card'
    }
  },
  methods: {
    checkError () {
      this.$emit('update:error', this.isPaymentDisabled)
    },
    updateValue () {
      this.$nextTick(() => {
        if (this.disableWallet && !this.disableCreditCard) {
          this.$emit('input', 'card')
        } else if (this.disableCreditCard && !this.disableWallet) {
          this.$emit('input', 'wallet')
        }
      })
    }
  },
  mounted () {
    this.updateValue()
    this.checkError()
  }
}
</script>
