<template>
  <section>
    <div class="search-chat">
      <input
        type="text"
        v-model="search"
        id="newsletter"
        name="newsletter"
        :placeholder="$t('messages.user_search')"
        class=""
        @input="listItems()"
      />
    </div>

    <div class="row my-8 center" v-if="loading">
      <v-progress-circular
        color="#E400A0"
        indeterminate
        class="p-4">
      </v-progress-circular>
    </div>

    <ListView
      v-if="!loading"
      no-gutters
      :items="users"
      cols="12"
      item-class="border-bottom"
      :dark="darkTheme"
      align="start"
      justify="start">
      <template #default="{ item }">
        <UserSearch :user="item"></UserSearch>
      </template>
    </ListView>
  </section>
</template>

<script>
import UserSearch from '@/components/chat/components/UserSearch'
import UserApi from '@/api/UserApi'

export default {
  name: 'NewChat',
  components: { UserSearch },
  data () {
    return {
      message: false,
      broadcastMessage: false,
      users: [],
      loading: false,
      timeOut: null,
      search: ''
    }
  },
  computed: {
     darkTheme(){
      return this.$vuetify.theme.dark
    },
  },
  methods: {
    listItems () {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(async () => {
        this.loading = true
        const that = this
        that.users = []
        const { data, error } = await UserApi.list({ username: this.search })
        if (!error) {
          data.data.forEach(user => {
            that.users.push({ name: user.username, avatar: user.avatarS3Route })
          })
        }
        this.loading = false
        return that.users
      }, 1000)
    }
  }
  /*
  async mounted () {
    const that = this
    that.users = []
    const { data, error } = await UserApi.list({ username: this.search })
    if (!error) {
      data.data.forEach(user => {
        that.users.push({ name: user.username, avatar: user.avatarS3Route })
      })
    }
  }
  */

}
</script>

<style lang="scss" scoped>

.search-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  height: 37px;

  input {
    width: 100%;
    display: inline-block;
    border: 1px solid #d7d7d7;
    height: 40px;
    padding: 7px 15px;
    margin: 4px;
    font-size: 0.9375em;
    border-radius: 30px;
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.center {
  justify-content: center;
  align-content: center;
}
</style>
