<template>
  <div class="modal-backdrop" :class="{mobile: isMobile}" v-if="showDisclaimer">
    <iframe style="min-width: 100%; min-height: 100%" :src="legalUrl"></iframe>
    <!--<div class="col-10 modal">
      <slot name="header">
        <h3 class="bold center" style="font-size: 20px;">{{ $t('unlogged.disclaimer.title') }} </h3><h3 class="bold center" style="font-size: 20px;white-space: pre;margin-left: 8px; color: var(--v-primary-base)">{{ $t('unlogged.disclaimer.underAge') }}</h3>
      </slot>
      <slot name="body" >
        <br>
        <p class="text-center" style="font-size: 14px;color:black">{{$t('unlogged.disclaimer.paragraph_1')}}</p>
        <p class="text-center" style="font-size: 14px;color:black">{{$t('unlogged.disclaimer.paragraph_2')}}</p>
        <br>
        <p class="text-center" style="font-size: 14px;color:black">{{$t('unlogged.disclaimer.paragraph_3')}}</p>
        <p class="text-center bold" style="font-size: 14px;color:black">{{$t('unlogged.disclaimer.paragraph_4')}}</p>
        <v-row justify="center" class="mt-3">
          <v-col cols="auto">
            <c-btn
              css="mb-3"
              color="success"
              @click="allowAccess">
              {{ $t('unlogged.disclaimer.answer_yes') }}
            </c-btn>
          </v-col>
          <v-col cols="auto">
            <c-btn
              css="mb-3"
              @click="denyAccess">
              {{ $t('unlogged.disclaimer.answer_no') }}
            </c-btn>
          </v-col>
        </v-row>
        <p class="text-center" style="font-size: 14px;" v-html="$t('unlogged.disclaimer.paragraph_5')"></p>
        <br>
        <p class="text-center" style="font-size: 14px;" v-html="$t('unlogged.disclaimer.paragraph_6')"></p>
        <br>
        <p class="text-center" style="font-size: 14px;" v-html="$t('unlogged.disclaimer.paragraph_7')"></p>
        <br>
        <p class="text-center" style="font-size: 14px;">{{ $t('unlogged.disclaimer.paragraph_8')}} <a :href="`mailto:${$t('unlogged.disclaimer.email')}`" target="_blank" >{{$t('unlogged.disclaimer.email')}}</a></p>
      </slot>
      <slot name="footer">
        <br>
      </slot>
    </div>-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/project'
import cookies from '@/cookies'

export default {
  name: 'LegalAgeMessage',
  data () {
    return {
      showDisclaimer: false,
      legalAge: cookies.get('legalAge'),
      config
    }
  },
  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('application', ['isMobile']),
    legalUrl () {
      return `${config.domains.baseUrl}/campaigns/extra/legalAge.html`
    }
  },
  watch: {
    showDisclaimer(value) {
      if(value) this.applyBlur()
      else this.removeBlur()
    }
  },
  methods: {
    hideLegalAge (event) {
      if (event.data === 'legalAge') {
        this.allowAccess()
      }
      if (event.data === 'underAge') {
        this.denyAccess()
      }
    },
    applyBlur() {
      const content = document.getElementsByClassName("v-content")
      content.forEach(element => {
        element.classList.add('blurred')
      });
    },
    removeBlur() {
      const content = document.getElementsByClassName("v-content")
      content.forEach(element => {
        element.classList.remove('blurred')
      });
    },
    allowAccess () {
      sessionStorage.setItem('origin', this.$router.currentRoute.fullPath)
      cookies.set('legalAge', true)
      this.legalAge = true
      this.showDisclaimer = false
    },
    denyAccess () {
      window.location.href = 'http://www.google.com'
    }
  },
  mounted () {
    this.showDisclaimer = !this.authenticated && !this.legalAge
    window.addEventListener('message', this.hideLegalAge);
  }
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(25px);
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  &.mobile {
    overflow-y: auto;
    align-items: baseline !important;
  }
}

.modal {
  font-size: medium !important;
  padding: 15px 25px 5px 25px;
  width: fit-content;
  text-align: justify;
  background: #FFFFFF;
  color: black !important;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.modal-header, .modal-footer {
  padding: 15px;
  display: flex;
}

.text-center {
  text-align: center!important;
}
</style>
