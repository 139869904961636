<template>
<v-row justify="center">
  <v-col cols="12" md="8">
  <section class="text-center pa-4">
    <img
      width="150"
      src="/images/logo_horizontal.svg?dfk23" alt="logo" title="Logo"/>
    <h1 class="my-5">{{ $t("remember_password.title")}}</h1>
    <h1 class="title-text-1 semibold" v-html="$t('remember_password.hint')"></h1>
    <v-row justify="flex-end">
      <v-col>
        <router-link to="/login" class="capital">{{ $t('signup.already_registered')}}</router-link>
      </v-col>
    </v-row>

    <v-form ref="form" v-model="validForm"
      @submit.prevent="submit">
      <template v-if="!requested">
        <v-text-field outlined autofocus persistent-hint
          :label="$t('models.user.email')"
          v-model="email"
          append-icon="email"
          :rules="[$vRequired,$vEmail]">
        </v-text-field>
        <c-btn rounded
          block
          @click.stop.prevent="submit"
          class="mb-3">
          {{ $t('actions.send') }}
        </c-btn>
      </template>
      <template class="remember" v-else>
        <p>{{ $t('remember_password.email_sent') }}</p>
        <p>{{ $t('remember_password.email_sent_2') }}</p>
        <c-btn text
          class="mb-3"
          to="/login">
            {{ $t('actions.back') }}
        </c-btn>
      </template>
    </v-form>
  </section>
  </v-col>
</v-row>
</template>

<script>
import validatable from '@/components/mixins/validatable'
import PasswordApi from '@/api/PasswordApi'
import project from '@/project'

export default {
  name: 'Login',
  mixins: [validatable('form')],
  metaInfo () {
    return {
      title: this.$t('meta.passwords.title')
    }
  },
  data () {
    return {
      email: '',
      requested: false
    }
  },

  methods: {
    async onSubmit () {
      const { error } = await PasswordApi.request(this.email.trim())
      this.requested = !error
    }
  }
}
</script>
<style lang="scss" scoped>
  .capital {
    vertical-align: center;
  }
</style>
