<template>
  <div class="content-dis">
    <div class="triangulo-equilatero-bottom-rigth">
      <img class="img" :src="getSrc" width="30px" height="30px"/>
      <b class="text-dis">{{ discount }}%</b>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Discount',
  props: {
    discount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getSrc () {
      return require(`@/views/landing/specialCampaign/resources/discount-star.svg`)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../_styles";

.content-dis {
  opacity: 1;
  width: 64px;
  height: 64px;
}

.triangulo-equilatero-bottom-rigth {
  width: 0;
  height: 0;
  border-bottom: 56px solid #ffffff;
  border-left: 56px solid transparent;
}

.text-dis {
  position: relative;
  top: 8px;
  right: 32px;
  text-align: center;
  font: normal normal bold 13px/24px Montserrat;
  letter-spacing: 0px;
  color: #38cc0a;
  opacity: 0.8;
}

img {
  position: relative;
  right: 28px;
  top: 8px;
  width: 16px;
  height: 16px;
}
</style>
