<template>
  <v-row align="center">
    <v-col cols="12">
      <v-row dense align="center">
        <v-col cols="4">
          {{ $t('models.post.participants.upload_dni1') }}
        </v-col>
        <v-col cols="4" class="d-flex">
          <v-img v-if="isUploadedFirst" class="document-thumb mb-1" :src="dni1.preview"/>
          <v-icon v-if="isUploadedFirst" color="success" right>check_circle</v-icon>
        </v-col>
        <v-col cols="4">
          <FileInput
            button autoupload small
            css="mx-auto mb-1"
            max-files="1"
            :loading="(dni1 || {}).loading"
            @file-added="dni1 = $event"
            @file-uploaded="completeParticipant">
            {{ $t('actions.upload') }}
          </FileInput>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense align="center">
        <v-col cols="4">
          {{ $t('models.post.participants.upload_dni2') }}
        </v-col>
        <v-col cols="4" class="d-flex">
          <v-img v-if="isUploadedSecond" class="document-thumb mb-1" :src="dni2.preview"/>
          <v-icon v-if="isUploadedSecond" color="success" right>check_circle</v-icon>
        </v-col>
        <v-col cols="4">
          <FileInput
            button autoupload small
            css="mx-auto"
            max-files="1"
            :loading="(dni2 || {}).loading"
            @file-added="dni2 = $event"
            @file-uploaded="completeParticipant">
            {{ $t('actions.upload') }}
          </FileInput>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense align="center">
        <v-col cols="4">
          {{ $t('models.post.participants.selfie') }}
        </v-col>
        <v-col cols="4" class="d-flex">
          <v-img v-if="isUploadedSelfie" class="document-thumb mb-1" :src="selfie.preview"/>
          <v-icon v-if="isUploadedSelfie" color="success" right>check_circle</v-icon>
        </v-col>
        <v-col cols="4">
          <FileInput
            button autoupload small
            css="mx-auto"
            max-files="1"
            :loading="(selfie || {}).loading"
            @file-added="selfie = $event"
            @file-uploaded="completeParticipant">
            {{ $t('actions.upload') }}
          </FileInput>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense align="center">
        <v-col cols="7">
          {{ $t('models.post.participants.agreement') }}
          <c-btn x-small text @click="downloadModel()">Download Model Release</c-btn>
        </v-col>
        <v-col cols="1">
          <v-icon v-if="isUploadedAgreement" color="success" right>check_circle</v-icon>
        </v-col>
        <v-col cols="4">
          <FileInput
            button autoupload small
            css="mx-auto"
            max-files="1"
            content-type="*"
            :loading="(agreement || {}).loading"
            @file-added="agreement = $event"
            @file-uploaded="completeParticipant">
            {{ $t('actions.upload') }}
          </FileInput>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto">
      <c-btn small @click="$emit('remove')">
        {{ $t('actions.cancel') }}
      </c-btn>
    </v-col>
    <a id="downloadModelLink" href="/downloads/2257_Model_release.docx" class="d-none" download></a>
  </v-row>
</template>
<script>

export default {
  name: 'ParticipantRowForm',
  props: {
    value: { type: Object }
  },

  data () {
    return {
      dni1: null,
      dni2: null,
      selfie: null,
      agreement: null
    }
  },
  computed: {
    isUploadedFirst () {
      return this.dni1 && this.dni1.uploaded
    },
    isUploadedSecond () {
      return this.dni2 && this.dni2.uploaded
    },
    isUploadedSelfie () {
      return this.selfie && this.selfie.uploaded
    },
    isUploadedAgreement () {
      return this.agreement && this.agreement.uploaded
    },
    isTotallyUploaded () {
      return this.isUploadedFirst && this.isUploadedSelfie && this.isUploadedSecond && this.isUploadedAgreement
    }
  },
  watch: {
    value: {
      handler (newValue) {
        this.dni1 = newValue.dni1
        this.dni2 = newValue.dni2
        this.selfie = newValue.selfie
        this.agreement = newValue.agreement
      },
      immediate: true
    }
  },
  methods: {
    completeParticipant () {
      if (this.isTotallyUploaded) {
        this.$emit('finish', {
          dni1: this.dni1,
          dni2: this.dni2,
          selfie: this.selfie,
          agreement: this.agreement
        })
      }
    },
    downloadModel () {
      document.getElementById('downloadModelLink').click()
    }
  }
}
</script>
<style scoped>
.upload-container {
  height: 100%;
  min-height: 56px;
}

.document-thumb {
  height: 56px;
  max-height: 56px;
  width: 56px;
  max-width: 56px;
  margin: 0 auto;
}
</style>
