<template>
  <div class="mt-4">
    <h3>Firma de Contratos Masivos</h3>

    <div>
      <input type="file" accept="text/csv" @change="readFile" ref="archivoInput">
      <pre>{{ file }}</pre>
      <div ref="pdfPrint"
           v-show="showContract">
        <containt-print-contract
          :name="name"
          :surname="surname"
          :birthdate="birthdate"
          :dni="dni"
          :date="date"
          :address="address"
          :lang="lang"
        />
      </div>
      <c-btn class="mt-5" :loading="loading" :disabled="arrayUsers.length === 0" @click="initReplace">Reemplazar
        contratos
      </c-btn>
      <div class="mt-5 mb-10">
        <b>InfoLog</b>
        Procesados: {{ noErrorInProcess }} / {{ replaceContract.length }} Ultimo Csv: {{ arrayUsers.length }}
        <hr style="border: 0.5px solid black !important;">
        <div v-for="(rep,id) in replaceContract" :key="id">
          {{ id + 1 }}----------{{ rep.username }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import En_PDF from "@/components/signContract/locales/En_PDF.vue";
import Es_PDF from "@/components/signContract/locales/Es_PDF.vue";
import ContaintPrintContract from "@/components/signContract/ContaintPrintContract.vue";
import S3Api from "@/api/S3Api";
import UserApi from "@/api/admin/UserApi";
import FileManager from '@/components/mixins/file_manager'

import pdfMake from 'pdfmake';
import pdfFonts from '@/plugins/vfs_fonts'
import htmlToPdfmake from 'html-to-pdfmake';

import papaparse from 'papaparse';
import Spacer from "@/views/landing/catchment/components/Spacer.vue";


export default {
  name: 'ContractMassive',
  mixins: [FileManager],
  components: {Spacer, ContaintPrintContract, Es_PDF, En_PDF},
  data() {
    return {
      file: null,
      arrayUsers: [],
      replaceContract: [],
      name: '',
      surname: '',
      address: '',
      dni: '',
      birthdate: '',
      date: new Date(),
      lang: 'es',
      username: '',
      oldPath: '',
      newPath: '',
      showContract: false,
      loading: false
    }
  },
  computed: {
    noErrorInProcess() {
      return this.replaceContract.filter(r => !r.username.includes('Error')).length
    }
  },
  watch: {
    username(value) {
      this.replace(value)
    }
  },
  methods: {
    initReplace() {
      this.loading = true
      this.username = this.arrayUsers[0].username
    },
    readFile() {
      const file = this.$refs.archivoInput.files[0];
      const read = new FileReader();

      read.onload = (e) => {
        const csvContent = e.target.result;
        this.arrayUsers = []
        papaparse.parse(csvContent, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            this.arrayUsers = result.data;
            console.log(result.data)
          },
          error: (error) => {
            console.error('Error al analizar el CSV:', error.message);
          },
        });
      };
      read.readAsText(file);
    },
    async replace(username) {
      this.showContract = true
      const index = this.arrayUsers.findIndex(user => user.username === username)
      const item = this.arrayUsers[index]
      this.name = item.name
      this.surname = item.surname
      this.address = item.address
      this.dni = item.documentNumber
      this.birthdate = item.birthdate
      this.date = item.createdAt
      this.lang = item.lang
      //this.username = item.username
      this.oldPath = item.documentPath

      console.log('INIT NEW CONTRACT:', this.username)
      setTimeout(()=>{
       this.generateReport(index)
      },1)
    },
    async generateReport(index) {
      const pdfPrint = this.$refs.pdfPrint;
      const html = htmlToPdfmake(pdfPrint.innerHTML);
      const stack = html[0].stack
      const signature = stack.findIndex((element) =>
        element.id === 'signature'
      );
      stack[signature].fontSize = 20
      if (stack[signature].text.replace(/\s/g, '').match(new RegExp('^([A-zÀ-ÿ0-9!@#$&()\\-`.+,/\\"])*$', 'g'))) {
        stack[signature].style = { font: 'Sacramento'}
      }

      const documentDefinition = {
        content: html,
        defaultStyle: {
          font: 'Roboto'
        }
      }
      pdfMake.fonts = {
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-MediumItalic.ttf'
        },
        Sacramento: {
          normal: 'Sacramento.ttf',
          bold: 'Sacramento.ttf',
          cursive: 'Sacramento.ttf',
          bolditalics: 'Sacramento.ttf'
        }
      }
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      //pdfMake.createPdf(documentDefinition).open()
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
      this.showContract = false
      pdfDocGenerator.getBlob(async (blob) => {
        console.log('GENERATED')
        await this.directUpload(blob)
        await this.updateContract(index)
      });
    },
    async directUpload(blob) {
      this.file = null
      const file = await this.wrappedFile(
        new File([blob], `${new Date().getTime()}.pdf`, {type: 'application/pdf'})
      )
      const {
        data,
        error
      } = await S3Api.directUpload(file)
      if (!error) {
        file.location = data.filename
        this.newPath = file.location
        console.log('UPLOAD:', this.newPath)
      }
    },
    async updateContract(index) {

      const config = {
        username: this.username,
        signedDocumentPath: this.oldPath,
        newSignedDocumentPath: this.newPath
      }

      const {data, error} = await UserApi.fixSignedDocument(config)
      if (!error) {
        const log = {
          username: this.username,
        }
        this.replaceContract.push(log)
        console.log('CHANGED:', this.username)
      } else {
        console.log('ERROR NEW CONTRACT:', this.username)
        console.log('ERROR', `Usuario:${this.username} -- ${data.errorCode}`)
        const log = {
          username: `${this.username} ---------- Error: ${data.errorCode}`,
        }
        this.replaceContract.push(log)
      }
      if (index === this.arrayUsers.length - 1) {
        this.loading = false
      } else {
        this.username = this.arrayUsers[index + 1].username
      }
    }
  }
}
</script>
