<template>
  <v-card flat>
    <v-card-title>
      {{ $t('settings.account.delete.warning') }}
    </v-card-title>
    <v-card-text v-html="$t('settings.account.delete.description')">
    </v-card-text>
    <div class="light-text ml-8  my-2">
      {{ $t('settings.account.delete.personal_data') }}
    </div>
    <div class="light-text ml-8 my-2">
      {{ $t('settings.account.delete.wallet') }}
    </div>
    <div class="light-text ml-8  my-2">
      {{ $t('settings.account.delete.subscriptions') }}
    </div>
    <div class="light-text ml-8  my-2">
      {{ $t('settings.account.delete.social_media') }}
    </div>
    <div class="light-text ml-8  my-2">
      {{ $t('settings.account.delete.loverfans_actions') }}
    </div>
    <template v-if="isInfluencer">
      <div class="light-text ml-8  my-2">
        {{ $t('settings.account.delete.subscribers') }}
      </div>
      <div class="light-text ml-8  my-2">
        {{ $t('settings.account.delete.posts') }}
      </div>
      <div class="light-text ml-8  my-2">
        {{ $t('settings.account.delete.premium_posts') }}
      </div>
    </template>
    <v-form v-model="validForm" ref="form" @submit.prevent="submit">
      <v-card-actions>
        <v-spacer />
        <c-btn
          text color="secondary"
          @click="emitCancel">
            {{ $t('actions.close') }}
        </c-btn>
        <c-btn @click.stop.prevent="submit">
          {{ $t('actions.delete') }}
        </c-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { SessionActions } from '@/store'
import ProfileApi from '@/api/ProfileApi'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'UserDeleteAccount',
  mixins: [validatable('form')],
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer'])
  },

  methods: {
    emitCancel () {
      this.$emit('cancel')
    },

    async onSubmit () {
      const { error } = await ProfileApi.delete()
      if (!error) {
        this.$store.dispatch(SessionActions.Logout)
      }
    }
  }
}
</script>
<style lang="scss">
.profile-form {
  color: white !important;
  position: relative;

  .profile-avatar {
    position: absolute;
    top: 22px;
    left: 22px;
    bottom: 22px;
    height: 79px;
    width: 79px;
    background-color: white;
  }

  .profile-avatar, .profile-banner {
    margin-bottom: 15px;
    .v-responsive__content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .semi-opaque:hover {
    opacity: 1;
  }
}
</style>
