<template>
  <section>
    <div>
      {{ withdrawData.fullName }}
    </div>
    <div>
      {{ withdrawData.country}}
    </div>
    <div>
      {{ withdrawData.city}} - {{ withdrawData.postalCode}} ({{ withdrawData.province}})
    </div>
    <div>
      {{ withdrawData.address}}
    </div>
    <div>
      {{ documentTypeText }}: {{ withdrawData.documentNumber }}
    </div>

  </section>
</template>

<script>
import { mapState } from 'vuex'
import { WithdrawActions } from '@/store'

export default {
  name: 'WithdrawDataInfo',
  props: {
    noLoad: Boolean
  },
  computed: {
    ...mapState('withdraws', ['withdrawData']),
    documentTypeText() {
      return this.$t(`models.influencer_request.id_document_types.${this.withdrawData.documentType}`)
    }
  },
  beforeMount () {
    if (!this.noLoad) {
      this.$store.dispatch(WithdrawActions.FetchData)
    }
  }
}
</script>
