<template>
  <PaymentForm
    allow-wallet
    resource="posts"
    :resource-id="resource.id"
    :price="totalPrice"
    :wallet-payment-success="walletPaymentSuccess"
    :provider="provider"
    :payment-form-url="paymentFormUrl"
    :error="error"
    :error-message="errorMessage"
    :vat-post-price="vatPrice"
    @payment-selected="changePaymentType"
    @payment="doPayment"
    @close="emitClose">
    <v-row>
      <v-col :cols="cols">
        <c-img class="rounded post thumb"
               :src="resource.preview.cover || resource.preview.url">
          <div class="d-flex flex-column justify-center align-center fill-height text-center p-2">
            <v-icon class="large-icon">lock_outline</v-icon>
            <img class="isologo mt-5" src="/images/isologo_white.svg?dfk23" />
          </div>
        </c-img>
      </v-col>
      <v-col :cols="cols">
        <UserAvatar :profile="false" :user="resource.author" />
      <div class="mt-4 mb-1 semibold">
        {{ $t('payments.info.post_price', { price: $currency(specialOffer(resource.price)) }) }}
      </div>
        <VatExplain :price="vatPrice" />
      <div class="mt-1 mb-4 semibold">
        {{ totalPriceText }}
      </div>
        <div class="my-4">
          <v-icon>remove_red_eye</v-icon>
          {{ $t('payments.posts.engagment_1') }}
        </div>
        <div class="my-4">
          <v-icon>chat</v-icon>
          {{ $t('payments.posts.engagment_2') }}
        </div>
        <div class="my-4">
          <v-icon>bookmark</v-icon>
          {{ $t('payments.posts.engagment_3') }}
        </div>
      </v-col>
    </v-row>
    <template #wallet-payment-success>
      <PostInfo :id="resource.id" />
    </template>
  </PaymentForm>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { ProfileActions, PostActions } from '@/store'
import PostInfo from '@/components/payments/dialogs/PostInfo'
import PaymentForm from '@/components/payments/forms/PaymentForm'
import UserAvatar from '@/components/users/UserAvatar'
import PostApi from '@/api/PostApi'
import config from '@/project'
import VatExplain from '../VatExplain'

export default {
  name: 'PostPayment',
  components: { VatExplain, PostInfo, PaymentForm, UserAvatar },
  props: {
    resource: Object
  },
  data () {
    return {
      paymentType: '',
      walletPaymentSuccess: false,
      provider: '',
      paymentFormUrl: '',
      error: false,
      errorMessage: '',
      config
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapState('application', ['billing', 'isMobile', 'affiliate', 'affiliateSource']),
    ...mapGetters('revolution', ['getSfsUser']),
    origin () {
      if (config.payments.darkfans_redirect) return `https://darkfans.com?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
      return `${window.location.origin}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
    },
    vatPriceText () {
      const key = this.billing.code === 'CA' ? 'payments.info.vat_ca_price' : 'payments.info.vat_price'
      const price = this.$currency(this.vatPrice * (this.isSpecialOfferActive ? (100 - config.specialOffer.discount) / 100 : 1), this.resource.currency)
      return this.$t(key, { price })
    },
    isSpecialOfferActive () {
      const today = new Date()
      return config.specialOffer.active && config.specialOffer.startDate < today && config.specialOffer.endDate > today && config.specialOffer.discount > 0
    },

    isWallet () {
      return this.paymentType === 'wallet'
    },
    vatPrice () {
      const price = this.resource.price
      const vat = this.currentUser.billing.vat
      const expenses = (price * this.currentUser.billing.exes) / 100
      const vatAmount = Math.ceil((price + expenses) * vat * (this.isSpecialOfferActive ? (100 - config.specialOffer.discount) / 100 : 1) / 100)

      return Math.ceil(vatAmount + expenses)
    },
    totalPrice () {
      return this.specialOffer(this.resource.price)
    },
    totalPriceText () {
      return this.$t('payments.info.total_price', { price: this.$currency(this.totalPrice + this.vatPrice, this.resource.currency) })
    },
    cols () {
      return this.isMobile ? 12 : 6
    },

    spam4spamAttrs () {
      const sfs = this.getSfsUser(this.resource.username) || {}
      return {
        sfsUsername: sfs.affiliate,
        sfsSource: sfs.source
      }
    }
  },
  methods: {
    specialOffer (amount) {
      if (this.isSpecialOfferActive) {
        return amount * (100 - config.specialOffer.discount) / 100
      }
      return amount
    },
    resetState () {
      this.paymentType = ''
      this.walletPaymentSuccess = false
      this.paymentFormUrl = ''
      this.error = false
      this.errorMessage = ''
    },
    changePaymentType (payment) {
      this.paymentType = payment
    },

    async doPayment ({ name }) {
      const action = this.isWallet ? this.walletPayment(name) : this.creditCardPayment(name)
      const { data, error } = await action
      if (error) {
        this.error = error
        this.errorMessage = data.error
        this.$gtm('premium_content_card_error')
      } else {
        this.$gtag.event('purchased', { event_category: 'post', value: 0.01 })
      }
    },
    async walletPayment (name) {
      const payload = {
        id: this.resource.id,
        attrs: { name, affiliate: this.affiliate, affiliateSource: this.affiliateSource, ...this.spam4spamAttrs }
      }
      const result = await this.$store.dispatch(PostActions.Acquire, payload)
      if (!result.error) {
        this.isWallet ? this.$gtm('premium_content_wallet_complete') : this.$gtm('premium_content_card_complete')
        this.$store.dispatch(ProfileActions.Fetch)
        this.walletPaymentSuccess = true
        this.$emit('payment-success')
      }
      return result
    },
    async creditCardPayment (name) {
      const { data, error } = await PostApi.requestPayment(this.resource.id, {
        name, affiliate: this.affiliate, affiliateSource: this.affiliateSource, origin: this.origin, ...this.spam4spamAttrs
      })
      if (!error) {
        if (data.redirectUrl) {
          this.$gtm('premium_content_card_step2')
          const slash = data.redirectUrl.includes('?') ? '&' : '?'
          window.location = `${data.redirectUrl}${slash}provider=${data.project}`
        } else {
          this.$gtm('premium_content_card_step2')
          this.provider = data.provider
          this.paymentFormUrl = `${data.url}`
        }
      }
      return { data, error }
    },
    emitClose () {
      if (this.walletPaymentSuccess) {
        this.$store.dispatch(PostActions.Fetch, { id: this.resource.id, force: true })
      }
      this.$emit('close')
    }
  },
  mounted () {
    if (this.paymentType === 'wallet') {
      this.$gtm('premium_content_card_step1')
    }
  }
}
</script>
<style lang="scss">
  .post.thumb {
    height: 224px;
    width: 224px;
    max-height: 224px;
    max-width: 224px;
    .v-image__image {
      filter: blur(1rem);
    }
  }

  .mobile {
    width: auto !important;
    height: auto !important;
  }
</style>
