<template>

  <section>
    <v-col :cols="isMobile ? 12 : 10" style="margin: auto">
      <v-row class="modal-header" :style="getBorderStyle">
        <img :width="isMobile ? 200 : 250"
             :src="getSrc" align="left" style="margin-top: auto; margin-right: auto; margin-bottom: 10px"/>
        <v-col style="text-align: right">{{ factura.uuid }}</v-col>

      </v-row>
      <v-spacer style="height: 25px"></v-spacer>
    </v-col>
    <v-col :cols="isMobile ? 12 : 10" style="margin-left: auto">

      <v-row style="margin: auto">
        <v-col cols="12">
          <h1 style="font-size: 25px">{{ factura.fullName }}</h1>
        </v-col>
        <v-col cols="6">
          <p>{{ factura.address }}</p>
          <p>{{ factura.postalCode }}</p>
          <p>{{ factura.city }}, {{ factura.province }}</p>
          <p>{{ factura.country }}</p>
        </v-col>
        <v-col cols="6" style="margin-bottom: auto; margin-top: auto">
          <p><b>Mail:</b> {{ factura.userMail }}</p>
<!--          <p><b>VAT:</b> {{ factura.vat }}</p>-->
        </v-col>
      </v-row>

      <v-spacer style="height: 25px"></v-spacer>

      <v-row style="margin: auto">
        <v-col cols="12">
          <h1 style="font-size: 25px">LoverFans.com</h1>
        </v-col>
        <v-col cols="6">
          <p>Artemisa 3000 Tech Solutions<br>S.L.</p>
          <p><b>VAT:</b> B88466743</p>
          <p>{{ factura.direccion1 }}<br>{{ factura.direccion2 }}</p>
        </v-col>
        <v-col cols="6">
          <p><b>Web:</b> https://loverfans.com</p>
          <p><b>Email:</b> soporte@loverfans.com</p>
        </v-col>
      </v-row>
    </v-col>

    <v-spacer style="height: 40px"></v-spacer>

    <v-col :cols="isMobile ? 12 : 8" style="margin: auto">
      <v-row style="border-bottom: inset;" :style="getBorderStyle">
        <v-col cols="8">
          <p><b>{{ factura.content }}</b></p>
          <p><b>Impuestos</b></p>
        </v-col>
        <v-col cols="4" style="text-align: right">
          <p>{{ factura.amount }}</p>
          <p>{{ factura.impuesto }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <b>Total:</b>
        </v-col>
        <v-col cols="6" style="text-align: right">
          <p>{{ factura.total }}</p>
        </v-col>
      </v-row>
    </v-col>
  </section>

</template>

<script>
import { mapState } from 'vuex'
import dialog from '@/components/mixins/dialog'
import UserApi from '@/api/admin/UserApi'

export default {
  name: 'WithdrawBill',
  mixins: [dialog],
  props: {
    value: Boolean,
    type: { type: String, default: 'withdraw' },
    bill: { type: Object, default: null }
  },
  data () {
    return {
      dialogKey: new Date().getTime(),
      loading: false,
      factura: {}
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isAdmin', 'isAccountManager']),
    ...mapState('application', ['isMobile']),
    ...mapState('preferences', ['darkTheme']),
    getBorderStyle () {
      return {
        'border-bottom': 'inset',
        'border-bottom-color': this.darkTheme ? 'rgb(84,81,81)' : 'rgb(45,45,45)'
      }
    },
    getSrc () {
      return '/images/' + (this.darkTheme ? 'logo_horizontal_white_color' : 'logo_horizontal') + '.svg?dfk23'
    }
  },
  methods: {
    getTotal (amount) {
      return amount + this.bill.vat + this.bill.impuesto
    },
    startDownloading () {
      this.$emit('download', this.factura)
      this.$emit('input', false)
      this.$emit('close')
    }
  },
  async mounted () {
    this.loading = true
    let userData = this.currentUser

    if (this.isAdmin || this.isAccountManager) {
      await UserApi.find(this.bill.receiver).then(function (res) {
        userData = res.data
      })
    }

    this.factura = {
      uuid: this.bill.uuid,
      direccion1: 'Domicilio en CALLE GOYA, NUM 47 PLANTA 7, PUERTA 12',
      direccion2: '28001, Madrid',
      web: 'https://loverfans.com',
      contactMail: 'soporte@loverfans.com',
      fullName: userData.fullName,
      userMail: userData.email,
      address: userData.address,
      city: userData.city,
      postalCode: userData.postalCode,
      province: userData.province,
      country: userData.country,
      id: this.bill.id,
      status: 'Completado',
      date: this.bill.date || this.bill.billedAt,
      metodoPago: this.bill.creditCard ? 'Tarjeta de credito' : 'Monedero',
      content: this.bill.type || this.bill.content,
      // vat: this.bill.vat,
      amount: this.$currency(this.bill.amount, this.bill.currency),
      impuesto: this.$currency(this.bill.impuesto, this.bill.currency),
      total: this.$currency(this.getTotal(this.bill.amount), this.bill.currency)
    }
  }
}
</script>

<style scoped>

</style>
