const actionsTypes = {
  AddCreator: 'add_creator',
  AddPost: 'add_post'
}

const state = {
  creators: [],
  posts: []
}

const mutations = {
  reset: (state) => {
    state.creators = []
    state.posts = []
  },

  add_creator: (state, payload) => {
    const exists = (sfs) => sfs.username === payload.username
    if (state.creators.some(exists)) return
    state.creators.push(payload)
  },

  add_post: (state, payload) => {
    const exists = (sfs) => sfs.postId === payload.postId
    if (state.posts.some(exists)) return
    state.posts.push(payload)
  }
}

const actions = {
  [actionsTypes.AddCreator]: (context, payload) => {
    context.commit('add_creator', payload)
  },
  [actionsTypes.AddPost]: (context, payload) => {
    context.commit('add_post', payload)
  }
}

const getters = {
  getSfsUser: (state) => (username) => {
    return state.creators.find(sfs => sfs.username === username)
  },
  getSfsPost: (state) => (postId) => {
    return state.posts.find(sfs => sfs.postId === postId)
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'revolution',
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
