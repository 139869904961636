<template>
  <div class="image-mobile-animated">
    <div class="kuang" id="kuang" >
      <div class="slide xiaoshi left"><img class="fotoslide" :src="`images/landing/img-slider-${ranNums[0]}.png`" alt=""></div>
      <div class="slide xiaoshi middle"><img class="fotoslide" :src="`images/landing/img-slider-${ranNums[1]}.png`" alt=""></div>
      <div class="slide xiaoshi right"><img class="fotoslide" :src="`images/landing/img-slider-${ranNums[2]}.png`" alt=""></div>
      <div class="slide xiaoshi"><img class="fotoslide" src="images/landing/img-slider-stats.png" alt=""></div>
      <div class="slide xiaoshi"><img class="fotoslide" :src="`images/landing/img-slider-${ranNums[0]}.png`" alt=""></div>
      <div class="slide xiaoshi"><img class="fotoslide" :src="`images/landing/img-slider-${ranNums[1]}.png`" alt=""></div>
      <div class="slide xiaoshi"><img class="fotoslide" :src="`images/landing/img-slider-${ranNums[2]}.png`" alt=""></div>
    </div>
  </div>
</template>

<script>
import project from '@/project'

export default {
  name: 'MobileWidget',
  data () {
    return {
      ranNums: []
    }
  },
  async mounted () {
    afterExec()
  },
  beforeMount () {
    var nums = project.project === 'scatbook' ? [1, 2, 3, 4, 5, 6] : [1, 2, 3]
    var i = nums.length
    var j = 0

    while (i-- && this.ranNums.length < 3) {
      j = Math.floor(Math.random() * (i + 1))
      this.ranNums.push(nums[j])
      nums.splice(j, 1)
    }
  }
}

var sUsrAg = navigator.userAgent

function afterExec () {
  if (sUsrAg.indexOf('Firefox') > -1) {
    const element = document.querySelector('#mobile-wrapper')
    if (element) {
      element.style.width = document.getElementById('image-layout').clientWidth
    }
  }

  window.addEventListener('resize', function (event) {
    if (sUsrAg.indexOf('Firefox') > -1) {
      const element = document.querySelector('#mobile-wrapper')
      if (element) {
        element.style.width = document.getElementById('image-layout').clientWidth
      }
    }
  }, true)

  var items = document.querySelectorAll('.slide')
  for (var i = 0; i < items.length; i++) {
    items[i].addEventListener('click', function () {
      for (let j = 0; j < items.length; j++) {
        items[j].classList.remove('left', 'right', 'middle')
        items[j].classList.add('xiaoshi')
      }
      this.classList.remove('left', 'middle', 'right')
      this.classList.add('middle')
      const left = this.previousElementSibling ?? items[items.length - 1]
      left.classList.add('left')
      const right = this.nextElementSibling ?? items[0]
      right.classList.add('right')
    })
  }

  document.getElementById('kuang').addEventListener('mouseover', mouseOver)
  document.getElementById('kuang').addEventListener('mouseout', mouseOut)

  function mouseOver (evento) {
    if (document.getElementById('kuang').i) {
      clearInterval(document.getElementById('kuang').i)
    }
  }

  function mouseOut (evento) {
    if (document.getElementById('kuang').i) {
      clearInterval(document.getElementById('kuang').i)
    }
    document.getElementById('kuang').i = setInterval(function () {
      $('.slide.right').trigger('click')
    }, 2500)
  }
  mouseOut()
}
</script>

<style lang="scss" scoped>
// Mobile slider
.image-mobile-animated {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.kuang{ position: relative; width: 200px; height: 398px; cursor: pointer; }
.slide{  position: absolute; top: 0; left: 0; width: 200px; height: 398px; transition: all 1.5s; transform: rotate(10deg); }
.slide img{ width: 100%; height: 100%; transform: rotate(0deg);}
.kuang .left{ left: -200px; filter: blur(6px); z-index: 3; }
.kuang .middle{  left: 0px; transform: scale(1.3); z-index: 4; }
.kuang .right{ left: 200px; filter: blur(6px); z-index: 3; }
.xiaoshi{ left: 0px; z-index: 1; }
</style>
