<template>
  <div>
    <h1>CONTRATO DE ACCESO A LA PLATAFORMA <span class="upper">{{ project.locales.site }}</span></h1>
    <h2>PARA CREADORES DE CONTENIDO</h2>
    <br>
    <p>Este contrato de acceso a la plataforma <strong>{{ project.locales.site }}</strong> para creadores de contenido
      (en adelante,
      el "Contrato") se celebra entre:</p>
    <p>I. ARTEMISA3000 TECH SOLUTIONS, S.L., con N.I.F. B88466743 y domicilio social en Calle Goya nº47, Planta 7, Pta.
      12, 28001 Madrid (España) (en adelante, "ARTEMISA")</p>
    <p>II. La persona física identificada en el apartado de firmas de este Contrato con su nombre completo, número de
      documento oficial de identificación y dirección postal (en adelante, el "Creador de Contenido").</p>
    <br>
    <h3>1. OBJETO</h3>
    <p>El presente documento se configura como el contrato que regula las condiciones de acceso a la plataforma <strong>{{ project.locales.site }}</strong>
      (en adelante, la "Plataforma") por parte de los creadores de contenido, lo que les permite acceder a las
      funcionalidades que ofrece la Plataforma para publicar y poner a disposición de los usuarios de la Plataforma los
      contenidos audiovisuales y servicios del propio creador de contenido interesado.</p>
    <p>En este sentido, ARTEMISA presta al Creador de Contenido un servicio consistente en la habilitación de un
      espacio donde el Creador de Contenido puede ofrecer sus contenidos, productos y servicios, si así lo desea.</p>
    <p>A estos efectos, el Creador de Contenido actúa en el ámbito de la Plataforma como empresario o profesional,
      siendo de su cuenta y exclusiva responsabilidad el cumplimiento diligente de las obligaciones fiscales que le
      correspondan en su país de residencia fiscal, así como en cualesquiera otras jurisdicciones que resultaran
      competentes con motivo del desarrollo de su actividad profesional en la Plataforma.</p>
    <p>Este Contrato se complementa, en todo lo que resulte aplicable, tanto por lo previsto en la Política de
      Privacidad, como por los términos del Aviso Legal, siendo ambos de obligatorio cumplimiento para cualquier usuario
      de la Plataforma, con independencia de su condición de Usuario o Creador de Contenido.</p>
    <p>Mediante la firma de este Contrato por medio del procedimiento que en cada momento se encuentre habilitado en la
      Plataforma para el alta como Creador de Contenido, el firmante de este Contrato acepta expresamente el contenido
      del mismo y reconoce tener la capacidad suficiente para adquirir las obligaciones derivadas de sus actuaciones a
      través de la Plataforma, así como que ha leído previamente y comprende su contenido.</p>
    <br>
    <h3>2. FUNCIONALIDADES DISPONIBLES PARA EL CREADOR DE CONTENIDO</h3>
    <p>ARTEMISA presta al Creador de Contenido un servicio consistente en la habilitación de una plataforma donde el
      Creador de Contenido puede ejercer su actividad profesional por medio de las siguientes funcionalidades:</p>
    <p><strong>a) Publicación de contenidos:</strong></p>
    <p>El Creador de Contenido podrá, a través de la Plataforma, ofrecer sus contenidos audiovisuales (incluyendo
      vídeos, fotos y notas de voz -audios-) a los Usuarios de la Plataforma (en adelante, los "Contenidos"). Para ello,
      podrá publicar sus contenidos en abierto o sujeto al pago de un precio que determinará el propio Creador de
      Contenido, ya sea en modalidad de suscripción (mediante la cual el Usuario contratante podría acceder a la
      totalidad de los contenidos del Creador de Contenido sometidos a esta modalidad de contratación) o en modalidad de
      pago por acceso (mediante la cual el Usuario contratante podría acceder a un contenido determinado), a elección
      del Creador de Contenido.</p>
    <p>Además de lo anterior, el Creador de Contenido podrá participar en campañas promocionales que se puedan habilitar
      en la Plataforma, mediante su adhesión a las mismas, con el fin de ofrecer condiciones más beneficiosas a los
      Usuarios durante plazos determinados y para ellos mismos (o por ejemplo promociones del 100% de sus ventas o bonos
      extra de dinero), que incentiven la contratación de sus Contenidos y Productos por parte de los Usuarios.</p>
    <p><strong>b) Venta de productos:</strong></p>
    <p>A través de su perfil en la Plataforma, el Creador de Contenido que así lo desee podrá ofrecer productos físicos
      o digitales, exclusivos o de segunda mano, para su venta a los Usuarios (en adelante, los "Productos"). La compra
      de estos Productos por parte de los Usuarios se llevará a cabo a través del sistema de contratación de la
      Plataforma, conforme a los términos (precio, disponibilidad, fecha, etc.) que haya predefinido el Creador de
      Contenido.</p>
    <p>Con carácter general, los Productos únicamente podrán enviarse dentro del mismo país donde reside el Creador de
      Contenido (en el caso de que el Creador de Contenido resida en la Unión Europea, los envíos abarcarán a toda la
      UE). El Creador de Contenido definirá los gastos de envío, que serán únicos dentro del territorio de la Unión
      Europea, y por país cuando se encuentre fuera de dicho territorio. Por este motivo, será especialmente importante
      que el Creador de Contenido informe expresamente acerca de las restricciones territoriales al envío y lo configure
      correctamente al configurar su perfil y publicar cualquier Producto, para evitar que se realicen compras desde
      territorios a los que no se puedan enviar los mismos.</p>
    <p>Una vez un Usuario ha comprado un Producto del Creador de Contenido, este último recibirá un aviso a través de la
      Plataforma informándole de que alguien ha adquirido uno de sus Productos y la información relativa a dicha
      contratación. Será responsabilidad del Creador de Contenido contactar con el Usuario (a través del canal de
      mensajería interno de la Plataforma) para solicitarle la dirección de envío del Producto comprado, así como
      gestionar el propio envío al Usuario comprador.</p>
    <p>En el caso de que un Usuario resida fuera del territorio al cual se realizan los envíos, podrá contactar con el
      Creador de Contenido para negociar una posible venta y envío a su territorio, siendo a discreción del Creador de
      Contenido aceptar o rechazar dicha solicitud, así como establecer sus propias condiciones para realizar dicha
      venta extraordinaria. En cualquier caso, la compra del Producto se realizará a través del procedimiento de
      contratación de la Plataforma, y el Usuario deberá abonar los gastos de envío en forma de "propina" al Creador de
      Contenido a través de la Plataforma.</p>
    <p>ARTEMISA no asumirá responsabilidad alguna en relación con los acuerdos particulares que pudieran alcanzar
      los
      Creadores de Contenido y los Usuarios al respecto.</p>
    <p><strong>c) Interacción con los Usuarios:</strong></p>
    <p>El Creador de Contenido podrá interactuar con los Usuarios por diversas vías, de forma pública o privada.</p>
    <p>La interacción privada se llevará a cabo por medio del canal de mensajería interna de la Plataforma, a través del
      cual los Usuarios pueden entrar en contacto con los Creadores de Contenido de forma individualizada. Además de
      gestionar cuestiones directamente relacionadas con las contrataciones realizadas por un Usuario (p.ej., para
      indicar la dirección de envío de un Producto o la entrega de Contenidos digitales adquiridos por el Usuario), el
      Creador de Contenido podrá utilizar este canal para ofrecer al Usuario Contenidos (gratuitos o bajo la modalidad
      de pago -PPV-), así como hacer videollamadas privadas (sujeto al pago del precio correspondiente, que se
      tarificará por minuto con base en la tarifa EUROS/minuto que fije el propio Creador de Contenido, aunque también
      podrán realizarse de forma gratuita), vender paquetes de Contenidos y recibir propinas directas.</p>
    <p>La interacción pública se llevará a cabo mediante la emisión en directo en una sala en abierto, a la que podrán
      acceder los Usuarios de la Plataforma para visionar dicha emisión sin restricción de acceso y de forma gratuita,
      sin perjuicio de que podrán realizar pagos (del importe que cada Usuario desee, sin ser obligatorio).</p>
    <p><strong>d) Gestión de la facturación y los pagos:</strong></p>
    <p>ARTEMISA proporciona al Creador de Contenido un servicio de centralización y gestión de las contrataciones
      que
      este lleve a cabo con los Usuarios, de tal forma que, en relación con los Contenidos, Productos y/o servicios que
      los Usuarios pudieran estar interesados en contratar, ARTEMISA gestionará dichas contrataciones mediante el
      procedimiento de contratación de la Plataforma.</p>
    <p>A estos efectos, el Creador de Contenido fijará en su perfil el precio aplicable a cada Contenido, Producto y
      servicio que ofrezca en la Plataforma, aunque para los Productos o Contenidos personalizados, o a petición del
      Usuario, podrá acordar un precio distinto con el Usuario interesado a través del canal de mensajería interna. En
      cualquier caso, la contratación se formalizará a través de la Plataforma, constituyéndose ARTEMISA como
      intermediador a efectos del pago y facturación.</p>
    <br>
    <h3>3. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>

    <h4>3.1. Derechos sobre los Contenidos.</h4>

    <p>Todos aquellos Contenidos, de cualquier naturaleza, que pudiera publicar u ofrecer el Creador de Contenido en la
      Plataforma serán titularidad del Creador de Contenido en todo momento, sin que ARTEMISA adquiera derecho
      alguno
      sobre los mismos por el mero hecho de alojarlos para ponerlos a disposición de los Usuarios de la Plataforma.</p>

    <p>En este sentido, todos los derechos de propiedad intelectual, industrial y de imagen sobre cualesquiera
      Contenidos que sean publicados por el Creador de Contenido serán titularidad de este. Sobre dichos contenidos, el
      Creador de Contenido únicamente otorga a ARTEMISA una licencia para su correcta publicación y explotación en
      la
      Plataforma, así como en otras plataformas de ARTEMISA3000 TECH SOLUTIONS, S.L. y/o en sitios webs de terceros
      alojados en servidores de esta última, con el fin de promocionar dicho contenido bajo las condiciones configuradas
      por el Creador de Contenido en cuestión. Dicha licencia tendrá carácter mundial y quedará limitada al tiempo que
      el Creador de Contenido esté registrado en la Plataforma y/o hasta que el Contenido en cuestión sea eliminado, ya
      sea por el propio Creador de Contenido o por ARTEMISA como consecuencia de cualquier infracción de derechos,
      de
      los términos aplicables a la Plataforma y/o de la legislación vigente. No obstante, en el caso de Contenidos de
      pago o vendidos a través del canal de mensajería de la Plataforma, los mismos se asignarán al Usuario que los
      hubiera adquirido y continuarán estando disponibles para este durante todo el tiempo que continúe registrado en la
      Plataforma, por lo que la cesión de derechos del Creador de Contenido se realiza, en estos casos, a
      perpetuidad.</p>

    <p>ARTEMISA no tiene intención de utilizar los contenidos generados por el Creador de Contenido para fin
      distinto
      de la efectiva prestación del servicio y de la promoción de la Plataforma, tal y como se especifica en el Aviso
      Legal.</p>

    <p>A tal fin, y sin carácter limitativo, la antedicha licencia se realizará expresamente para el alojamiento de los
      contenidos en los servidores de ARTEMISA (o del tercero que esta contrate a estos efectos), así como para su
      puesta a disposición en la Plataforma (y en otras plataformas de ARTEMISA3000 TECH SOLUTIONS, S.L. o alojadas en
      sus servidores) conforme a su tipología y a la modalidad de acceso que se hubiera configurado para el contenido en
      cuestión.</p>

    <p>El Creador de Contenido reconoce y garantiza ser titular de todos los derechos sobre los Contenidos que publique
      en la Plataforma, y que los mismos son plenamente conformes con el ordenamiento jurídico y no infringen ni
      menoscaban los derechos de terceros, incluidos los de imagen, ni supondrá ningún coste y/o gasto adicional para
      ARTEMISA, estando el Creador de Contenido en cuestión capacitado para disponer de los mismos libremente.
      Asimismo, garantiza que los Contenidos que publique no tienen carácter confidencial ni son perjudiciales para
      terceros.</p>

    <p>El Creador de Contenido reconoce asumir la responsabilidad, dejando indemne a ARTEMISA, por cualquier
      comunicación que suministre personalmente o a su nombre, alcanzando dicha responsabilidad sin restricción alguna
      la exactitud, legalidad, originalidad y titularidad de la misma.</p>

    <h4>3.2. Derechos sobre la Plataforma.</h4>

    <p>Tal y como se describe en el Aviso Legal de la Plataforma, ARTEMISA es titular de la Plataforma, así como de
      todos sus materiales, informaciones y elementos, o en su caso cuenta con las licencias correspondientes sobre los
      mismos para su explotación en este ámbito.</p>

    <p>El acceso a la Plataforma por parte del Creador de Contenido en ningún caso otorga a este derecho alguno sobre la
      misma, ni sobre sus elementos, a salvo de un derecho de acceso y uso limitado al desarrollo de la actividad
      profesional del Creador de Contenido en el marco de la misma. Quedan expresamente reservados a ARTEMISA todos
      los
      derechos de propiedad intelectual e industrial sobre la Plataforma y, en particular, queda prohibido modificar,
      copiar, reproducir, comunicar públicamente, transformar o distribuir, por cualquier medio y bajo cualquier forma,
      la totalidad o parte de la misma y/o de los elementos que la integran si no se cuenta con la autorización previa,
      expresa y por escrito de ARTEMISA o, en su caso, del titular de los derechos correspondientes.</p>

    <p>Este derecho limitado de acceso y uso del Creador de Contenido quedará expresamente revocado en el momento en que
      quede resuelto este Contrato por cualquiera de las circunstancias contempladas en este documento.</p>
    <br>
    <h3>4. OBLIGACIONES PARA EL CREADOR DE CONTENIDO</h3>

    <p>El Creador de Contenido se obliga a:</p>

    <ol type="a">
      <li>Cumplir todas las obligaciones, normas de uso y condiciones establecidas por ARTEMISA en la Plataforma en
        relación con el uso de la misma, tal y como se contienen en este Contrato y en el <a
          :href="`https://${project.locales.site}/conditions`">Aviso Legal</a>.
      </li>
      <li>Cumplir con diligencia las obligaciones fiscales con las autoridades tributarias competentes en su país de
        residencia fiscal y en otras jurisdicciones que resultaran competentes. En este sentido, el Creador de Contenido
        se obliga a asesorarse fiscalmente en relación con el desarrollo de su actividad profesional a través de la
        Plataforma.
      </li>
      <li>Facilitar toda la información que sea necesaria para gestionar su alta en la Plataforma, así como mantener
        dicha información actualizada en todo momento, obligándose a comunicar a ARTEMISA cualquier modificación en
        dicha información en caso de que esta se llegara a producir.
      </li>
      <li>Comunicar a ARTEMISA, al momento de alta en la Plataforma, su país de residencia fiscal y comunicar
        cualquier
        cambio en dicha residencia que se produjera durante la vigencia de este Contrato.
      </li>
      <li>Ofrecer sus servicios, Contenidos y Productos en la Plataforma de conformidad con la legislación vigente en
        cada momento y con los términos establecidos en el presente Contrato y en el
        <router-link to="/conditions">Aviso Legal</router-link>
        , así como en cualesquiera otras políticas que pudiera
        publicar ARTEMISA en la Plataforma en cada momento.
      </li>
      <li>No publicar contenidos que vulneren lo establecido en el <a
        :href="`https://${project.locales.site}/conditions`">Aviso
        Legal</a> en cuanto a contenidos y conductas prohibidos.
      </li>
      <li>En caso de que en cualquier Contenido aparecieran terceras personas, estas deberán en todo caso ser mayores de
        edad en la jurisdicción donde se genere dicho Contenido. Además, el Creador de Contenido deberá obtener
        previamente su consentimiento para la publicación del Contenido en la Plataforma. Dicho consentimiento deberá
        estar firmado y reflejar expresamente la autorización de la persona participante respecto de la cesión de sus
        derechos de imagen para la explotación del Contenido en Internet, debiendo ser dicha autorización lo
        suficientemente amplia para abarcar la publicación del Contenido en la Plataforma y en otras plataformas de
        ARTEMISA3000 TECH SOLUTIONS, S.L., según se describe en este Contrato ARTEMISA podrá solicitar en cualquier
        momento que el Creador de Contenido aporte copia de dicho consentimiento, junto con una copia del documento
        nacional de identidad oficial de dicha persona y prueba de identidad de la misma, solicitud que el Creador de
        Contenido deberá atender de inmediato. A estos efectos, ARTEMISA ha habilitado un apartado en la Plataforma
        para que el Creador de Contenido pueda subir dicha documentación, la cual se obliga a mantener actualizada en
        todo momento con toda la información pertinente. ARTEMISA podrá realizar las comprobaciones que considere
        necesarias para verificar que la información es completa, veraz y que se encuentra actualizada, pudiendo
        solicitar al Creador de Contenido en cualquier momento que la aporte o actualice, si estimara que está
        incompleta o desactualizada. El Creador de Contenido deberá atender dicho requerimiento de inmediato. En todo
        caso, el Creador de Contenido mantendrá indemne a ARTEMISA ante cualquier reclamación que dichos terceros
        pudieran cursar por vulneración de sus derechos.
      </li>
      <li>Gestionar las entregas de Contenidos y Productos que contratase cada Usuario, siendo dicha gestión de su
        exclusiva responsabilidad en cuanto a habilitación de los Contenidos en la Plataforma y envío de los Productos a
        la dirección indicada por cada Usuario.
      </li>
    </ol>
    <br>
    <h3>5. OBLIGACIONES PARA ARTEMISA</h3>

    <p>ARTEMISA se obliga a:</p>

    <ol type="a">
      <li>Prestar sus servicios en relación con la Plataforma de conformidad con la legislación vigente en cada
        momento.
      </li>
      <li>Gestionar las contrataciones que realicen los Usuarios con los Creadores de Contenido con la diligencia
        exigible y conforme a las buenas prácticas del mercado.
      </li>
      <li>Facilitar al Creador de Contenido, sin demoras indebidas, toda la información relacionada con las
        contrataciones que realizaran los Usuarios respecto de los Contenidos y Productos, a efectos de que el Creador
        de Contenido pueda gestionar la producción y entrega de los Contenidos o Productos contratados por los Usuarios.
      </li>
      <li>Abonar las cantidades generadas por la actividad del Creador de Contenido a través de la Plataforma de
        conformidad con lo aquí dispuesto.
      </li>
    </ol>
    <br>
    <h3>6. CONDICIONES ECONÓMICAS: PAGO Y FACTURACIÓN</h3>

    <h4>6.1. Fijación de los precios</h4>

    <p>El Creador de Contenido establecerá el precio que desee para sus Contenidos y Productos, así como para los
      servicios que ofrezca a través del canal de mensajería interno y por videollamada, en cada modalidad de
      contratación (de pago, por suscripción, etc.). Este precio será el que deberán abonar los Usuarios que deseen
      contratar el Contenido, adquirir el Producto, intercambiar mensajes o realizar una videollamada con el Creador de
      Contenido, gestionándose dicho pago a través de la Plataforma.</p>

    <p>Aunque el Creador de Contenido tendrá libertad para fijar el precio que desee para sus Contenidos, Productos y
      servicios, se encontrará limitado por el importe máximo establecido por ARTEMISA en cada momento, el cual será
      aplicable de forma general a la totalidad de Creadores de Contenido de la Plataforma. Dicho límite máximo podrá
      ser modificado por ARTEMISA en cualquier momento, y en caso de que una modificación a la baja implicase que
      determinados Contenidos, Productos o servicios superasen el nuevo límite máximo fijado, el Creador de Contenido
      será advertido por la Plataforma para que pueda ajustar el precio del Contenido, Producto o servicio afectado.</p>

    <p>A su vez, como contraprestación por la prestación del servicio, el Creador de Contenido conviene con ARTEMISA
      que esta (i) aplicará un recargo al precio que deba abonar el Usuario contratante y (ii) retendrá un VEINTE POR
      CIENTO (20%) de la cantidad a liquidar con el Creador de Contenido por los Contenidos, Productos o servicios que
      hubieran contratado los Usuarios. De este modo, el precio total que abonará el Usuario incluirá el precio del
      Contenido, Producto o servicio contratado (sobre el cual ARTEMISA retendrá el porcentaje indicado como
      contraprestación), más un recargo en concepto de gastos de gestión y de servicio, y sobre ello se aplicará el
      Impuesto sobre el Valor Añadido (IVA).</p>
    <br>
    <h4>6.2. Pago y facturación</h4>

    <p>Las cantidades que genere la actividad del Creador de Contenido a través de la Plataforma serán abonadas por
      ARTEMISA de conformidad con los plazos y procedimientos descritos en el siguiente enlace: <a
        :href="`https://${project.locales.site}/academy/payments.html`">https://{{
          project.locales.site
        }}/academy/payments.html</a>, siempre que
      en el momento en que el Creador de Contenido solicite la liquidación, haya generado un importe mínimo de CIEN
      (100) EUROS una vez deducida la comisión del 20% establecida en el párrafo anterior. Es decir, deberá haber
      generado un importe mínimo de 120 EUROS brutos.</p>

    <p>De este modo, a las cantidades brutas totales se les detraerán aquellas que correspondan a ARTEMISA como
      contraprestación por sus servicios y los gastos bancarios, y se les aplicará el IVA y la retención que en su caso
      corresponda.</p>

    <p>El pago de la cantidad acumulada para cada periodo de facturación será realizado previa solicitud del Creador de
      Contenido (siempre que hubiera superado los 120 EUROS brutos mínimos indicados arriba). Recibida una solicitud de
      liquidación, ARTEMISA validará el importe para comprobar que es correcto, y en ese caso procederá a realizar
      el
      pago. Con la realización del pago se emitirá la correspondiente autofactura dentro de la Plataforma, que estará
      disponible para el Creador de Contenido automáticamente a partir de ese momento. En este sentido, ambas Partes
      aceptan que el procedimiento de facturación se lleve a cabo mediante la emisión de autofacturas electrónicas.</p>

    <p>En cualquier caso, en el caso de que ARTEMISA fuese requerida por cualquier autoridad fiscal para el ingreso
      de
      impuestos en nombre del Creador de Contenido, la cantidad requerida se detraerá de la liquidación de cantidades
      futuras que fuesen debidas por ARTEMISA al Creador de Contenido en cuestión.</p>
    <br>
    <h3>7. RESPONSABILIDADES DEL CREADOR DE CONTENIDO</h3>

    <p>El Creador de Contenido será responsable, y deberá mantener indemne a ARTEMISA, por cualquier reclamación de
      terceros, acción o sanción de cualquier naturaleza relacionada con la actividad del Creador de Contenido, las
      interacciones con los Usuarios, los Contenidos y Productos que publicara en la Plataforma, la entrega de estos a
      los Usuarios contratantes, y cualesquiera responsabilidades que se derivaran del uso de los mismos por parte de
      los Usuarios.</p>

    <p>Lo anterior será especialmente exigible en relación con cualesquiera terceros que pudieran aparecer en los
      Contenidos que publicara el Creador de Contenido en la Plataforma, respecto de los cuales se obliga a obtener el
      debido consentimiento para la explotación de su imagen en la Plataforma.</p>

    <p>Asimismo, el Creador de Contenido será responsable y garantiza el cumplimiento de sus obligaciones legales de
      cualquier naturaleza, incluida la tributaria, y deberá mantener indemne a ARTEMISA en caso de cualquier
      reclamación relacionada con un incumplimiento de dichas obligaciones por parte del Creador de Contenido.</p>

    <p>El Creador de Contenido será responsable de cualesquiera actuaciones que pudieran llevar a cabo terceras personas
      utilizando su cuenta de Creador de Contenido, y responderá personalmente ante ARTEMISA y mantendrá a esta
      indemne
      por cualesquiera reclamaciones, acciones, sanciones o consecuencias de cualquier naturaleza que tuvieran dichas
      actuaciones. En cualquier caso, el Creador de Contenido deberá trasladar los términos que rigen el uso de la
      Plataforma a cualquier tercero a quien pudiera dar acceso a su cuenta de Creador de Contenido.</p>

    <p>ARTEMISA se reserva el derecho a suspender de inmediato el acceso a la Plataforma por parte del Creador de
      Contenido en caso de que se produjese cualquier incumplimiento de las garantías y obligaciones recogidas en esta
      cláusula, quedando retenidas las cantidades que en ese momento se hubiesen generado y se encontrasen pendientes de
      pago al Creador de Contenido, y reservándonos el derecho a cualquier tipo de reclamación económica, basándonos en
      los daños y perjuicios que pueda causar un mal uso de la Plataforma por parte del Creador de Contenido.</p>

    <h3>8. RESPONSABILIDADES LIMITADAS DE ARTEMISA</h3>

    <p>La responsabilidad de ARTEMISA para con el Creador de Contenido estará limitada a la gestión de las
      contrataciones que realicen los Usuarios con los Creadores de Contenido a través de la Plataforma, así como a los
      servicios que presta al Creador de Contenido.</p>

    <p>Además de las exclusiones de responsabilidad contenidas en el Aviso Legal, ARTEMISA no será responsable en
      caso
      de funcionamientos defectuosos de la Plataforma que resulten de un mal uso o de un incumplimiento de los términos
      del presente documento por parte del Creador de Contenido.</p>

    <p>En ningún caso ARTEMISA será responsable de los daños indirectos, incluyendo sin carácter limitativo lucro
      cesante o pérdida de beneficios, que pudieran producirse como consecuencia de cualquier incumplimiento de este
      Contrato.</p>

    <p>En la medida permitida por la ley aplicable, ARTEMISA no ofrece ninguna garantía sobre el uso de la
      Plataforma.
      La Plataforma y sus funcionalidades se facilitan 'tal cual' y ARTEMISA rechaza cualquier otra garantía o
      condición, ya sea expresa o implícita, incluyendo, sin limitación, garantías, obligaciones o condiciones de
      idoneidad para fines determinados, calidad, ejecución, disponibilidad, continuidad, resultados y ausencia de
      virus. Sin perjuicio de lo anterior, ARTEMISA garantiza que ha adoptado todas las medidas necesarias, dentro
      de
      sus posibilidades y del estado de la técnica, para garantizar el funcionamiento de la Plataforma y reducir los
      fallos de la misma al mínimo. Asimismo, ARTEMISA rechaza cualquier garantía en relación con la compatibilidad
      de
      la Plataforma y de sus funcionalidades y servicios con los sistemas, aplicaciones, dispositivos, redes y cualquier
      otro software o hardware utilizado por el Creador de Contenido, salvo que ARTEMISA haya declarado su
      compatibilidad expresamente y por escrito.</p>
    <br>
    <h3>9. VIGENCIA Y TERMINACIÓN</h3>

    <p>Este Contrato entrará en vigor entre ARTEMISA y el Creador de Contenido el día en que este último se dé de
      alta
      en la Plataforma, lo que conlleva la firma de este Contrato, y se mantendrá vigente mientras el Creador de
      Contenido continúe registrado en la Plataforma, con independencia de que utilice la misma de forma activa para
      ofrecer sus Contenidos y Productos a los Usuarios, hasta que cualquiera de las partes lo resuelva conforme a los
      términos de este Contrato. En este sentido, ARTEMISA se reserva el derecho de realizar las verificaciones y
      comprobaciones que considere necesarias para validar el alta del Creador de Contenido, en cualquier momento tras
      la firma de este Contrato.</p>

    <p>No obstante, determinadas disposiciones de este documento tienen la intención expresa o implícita de continuar en
      vigor tras la terminación o vencimiento, de modo que permanecerán en vigor y continuarán siendo exigibles para el
      Creador de Contenido conforme a sus términos incluso cuando el Contrato ya no esté vigente.</p>

    <p>El Creador de Contenido reconoce que este Contrato podrá ser resuelto anticipadamente y, por tanto, su acceso a
      la Plataforma podrá ser revocado, en las siguientes circunstancias:</p>

    <ul>
      <li>Resolución por conveniencia. El Creador de Contenido puede resolver el Contrato en cualquier momento cursando
        su baja de la Plataforma conforme se describe en el Aviso Legal.
      </li>
      <li>Resolución por incumplimiento. ARTEMISA podrá resolver el Contrato en caso de incumplimiento del mismo y/o
        del Aviso Legal por parte del Creador de Contenido y, en particular, en caso de incumplimiento de cualesquiera
        obligaciones o prohibiciones vigentes en la Plataforma en cada momento. Atendiendo a la naturaleza del
        incumplimiento, ARTEMISA podrá, a su discreción, otorgar al Creador de Contenido un plazo máximo para
        subsanar
        el mismo. ARTEMISA se reserva el derecho a suspender el acceso del Creador de Contenido a la Plataforma
        durante
        el plazo de subsanación. En cualquier caso, dicha resolución no eximirá al Creador de Contenido de cumplir las
        obligaciones que hubiesen estado pendientes y que aún fuesen exigibles.
      </li>
      <li>Resolución por interrupción o suspensión del servicio de la Plataforma. En el caso de que ARTEMISA
        decidiese
        interrumpir o suspender, provisional o definitivamente, el servicio de la Plataforma, ARTEMISA podrá
        resolver
        el Contrato unilateralmente. En ese caso, ARTEMISA lo comunicará con una antelación razonable con el fin de
        que
        estos puedan, en su caso, gestionar el cierre de las contrataciones o servicios que tuviesen pendientes en la
        Plataforma con los Usuarios.
      </li>
    </ul>

    <p>En caso de resolución de este Contrato a conveniencia del Creador de Contenido o por interrupción o suspensión
      del servicio de la Plataforma, el Creador de Contenido perderá su derecho de acceso a la Plataforma, pero en todo
      caso podrá liquidar con ARTEMISA las cantidades que se encontrasen pendientes de pago a la fecha de
      resolución,
      siempre y cuando dichas cantidades superen el mínimo liquidable establecido en la cláusula 6.2 de este Contrato.
      Cuando la causa de resolución fuera el incumplimiento por parte del Creador de Contenido, este perderá el derecho
      a la liquidación de las cantidades pendientes de pago.</p>

    <p>El Creador de Contenido no tendrá derecho a ningún tipo de indemnización o compensación por la resolución
      anticipada del Contrato. ARTEMISA se reserva el derecho de reclamar al Creador de Contenido la correspondiente
      indemnización por los daños y perjuicios que en su caso le hubiera ocasionado el incumplimiento del Creador de
      Contenido que diese lugar a la resolución, que percibirá de forma cumulativa a las cantidades que en su caso
      pudiera haber retenido en virtud del párrafo anterior.</p>
    <br>
    <h3>10. SERVICIO DE SOPORTE</h3>

    <p>Con el fin de prestar un servicio de soporte a los Creadores de Contenido, ARTEMISA ha habilitado un canal de
      contacto electrónico para la gestión de cuantas consultas, quejas o sugerencias, así como la resolución de
      incidencias, que pudieran plantearse para el Creador de Contenido en relación con el funcionamiento y la operativa
      de la Plataforma y los servicios puestos a su disposición en la misma.</p>

    <p>Dicho canal está disponible a través del e-mail <a
      :href="`mailto:stars@${project.locales.site}`">stars@{{ project.locales.site }}</a>.</p>

    <p>ARTEMISA atenderá las comunicaciones recibidas por este canal de lunes a viernes, en horario de 9:00 - 18:00 CET, con un compromiso de respuesta de 72 horas en días laborables.</p>
    <br>
    <h3>11. CONFIDENCIALIDAD</h3>

    <p>11.1. ARTEMISA podría tener acceso a información confidencial compartida por el Creador de Contenido con el
      fin
      de prestar los servicios de la Plataforma y/o gestionar la relación contractual. En el caso de que eso ocurriera,
      nos comprometemos a: (i) utilizar la información confidencial recibida única y exclusivamente para el cumplimiento
      de las condiciones de este Contrato y la prestación de los servicios contratados; (ii) custodiar de manera
      estricta la información confidencial, evitando su divulgación o suministro, en todo o en parte, a cualquier
      tercero sin el consentimiento previo y expreso del Creador de Contenido; (iii) tratar la información confidencial
      con la máxima discreción y utilizarla única y exclusivamente en relación con la prestación de los servicios; (iv)
      implantar las medidas de seguridad razonablemente necesarias a efectos de cumplir esta cláusula.</p>

    <p>11.2. Las anteriores obligaciones de confidencialidad no serán aplicables respecto de aquella información que:
      (i) sea de dominio público o fuese hecha pública por el Creador de Contenido; (ii) fuese lícitamente obtenida de
      terceros por ARTEMISA sin restricción para su divulgación; (iii) fuese desarrollada independientemente y de
      manera legítima por ARTEMISA; y (iv) fuese requerida por una autoridad judicial o administrativa en ejercicio
      de
      su competencia y únicamente en la medida en que la divulgación de la información fuese necesaria para cumplir ese
      requerimiento.</p>
    <br>
    <h3>12. MISCELÁNEA</h3>

    <p>12.1. Los encabezamientos de las distintas cláusulas son meramente informativos y no afectarán, calificarán ni
      fomentarán la interpretación de este Contrato.</p>

    <p>12.2. Todas las cláusulas o extremos de este Contrato deben ser interpretadas de forma independiente, no viéndose
      afectadas el resto de las cláusulas en caso de que una de ellas haya sido declarada nula por sentencia judicial o
      resolución arbitral firme. Se sustituirá la cláusula o cláusulas afectadas por otra u otras que preserven los
      efectos perseguidos por este documento.</p>

    <p>12.3. Si alguna de las cláusulas de este Contrato es rechazada o declarada ineficaz por un Tribunal o regulador
      competente, dicha ineficacia no afectará a las disposiciones restantes, que seguirán siendo de aplicación.</p>
    <br>
    <h3>13. MODIFICACIONES AL CONTRATO</h3>

    <p>13.1. ARTEMISA podrá modificar las condiciones del Contrato en cualquier momento, mediando preaviso al
      Creador
      de Contenido.</p>

    <p>13.2. Cualquier modificación será comunicada por ARTEMISA por medio de correo electrónico y banner o aviso en
      la
      propia Plataforma, con una antelación mínima de 30 días antes de la
      entrada en vigor de las nuevas condiciones contractuales, con el fin de que el Creador de Contenido tenga ocasión
      de revisarlas de forma previa a su entrada en vigor.</p>

    <p>13.3. Sin perjuicio de que ARTEMISA pueda, en su caso, establecer otros mecanismos para la aceptación de las
      nuevas condiciones del Contrato (de lo cual se informará al momento de notificar los cambios), la aceptación se
      producirá de forma tácita si, transcurrido el plazo de preaviso, el Creador de Contenido continúa utilizando la
      Plataforma para ofrecer sus Contenidos, Productos y servicios. Por ese motivo, en caso de no estar de acuerdo con
      las nuevas condiciones del Contrato, el Creador de Contenido deberá darse de baja de la Plataforma antes de que
      estas entren en vigor, ya que, llegada dicha fecha, las nuevas condiciones serán exigibles a cualquier Creador de
      Contenido que continúe utilizando la Plataforma, entendiéndose que las ha aceptado tácitamente.</p>
    <br>
    <h3>14. LEY APLICABLE Y JURISDICCIÓN COMPETENTE</h3>

    <p>14.1. Este Contrato, incluido el uso que el Creador de Contenido haga de la Plataforma y de sus funcionalidades,
      así como en relación con los Contenidos y Productos que ofrezca en la misma, se rigen por la legislación española
      vigente en cada momento.</p>

    <p>14.2. En caso de controversia, acción o reclamación que pudiera surgir en relación con la interpretación y
      aplicación de este Contrato, ambas Partes se someten a la jurisdicción exclusiva de los Juzgados y Tribunales de
      la ciudad de Madrid (España), con renuncia expresa y voluntaria a cualquier otro fuero, salvo disposición legal
      imperativa en contrario.</p>

    <p>Y, en prueba de conformidad con cuanto antecede, el presente Contrato se firma entre ARTEMISA y el Creador de
      Contenido indicado a continuación en la fecha abajo indicada:</p>
    <br>
    <slot/>
    <br>
  </div>
</template>

<script>
import project from '@/project'

export default {
  name: 'es',
  data () {
    return {
      project
    }
  }
}
</script>

<style scoped lang="scss">

</style>
