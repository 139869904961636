<template>
  <div>
    <v-row v-if="loading" align="center" justify="center" class="my-5">
      <v-progress-circular v-if="loading"
                           indeterminate
                           class="p-4"
                           color="primary">
      </v-progress-circular>
    </v-row>

    <section v-if="!loading" v-html="conditions">
    </section>
  </div>
</template>
<script>
import marked from 'marked'
import project from '@/project'
import {mapState} from "vuex";

export default {
  name: 'TermsConditions',
  props: {
    type: {type: String, default: 'user'}
  },
  data() {
    return {
      loading: false,
      conditions: '',
      project
    }
  },

  watch: {
    type: {
      async handler(value) {
        this.loadConditions()
      },
      immediate: true
    },
    '$i18n.locale': function () {
      this.loadConditions()
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer']),
    here() {
      return this.$i18n.locale === 'es' ? 'Revísalos aquí' : 'Review them here.'
    }
  },
  methods: {
    async loadConditions() {
      const lang = this.$i18n.locale === 'es' ? 'es' : 'en'
      const type = this.isInfluencer ? 'influencer' : 'user'
      this.loading = true
      const terms = await import(`./texts/${type}_${lang}.md`)
      this.conditions = marked(terms.default
        .replaceAll('{{SITE}}', this.$t('site'))
        .replaceAll('{{PROJECT_UPPER}}', this.$t('project_upper'))
        .replaceAll('{{PROJECT}}', this.$t('project'))
        .replaceAll('{{CONTACT_EMAIL}}', this.$t('contact_email'))
        .replaceAll('{{INFO_EMAIL}}', this.$t('info_email'))
        .replaceAll('{{CIF_IMG}}', this.project.showFooterImages ?
          `<img src="images/cif_${this.$vuetify.theme.dark ? 'white' : 'black'}.png" style="margin-bottom: 0px; width: 75px; height: 10px"/>` :
          'B88466743')
        .replaceAll('{{COMPANY_IMG}}', this.project.showFooterImages ?
          `<img src="images/artemisa_${this.$vuetify.theme.dark ? 'white' : 'black'}.png" style="margin-bottom: -1px; width: 180px; height: 13px"/>` :
          'Artemisa 3000 Tech Solutions SL')
      )
      this.loading = false

    }
  }
}
</script>
