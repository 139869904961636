'user strict'

import xlsx from 'json-as-xlsx'
import config from '@/project'
import utils from '@/utils'

import api from '@/api/Api'

const fixDates = ({ sheet, columns, content }) => {
  const fixed = content.map(complaint => {
    const reportCreatedAt = utils.formatDate(complaint.report_createdAt, 'YYYY-MM-DD');
    const actionCreatedAt = utils.formatDate(complaint.action_createdAt, 'YYYY-MM-DD');
    return { ...complaint, reportCreatedAt, actionCreatedAt }
  })

  return { sheet, columns, content: fixed }
}
export default {

  list (query) {
    return api.get('/complaints', query)
  },

  create (complaint) {
    return api.post('/complaints', complaint)
  },

  update (id, attrs) {
    return api.put(`/complaints/${id}`, attrs)
  },

  delete (id) {
    return api.delete(`/complaints/${id}`)
  },

  bulkDelete ({ resource, resourceId }) {
    return api.post('/complaints/delete', { resource, resourceId })
  },

  async exportComplaints ({ start, end }) {
    const result = await api.get('/complaints/export', { start, end })
    if (!result.error) {
      const startDate = utils.formatDate(start, 'YYYYMMDD')
      const endDate = utils.formatUTCDate(end, 'YYYYMMDD')
      const complaints = result.data.map(fixDates)
      xlsx(complaints, { fileName: `${config.acronym}_${startDate}-${endDate}_Complaints.xlsx` })
    }
    return result
  }
}
