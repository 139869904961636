<template xmlns="http://www.w3.org/1999/html">
  <section>


    <v-col class="align-center" style="margin: auto;text-align: center; padding: 0px;">
      <router-link :to="{ name: 'home' }">
        <v-img contain style="margin: auto"
               :src="`/images/logo_horizontal.svg?dfk23`"
               max-width="300" max-height="48" alt="loverfans"></v-img>
      </router-link>

      <br>
      <p class="header" v-html="$t(`index.header.${project.project}`)"></p>
      <p class="counter">
          <span class="dest-text" style="font-family: 'BenchNine', sans-serif;">
            {{ formatNumber(influencersCount) }}
          </span> {{ $t(`index.counter.creators`) }}
        <span class="dest-text" style="font-family: 'BenchNine', sans-serif;">| {{ formatNumber(videos) }}</span>
        {{ $t(`index.counter.videos`) }} <span class="dest-text"
                                               style="font-family: 'BenchNine', sans-serif;">| {{
          formatNumber(photos)
        }}</span>
        {{ $t(`index.counter.photos`) }}
        <br>
        <router-link :to="{name: 'discover_star'}">
          <span class="discover">{{ $t(`index.counter.discover`) }} {{ project.locales.project_upper }}</span>
        </router-link>
      </p>

    </v-col>
    <v-row class="first" justify="center">
      <v-col :cols="isMobile ? 11 : 5" class="image-mobile-wrapper" v-if="!isMobile">
        <component :is="landingWidget"/>
      </v-col>
      <v-col :cols="isMobile ? 12 : 7" class="sec-form">
        <v-row id="log-sect-lover" style="font-size: 16px;">
          <v-col cols="12" class="text-center"
                 style="margin-left: auto; padding: 0; margin-top: 20px; margin-right: auto;height: 25px;">
            <v-menu
              top
              close-on-content-click
            >
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <img width="16" height="13" :src="country.image" alt="country"> <span
                  class="loginWith">{{ $t(`index.login`) }}</span>
                </v-btn>

              </template>

              <v-list style="overflow: hidden;">
                <v-list-item
                  dense
                  v-for="(item, index) in countries"
                  :key="index"
                >
                  <v-list-item-title @click="setLocale(item)" style="font-family: Quicksand, sans-serif;"><span
                    class="flag"><img width="20" height="13" :src="item.image" alt="country"></span> {{ item.value }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" style="margin-bottom: -10px;">
            <NewConnectTwitter large block color="#3b7ddd" isLogin
                               :connecting.sync="connectingTwitter"
                               @twitter-connected="redirect"></NewConnectTwitter>
          </v-col>
          <!--<v-col v-if="googleSigninEnabled"
            cols="12" class="text-center" style="margin-bottom: -10px;">
            <GoogleSignin />
          </v-col>

          <v-col cols="12">
            <v-btn class="login-button" color="#3b7ddd" large block><img src="images/google.svg" /><span class="login-button">&nbsp;&nbsp;{{ $t(`index.google`) }}</span></v-btn>
          </v-col>
          -->
          <v-col cols="12" style="margin-bottom: -30px;">
            <p class="p-0" style="text-align: center; margin: 0; font-family: Quicksand, sans-serif;">-
              {{ $t(`index.or`) }} -</p>
            <p style="text-align: center;margin-bottom:4px !important; font-family: 'Quicksand', sans-serif;"
               v-if="showSignup" v-html="$t(`index.free_account`)"></p>
          </v-col>
        </v-row>

        <span v-if="showSignup">
            <SimpleSignUpForm :star="star" :promocode="promoCode"></SimpleSignUpForm>
          </span>
        <span v-else>
            <SimpleLoginForm/>
          </span>

        <div class="text-center">
          <a v-if="showSignup" @click="showSignup = !showSignup"
             style="font-family: BenchNine, sans-serif; font-size: 20px;">{{ $t(`signup.already_registered`) }}</a>
          <a v-else @click="showSignup = !showSignup"
             style="font-family: BenchNine, sans-serif; font-size: 20px;">{{ $t(`signup.not_member`) }}</a>
        </div>
      </v-col>
    </v-row>

    <v-row style="margin-bottom: 40px;" :style="{marginTop: showSignup ? '10px' : isMobile ? '10px' : '45px'}">
      <v-col class="dest-text tit-slogan text-center">
        <strong class="primary--text"
                style="font-size: 32px;font-family: 'Quicksand', sans-serif !important; font-weight: 900">{{
            $t(`index.footer.title`)
          }}</strong>
        <br>
        <router-link
          class="revolution-link txtmin text-center" style="font-size: 14px;text-decoration: underline;"
          to="/revolution">{{ $t(`index.footer.link`) }}
        </router-link>
      </v-col>
    </v-row>
    <cookies-modal></cookies-modal>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import AppFooter from '@/components/layouts/AppFooter'
import project from '@/project'
import cookies from '@/cookies'
import SimpleLoginForm from '@/components/sessions/SimpleLoginForm'
import SimpleSignUpForm from '@/components/sessions/SimpleSignUpForm'
import UserApi from '@/api/UserApi'
import PostApi from '@/api/PostApi'
import NewConnectTwitter from '@/components/twitter/NewConnectTwitter'
import GoogleSignin from '@/components/twitter/GoogleSignin'
import CookiesModal from '@/components/cookies/cookies'
import MobileWidget from './widget/MobileWidget.vue'
import SlaveWidget from './widget/SlaveWidget.vue'
import Recaptcha from "@/components/verifyIdenty/Recaptcha.vue";

export default {
  name: 'LoverLanding',
  components: {
    NewConnectTwitter,
    SimpleSignUpForm,
    AppFooter,
    SimpleLoginForm,
    GoogleSignin,
    MobileWidget,
    SlaveWidget,
    CookiesModal,
    Recaptcha
  },
  props: {
    star: Boolean,
    promoCode: {type: String, default: null}
  },
  data() {
    return {
      locales: ['en', 'es', 'de', 'fr', 'it', 'pt'],
      countries: [],
      country: {},
      connectingTwitter: false,
      influencersCount: 0,
      followersCount: {type: Number, default: 45000},
      // new datas
      videos: 3.3,
      photos: 5.5,
      showSignup: true,
      project,
      sitekey: '6LeYaXopAAAAALkUQsj7-khKtEzDsNObESnAmJ-2',
      widgetId: 0
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    landingWidget() {
      return project.landing_widget || 'MobileWidget'
    },
    googleSigninEnabled() {
      return this.project.google.oauth.enabled
    }
  },
  methods: {
    langs() {
      var elements = this.locales
      for (var el of elements) {
        this.countries.push({
          image: el === 'en' ? '/images/usa-flag-icon-20.jpg' : 'https://flagcdn.com/28x21/' + el.toLowerCase() + '.png',
          el: el,
          value: this.$t(`langs.names.${el}`)
        })
      }
      this.country = this.countries.find(element => element.el === this.getLocale()) || this.countries[0]
    },
    formatNumber(value) {
      var newValue = value
      if (value >= 1000) {
        var suffixes = ['', 'K', 'M', 'B', 'T']
        var suffixNum = Math.floor(('' + value).length / 3)
        var shortValue = ''
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision))
          var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
          if (dotLessShortValue.length <= 2) {
            break
          }
        }
        if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1)
        newValue = shortValue < 1 ? Math.floor(value / 1000) + suffixes[suffixNum - 1] : shortValue + suffixes[suffixNum]
      }
      return newValue
    },
    setLocale(item) {
      this.$i18n.loadLocale(item.el)
      this.$timeago.locale = item.el
      this.$vuetify.lang.current = item.el
      this.country = item
      cookies.set('locale', item.el)
    },
    getLocale() {
      return cookies.get('locale')
    },
    async loadTotals() {
      const {data, error} = await UserApi.count()
      if (!error) {
        this.influencersCount = data.find(d => d.role === 'influencer').total
        this.followersCount = data.find(d => d.role === 'follower').total
      }
    },
    redirect() {
      const origin = sessionStorage.getItem('origin')
      if (origin) {
        this.$router.replace(origin)
        sessionStorage.removeItem('origin')
      } else {
        this.$router.replace('/home')
      }
    }
  },
  async mounted() {

    if (this.home === 'login') {
      this.showSignup = false
    } else {
      this.showSignup = true
      this.signUpForm = this.home
    }
    this.loadTotals()
    this.langs()

    const {data, error} = await PostApi.count()
    if (!error) {
      this.photos = data.image
      this.videos = data.video
    }
  }
}

</script>

<style lang="scss" scoped>
.revolution-link {
  color: black !important;
}

.theme--dark {
  .revolution-link {
    color: white !important;
  }
}

.container {
  .header {
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 6px
  }

  p.counter {
    font-family: 'BenchNine-Light', sans-serif;
    font-size: 20.8px;
    line-height: 1.2em;
    padding: 4px 10px 2px 10px;
    border-radius: 20px;

    .dest-text {
      color: var(--v-primary-base);
    }
  }

  .flag {
    cursor: pointer;
    font-size: x-large;
  }
}

.tit-slogan {
  font-size: 2em;
  line-height: 1em;
  color: #e400a0;
}

.txtmin {
  font: normal 0.875em 'Open Sans', Arial, Helvetica, sans-serif;
  line-height: 140%;
  text-decoration: none;
  margin: 5px 0;
  margin-bottom: 46px;
}

.sec-form {
  max-width: 490px;
  z-index: 100;
}

.login-button {
  font-family: 'Quicksand', sans-serif !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 1.42857143;
  color: white !important;
}

.discover {
  color: #31ae00;
  border-bottom: 2px solid #31ae00;
  font-family: 'BenchNine', sans-serif;
  font-size: 20px;
  line-height: 1.2em;
  padding: 2px 0 0 0;
}

.loginWith {
  margin-left: 10px;
  font-family: Quicksand, sans-serif;
  text-transform: capitalize;
  font-size: 16px;
}

.logotipo-home {
  max-width: -webkit-fill-available;
  margin-left: auto;
}

/////////////////
// Mobile slider
.image-mobile-wrapper {
  display: none;
}

@media (min-width: 768px) {
  .image-mobile-wrapper {
    max-width: 100%;
    max-height: 100%;
    display: initial;
    padding-right: 80px;
  }
}
</style>
