<template>
  <v-form
    ref="accountForm"
    v-model="validForm"
    :dark="false"
    @submit.prevent="submit"
  >
    <v-col :cols="isMobile ? 12 : 9" style="margin: auto">
      <v-row justify="center" dense>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="{ paddingRight: !isMobile ? '10px' : 'null' }"
        >
          <div class="campo-label">{{ $t("models.user.name") }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.name')"
            v-model="name"
            :rules="[$vRequired]"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingLeft"
        >
          <div class="campo-label">{{ $t("models.user.surname") }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.surname')"
            v-model="surname"
            :rules="[$vRequired]"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 4"
          :style="{ paddingRight: !isMobile ? '10px' : 'null' }"
        >
          <div class="campo-label">{{ $t("models.user.day") }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.day')"
            v-model="day"
            type="number"
            :rules="[$vMin(1), $vMax(31), $vRequired]"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 4"
          :style="{ paddingRight: !isMobile ? '10px' : 'null' }"
        >
          <div class="campo-label">{{ $t("models.user.month") }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.month')"
            v-model="month"
            type="number"
            :rules="[$vMin(1), $vMax(12), $vRequired]"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 4"
          :style="paddingLeft"
        >
          <div class="campo-label">{{ $t("models.user.year") }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.year')"
            v-model="year"
            type="number"
            :rules="[$vMin(1850), $vMax(getCurrentYear()), $vRequired]"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="{ paddingRight: !isMobile ? '10px' : 'null' }"
        >
          <div class="campo-label">{{ $t("models.user.country") }}</div>
          <v-autocomplete
            outlined
            persistent-hint
            dense
            :placeholder="$t('models.user.country')"
            :items="countries"
            v-model="country"
            item-text="text"
            item-value="value"
            :dark="isDark"
            :rules="[$vRequired]"
          ></v-autocomplete>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingLeft"
        >
          <div class="campo-label">{{ $t("models.user.city") }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.city')"
            v-model="city"
            :rules="[$vRequired]"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="{ paddingRight: !isMobile ? '10px' : 'null' }"
        >
          <div class="campo-label">{{ $t("models.user.province") }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.province')"
            v-model="province"
            :rules="[$vRequired]"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingLeft"
        >
          <div class="campo-label">{{ $t("models.user.postal_code") }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.postal_code')"
            v-model="postalCode"
            :rules="[$vRequired]"
          ></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 11" sm="12">
          <div class="campo-label">{{ $t("models.user.address") }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.address')"
            v-model="address"
            :rules="[$vRequired]"
          ></v-text-field>
        </v-col>

        <v-col :cols="isMobile ? 12 : 11" sm="12">
          <div class="campo-label">
            {{ $t("models.user.payment_person.title") }}
          </div>
          <v-checkbox
            :dark="isDark"
            :label="$t('models.user.payment_person.desc')"
            v-model="samePersonalData"
          />
        </v-col>

        <v-col
          cols="12"
          v-if="!samePersonalData"
          :style="{
            backgroundColor: isDark
              ? '#333333 !important'
              : '#F2F2F2 !important'
          }"
        >
          <v-col cols="12" justify="center">
            <v-row justify="center" dense style="color: #FFFFFF !important">
              <p
                class="campo-label"
                v-html="$t('models.user.payment_person.info')"
                :style="{
                  color: isDark ? '#FFFFFF !important' : '#000000 !important'
                }"
              ></p>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 6"
                :style="paddingRight"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.user.name") }}
                </div>
                <v-text-field
                  outlined
                  persistent-hint
                  dense
                  :dark="isDark"
                  :placeholder="$t('models.user.name')"
                  v-model="managerName"
                  :rules="[$vRequired]"
                ></v-text-field>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 6"
                :style="paddingLeft"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.user.surname") }}
                </div>
                <v-text-field
                  outlined
                  persistent-hint
                  dense
                  :dark="isDark"
                  :placeholder="$t('models.user.surname')"
                  v-model="managerSurname"
                ></v-text-field>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 6"
                :style="paddingRight"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.influencer_request.id_document_type") }}
                </div>
                <v-select
                  outlined
                  dense
                  :placeholder="
                    $t('models.influencer_request.id_document_type')
                  "
                  v-model="managerDocumentType"
                  :dark="isDark"
                  :items="documentTypes"
                  :rules="[$vRequired]"
                >
                </v-select>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 6"
                :style="paddingLeft"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.influencer_request.id_document_number") }}
                </div>
                <v-text-field
                  outlined
                  dense
                  persistent-hint
                  :dark="isDark"
                  :placeholder="
                    $t('models.influencer_request.id_document_number')
                  "
                  v-model="managerDocumentId"
                  :rules="[$vRequired /*, $vValidNIF(idDocumentNumber)*/]"
                />
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 4"
                :style="paddingRight"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.user.day") }}
                </div>
                <v-text-field
                  outlined
                  persistent-hint
                  dense
                  :dark="isDark"
                  :placeholder="$t('models.user.day')"
                  type="number"
                  v-model="managerDay"
                  :rules="[$vMin(1), $vMax(31)]"
                ></v-text-field>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 4"
                :style="paddingRight"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.user.month") }}
                </div>
                <v-text-field
                  outlined
                  persistent-hint
                  dense
                  :dark="isDark"
                  :placeholder="$t('models.user.month')"
                  type="number"
                  v-model="managerMonth"
                  :rules="[$vMin(1), $vMax(12)]"
                ></v-text-field>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 4"
                :style="paddingLeft"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.user.year") }}
                </div>
                <v-text-field
                  outlined
                  persistent-hint
                  dense
                  :dark="isDark"
                  :placeholder="$t('models.user.year')"
                  type="number"
                  v-model="managerYear"
                  :rules="[$vMin(1800), $vMax(getCurrentYear())]"
                ></v-text-field>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 6"
                :style="paddingRight"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.user.country") }}
                </div>
                <v-autocomplete
                  outlined
                  persistent-hint
                  dense
                  :dark="isDark"
                  :placeholder="$t('models.user.country')"
                  :items="countries"
                  v-model="managerCountry"
                  item-text="text"
                  item-value="value"
                  :rules="[$vRequired]"
                ></v-autocomplete>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 6"
                :style="paddingLeft"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.user.city") }}
                </div>
                <v-text-field
                  outlined
                  persistent-hint
                  dense
                  :dark="isDark"
                  :placeholder="$t('models.user.city')"
                  v-model="managerCity"
                ></v-text-field>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 6"
                :style="paddingRight"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.user.province") }}
                </div>
                <v-text-field
                  outlined
                  persistent-hint
                  dense
                  :dark="isDark"
                  :placeholder="$t('models.user.province')"
                  v-model="managerProvince"
                ></v-text-field>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 11"
                :sm="isMobile ? 12 : 6"
                :style="paddingLeft"
              >
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.user.postal_code") }}
                </div>
                <v-text-field
                  outlined
                  persistent-hint
                  dense
                  :dark="isDark"
                  :placeholder="$t('models.user.postal_code')"
                  v-model="managerPostalCode"
                ></v-text-field>
              </v-col>
              <v-col :cols="isMobile ? 12 : 11" sm="12">
                <div
                  class="campo-label"
                  :style="{
                    color: isDark ? '#FFFFFF !important' : '#000000 !important'
                  }"
                >
                  {{ $t("models.user.address") }}
                </div>
                <v-text-field
                  outlined
                  persistent-hint
                  dense
                  :dark="isDark"
                  :placeholder="$t('models.user.address')"
                  v-model="managerAddress"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </v-col>

    <!-- <v-col :cols="isMobile ? 12 : 9" style="margin: auto">
      <v-row>
        <v-col :cols="6" style="text-align: left">
          <v-btn height="52"
            :loading="submitting"
            :block="isMobile"
            color="#D5D5D5"
            @click="$emit('back')"
            style="color: white; padding: 17px 29px;">{{ $t('actions.back') }}</v-btn>
        </v-col>
        <v-spacer v-if="!isMobile"></v-spacer>
        <v-col cols="auto" style="text-align: right">
          <v-btn height="52"
            :loading="submitting"
            :block="isMobile"
            :dark="isDark"
            color="#41B849"
            :disabled="!validForm"
            @click="submit">
            <span style="color: white; padding: 17px 29px;">{{ $t('actions.step', {count: 3}) }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col> -->
  </v-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PersonalData",
  props: {
    isDark: Boolean
  },
  data() {
    return {
      submitting: false,
      documentTypes: [
        {
          text: this.$t("models.influencer_request.id_document_types.passport"),
          value: "passport"
        },
        {
          text: this.$t(
            "models.influencer_request.id_document_types.id_document"
          ),
          value: "id_document"
        },
        {
          text: this.$t("models.influencer_request.id_document_types.cif"),
          value: "cif"
        }
      ],
      validForm: false,
      name: null,
      surname: null,
      day: null,
      month: null,
      year: null,
      country: null,
      postalCode: null,
      province: null,
      city: null,
      address: null,
      samePersonalData: true,
      managerName: null,
      managerSurname: null,
      managerDay: null,
      managerMonth: null,
      managerYear: null,
      managerCountry: null,
      managerPostalCode: null,
      managerProvince: null,
      managerCity: null,
      managerAddress: null,
      managerDocumentType: null,
      managerDocumentId: null
    };
  },
  computed: {
    ...mapState("application", ["isMobile"]),
    ...mapState("profile", ["currentUser"]),
    ...mapState("withdraws", ["withdrawData"]),
    countries() {
      return this.$t("countries_v2");
    },
    paddingRight() {
      if (this.isMobile) return;
      return {
        paddingRight: "10px"
      };
    },
    paddingLeft() {
      if (this.isMobile) return;
      return {
        paddingLeft: "10px"
      };
    },
    newPersonalData() {
      return {
        name: this.name,
        surname: this.surname,
        fullName: this.name + " " + this.surname,
        birthdate: `${this.year}-${this.month}-${this.day}`,
        country: this.country,
        postalCode: this.postalCode,
        province: this.province,
        city: this.city,
        address: this.address
      };
    },
    newWithdrawData() {
      if (this.samePersonalData) return this.newPersonalData;
      return {
        fullName: this.managerName + " " + this.managerSurname,
        birthdate: `${this.managerYear}-${this.managerMonth}-${this.managerDay}`,
        country: this.managerCountry,
        postalCode: this.managerPostalCode,
        province: this.managerProvince,
        city: this.managerCity,
        address: this.managerAddress,
        documentType: this.managerDocumentType,
        documentNumber: this.managerDocumentId
      };
    }
  },
  watch: {
    validForm() {
      this.$emit("validForm", this.validForm);
    },
    newPersonalData() {
      this.$emit("personalData", this.newPersonalData);
    },
    newWithdrawData() {
      this.$emit("withdrawData", this.newWithdrawData);
    }
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
    submit() {
      this.submitting = true;
      const payload = {
        form: this.newPersonalData,
        withdrawData: this.newWithdrawData
      };
      this.$emit("next", payload);
    }
  },
  mounted() {
    const date = new Date(this.currentUser.birthdate);
    this.name = this.currentUser.name;
    this.surname = this.currentUser.surname;
    this.day = date.getDate();
    this.month = date.getMonth() + 1;
    this.year = date.getFullYear();
    this.country = this.currentUser.country;
    this.postalCode = this.currentUser.postalCode;
    this.province = this.currentUser.province;
    this.city = this.currentUser.city;
    this.address = this.currentUser.address;
    this.documentType = this.currentUser.promotion.idDocumentType;
    this.documentId = this.currentUser.promotion.idDocumentNumber;
    if (this.withdrawData) {
      const dateManager = new Date(this.withdrawData.birthdate);
      const splitted = (this.withdrawData.fullName || "").split(" ");
      this.managerName = splitted[0];
      this.managerSurname = splitted.slice(0, 1).join(" ");
      this.managerDay = dateManager.getDate();
      this.managerMonth = dateManager.getMonth() + 1;
      this.managerYear = dateManager.getFullYear();
      this.managerCountry = this.withdrawData.country;
      this.managerPostalCode = this.withdrawData.postalCode;
      this.managerProvince = this.withdrawData.province;
      this.managerCity = this.withdrawData.city;
      this.managerAddress = this.withdrawData.address;
      this.managerDocumentType = this.withdrawData.documentType;
      this.managerDocumentNId = this.withdrawData.documentNumber;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>

<style lang="scss" scoped>
.campo-label {
  text-align: left;
  font-size: 16px;
  font-family: Quicksand, sans-serif;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  font-family: Quicksand, sans-serif;
}
</style>
