<template>
  <section>
    <span class="border-bottom d-flex center" v-if="showSubMenu">
      <button
        class="chat-buttons py-3"
        type="button"
        :class="{ dark: darkTheme }"
        @click="selectAll()"
      >
        {{ $t('words.mark_all').toUpperCase() }}
      </button>
      <button
        v-if="deleteChats"
        class="chat-buttons py-3"
        type="button"
        :class="{ dark: darkTheme }"
        @click="showWarningDialog()"
      >
        {{ $t('words.delete').toUpperCase() }}
      </button>
      <button
        v-else
        class="chat-buttons py-3"
        type="button"
        :class="{ dark: darkTheme }"
        @click="showWarningDialog(true)"
      >
        {{ $t('words.read').toUpperCase() }}
      </button>
    </span>
    <ListView
      no-gutters
      :loading="loading"
      :items="oderedItems"
      cols="12"
      item-class="border-bottom"
      align="start"
      justify="start"
      @bottom-reached="loadItems"
      style=" height: 80px "
    >
      <template #default="{ item }">
        <ChatRow
          :show-sub-menu="showSubMenu"
          :deleteChat="deleteChats"
          :chat="item"
          :selectedAll="selectedAll"
          @click.native="chatSelected(item)"
          @removeChatId="selectedActionChat(item.id)"
          @readChatId="selectedActionChat(item.id)"
        >
        </ChatRow>
      </template>
    </ListView>
    <WarningDialog
      :show="showWarning"
      :textWarning="text"
      :textButtonOK="textButtonOk"
      @handlerButtonCancel="close"
      :loading="loadingButton"
      @handlerButtonOK="removeAction"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ChatActions, AppActions } from '@/store'
import CursorService from '@/components/mixins/cursor_api'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import WarningDialog from '@/components/chat/component/WarningDialog'
import ChatRow from './ChatRow'

export default {
  name: 'ChatList',
  mixins: [CursorService(ConversationsApi)],
  components: { ChatRow, WarningDialog },
  props: {
    username: {
      type: String,
      default: ''
    },
    onlyFans: {
      type: Boolean,
      default: false
    },
    withTips: {
      type: Boolean,
      default: false
    },
    unRead: {
      type: Boolean,
      default: false
    },
    pendingApproval: Boolean,
    deleteChats: Boolean
  },
  data () {
    return {
      conversationsIds: [],
      selectedAll: false,
      changeSelected: false,
      showWarning: false,
      text: '',
      textButtonOk: '',
      loadingButton: false,
      removeAction: '',
       perPage:50,
    }
  },
  computed: {
    ...mapState('chats', ['selectedChat', 'refreshChat']),
    ...mapState('application', ['isMobile']),
    showSubMenu () {
      return this.deleteChats || this.unRead
    },
    darkTheme(){
      return this.$vuetify.theme.dark
    },
    query () {
      const filter = {
        search: this.username,
        started: true
      }
      if (this.unRead) {
        filter.isRead = !this.unRead
      }
      if (this.onlyFans) {
        filter.onlyFans = this.onlyFans
      }
      if (this.withTips) {
        filter.withTips = this.withTips
      }
      if (this.pendingApproval) {
        filter.approved = false
      }
      return filter
    },
    oderedItems () {
      const copy = [...this.items].filter(
        (value, index, self) => self.findIndex(v => v.id === value.id) === index
      )

      return copy.sort((a, b) => {
        if (!a.lastMessageAt && !b.lastMessageAt) {
          return 0
        } else if (!a.lastMessageAt) {
          return -1
        } else if (!b.lastMessageAt) {
          return 1
        } else {
          return new Date(b.lastMessageAt) - new Date(a.lastMessageAt)
        }
      })
    }
  },
  watch: {
    oderedItems () {
      if (this.selectedAll) {
        this.conversationsIds = []
        this.oderedItems.forEach(chat => this.conversationsIds.push(chat.id))
      }
    },
    showSubMenu (value) {
      if (!value) {
        this.reset()
      }
    },
    selectedChat (chat) {
      if (!chat) {
        return
      }
      const index = this.items.findIndex(c => c.id === chat.id)
      if (index < 0) {
        this.items.splice(0, 0, chat)
      } else {
        this.items.splice(index, 1, chat)
      }
    },
    refreshChat () {
      if (this.refreshChat.action === 'updated' && this.refreshChat.value) {
        const value = {
          conversationId: this.refreshChat.idChat
        }
        this.onChatUpdated(value)
        this.$store.dispatch(ChatActions.RefreshConversation, {
          action: 'updated',
          value: false,
          idChat: 0
        })
      }
      if (this.refreshChat.action === 'delete' && this.refreshChat.value) {
        this.deleteChat(this.refreshChat)
        this.$store.dispatch(ChatActions.RefreshConversation, {
          action: 'delete',
          value: false,
          idChat: 0
        })
      }
    }
  },
  methods: {
    close () {
      this.showWarning = !this.showWarning
      this.reset()
    },
    reset () {
      this.conversationsIds = []
      this.selectedAll = false
    },
    selectAll () {
      this.selectedAll = !this.selectedAll
      if (this.selectedAll) {
        this.conversationsIds = []
        this.oderedItems.forEach(chat => this.conversationsIds.push(chat.id))
      } else {
        this.conversationsIds = []
        for (var chat of this.oderedItems) {
          document.getElementById(`chat-${chat.id}`).checked = false
        }
      }
    },
    selectedActionChat (id) {
      this.selectedAll = false
      if (this.conversationsIds.indexOf(id) !== -1) {
        this.conversationsIds.splice(this.conversationsIds.indexOf(id), 1)
      } else {
        this.conversationsIds.push(id)
      }
    },
    showWarningDialog () {
      if (this.conversationsIds.length > 0) {
        this.text = this.deleteChats
          ? this.$t('chats.remove.list', {
            number: this.conversationsIds.length
          })
          : this.$t('chats.read.list', { number: this.conversationsIds.length })
        this.textButtonOk = this.deleteChats
          ? this.$t('actions.delete').toUpperCase()
          : this.$t('actions.ok').toUpperCase()
        this.removeAction = this.deleteChats ? this.removeChat : this.readChat
        this.showWarning = true
      }
    },
    async readChat () {
      this.loadingButton = true
      const form = {
        conversationIds: [...this.conversationsIds]
      }
      const { data, error } = await ConversationsApi.readChatsBulk(form)
      if (!error) {
        this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
        this.refreshList()
      } else {
        this.$store.dispatch(AppActions.SetNotification, {
          type: 'error',
          error: data
        })
      }
      this.$store.dispatch(AppActions.ShowNotification, true)
      this.loadingButton = false
      this.close()
      this.reset()
      if (this.items.length < 1) {
        this.$emit('default-filter')
      }
    },
    async removeChat () {
      this.loadingButton = true
      const form = {
        conversationIds: [...this.conversationsIds]
      }
      const { data, error } = await ConversationsApi.deleteConversationsBulk(
        form
      )
      if (!error) {
        this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
        this.refreshList()
      } else {
        this.$store.dispatch(AppActions.SetNotification, {
          type: 'error',
          error: data
        })
      }
      this.$store.dispatch(AppActions.ShowNotification, true)
      this.loadingButton = false
      this.close()
      this.reset()
      this.$emit('back')
    },
    refreshList () {
      for (const chatId of this.conversationsIds) {
        this.deleteChat({ idChat: chatId })
      }
    },
    async onChatUpdated (value) {
      const queryPeticion = {
        conversationId: parseInt(value.conversationId)
      }
      const { data, error } = await ConversationsApi.findConversations(
        queryPeticion
      )
      if (!error) {
        this.refreshChatLoad(data.data[0])
      }
    },
    deleteChat (chat) {
      if (this.selectedChat && this.selectedChat.id === chat.idChat) {
        this.$store.dispatch(ChatActions.SelectChat, null)
        this.$store.dispatch(ChatActions.ShowSetting, false)
        this.$store.dispatch(ChatActions.ShowCarousel, false)
      }
      const index = this.items.findIndex(c => c.id === chat.idChat)
      if (index !== -1) {
        this.items.splice(index, 1)
      }
    },
    refreshChatLoad (chat) {
      // if (this.selectedChat && this.selectedChat.id !== parseInt(chat.id)) {
      const index = this.items.findIndex(c => c.id === chat.id)
      if (index !== -1) {
        this.items.splice(index, 1, chat)
      } else {
        this.items.push(chat)
      }
      // }
    },
    chatSelected(chat) {
      this.$store.dispatch(ChatActions.SelectChat, null);
      this.$store.dispatch(ChatActions.SelectChat, chat);
      this.$store.dispatch(ChatActions.MarkRead);

      if (!this.isMobile || !this.showSubMenu) {
        this.$store.dispatch(ChatActions.ShowSetting, false);
        this.$store.dispatch(ChatActions.ShowVideoChat, false);
        this.$store.dispatch(ChatActions.ShowCarousel, false);
      }
    },
    async createdChat (c) {
      const query = {
        nextCursorToken: '',
        conversationId: c.id
      }
      const { data, error } = await ConversationsApi.findConversations(query)
      if (!error) {
        this.items.unshift(data.data[0])
      }
    },
    visibilityListener () {
      if (document.visibilityState === 'visible' && this.$router.currentRoute.name === 'messages') {
        this.nextCursor=''
        this.items=[]
        this.$store.dispatch(AppActions.UpdateHasMessages, true)
        this.loadItems()
      }
    },
  },

  mounted () {
    this.wsCreatedChat = this.createdChat.bind(this)
    this.$ws.subscribe('conversation-started', this.wsCreatedChat)

    this.wsChatUpdated = this.onChatUpdated.bind(this)
    this.$ws.subscribe('conversation-message', this.wsChatUpdated)

    this.docVisibilityListener= this.visibilityListener.bind(this)
    document.addEventListener('visibilitychange', this.docVisibilityListener)
  },

  destroyed () {
    this.$ws.unsubscribe('conversation-started', this.wsCreatedChat)
    this.$ws.unsubscribe('conversation-message', this.wsChatUpdated)
    document.removeEventListener('visibilitychange', this.docVisibilityListener)
  }
}
</script>

<style lang="scss" scoped>
.chat-row-t {
  height: 80px !important;
}
</style>
