<template>
  <section>
    <h1>CONDICIONES DE CONTRATACIÓN PARA USUARIOS</h1>
    <br>
    <h2>1. IDENTIFICACIÓN</h2>

    <ul>
      <li>Titular: ARTEMISA3000 TECH SOLUTIONS, S.L. (en adelante, "ARTEMISA")</li>
      <li>Domicilio social: Calle Goya nº47, Planta 7, Pta. 12, 28001 Madrid (España)</li>
      <li>N.I.F.: B-88466743</li>
      <li>Registro público: [[29 de Agosto de 2019, número 1741 de protocol, inscrita en el Registro Mercantil de
        Madrid, tomo 39620, folio 10, hoja número M-703152, inscripción primera]
      </li>
      <li>Teléfono: +34656897133</li>
      <li>E-mail: <a :href="`mailto:${project.locales.contact_email}`" target="_blank">{{
          project.locales.contact_email
        }}</a></li>
    </ul>
    <br>

    <h2>2. OBJETO</h2>
    <p>
      Mediante el presente documento se establecen las condiciones (en adelante, las <b>“Condiciones”</b>) que rigen la
      contratación de los contenidos digitales y/o productos físicos (en adelante, los <b>“Contenidos”</b> y los <b>“Productos”</b>)
      ofrecidos por los creadores de contenido registrados a tal fin en la plataforma ubicada en la URL <a
      :href="`${project.locales.site}`" target="_blank">{{ project.locales.site }}</a> (en adelante, la
      <b>“Plataforma”</b>).
    </p>
    <br>
    <p>
      La marcación de la casilla correspondiente en el procedimiento de contratación, así como el hecho de seguir
      telemáticamente todos los pasos del mismo, supone la aceptación expresa de las presentes Condiciones por parte del
      Usuario, teniendo la misma validez que su firma presencial, manuscrita o digital. De este modo, el Usuario
      reconoce ser una persona con capacidad suficiente para adquirir las obligaciones derivadas de sus actuaciones a
      través de la Plataforma, que ha leído previamente y comprende su contenido.
    </p>
    <br>
    <p>
      Cualquier cuestión no prevista expresamente en las presentes Condiciones se entenderá reservada a
      {{ project.locales.project_upper }}, sin
      perjuicio de la aplicación de lo dispuesto en la normativa vigente.
    </p>
    <br>
    <p>
      Asimismo, {{ project.locales.project_upper }} se reserva el derecho a llevar a cabo modificaciones y/o
      actualizaciones en las presentes
      Condiciones, de las que se informará previamente al Usuario para su aceptación o rechazo en caso de resultar
      sustanciales. En cualquier caso, se considerará que el Usuario acepta expresamente dichas modificaciones o
      actualizaciones si continúa utilizando la Plataforma y contratando Contenidos y/o Productos a través de la misma
      tras la entrada en vigor de dichas modificaciones.
    </p>
    <br>
    <p>
      En caso de ser aceptadas por el Usuario, las nuevas Condiciones sustituirán plenamente a las Condiciones vigentes
      hasta ese momento, comenzando a surtir efecto a partir de su fecha de entrada en vigor.
    </p>
    <br>
    <p>
      El proceso de contratación se encuentra disponible en los idiomas castellano e inglés, a elección del Usuario en
      los ajustes de la Plataforma
    </p>
    <br>
    <br>

    <h2>3. MODALIDADES DE CONTRATACIÓN DE CONTENIDOS Y PRODUCTOS</h2>
    <p>
      En la Plataforma, el Usuario puede acceder a distintos contenidos audiovisuales (en adelante, los
      <b>“Contenidos”</b>) y productos físicos o digitales (en adelante, los <b>“Productos”</b>) que publican en la
      Plataforma los creadores de contenido que estén dados de alta en la misma (en adelante, los <b>“Creadores de
      Contenido”</b>).
    </p>
    <br>
    <p>
      Los Contenidos de cada Creador de Contenido podrán estar disponibles para el Usuario en distintas modalidades,
      tanto de forma gratuita como de pago:
    </p>
    <br>
    <ul>
      <li><b>Contenidos gratuitos:</b> son publicados en “abierto” por los Creadores de Contenido para la generalidad de
        Usuarios de la Plataforma sin necesidad de contratación. Cualquier Usuario puede visualizarlos, pero solo podrá
        interactuar con ellos (dejando comentarios o poniéndose en contacto con el Creador de Contenido) si está
        registrado en la Plataforma.
      </li>
      <li><b>Contenidos de pago:</b> se encuentran disponibles para los Usuarios que expresamente los contraten. Su
        compra se puede realizar en dos modalidades:
        <ul>
          <li>i: <u>Suscripción</u>: los Usuarios de la Plataforma pueden adquirir suscripciones a sus Creadores de
            Contenido preferidos, lo que implica que podrán acceder a todos los Contenidos que el propio Creador de
            Contenido haya reservado para esta modalidad, sin perjuicio de, además, se puedan ofrecer Contenidos
            “premium”, esto es, de acceso exclusivo al margen de los Contenidos por Suscripción. Las Suscripciones
            tienen por defecto carácter mensual, si bien el Creador de Contenido podrá fijar distintas periodicidades
            (trimestrales, semestrales y anuales). Las Suscripciones se renovarán automáticamente a su vencimiento, de
            fecha a fecha, salvo que el Usuario contratante cancele su Suscripción conforme a lo indicado en la Cláusula
            4.2.
          </li>
          <li>ii: <u>Pago por Contenido</u>: los Usuarios de la Plataforma pueden comprar el acceso a determinados
            Contenidos de pago en modalidad “pay-per-view”, esto es, pago por acceso o visualización. Dichos Contenidos
            se añadirán a la “mediateca” del Usuario, donde podrá acceder a él desde el momento de su contratación. La
            contratación de cada Contenido bajo esta modalidad dará al Usuario acceso al Contenido en cuestión, sin
            limitación, hasta que se dé de baja en la Plataforma
          </li>
        </ul>
      </li>
    </ul>
    <br>
    <p>
      Los Productos son ofertados por los propios Creadores de Contenido en una sección específica dentro de sus
      respectivos perfiles, siendo a discreción de cada Creador de Contenido la publicación o no de Productos para su
      venta en cada momento. Estos Productos podrán ser:
    </p>
    <br>
    <ul>
      <li><b>Productos físicos</b>: bienes físicos de segunda mano que cada Creador de Contenido pueda poner a la venta
        en su perfil. El precio de estos Productos será fijado por el Creador de Contenido y su contratación se llevará
        a cabo a través del procedimiento de contratación de la Plataforma, realizándose el pago también a través del
        sistema ofrecido por la propia Plataforma. En el caso de dudas, el Usuario podrá contactar con el Creador de
        Contenido vendedor a través del sistema de mensajería interna de la Plataforma.
      </li>
      <li><b>Productos digitales</b>: contenidos digitales específicos que el Creador de Contenido ofrezca de forma
        exclusiva, al margen de los que publique en la Plataforma para la generalidad de los Usuarios. Estos Productos
        digitales podrán ser creados de forma personalizada para el Usuario que los contrate y sus condiciones se podrán
        acordar directamente entre el Creador de Contenido y el Usuario a través del canal de mensajería privada de la
        Plataforma. {{ project.locales.project_upper }} no asumirá papel alguno en las negociaciones entre ambas partes,
        sin perjuicio de que la
        contratación se formalizará a través de la Plataforma por medio del procedimiento de contratación habilitado en
        la misma.
      </li>
    </ul>
    <br>
    <p>Además, el Usuario podrá interactuar con cada Creador de Contenido a través del canal de mensajería de la
      Plataforma, si el Creador de Contenido lo hubiera habilitado. El uso de esta vía de contacto entre el Usuario y un
      Creador de Contenido podrá estar sujeto al pago de un precio por cada mensaje enviado, cuyo importe fijará cada
      Creador de Contenido a su discreción. Ese precio deberá ser abonado por el Usuario al momento de enviar cada
      mensaje, utilizando los fondos que hubiera precargado en su cuenta de usuario.</p>
    <br>
    <br>

    <h2>4. CONDICIONES ECONÓMICAS: PAGO Y FACTURACIÓN</h2>
    <h3>4.1. Precios</h3>
    <p>
      El precio de cada uno de los Contenidos y Productos comercializados en la Plataforma es fijado por el propio
      Creador de Contenido y se encontrará indicado en el perfil del propio creador. El precio mostrado no incluye el
      Impuesto sobre el Valor Añadido (IVA), que será expresamente añadido en el procedimiento de contratación.
    </p>
    <br>
    <p>
      En el caso de los Contenidos, el precio final de cada contratación incluirá todos aquellos incrementos o
      descuentos que sean de aplicación, gastos repercutidos al Usuario y gastos adicionales que correspondan por costes
      de tecnología, servicios accesorios, uso de medios de pago, etc., de haberlos. Todos estos importes serán
      mostrados al Usuario de forma desglosada durante el procedimiento de contratación, indicándose asimismo el precio
      total.
    </p>
    <br>
    <p>
      Respecto de los Productos, será el propio Creador de Contenido quien acuerde los términos concretos de adquisición
      de cada uno de ellos directamente con el Usuario, pudiendo negociarse condiciones distintas a las establecidas
      para los Contenidos y/o las publicadas con carácter general por el Creador de Contenido en su perfil.
    </p>
    <br>
    <br>
    <h3>4.2. Pago y facturación</h3>
    <h4>4.2.1. Contenidos</h4>
    <p>
      Cuando el Usuario contrate un Contenido a través de la Plataforma, abonará el precio correspondiente a través de
      cualquiera de las formas de pago disponibles en la Plataforma en ese momento.
    </p>
    <br>
    <p>
      Una vez confirmado el pago, la Plataforma remitirá al Usuario un correo electrónico en el que se recogerá toda la
      información relativa a la contratación: el Contenido adquirido, su precio unitario, los impuestos aplicables, así
      como el precio total de la contratación y, en el caso de las Suscripciones, su duración y la periodicidad con la
      que se producirán los pagos.
    </p>
    <br>
    <p>
      En el caso de las Suscripciones, se cargará el precio de la Suscripción contratada por el Usuario con la
      periodicidad indicada conforme al plan de suscripción escogido, hasta que el Usuario cancele la misma, lo cual
      podrá realizar en cualquier momento antes de la fecha de renovación de la Suscripción en cuestión. En caso de
      cancelar su Suscripción, podrá continuar accediendo a los Contenidos de la misma hasta el agotamiento del plazo de
      suscripción en curso. Para cancelar una Suscripción, el Usuario deberá acceder a la sección “Mis suscripciones”,
      dentro de su perfil en la Plataforma, donde podrá cancelar individualmente cada Suscripción mediante el botón
      “Suscrito/Cancelar”. También se podrán cancelar Suscripciones activas en la plataforma de procesamiento de pagos
      <a href="www.marketbilling.net" target="_blank">www.marketbilling.net</a>, propiedad de ARTEMISA3000 TECH
      SOLUTIONS, S.L., a través de la cual se procesan los pagos y de quien el Usuario recibe la información de cada
      Suscripción que adquiere.
    </p>
    <br>
    <p>
      En el caso de que, al momento de realizar el cargo, el medio de pago introducido por el Usuario no estuviera
      disponible o el Usuario no tuviese fondos suficientes para el abono, se realizarán sucesivos intentos diarios de
      cargo durante un plazo máximo de diez (10) días naturales a partir de dicha fecha. Si, en dicho plazo, no pudiera
      realizarse el cargo por falta de saldo, se dará de baja al Usuario automáticamente. Desde el primer intento de
      cargo fallido se suspenderá el acceso del Usuario a la Suscripción afectada por la falta de pago, hasta que el
      cargo haya sido realizado con éxito o hasta que se produzca la baja conforme a lo indicado, lo que ocurra antes
    </p>
    <br>
    <p>
      Si, transcurrido dicho plazo, el Usuario no hubiera procedido al pago, {{ project.locales.project_upper }}
      resolverá el contrato
      formalizado, finalizando la Suscripción del Usuario afectada por dicha falta de pago, sin perjuicio de que, en
      caso de que esta situación se repita, {{ project.locales.project_upper }} podrá suspender el acceso del Usuario a
      la Plataforma
    </p>
    <br>
    <p>
      Los pagos se procesarán a través de la plataforma <a href="https://www.marketbilling.net" target="_blank">https://www.marketbilling.net </a>,
      propiedad de ARTEMISA3000 TECH SOLUTIONS, S.L., que se encuentra alojada bajo protocolo seguro SSL y a la que el
      Usuario será redirigido durante el procedimiento de contratación. En cualquier caso, aunque esta plataforma
      emitirá las correspondientes confirmaciones, el procesamiento de las tarjetas de los Usuarios se llevará a cabo
      por parte de la entidad financiera externa correspondiente.
    </p>
    <br>
    <p>
      En cualquier caso, la contratación sólo será efectiva cuando {{ project.locales.project_upper }} reciba
      confirmación del pago por la entidad
      bancaria titular de la pasarela de pago seguro. Si la transacción resultase por algún motivo denegada por dicha
      entidad, o no se aportase la cantidad íntegra correspondiente al importe del pedido (incluidos recargos por gastos
      de gestión y comisiones de transferencia bancaria), éste se suspenderá, informando al Usuario de que la
      transacción no ha sido finalizada
    </p>
    <br>
    <br>
    <h4>4.2.2. Productos</h4>
    <p>
      El pago del precio que cada Creador de Contenido haya fijado para cada Producto se producirá a través de la
      Plataforma, por el medio de pago que seleccione el Usuario de entre los ofrecidos, si bien la gestión de la
      entrega del Producto adquirido será responsabilidad única y exclusiva del Creador de Contenido en cuestión.
      {{ project.locales.project_upper }} únicamente interviene en la contratación entre el Usuario y el Creador de
      Contenido en calidad de gestor
      de la Plataforma donde se canaliza el pago, siendo una contratación completamente ajena a
      {{ project.locales.project_upper }} sobre la cual,
      por tanto, {{ project.locales.project_upper }} no asume responsabilidad alguna.
    </p>
    <br>
    <br>
    <h2>5. PROCEDIMIENTO DE CONTRATACIÓN</h2>
    <p>
      De conformidad con lo dispuesto en el artículo 23 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad
      de la información y de comercio electrónico, los contratos celebrados por vía electrónica producirán todos los
      efectos previstos por el ordenamiento jurídico cuando concurran el consentimiento y los demás requisitos
      necesarios para su validez.
    </p>
    <br>
    <p>
      En todo caso, el soporte electrónico en que conste el contrato celebrado por vía electrónica será admisible como
      prueba documental en caso de controversia entre las partes.
    </p>
    <br>
    <p>
      A estos efectos, se entenderá que el seguimiento de todas las fases del proceso de contratación y el abono del
      Precio correspondiente implican necesariamente la prestación del consentimiento requerido para la contratación.
    </p>
    <br>
    <p>
      Del mismo modo, y atendiendo a lo dispuesto en el artículo 27 de la Ley 34/2002, de servicios de la sociedad de la
      información y de comercio electrónico, se pone a disposición de los Usuarios, de forma previa al inicio del
      procedimiento de contratación, toda la información relativa al mismo, que únicamente será de aplicación en caso de
      que el Usuario decida proceder a la contratación a través de la Plataforma.
    </p>
    <br>
    <br>

    <h3>5.1. Información previa</h3>
    <p>
      El acceso al proceso de contratación es completamente gratuito, sin que exista ningún coste asociado adicional,
      aparte de los propios de que el Usuario disponga de conexión a Internet
    </p>
    <br>
    <p>
      Todos los Contenidos y Productos disponibles en modalidad de pago dentro de la Plataforma se encuentran descritos
      en sus correspondientes secciones, no entendiéndose incluidas aquellas cuestiones que expresamente no hubieran
      sido indicadas en las mismas.
    </p>
    <br>
    <p>
      La contratación de Contenidos y Productos en cualquier modalidad de contratación según se describe en el apartado
      3 (“Modalidades de contratación de contenidos y productos”) de estas Condiciones se deberá realizar mediante la
      aceptación expresa de este documento y, en su caso, de las condiciones particulares que pudieran resultar
      aplicables a algún Contenido o Producto en concreto, en cuyo caso se especificará en la sección correspondiente al
      mismo
    </p>
    <br>
    <p>
      El contrato entre {{ project.locales.project_upper }} y el Usuario se entiende perfeccionado desde el momento en
      que el Usuario finaliza el
      procedimiento de contratación presionando el botón de << PAGUE AHORA>>, entendiéndose que el seguimiento de todas
      las fases del procedimiento de contratación electrónica y la inclusión de todos los datos solicitados suponen,
      junto con la marcación de la casilla correspondiente relativa a la aceptación de las presentes Condiciones, una
      manifestación directa de la voluntad del Usuario de aceptar las mismas.
    </p>
    <br>
    <p>
      {{ project.locales.project_upper }} archivará el documento electrónico en que se formaliza este contrato. Dicho
      documento electrónico será
      accesible para el Usuario mediante correo electrónico a la dirección asociada al perfil del Usuario en cuestión,
      donde se incluirán las presentes Condiciones.
    </p>
    <br>
    <p>
      {{ project.locales.project_upper }} introducirá medios adecuados y suficientes para identificar y corregir errores
      técnicos en la gestión de
      la información en cuanto resulte de su responsabilidad.
    </p>
    <br>
    <p>
      El idioma en que se tramitará el procedimiento de contratación, y en el que se formaliza el contrato, será el
      castellano o el inglés, a elección del Usuario mediante los ajustes de la Plataforma
    </p>
    <br>
    <br>
    <h3>5.2. Procedimiento de contratación</h3>
    <p>
      El procedimiento de contratación de cualquier Contenido o Producto de pago se lleva a cabo de forma electrónica a
      través de la Plataforma, siendo necesario en todo caso que el Usuario se encuentre registrado en la misma.
    </p>
    <br>
    <p>
      El procedimiento completo que deberán seguir todos los Usuarios que deseen adquirir alguno de los Contenidos o
      Productos sujetos a pago ofrecidos por los Creadores de Contenido será el siguiente:
    </p>
    <br>
    <ol>
      <li>
        <p>El Usuario deberá iniciar sesión en su cuenta y seleccionar el Contenido que desea adquirir (ya sea una
          suscripción a un Creador de Contenido o un Contenido concreto), siendo muy importante que revise las
          condiciones y precios que haya establecido el Creador de Contenido en su perfil.</p>
        <br>
        <p>En el caso de los Productos, una vez visualizado y seleccionado el Producto que desea adquirir (ya sea físico
          o digital), en caso de duda podrá ponerse en contacto con el Creador de Contenido que ofrece dicho Producto
          para resolver las mismas. Dicho contacto se producirá por medio del canal de mensajería interna de la
          Plataforma, si bien la eventual contratación y su pago correspondiente se llevarán a cabo por los medios
          técnicos de la Plataforma</p>
      </li>
      <li>Una vez realizado lo anterior, deberá iniciar el proceso de compra electrónica pulsando el botón
        correspondiente.
      </li>
      <li>
        <p>Para proceder al pago, el Usuario deberá introducir los datos relativos a la forma de pago disponible y
          escogida por el Usuario (en caso de ser más de una). En ningún momento {{ project.locales.project_upper }} ni
          el Creador de Contenido
          tendrán acceso a los datos bancarios del Usuario, siendo estos gestionados directamente por las entidades
          bancarias correspondientes en la pasarela de pagos.</p>
        <br>
        <p>Además, en el caso de adquisición de Productos, el Usuario deberá proporcionar los datos que le sean
          solicitados, que incluirán en todo caso nombre completo, email y dirección. Los datos relativos al envío del
          Producto adquirido se facilitarán directamente al Creador de Contenido por medio del canal de mensajería
          privada de la Plataforma.</p>
        <br>
      </li>
      <li>Antes de confirmar la compra, el Usuario deberá leer y aceptar expresamente las presentes Condiciones mediante
        la marcación de las casillas correspondientes dispuestas a tal efecto.
      </li>
      <li>Finalizada la compra, se mostrará una pantalla de confirmación de la compra realizada. El Usuario recibirá
        automáticamente un correo electrónico de confirmación de que la compra se ha realizado con éxito. En dicho
        correo se describirá la contratación realizada, así como sus características (precio, duración de la suscripción
        en su caso) y datos completos de quien procesa la compra, y este documento servirá de acreditación en caso de
        reclamación. En caso de no recibir dicho correo electrónico, deberá revisar la bandeja de “correo no deseado” o
        “spam” y, si no se encontrase en ese apartado, deberá ponerse en contacto con
        {{ project.locales.project_upper }} en el menor tiempo
        posible
      </li>
      <li>El Usuario podrá gestionar sus suscripciones activas en el panel de control de su cuenta de usuario en la
        Plataforma, desde donde, previa identificación, podrá cancelarlas, si así lo desea. La información desglosada de
        sus contrataciones la recibirá por correo electrónico, pero también podrá acceder a dicha información en <a
          href="https://www.marketbilling.net" target="_blank">https://www.marketbilling.net</a>, plataforma a través de
        la cual se procesan los pagos.
      </li>
      <li>
        <p>En el caso de Contenidos, el Contenido adquirido estará disponible para el Usuario desde el momento en que se
          procese el pago correspondiente.</p>
        <br>
        <p>En el caso de compra de Productos, físicos o digitales, cuya entrega requiere una acción por parte del
          Creador de Contenido, {{ project.locales.project_upper }} trasladará la información relacionada con la compra
          realizada al Creador de
          Contenido correspondiente para que este pueda, en su caso, gestionar la entrega del Producto adquirido, ya
          deba esta realizarse por vía telemática (en caso de Productos de naturaleza digital) o postal (en caso de
          Productos que constituyan bienes físicos). A tal fin, el Usuario deberá proporcionar al Creador de Contenido
          los datos de envío (telemáticos o postales) para gestionar el envío o entrega, tal y como se describe en el
          siguiente apartado (“Entrega”).</p>
        <br>
      </li>
    </ol>
    <br>
    <h2>6. ENTREGA</h2>
    <p>Una vez recibida la confirmación del pago correspondiente, se procederá a la entrega de los Contenidos o
      Productos adquiridos por el Usuario de la siguiente manera:</p>
    <br>
    <ul>
      <li><u>Contenidos</u>: los Contenidos que el Usuario adquiera en las modalidades de Suscripción o de Pago se
        habilitarán automáticamente para su visualización por el Usuario en el correspondiente perfil de Creador de
        Contenido en el caso de suscripción, en la sección “Mis compras” en el caso de realizarse una compra individual
        de contenido (PPV) dentro del propio perfil del Usuario o en el historial de archivos intercambiados mediante el
        sistema de mensajería instantánea en el caso de que el Contenido se haya comprado por ese medio. En el caso de
        contratar Contenidos exclusivos para el Usuario, su entrega se realizará por parte del Creador de Contenido por
        el medio que hubieran acordado el Usuario y el Creador de Contenido a la hora de acordar las características de
        dicho Contenido exclusivo vía el canal de mensajería interna.
      </li>
      <li>
        <p><u>Productos</u>: los Productos que un Usuario pudiera adquirir de un Creador de Contenido serán entregados
          por el Creador de Contenido en cuestión en la dirección (postal o de correo electrónico, en función de la
          naturaleza del Producto adquirido) que el Usuario hubiera indicado al efecto en el procedimiento de
          contratación.</p>
        <br>
        <p>En el caso de Productos digitales (p.ej., packs de fotografías o vídeos), se entregarán vía correo
          electrónico o mensaje privado en la Plataforma.</p>
        <br>
        <p>En el caso de Productos físicos, su envío puede estar limitado a determinados territorios. Para determinar si
          un Producto físico está disponible para envío a la dirección del Usuario, el sistema utilizará el país
          asociado al perfil del Usuario (indicado por el propio Usuario en el proceso de registro). En caso de estar
          disponible, la compra de dicho Producto estará habilitada para el Usuario y, si lo selecciona, será redirigido
          al check-out para realizar el pago del precio del Producto, al que se añadirán los correspondientes gastos de
          envío en función del país al que debiera realizarse el mismo. Una vez realizado el pago, el Creador de
          Contenido contactará con el Usuario por medio del sistema de mensajería privada de la Plataforma para acordar
          la dirección a la que deberá realizar el envío del Producto adquirido.</p>
        <br>
        <p>En caso de no estar disponible el envío al país del Usuario, se le invitará a contactar directamente con el
          Creador de Contenido para concretar una posible venta y envío del Producto.</p>
        <br>
      </li>
    </ul>
    <br>

    <h2>7. DERECHO DE DESISTIMIENTO</h2>
    <p>El Usuario reconoce y acepta que, dada la naturaleza de los bienes y servicios ofrecidos en la Plataforma, no
      existirá a su favor derecho de desistimiento conforme a lo dispuesto en el Real Decreto Legislativo 1/2007, de 16
      de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y
      Usuarios y otras leyes complementarias.</p>
    <br>
    <p>Respecto de los Contenidos:</p>
    <br>
    <ol>
      <li><u>En modalidad de Suscripción o de Pago por Contenido</u>: se habilitará el acceso a los mismos
        automáticamente, por lo que la ejecución del contrato comenzará desde el momento en que se realice el pago y, en
        consecuencia, no existirá derecho de desistimiento a favor del Usuario contratante en virtud del artículo 103.m)
        del Real Decreto Legislativo 1/2007;
      </li>
      <li><u>Contenidos personalizados para el Usuario</u>: no existirá derecho de desistimiento conforme al artículo
        103.c) del Real Decreto Legislativo 1/2007
      </li>
    </ol>
    <p>Por otro lado, en cuanto a los Productos que pudieran ofrecer los Creadores de Contenido y que fueran adquiridos
      por el Usuario:</p>
    <br>
    <ul>
      <li><u>Productos de naturaleza digital</u>: salvo que el Creador de Contenido acuerde otra cosa con el Usuario u
        ofrezca otras condiciones, por lo general no existirá derecho de desistimiento respecto de los Productos de
        naturaleza digital, en los mismos términos indicados en los párrafos anteriores para los Contenidos de
        Suscripción, Premium o Personalizados;
      </li>
      <li><u>Productos físicos</u>: dada su naturaleza, no existirá derecho de desistimiento para el Usuario por motivos
        de protección de la salud y de higiene. En cualquier caso, si el Usuario tuviese derecho a desistir del contrato
        conforme al Real Decreto Legislativo 1/2007, dicho derecho deberá ejercitarse ante el propio Creador de
        Contenido
      </li>
    </ul>
    <br>

    <h2>8. DEVOLUCIONES Y REEMBOLSOS</h2>
    <p>Por defecto, el Usuario no tendrá derecho a solicitar devolución o reembolso alguno por las contrataciones
      realizadas en la Plataforma, salvo en el caso de cargos duplicados por parte de la pasarela de pago.
      {{ project.locales.project_upper }}
      evaluará cada caso individualmente.</p>
    <br>
    <p>Para solicitar un reembolso en los casos que corresponda, el Usuario debe mandar un correo electrónico a
      {{ project.locales.contact_email }} facilitando toda la información relativa al cargo indebido y aportando las
      evidencias en su
      cuenta bancaria, como el pago duplicado. {{ project.locales.project_upper }} resolverá estas reclamaciones en
      menos de 7 días desde la
      última comunicación.</p>
    <br>
    <p>En caso de que {{ project.locales.project_upper }} apruebe una devolución, el Usuario la tendrá disponible en la
      misma tarjeta desde la que
      realizó la compra en un plazo de 10 días hábiles desde la confirmación. No se realizarán reembolsos por otros
      medios, como cheques o efectivo.</p>
    <br>
    <br>

    <h2>9. RESPONSABILIDADES DEL USUARIO</h2>
    <p>El Usuario será responsable de:</p>
    <br>
    <ol>
      <li>Cualquier acto que contravenga el contenido de estas Condiciones y/o de cualesquiera otras condiciones
        vigentes en la Plataforma, la ley, la moral, las costumbres o normas sociales generalmente aceptadas y el orden
        público.
      </li>
      <li>Cualquier daño o pérdida que pueda derivarse de un mal uso, negligencia o incumplimiento por parte del Usuario
        en su utilización de la Plataforma y/o de los Contenidos o Productos, incluyendo, a título meramente
        enunciativo, los términos de las Condiciones, la legislación aplicable y/o cualquier instrucción, directriz o
        recomendación realizada por {{ project.locales.project_upper }}.
      </li>
      <li>Exactitud e integridad de la información proporcionada por el Usuario al aceptar y ejecutar las Condiciones.
      </li>
      <li>Cumplimiento de los términos de las Condiciones, incluido el pago de los correspondientes Precios.</li>
    </ol>
    <p>El Usuario se compromete a indemnizar, defender y eximir a {{ project.locales.project_upper }}, incluyendo sus
      funcionarios, directores,
      accionistas, sucesores, afiliados, empleados, agentes y representantes, de y contra cualesquiera costes,
      reclamaciones, demandas, responsabilidades, gastos, pérdidas, daños, incluyendo los honorarios de abogados, que
      surjan o resulten del uso de la Plataforma y de los Contenidos y Productos adquiridos a través de la misma por el
      Usuario. El Usuario también se compromete a indemnizar a {{ project.locales.project_upper }} por los honorarios
      legales en los que incurra
      esta última, actuando razonablemente, en la investigación o la aplicación de sus derechos en virtud de las
      presentes Condiciones.</p>
    <br>
    <br>

    <h2>10. RESPONSABILIDADES LIMITADAS DE {{ project.locales.project_upper }}</h2>
    <p>El Usuario reconoce y acepta que la única responsabilidad de {{ project.locales.project_upper }} respecto de las
      contrataciones que los
      Usuarios realicen a través de la Plataforma será en relación con la gestión de las mismas y de la intermediación
      en el pago sobre los Contenidos y Productos.</p>
    <br>
    <p>En este sentido, además de lo descrito en el Aviso Legal, {{ project.locales.project_upper }} no se
      responsabiliza de:</p>
    <br>
    <ul>
      <li>Los Contenidos que publiquen los Creadores de Contenido en la Plataforma, incluyendo todos los elementos que
        los integren;
      </li>
      <li>Los Productos que pudieran ofrecer los Creadores de Contenido para su venta a través de las secciones
        habilitadas para ello;
      </li>
      <li>La gestión, ejecución y entrega de las contrataciones que realizara el Usuario directamente con un Creador de
        Contenido, por ejemplo, para la adquisición de un Producto, incluyendo, entre otros, la gestión del envío en el
        caso de Productos Físicos;
      </li>
      <li>El contenido de los mensajes que pudiera intercambiar el Usuario con los Creadores de Contenido.</li>
    </ul>
    <p>{{ project.locales.project_upper }} no puede garantizar la fiabilidad, licitud y utilidad de los contenidos
      publicados por terceros a
      través de la Plataforma, incluyendo, pero no limitado a los propios Contenidos y cualesquiera otros contenidos,
      informaciones, textos (p.ej., comentarios), entre otros, y en consecuencia no será responsable de los mismos. Esto
      también se aplica a la información contenida en otras plataformas o sitios web que estén enlazados desde la
      Plataforma. {{ project.locales.project_upper }} no se hace responsable de los daños que pudieran derivarse del uso
      de dichas informaciones.
      Si el Usuario tiene conocimiento de la existencia de cualquier contenido ilegal, ilegal o que infringe los
      derechos de terceros, ha de notificarlo inmediatamente a {{ project.locales.project_upper }} para que se puedan
      tomar las medidas
      oportunas.</p>
    <br>
    <p>Si el Usuario tuviera conocimiento de la publicación de algún dato inexacto, desactualizado y falso o de la
      existencia de algún contenido ilícito, ilegal, contrario a las leyes o que pudiera suponer una infracción de
      derechos de propiedad intelectual o industrial, de la normativa aplicable en material de protección de datos
      personales y/o de cualquier otro derecho, deberá notificarlo inmediatamente a {{ project.locales.project_upper }}
      para que esta pueda
      proceder a la adopción de las medidas oportunas</p>
    <br>
    <br>

    <h2>11. DURACIÓN Y TERMINACIÓN</h2>
    <p>Estas Condiciones entrarán en vigor entre {{ project.locales.project_upper }} y el Usuario desde el momento en
      que el Usuario las acepte
      expresamente mediante la marcación de la casilla correspondiente durante el procedimiento de contratación de
      cualquier Contenido o Producto sujeto a pago disponible en la Plataforma, y permanecerán vigentes mientras el
      Usuario continúe haciendo uso de cualesquiera de tales Contenidos o Productos, hasta que cualquiera de las partes
      lo resuelva conforme a los términos de este documento.</p>
    <br>
    <p>No obstante, determinadas disposiciones de este documento tienen la intención expresa o implícita de continuar en
      vigor tras la terminación o vencimiento, de modo que permanecerán en vigor y continuarán siendo exigibles para el
      Usuario conforme a sus términos incluso cuando estas Condiciones ya no se encuentren vigentes.</p>
    <br>
    <p>El Usuario reconoce que la contratación de determinados Contenidos y la adquisición de Productos conlleva el pago
      del Precio correspondiente, que dependerá de la modalidad de contratación seleccionada (suscripción, PPV o
      compra). La falta de pago del Precio correspondiente implicará que el Usuario no tendrá acceso al Contenido o
      Producto seleccionado, por lo que únicamente podrá acceder a aquellos que sí hubiese pagado y a aquellos
      Contenidos disponibles de manera gratuita en la Plataforma.</p>
    <br>
    <p>Asimismo, el Usuario reconoce que estas Condiciones podrán ser resueltas anticipadamente y, por tanto, cualquier
      Suscripción, Compra o Adquisición vigente podrá ser revocad, en las siguientes circunstancias:</p>
    <br>
    <ul>
      <li><i>Resolución por conveniencia</i>. El Usuario puede resolver el contrato en cualquier momento antes del
        vencimiento del periodo de facturación. En el caso de que la resolución se produjese a mitad del periodo de
        facturación, el Usuario podrá consumir el tiempo restante de su Suscripción hasta el vencimiento del Periodo de
        Suscripción correspondiente.
      </li>
      <li><i>Resolución por incumplimiento</i>. {{ project.locales.project_upper }} puede resolver el contrato en caso
        de incumplimiento de estas
        Condiciones por parte del Usuario y, en particular, en caso de retraso en el pago del Precio de cualquier
        Contenido o Producto contratado por el Usuario, sin perjuicio de que {{ project.locales.project_upper }}, a su
        discreción y atendiendo a
        la naturaleza del incumplimiento, podrá otorgar al Usuario un plazo máximo para subsanar el mismo.
        {{ project.locales.project_upper }} se
        reserva el derecho a suspender el acceso del Usuario a la Plataforma (o a cualquier Contenido afectado) durante
        el plazo de subsanación. En cualquier caso, dicha resolución no eximirá al Usuario de pagar el Precio que
        hubiese estado pendientes de pago.
      </li>
      <li><i>Resolución por usos indebidos</i>. {{ project.locales.project_upper }} podrá suspender o resolver el
        contrato con un Usuario en el
        caso de que se tenga conocimiento efectivo del uso indebido o contrario a las disposiciones de estas
        Condiciones, así como en el caso de recibir un aviso o notificación por el cual se informe a
        {{ project.locales.project_upper }} de que el
        uso de un Usuario puede constituir una actividad delictiva o ilícita conforme a la legislación vigente en España
        o en el país origen del Usuario.
      </li>
      <li><i>Resolución por interrupción o suspensión de la Plataforma</i>. En el caso de que
        {{ project.locales.project_upper }} decida
        interrumpir o suspender la actividad de la Plataforma, podrá resolver el contrato.
      </li>
    </ul>
    <br>

    <h2>Resolución por interrupción o suspensión de la Plataforma</h2>
    <p>{{ project.locales.project_upper }} podría tener acceso a información confidencial compartida por los Usuarios
      con el fin de prestar los
      servicios de la Plataforma y/o gestionar la relación contractual con aquellos. En el caso de que eso ocurriera,
      nos comprometemos a: (i) utilizar la información confidencial recibida única y exclusivamente para el cumplimiento
      de las Condiciones y la prestación de los servicios contratados; (ii) custodiar de manera estricta la información
      confidencial, evitando su divulgación o suministro, en todo o en parte, a cualquier tercero sin el consentimiento
      previo y expreso del Usuario; (iii) tratar la información confidencial con la máxima discreción y utilizarla única
      y exclusivamente en relación con la prestación de los servicios; (iv) implantar las medidas de seguridad
      razonablemente necesarias a efectos de cumplir esta cláusula.</p>
    <br>
    <p>Las anteriores obligaciones de confidencialidad no serán aplicables respecto de aquella información que: (i) sea
      de dominio público o fuese hecha pública por el Usuario; (ii) fuese lícitamente obtenida de terceros por
      {{ project.locales.project_upper }}
      sin restricción para su divulgación; (iii) fuese desarrollada independientemente y de manera legítima por
      {{ project.locales.project_upper }}; y (iv) fuese requerida por una autoridad judicial o administrativa en
      ejercicio de su competencia y
      únicamente en la medida en que la divulgación de la información fuese necesaria para cumplir ese
      requerimiento.</p>
    <br>
    <br>

    <h2>13. MISCELÁNEA</h2>
    <p>Los encabezamientos de las distintas cláusulas son meramente informativos y no afectan, califican ni fomentan la
      interpretación de estas Condiciones.</p>
    <br>
    <p>Todas las cláusulas o extremos de estas Condiciones deben ser interpretadas de forma independiente, no viéndose
      afectadas el resto de las cláusulas en caso de que una de ellas haya sido declarada nula por sentencia judicial o
      resolución arbitral firme. Se sustituirá la cláusula o cláusulas afectadas por otra u otras que preserven los
      efectos perseguidos por este documento</p>
    <br>
    <p>{{ project.locales.project_upper }} puede modificar los términos y condiciones aquí establecidos, en todo o en
      parte, en cualquier momento.
      Los cambios se publicarán en la Plataforma de forma similar a como están disponibles estas Condiciones y, en caso
      de afectar a Suscripciones o Contrataciones vigentes, por medio de correo electrónico.</p>
    <br>
    <br>

    <h2>14. LEY APLICABLE Y JURISDICCIÓN COMPETENTE</h2>
    <p>Estas Condiciones, incluyendo el uso que el Usuario haga de la Plataforma y de los Contenidos y Productos que se
      ofrecen en ella, se rigen por la legislación española vigente en cada momento.</p>
    <br>
    <p>En caso de controversia, acción o reclamación que pudiera surgir en relación con la interpretación y aplicación
      de las presentes Condiciones, el Usuario y {{ project.locales.project_upper }} se someten a la jurisdicción
      exclusiva de los Juzgados y
      Tribunales de la ciudad de Madrid (España) con renuncia expresa y voluntaria a cualquier otro fuero, salvo
      disposición legal imperativa en contrario</p>
    <br>
    <p>En cualquier caso, el Usuario podrá acudir a la Plataforma de Resolución de Conflictos En Línea de la Unión
      Europea a través del siguiente enlace: <a href="https://ec.europa.eu/consumers/odr/main/index.cfm"
                                                target="_blank">https://ec.europa.eu/consumers/odr/main/index.cfm</a>
    </p>
    <br>
  </section>

</template>

<script>
import project from '@/project'

export default {
  name: 'ES',
  data() {
    return {
      project
    }
  }
}
</script>

<style scoped>

</style>
