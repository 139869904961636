<template>
  <span>
    <TagPreferencesDialog/>
    <v-app-bar v-if="authenticated"
               app flat
               class="small-text border-bottom"
               :class="{ mini: !isLargeScreen, 'app-bar': !darkTheme } ">
      <slot name="prepend" v-if="!isLoverfans"/>
      <div v-if="activeMobileSearch && isMobile && !isLoverfans"
           class="app-wrapper align-center">
        <Search v-on:focused="switchSearchBar()"/>
      </div>
      <!--      <div class="app-wrapper d-flex align-center" v-else>-->
      <div class="app-wrapper d-flex align-center" v-if="!activeMobileSearch">
        <router-link :to="{ name: 'home' }">
          <img
            :src="`/images/${brandingLogo}.svg?dfk23`"
            height="30"/>
        </router-link>
        <v-spacer></v-spacer>
        <template>
        <router-link :to="{ name: 'search' }" v-if="isLoverfans && isNewFrontend">
          <v-icon>search</v-icon>
        </router-link>
          <span v-else>

            <Search v-if="!isMobile"/>
            <div v-else
                 class="my-2">
              <v-btn class="searchButton" text
                     fab x-small
                     @click="switchSearchBar()">
                <v-icon>search</v-icon>
              </v-btn>
            </div>
          </span>
          <!--          <Search v-if="!isMobile"/>-->
          <!--          <div v-else-->
          <!--               class="my-2">-->
          <!--            <v-btn class="searchButton" text-->
          <!--                   fab x-small-->
          <!--                   @click="switchSearchBar()">-->
          <!--              <v-icon>search</v-icon>-->
          <!--            </v-btn>-->
          <!--          </div>-->
        </template>
        <!--<v-col v-if="!isMobile" cols="auto" style="width: 60px !important;">
          <PublicationLauncher navbar></PublicationLauncher>
        </v-col>
        <c-btn v-else text rounded
               :href="chatHref"
               :to="chatRoute"
               :target="redirect ? '_blank' : ''"
               active-class="primary--text"
               transform="none"
               color=""
               hover-color="primary"
               :tooltip="chatTooltip"
               @click="hideSettings()">
          <v-icon :color="chatColor" center>
            {{ chatIcon }}
          </v-icon>
          <span v-if="unreadMessages" class="notification-unread-message">{{ countUnreadMessages }}</span>
        </c-btn>-->
        <!--<SwitchThemeButton v-if="!isMobile"/>-->
        <!--        <c-btn text rounded v-if="!isAdmin && !isAccountManager"-->
        <c-btn text rounded v-if="!isAdmin && !isAccountManager"
               :href="walletHref"
               :to="walletRoute"
               :target="mustRedirect ? '_blank' : ''"
               active-class="primary--text"
               transform="none"
               color=""
               hover-color="primary"
               :tooltip="walletTooltip">
          <v-icon v-if="!isMobile" left>monetization_on</v-icon>
          <span class="app-bar-content small-text success--text">
            {{ $currency(totalWalletAmount) }}
          </span>
        </c-btn>
        <template v-if="!isMobile || !isLoverfans">
          <div class="relative">
            <c-btn text rounded
                   :to="{ name: 'notifications' }"
                   active-class="primary--text"
                   transform="none"
                   color=""
                   hover-color="primary"
                   :tooltip="notificationsTooltip">
              <img :src="`/images/icons/${darkTheme ? 'notifications-dark' : 'notifications'}.svg`" height="24"/>
              <div class="circulo" v-if="hasNotifications">
                <div class="circulo-white">
                </div>
              </div>
              <!--<v-icon left :color="notificationColor">
                {{ notificationIcon }}
              </v-icon>
              <span class="app-bar-content small-text">
                {{ $t('application.menu.notifications') }}
              </span>-->
            </c-btn>
          </div>

        </template>

        <!--<v-col v-if="!isMobile" cols="auto">
          <LocaleSelect dense/>
        </v-col>-->
        <!--<app-bar-menu bottom left></app-bar-menu>-->
      </div>
    </v-app-bar>
    <v-app-bar v-else
               app flat
               class="app-bar small-text border-bottom">
      <!--<slot name="prepend"/>-->
      <div class="app-wrapper d-flex align-center">
        <router-link :to="{ name: 'home' }">
          <img
            :src="`/images/${brandingLogo}.svg?dfk23`"
            height="30"/>
        </router-link>
        <div class="body my-2">
          {{ $t('unlogged.unlogged') }}
        </div>
        <!--<v-col cols="auto">
          <LocaleSelect dense/>
        </v-col>-->
        <!--<app-bar-menu bottom left></app-bar-menu>-->
      </div>
    </v-app-bar>

  </span>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import Search from '@/components/custom/Search'
import {ChatActions} from '@/store'
import config from '@/project'
import TagPreferencesDialog from '@/components/tags/TagPreferencesDialog'

export default {
  name: 'AppBar',
  components: {
    TagPreferencesDialog,
    Search
  },
  data() {
    return {
      activeMobileSearch: false,
      themesEnabled: config.enable_themes,
      redirect: config.payments.darkfans_redirect && !this.isInfluencer
    }
  },
  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('preferences', ['darkTheme']),
    ...mapState('application', ['isMobile', 'isLargeScreen', 'hasNotifications', 'hasMessages', 'countUnreadMessages']),
    ...mapState('profile', ['currentUser', 'isFollower', 'isInfluencer', 'isAdmin', 'isAccountManager']),
    ...mapGetters('profile', ['totalWalletAmount']),
    walletTooltip() {
      return !this.isLargeScreen && this.$t('application.menu.wallet')
    },
    mustRedirect() {
      return config.payments.darkfans_redirect && !this.isInfluencer
    },
    walletHref() {
      if (!this.mustRedirect) return undefined
      return `https://darkfans.com/wallet?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
    },
    walletRoute() {
      if (this.mustRedirect) return undefined
      return {name: 'wallet'}
    },
    chatHref() {
      if (!this.mustRedirect) return undefined
      return `https://darkfans.com/messages?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
    },
    chatRoute() {
      if (this.mustRedirect) return undefined
      return {name: 'messages'}
    },

    chatTooltip() {
      return !this.isLargeScreen && this.$t('application.menu.chat')
    },
    unreadMessages() {
      return this.countUnreadMessages !== ''
    },
    notificationsTooltip() {
      return !this.isLargeScreen && this.$t('application.menu.notifications')
    },
    brandingLogo() {
      if (!this.darkTheme) {
        return this.isMobile ? 'logo_horizontal' : 'logo_horizontal'
        // return this.isMobile ? 'logo' : 'logo_horizontal'
      } else {
        return this.isMobile ? 'logo_white' : 'logo_horizontal_white'
      }
    },
    notificationIcon() {
      return this.hasNotifications ? 'notification_important' : 'notifications'
    },
    notificationColor() {
      return this.hasNotifications ? 'primary' : ''
    },
    chatIcon() {
      return 'chat'
    },
    chatColor() {
      return this.hasMessages ? 'primary' : ''
    },

    themeTooltip() {
      return this.darkTheme ? this.$t('application.menu.light_theme') : this.$t('application.menu.dark_theme')
    },

    isLoverfans() {
      return config.project === 'loverfans'
    },
    isNewFrontend () {
      return config.redirect_new_frontend
    }
  },
  methods: {
    switchSearchBar() {
      this.activeMobileSearch = !this.activeMobileSearch
    },
    hideSettings() {
      this.$store.dispatch(ChatActions.SelectChat, null)
      this.$store.dispatch(ChatActions.ShowChatSetting, false)
      this.$store.dispatch(ChatActions.ShowChatNew, false)
      this.$store.dispatch(ChatActions.ShowSetting, false)
      this.$store.dispatch(ChatActions.ShowVideoChat, false)
      this.$store.dispatch(ChatActions.ShowCarousel, false)
    }
  }
}
</script>

<style lang="scss">
.searchButton {
  color: #d4cbcb;
}

.body {
  margin: auto;
  text-align: center;
}

body.modal-open {
  overflow: hidden;
}

.notification-unread-message {
  background-color: #df3856;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  margin-top: -18px;
  margin-left: -5px;
}

.circulo {
  position: absolute;
  top: 0px;
  left: 5px;
  width: 13px;
  height: 13px;
  background: red;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  &.chat {
    left: 0px;
  }
}

.circulo-white {
  margin: auto;
  margin-top: 3px;
  width: 4px;
  height: 4px;
  background: #f1f1f1;
  border-radius: 50px;
}
</style>
