<template>
  <div class="user-cover">
    <c-img aspect-ratio="4.6" :height="checkHeight()"
      gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75) 99%"
      :src="user.bannerS3Route">
    </c-img>
    <CoverPortrait class="user-profile" :user="user" :editting-mode="innerEditting"/>
    <CoverActions class="profile-actions" :user="user" :editting-mode.sync="innerEditting" />
  </div>
</template>

<script>
import CoverPortrait from '@/components/users/covers/CoverPortrait'
import CoverActions from '@/components/users/covers/CoverActions'
import { mapState } from 'vuex'

export default {
  name: 'NormalCover',
  components: { CoverPortrait, CoverActions },
  computed: {
    ...mapState('profile', ['currentUser'])
  },
  props: {
    user: Object,
    edittingMode: { type: Boolean }
  },
  data () {
    return {
      innerEditting: false
    }
  },
  watch: {
    edittingMode: {
      immediate: true,
      handler (value) {
        this.innerEditting = value
      }
    },
    innerEditting (value) {
      this.$emit('update:editting-mode', value)
    }
  },
  methods: {
    checkHeight () {
      return this.isMobile ? 335 : 350
    }
  }
}
</script>
<style lang="scss" scoped>
.user-cover {
  color: white !important;
  position: relative;
  min-width: 280px !important;
  .user-profile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .profile-actions {
    position: absolute;
    text-align: right;
    top: 22px;
    right: 22px;
  }
}
</style>
