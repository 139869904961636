<template>
  <section>
    <h5>{{ title }}</h5>
    <InputText
      :showIconEmojis="showEmojis"
      :rows="rows"
      :maxLength="maxLength"
      :style="{color: darkTheme ? 'white' : 'black'}"
      noResize
      v-on:newText="saveValue"
      @hasBannedWord="isBannedWord"
      @hasHtmlLink="isHtmlTags"
      @hasPhone="isPhone"
      @hasLink="isLink"
      :load-message="content"
      :has-error="hasError"
      :load-default="loadDefault"
    ></InputText>
  </section>
</template>

<script>
import { existBannedWords, noHtml, noLink, noPhone } from '@/plugins/form_validations'
import InputText from '@/components/chat/component/InputText'
import { mapState } from 'vuex'

export default {
  name: 'Field',
  props: {
    title: String,
    rows: { type: Number, default: 4 },
    showEmojis: { type: Boolean, default: true },
    content: String,
    maxLength: { type: Number, default: 1500 },
    loadDefault: Boolean
  },
  components: { InputText },
  data () {
    return {
      hasBannedWord: false,
      hasPhone: false,
      hasLink: false,
      hasHtml: false
    }
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    hasError () {
      const errors = []
      if (this.hasBannedWord) {
        errors.push({ error: 'bannedWord', field: this.hasBannedWord })
      }
      if (this.hasPhone) {
        errors.push({ error: 'noPhoneNumber', field: true })
      }
      if (this.hasLink) {
        errors.push({ error: 'noLinksAllowed', field: true })
      }
      if (this.hasHtml) {
        errors.push({ error: 'noHtmlTags', field: true })
      }
      this.$emit('errors', errors)
      this.$emit('hasError', errors.length > 0)
      return errors
    }
  },
  methods: {
    saveValue (newValue) {
      this.$emit('newValue', newValue)
    },
    saveDisplayName (newDisplayName) {
      this.displayName = newDisplayName
    },
    isBannedWord (value) {
      this.hasBannedWord = existBannedWords(value)
    },
    isHtmlTags (value) {
      this.hasHtmlTags = noHtml(value)
    },
    isPhone (value) {
      this.hasPhone = noPhone(value)
    },
    isLink (value) {
      this.hasLink = noLink(value)
    }
  }
}
</script>

<style scoped>

</style>
