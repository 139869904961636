import { render, staticRenderFns } from "./LegalAgeMessage.vue?vue&type=template&id=379ba43e&scoped=true&"
import script from "./LegalAgeMessage.vue?vue&type=script&lang=js&"
export * from "./LegalAgeMessage.vue?vue&type=script&lang=js&"
import style0 from "./LegalAgeMessage.vue?vue&type=style&index=0&id=379ba43e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379ba43e",
  null
  
)

export default component.exports