<template>
  <svg :height="`${size}px`" :width="`${size}px`" xmlns="http://www.w3.org/2000/svg"
       class="icon icon-tabler icon-tabler-mood-happy" viewBox="0 0 24 24" stroke-width="2"
       fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path fill="none" stroke="none" d="M0 0h24v24H0z"></path>
    <path :style="{color:color}" d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
    <path d="M9 9l.01 0"></path>
    <path d="M15 9l.01 0"></path>
    <path d="M8 13a4 4 0 1 0 8 0h-8"></path>
  </svg>
</template>

<script>
export default {
  name: 'Emojis',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
