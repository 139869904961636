import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './locales/en.js'
import cookies from '@/cookies'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en: enLocale },
  silentTranslationWarn: true,
  numberFormats: {
    es: {
      currency: {
        style: 'currency', currency: 'EUR'
      }
    },
    en: {
      currency: {
        style: 'currency', currency: 'EUR'
      }
    }
  },
  dateTimeFormats: {
    es: {
      short: {
        day: 'numeric', month: 'numeric', year: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
      }
    },
    en: {
      short: {
        day: 'numeric', month: 'numeric', year: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
      }
    }
  }
})

i18n.supportedLocales = ['es', 'en', 'de', 'it', 'pt', 'fr']
const loadedLocales = ['en']

i18n.loadLocale = async function (locale) {
  if (!this.supportedLocales.includes(locale)) {
    return
  }
  if (!loadedLocales.includes(locale)) {
    // place path in a const variable to avoid failing Babel linting
    // const localePath = 
    const messages = await import(/* webpackChunkName: "lang-[request]" */ `@/i18n/locales/${locale}.js`)
    i18n.setLocaleMessage(locale, messages)
    loadedLocales.push(locale)
  }
  i18n.locale = locale
  cookies.set('locale', locale)
  document.querySelector('html').setAttribute('lang', locale)
}

i18n.install = (Vue) => {
  Vue.use(VueI18n)
  const padTo = (elem, count, char) => ('' + elem).padStart(count, char)

  Vue.prototype.$datetime = (date, format) => {
    if (!date) {
      return ''
    }

    const dateObject = new Date(date)
    const dateFormat = format || i18n.t('types.datetime.format')
    return dateFormat
      .replace('YYYY', dateObject.getFullYear())
      .replace('MM', padTo(dateObject.getMonth() + 1, 2, '0'))
      .replace('DD', padTo(dateObject.getDate(), 2, '0'))
      .replace('HH', padTo(dateObject.getHours(), 2, '0'))
      .replace('MM', padTo(dateObject.getMinutes(), 2, '0'))
      .replace('SS', padTo(dateObject.getSeconds(), 2, '0'))
  }

  Vue.prototype.$date = (date, format) => {
    if (!date) {
      return ''
    }
    const dateObject = new Date(date)
    const dateFormat = format || i18n.t('types.date.format')
    return dateFormat
      .replace('YYYY', dateObject.getFullYear())
      .replace('MM', padTo(dateObject.getMonth() + 1, 2, '0'))
      .replace('DD', padTo(dateObject.getDate(), 2, '0'))
  }

  Vue.prototype.$country = (ISOKey) => {
    const country = i18n.t('countries').find(c => c.value === ISOKey)
    return country ? country.text : ISOKey
  }
}

export default i18n
