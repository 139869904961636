<template>
  <div style="min-height: 1000px;">
    <!-- <div class="wire" :style="`left: calc(50% - ${isMobile ? 108 : 132}px);margin-top: -16%; width: ${isMobile ? 220 : 280}px`">
      <div class="first" :style="`width: ${isMobile ? 72 : 88}px; left: 0px;`"></div>
      <div class="second" :style="`width: ${isMobile ? 72 : 88}px; left: ${isMobile ? 72 : 88}px;`"></div>
      <div class="third" :style="`width: ${isMobile ? 72 : 88}px; left: ${isMobile ? 144 : 176}px;`"></div>
    </div>
    -->
    <FollowLanding v-if="false"/>
    <!--     <CreatorLanding v-else /> -->
    <!--  <v-col
      cols="12"
      class="align-center"
      style="margin: auto;text-align: center"
    >
      <div class="adorno-murcielago" style="position: fixed; z-index:0">
        <img
          style="float: right; transform: scaleX(-1);"
          class="img-responsive"
          src="/images/promotions/murcielago.svg"
        />
      </div>

      <div class="adorno-calabaza" style="position: fixed; z-index:0">
        <img class="img-responsive" src="/images/promotions/calabaza.svg" />
      </div>

      <div></div>
      <v-row>
        <v-col
          cols="4"
          class=""
          style="background: #F71953 0% 0% no-repeat padding-box;"
        >
          asd
        </v-col>
        <v-col
          cols="4"
          class=""
          style="background: #000000 0% 0% no-repeat padding-box;"
        >
        </v-col>
        <v-col
          cols="4"
          class=""
          style="background: #F71953 0% 0% no-repeat padding-box;"
        >
          asd
        </v-col>

        <div
          class="caja-logo"
          :style="{
            margin: !isLargeScreen ? 'auto' : '5% auto',
            width: !isLargeScreen ? '-webkit-fill-available' : ''
          }"
        >
          <router-link :to="{ name: 'home' }">
            <v-img
              contain
              :src="getSrc"
              max-height="35px"
              :style="{ margin: !isLargeScreen ? 'auto' : '' }"
              style="max-width: fit-content;"
            ></v-img>
          </router-link>
          <div class="separa xs-size"></div>
          <div class="separa xs-size telefono"></div>
          <div
            class="bloqueslogan2 fuente-destacada negro"
            :style="{ textAlign: !isLargeScreen ? 'center' : '' }"
            style="display: block;padding: 0; font-weight: bolder; text-transform: uppercase; font-family: Quicksand, sans-serif;"
          >
            <span class="blanco">{{ $t("event.halloween.title1") }}</span>
            <br />{{ $t("event.halloween.desc1") }}
          </div>
          <div class="separa xs-size"></div>
          <div class="bloqueslogan3 fuente-destacada negro" style="font-size: 18px; padding: 0">Disponibles solo del <span class="blanco">28/10</span> al <span class="blanco">01/11</span></div>
        </div>
        <v-spacer></v-spacer>
        <v-col
          :cols="!isLargeScreen ? 12 : 5"
          style="position: relative; margin: auto;"
        >
          <div
            class="slogan-cabecera fuente-destacada blanco"
            style="line-height: 1em; position: relative;"
          >
            <div
              style=" position: absolute; top: 0; left: 8px; background-image: url('/images/promotions/galon.svg'); width: 34px; height: 37px;"
            >
              <img
                src="/images/logo_white.svg"
                style="width: calc(100% - 15px); height: calc(100% - 15px);margin-top: 5px;"
              />
            </div>

            <strong
              style="color:#f0ff00; padding-left: 36px; font-size: 38px; line-height: 44px; vertical-align: middle;"
              ><span style="color: #ffffff">{{ $t("event.gift") }}</span>
              <span style="color: #FC6B00"> +25%</span></strong
            ><br /><span
              style="font-size: 18px; line-height: 28px; letter-spacing: 1px;"
              >{{ $t("event.recharge") }}</span
            >
          </div>
          ///// campaña antigua

          <v-col
            cols="12"
            class="counter"
            :style="{ color: isDark ? 'white' : 'black' }"
            >{{ $t("timeleft") }}</v-col
          >

          <vue-countdown-timer
            :day-txt="'days'"
            :hour-txt="'hours'"
            :interval="1000"
            :minutes-txt="'minutes'"
            :seconds-txt="'seconds'"
            :start-time="'2022-08-01 00:00:00'"
            :end-time="'2022-08-31 23:59:59'"
            label-position="begin"
            @end_callback="endEvent()"
          >
            <template slot="countdown" slot-scope="scope">
              <div class="countdown-box" style="justify-content: center;">
                <div
                  class="countdown-box-item fuente-destacada"
                  :style="cssBoxStyle"
                >
                  <div class="countdown-box-numero">{{ scope.props.days }}</div>
                  <div class="countdown-box-titulo">
                    {{
                      scope.props.days === 1
                        ? $t("types.date.day")
                        : $t("types.date.days")
                    }}
                  </div>
                </div>
                <div
                  class="countdown-box-item fuente-destacada"
                  :style="cssBoxStyle"
                >
                  <div class="countdown-box-numero">
                    {{ scope.props.hours }}
                  </div>
                  <div class="countdown-box-titulo">
                    {{
                      scope.props.hours === 1
                        ? $t("types.date.hour")
                        : $t("types.date.hours")
                    }}
                  </div>
                </div>
                <div
                  class="countdown-box-item fuente-destacada"
                  :style="cssBoxStyle"
                >
                  <div class="countdown-box-numero">
                    {{ scope.props.minutes }}
                  </div>
                  <div class="countdown-box-titulo">
                    {{
                      scope.props.minutes === 1
                        ? $t("types.date.minute")
                        : $t("types.date.minutes")
                    }}
                  </div>
                </div>
                <div
                  class="countdown-box-item fuente-destacada"
                  :style="cssBoxStyle"
                >
                  <div class="countdown-box-numero">
                    {{ scope.props.seconds }}
                  </div>
                  <div class="countdown-box-titulo">
                    {{
                      scope.props.seconds === 1
                        ? $t("types.date.second")
                        : $t("types.date.seconds")
                    }}
                  </div>
                </div>
              </div>
            </template>

            <template slot="end-text" slot-scope="scope">
              <div class="countdown-box" style="justify-content: center;">
                <div
                  class="countdown-box-item fuente-destacada"
                  :style="cssBoxStyle"
                >
                  <div class="countdown-box-numero">00</div>
                  <div class="countdown-box-titulo">
                    {{
                      scope.props.days === 1
                        ? $t("types.date.day")
                        : $t("types.date.days")
                    }}
                  </div>
                </div>
                <div
                  class="countdown-box-item fuente-destacada"
                  :style="cssBoxStyle"
                >
                  <div class="countdown-box-numero">00</div>
                  <div class="countdown-box-titulo">
                    {{
                      scope.props.hours === 1
                        ? $t("types.date.hour")
                        : $t("types.date.hours")
                    }}
                  </div>
                </div>
                <div
                  class="countdown-box-item fuente-destacada"
                  :style="cssBoxStyle"
                >
                  <div class="countdown-box-numero">00</div>
                  <div class="countdown-box-titulo">
                    {{
                      scope.props.minutes === 1
                        ? $t("types.date.minute")
                        : $t("types.date.minutes")
                    }}
                  </div>
                </div>
                <div
                  class="countdown-box-item fuente-destacada"
                  :style="cssBoxStyle"
                >
                  <div class="countdown-box-numero">00</div>
                  <div class="countdown-box-titulo">
                    {{
                      scope.props.seconds === 1
                        ? $t("types.date.second")
                        : $t("types.date.seconds")
                    }}
                  </div>
                </div>
              </div>
            </template>
          </vue-countdown-timer>
        </v-col>
      </v-row>

      <v-row style="margin: 30px -30px;">
        <ListView
          no-gutters
          cols="6"
          sm="6"
          md="3"
          :loading="cursor.loading"
          :items="cursor.items"
          @bottom-reached="cursor.loadItems"
        >
          <template #default="{ item }">
            <section class="px-2 py-2">
              <div :style="{ margin: !isLargeScreen ? 'auto' : '0px 10px' }">
                <router-link
                  :to="{
                    name: 'influencer',
                    params: { username: item.username }
                  }"
                >
                  <div
                    class="ficha-chica-caja"
                    :style="{
                      backgroundImage: `url(${item.avatarS3Route})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover'
                    }"
                  >
                    <div
                      class="caja-enlinea2 full center centrado"
                      v-if="item.onlineStatus !== 'offline'"
                    >
                      <div class="online-punto"></div>
                    </div>
                    <div class="caja-ficha-chica-foto">
                      <v-avatar :size="150" class="ficha-chica-foto">
                        <c-img
                          placeholder="false"
                          :height="150"
                          :src="item.avatarS3Route"
                        ></c-img>
                      </v-avatar>
                    </div>
                    <div class="caja-textos-chica fuente-destacada ">
                      <div class="separa"></div>
                      <span class="caja-nombre-chica blanco">{{
                        item.displayName
                      }}</span
                      ><br />
                      <div class="separa xs-size tableta escritorio"></div>
                      <span class="caja-nick-chica">@{{ item.username }}</span>
                      <div class="separa xs-size tableta escritorio"></div>
                      <br class="telefono" />
                      <v-btn
                        class="boton-videollamada fuente-destacada"
                        color="#99f5db"
                        small
                        v-if="
                          item.onlineStatus !== 'offline' &&
                            item.videochatEnabled
                        "
                        :to="{
                          name: 'messages',
                          query: { username: item.username, call: true }
                        }"
                      >
                        <img
                          class="icono-boton-videollamada"
                          src="@/components/chat/assets/images/ico-video-call.svg"
                        />
                        <p>Videocall</p>
                      </v-btn>
                      <div class="separa xs-size"></div>
                    </div>
                    <div class="degradadooscuro"></div>
                  </div>
                </router-link>
                <v-spacer></v-spacer>
              </div>
            </section>
          </template>
        </ListView>

        <v-col cols="12">
          <div class="full center">
            <p
              class="fuente-destacada negro"
              style="font: normal normal medium 14px/18px Quicksand;"
            >
              {{ $t("event.validPromotion") }}
            </p>
          </div>
        </v-col>

        <v-col cols="12">
          <v-row justify="end">
            <v-col cols="auto">
              <v-btn
                tile
                depressed
                :color="isDark ? '#B0601E' : '#F71953'"
                :to="{ name: 'stars_discover' }"
              >
                <span style="font-family: Arial; font-size: 16px; color: white"
                  >{{ $t("actions.see_more") }}➤</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col> -->
  </div>
</template>

<script>
import project from '@/project'
import { mapState } from 'vuex'
import messages from '@/views/landing/specialCampaign/locales/all.json'
import CreatorLanding from '@/views/landing/specialCampaign/CreatorLanding'
import FollowLanding from '@/views/landing/specialCampaign/FollowLanding'
import CampaingPromoDialog from '@/views/landing/specialCampaign/CampaingPromoDialog'

export default {
  name: 'SpecialCampaign',
  i18n: { messages },
  components: {
    CreatorLanding,
    FollowLanding,
    CampaingPromoDialog
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isFollower']),
    ...mapState('application', ['isMobile'])
  },
  created () {
    /*  const root = document.documentElement;
    if (project.project === "scatbook") {
      const baseColor = "#f47b03";
      root.style.setProperty("--unnamed-color-base", baseColor);
    }
    if (project.project === "loverfans") {
      const baseColor = "#f71953";
      root.style.setProperty("--unnamed-color-base", baseColor);
    }
    if (project.project === "darkfans") {
      const baseColor = "#f40303";
      root.style.setProperty("--unnamed-color-base", baseColor);
    } */
  },
  methods: {
    eventStarted (today) {
      const users = project.campaign.users
      let activeUser = true
      if (users.length > 0) {
        activeUser = false
        const index = users.findIndex(u => u === this.currentUser.username)
        activeUser = index !== -1
      }
      // LOS MESES SON DE 0 - 11)
      var eventStarted = project.campaign.started
      const activeDate = today - eventStarted > 0
      return activeDate && activeUser
    },
    eventEnded (today) {
      // LOS MESES SON DE 0 - 11)
      var eventEnded = project.campaign.ended
      return eventEnded - today < 0
    },
    endEvent () {
      if (project.campaign.specialEnabled) return
      this.$router.push({ name: 'login' })
    }
  },
  beforeMount () {
    if (!this.eventStarted(new Date()) || this.eventEnded(new Date())) {
      this.endEvent()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./_styles";

.fuente-destacada span {
  font-family: "Quicksand", sans-serif !important;
}

.header {
  font-family: "Quicksand", sans-serif !important;
  font-size: 16px;
}

p.counter {
  font-family: "BenchNine-Light", sans-serif;
  font-size: 20.8px;
  line-height: 1.2em;
  border-radius: 20px;

  .dest-text {
    color: var(--v-primary-base);
  }
}

.buttons {
  color: white;
  font-size: 13px;
  font-family: Arial;
}

.starName {
  background-color: white !important;
  color: black;
  margin: auto;
  width: fit-content;
  max-width: 167px;
  max-height: 25px;
  text-transform: capitalize;
}

.stats {
  position: absolute;
  background-color: brown;
  /* top: 0; */
  color: white;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* margin: 5px 0; */
  font-size: 16px;
  bottom: 0;

  img {
    margin-right: 5px;
  }
}

.free-content {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #ffffff;
  font-size: 16px;
  font-family: Quicksand, sans-serif;
  font-weight: bold;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  background: linear-gradient(270deg, #3cc590, #00d617);
}

.online {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  background-color: #00d617;
  width: 14px;
  height: 14px;
  border-radius: 14px;
}

.card-star {
  margin: auto;
  display: inline-block;
  position: relative;
  max-height: 430px;
  min-height: 220px;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #f0f0f0;

  .bottom {
    font-size: 20px;
    position: absolute;
    height: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.locale :hover {
  background-color: #f2f2f2 !important;
}

/*Colores identidad de la web TEXTO*/
.colordest {
  color: #f71953;
}

.fondo-colordest {
  background-color: #f71953;
}

/*Fuente destacada*/
.fuente-destacada {
  font-family: "Quicksand", sans-serif;
}

.fuente-destacada2 {
  font-family: "BenchNine", sans-serif;
}

.box-list {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  max-height: 310px;
}

.tachado {
  text-decoration: line-through;
}

.tit-item-list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /*background: rgb(0,0,0); background: linear-gradient(0deg, rgba(0,0,0,1) 24%, rgba(18,9,1,0) 100%);*/
  height: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tit-item-list span {
  color: #000000;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 0.9em;
  line-height: 1em;
}

@media (max-width: 991px) {
  .tit-item-list span {
    font-size: 0.7em;
    margin: 0 5px;
  }
}

.box-porcent {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  color: #ffffff;
  border-radius: 4px;
  padding: 0 5px;
  background: linear-gradient(270deg, #3cc590, #00d617);
}

.box-perfil {
  color: #ffffff;
  z-index: 2;
  border-radius: 4px;
  padding: 0 5px;
  background: linear-gradient(270deg, #3bd1ff, #00acff);
  font-size: 0.8em;
}

@media (max-width: 991px) {
  .ajuste-contenedor {
    max-width: 100%;
  }
}

.tit-datos {
  font-size: 1.3em;
  color: #000000;
  line-height: 1.2em;
  padding: 4px 10px 2px 10px;
  border-radius: 20px;
  display: inline-block;
}

.tit-datos.dos {
  font-size: 1.1em;
  background-color: #16252d;
  color: #ffffff;
  line-height: 1.2em;
  padding: 2px 10px 0 10px;
  border-radius: 20px;
  display: inline-block;
}

.box-datos {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  padding: 5px 0;
  color: #000000;
  background-color: #ffffff;
}

.v-al {
  vertical-align: middle;
}

@media (max-width: 991px) {
  .v-al {
    font-size: 0.7em;
  }
}

.tachado {
  margin: auto;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  min-width: 40px /*operahack*/
;
}

.title-txt {
  font-size: 32px;
  font-weight: bold;
  font-family: Quicksand, SansSerif;
  text-align: left;
  margin-bottom: -15px;
}

.counter {
  font-size: 24px;
  font-family: Quicksand, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: -10px;
}

.countdown-box {
  display: flex;
  flex-direction: row;
}

@media (max-width: 991px) {
  .countdown-box {
    margin-top: 10px;
  }
}

.countdown-box-item {
  width: 100px;
  height: 100px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0 10px;
}

@media (max-width: 991px) {
  .countdown-box-item {
    height: 60px;
    width: 60px;
  }
}

.countdown-box-numero {
  font-size: 4em;
  line-height: 1em;
  font-family: Quicksand, sans-serif !important;
}

.countdown-box-titulo {
  font-size: 1em;
  line-height: 1em;
  font-family: Quicksand, sans-serif !important;
}

@media (max-width: 991px) {
  .countdown-box-numero {
    font-size: 2em;
    line-height: 1em;
    font-family: Quicksand, sans-serif !important;
  }
  .countdown-box-titulo {
    font-size: 0.6em;
    line-height: 1em;
    font-family: Quicksand, sans-serif !important;
  }
}

.bolaIzq {
  position: absolute;
  left: 35px;
  top: 30px;
}

.bolaDer {
  position: absolute;
  right: 35px;
  top: 30px;
}

.adorno-murcielago {
  position: absolute;
  top: -48px;
  right: -35px;
  opacity: 0.1;
  width: 50%;
}

.adorno-calabaza {
  position: absolute;
  top: -75px;
  left: 0;
  opacity: 0.2;
  width: 50%;
}

.caja-logo {
  z-index: 1;
  text-align: left;
  display: inline-block;
}

.bloqueslogan2 {
  border-radius: 10px;
  padding: 10px;
  text-align: left;
  font-size: 21px;
  line-height: 26px;
  font-weight: bold;
  display: inline-block;
  margin: 0 auto;
}

.blanco {
  color: #ffffff;
}

.separa {
  width: 100%;
  height: 30px;
  font-size: 0;
  clear: both;
}

.separa.xs-size {
  width: 100%;
  height: 10px;
  font-size: 0;
  clear: both;
}

.slogan-cabecera {
  line-height: 1em;
  position: relative;
  text-align: center;
  font-size: 2em;
  background: #1c1c1c;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  line-height: 1em;
  position: relative;
}

#text {
  font-family: Quicksand, sans-serif !important;
}

.ficha-chica-caja {
  z-index: 2;
  position: relative;
  height: 290px;
  margin-bottom: 55px;
  margin-top: 30px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid white;
}

.caja-enlinea2 {
  position: absolute;
  top: -38px;
  width: 100%;
  margin-left: -42px;
  text-align: center;
  z-index: 3;
}

.online-punto {
  z-index: 2;
  background-color: #00d617;
  width: 12px;
  height: 12px;
  border-radius: 14px;
  margin: 0 auto;
  display: inline-block;
}

.caja-ficha-chica-foto {
  position: absolute;
  top: -40px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.ficha-chica-foto {
  border-radius: 50%;
  border: 2px solid #ffffff;
  width: 150px;
  z-index: 2;
}

.caja-textos-chica {
  position: absolute;
  top: 105px;
  width: 100%;
  text-align: center;
  z-index: 5;
}

.caja-nombre-chica {
  font-size: 14px;
  line-height: 26px;
  font-weight: bold;
}

.separa.xs-size {
  width: 100%;
  height: 10px;
  font-size: 0;
  clear: both;
}

.caja-nick-chica {
  font-size: 14px;
  line-height: 26px;
  font-weight: normal;
  font-weight: normal;
  color: #ffffff;
  opacity: 0.8;
}

.boton-videollamada {
  border-radius: 20px;
  background-color: #99f5db;
  color: #60be99;
  padding: 5px 5px;
  display: inline-flex;
  margin-top: 10px;
}

.icono-boton-videollamada {
  width: 20px;
}

.degradadooscuro {
  background: rgb(0, 0, 0);
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8547794117647058) 0%,
      rgba(0, 0, 0, 0.6362920168067228) 65%
  );
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 5px;
}

.full {
  width: 100%;
}

.center {
  text-align: center;
}

.wire {
  position: absolute;
  height: 100%;
  min-height: -webkit-fill-available;
  /*min-width: -webkit-fill-available;
  min-width: -moz-available;*/
  z-index: 0;

  .first {
    min-height: -webkit-fill-available;
    min-height: 100%;
    position: absolute;
    background-color: #D75778;
  }

  .second {
    min-height: -webkit-fill-available;
    min-height: 100%;
    position: absolute;
    background-color: #CB0A5B;
  }

  .third {
    min-height: -webkit-fill-available;
    min-height: 100%;
    position: absolute;
    background-color: #61174A;
  }
}

</style>
