<template>
  <section class="app-fullpage">
    <v-tabs
      background-color="transparent"
      class="border-bottom"
      v-model="tab"
      show-arrows>
      <v-tab
        v-for="item in availableTabs"
        :key="item.component"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-alert v-if="isPending"
               text type="info" color="blue">
        {{ $t('promotion.state.pending') }}
      </v-alert>
      <v-tab-item
        v-for="item in availableTabs"
        :key="item.component">
        <component :is="item.component"
                   persist
                   class="px-3 py-3"
                   style="padding-bottom: 52px;"
                   :route="item.route"></component>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions, WithdrawActions } from '@/store'
import UserAccountSettings from '@/components/users/UserAccountSettings'
import UserPersonalSettings from '@/components/users/UserPersonalSettings'
import UserPaymentSettings from '@/components/users/UserPaymentSettings'
import RedirectComponent from '@/components/custom/RedirectComponent'
import PreferencesView from '@/components/preferences/views/PreferencesView'
import WithdrawSettingSection from '@/components/withdraws/WithdrawSettingSection'
import PromotionRequestedFinished from '@/components/promote/PromotionRequestedFinished.vue'

export default {
  name: 'SettingsView',
  components: {
    UserAccountSettings,
    UserPersonalSettings,
    UserPaymentSettings,
    PreferencesView,
    RedirectComponent,
    WithdrawSettingSection,
    PromotionRequestedFinished
  },
  data () {
    return {
      tab: null
    }
  },

  computed: {
    ...mapState('application', ['isMobile', 'isMediumScreenOrGreater']),
    ...mapState('profile', ['currentUser', 'isFollower', 'isInfluencer', 'promotion']),
    isInactive () {
      return this.promotion.status === 'inactive'
    },
    isRejected () {
      return this.promotion.status === 'rejected'
    },
    isPending () {
      return this.promotion.status === 'pending'
    },
    tabs () {
      const tabs = [
        {
          name: this.$t('settings.tabs.account'),
          component: 'UserAccountSettings',
          show: true
        },
        {
          name: this.$t('settings.tabs.personal'),
          component: 'UserPersonalSettings',
          show: true
        },
        {
          name: this.$t('settings.tabs.payments'),
          component: 'UserPaymentSettings',
          show: this.isInfluencer
        },
        {
          name: this.$t('settings.tabs.promo_star'),
          component: 'RedirectComponent',
          route: {
            name: 'become_star',
            params: { origin: 'setting' }
          },
          show: !this.isInfluencer && !this.isPending
        },
        {
          name: this.$t('settings.tabs.promo_star'),
          component: 'PromotionRequestedFinished',
          show: !this.isInfluencer && this.isPending
        },
        {
          name: this.$t('settings.tabs.billing'),
          component: 'WithdrawSettingSection',
          show: this.isInfluencer
        },
        {
          name: this.$t('settings.tabs.messages'),
          component: 'RedirectComponent',
          route: {
            name: 'messages',
            params: { tab: 'setting' }
          },
          show: this.isInfluencer
        },
        {
          name: this.$t('settings.tabs.privacy'),
          component: 'PreferencesView',
          show: true
        }
      ]
      if (this.isFollower) {
        tabs[2].name = this.$t('settings.tabs.promo_star')
      }
      return tabs
    },
    availableTabs () {
      return this.tabs.filter(tab => tab.show)
    }
  },
  beforeMount () {
    this.$store.dispatch(ProfileActions.Fetch)
    this.$store.dispatch(WithdrawActions.FetchData)
    this.$store.dispatch(WithdrawActions.FetchMethods)
  },

  mounted () {
    const tab = this.$route.query.tab
    if (tab === 'service') {
      this.tab = this.isFollower ? 2 : 3
    } else if (tab === 'notifications') {
      this.tab = 4
    } else if (tab === 'personal') {
      this.tab = 1
    }
  }
}
</script>
