<template>
  <section>
    <div class="item-list-bulk" @click="selectedList">
      <div>
        <p>
          {{ $t('application.chat_setting.userList.row.name') }} {{ row.name }}
        </p>
        <p class="date-item-list-bulk">
          {{ $t('application.chat_setting.userList.row.createAt') }}
          {{ $date(row.updatedAt) }}
        </p>
        <p class="date-item-list-bulk">
          {{ $t('application.chat_setting.userList.row.countUser') }}
          {{ totalUser }}
        </p>
      </div>
      <div class="icon-bulk" v-if="!noEdit">
        <IconButton type="edit" :size="17" @handler="editItemList" />
        <IconButton type="delete" :size="17" @handler="showWarning" />
      </div>
    </div>
    <WarningDialog
      :show="showWarningDelete"
      :textWarning="textWarning"
      :textButtonOK="textButtonOK"
      @handlerButtonCancel="showWarning"
      @handlerButtonOK="deleteItemList"
      :loading="loadingButton"
    />
    <hr class="hr" />
  </section>
</template>

<script>
import IconButton from '@/components/chat/component/IconButton'
import WarningDialog from '@/components/chat/component/WarningDialog.vue'
export default {
  name: 'ViewListUserRow',
  components: {
    IconButton,
    WarningDialog
  },
  props: {
    row: { type: Object },
    noEdit: { type: Boolean, defualt: false }
  },
  computed: {
    totalUser () {
      return this.row.users.length
    },
    textWarning () {
      return this.$t('application.chat_setting.userList.deleteWarning', {
        nameList: this.row.name
      })
    },
    textButtonOK () {
      return this.$t('actions.ok').toUpperCase()
    }
  },
  data () {
    return {
      loadingButton: false,
      showWarningDelete: false
    }
  },
  methods: {
    showWarning () {
      this.showWarningDelete = !this.showWarningDelete
    },
    deleteItemList () {
      this.$emit('deleteItemList', this.row.id)
      this.showWarning()
    },
    editItemList () {
      this.$emit('editItemList', this.row)
    },
    selectedList(){
      this.$emit('selectedList', this.row)
    }
  }
}
</script>

<style lang="scss" scoped>
.item-list-bulk {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.date-item-list-bulk {
  font-size: 0.8em;
  line-height: 1em;
  color: #a5a5a5;
}
.icon-bulk {
  display: flex;
}
.hr {
  overflow: hidden;
  margin-top: 5px;
  border-color: rgb(0 0 0 / 14%);
  border-width: 1px;
}
</style>
