<template>
  <div class="draft rounded"
       :class="{ large }">
    <div class="message d-flex flex-column justify-center rounded">
      <img src="/images/check_green.svg" width="80" height="80" style="margin:0 auto">
      <b class="mt-2">{{ $t('posts.draft_hint_3') }}</b>
      <span class="mt-2">{{ $t('posts.draft_hint_4') }}</span>
      <span class="mt-2">{{ $t('posts.draft_hint_5') }}</span>
      <slot></slot>
      <c-btn v-if="skippable"
             color="primary"
             css="mt-4"
             @click="$emit('continue')">
        {{ $t('posts.draft_continue') }}
      </c-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostDraft',
  props: {
    large: Boolean,
    skippable: Boolean
  }
}
</script>
<style lang="scss" scoped>

.draft {
  font-size: 18px;
  text-align: center;

  .message {
    height: 100%;
    padding: 15px;
    backdrop-filter: blur(15px);
  }

  .progress {
    margin-top: 25px;
    width: 100%;
  }

  &.large {
    height: 500px;

    .message {
      padding: 50px;
    }
  }
}
</style>
