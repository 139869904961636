<template>
  <v-form
    v-model="validForm" ref="form"
    @submit.stop.prevent="submit">
    <template v-if="showWebcam">
      <div style="position: relative;">
        <img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%209%22%20width%3D%2216%22%20height%3D%229%22%2F%3E" style="width: 100%;" />
        <video id="webcam" autoplay="true" class="live-view rounded">
        </video>
        <div class="webcam-feedback d-flex flex-wrap align-center justify-center">
          <v-icon v-if="camSuccess"
            class="top-right live-animation"
            color="primary">radio_button_checked</v-icon>
          <div class="webcam-feedback-message elevation-2 bold rounded"
            :class="webcamColorClass">
            {{ webcamFeedbackMessage}}
          </div>
        </div>
      </div>
      <ConferenceDescription v-if="currentConference"
        v-bind="currentConference"
        @leave="close('close')" />
    </template>
    <v-text-field outlined
      :label="$t('models.conference.title')"
      v-model="title"
      :rules="[$vBannedWords(title), $vNoHtml(title)]">
    </v-text-field>
    <v-row align="center">
      <v-col cols="3" class="label small-text">
        <v-icon>visibility</v-icon>
        <span>{{ $t('models.conference.visibility') }}</span>
      </v-col>
      <v-col sm="4">
        <v-select outlined hide-details
          class="d-inline-block"
          v-model="type"
          :items="visibilityOptions"
          :rules="[$vRequired]">
        </v-select>
      </v-col>
      <v-col v-if="type"
        cols="5"
        class="small-text">{{ visibilityDescription }}</v-col>
    </v-row>
    <v-row v-if="isPayment" align="center">
      <v-col cols="3" class="label small-text">
        <v-icon>monetization_on</v-icon>
        <span>{{ $t('models.conference.minute_price') }}</span>
      </v-col>
      <v-col cols="4">
        <CurrencyInput outlined
          color="orange"
          hint="XXX,YY"
          v-model="minutePrice"
          :rules="[$vRequired]"
          minValue="10" />
      </v-col>
      <v-col cols="5" v-if="isPremium">
        <v-switch color="primary" v-model="freeFans">
          <template #prepend>
            <span class="small-text">{{ $t('models.conference.free_fans_hint') }}</span>
          </template>
        </v-switch>
      </v-col>
    </v-row>
    <v-row v-if="isExclusive" align="center">
      <v-col cols="auto" class="label small-text">
        <v-icon>person</v-icon>
        <span>{{ $t('models.conference.selected_user') }}</span>
      </v-col>
      <v-col>
        <v-row>
          <v-col v-if="selectedUser"
            cols="8">
            <UserAvatar :user="selectedUser" />
          </v-col>
          <v-col cols="4">
            <c-btn small text
              css="ma-2"
              @click.stop="showDialog">
              {{ $t('actions.chose') }}
            </c-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="space-between">
      <v-col cols="auto">
        <v-icon >access_time</v-icon>
        {{ $t('models.conference.choose_starts_at') }}
      </v-col>
      <v-col cols="auto">
        <v-switch color="primary" v-model="showDates"></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="showDates">
      <v-col cols="12" md="6">
        <date-picker-input v-model="startDate"
          :rules="[$vRequired]"
          :label="$t('models.conference.starts_date')" />
      </v-col>
      <v-col cols="12" md="6">
        <time-picker-input v-model="startTime"
          :rules="[$vRequired]"
          :label="$t('models.conference.starts_time')" />
      </v-col>
    </v-row>
    <v-card-actions class="justify-end">
      <c-btn text color="secondary"
        @click="close('close')">
          {{ $t('actions.close') }}
      </c-btn>
      <c-btn :disabled="!submitable"
        @click.stop.prevent="submit">
        {{ $t("actions.publish") }}
      </c-btn>
    </v-card-actions>
    <UserSearchDialog
      v-model="dialog"
      @user-selected="(user) => selectedUser = user" />
  </v-form>
</template>
<script>
import ConferenceApi from '@/api/ConferenceApi'
import validatable from '@/components/mixins/validatable'
import dialog from '@/components/mixins/dialog'
import UserAvatar from '@/components/users/UserAvatar'
import ConferenceDescription from '@/components/conferences/ConferenceDescription'
import UserSearchDialog from '@/components/users/UserSearchDialog'

const ConferenceTypes = ['public', 'premium', 'exclusive']

export default {
  name: 'ConferenceForm',
  mixins: [validatable('form'), dialog],
  components: { ConferenceDescription, UserAvatar, UserSearchDialog },
  props: {
    showWebcam: { type: Boolean, default: true }
  },
  data () {
    return {
      currentConference: null,
      title: '',
      type: '',
      selectedUser: '',
      minutePrice: 10,
      freeFans: false,
      showDates: false,
      startDate: null,
      startTime: null,
      camSuccess: false,
      camError: false,
      noCam: false
    }
  },
  computed: {
    visibilityOptions () {
      return ConferenceTypes.map(value => ({ text: this.$t(`models.conference.types.${value}`), value }))
    },
    visibilityDescription () {
      return this.type === ''
        ? ''
        : this.$t(`models.conference.type_description.${this.type}`)
    },
    isExclusive () {
      return this.type === 'exclusive'
    },
    isPremium () {
      return this.type === 'premium'
    },
    isPayment () {
      return this.isExclusive || this.isPremium
    },

    submitable () {
      return this.validForm && (!this.isExclusive || !!this.selectedUser)
    },
    webcamColorClass () {
      return this.camSuccess ? 'success--text' : 'indigo--text'
    },
    webcamFeedbackMessage () {
      const key = this.camSuccess ? 'video_success'
        : this.camError ? 'video_error'
          : this.noCam ? 'no_webcam_access'
            : 'video_check'
      return this.$t(`conferences.${key}`)
    },
    startsAt () {
      if (!this.startDate || !this.startTime) {
        return null
      }
      return new Date(`${this.startDate}T${this.startTime}`)
    }
  },

  methods: {

    async fetchCurrent () {
      const { data, error } = await ConferenceApi.findCurrent()
      if (!error && data) {
        this.currentConference = data
      }
    },

    async onSubmit () {
      const { data, error } = await ConferenceApi.create({
        type: this.type,
        title: this.title,
        freeFans: this.freeFans,
        minutePrice: this.minutePrice,
        selectedUser: (this.selectedUser && this.selectedUser.username) || null,
        startsAt: this.startsAt
      })
      if (!error) {
        this.$router.push({ name: 'conference', params: { uuid: data.id } })
        this.close('submit', data)
      } else {
        // TODO Mostrar error
      }
    },
    checkWebcam () {
      if (!this.showWebcam) {
        return
      }
      var container = document.querySelector('#webcam')
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            container.srcObject = stream
            this.camSuccess = true
            this.stream = stream
          })
          .catch(() => {
            this.camError = true
          })
      } else {
        this.noCam = true
      }
    },

    close (event, value) {
      this.$emit(event, value)
    }
  },

  mounted () {
    this.fetchCurrent()
    this.checkWebcam()
  },

  beforeDestroy () {
    if (this.stream) {
      this.stream.getTracks().forEach(s => s.stop())
    }
  }
}
</script>
<style lang="scss" scoped>
.breack {
  flex-basis: 100%;
  height: 0px;
}
.label {
  width: 120px;
  i {
    margin-right: 5px;
  }
}

  .top-right {
    position: absolute;
    top: 18px;
    right: 20px;
  }
  .center-absolute {
    position: absolute;
    top: 40%;
    left: 10%;
    right: 10%;
    z-index: 100;
  }
  .dark {
    border: 1px groove gray;
  }
.live-view {
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 16px;
  background: #f5f5f5;
  height: 90%;
  width: 95%;
}

.webcam-feedback {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &-message {
    background-color: rgba(0,0,0, 0.4);
    padding: 16px;
    margin: 24px;
  }
}

@keyframes rotate {
  from {
    transform: scale(0.4, 0.4);
    -o-transform: scale(0.4, 0.4);
    -ms-transform: scale(0.4, 0.4);
    -moz-transform: scale(0.4, 0.4);
    -webkit-transform: scale(0.4, 0.4);
  }
  to {
    transform: scale(1,1);
    -o-transform: scale(1,1);
    -ms-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -webkit-transform: scale(1,1);
  }
}
.live-animation {
  animation: rotate 1s ease infinite;
}
</style>
