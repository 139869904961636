<template>
  <c-dialog persistent
    width="684"
    :actions="false"
    :fullscreen="false"
    :value="value"
    @input="close"
    @close="close">
    <v-list>
      <v-list-item-group
        color="primary"
        :value="$i18n.locale">
        <v-list-item
          v-for="locale in locales"
          :key="`select-${locale}`"
          @click="updateLocale(locale)"
          :value="locale"
        >
          <v-list-item-content>
          <span>{{ $t(`langs.names.${locale}`) }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </c-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'

export default {
  name: 'LocaleDialog',
  props: {
    value: Boolean,
  },
  data () {
    return {
      locales: ['en', 'es', 'de', 'fr', 'it', 'pt']
    }
  },

  computed: {
    ...mapState('session', ['authenticated'])
  },

  methods: {
    updateLocale (value) {
      this.$i18n.loadLocale(value)
      this.$timeago.locale = value
      this.$vuetify.lang.current = value
      if (this.authenticated) {
        this.$store.dispatch(ProfileActions.UpdateAccountData, { lang: value })
      }
      this.close()
    },
    close () {
      this.$emit('input', false)
    }
  }
}
</script>
