<template>
  <c-dialog
    width="684"
    v-model="value">
    <template slot="title">
      <v-icon color="success">check_circle_outline</v-icon>
      <span class="title-text-2 semibold">{{ $t(`payments.successful`)}}</span>
    </template>
    <component
      :is="paymentComponent"
      :id="resourceId"
      :payment="payment"
      @close="$emit('close')" />

  </c-dialog>
</template>
<script>
import PostInfo from '@/components/payments/dialogs/PostInfo'
import ProductInfo from '@/components/payments/dialogs/ProductInfo'
import SubscriptionInfo from '@/components/payments/dialogs/SubscriptionInfo'
import WalletInfo from '@/components/payments/dialogs/WalletInfo'
import CartInfo from '@/components/payments/dialogs/CartInfo'

export default {
  name: 'SuccessPaymentDialog',
  components: { PostInfo, ProductInfo, SubscriptionInfo, WalletInfo, CartInfo },
  props: {
    value: Boolean,
    payment: Object,
    resource: String,
    resourceId: [String, Number]
  },
  data () {
    return {
      components: {
        posts: 'PostInfo',
        products: 'ProductInfo',
        subscriptions: 'SubscriptionInfo',
        wallets: 'WalletInfo',
        carts: 'CartInfo'
      }
    }
  },

  computed: {
    paymentComponent () {
      return this.components[this.resource]
    }
  }
}
</script>
