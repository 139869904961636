<template>
  <section>
    <Paginations
      :loading="loading"
      :items="items"
      :perPage="perPage"
      :totalItems="totalItems"
      @pageSelected="pageSelected"
      :grid="true"
    >
      <template #default="{ item,index }">
        <div>
          <ListVaultRow
            :row="item"
            :index="index"
            :classSelect="classSelect"
            :withCheck="withCheck"
            :onDelete="onDelete"
            @delete-file="deleteFile"
            @view-file="selectedResource"
          ></ListVaultRow>
        </div>
      </template>
    </Paginations>
  </section>
</template>

<script>
import Paginations from "@/components/chat/component/Paginations";
import table from "@/components/mixins/table";
import MediaFileApi from "@/api/conversations/MediaFileApi";
import ListVaultRow from "@/components/chat/components/vault/ListVaultRow";
import { ChatActions, AppActions } from "@/store";

export default {
  name: "ListVault",
  mixins: [table(MediaFileApi, "listType")],
  components: { Paginations, ListVaultRow },
  props: {
    type: { type: String, default: "video" },
    withCheck: { type: Boolean, default: false },
    classSelect: { type: Boolean, default: false },
    username: { type: String },
    onDelete: { type: Boolean, default: false }
  },
  data() {
    return {
      perPage: 12,
      query: {
        mediaType: "video",
        sortable: true,
        order: "date:desc",
        username: this.username
      }
    };
  },
  watch: {
    type() {
      this.query.mediaType = this.type;
      this.items = [];
      this.totalItems = 0;
      this.page = 1;
      this.loadItems();
    }
  },
  methods: {
    pageSelected(pag) {
      this.page = pag;
    },
    selectedResource(item, index) {
      if (this.withCheck) {
        this.$store.dispatch(ChatActions.SetAttachmentForm, item);
        return;
      }
      if (this.query.mediaType !== "audio") {
        this.$store.dispatch(ChatActions.CarouselItemsList, this.items);
        this.$store.dispatch(ChatActions.CarouselItemSelected, index);
        this.$store.dispatch(ChatActions.ShowCarousel, true);
      }
    },
    async deleteFile({ id }) {
      const { data, error } = await MediaFileApi.delete(id);
      if (!error) {
        this.$store.dispatch(AppActions.SetNotification, { type: "success" });
        setTimeout(() => {
          this.deleteFilesView(id);
          this.loadItems()
        }, 10);
      } else {
        this.$store.dispatch(AppActions.SetNotification, {
          type: "error",
          error: data
        });
      }
      this.$store.dispatch(AppActions.ShowNotification, true);
    },
    deleteFilesView(id) {
      const index = this.items.findIndex(f => f.id === id);
      if (index !== -1) this.items.splice(index, 1);
    }
  }
};
</script>
