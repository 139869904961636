<template>
  <v-form ref="form" v-model="validForm" >

    <h3 class="mb-2">{{ $t('withdraws.withdraw_methods.types.btc') }}</h3>
    <v-text-field
      outlined
      :label="$t('models.withdraw_method.btc_account')"
      type="text"
      v-model="accountId"
      :rules="[$vRequired]"/>
    <!--      <v-row justify="end">
            <v-col cols="auto">
              <c-btn
                text color="secondary"
                @click="cancel">
                {{ $t('actions.cancel')}}
              </c-btn>
            </v-col>
            <v-col cols="auto">
              <c-btn
                :disabled="!validForm"
                @click="submit">
                {{ $t('actions.save')}}
              </c-btn>
            </v-col>
          </v-row>-->

  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'BtcMethodForm',
  mixins: [validatable('form')],
  props: {
    method: {
      type: Object,
      default: null
    },
    dark: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      currency: null,
      currencyOptions: ['EUR', 'USD'],
      accountId: null
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    methodAttrs () {
      return {
        type: 'btc',
        accountId: this.accountId,
        withdrawMethodApproved: true
      }
    }
  },
  watch: {
    methodAttrs () {
      this.$emit('methodAttrs', this.methodAttrs)
    },
    validForm () {
      this.$emit('validForm', this.validForm)
    }
  },

  mounted () {
    if (!this.method) {
      return
    }
    this.accountId = this.method.accountId
    this.currency = this.method.currency
  }
}
</script>
