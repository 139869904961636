'user strict'

import api from '@/api/Api'

export default {

  create (attrs) {
    return api.post('/tips', attrs)
  }
}
