<template>
  <v-footer class="app-footer" :class="themeClasses" :style="cssFixStyle">
    <v-row no-gutters justify="space-between" style="margin-top: 10px;">
      <v-col cols="1" v-if="rrss">
        <!--<a href="http://www.facebok.com" class="item link semi-opaque">
          <img :src="themeBrand('facebook')" />
        </a>-->
        <a :href="twitter" class="item link semi-opaque">
          <img :src="themeBrand('twitter')"/>
        </a>
        <a :href="instagram" class="item link semi-opaque" v-if="!isScatbook && !isDarkfans">
          <img :src="themeBrand('instagram')"/>
        </a>
        <!--  <a href="http://www.snapchat.com" class="item link semi-opaque">
          <img :src="themeBrand('snapchat')" />
        </a> -->
      </v-col>
      <v-spacer v-if="!isMobile"></v-spacer>
      <v-col :cols="rrss?10:12">
        <v-row justify="center" class="link" style="font-weight: bold">
          <router-link class="item link semi-opaque" to="/faq">
            {{ $t('application.menu.faq') }}
          </router-link>
          <router-link class="item link semi-opaque" to="/cookies">
            {{ $t('application.menu.cookies') }}
          </router-link>
          <a
            class="item link semi-opaque"
            :href="`mailto:${$t('contact_email')}`"
          >
            {{ $t('application.menu.contact') }}
          </a>
          <router-link class="item link semi-opaque" to="/conditions">
            {{ $t('application.menu.terms_conditions') }}
          </router-link>
          <router-link class="item link semi-opaque" to="/contract-conditions">
            {{ $t('application.menu.contract_conditions') }}
          </router-link>
          <router-link class="item link semi-opaque" to="/privacy">
            {{ $t('application.menu.privacy') }}
          </router-link>
          <router-link class="item link semi-opaque" to="/complaint_2257">
            USC 2257
          </router-link>
          <router-link class="item link semi-opaque" to="/dmca">
            {{ `DMCA Notice & Takedown Policy and Procedures` }}
          </router-link>
          <router-link class="item link semi-opaque" to="/compliance-policy">
            {{ `Complaints & Content Removal` }}
          </router-link>
<!--          <router-link class="item link semi-opaque" to="/contract-conditions">
            {{ `Contract Conditions` }}
          </router-link>-->

        </v-row>
        <v-row justify="center">
          <img src="/images/artemisa_white.png" class="img-company" v-if="project.showFooterImages"/>
          <span style="margin-right: 5px;" v-else>Artemisa 3000 Tech Solutions SL</span>
          CIF:<img
          :src="`/images/cif_white.png`"
          style=""
          class="img-cif"
          v-if="project.showFooterImages"
        />
          <span v-else> B88466743 </span>
          <span class="item text-center">
            {{ $t('application.company_address') }},
            {{ $t('application.company_city') }},
            {{ $t('application.company_country') }}
          </span>
        </v-row>
        <v-row v-if="isLoverfans" class="item" justify="center">
          <a href="https://www.commercegate.com/" target="_blank"
          >CommerceGate:</a
          >
          <span class="space-link">
            {{ $t('application.commercegate_1') }}
          </span>
          <a href="https://www.cgbilling.com/secure" target="_blank">
            {{ $t('application.commercegate_2') }}
          </a>
        </v-row>
        <v-row justify="center">
          <span class="item">{{ $t('application.contact_email') }}</span>
        </v-row>
      </v-col>
      <v-spacer v-if="!isMobile"></v-spacer>
      <v-col v-if="lang">
        <div class="d-flex flex-row-reverse">
          <LocaleSelect
            :background-color="localeSelectBackground"
            class="link"
            dense
          />
        </div>
      </v-col>
    </v-row>
    <div class="line-separator"/>

    <div class="py-1 center logocard" :class="{mobile:isMobile}">
      <!--:class="isMobile?'center':'justify-space-between'"-->
      <img height="25" src="/images/mastercard.svg">
      <img height="20" src="/images/visa.svg">
      <!--      <p v-if="!isMobile" class="text-center">{{ year }} © {{ domain }} · {{ $t('footer.reserved') }}</p>
            <div>
              <img height="24" src="/images/mastercard.png" style="object-fit: contain;">
              <img height="24" src="/images/visa.jpg" style="object-fit: contain;">
            </div>
            <p v-if="isMobile" class="text-center">{{ year }} © {{ domain }} · {{ $t('footer.reserved') }}</p>-->
    </div>
  </v-footer>
</template>
<script>
import project from '@/project'
import { mapState } from 'vuex'

export default {
  name: 'AppFooter',
  props: {
    theme: String,
    rrss: {
      type: Boolean,
      default: true
    },
    lang: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const twitter = project.twitter
      ? `http://www.twitter.com/${project.twitter}`
      : 'http://www.twitter.com/'
    const instagram = project.twitter
      ? `http://www.instagram.com/${project.instagram}`
      : 'http://www.instagam.com/'
    return {
      showExtendedFooter: project.show_footer,
      showImprint: project.show_imprint,
      twitter,
      instagram,
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    isDark () {
      return this.theme !== 'white'
    },

    isScatbook () {
      return project.project === 'scatbook'
    },
    isDarkfans () {
      return project.project === 'darkfans'
    },

    isLoverfans () {
      return project.project === 'loverfans'
    },

    themeClasses () {
      return {
        dark: this.isDark,
      }
    },

    localeSelectBackground () {
      return this.isDark ? 'rgb(153,153,153)' : ''
    },

    cssFixStyle () {
      if (!this.$router.currentRoute.path.startsWith('/promos/')) {
        return
      }
      return {
        'margin-bottom': !this.isMobile ? '40px !important' : '70px !important',
        'height': !this.isMobile ? '' : '300px !important',
        'padding-top': '0px'
      }
    },
    domain () {
      return project.locales.site
    },
    year () {
      return new Date().getFullYear()
    }
  },
  methods: {
    themeBrand (brand) {
      const theme = this.isDark ? '_white' : ''
      return `/images/${brand}${theme}.svg`
    }
  }
}
</script>
<style lang="scss">
@use 'src/scss/colors';

.app-footer {
  .row {
    width: 100%;
    color: #5e5e5e;
  }

  .item {
    font-size: 12px;
    line-height: 24px;
    margin: 0 5px;
  }

  .link {
    color: #5e5e5e;
    text-decoration: none;

    &:hover {
      color: #2f2b2b;
      opacity: 1;
    }
  }

  &.dark {
    background-color: rgb(153, 153, 153) !important;

    .row {
      color: #f2f2f2;
    }

    .link {
      color: #f2f2f2;

      &:hover {
        color: #2f2b2b;
      }
    }
  }

  .space-link {
    margin: 0 5px;
  }
}

.logocard {
  display: flex;
  flex-direction: row;
  margin: auto;

  img {
    margin: 10px;
    object-fit: contain
  }

  &.mobile {
    display: flex;
    flex-direction: column
  }
}

.img-company {
  margin-top: 5px;
  margin-right: 5px;
  width: 12rem;
  height: 11px;
  opacity: 0.65;
}

.img-cif {
  margin-top: 5px;
  margin-left: 2px;
  width: 5rem;
  height: 10px;
  opacity: 0.65;
}

.line-separator {
  width: 100%;
  border: 1px solid #8D8D8D;
}
</style>
