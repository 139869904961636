<template>
  <vue-countdown-timer class="countdown"
    :class="{ mobile: isMobile }"
    start-time="2019-10-10 00:00:00"
    end-time="2020-10-06 00:00:00"
    :interval="1000"
    label-position="begin"
    day-txt="days"
    hour-txt="hours"
    minutes-txt="minutes"
    seconds-txt="seconds">
    <template #countdown="{ props }" >
      <v-row  :no-gutters="banner"
        align="center"
        class="timer"
        :class="{ banner: banner, mobile : isMobile}">
        <v-col cols="4" class="number" :style="timerSize">{{ props.days}}</v-col>
        <v-col cols="4" class="number" :style="timerSize">{{ props.hours}}</v-col>
        <v-col cols="4" class="number" :style="timerSize">{{ props.minutes}}</v-col>
      </v-row>
      <v-row  :no-gutters="banner"
        align="center"
        class="counter"
        :class="{ banner: banner, mobile : isMobile}">
        <v-col cols="4" class="cont-text" :style="contSize">{{ props.days === 1 ? $t('types.date.day') : $t('types.date.days') }}</v-col>
        <v-col cols="4" class="cont-text" :style="contSize">{{ props.hours === 1 ? $t('types.date.hour') : $t('types.date.hours') }}</v-col>
        <v-col cols="4" class="cont-text" :style="contSize">{{ props.minutes === 1 ? $t('types.date.minute') : $t('types.date.minutes') }}</v-col>
      </v-row>
    </template>
    <template #end-text="{ props }">
      <v-row  :no-gutters="banner"
        class="timer"
        :class="{ banner: banner, mobile : isMobile}">
        <v-col cols="4" class="number" :style="timerSize">0</v-col>
        <v-col cols="4" class="number" :style="timerSize">0</v-col>
        <v-col cols="4" class="number" :style="timerSize">0</v-col>
      </v-row>
      <v-row  :no-gutters="banner"
        class="counter"
        :class="{ banner: banner, mobile : isMobile}">
        <v-col cols="4" class="cont-text" :style="contSize">{{ props.days === 1 ? $t('types.date.day') : $t('types.date.days') }}</v-col>
        <v-col cols="4" class="cont-text" :style="contSize">{{ props.hours === 1 ? $t('types.date.hour') : $t('types.date.hours') }}</v-col>
        <v-col cols="4" class="cont-text" :style="contSize">{{ props.minutes === 1 ? $t('types.date.minute') : $t('types.date.minutes') }}</v-col>
      </v-row>
    </template>
  </vue-countdown-timer>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'PromotionCounter',
  props: {
    banner: { type: Boolean, default: false }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    timerSize () {
      return {
        fontSize: this.banner ? '60px' : '127px'
      }
    },
    contSize () {
      return {
        fontSize: this.banner ? '20px' : '36px'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.countdown {
  .timer {
    height: 162px;
    font-family: AllertaStencil;
    //font-size: 127px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #088a7c;

    &.banner {
      line-height: 40px !important;
    }

  }

  .counter {
    height: -webkit-fill-available;
    font-family: Cabin;
    font-size: 36px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: #5d5d5d;
    text-align: center;
    text-transform: uppercase;
    &.banner {
      line-height: 40px !important;
    }
  }

  .number {
    font-family: AllertaStencil;
    //font-size: 127px !important;
    //line-height: 162px;
  }

  .cont-text {
    font-family: Cabin;
    //font-size: 36px !important;
    line-height: 44px !important;
  }
  &.mobile {
    .timer {
      line-height: 90px;
      margin: auto;
      .banner {
        height : 50px;
        line-height: 25px !important;
      }
    }

    .counter {
      font-size: 20px;
    }
    .number {
      font-size: 45px !important;
      line-height: 50px;
    }
  }
}
</style>
