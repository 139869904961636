import Utils from '@/utils'

Object.defineProperty(HTMLCanvasElement.prototype, 'toFile', {
  value (type = 'image/jpeg', options) {
    const binStr = atob(this.toDataURL(type, options.quality).split(',')[1])
    const len = binStr.length
    const arr = new Uint8Array(len)

    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i)
    }
    const blob = new Blob([arr], { type })
    const ext = type.split('/')[1]
    return new File([blob], `${Utils.extractFilename(options.name)}.${ext}`, {
      type, lastModified: new Date()
    })
  }
})
