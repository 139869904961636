<template>
  <section>
    <div class="my-2">
      {{ $t('subscriptions.trials.info') }}
      <c-btn x-small
        class="ml-2 mb-1"
        @click="emitConfig">
        {{ $t('actions.add') }}
      </c-btn>
    </div>
    <v-chip  v-for="trial in mappedTrials"
      :key="trial.expiration"
      @click="showInfo(trial)"
      outlined
      color="primary"
      class="mr-2 mb-1">
        {{ trial.description }}
    </v-chip>
    <TrialInfoDialog v-if="selectedTrial" />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import TrialInfoDialog from './TrialInfoDialog'
import messages from '../locales/all.json'

export default {
  name: 'TrialInfo',
  components: { TrialInfoDialog },
  i18n: { messages },
  computed: {
    ...mapState('profile', ['trials', 'selectedTrial']),
    mappedTrials () {
      return this.trials.map(trial => {
        const users = `${trial.currentFans}/${trial.maxFans}`
        const expires = this.$date(trial.expiresAt)
        return {
          ...trial,
          description: this.$t('subscriptions.trials.short_info', { users, expires })
        }
      })
    }
  },
  methods: {
    showInfo (trial) {
      this.$store.dispatch(ProfileActions.SelectTrial, trial)
    },
    emitConfig () {
      this.$emit('open-config', 'trials')
    }
  }
}
</script>
