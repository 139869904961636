<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :height="`${size}px`" :width="`${size}px`">
    <path :fill="color" id="votari-inactivo-2"
          d="M13.6-.85a4.047,4.047,0,0,1,2.444.734,4.242,4.242,0,0,1,1.5,3.625,6.415,6.415,0,0,1-.958,3.165l-.1.18h3.256a3.409,3.409,0,0,1,3.4,3.333,4.02,4.02,0,0,1-.752,2.329,4.5,4.5,0,0,1-.446,3.453,4.336,4.336,0,0,1-.726,3.351,3,3,0,0,1-.468,2.187c-1.143,1.642-3.632,1.642-5.632,1.642H15a13.226,13.226,0,0,1-5.556-1.457,6.64,6.64,0,0,0-2-.64,1.324,1.324,0,0,1-1.108-.646v.9A1.846,1.846,0,0,1,4.5,23.15H.994A1.846,1.846,0,0,1-.85,21.306V10.8A1.846,1.846,0,0,1,.994,8.955H4.5A1.847,1.847,0,0,1,6.19,10.071a1.326,1.326,0,0,1,.355-.632,16.935,16.935,0,0,0,2.07-2.613,15.789,15.789,0,0,1,1.827-2.335,4.706,4.706,0,0,0,.9-2.2l0-.017a9.817,9.817,0,0,1,.533-1.7C12.3-.373,12.882-.85,13.6-.85Zm1.525,22.412c1.544,0,3.659,0,4.329-.961a1.555,1.555,0,0,0,.15-1.312l-.1-.423.3-.314a2.692,2.692,0,0,0,.538-2.471l-.132-.395.25-.333a2.857,2.857,0,0,0,.311-2.695l-.207-.492.382-.382a2.371,2.371,0,0,0,.619-1.589,1.815,1.815,0,0,0-1.813-1.753H14.272l.256-.992a6.951,6.951,0,0,1,.66-1.522,4.885,4.885,0,0,0,.772-2.419c0-1.851-.76-2.758-2.323-2.772a1.609,1.609,0,0,0-.382.661,9.742,9.742,0,0,0-.362,1.243l0,.017a5.966,5.966,0,0,1-1.322,2.954A14.6,14.6,0,0,0,9.925,7.724a18.551,18.551,0,0,1-2.183,2.764v8.994a8.4,8.4,0,0,1,2.349.76A11.911,11.911,0,0,0,15,21.562Zm-10.63,0a.257.257,0,0,0,.257-.257V10.8a.257.257,0,0,0-.257-.257H.994a.257.257,0,0,0-.257.257V21.306a.257.257,0,0,0,.257.257ZM2.745,17.71A1.844,1.844,0,1,1,.9,19.555,1.846,1.846,0,0,1,2.745,17.71Zm0,2.1a.257.257,0,1,0-.257-.257A.257.257,0,0,0,2.745,19.811Z"
          transform="translate(0.85 0.85)" />
  </svg>
</template>
<script>
export default {
  name: 'Like',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
