<template>
  <v-form class="p-5">
    <h3 class="mb-2">{{ $t('models.complaint.reason_question_video') }}</h3>
    <v-text-field
      outlined
      v-model="comment"
      :label="$t('models.complaint.comment')"
      :rules="[$vRequired]">
    </v-text-field>
    <div class="row center " style="margin: 10px">
      <Buttons
        style="font-size: 14px; width: 150px;"
        :text="$t('actions.report').toUpperCase()"
        @handler="report"
        :disabled="!comment"
        :loading="loading"
      />
    </div>
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import PostApi from '@/api/admin/PostApi'
import ComplaintApi from '@/api/ComplaintApi'
import Buttons from '@/components/chat/component/Buttons.vue'

export default {
  name: 'ReportForm',
  components: { Buttons },
  data () {
    return {
      comment: '',
      loading: false
    }
  },
  computed: {
    ...mapState('chats', ['selectedChat']),
    ...mapState('profile', ['isAdmin', 'isAccountManager', 'isContentManager', 'isController'])
  },
  methods: {
    async reject () {
      await PostApi.update({
        id: this.id,
        status: 'rejected',
        rejection: {
          reason: 'not_allowed',
          detail: this.$t('posts.approval.rejection.reasons.not_allowed')
        }
      })
      this.showMenu = false
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.report_success')
      })
    },

    async report () {
      this.loading = true
      await ComplaintApi.create({
        resource: 'videocall',
        resourceId: this.selectedChat.id,
        comment: this.comment
      })
      this.loading = false
      this.$emit('close')
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.report_success')
      })
    },

    remove () {
      this.showMenu = false
      this.$emit('remove-item')
    }
  }
}
</script>
<style lang="scss" scoped>
.transparent-background {
  background-color: transparent !important;
}
</style>
