<template>
  <section>
    <div
      class="col-12 border-bottom py-3"
      style="text-align: center;display: flex;"
    >
      <icon-button type="previous" class="icons" :size="22" @handler="backToMenu"/>
      <button
        v-if="isInfluencer || isAdmin || isAccountManager"
        class="chat-buttons"
        :class="{ selected: message }"
        type="button"
        @click="newMessage()"
      >
        {{ $t('chats.new_message') }}
      </button>
      <span v-else class="chat-buttons" :class="{ selected: message }" style="margin: auto">
        {{ $t('chats.new_message') }}
      </span>
      <button
        v-if="isInfluencer || isAdmin || isAccountManager"
        :class="{ selected: broadcastMessage }"
        class="chat-buttons"
        type="button"
        @click="newBroadcast()"
      >
        {{ $t('chats.new_broadcast.title') }}
      </button>
    </div>

    <NewChat v-if="message"></NewChat>
    <BroadcastMessageMenu v-if="broadcastMessage"></BroadcastMessageMenu>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import UserApi from '@/api/UserApi'
import NewChat from '@/components/chat/components/newChat/NewChat'
import IconButton from '@/components/chat/component/IconButton'
import BroadcastMessageMenu from '@/components/chat/components/newChat/BroadcastMessageMenu'

export default {
  name: 'ChatNew',
  components: { NewChat, BroadcastMessageMenu, IconButton },
  data () {
    return {
      message: false,
      broadcastMessage: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer', 'isAdmin', 'isAccountManager']),
     darkTheme(){
      return this.$vuetify.theme.dark
    },
  },
  created () {
    if (!this.isInfluencer || !this.isAdmin || !this.isAccountManager) {
      this.newMessage()
    }
  },
  methods: {
    newMessage () {
      this.message = !this.message
      this.broadcastMessage = false
    },
    newBroadcast () {
      this.broadcastMessage = !this.broadcastMessage
      this.message = false
    },
    onSubmit () {
      if (!this.search) {
        return
      }
      this.$router.push({ name: 'search', query: { search: this.search } })
    },
    backToMenu () {
      this.$emit('back')
    }
  },
  async mounted () {
    const that = this
    that.users = []
    const { data, error } = await UserApi.list({ username: this.search })
    if (!error) {
      data.data.forEach(user => {
        that.users.push({ name: user.username, avatar: user.avatarS3Route })
      })
    }
    return that.users
  }
}
</script>

<style lang="scss" scoped>
.search-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  height: 37px;

  input {
    width: 100%;
    display: inline-block;
    border: 1px solid #d7d7d7;
    height: 40px;
    padding: 7px 15px;
    margin: 4px;
    font-size: 0.9375em;
    border-radius: 30px;
  }
}

.chat-buttons {
  text-align: center;
  font-size: 14px;
  font-family: Quicksand, sans-serif;
  background-color: transparent;
  width: 50%;
}
.selected {
  font-weight: bold;
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: '';
  height: 40px;
  width: 40px;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.center {
  justify-content: center;
  align-content: center;
}
.icons {
  display: flex;
  margin-top: 5px;
  text-align: center;
}
</style>
