<template>
    <section class="d-flex flex-column">
    <div class="title-row border-bottom pa-2">
      {{ $t('comments.reviews') }}
    </div>
    <v-form ref="form"
      v-model="validForm"
      @submit.stop.prevent="submit">
      <EmojiInput v-if="authenticated"
        solo flat
        v-model="comment"
        max-length="500"
        :placeholder="$t('comments.review_placeholder')">
        <c-btn icon
          :disabled="!comment || !validForm"
          @click="submit">
          <v-icon>send</v-icon>
        </c-btn>
      </EmojiInput>
    </v-form>
    <div class="comments-container border-top my-4">
      <CommentRow v-for="comment in items"
        :key="`comment-${comment.id}`"
        class="border-bottom py-2"
        :comment="comment"
        :author="product.username"
         @delete="deleteComment(comment.id)"/>
      <div v-if="totalPages > 1" class="my-2">
        <v-pagination
          v-model="page"
          :length="totalPages"
        ></v-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import ProductApi from '@/api/ProductApi'
import validatable from '@/components/mixins/validatable'
import table from '@/components/mixins/table'
import CommentRow from '@/components/comments/CommentRow'

export default {
  name: 'ProductComments',
  mixins: [validatable('form'), table(ProductApi, 'listComments')],
  components: { CommentRow },
  props: {
    product: Object
  },
  data() {
    return { comment: '' }
  },
  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('profile', ['currentUser']),
    query () {
      return { productId: this.product.id, order: 'date:desc' }
    }
  },
  methods: {
    async onSubmit() {
      const { error } = await ProductApi.sendComment(this.product.id, {
        productId: this.product.id, comment: this.comment
      })
      if (!error) {
        this.items.splice(0, 0, {
          id: new Date().getTime(),
          username: this.currentUser.username,
          avatarS3Route: this.currentUser.avatarS3Route,
          comment: this.comment,
          date: new Date(),
        })
        this.comment = ''
      }
    },

    async deleteComment(id) {
      const { error } = await ProductApi.deleteComment(this.product.id, { id })

      if (!error) {
        const index = this.items.findIndex((c) => c.id === id)
        this.items.splice(index, 1)
      }
    }
  }
}
</script>
