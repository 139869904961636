<template>
  <section>
    <div
      v-if="message.messageType === 'notification'"
      style="margin-bottom: 10px;"
    >
      <NotificationMessage
        :message="message"
        @deleteNotif="deleteMessage"
        :notification="message.payload"
        :username="selectedChat.receiver.username"
        :messageId="message.id"
        :user="user"
      />
    </div>
    <div class="d-flex px-3 py-1" :class="rowClass" v-else>
      <div
        class="ma-2 px-2 py-2 regular-text light-text rounded elevation-1"
        :class="messageClass"
      >
        <AttachView
          :message="message"
          v-if="hasReference"
          @resource-loaded="emitLoaded"
          @refresh-message="emitRefreshMesage"
        />
        <!-- <MessageRowResource
          v-if="hasReference"
          :message="message"
          @resource-loaded="emitLoaded"
          @refresh-message="emitRefreshMesage"
        /> -->
        <component :is="showMessage" />
        <InfoDateMessageRow
          :message="message"
          @successDelete="deleteMessage"
          :statusIsRead="message.isRead"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
import MessageRowResource from "./MessageRowResource";
import project from "@/project";
import InfoDateMessageRow from "./InfoDateMessageRow";
import Carousel from "@/components/chat/component/Carousel";
import NotificationMessage from "@/components/chat/component/NotificationMessage";
import WarningDialog from "../component/WarningDialog";
import AttachView from "@/components/chat/components/attachView/AttachView";

import { replaceMention } from "@/components/scripts/ReplaceMentions.js";
import UserApi from "@/api/UserApi";

export default {
  name: "MessageRow",
  components: {
    InfoDateMessageRow,
    MessageRowResource,
    WarningDialog,
    NotificationMessage,
    Carousel,
    AttachView
  },
  props: {
    message: { type: Object }
    /* markText: { type: String, default: '' } */
  },
  data() {
    return {
      avatarSize: 32,
      showCarousel: false,
      resources: [],
      showClearMessage: false,
      user: null
    };
  },
  computed: {
    ...mapState("profile", ["currentUser"]),
    ...mapState("chats", ["selectedChat"]),
    darkTheme() {
      return this.$vuetify.theme.dark;
    },
    isAuthor() {
      return this.currentUser.id === this.message.userId;
    },
    isVisible() {
      return this.isAuthor || this.message.visible;
    },

    rowClass() {
      return { "flex-row-reverse": this.isAuthor };
    },

    hasReference() {
      return this.message.attachments.length > 0;
    },

    messageText() {
      return this.message.message;
    },
    messageClass() {
      return {
        sender: this.isAuthor,
        receiver: !this.isAuthor,
        dark: this.darkTheme,
        multimedia: this.hasReference
      };
    },

    showMessage() {
      const comment = replaceMention(
        this.messageText,
        project.project,
        this.message.username
      );
      return { template: `<div class="chatMessage" >${comment}</div>` };
    }
  },
  methods: {
    closeClearMessage() {
      this.showClearMessage = !this.showClearMessage;
    },
    emitLoaded() {
      this.$emit("resource-loaded");
    },
    emitRefreshMesage(message) {
      this.$emit("refresh-message", message);
    },
    deleteMessage(message) {
      this.$emit("delete-message", message);
    },
    deleteNotifTemp(message) {
      this.$emit("delete-temp-notif", message);
    },
    markSearchResult(message) {
      const aux = [];
      //const newMark = document.createElement('div')
      const split = message.split(" ");
      split.map(p => {
        if (p.includes(this.markText) || p === this.markText) {
          aux.push(
            `${p.replace(this.markText, "<mark>" + this.markText + "</mark>")}`
          );
        } else {
          aux.push(p);
        }
      });
      return `<div class="chatMessage">${aux.join(" ")}</div>`;
    }
  },
  async beforeMount() {
    if (this.message.payload.code === 'influencerMessage') {
      const {data, error} = await UserApi.find(this.selectedChat.receiver.username)
      if (!error) {
        this.user = data
      }
    }
  }
};
</script>
<style lang="scss">
@use 'src/scss/colors.scss';

.receiver {
  background-color: colors.$white-two;
  max-width: 80%;
  text-align: left;
  height: auto;
  word-wrap: break-word;
  line-height: 1.2em;
  background: #faffe8;
  &.dark {
    background-color: #005d4b !important;
    border: 1px groove #d4cbcb !important;
    color: white !important;
  }
}
.sender {
  background-color: colors.$white-three;
  max-width: 80%;
  text-align: right;
  height: auto;
  word-wrap: break-word;
  background: #dfe3e9;
  &.dark {
    background-color: #1f2c34 !important;
    border: 1px groove #d4cbcb !important;
    color: white !important;
  }
}

.replaceMessage {
  font-family: "Roboto", sans-serif;
}

.chatMessage {
  color: #3a3a3a !important;
  max-width: 350px;
}
.mark {
  p {
    background-color: blue;
    color: white;
    /* font-: #ff0 !important; */
    color: #3a3a3a !important;
  }
}
</style>
