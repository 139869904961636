export default {
  data: [
    {
      name: 'user',
      src: '/chat/ico-profile.svg'
    },
    {
      name: 'settings',
      src: '/chat/ico-settings.svg'
    },
    {
      name: 'close',
      src: '/chat/ico-close.svg'
    },
    {
      name: 'delete',
      src: '/chat/ico-delete.svg'
    },
    {
      name: 'mute',
      src: '/chat/ico-mute.svg'
    },
    {
      name: 'block',
      src: '/chat/ico-block.svg'
    },
    {
      name: 'multi',
      src: '/chat/ico-multi.svg'
    },
    {
      name: 'unread',
      src: '/chat/ico-unread.svg'
    },
    {
      name: 'microf',
      src: '/chat/ico-microf.svg'
    },
    {
      name: 'cash',
      src: '/chat/ico-cash.svg'
    },
    {
      name: 'plus',
      src: '/chat/ico-plus.svg'
    },
    {
      name: 'emojis',
      src: '/chat/smile.svg'
    },
    {
      name: 'stop-record',
      src: '/chat/ico-stop-record.svg'
    },
    {
      name: 'attach',
      src: '/chat/ico-attach-file.svg'
    },
    {
      name: 'plus',
      src: '/chat/ico-plus.svg'
    },
    {
      name: 'newMessage',
      src: '/chat/icono-nuevomensaje.svg'
    },
    {
      name: 'schedule',
      src: '/chat/ico-calendario.svg'
    },
    {
      name: 'upload',
      src: '/chat/ico-upload-new.svg'
    },
    {
      name: 'ban',
      src: '/chat/icono-ban-user.svg'
    },
    {
      name: 'stop',
      src: '/chat/ico-stop.svg'
    },
    {
      name: 'play',
      src: '/chat/ico-play.png'
    },
    {
      name: 'webcam',
      src: '/chat/ico-webcam.svg'
    },
    {
      name: 'mute-mic',
      src: '/chat/ico-mute-mic.svg'
    },
    {
      name: 'active-mic',
      src: '/chat/ico-mic1.svg'
    },

    {
      name: 'active-cam',
      src: '/chat/ico-active-cam.svg'
    },
    {
      name: 'mute-cam',
      src: '/chat/ico-unativate-cam.svg'
    },
    {
      name: 'menu-point',
      src: '/chat/ico-menu-point.svg'
    },
    {
      name: 'lock',
      src: '/chat/ico-lock.svg'
    },
    {
      name: 'previous',
      src: '/chat/ico-prev.svg'
    },
    {
      name: 'next',
      src: '/chat/ico-next.svg'
    },
    {
      name: 'dot',
      src: '/chat/ico-dot.svg'
    },
    {
      name: 'translate',
      src: '/chat/ico-translate.svg'
    },
    {
      name: 'notif-tip',
      src: '/chat/ico-notif-tip.svg'
    },
    {
      name: 'notif-unblock',
      src: '/chat/ico-notif-unblock.svg'
    },
    {
      name: 'notif-fan',
      src: '/chat/ico-notif-fan.svg'
    },
    {
      name: 'notif-free',
      src: '/chat/ico-notif-free.svg'
    },
    {
      name: 'notif-payment',
      src: '/chat/ico-notif-payment.svg'
    },
    {
      name: 'edit',
      src: '/chat/ico-edit.svg'
    },
    {
      name: 'notif-info',
      src: '/chat/ico-notif-info.svg'
    },
    {
      name: 'audio',
      src: '/chat/ico-audio.svg'
    },
    {
      name: 'vault',
      src: '/chat/ico-vault.svg'
    },
    {
      name: 'album',
      src: '/chat/ico-album.svg'
    },
    {
      name: 'call',
      src: '/chat/ico-call.gif'
    },
    {
      name: 'end-call',
      src: '/chat/ico-end-call.svg'
    },
    {
      name: 'video-chat',
      src: '/chat/ico-video-chat.svg'
    },
    {
      name: 'arrow-bottom',
      src: '/chat/ico-arrow-bottom.svg'
    },
    {
      name: 'active-audio',
      src: '/chat/ico-active-audio.svg'
    },
    {
      name: 'mute-audio',
      src: '/chat/ico-mute-audio.svg'
    },
    {
      name: 'search',
      src: '/chat/ico-search.svg'
    },
    {
      name: 'arrow',
      src: '/chat/ico-arrow.svg'
    },
    {
      name: 'list',
      src: '/chat/ico-list.svg'
    },
    {
      name: 'lost-call',
      src: '/chat/ico-lost-call.svg'
    },
    {
      name: 'rejected-call',
      src: '/chat/ico-rejected-call.svg'
    },
    {
      name: 'in-call',
      src: '/chat/ico-in-call.svg'
    },
    {
      name: 'video-call',
      src: '/chat/ico-video-call.svg'
    },
    {
      name: 'padlock',
      src: '/chat/ico-lock-padlock.svg'
    }
  ]
}
