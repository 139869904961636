<template>
  <div class="col-12 my-3 user-new-broadcast" :class="borderDark">
    <TitleCollapse :title="$t('chats.new_broadcast.by')" :openCollapse="openBy">
      <div class="col-12 filter">
        <fieldset :class="{ errorField: noDestinationSelected && showError }">
          <legend style="margin: 5px 10px;padding: 5px;">
            <input
              type="checkbox"
              id="allfree"
              name="allfree"
              class="regular-check"
              v-model="all"
              @change="allSelected()"
            />
            <label for="allfree">{{
              $t('chats.new_broadcast.type.all')
            }}</label>
          </legend>
          <input
            type="checkbox"
            id="fans"
            name="fans"
            class="regular-check sub"
            v-model="fans"
            @change="changeSelected()"
          />
          <label for="fans">{{ $t('chats.new_broadcast.type.fans') }}</label
          ><br />
          <input
            type="checkbox"
            id="followers"
            name="followers"
            class="regular-check sub"
            v-model="followers"
            @change="changeSelected()"
          />
          <label for="followers">{{
            $t('chats.new_broadcast.type.followers')
          }}</label>
          <br />
        </fieldset>
        <div style="margin-top: 5px;"></div>
        <input
          type="checkbox"
          id="creators"
          name="creators"
          class="regular-check sub"
          v-model="creators"
          :disabled="checkDisabled"
          @change="changeSelected()"
        />
        <label for="creators"
          >{{ $t('chats.new_broadcast.type.creators') }}&nbsp;<br />

          <div v-if="checkDisabled"
            class="col-11"
            style="font-size: 10px;margin: auto; padding: 2px 0px 0px 10px;color: red"
          >
            {{ $t('chats.new_broadcast.type.creators_note_not_allowed') }}
          </div>
          <div
            class="col-11"
            style="font-size: 10px;margin: auto; padding: 2px 0px 0px 10px;"
          >
            {{ $t('chats.new_broadcast.type.creators_note') }}
          </div>
        </label>
      </div>
    </TitleCollapse>

    <TitleCollapse
      :title="$t('chats.new_broadcast.include')"
      :openCollapse="openInclude"
      class="mt-2"
    >
      <SearchUserNewBroadcast @list-users="listUserInclude" nameRadio="included"/>
    </TitleCollapse>
    <TitleCollapse
      :title="$t('chats.new_broadcast.exclude')"
      :openCollapse="openExclude"
      class="mt-2"
    >
      <SearchUserNewBroadcast @list-users="listUserExclude" nameRadio="excluded"/>
    </TitleCollapse>
  </div>
</template>
<script>
import TitleCollapse from '@/components/chat/component/TitleCollapse'
import SearchUserNewBroadcast from '@/components/chat/components/newChat/SearchUserNewBroadcast'

export default {
  components: { TitleCollapse, SearchUserNewBroadcast },
  props: {
    showError: { type: Boolean, default: false },
    noDestinationSelected: { type: Boolean, default: false },
    mediaAdded: Boolean
  },
  data () {
    return {
      excludeUsers: [],
      includeUsers: [],
      openInclude: false,
      openExclude: false,
      openBy: true,
      all: false,
      followers: false,
      fans: false,
      creators: false
    }
  },
  watch: {
    mediaAdded (value) {
      this.creators = false
    }
  },
  computed: {
    borderDark () {
      return {
        dark: this.$vuetify.theme.dark
      }
    },
    checkDisabled () {
      return this.mediaAdded
    }
  },
  methods: {
    listUserInclude (listUsers) {
      this.includeUsers = this.createArrayUser(listUsers)
      this.$emit('list-included-user', this.includeUsers)
    },
    listUserExclude (listUsers) {
      this.excludeUsers = this.createArrayUser(listUsers)
      this.$emit('list-excluded-user', this.excludeUsers)
    },
    createArrayUser (listUsers) {
      const aux = []
      listUsers.map(item => {
        if (item.isList) {
          item.users.map(user => {
            aux.push(user)
          })
        } else {
          aux.push(item.name)
        }
      })
      return [...new Set(aux)]
    },
    changeSelected () {
      /* this.showError = true */
      if (this.all) {
        this.all = false
      }
      this.$emit(
        'messageBy',
        this.all,
        this.followers,
        this.fans,
        this.creators
      )
    },
    allSelected () {
      if (this.all) {
        this.followers = true
        this.fans = true
      } else {
        this.followers = false
        this.fans = false
      }
      /* this.showError = true */

      this.$emit(
        'messageBy',
        this.all,
        this.followers,
        this.fans,
        this.creators
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.user-new-broadcast {
  border: #9e9e9e solid 2px;
  border-radius: 10px;
  &.dark {
    border-color: #c0c0c0;
  }
}
fieldset {
  border-radius: 10px;
}
.regular-check {
  -webkit-appearance: none;
  width: 8px !important;
  height: 8px !important;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 4px;
  border-radius: 1px;
  display: inline-block;
  position: relative;
  background-color: transparent;
  margin-right: 10px;
  .main {
    position: absolute;
    top: -25px;
    background: #fff;
    padding: 0 10px;
  }
  &.sub {
    margin-left: 25px;
  }
}
.regular-check:checked {
  background-color: var(--v-primary-base);
}
.regular-check:checked:disabled {
  background-color: #939393;
}
</style>
