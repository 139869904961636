<template>
  <v-form
    ref="accountForm"
    v-model="validForm"
    :dark="true"
  >
    <v-col :cols="isMobile ? 12 : 9" style="margin: auto">
      <v-row justify="center" dense>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingRight"
        >
          <div class="campo-label">{{ $t('models.user.username') }}</div>
          <v-text-field
            outlined
            :rounded="rounded"
            persistent-hint
            dense
            disabled
            :dark="isDark"
            :placeholder="$t('models.user.username')"
            :prefix="'@'"
            v-model="username"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingLeft"
        >
          <div class="campo-label">{{ $t('models.user.displayName') }}</div>
          <v-text-field
            outlined
            :rounded="rounded"
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.displayName')"
            v-model="displayName"
            :rules="[$vBannedWords(displayName),$vMaxLength(50)]"
            :hint="$t('models.user.displayName_hint')"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingRight"
        >
          <div class="campo-label">{{ $t('models.user.email') }}</div>
          <v-text-field
            outlined
            :rounded="rounded"
            color="secondary"
            dense
            :dark="isDark"
            class="white-background"
            :placeholder="$t('models.user.email')"
            v-model="email"
            :rules="[$vRequired, $vEmail]"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingLeft"
        >
          <div class="campo-label">
            {{ $t('models.user.email_confirmation') }}
          </div>
          <v-text-field
            :rounded="rounded"
            outlined
            color="secondary"
            dense
            :dark="isDark"
            class="white-background"
            :placeholder="$t('models.user.email_confirmation')"
            v-model="emailConfirmation"
            :rules="[$vRequired, $vConfirmation('email')]"
          ></v-text-field>
        </v-col>
        <!--        <v-col
                  :cols="isMobile ? 12 : 11"
                  :sm="isMobile ? 12 : 6"
                  :style="paddingRight"
                >
                  <div class="campo-label">
                    {{ $t("models.user.current_password") }}
                  </div>
                  <PasswordInput
                    outlined
                    dense
                    class="white-background"
                    ref="password"
                    :label="' '"
                    :dark="isDark"
                    :placeholder="$t('models.user.current_password')"
                    v-model="password"
                    :rules="[$vRequired]"
                  />
                </v-col>-->
        <!--        <v-col
                  :cols="isMobile ? 12 : 11"
                  :sm="isMobile ? 12 : 6"
                  :style="paddingLeft"
                >
                  <div class="campo-label">
                    {{ $t("models.user.password_confirmation") }}
                  </div>
                  <PasswordInput
                    outlined
                    dense
                    class="white-background"
                    :label="' '"
                    :dark="isDark"
                    :placeholder="$t('models.user.password_confirmation')"
                    v-model="confirmPassword"
                    :override-rules="[$vRequired, $vConfirmation('password')]"
                  />
                </v-col>-->
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingRight"
        >
          <div class="campo-label">{{ $t('models.user.phone_prefix') }}</div>
          <v-autocomplete
            :rounded="rounded"
            outlined
            dense
            height="15"
            v-bind="$attrs"
            :items="countries"
            :filter="search"
            v-model="phonePrefix"
            :rules="currentUser.forcePhone ? [$vRequired] : []"
          >
            <template v-slot:selection="{ item }">
              {{ item.name }}
              {{
                item.value.startsWith('00')
                  ? item.value.substring(2)
                  : item.value
              }}
            </template>
            <template v-slot:item="{ item }">
              {{ item.name }}
              {{
                item.value.startsWith('00')
                  ? item.value.substring(2)
                  : item.value
              }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingLeft"
        >
          <div class="campo-label">{{ $t('models.user.mobile') }}</div>
          <v-text-field
            :rounded="rounded"
            outlined
            dense
            :dark="isDark"
            :placeholder="$t('models.user.mobile')"
            color="primary"
            v-model="phone"
            :rules="currentUser.forcePhone ? [$vRequired, $vInteger] : [$vInteger]"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingRight"
          v-if="!noTelegram"
        >
          <div class="campo-label">{{ $t('models.user.telegram') }}</div>
          <v-text-field
            :rounded="rounded"
            outlined
            dense
            :dark="isDark"
            :placeholder="$t('models.user.telegram')"
            color="primary"
            v-model="telegram"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="isMobile ? 12 : 11"
          :sm="isMobile ? 12 : 6"
          :style="paddingLeft"
          v-if="!noTwitter"
        >
          <div class="campo-label">{{ $t('models.user.twitter') }}</div>
          <v-text-field
            :rounded="rounded"
            outlined
            dense
            :placeholder="$t('models.user.twitter')"
            color="primary"
            :dark="isDark"
            v-model="twitter"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <slot/>
      </v-row>
    </v-col>
  </v-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import project from '@/project'

export default {
  name: 'AccountData',
  props: {
    isDark: Boolean,
    noTwitter: Boolean,
    noTelegram: Boolean,
    rounded: Boolean
  },
  data() {
    return {
      submitting: false,
      validForm: false,
      username: '',
      displayName: '',
      email: '',
      emailConfirmation: '',
      password: null,
      confirmPassword: null,
      phonePrefix: '',
      phone: '',
      telegram: '',
      twitter: '',
      countries: [],
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser']),
    ...mapGetters('profile', ['currentWallet']),
    usernameIcon() {
      return !this.loadingValidUsername &&
      this.validUsername &&
      !this.$refs.username.hasError
        ? 'done'
        : !this.loadingValidUsername && this.validUsername === false
          ? 'block'
          : ''
    },
    usernameIconColor() {
      return this.validUsername ? 'success' : 'error'
    },
    paddingRight() {
      if (this.isMobile) return
      return {
        paddingRight: '10px'
      }
    },
    paddingLeft() {
      if (this.isMobile) return
      return {
        paddingLeft: '10px'
      }
    },
    getForm() {
      return {
        username: this.username,
        email: this.email,
        password: this.password,
        displayName: this.displayName,
        phonePrefix: this.phonePrefix,
        phone: this.phone,
        telegram: this.telegram,
        twitter: this.twitter
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit('validForm', value)
    },
    getForm() {
      this.$emit('dataForm', this.getForm)
    }
  },
  methods: {
    search(item, queryText, itemText) {
      return item.name.toLowerCase().includes(queryText.toLowerCase())
    }

    /* async emitData() {
      const action = this.$listeners.next;
      this.$refs.accountForm.validate();
      if (this.validForm) {
        this.submitting = true;
        try {
          await action(this.getForm());
        } catch (ex) {}
        this.submitting = false;
      }
    } */
  },
  mounted() {
    var elements = this.$t('countries_v2')
    for (var el of elements) {
      this.countries.push({
        name: el.text,
        image: 'https://flagcdn.com/28x21/' + el.value.toLowerCase() + '.png',
        value:
          el.value === 'CA' ? '00+1' : el.value === 'MP' ? '00+1' : el.prefix
      })
    }
    this.countries = this.countries.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1
      }
      return 0
    })
    this.username = this.currentUser.username
    this.email = this.currentUser.email
    this.emailConfirmation = this.currentUser.email
    this.password = this.password || null
    this.confirmPassword = this.password || null
    this.displayName = this.currentUser.displayName || null
    this.phonePrefix = `+${this.currentUser.phonePrefix}` || null
    this.phone = this.currentUser.phone || ''
    this.telegram = this.currentUser.telegram || null
    this.twitter = this.currentUser.twitter || null

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
</script>

<style lang="scss">
.tit-seccion {
  font-size: 32px;
  font-weight: bold;
}

.fuente-destacada {
  font-family: "Cabin", sans-serif;
}

.colordest {
  color: #f71953 !important;
}

.tit-desc {
  line-height: 20px;
  font-size: 16px;
  font-family: "Cabin", sans-serif;
}

.campo-label {
  text-align: left;
  font-size: 16px;
  padding-left: 10px;
  font-family: Quicksand, sans-serif;
}

.v-text-field.v-text-field--solo .v-input__control {
  height: 20px;
}

.v-messages__message {
  font-family: Quicksand, sans-serif;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  font-family: Quicksand, sans-serif;
}
</style>
