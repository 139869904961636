<template>
  <v-app :style="rootStyle">
    <v-content class="background">
        <img class="mobile-image" :class="mobilePicClass" :src="mobilePicRoute" />
        <v-row class="unlogged-container" justify="center" align="center" no-gutters>
          <v-col cols="12" class="flex-container" style="height: inherit">
            <router-view :key="$route.fullPath" class="login-wrapper" />
          </v-col>
        </v-row>
    </v-content>
    <cookies-modal></cookies-modal>
    <app-footer app></app-footer>
  </v-app>
</template>

<script>
import AppFooter from '@/components/layouts/AppFooter'
import CookiesModal from '../cookies/cookies'
import project from '@/project'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: { CookiesModal, AppFooter },
  computed: {
    ...mapState('application', ['isLargeScreen']),
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    rootStyle () {
      return {
        background: this.$vuetify.theme.themes[this.theme].background
      }
    },
    mobilePicRoute () {
      return !this.isLargeScreen ? '/images/movil_comp_720.png' : '/images/movil_comp_1080.png'
    },
    mobilePicClass () {
      return {
        'mobile-image-absolute': !this.isLargeScreen
      }
    },
    formAlign () {
      return !this.isLargeScreen ? 'end' : 'center'
    }
  },
  mounted () {
    this.$vuetify.theme.dark = project.theme === 'dark'
  }
}
</script>
<style lang="scss">

.background {
  background-image: url('/images/background_v2.png');
  background-size: cover;
}

.unlogged-container {
  position: relative;
  z-index: 1000;
  height: 100%;
}

.mobile-image {
  position: absolute;
  margin: auto 0;
  top: 0;
  bottom:0;
  left: -160px;
  width: 1080px;
}

.mobile-image-absolute {
  display: none;
}

@media (max-width: 1650px) {
  .login-wrapper {
    margin-top: auto;
    margin-bottom: auto;
  }
}

</style>
