import store from '@/store'
import AppLayout from '@/components/layouts/Application'
import UnloggedLayout from '@/components/layouts/Unlogged'

export default (to) => {
  const authenticated = store.state.session.authenticated
  if (!to.matched.some(m => m.meta.layout_set === true)) {
    const unloggedLayout = to.meta.unlogged_layout ? to.meta.unlogged_layout : UnloggedLayout
    to.meta.layout = authenticated ? AppLayout : unloggedLayout
  }
}
