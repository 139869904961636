<template>
  <v-row dense align="center">
    <v-col :cols="9">
      {{ label }}
      <v-btn
        css="ma-1"
        x-small
        v-if="isDownload"
        icon :href="downloadFile" download>
        <v-icon>file_download</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="1">
      <v-icon v-if="isUploadedAgreement" color="success" right>check_circle</v-icon>
    </v-col>
    <v-col cols="2">
      <FileInput
        button autoupload small
        max-files="1"
        style="justify-content: flex-end"
        content-type="*"
        :loading="(file || {}).loading"
        @file-added="file = $event"
        :rules="[$vRequired]"
      >
        {{ $t('actions.upload') }}
      </FileInput>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'WithdrawDocumentation',
  props: {
    label: String,
    tooltip: String,
    downloadFile: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      file: null
    }
  },
  computed: {
    isUploadedAgreement () {
      return this.file && this.file.uploaded
    },
    isDownload () {
      return this.downloadFile.length > 0
    },
  },
  watch: {
    isUploadedAgreement () {
      if (this.isUploadedAgreement) {
        this.$emit('fileUploaded', this.file)
      }
    }
  },
  methods: {
    download () {
    }
  }

}
</script>

<style scoped>

</style>
