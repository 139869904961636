<template>
  <v-form ref="form" v-model="validForm" >
    <h3 class="mb-2">{{ $t('withdraws.withdraw_methods.types.paypal') }}</h3>
    <v-text-field
      outlined
      :label="$t('models.withdraw_method.paypal_account')"
      type="text"
      v-model="accountId"
      :rules="[$vRequired]"/>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'PaypalMethodForm',
  mixins: [validatable('form')],
  props: {
    method: {
      type: Object,
      default: null
    },
    dark: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      currency: null,
      currencyOptions: ['EUR', 'USD'],
      accountId: null,
      withdrawMethodApproved: true
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    methodAttrs () {
      return {
        type: 'paypal',
        accountId: this.accountId
      }
    }
  },
  watch: {
    methodAttrs () {
      this.$emit('methodAttrs', this.methodAttrs)
    },
    validForm () {
      this.$emit('validForm', this.validForm)
    }
  },
  mounted () {
    if (!this.method) {
      return
    }
    this.accountId = this.method.accountId
  }
}
</script>
