<template>
  <section>
    <div class="box-title">
      <strong
        ><span class="date">⚬</span>
        {{ $t("chats.user_preferences.title_1") }}</strong
      >
      <span class="date"> {{ dateCreateUser }} </span>
      <span v-if="isNew" class="new"> NEW</span>
    </div>
    <div v-if="statistics">
      <div class="box-title">
        <strong
          ><span class="date">⚬</span>
          {{ $t("chats.user_preferences.title_2") }}</strong
        >
      </div>
      <div class="user-score-data">
        <div class="item-user-score-data">
          <span
            class="item-user-score-data-title"
            v-html="
              $t(
                `chats.user_preferences.score_text.sub.${
                  statistics.subscriptionPosition !== 0 ? 'rank' : 'no_rank'
                }`,
                { porcent: maskedValue(statistics.subscriptionPosition) }
              )
            "
          />
        </div>
        <div class="item-user-score-data">
          <span
            class="item-user-score-data-title"
            v-html="
              $t(
                `chats.user_preferences.score_text.buy.${
                  statistics.buyingContentPosition !== 0 ? 'rank' : 'no_rank'
                }`,
                { porcent: maskedValue(statistics.buyingContentPosition) }
              )
            "
          />
        </div>
        <div class="item-user-score-data">
          <span
            class="item-user-score-data-title"
            v-html="
              $t(
                `chats.user_preferences.score_text.text.${
                  statistics.sextingPosition !== 0 ? 'rank' : 'no_rank'
                }`,
                { porcent: maskedValue(statistics.sextingPosition) }
              )
            "
          />
        </div>
        <div class="item-user-score-data">
          <span
            class="item-user-score-data-title"
            v-html="
              $t(
                `chats.user_preferences.score_text.tips.${
                  statistics.tipsPosition !== 0 ? 'rank' : 'no_rank'
                }`,
                { porcent: maskedValue(statistics.tipsPosition) }
              )
            "
          />
        </div>
        <div class="spacer"></div>
      </div>
    </div>

    <div class="box-title">
      <strong
        ><span class="date">⚬</span>
        {{ $t("chats.user_preferences.title_3") }}</strong
      >
    </div>
    <div class="row justify">
      <p class="mt-1 col-8 colinput">
        {{ this.$t("application.chat_setting.default_price_sexting") }}
      </p>
      <InputCurrency
        :disabled="disabled"
        :currency="currency"
        :defaultPrice="currentPrice"
        class="inputCurrency col-4 colinput"
        v-on:newPrice="setPriceConversations"
      />
    </div>
    <div class="row justify">
      <p class=" mt-1 col-8 colinput">
        {{ this.$t("application.chat_setting.default_price_media") }}
      </p>
      <InputCurrency
        :currency="currency"
        :defaultPrice="mediaPrice"
        class="inputCurrency col-4 colinput"
        v-on:newPrice="setPriceMediaFile"
      />
    </div>
    <div class="row justify">
      <p class="mt-1 col-8 colinput">
        {{ this.$t("application.chat_setting.default_price_video") }}
      </p>
      <InputCurrency
        :currency="currency"
        :defaultPrice="videoPrice"
        class="inputCurrency col-4 colinput"
        v-on:newPrice="setPriceVideoChat"
      />
    </div>
    <div class="spacer"></div>
    <div class="center rows">
      <Button
        color="#41b849"
        text="GUARDAR"
        @handler="saveNewPrice"
        :loading="loadingButton"
      />
    </div>
    <div class="spacer"></div>
  </section>
</template>
<script>
import utils from "@/utils";
import { mapState, mapGetters } from "vuex";
import Button from "../component/Buttons";
import InputCurrency from "../component/InputCurrency.vue";
import ConversationsApi from "@/api/conversations/ConversationsApi";
import { ChatActions } from "@/store";
export default {
  name: "UserSetting",
  components: {
    Button,
    InputCurrency
  },
  computed: {
    ...mapState("profile", ["currentUser"]),
    ...mapState("chats", ["selectedChat"]),
    ...mapGetters("chats", ["getPreferences"]),
    currency() {
      return this.currentUser.wallet.currency === "EUR" ? "€" : "$";
    },
    currentPrice() {
      return this.selectedChat.sender.price.toString();
    },
    mediaPrice() {
      return this.selectedChat.sender.mediaPrice.toString();
    },
    videoPrice() {
      return this.selectedChat.sender.videochatPrice.toString();
    },
    dateCreateUser() {
      return utils.formatDate(this.selectedChat.receiver.createdAt);
    },
    disabled() {
      return (
        this.selectedChat.receiver.role === "influencer" ||
        this.selectedChat.receiver.role === "admin"
      );
    },
    isNew() {
      const date1 = new Date();
      const date2 = new Date(this.selectedChat.receiver.createdAt);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return !diffDays > 30;
    }
  },
  data() {
    return {
      receiverUser: null,
      price: 0,
      priceMedia: 0,
      videochatPrice: 0,
      loadingButton: false,
      statistics: null
    };
  },
  async mounted() {
    this.receiverUser = this.selectedChat.receiver;
    const { data, error } = await ConversationsApi.getStatistics({
      username: this.receiverUser.username
    });
    if (!error) {
      this.statistics = data;
    }
  },
  methods: {
    setPriceConversations(price) {
      this.price = price;
    },
    setPriceMediaFile(price) {
      this.priceMedia = price;
    },
    setPriceVideoChat(price) {
      this.videochatPrice = price;
    },
    maskedValue(value) {
      const val = 1001 - (value || 0);
      return val / 10;
    },
    async saveNewPrice() {
      this.loadingButton = true;
      const id = this.selectedChat.id;
      const preferences = Object.assign({}, this.getPreferences);
      const sender = Object.assign({}, this.selectedChat.sender);
      const price = this.price;
      const mediaPrice = this.priceMedia;
      const videochatPrice = this.videochatPrice;
      preferences.mediaPrice = mediaPrice;
      preferences.videochatPrice = videochatPrice;
      preferences.price = price;
      preferences.notified = false;
      /*  const postPreference = {
        approved: preferences.approved,
        archived: preferences.archived,
        mediaPrice: mediaPrice,
        videochatPrice: videochatPrice,
        price: price,
        notified: false
      }; */
      const { data, error } = await ConversationsApi.preferences(
        id,
        preferences
      );
      if (!error) {
        this.loadingButton = false;
        sender.price = price;
        sender.mediaPrice = mediaPrice;
        sender.videochatPrice = videochatPrice;
        this.$store.dispatch(ChatActions.RefreshConversation, {
          action: "updated",
          value: true,
          idChat: this.selectedChat.id
        });
        this.$store.dispatch(ChatActions.UpdateAttributes, { sender: sender });
        this.$emit("close");
      }
    }
  }
};
</script>
<style lang="scss">
.box-title {
  padding: 2px 7px;
  margin-bottom: 10px;
  background-color: #0097b8;
  color: #ffffff;
  font-size: 15px;
}
.date {
  color: #ebff00;
}
.new {
  background-color: #d9c700;
  border-radius: 3px;
  padding: 0 3px;
  font-size: 0.8em;
  color: black;
}
.inputCurrency {
  max-width: 30%;
}
.user-score-data {
  padding-left: 10px;
}
.colinput {
  padding: 0px 15px !important;
}
.justify {
  justify-content: space-between !important;
}
</style>
