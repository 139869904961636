<template>
  <section>
    <div class="box-spacearound" :class="dark">
      <div class="form-amount">
        <Dialog
          :show="showLoadFunds"
          @hide="() => (showLoadFunds = !showLoadFunds)"
          :title="$t('actions.load_funds').toUpperCase()"
        >
        <template slot="bodyDialog">
          <div>
            <LoadFunds :clearInit="showLoadFunds" />
          </div>
        </template>
      </Dialog>

        <div v-if="showOk">
          <p>
            Se ha enviado la propina correctamente
          </p>
          <div class="spacer"></div>
          <Button
            color="#41b849"
            :text="$t('actions.ok').toUpperCase()"
            @handler="onOk"
          />
        </div>

        <div v-else class="rows" style="max-height: 35px;">
          <span style="margin: auto;">Amount:</span>
          <InputCurrency
            style="display: flex"
            :currency="currency"
            @newPrice="setTips"
          />
          <Button
            color="#41b849"
            :text="$t('actions.send').toUpperCase()"
            @handler="sendTips"
            :loading="loadingButton"
            :disabled="minValueDisabled"
          />
        </div>
      </div>
    </div>
    <p class="count-amount">
      <strong>{{
        $t('wallet.current_balance_amount', { amount: amountWallet })
      }}</strong>
    </p>
  </section>
</template>

<script>
import TipApi from '@/api/TipApi'
import { mapState, mapGetters } from 'vuex'
import InputCurrency from '../../component/InputCurrency.vue'
import Button from '../../component/Buttons.vue'
import Dialog from '../../component/Dialog'
import LoadFunds from '../LoadFunds'
import { ProfileActions } from '@/store'
export default {
  name: 'SendTips',
  components: {
    Button,
    InputCurrency,
    Dialog,
    LoadFunds
  },
  props: {
    clearInit: { type: Boolean }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapGetters('profile', ['totalWalletAmount']),
    ...mapState('chats', ['selectedChat']),
     darkTheme(){
      return this.$vuetify.theme.dark
    },
    currency () {
      return this.currentUser.wallet.currency === 'EUR' ? '€' : '$'
    },
    dark () {
      return {
        dark: this.darkTheme
      }
    },
    minValueDisabled () {
      return !this.tip > 0
    },
    receiverUser () {
      return this.selectedChat.receiver.username
    },
    amountWallet () {
      return this.$currency(this.totalWalletAmount)
    }
  },
  data () {
    return {
      tip: 0,
      loadingButton: false,
      showOk: false,
      showLoadFunds:false,
      defaultPrice:null
    }
  },
  watch: {
    clearInit () {
      this.tip = 0
    }
  },
  methods: {
    setTips (price) {
      this.tip = price
    },
    onOk () {
      this.showOk = false
      this.$emit('close')
    },
    async sendTips () {
      this.loadingButton = true
      const tip = {
        name: '',
        resource: 'conversations',
        resourceId: this.selectedChat.id,
        tip: this.tip,
        username: this.receiverUser
      }
      const { data, error } = await TipApi.create(tip)
      if (!error) {
        this.tip = 0
        this.$store.dispatch(ProfileActions.Fetch)
        this.showOk = true
        this.loadingButton = false
      } else {
        this.loadingButton = false
        this.showLoadFunds=true
      }
    }
  },
}
</script>
<style lang="scss">
.box-spacearound {
  display: flex;
  justify-content: space-around;
  background: whitesmoke;
  font-family: 'Quicksand', sans-serif;
  padding: 5px 5px;
  border-radius: 20px;
  align-items: center;
  &.dark {
    background-color: transparent !important;
  }
}
.form-amount {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  margin: 5px;
}
.form-amount input {
  width: 100%;
  display: inline-block;
  border: 1px solid #d7d7d7;
  height: 30px;
  padding: 7px 15px;
  margin: 4px;
  font-size: 0.9375em;
  border-radius: 30px;
}
.count-amount {
  padding: 20px;
  display: block;
  font-size: 14px;
  text-align: center;
  float: none;
}
</style>
