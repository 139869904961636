<template>
  <svg :height="`${size}px`" :width="`${size}px`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g id="emviar-mensaje-2" transform="translate(-1.5 -1.5)">
      <path :fill="color" id="Trazado_135" data-name="Trazado 135"
            d="M16.091,15.682a1.091,1.091,0,0,1-.771-1.862l12-12a1.091,1.091,0,1,1,1.543,1.543l-12,12A1.087,1.087,0,0,1,16.091,15.682Z"
            transform="translate(-3.682 0)"/>
      <path :fill="color" id="Trazado_136" data-name="Trazado 136"
            d="M24.409,1.5a1.091,1.091,0,0,1,1.03,1.451L17.8,24.769a1.091,1.091,0,0,1-2.027.083l-4.193-9.435L2.148,11.224A1.091,1.091,0,0,1,2.231,9.2L24.049,1.561A1.09,1.09,0,0,1,24.409,1.5ZM16.652,21.452,22.631,4.369,5.548,10.348l7.3,3.246a1.091,1.091,0,0,1,.554.554Z"
            transform="translate(0 0)"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Send',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
