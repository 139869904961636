import axios from 'axios'
import WS from '@/api/Websocket'
import Config from '@/project'

const http = axios.create({
  crossDomain: true,
  withCredentials: true,
  timeout: 0
})
const safeHttp = axios.create({
  crossDomain: true,
  timeout: 0
})

const localTimezone = JSON.stringify(new Date())
http.defaults.headers.common['Local-Timezone'] = localTimezone

const handleRequest = async (req) => {
  try {
    const result = await req()
    return { data: result.data, headers: result.headers, error: false }
  } catch (e) {
    console.error(e)
    if (e.response && e.response.data) {
      return { data: e.response.data, headers: e.response.headers, error: true }
    } else {
      return { data: { errorCode: 'Error' }, error: true }
    }
  }
}
const wrappedHttp = (http) => ({
  http,
  get (path, data, options) {
    return handleRequest(() => {
      return http.get(path, { params: data, ...options })
    })
  },
  post (path, data, options) {
    return handleRequest(() => {
      return http.post(path, data, options)
    })
  },
  put (path, data, options) {
    return handleRequest(() => {
      return http.put(path, data, options)
    })
  },
  delete (path, data, options) {
    return handleRequest(() => {
      return http.delete(path, { params: data }, options)
    })
  },
  deleteBody (path, data, options) {
    return handleRequest(() => {
      return http.delete(path, { data }, options)
    })
  }
})

export default {
  wrapped: wrappedHttp(http),
  domain: Config.domains.baseUrl,
  baseUrl: Config.domains.api,
  websocketUrl: Config.domains.websocket,

  cancelToken () {
    return axios.CancelToken.source()
  },

  setHeader (key, value) {
    http.defaults.headers.common[key] = value
  },

  removeHeader (key) {
    delete http.defaults.headers.common[key]
  },

  intercepResponse (success, error) {
    if (typeof success !== 'function') {
      success = (res) => res
    }
    http.interceptors.response.use(success, error)
  },

  get (path, data, options) {
    return this.wrapped.get(`${this.baseUrl}${path}`, data, options)
  },

  post (path, data, options) {
    return this.wrapped.post(`${this.baseUrl}${path}`, data, options)
  },

  put (path, data, options) {
    return this.wrapped.put(`${this.baseUrl}${path}`, data, options)
  },

  delete (path, data, options) {
    return this.wrapped.delete(`${this.baseUrl}${path}`, data, options)
  },

  deleteBody (path, data, options) {
    return this.wrapped.deleteBody(`${this.baseUrl}${path}`, data, options)
  },

  raw () {
    return this.wrapped
  },

  defaultRaw () {
    return wrappedHttp(safeHttp)
  },

  websocket () {
    if (!this.ws) {
      this.ws = new WS(this.websocketUrl)
    }
    return this.ws
  },

  mockCall () {
    return {error: true}
  }
}
