<template>
  <svg id="volver-atras-blanco" xmlns="http://www.w3.org/2000/svg" :height="`${size}px`" :width="`${size}px`"
       viewBox="0 0 40 40">
    <g :fill="color" id="Icon_feather-arrow-left" data-name="Icon feather-arrow-left" transform="translate(6 6)">
      <path :fill="color" id="Unión_9" data-name="Unión 9"
            d="M-5232.708-6389.293l-6.983-6.982a1,1,0,0,1-.31-.724,1,1,0,0,1,.318-.731l6.975-6.977a1,1,0,0,1,1.415,0,1,1,0,0,1,0,1.415l-5.293,5.293H-5225a1,1,0,0,1,1,1,1,1,0,0,1-1,1h-11.587l5.293,5.293a1,1,0,0,1,0,1.413,1,1,0,0,1-.707.292A1,1,0,0,1-5232.708-6389.293Z"
            transform="translate(5246 6411)"/>
    </g>
    <rect :fill="color" id="Rectángulo_80" data-name="Rectángulo 80" width="40" height="40" fill="none"/>
  </svg>
</template>

<script>
export default {
  name: 'Back',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
