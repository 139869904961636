'user strict'

import api from '@/api/Api'
import Utils from '@/utils'

export default {

  find (username) {
    return api.get(`/users/${username}`)
  },

  list (query, options) {
    if (query.username && Utils.bannedWords(query.username)) {
      return api.mockCall()
    }
    return api.get('/users', query, options)
  },

  listActives (query, options) {
    return api.get('/users/actives', query, options)
  },

  follow (username) {
    return api.post(`/users/${username}/follow`)
  },

  unfollow (username) {
    return api.delete(`/users/${username}/follow`)
  },

  recommendations (perPage, options) {
    return api.get('/users/recommended', { perPage }, options)
  },

  checkUsername (username) {
    return api.get(`/users/check_name/${username}`)
  },

  referred (query, options) {
    return api.get('/payments/referred', query, options)
  },

  count () {
    return api.get('/users/count')
  },

  starsOffer (query, options) {
    return api.get('/stars/offers', query, options)
  },

  yoti (query) {
    return api.post('/iam/yoti', query)
  },

  yotiGetSession (username, query) {
    return api.post(`/iam/yoti_callback/${username}`, query)
  },

  yotiDocumentRetrieve (query) {
    return api.get('/iam/yoti_document', query)
  },

  yotiGetConfig () {
    return api.get('/iam/yoti_config')
  },
  signDocument (query) {
    return api.post('/users/signedDocumet', query)
  },
  signedDocument () {
    return api.get('/users/signedDocumet')
  },
  activeSubscriptions (query) {
    return api.get('/users/subscribed', query)
  }

}
