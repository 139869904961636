<template>
  <v-form ref="form" v-model="validForm"
    :dark="false"
    @submit.prevent="submit">
    <v-alert type="error"
      v-if="loginError"
      icon="warning"
      dense outlined>
      {{ loginError }}
    </v-alert>
    <v-text-field outlined autofocus
      class="white-background"
      dense
      :label="$t('login.login_field')"
      name="login"
      prepend-inner-icon="person"
      v-model="login"
      :rules="[$vRequired]"
    ></v-text-field>
    <PasswordInput outlined
      prepend-inner-icon="vpn_key"
      dense
      class="white-background"
      v-model="password"
      :override-rules="[$vRequired]" />
    <v-spacer style="margin-bottom: 15px"></v-spacer>
    <c-btn block
      color="success"
      type="submit"
      @click.stop.prevent="submit"
      css="mb-3">
      {{ $t('actions.login') }}
    </c-btn>
  </v-form>
</template>

<script>
import { SessionActions } from '@/store'
import validatable from '@/components/mixins/validatable'
import { mapState } from 'vuex'

export default {
  name: 'LoginForm',
  mixins: [validatable('form')],
  props: {
    dark: Boolean,
    challenge: { type: Boolean, default: false },
    small: { type: Boolean, default: false }
  },
  data () {
    return {
      login: '',
      password: '',
      loginError: null
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    textColorClass () {
      return {
        'black--text': !this.dark,
        'white--text': this.dark
      }
    }
  },
  methods: {
    async onSubmit () {
      const { data, error } = await this.$store.dispatch(SessionActions.Login, {
        login: this.login, password: this.password
      })
      if (error) {
        this.loginError = this.$t(`remote_errors.${data.errorCode}`)
      } else {
        if (!this.small) {
          this.redirect()
        } else {
          this.$emit('close')
        }
      }
    },
    redirect () {
      const origin = sessionStorage.getItem('origin')
      if (origin && origin!=='/login') {
        this.$router.replace(origin)
        sessionStorage.removeItem('origin')
      } else {
        if(this.challenge) this.$emit('logged')
        else { this.$router.replace('/home') }
      }
    }
  },
  beforeDestroy() {
    this.$emit('close')
  }
}
</script>
<style scoped>
.white-background >>> .v-input__slot {
  background-color: white !important;
}
.white-text {
  color: white !important;
}
</style>
