'user strict'

import api from '@/api/Api'

export default {

  list (query = {}, options) {
    return api.get('/subscriptions', query, options)
  },

  create (params) {
    return api.post('/subscriptions', params)
  },

  cancel (username) {
    return api.put('/subscriptions', { influencer: username, state: 'canceled' })
  },

  createTrial (params) {
    return api.post('/subscriptions/trials', params)
  },

  disableTrial (id) {
    return api.put(`/subscriptions/trials/${id}`, { disabled: true })
  },

  findTrialByToken (token) {
    return api.get(`/trials/${token}`)
  },

  claimTrial (token) {
    return api.post(`/trials/${token}`)
  }
}
