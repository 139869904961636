<template>
  <section>
    <div class="container-att" v-if="getTotalAttach" @click="selectedResource">
      <div class="w-100 h-100" >
        <Block v-if="blocked" :src="getFirt.url" primary :type="getFirt.type" />
        <Recoding v-else-if="isRecoding" />
        <component v-else
          :is="getComponents(getFirt)"
          primary
          :src="getFirt.url"
        ></component>
        <section v-if="getTotalAttach > 1" class="row px-2">
          <div v-for="(item, index) in getSecond" :key="index" class="col px-1">
            <component
              :is="getComponents(item)"
              :src="item.url"
              :count="getCount"
            ></component>
          </div>
        </section>
      </div>
    </div>
    <!--<p class="txtmin s-size" style="color: #8d9193; transform: translateY(80%);">
												<i class="flaticon-camara-fotos"></i> 5 <i class="flaticon-video"></i> 3</p>
											</p>-->
  </section>
</template>

<script>
import VImage from "./type/VImage";
import VVideo from "./type/VVideo";
import VAudio from "./type/VAudio";
import Recoding from "./type/Recoding";
import Block from "./type/Block";
import OnlyOneAudio from "./type/OnlyOneAudio";
import { ChatActions } from "@/store";
import CountPlus from "./type/CountPlus";

export default {
  name: "ListAttachView",
  components: {
    VImage,
    VVideo,
    CountPlus,
    VAudio,
    Recoding,
    Block,
    OnlyOneAudio
  },
  props: {
    message: Object,
    //attachments: { type: Array, default: new Array() },
    blocked: Boolean
  },
  computed: {
    attachments() {
      return this.message.attachments;
    },
    getFirt() {
      //return this.getComponents(this.attachmentsLocal[0]);
      if (this.attachmentsLocal.length) {
        return this.attachmentsLocal[0];
      }
    },
    getSecond() {
      if (this.attachmentsLocal.length) {
        return this.attachmentsLocal[1];
      }
    },
    getCount() {
      return this.getTotalAttach - 4;
    },
    getTotalAttach() {
      return this.attachments.length;
    },
    resourceAttachments() {
      return (this.message.attachments || []).map((res) => {
        const result = res;
        if (res.media) res = res.media
        return res;
      })
    },
    isRecoding() {
      const first = this.getFirt
      return !this.blocked && first && first.type === 'video' && first.media && first.media.status
    }
  },
  created() {
    this.contructorArray();
  },
  watch: {
    attachments() {
      this.contructorArray();
    }
  },
  data() {
    return {
      attachmentsLocal: []
    };
  },
  methods: {
    contructorArray() {
      const attachments = this.attachments.slice();
      //console.log("attachments", attachments.length);
      const result = new Array();
      const aux = new Array();

      if (attachments.length >= 1) {
        result.push(attachments.shift());
      }
      if (attachments.length >= 2) {
        attachments.map(att => {
          aux.push(att);
        });
        result.push(aux);
      }
      if (result.length === 2) {
        if (result[1].length <= 4) {
          if (result[0].type !== "audio") {
            result[1] = this.fillEmpty(result[1]);
          }
        } else {
          result[1] = this.fillPlus(result[1]);
        }
      }
      this.attachmentsLocal = result;
    },
    fillEmpty(array) {
      let value = {
        url: "",
        type: "fill",
        poster: ""
      };
      let aux = [array, value, value, value, value];
      aux = aux.flat();
      return aux.slice(0, 4);
    },

    fillPlus(array) {
      const aux = array.slice(0, 3);
      aux.push({ url: "", type: "plus", poster: "" });
      return aux;
    },
    isOnlyOneAudio(item) {
      return item.type === "audio" && this.attachments.length === 1;
    },
    getComponents(item) {
      const type = item.type;
      if (this.isOnlyOneAudio(item)) {
        return "OnlyOneAudio";
      } else if (type === "image") {
        return "VImage";
      } else if (type === "plus") {
        return "CountPlus";
      } else if (type === "video") {
        return "VVideo";
      } else if (type === "audio") {
        return "VAudio";
      }
    },
    selectedResource() {
      if (this.blocked || this.isOnlyOneAudio(this.attachments[0])) return;
      if (this.isRecoding) return;
      this.$store.dispatch(ChatActions.CarouselItemsList, this.resourceAttachments);
      this.$store.dispatch(ChatActions.CarouselItemSelected, 0);
      this.$store.dispatch(ChatActions.ShowCarousel, true);
    }
  }
};
</script>
<style lang="scss" scoped>
.container-att {
  //display: flex;
  max-width: 350px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.zonaimgal.adj {
  -ms-flex: 25%;
  flex: 25%;
  max-width: 25%;
  text-align: center;
  cursor: default;
}
.adjuntos img {
  border-radius: 4px !important;
}
.zonaimgal.adj p.numgal {
  font-size: 2.5em;
  line-height: 1;
}
.gallery {
  .zonaimgal {
    display: flex;
    width: 100%;
    max-width: 450px;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 0px;
    margin-top: 5px;
  }

  //justify-content: space-evenly;
}
</style>
