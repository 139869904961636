var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('Box',[_c('div',{staticClass:"blurs",style:(("background-image: url(" + _vm.getBlurUrl + ")")),on:{"click":_vm.showBuy}},[_c('div',{staticClass:"row center paymentBtn"},[_c('div'),_c('div',{staticClass:"col-12 center",staticStyle:{"display":"block"}},[_c('span',{staticClass:"material-icons"},[_vm._v(" lock ")]),(!_vm.message.searchMessage)?_c('Buttons',{staticClass:"buyBtn",attrs:{"text":_vm.$t(
                _vm.message.attachments.length > 1
                  ? 'actions.buy_for_multiple'
                  : 'actions.buy_for',
                {
                  number: _vm.message.attachments.length,
                  price: _vm.getPriceCurrency
                }
              )}}):_vm._e()],1)])])]),_c('WarningDialog',{attrs:{"show":_vm.showBuyMessage,"textWarning":_vm.textBuy,"textButtonOK":_vm.$t('actions.buy').toUpperCase(),"loading":_vm.loadingButton},on:{"handlerButtonCancel":_vm.showBuy,"handlerButtonOK":_vm.buyMessages}}),_c('Dialog',{attrs:{"show":_vm.showLoadFunds,"title":_vm.$t('actions.load_funds').toUpperCase()},on:{"hide":function () { return (_vm.showLoadFunds = !_vm.showLoadFunds); }}},[_c('template',{slot:"bodyDialog"},[_c('div',[_c('LoadFunds',{attrs:{"price":_vm.messageLocal.price}})],1)])],2),_c('WarningDialog',{attrs:{"show":_vm.showErrorBuyMessage,"textWarning":_vm.textWarningWallet,"textButtonOK":_vm.$t('actions.reload').toUpperCase()},on:{"handlerButtonCancel":_vm.showErrorBuy,"handlerButtonOK":_vm.showFunds},scopedSlots:_vm._u([{key:"plus",fn:function(){return [_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(("*" + (_vm.$t("subscriptions.vat_explain", { price: _vm.$currency(_vm.getPriceExes) }))))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }