<template>
  <div>
    <c-btn @click="dialog = !dialog" icon small color="secondary">
      <slot name="iconShared">
        <c-icon icon="Shared"></c-icon>
      </slot>
    </c-btn>
    <c-dialog
      width="300"
      :fullscreen="false"
      v-bind:value="dialog"
      @close="close">
      <template #title>
        <h3>{{ $t('actions.share') }}</h3>
      </template>
      <div class="mb-0" v-for="(item, index) in itemsShared" :key="index">
        <div class="row-shared" :tabindex="0" @click="item.click">
          <span class="menu-text">{{ item.title }}</span>
          <img height="30" class="ml-auto" :src="item.iconSrc"/>
        </div>
      </div>
    </c-dialog>
  </div>
</template>

<script>
import dialog from '@/components/mixins/dialog'
import config from '@/project'
import { AppActions } from '@/store'


export default {
  name: 'Shared',
  mixins: [dialog],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      itemsShared: [
        {
          title: this.$t('users.profile.share.share_twitter'),
          iconSrc: '/images/icons/twitter_color.svg',
          click: this.twitterUrl.bind(this)
        },
        {
          title: this.$t('users.profile.share.share_facebook'),
          iconSrc: '/images/icons/facebook_color.svg',
          click: this.facebookUrl.bind(this)
        },
        /*         {
                  title: this.$t('users.profile.share.share_instagram'),
                  iconSrc: '/images/icons/instagram_color.svg',
                  click: this.instagramUrl.bind(this)
                }, */
        {
          title: this.$t('users.profile.share.share_whatsapp'),
          iconSrc: '/images/icons/whatsapp_color.svg',
          click: this.whatsappUrl.bind(this)
        },
        {
          title: this.$t('users.profile.share.share_gmail'),
          iconSrc: '/images/icons/gmail_color.svg',
          click: this.gmailUrl.bind(this)
        },
        {
          title: this.$t('users.profile.share.copy_link'),
          iconSrc: '/images/icons/copy_profile.svg',
          click: this.copyLink.bind(this)
        }
      ]
    }
  },
  computed: {
    profileLink () {
      return `https://${config.project}.com/special/nextDoor/user?username=${this.user.username}`
    },
    textSend () {
      return this.$t('users.profile.share.text_send', {
        url: this.profileLink,
        plaform: config.locales.project_upper
      })
    }
  },
  methods: {
    close () {
      this.dialog = false
    },
    twitterUrl () {
      window.open(`https://twitter.com/intent/tweet?text=${this.textSend}`)
    },
    whatsappUrl () {
      window.open(`https://api.whatsapp.com/send?text=${this.textSend}`)
    },
    facebookUrl () {
      this.copyLinkMsg()
      window.open('https://www.facebook.com')
    },
    gmailUrl () {
      window.location.href = `mailto:?subject=${this.textSend}&body=${this.textSend}`
    },
    instagramUrl () {
      this.copyLinkMsg()
      window.open('https://www.instagram.com')
    },
    copyLinkMsg () {
      const copyText = document.createElement('textarea')
      copyText.value = this.textSend
      copyText.setAttribute('readonly', '')
      copyText.style.position = 'absolute'
      copyText.style.left = '-9999px'
      document.body.appendChild(copyText)
      copyText.select()
      document.execCommand('copy')
      document.body.removeChild(copyText)
    },
    copyLink () {
      navigator.clipboard.writeText(this.profileLink)
      this.$store.dispatch(AppActions.SetNotification, { type: 'copy' })
      this.$store.dispatch(AppActions.ShowNotification, { type: 'copy' })
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.row-shared {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.v-dialog.round.v-dialog--active.v-dialog--persistent {
  border-radius: 16px;
}

</style>
