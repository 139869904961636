<template>
  <div>
    <v-row v-if="isCurrentUser" justify="end">
      <v-col cols="auto">
        <v-btn
          color="secondary"
          small
          rounded
          v-if="isFollower || edittingMode"
          @click.stop="showDialog"
        >
          {{ $t('actions.edit') }}
          <v-icon small right dark>wallpaper</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" v-if="isInfluencer">
        <c-btn color="secondary" small rounded @click="switchMode">
          {{ $t(`users.profile.${edittingMode ? 'starMode' : 'fanMode'}`) }}
        </c-btn>
      </v-col>
    </v-row>
    <template v-if="isUserInfluencer">
      <v-row no-gutters justify="end" align="center">
        <v-col cols="auto">
          <btn-video-call :user="user"/>
        </v-col>
        <!-- <v-col cols="auto" >
          <TipButton
            :disabled="isCurrentUser"
            :icon="isMobile"
            :user="user" />
        </v-col> -->
        <v-col cols="auto">
          <PaymentButton
            v-if="showSubscriptionButton"
            :available="user.subscribable"
            :color="subscribeButtonColor"
            :show-init="showPaymentButton"
            small
            subscription
            :resource="user"
          />
          <v-btn
            v-else
            small
            rounded
            color="primary"
            @click="offersDialog = true"
          >
            {{ priceSubscription }}
            <v-icon right dark>edit</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters justify="end" align="center">
        <v-col cols="auto">
          <div class="mt-3">
            <TipButton :disabled="isCurrentUser" :icon="isMobile" :user="user" :show-init="showInitTips"/>
          </div>
        </v-col>
        <v-col v-if="showFollowButton" cols="auto">
          <FollowingBtn
            class="mt-3"
            css="align-self-center"
            small
            outlined
            color="white"
            :user="user"
          />
        </v-col>
      </v-row>
    </template>
    <template v-if="isCurrentUser">
      <c-dialog v-model="dialog" :actions="false">
        <h1 slot="title">{{ $t('user_profile.edit') }}</h1>
        <ProfileForm @close="hideDialog"/>
      </c-dialog>
      <SubscriptionConfigDialog
        v-model="offersDialog"
        @submitted="trialCreated"
      />
      <TrialInfoDialog v-if="selectedTrial"/>
    </template>

    <SimpleSessionForm v-if="showSession" @close="close" no-redirect/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import dialog from '@/components/mixins/dialog'
import ProfileForm from '@/components/users/ProfileForm'
import PaymentButton from '@/components/payments/PaymentButton'
import FollowingBtn from '@/components/users/FollowingButton'
import TipButton from '@/components/tips/TipButton'
import SubscriptionConfigDialog from '@/components/subscriptions/components/SubscriptionConfigDialog'
import TrialInfoDialog from '@/components/subscriptions/components/TrialInfoDialog'
import config from '@/project'
import SimpleSessionForm from '@/components/sessions/SimpleSessionForm'

export default {
  name: 'CoverActions',
  mixins: [dialog],
  components: {
    SimpleSessionForm,
    TrialInfoDialog,
    SubscriptionConfigDialog,
    TipButton,
    ProfileForm,
    PaymentButton,
    FollowingBtn
  },
  props: {
    user: Object,
    edittingMode: Boolean
  },

  data () {
    return {
      profileDialog: false,
      offersDialog: false,
      showPaymentButton: false,
      showSession: false
    }
  },
  computed: {
    ...mapState('profile', [
      'currentUser',
      'isInfluencer',
      'isFollower',
      'selectedTrial'
    ]),
    ...mapState('application', ['isMobile']),
    ...mapState('session', ['authenticated']),
    isCurrentUser () {
      return (
        this.authenticated && this.user.username === this.currentUser.username
      )
    },
    showInitTips () {
      return this.$route.query.tips && !this.isCurrentUser
    },
    isUserInfluencer () {
      return this.user.role === 'influencer' && this.user.role !== 'admin'
    },
    showTipButton () {
      return this.authenticated && (!this.isCurrentUser || !this.edittingMode)
    },
    showSubscriptionButton () {
      return !this.isCurrentUser || !this.edittingMode
    },
    showFollowButton () {
      return !this.isCurrentUser || !this.edittingMode
    },
    canTip () {
      return !this.isCurrentUser && this.isUserInfluencer
    },
    canSubscribe () {
      return !this.isCurrentUser && this.user.subscribable
    },
    priceSubscription () {
      return this.$t('users.subscribe', {
        price: this.$currency(!this.isSpecialOfferActive ? this.user.subscriptionPrice : (this.user.subscriptionPrice * (100 - config.specialOffer.discount) / 100), this.user.currency)
      })
    },
    subscribeButtonColor () {
      return this.canSubscribe && this.user.subscribed ? 'white' : 'primary'
    },
    isSpecialOfferActive () {
      const today = new Date()
      return config.specialOffer.active && config.specialOffer.startDate < today && config.specialOffer.endDate > today && config.specialOffer.discount > 0
    }
  },
  methods: {
    trialCreated ({ trial } = {}) {
      if (!trial) {
        return
      }
      this.$store.dispatch(ProfileActions.SelectTrial, trial)
    },
    switchMode () {
      this.$emit('update:editting-mode', !this.edittingMode)
    },
    close () {
      console.log('CLOSSSSSSS')
      this.showSession = !this.showSession
    }
  },
  mounted () {
    if (this.$route.query.subs) {
      if (!this.authenticated) {
        this.showSession = true
      } else {
        this.showPaymentButton = true
      }
    }
  }
}
</script>
