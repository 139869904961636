<template>
  <section>
    <v-card-text>
      <i18n path="subscriptions.trials.claim.info" tag="p">
        <template #name><b>{{ trial.author.displayName }}</b></template>
        <template #days><b>{{ $tc('subscriptions.trials.duration_count', trial.duration) }}</b></template>
      </i18n>
      <p v-if="trial.creditCard">
        {{ $t('subscriptions.trials.claim.credit_card') }}
      </p>
    </v-card-text>
    <v-card-text>
      {{ $t('subscriptions.trials.claim.question') }}
    </v-card-text>
  </section>
</template>
<script>
import messages from '../locales/all.json'
export default {
  name: 'TrialClaimInfo',
  i18n: { messages },
  props: {
    trial: { type: Object, default: () => ({}) }
  }
}
</script>
