<template>
  <v-form ref="form" v-model="validForm"
          @submit.prevent="submit">
    <v-alert type="error"
             v-if="loginError"
             icon="warning"
             dense outlined>
      {{ loginError }}
    </v-alert>
    <v-text-field outlined autofocus
                  dense
                  :label="$t('login.login_field')"
                  name="login"
                  prepend-inner-icon="person"
                  v-model="login"
                  :rules="[$vRequired]"
    ></v-text-field>
    <PasswordInput outlined
                   prepend-inner-icon="vpn_key"
                   dense
                   v-model="password"
                   :override-rules="[$vRequired]"/>
    <div style="margin-bottom: 25px" class="text-center">
      <router-link :to="{ name: 'remember_password' }"
                   class="remember-password capital">
        {{ $t('login.remember_password') }}
      </router-link>
    </div>
    <c-btn depressed
           block :rounded="false"
           style="text-transform: uppercase;background-color: #41b849 !important;"
           color="#41b849"
           type="submit"
           @click.stop.prevent="submit"
           css="mb-3">
      <span style="font-family: Quicksand, sans-serif; color: white; font-weight: 600">{{ $t('actions.login') }}</span>
    </c-btn>
  </v-form>
</template>

<script>
import {SessionActions} from '@/store'
import validatable from '@/components/mixins/validatable'
import {mapState} from 'vuex'

export default {
  name: 'SimpleLoginForm',
  mixins: [validatable('form')],
  props: {
    challenge: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
    noRedirect: {type: Boolean, default: false}
  },
  data() {
    return {
      login: '',
      password: '',
      loginError: null
    }
  },
  computed: {
    ...mapState('application', ['isMobile'])
  },
  methods: {
    async onSubmit() {
      const {data, error} = await this.$store.dispatch(SessionActions.Login, {
        login: this.login, password: this.password
      })
      if (error) {
        this.loginError = this.$t(`remote_errors.${data.errorCode}`)
      } else {
        this.$gtm('log_in')
        if (!this.small) {
          this.redirect()
        } else {
          this.$emit('complete')
        }
      }
    },
    redirect() {
      const origin = sessionStorage.getItem('origin')
      if (origin && origin !== '/login') {
        this.$router.replace(origin)
        sessionStorage.removeItem('origin')
      } else {
        if (this.challenge) this.$emit('logged')
        else {
          this.$router.replace('/home')
        }
      }
    }
  },
  beforeDestroy() {
    this.$emit('close')
  }
}
</script>
<style scoped lang="scss">
.white-background > > > .v-input__slot {
  background-color: white !important;
}

.white-text {
  color: white !important;
}

.theme--dark {
  .remember-password {
    color: white !important;
  }
}
</style>
