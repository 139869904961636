<template>
  <v-chip
    x-small
    class="translucient-dark"
    :color="approvalColor">
    {{ $t(`posts.approval.status_label.${post.approvalStatus}`) }}
  </v-chip>
</template>
<script>

export default {
  name: 'ApprovalStatus',
  props: {
    post: Object
  },

  computed: {
    isApproved () { return this.post.approvalStatus === 'approved' },
    isWhitelisted () { return this.post.approvalStatus === 'whitelisted' },
    isBlocked () { return this.post.approvalStatus === 'blocked' },
    isRejected () { return this.post.approvalStatus === 'rejected' },
    isPending () { return this.post.approvalStatus === 'pending' },
    isRequested () { return this.post.approvalStatus === 'requested' },
    approvalColor () {
      return this.isApproved || this.isWhitelisted ? 'success'
        : this.isBlocked || this.isRejected ? 'red'
        : 'warning'
    }
  }
}
</script>