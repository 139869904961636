const PWAPlugin = {
  install (Vue) {
    Vue.prototype.$pwaInstall = []
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register('/service-worker.js')
    //     .then(function (reg) {
    //       console.log('installed')
    //     }).catch(function (err) {
    //       console.log(err)
    //     })
    // }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations().then(function (registrations) {
          for (const registration of registrations) {
            registration.unregister()
          }
        })
    }

    // window.addEventListener('beforeinstallprompt', (e) => {
    //   Vue.prototype.$pwaInstall.push(e)
    // })
  }
}

export default PWAPlugin
