<template>
  <div>
    <ListView cols="12" class="p-0"
              :items="withdrawMethods">
      <template #default="{ item }">
        <WithdrawMethod class="border-bottom"
                        :method="item"/>
      </template>
      <template #empty>
        {{ $t('withdraws.withdraw_methods.no_data') }}
      </template>
    </ListView>
  </div>

</template>
<script>
import { mapState } from 'vuex'
import { WithdrawActions } from '@/store'
import WithdrawMethod from '@/components/withdraws/WithdrawMethod'

export default {
  name: 'WithdrawMethodsList',
  components: { WithdrawMethod },
  computed: {
    ...mapState('withdraws', ['withdrawMethods'])
  },
  beforeMount () {
    this.$store.dispatch(WithdrawActions.FetchMethods)
  }
}
</script>
