<template>
  <section>
    <h1 class="mb-1">{{ $t('blocked_users.title')}}
      <c-btn x-small
        class="mb-1"
        @click="showDialog">
        {{ $t('actions.change') }}
      </c-btn>
    </h1>
    <p v-if="!blockedUsers.length"
      class="px-5">
      {{ $t('blocked_users.no_users')}}
    </p>
    <v-row align="center">
      <span v-for="user in listedUsers"
        :key="user.username"
        class="primary--text mx-1">
        @{{ user.username }}
      </span>
      <span v-if="hasRemaingingCount">{{ remaingingCount }}</span>
    </v-row>
    <SelectUserDialog v-model="dialog" />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import dialog from '@/components/mixins/dialog'
import SelectUserDialog from '@/components/users/blocking/SelectUserDialog'

const MaxBlockedUsersCount = 3

export default {
  name: 'BlockedUsersInfo',
  mixins: [dialog],
  components: { SelectUserDialog },
  props: {
    editable: Boolean
  },

  computed: {
    ...mapState('profile', ['blockedUsers']),
    listedUsers () {
      return this.blockedUsers.slice(0, MaxBlockedUsersCount)
    },

    hasRemaingingCount () {
      return this.blockedUsers.length > MaxBlockedUsersCount
    },

    remaingingCount () {
      const count = this.blockedUsers.length - MaxBlockedUsersCount
      return this.$t('blocked_users.remaining_blocked_count', { count })
    }
  }
}
</script>
