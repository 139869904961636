import api from '@/api/Api'
import cookies from '@/cookies'

export default {

  config () {
    return api.get('/twitter')
  },

  update (notifications) {
    return api.put('/twitter', notifications)
  },

  requestToken (callback, lang) {
    return api.get('/twitter/request_token', { callback, lang })
  },

  requestAccess (token, verifier, lang) {
    const referral = cookies.get('referral')
    return api.post('/twitter/request_access', { token, verifier, lang, referral })
  },

  publish (comment, s3Route) {
    return api.post('/twitter/publish', { comment, s3Route })
  },

  disconnect () {
    return api.delete('/twitter')
  }
}
