<template
  ><section>
    <v-row>
      <v-col cols="auto" v-if="!agents">
        <v-card class="current-balance">
          <v-subheader>{{ $t("models.payment.credit_card") }}</v-subheader>
          <v-card-text>
            <currency class="x-large-title-text semibold" :amount="creditCard">
            </currency>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="auto">
        <v-card class="unit-balance">
          <v-subheader>{{
            $t("models.payment.influencer_amount")
          }}</v-subheader>
          <v-card-text>
            <currency
              class="large-title-text semibold"
              :amount="influencersNetAmount"
            >
            </currency>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col cols="auto" v-if="!agents">
        <v-card class="unit-balance">
          <v-subheader>{{ $t("models.payment.vat") }}</v-subheader>
          <v-card-text>
            <currency class="large-title-text semibold" :amount="vatAmount">
            </currency>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" v-if="agents">
        <v-card class="unit-balance">
          <v-subheader>{{ $t("models.payment.fee") }}</v-subheader>
          <v-card-text>
            <currency class="large-title-text semibold" :amount="feeAmount">
            </currency>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto">
        <v-card class="unit-balance">
          <v-subheader>{{ $t("models.payment.total_amount") }}</v-subheader>
          <v-card-text>
            <currency class="large-title-text semibold" :amount="amountTotal">
            </currency>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import PaymentApi from "@/api/admin/PaymentApi";
import { mapState } from "vuex";

export default {
  name: "PaymentStatistics",
  props: {
    filter: { type: Object, default: () => ({}) },
    agents: Boolean
  },
  data() {
    return {
      statistics: []
    };
  },
  computed: {
    ...mapState("profile", ["currentUser", "isAdmin"]),
    creditCard() {
      const rows = this.statistics.filter(s => s.creditCard);

      return rows.reduce((acum, s) => acum + s.amount + s.vat, 0);
    },
    influencersNetAmount() {
      const sales = this.statistics.filter(s => s.resource !== "wallets");
      return sales.reduce((acum, s) => acum, 0);
    },
    feeAmount() {
      let acum = this.statistics.reduce((acum, s) => acum + s.agentFee, 0);
      const feeplatform = acum * (20 / 100);
      return acum - feeplatform;
    },
    vatAmount() {
      return this.statistics.reduce((acum, s) => acum + s.vat, 0);
    },
    amountTotal() {
      let acum = 0;
      this.statistics.map(s => {
        acum += s.amount;
      });
      const feeplatform = acum * (20 / 100);
      return acum - feeplatform;
    }
  },
  watch: {
    filter() {
      this.startLoading();
    }
  },
  methods: {
    stopLoading() {
      clearTimeout(this.timeout);
    },
    startLoading() {
      this.stopLoading();
      this.timeout = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },
    async loadItems() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const { data, error } = this.isAdmin
        ? await PaymentApi.statistics(this.filter)
        : await PaymentApi.representedStatistics(this.filter);
      if (!error) {
        this.statistics = data;
      }
      this.loading = false;
    }
  },
  mounted() {
    this.loadItems();
  }
};
</script>
