<template>
  <section>
    <div class="row" v-if="!loading">
      <div class="col-12">
        <h5>{{ $t('application.chat_setting.setting.subtittle') }}:</h5>
      </div>
      <div class="col-12 filter">
        <fieldset>
          <legend style="margin: 5px 10px;padding: 5px;">
            <input
              type="checkbox"
              id="allfree"
              name="allfree"
              class="regular-check"
              v-model="all"
              @change="allSelected()"
            />
            <label for="allfree"
              >{{ $t('chats.new_broadcast.type.all') }} +
              {{ $t('chats.new_broadcast.type.free') }}</label
            >
          </legend>
          <input
            type="checkbox"
            id="fans"
            name="fans"
            class="regular-check sub"
            @change="changeSelected()"
            v-model="fans"
          />
          <label for="fans">{{ $t('chats.new_broadcast.type.fans') }}</label
          ><br />
          <div v-if="fans">
            <input
              type="radio"
              id="paymentFan"
              name="subFan"
              class="regular-radio sub"
              @change="changeSelected()"
              @click="
                () => {
                  fansFree = false
                }
              "
            />
            <label for="paymentFan">{{
              $t('chats.new_broadcast.type.paid_chat')
            }}</label
            ><br />
            <input
              type="radio"
              id="freeFan"
              name="subFan"
              class="regular-radio sub"
              @change="changeSelected()"
              @click="
                () => {
                  fansFree = true
                }
              "
            />
            <label for="freeFan">{{
              $t('chats.new_broadcast.type.free')
            }}</label
            ><br />
          </div>
          <input
            type="checkbox"
            id="followers"
            name="followers"
            class="regular-check sub"
            @change="changeSelected()"
            v-model="followers"
          />
          <label for="followers">{{
            $t('chats.new_broadcast.type.followers')
          }}</label
          ><br />
          <div v-if="followers">
            <input
              type="radio"
              id="paymentFollower"
              name="subFollowers"
              class="regular-radio sub"
              @change="changeSelected()"
              @click="
                () => {
                  followersFree = false
                }
              "
            />
            <label for="paymentFollower">{{
              $t('chats.new_broadcast.type.paid_chat')
            }}</label
            ><br />
            <input
              type="radio"
              id="freeFollower"
              name="subFollowers"
              class="regular-radio sub"
              @change="changeSelected()"
              @click="
                () => {
                  followersFree = true
                }
              "
            />
            <label for="freeFollower">{{
              $t('chats.new_broadcast.type.free')
            }}</label
            ><br />
          </div>
          <input
            type="checkbox"
            id="followed"
            name="followed"
            class="regular-check sub"
            @change="changeSelected()"
            v-model="followedByMe"
          />
          <label for="followed">{{
            $t('chats.new_broadcast.type.followed')
          }}</label
          ><br />
          <div v-if="followedByMe">
            <input
              type="radio"
              id="paymentFollowed"
              name="subFollowed"
              class="regular-radio sub"
              @change="changeSelected()"
              @click="
                () => {
                  followedByMeFree = false
                }
              "
              disabled
            />
            <label for="paymentFollowed">{{
              $t('chats.new_broadcast.type.paid_chat')
            }}</label
            ><br />
            <input
              type="radio"
              id="freeFollowed"
              name="subFollowed"
              class="regular-radio sub"
              @change="changeSelected()"
              @click="
                () => {
                  followedByMeFree = true
                }
              "
            />
            <label for="freeFollowed">{{
              $t('chats.new_broadcast.type.free')
            }}</label
            ><br />
          </div>
          <input
            type="checkbox"
            :style="{ 'margin-bottom: 15px': !others }"
            id="others"
            name="others"
            class="regular-check sub"
            @change="changeSelected()"
            v-model="others"
          />
          <label for="others">{{ $t('chats.new_broadcast.type.others') }}</label
          ><br />
          <div v-if="others">
            <input
              type="radio"
              id="paymentOther"
              name="subOther"
              class="regular-radio sub"
              @change="changeSelected()"
              @click="
                () => {
                  othersFree = false
                }
              "
            />
            <label for="paymentOther">{{
              $t('chats.new_broadcast.type.paid_chat')
            }}</label
            ><br />
            <input
              type="radio"
              id="freeOther"
              name="subOther"
              class="regular-radio sub"
              @change="changeSelected()"
              @click="
                () => {
                  othersFree = true
                }
              "
            />
            <label for="freeOther">{{
              $t('chats.new_broadcast.type.free')
            }}</label
            ><br />
          </div>
          <div style="margin-bottom: 10px"></div>
        </fieldset>
      </div>
      <div class="col-12">
        <h5>{{ $t('application.chat_setting.default_price') }}</h5>
      </div>
      <div
        class="row"
        v-for="(item, index) in sections"
        :key="item.title"
        style="margin: 0px 5px;"
      >
        <div class="col-7" v-if="item.show">
          {{ item.title }}
        </div>
        <div class="col-5" v-if="item.show">
          <InputCurrency
            :default-price="item.defaultPrice"
            :placeholder="item.placeholder"
            :disabled="all && index === 0"
            canReset
            @newPrice="item.updateMethod"
          ></InputCurrency>
        </div>
      </div>
      <Buttons
        class="saveBtn"
        @handler="() => (showWarningApplyAll = !showWarningApplyAll)"
        :text="$t('actions.save')"
        :loading="saving"
      ></Buttons>
    </div>
    <div style="text-align: center" v-else>
      <Spinner color="primary" />
    </div>
    <Dialog
      :show="showWarningApplyAll"
      @hide="() => (showWarningApplyAll = !showWarningApplyAll)"
      :title="$t('application.chat_setting.setting.title')"
    >
      <template slot="bodyDialog">
        <div>
          <p class="ml-1">
            {{ $t('application.chat_setting.setting.save.subtittle') }}
          </p>
          <div class="col background-input" :class="classCss">
            <div>
              <input
                type="radio"
                name="applyAll"
                checked
                class="regular-check"
                @click="
                  () => {
                    applyAll = true
                  }
                "
              />
              <label
                class="ml-1"
                v-html="$t('application.chat_setting.setting.save.option_1')"
              ></label>
            </div>
            <div>
              <input
                type="radio"
                name="applyAll"
                class="regular-check"
                @click="
                  () => {
                    applyAll = false
                  }
                "
              />
              <label
                class="ml-1"
                v-html="$t('application.chat_setting.setting.save.option_2')"
              >
              </label>
            </div>
          </div>
        </div>
        <div class="footerWarning">
          <Buttons
            class="saveBtn"
            @handler="save"
            :text="$t('actions.save')"
            :loading="saving"
          ></Buttons>
          <Buttons
            class="saveBtn"
            color="#f44336"
            @handler="() => (showWarningApplyAll = !showWarningApplyAll)"
            :text="$t('actions.cancel')"
          ></Buttons>
        </div>
        <div class="spacer"></div>
      </template>
    </Dialog>
  </section>
</template>

<script>
import InputCurrency from '@/components/chat/component/InputCurrency'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import Buttons from '@/components/chat/component/Buttons'
import Dialog from '@/components/chat/component/Dialog'
import Spinner from '@/components/chat/component/Spinner'
import { AppActions, ChatActions } from '@/store'

export default {
  name: 'ChatSettings',
  components: { Buttons, InputCurrency, Spinner, Dialog },
  computed: {
     darkTheme(){
      return this.$vuetify.theme.dark
    },
    sections () {
      return [
        {
          title: this.$t('application.chat_setting.default_price_sexting'),
          defaultPrice: this.messagePrice,
          placeholder: 'Default Price Sexting',
          updateMethod: this.updateSexting,
          show: true
        },
        {
          title: this.$t('application.chat_setting.default_price_media'),
          defaultPrice: this.mediaPrice,
          placeholder: 'Default Price Media',
          updateMethod: this.updateMedia,
          show: true
        },
        {
          title: this.$t('application.chat_setting.default_price_video'),
          defaultPrice: this.videochatPrice,
          placeholder: 'XX.xx / min',
          updateMethod: this.updateVideo,
          show: true
        }
      ]
    },
    attrs () {
      return {
        followers: this.followers,
        followersFree: this.followersFree,
        fans: this.fans,
        fansFree: this.fansFree,
        others: this.others,
        othersFree: this.othersFree,
        followedByMe: this.followedByMe,
        followedByMeFree: this.followedByMeFree,
        messagePrice: this.messagePrice,
        mediaPrice: this.mediaPrice,
        videochatPrice: this.videochatPrice,
        translateLanguage: this.translateLanguage
      }
    },
    classCss () {
      return {
        dark: this.darkTheme
      }
    }
  },
  data () {
    return {
      loading: false,
      saving: false,
      all: false,
      followers: false,
      followersFree: false,
      fans: false,
      fansFree: false,
      others: false,
      othersFree: false,
      followedByMeFree: false,
      followedByMe: false,
      messagePrice: 0,
      mediaPrice: 0,
      videochatPrice: 0,
      showWarningApplyAll: false,
      translateLanguage: '',
      applyAll: true
    }
  },
  methods: {
    changeSelected () {
      this.showError = true
      this.all = false
    },
    async save () {
      this.saving = true
      const form = this.attrs
      form.applyAll = this.applyAll

      const { data, error } = await ConversationsApi.updateSetting(form)
      if (!error) {
        this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
      } else {
        this.$store.dispatch(AppActions.SetNotification, {
          type: 'error',
          error: data
        })
      }
      this.$store.dispatch(AppActions.ShowNotification, true)
      this.saving = false
      this.applyAll = true
      this.showWarningApplyAll = false
    },
    updateSexting (value) {
      this.messagePrice = value
    },
    updateMedia (value) {
      this.mediaPrice = value
    },
    updateVideo (value) {
      this.videochatPrice = value
    },
    allSelected () {
      if (this.all) {
        this.othersFree = true
        this.others = true
        this.followersFree = true
        this.followers = true
        this.followedByMeFree = true
        this.followedByMe = true
        this.fansFree = true
        this.fans = true
        setTimeout(() => {
          document.getElementById('freeFollower').checked = true
          document.getElementById('paymentFollower').checked = false
          document.getElementById('freeFan').checked = true
          document.getElementById('paymentFan').checked = false
          document.getElementById('freeOther').checked = true
          document.getElementById('paymentOther').checked = false
          document.getElementById('freeFollowed').checked = true
          document.getElementById('paymentFollowed').checked = false
        }, 100)
      }
    },
    checkRadios (data) {
      if (data.followers) {
        this.followers = data.followers
        this.followersFree = data.followersFree
        setTimeout(() => {
          document.getElementById('freeFollower').checked = data.followersFree
          document.getElementById(
            'paymentFollower'
          ).checked = !data.followersFree
        }, 100)
      }
      if (data.fans) {
        this.fans = data.fans
        this.fansFree = data.fansFree
        setTimeout(() => {
          document.getElementById('freeFan').checked = data.fansFree
          document.getElementById('paymentFan').checked = !data.fansFree
        }, 100)
      }
      if (data.others) {
        this.others = data.others
        this.othersFree = data.othersFree
        setTimeout(() => {
          document.getElementById('freeOther').checked = data.othersFree
          document.getElementById('paymentOther').checked = !data.othersFree
        }, 100)
      }
      if (data.followedByMe) {
        this.followedByMe = data.followedByMe
        this.followedByMeFree = data.followedByMeFree
        setTimeout(() => {
          document.getElementById('freeFollowed').checked =
            data.followedByMeFree
          document.getElementById(
            'paymentFollowed'
          ).checked = !data.followedByMeFree
        }, 100)
      }
      setTimeout(() => {
        this.all =
          data.followedByMeFree &&
          data.othersFree &&
          data.fansFree &&
          data.followersFree
        document.getElementById('allfree').checked = this.all
      }, 150)
    }
  },
  async beforeMount () {
    this.loading = true
    const { data } = await ConversationsApi.settings()
    this.loading = false
    this.checkRadios(data)
    this.translateLanguage = data.translateLanguage
    this.messagePrice = data.messagePrice
    this.mediaPrice = data.mediaPrice
    this.videochatPrice = data.videochatPrice
  }
}
</script>

<style lang="scss" scoped>
.saveBtn {
  padding: 5px 20px;
  background-color: #41b849;
  width: 100%;
  font-family: Quicksand, sans-serif !important;
}
.footerWarning {
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
}
fieldset {
  border-radius: 10px;
}
.filter {
  margin-top: -15px;
  .regular-check {
    -webkit-appearance: none;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 4px;
    border-radius: 1px;
    width: 8px !important;
    height: 8px !important;
    display: inline-block;
    position: relative;
    background-color: transparent;
    margin-right: 10px;
    .main {
      position: absolute;
      top: -25px;
      background: #fff;
      padding: 0 10px;
    }
    &.sub {
      margin-left: 25px;
    }
  }
  .regular-check:checked {
    background-color: var(--v-primary-base);
  }
  .regular-check:checked:disabled {
    background-color: #939393;
  }
  div {
    margin-left: 15px;

    .regular-radio {
      -webkit-appearance: none;
      border: 1px solid #cacece;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 4px;
      border-radius: 20px;
      display: inline-block;
      position: relative;
      background-color: transparent;
      margin-right: 5px;
      &.sub {
        margin-left: 25px;
      }
    }
    .regular-radio:checked {
      background-color: var(--v-primary-base);
    }
    .regular-radio:checked:disabled {
      background-color: #939393;
    }
  }
}

.button__text {
  font: bold 20px 'Quicksand', san-serif;
  color: #ffffff;
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
.background-input {
  background: #d7d7d7;
  border-radius: 10px;
  input {
    accent-color: var(--v-primary-base);
  }
  &.dark {
    background: #5e5a5a !important;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
