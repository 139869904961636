<template>
  <section>
    <h1 class="mb-2">{{ $t('withdraws.withdraw_methods.title') }}</h1>

    <WithdrawDataSection class="mb-2"/>
    <WithdrawMethodSection/>
  </section>
</template>
<script>
import WithdrawDataSection from '@/components/withdraws/WithdrawDataSection'
import WithdrawMethodSection from '@/components/withdraws/WithdrawMethodSection'

export default {
  name: 'WithdrawConfigSection',
  components: {
    WithdrawDataSection,
    WithdrawMethodSection
  },
  data () {
    return {
      showWithdrawDataForm: true
    }
  }
}
</script>
