<template>
  <div class="pa-2 border-bottom d-flex align-center flex-wrap">
    <c-btn v-if="!isMediumScreenOrGreater"
      text color="secondary"
      @click.stop="emitBack">
      <v-icon>arrow_back</v-icon>
    </c-btn>
    <span class="title-text semibold flex-grow-1">
      {{ $t('chat_broadcast.title') }}
    </span>
    <c-btn
      @click.stop="showDialog">
      {{ $t('chat_broadcast.new') }}
    </c-btn>
    <MultimediaMessageDialog v-model="dialog"
      broadcast />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ChatActions } from '@/store'
import dialog from '@/components/mixins/dialog'
import MultimediaMessageDialog from './MultimediaMessageDialog'
import messages from '../locales/all.json'

export default {
  name: 'BroadcastHeader',
  mixins: [dialog],
  components: { MultimediaMessageDialog },
  i18n: { messages },
  computed: {
    ...mapState('application', ['isMediumScreenOrGreater'])
  },
  data () {
    return {
      showMultimedia: false
    }
  },
  methods: {
    emitBack () {
      this.$store.dispatch(ChatActions.SelectBroadcast, false)
    }
  }
}
</script>
