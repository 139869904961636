import { jsPDF as PDF } from 'jspdf'

const pdf = () => {
  const doc = new PDF()
  doc.setFontSize(12)
  let offsetX = 10
  let offsetY = 15
  return {
    width () {
      return doc.internal.pageSize.getWidth()
    },
    height () {
      return doc.internal.pageSize.getHeight()
    },
    offsetX (newOffset = null) {
      if (newOffset === null) {
        return offsetX
      }
      offsetX = newOffset
      return this
    },
    offsetY (newOffset = null) {
      if (newOffset === null) {
        return offsetY
      }
      offsetY = newOffset
      return this
    },

    image (url, options = {}) {
      const deltaX = options.offsetX || 0
      const deltaY = options.offsetY || 0
      const width = options.width || this.width()
      const height = options.height || this.height()
      const img = new Image()
      img.src = url
      return new Promise((resolve) => {
        img.onload = async () => {
          await doc.addImage(img, 'PNG', deltaX, deltaY, width, height, null, 0)
          resolve()
        }
      })
    },

    text (text, options = {}) {
      const deltaX = options.offsetX || 0
      const deltaY = options.offsetY || 0
      const align = options.align || 'left'
      const maxWidth = options.maxWidth
      doc.text(text.toString(), offsetX + deltaX, offsetY + deltaY, { maxWidth, align })
    },

    title (text) {
      const fontSize = doc.getFontSize()
      doc.setFontSize(18)
      this.textLine(text)
      doc.setFontSize(fontSize)
      return this
    },

    textLine (text) {
      doc.text(text, offsetX, offsetY)
      offsetY += 10
      return this
    },
    lineBreak (height = 5) {
      offsetY += height
      return this
    },
    divider (options) {
      const opts = options || {}
      const args = [
        offsetX + (opts.offsetX || 0),
        offsetY + (opts.offsetY || 0),
        doc.internal.pageSize.getWidth() - offsetX + (opts.offsetX || 0),
        offsetY + (opts.offsetY || 0)

      ]
      doc.line(...args)
      return this
    },
    save (filename) {
      doc.save(filename)
    }
  }
}
export default {
  methods: {
    async generatePdf (invoice) {
      const companyNamespace = invoice.agencyPayout ? 'payout_agency' : 'artemisa'
      const builder = pdf()
      const invoiceNumber = this.$t('invoicing.invoice_number', { number: invoice.billNumber || invoice.uuid })
      builder.text(invoiceNumber, { offsetX: builder.width() * 0.35 })
      builder.offsetY(25)
      builder.divider({ offsetY: -8 })
      builder.offsetX(25)
      builder.lineBreak(10)
      if (invoice.commitedAt || invoice.date) {
        const date = this.$t('invoicing.invoice_date', { date: invoice.commitedAt || invoice.date })
        builder.textLine(date)
      }
      builder.lineBreak(10)
      // user data
      builder.title(invoice.fullName)
      const documentType = this.$t(`models.influencer_request.id_document_types.${invoice.documentType}`)
      builder.textLine(`${documentType}: ${invoice.documentNumber || invoice.document}`)
      builder.textLine(invoice.address)
      builder.textLine(`${invoice.postalCode}, ${invoice.city}`)
      builder.textLine(invoice.province)
      builder.textLine(invoice.country)
      // builder.textLine(`Email: ${invoice.email}`)
      builder.lineBreak(10)
      // Loverfans data
      const name =
      builder.title(this.$t(`application.${companyNamespace}.title`))
      builder.textLine(this.$t(`application.${companyNamespace}.name`))
      builder.textLine(this.$t(`application.${companyNamespace}.cif`))
      builder.textLine(this.$t(`application.${companyNamespace}.address`))
      builder.textLine(this.$t(`application.${companyNamespace}.city`))
      builder.textLine(this.$t(`application.${companyNamespace}.country`))
      // builder.textLine(`Web: https://${this.$t('site')}`)
      if (!invoice.agencyPayout) builder.textLine('Email: financial@artemisa3000.com')
      builder.lineBreak(20)
      // Invoice data
      // payment
      let date = new Date(invoice.commitedAt || invoice.date)
      const options = { year: 'numeric', month: 'long' }
      date = date.toLocaleDateString(this.$i18n.locale, options)
      builder.text(this.$t('invoicing.new_concept', { date, billNumber: invoice.billNumber }), {
        maxWidth: 125,
      })
      builder.lineBreak(5)
      builder.text(invoice.baseAmount, {
        offsetX: builder.width() - builder.offsetX() * 2, align: 'right'
      })
      builder.lineBreak(15)
      // fee
      builder.text(this.$t('application.invoice_fee'), {
        offsetX: builder.width() - builder.offsetX() * 3.5, align: 'right'
      })
      builder.text(`- ${invoice.feeAmount}`, {
        offsetX: builder.width() - builder.offsetX() * 2, align: 'right'
      })
      builder.lineBreak(10)
      // vat
      builder.text(this.$t('application.vat'), {
        offsetX: builder.width() - builder.offsetX() * 3.5, align: 'right'
      })
      builder.text(invoice.vatAmount, {
        offsetX: builder.width() - builder.offsetX() * 2, align: 'right'
      })
      builder.lineBreak(10)
      // tax
      builder.text(this.$t('application.invoice_tax'), {
        offsetX: builder.width() - builder.offsetX() * 3.5, align: 'right'
      })
      builder.text(`- ${invoice.taxAmount}`, {
        offsetX: builder.width() - builder.offsetX() * 2, align: 'right'
      })
      builder.lineBreak(10)
      // total
      builder.divider({ offsetY: -8 })
      builder.text('Total', {
        offsetX: builder.width() - builder.offsetX() * 3.5, align: 'right'
      })
      builder.text(invoice.starAmount, {
        offsetX: builder.width() - builder.offsetX() * 2, align: 'right'
      })

      if (invoice.country !== 'ES') {
        builder.lineBreak(15)
        builder.text(this.$t('invoicing.legal_subject'))
      }
      builder.save(`${invoice.billNumber || invoice.uuid}.pdf`)
    }
  }
}
