import api from '@/api/Api'
import UserApi from '@/api/UserApi'
import PaymentApi from '@/api/PaymentApi'
import ProfileApi from '@/api/ProfileApi'
import cookies from '@/cookies'

const actionsTypes = {
  FetchUser: 'fetch_user',
  LoadToken: 'load_token',
  SetToken: 'set_token',
  AddItem: 'add_item',
  RemoveItem: 'remove_item',
  StartCheckout: 'start_checkout'
}
const state = {
  user: null,
  checkoutToken: cookies.get('checkoutToken') || '',
  checkoutUser: null,
  checkoutItems: []
}

const mutations = {
  reset: (state) => {
    state.checkoutItems = []
    api.removeHeader('AuthToken')
    cookies.delete('checkoutToken')
  },

  set_user: (state, user) => {
    state.user = user
  },

  set_checkout_user: (state, user) => {
    state.checkoutUser = user
  },

  set_token: (state, token) => {
    state.checkoutToken = token
    api.setHeader('AuthToken', token)
    cookies.set('checkoutToken', token)
  },

  add_item (state, item) {
    const index = state.checkoutItems.findIndex(i => i.type === item.type && i.id === item.id)
    if (index === -1) {
      state.checkoutItems.push(item)
    }
    state.checkoutItems = [item]
  },

  remove_item (state, item) {
    const index = state.checkoutItems.findIndex(i => i.type === item.type && i.id === item.id)
    if (index !== -1) {
      state.checkoutItems.splice(index, 1)
    }
    state.checkoutItems = []
  }
}

const actions = {
  [actionsTypes.FetchUser]: async (context, username) => {
    if (context.state.user) {
      return
    }
    const { data } = await UserApi.find(username)
    context.commit('set_user', data)
  },
  [actionsTypes.LoadToken]: async (context) => {
    if (context.state.checkoutToken) {
      context.commit('set_token', context.state.checkoutToken)
      const { data } = await ProfileApi.profile()
      context.commit('set_checkout_user', data)
    }
  },
  [actionsTypes.SetToken]: (context, token) => {
    context.commit('set_token', token)
  },
  [actionsTypes.AddItem]: (context, item) => {
    context.commit('add_item', item)
  },
  [actionsTypes.RemoveItem]: (context, item) => {
    context.commit('remove_item', item)
  },
  [actionsTypes.StartCheckout]: (context) => {
    if (!context.state.checkoutItems.length) {
      return
    }
    return PaymentApi.checkout(context.state.checkoutItems)
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'checkout',
  namespaced: true,
  state,
  mutations,
  actions
}
