<template>
  <section>
    <h1 class="mb-2">
      {{ $t('withdraws.withdraw_methods.title') }}
    </h1>
    <c-btn v-if="hasDataConfig"
           small
           @click="showDialog">
      {{ buttonText }}
    </c-btn>
    <span v-if="!hasDataConfig">
      {{ $t('withdraws.withdraw_methods.withdraw_data_needed') }}
    </span>
    <WithdrawMethodList v-else/>

    <WithdrawMethodDialog v-model="dialog"/>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import dialog from '@/components/mixins/dialog'
import WithdrawMethodList from '@/components/withdraws/WithdrawMethodList'
import WithdrawMethodDialog from '@/components/withdraws/WithdrawMethodDialog'

export default {
  name: 'WithdrawMethodsSection',
  mixins: [dialog],
  components: {
    WithdrawMethodList,
    WithdrawMethodDialog
  },
  computed: {
    ...mapState('withdraws', ['withdrawData', 'withdrawMethods']),
    hasDataConfig () {
      return this.withdrawData.fullName && this.withdrawData.country && this.withdrawData.province && this.withdrawData.city && this.withdrawData.postalCode && this.withdrawData.address
    },
    buttonText () {
      return this.withdrawMethods.length ? this.$t('actions.change') : this.$t('actions.add')
    }
  }
}
</script>
