<template>
  <section>
    <TitleOptionTab
      :attrs="title"
      @selectTab="onTab"
      :forceSelected="forceSelected"
    />
    <CreateListUser
      v-if="create"
      :isEdit="isEdit"
      :editList="editList"
      @removeDefaultContent="removeDefaultContent"
      @cancel-edit-list="onTab('list')"
    />
    <ViewListUser v-if="list" @editItemList="editItemList" />
  </section>
</template>

<script>
import TitleOptionTab from '@/components/chat/component/TitleOptionTab'
import CreateListUser from '@/components/chat/components/listUser/CreateListUser'
import ViewListUser from '@/components/chat/components/listUser/ViewListUser'

export default {
  name: 'ListUser',
  components: { TitleOptionTab, CreateListUser, ViewListUser },
  data () {
    return {
      title: [
        {
          iconType: 'newMessage',
          title: 'application.chat_setting.userList.title_1',
          call: 'create',
          icon: false,
          show: true
        },
        {
          iconType: 'newMessage',
          title: 'application.chat_setting.userList.title_2',
          call: 'list',
          icon: false,
          show: true
        }
      ],
      create: false,
      list: false,
      isEdit: false,
      editList: null,
      forceSelected: null
    }
  },

  methods: {
    onTab (name) {
      this.create = name === 'create'
      this.list = name === 'list'
      if (name === 'list') {
        this.forceSelected = this.title[1]
        this.removeDefaultContent()
      }
    },
    editItemList (editList) {
      this.isEdit = true
      this.editList = editList
      this.title[0].title = 'application.chat_setting.userList.title_3'
      this.forceSelected = this.title[0]
      this.onTab('create')
    },
    removeDefaultContent () {
      this.isEdit = false
      this.title[0].title = 'application.chat_setting.userList.title_1'
      this.editList = null
    }
  }
}
</script>
