<template>
  <section>
    <v-data-table
      class="elevation-2"
      :hide-default-footer="noPagination"
      :headers="headers"
      :items="items"
      :loading="loading"
      item-key="id"
      :locale="$i18n.locale"
      :page.sync="page"
      :items-per-page.sync="perPage"
      :server-items-length="totalItems"
    >
      <template #top>
        <BackRow/>
        <v-row v-if="!noFilter" align="center" class="px-2">
          <v-col cols="12" sm="3">
            <date-picker-input
              range
              :label="$t('labels.filter_dates')"
              v-model="filterDates"
            >
            </date-picker-input>
          </v-col>

        </v-row>
      </template>
      <template #item.uuid="{ item }">
        {{ item.billNumber || item.uuid }}
      </template>
      <template #item.amount="{ item }">
        <Currency :currency="item.currency" :amount="item.amount"></Currency>
      </template>
      <template #item.fee="{ item }">
        {{ item.fee || 0 }}% -
        <Currency :currency="item.currency" :amount="item.feeAmount"></Currency>
      </template>
      <template #item.vat="{ item }">
        {{ item.vat || 0 }}% -
        <Currency :currency="item.currency" :amount="item.vatAmount"></Currency>
      </template>
      <template #item.taxes="{ item }">
        {{ item.taxes || 0 }}% -
        <Currency :currency="item.currency" :amount="item.taxAmount"></Currency>
      </template>
      <template #item.totalAmount="{ item }">
        <Currency :currency="item.currency" :amount="item.totalAmount"></Currency>
      </template>
      <template #item.status="{ item }">
        <p @click="openDialogRejected(item.status, item.details)">
          {{ $t(`models.withdraw.statuses.${item.status}`) }}
        </p>
      </template>
      <template #item.actions="{ item }">
        <v-btn v-if="isCommited(item)"
               icon @click="download(item)">
          <v-icon>file_download</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <c-dialog width="400" :value="showDetailError" @input="close">
      <template #title>
        <h1>{{ $t(`models.withdraw.statuses.rejected`) }}</h1>
      </template>
      <v-icon>warnind_cicle</v-icon>
      {{ detailsError }}
    </c-dialog>
  </section>
</template>
<script>
import WithdrawApi from '@/api/WithdrawApi'
import table from '@/components/mixins/table'
import invoicePdf from '../invoicing/mixins/invoice_pdf'
import messages from '../invoicing/locales/all.json'
import {mapState} from "vuex";

export default {
  name: 'PaymentTable',
  mixins: [table(WithdrawApi), invoicePdf],
  i18n: { messages },
  data () {
    return {
      headers: [
        {
          text: this.$t('models.withdraw.date'),
          value: 'date',
          sortable: false
        },
        {
          text: this.$t('invoicing.payout_uuid'),
          value: 'uuid',
          sortable: false
        },
        {
          text: this.$t('models.withdraw.type'),
          value: 'type_text',
          sortable: false
        },
        {
          text: this.$t('models.withdraw.amount'),
          value: 'amount',
          sortable: false
        },
        {
          text: this.$t('models.withdraw.fee'),
          value: 'fee',
          sortable: false
        },
        {
          text: this.$t('models.withdraw.tax'),
          value: 'taxes',
          sortable: false
        },
        {
          text: this.$t('models.withdraw.vat'),
          value: 'vat',
          sortable: false
        },
        {
          text: this.$t('models.withdraw.total_amount'),
          value: 'totalAmount',
          sortable: false
        },
        {
          text: this.$t('models.withdraw.status'),
          value: 'status',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      filterDates: [],
      showDetailError: false,
      detailsError: ''
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    /*   tableTitle () {
      return this.title || this.$t('models.withdraws.name')
    }, */

    query () {
      const [after, before] = this.filterDates
      return {
        lastPath: null,
        createdAfter: after,
        createdBefore: before
      }
    }
  },
  methods: {
    close () {
      this.showDetailError = !this.showDetailError
    },
    isCommited (item) {
      return item.status === 'commited'
    },
    async download (item) {
      if (!item.withdrawMethod) {
        item.withdrawMethod = {
          type: item.type_text
        }
      }
      if (!item.withdrawData) {
        item.withdrawData = {
          business: "first_person",
          userId: this.currentUser.id,
          fullName: this.currentUser.fullName,
          birthdate: this.currentUser.birthdate,
          country: this.currentUser.country,
          province: this.currentUser.province,
          postalCode: this.currentUser.postalCode,
          city: this.currentUser.city,
          address: this.currentUser.address,
          documentType: this.currentUser.documentType,
          documentNumber: this.currentUser.documentNumber,
          documentCountry: this.currentUser.documentCountry,
          documentExpiration: this.currentUser.documentExpiration,
          bypassIrpf: false,
          deleted: false,
          withdrawDataUrl: null,
          identificationUrl: null
        }
      }
      item.userId = item.userId || this.currentUser.id
      item.fullName = item.fullName || this.currentUser.fullName
      item.birthdate = item.birthdate || this.currentUser.birthdate
      item.country = item.country || this.currentUser.country
      item.province = item.province || this.currentUser.province
      item.postalCode = item.postalCode || this.currentUser.postalCode
      item.city = item.city || this.currentUser.city
      item.address = item.address || this.currentUser.address
      item.documentType = item.documentType || this.currentUser.documentType
      item.documentNumber = item.documentNumber || this.currentUser.documentNumber
      item.documentCountry = item.documentCountry || this.currentUser.documentCountry
      item.documentExpiration = item.documentExpiration || this.currentUser.documentExpiration
      this.generatePdf({
        ...item,
        billNumber: item.billNumber || item.uuid,
        country: this.$country(item.country),
        countryCode: item.country,
        baseAmount: this.$currency(item.amount, item.currency),
        feeAmount: this.$currency(item.feeAmount, item.currency),
        vatAmount: this.$currency(item.vatAmount, item.currency),
        taxAmount: this.$currency(item.taxAmount, item.currency),
        starAmount: this.$currency(item.totalAmount, item.currency)
      })
    },
    mapItem (withdraw) {
      const feeAmount = (withdraw.amount * (withdraw.fee || 0)) / 100
      const taxAmount = ((withdraw.amount - feeAmount) * (withdraw.taxes || 0)) / 100
      const vatAmount = ((withdraw.amount - feeAmount) * (withdraw.vat || 0)) / 100
      return {
        ...withdraw,
        feeAmount,
        taxAmount,
        vatAmount,
        totalAmount: withdraw.amount - feeAmount - taxAmount + vatAmount,
        date: this.$date(withdraw.date, 'YYYY-MM-DD'),
        payoutDate: this.$date(withdraw.payoutDate || withdraw.date, 'YYYY-MM-DD'),
        commitedAt: withdraw.commitedAt ? this.$date(withdraw.commitedAt, 'YYYY-MM-DD') : null,
        type_text: this.$t(`withdraws.withdraw_methods.types.${withdraw.withdrawMethod ? withdraw.withdrawMethod.type : 'paypal'}`)
      }
    },
    openDialogRejected (status, details) {
      if (status === 'rejected') {
        this.detailsError = details
        this.showDetailError = !this.showDetailError
      }
    }
  }
}
</script>
