import api from '@/api/Api'
import store, { AppActions, ProfileActions, PostActions } from '@/store'

const ws = api.websocket()

const updateNotifications = event => {
  store.dispatch(AppActions.UpdateHasNotifications)
}

const updateChat = () => {
  store.dispatch(AppActions.UpdateHasMessages, true)
}

const updateProfile = event => {
  store.dispatch(ProfileActions.Set, event)
}

const updateWallet = event => {
  store.dispatch(ProfileActions.UpdateWallet, event)
}

const updatePost = event => {
  store.dispatch(PostActions.Fetch, { id: event.id, force: true })
}
const notificationCall = event => {
  if (store.state.application.dataCall.videochatId !== event.videochatId) {
    store.dispatch(AppActions.SetJoinVideoCall, true)
    store.dispatch(AppActions.SetDataCall, event)
    store.dispatch(AppActions.ShowNotificationsCall, true)
  }
}
const notificationUnCall = event => {
  store.dispatch(AppActions.ShowNotificationsCall, false)
}

const WebsocketPlugin = {
  install (Vue, options) {
    Vue.prototype.$ws = ws
    ws.subscribe('notification', updateNotifications)
    ws.subscribe('conversation-message', updateChat)
    ws.subscribe('update-profile', updateProfile)
    ws.subscribe('update-wallet', updateWallet)
    ws.subscribe('post-updated', updatePost)
    ws.subscribe('videochat-requested', notificationCall)
    ws.subscribe('videochat-rejected', notificationUnCall)
  }
}
export const websocket = ws
export default WebsocketPlugin
