<template>
  <v-form
    v-model="validForm" ref="form"
    @submit.prevent="submit">
    <FileUploader class="mb-5"
      autoupload
      v-model="files"
      max-images="5"
      content-type="image/*" />
    <v-text-field outlined
      :label="$t('models.product.name')"
      v-model="name"
      counter="75"
      :rules="[$vRequired, $vBannedWords(name), $vNoHtml(name)]">
    </v-text-field>
    <v-text-field outlined
      :label="$t('models.product.description')"
      v-model="description"
      :rules="[$vRequired, $vBannedWords(description), $vNoHtml(description)]">
    </v-text-field>
    <v-row align="center">
      <v-col cols="12" :sm="saleSoclums">
        <v-select outlined
          :label="$t('models.product.sale_type')"
          v-model="productType"
          :items="saleTypes"
          :rules="[$vRequired]">
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" v-if="isAuction">
        <v-text-field outlined
          persistent-hint
          :label="$t('models.product.auction_duration')"
          v-model="duration"
          :hint="$t('products.auction.duration_hint')"
          :rules="[$vRequired, $vInteger]">
        </v-text-field>
      </v-col>
    </v-row>
    <template v-if="isSale">
      <v-checkbox color="primary"
        :label="$t('models.product.infinite_stock')"
        v-model="infiniteStock">
      </v-checkbox>
      <p class="small-text">
        {{ $t('models.product.infinite_stock_hint') }}
      </p>
    </template>
    <v-row align="center">
      <v-col cols="2" class="text-right mb-8">
        <v-icon>monetization_on</v-icon>
        <span class="small-text">{{ $t('models.product.price') }}</span>
      </v-col>
      <v-col cols="10">
        <CurrencyInput outlined
          color="orange"
          v-model="initialPrice"
          hint="XXX,YY"
          :rules="[$vRequired]"
          minValue="500"
          maxValue="42000"
           />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" :sm="deliveryColumns">
        <v-select outlined hide-details
          :label="$t('models.product.delivery_type')"
          v-model="deliveryType"
          :items="deliveryTypes"
          :rules="[$vRequired]">
        </v-select>
      </v-col>
      <v-col v-if="isPacket"
        class="flex-grow">
        <CurrencyInput outlined
          color="orange"
          :label="$t('products.shipping_costs')"
          v-model="shippingCost"
          :hint="$t('products.shipping_cost_hint')"
          minValue="0"
          maxValue="42000"
          />
      </v-col>
    </v-row>
    <v-card-actions>
      <div>
        <b>Total: {{ totalPrice }} <br><span v-if="initialPrice+shippingCost > 42000" style="color: red; font-size: small">{{ $t('price.total')}}</span> </b>
      </div>
      <v-spacer />
      <c-btn text color="secondary"
        @click="close('close')">
          {{ $t('actions.close') }}
      </c-btn>
      <c-btn :disabled="!submitable"
        @click.stop.prevent="submit">
        {{ $t("actions.publish") }}
      </c-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import ProductService from '@/components/products/mixins/product_service'
import validatable from '@/components/mixins/validatable'

const Deliveries = ['digital', 'packet']
const Sales = ['sale', 'auction']

export default {
  name: 'ProductForm',
  mixins: [ProductService(), validatable('form')],
  data () {
    return {
      productType: '',
      name: '',
      description: '',
      deliveryType: '',
      initialPrice: '',
      shippingCost: 0,
      duration: null,
      infiniteStock: false,
      files: []
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    saleSoclums () {
      return this.isAuction ? 6 : 12
    },
    deliveryColumns () {
      return this.isPacket ? 6 : 12
    },

    deliveryTypes () {
      return Deliveries.map(value => ({ text: this.$t(`models.product.delivery_types.${value}`), value }))
    },

    saleTypes () {
      return Sales.map(value => ({ text: this.$t(`models.product.sale_types.${value}`), value }))
    },

    isPacket () {
      return this.deliveryType === 'packet'
    },

    isSale () {
      return this.productType === 'sale'
    },

    isAuction () {
      return this.productType === 'auction'
    },

    submitable () {
      const validFiles = !!this.files.length && !this.files.find(f => !f.uploaded)
      return this.validForm && validFiles && (this.initialPrice + this.shippingCost < 42001)
    },
    totalPrice () {
      return this.$currency((this.initialPrice || 0) + (this.shippingCost || 0))
    }
  },
  methods: {
    resetState () {
      this.productType = ''
      this.name = ''
      this.description = ''
      this.deliveryType = ''
      this.initialPrice = ''
      this.shippingCost = 0
      this.infiniteStock = false
      this.duration = null
      this.files = []
    },

    async onSubmit () {
      // TODO Log error
      const { data, error } = await this.createProduct({
        productType: this.productType,
        deliveryType: this.deliveryType,
        name: this.name,
        description: this.description,
        initialPrice: this.initialPrice,
        shippingCost: this.shippingCost,
        infiniteStock: this.infiniteStock,
        duration: this.duration,
        resources: this.files.map(f => f.location)
      })
      // TODO notify error
      this.$eventBus.publish('notification', {
        error,
        message: this.$t('snackbar.create_success')
      })
      if (!error) {
        this.$router.push({ name: 'product', params: { uuid: data.id } })
        this.close('submit', data)
      }
    },

    close (event, value) {
      this.resetState()
      this.$emit(event, value)
    }
  },

  mounted () {
    this.resetState()
  }
}
</script>
