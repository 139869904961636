<template>
<span>
  <slot name="label" :count="count">{{ count }}</slot>
</span>
</template>
<script>

export default {
  name: 'Timer',
  props: {
    startValue: Number,
    interval: { type: Number, default: 1000 },
  },
  data () {
    return {
      count: 0,
      timeout: null
    }
  },

  watch: {
    count (value) {
      if (this.count <= this.interval) this.stop()
    }
  },

  methods: {
    start () {
      this.count = this.startValue
      this.next()
    },

    next () {
      this.timeout = setTimeout(this.step.bind(this), this.interval)
    },

    step () {
      this.count = this.count - this.interval
      if (this.count > this.interval) {
        this.next()
      }
    },

    stop () {
      this.$emit('count-end')
      clearTimeout(this.timeout)
    }
  },

  created() {
    this.$nextTick(() => {
      this.start();
    })
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },
}

</script>
