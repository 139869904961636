<template>
  <UserSearchDialog
    :value="value"
    :search-action="searchAction"
    @input="hideDialog"
    @user-selected="emitUserSelected"
  />
</template>
<script>
import { UserActions } from '@/store'
import UserSearchDialog from '@/components/users/UserSearchDialog'

export default {
  name: 'FeaturedUserDialog',
  components: { UserSearchDialog },
  props: {
    value: { type: Boolean, default: true }
  },
  data() {
    return { searchAction: UserActions.SearchAffiliate }
  },
  methods: {
    hideDialog () {
      this.$emit('input', false)
    },
    emitUserSelected(user) {
      this.$emit('selected', user)
    }
  }
}
</script>
