<template>
  <c-btn
    v-bind="$attrs"
    :color="color"
    :hover-color="hoverColor"
    @click.stop.prevent="emitAction">
    <span v-if="isSpecialOfferActive">{{ specialButtonText }}<span class="oldPrice"> {{ $currency(resource.price) }}</span>&nbsp;{{ $currency(resource.price*(100 - config.specialOffer.discount) / 100) }}</span>
    <span v-else>{{ buttonText }}</span>
    <slot />
  </c-btn>
</template>
<script>
import config from "@/project";

export default {
  name: 'PurchasetButton',
  props: {
    resource: Object,
    wallet: Boolean,
    color: String,
    hoverColor: String,
    label: String
  },
  data () {
    return {
      config
    }
  },
  computed: {
    buttonText () {
      const { price, currency } = this.resource
      const localePrice = this.$currency(price * (this.isSpecialOfferActive ? (100 - config.specialOffer.discount) / 100 : 1), currency)
      return this.label || this.$t('posts.card_overlay.buy_action', { price: localePrice })
    },
    specialButtonText () {
      return this.label || this.$t('posts.card_overlay.special_buy_action')
    },
    isSpecialOfferActive () {
      if (this.wallet) return false
      const today = new Date()
      return config.specialOffer.active && config.specialOffer.startDate < today && config.specialOffer.endDate > today && config.specialOffer.discount > 0
    }
  },

  methods: {
    emitAction () {
      this.$emit('click', true)
    }
  }

}
</script>
