<template>
  <div>
    <div class="tabs-infochat" :class="ccsClass">
      <div
        class="tab-infochat"
        :class="ccsClass"
        ref="1"
        @click="selectTab(1, 'image')"
      >
        {{ $t("chats.gallery.images") }}
      </div>
      <div
        class="tab-infochat"
        :class="ccsClass"
        ref="2"
        @click="selectTab(2, 'video')"
      >
        {{ $t("chats.gallery.videos") }}
      </div>
      <div
        class="tab-infochat"
        :class="ccsClass"
        ref="3"
        @click="selectTab(3, 'audio')"
      >
        {{ $t("chats.gallery.audios") }}
      </div>
    </div>
    <div class="content-tab" :class="ccsClass">
      <ListView
        :cols="4"
        :items="items"
        :loading="loading"
        :empty-text="$t('listview.no_content')"
        @bottom-reached="loadItems"
      >
        <template #default="{item, index}">
          <Box :ratio="2 / 2" @click="selectedResource(item, index)">
            <div class="">
              <AudioPlayer
                class="attachment-img"
                v-if="item.type === 'audio' && activeTab === 3"
                :src="item.url"
                :sizeIcon="60"
              />
              <video
                v-if="item.type === 'video' && activeTab === 2"
                :src="item.url"
                class="attachment-img"
                controlslist="nodownload"
              ></video>
              <div v-if="!item.isPurchased" class="attachment-img">
                <FilePayment
                  :message="{
                    messageId: item.conversationMessageId,
                    url: item.url,
                    conversationId: item.conversationId,
                    searchMessage: true
                  }"
                  @refresh-message="refreshMessage"
                />
              </div>
              <img
                v-else-if="item.type === 'image' && activeTab === 1"
                :src="item.url"
                class="attachment-img"
                alt=""
              />
            </div>
          </Box>
        </template>
      </ListView>
    </div>
  </div>
</template>
<script>
import AudioPlayer from "./viewFiles/AudioPlayer";
import CursorService from "@/components/mixins/cursor_api";
import ConversationsApi from "@/api/conversations/ConversationsApi";
import { ChatActions } from "@/store";
import { mapState } from "vuex";
import FilePayment from "../components/viewFiles/FilePayment.vue";

export default {
  name: "MultimediaChat",
  mixins: [CursorService(ConversationsApi, "listAttachments")],
  components: { AudioPlayer, FilePayment },
  data() {
    return {
      contentTab: "",
      activeTab: 1,
      query: { type: "" }
    };
  },
  computed: {
    ...mapState("chats", ["selectedChat"]),
    darkTheme() {
      return this.$vuetify.theme.dark;
    },
    ccsClass() {
      return { dark: this.darkTheme };
    },
    getMessage() {
      return {
        conversationMessageId: this.items.conversationMessageId,
        conversationId: this.items.conversationId,
        searchMessage: true
      };
    }
    /*  listAttachments() {
      let list = [];
      if (this.activeTab === 3) {
        list = this.items.filter(a => a.type === "audio");
      }
      if (this.activeTab === 2) {
        list = this.items.filter(a => a.type === "video");
      }
      if (this.activeTab === 1) {
        list = this.items.filter(a => a.type === "image");
      }
      console.log(list);
      return list;
    } */
  },
  mounted() {
    this.selectTab(1, "image");
  },
  methods: {
    selectedResource(item, index = 0) {
      if (item.isPurchased && item.type !== "audio") {
        this.$store.dispatch(
          ChatActions.CarouselItemsList,
          this.addPaidFreeAttachments(this.items)
        );
        this.$store.dispatch(ChatActions.CarouselItemSelected, index);
        this.$store.dispatch(ChatActions.ShowCarousel, true);
      }
    },
    addPaidFreeAttachments(attachments) {
      return attachments.filter(att => att.isPurchased);
    },
    onLoadItems() {
      this.query.id = this.selectedChat.id;
      return !!this.selectedChat.id;
    },
    selectTab(ref, typeTab) {
      this.query.type = typeTab;
      this.resetCursor(true);
      this.contentTab = typeTab;
      this.$refs[this.activeTab].classList.remove("active");
      this.$refs[ref].classList.add("active");
      this.activeTab = ref;
    },
    refreshMessage(message) {
      this.resetCursor(true);
    }
  }
};
</script>

<style lang="scss">
.tabs-infochat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;

  &.dark {
    background-color: #1f2c34 !important;
  }

  :hover {
    background-color: #e0f2fa;
    cursor: pointer;
    color: #1f2c34 !important;
  }
}

.tab-infochat.active {
  border-bottom: 2px solid #6c757d;
}

.tab-infochat {
  padding: 14px;
  border-bottom: 2px solid #dbdbdb;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
  &.dark:hover {
    background-color: #2f434f;
    color: #bdbdbd !important;
  }
}

.content-tab {
  height: 100%;
  padding: 0 5px;
  background-color: white;

  &.dark {
    background-color: #121b22 !important;
  }
}
.borders {
  border: solid 1px red;
}
.attachment-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.attachment-audio {
  width: 100%;
}

[class*="attachment-"]:hover {
  cursor: pointer;
}
</style>
