<template>
  <section class="notification">
    <div class="row notif-content" @click="closeNotification()">
      <div class="center"><span class="material-icons" :style="cssStyle">{{ getIcon }}</span></div>
      <div class="text">{{getNotifMessage}}</div>
      <div class="progress">
        <div class="progress__bar"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { AppActions } from '@/store'

export default {
  name: 'NotificationAlert',
  computed: {
    ...mapState('application', ['isMobile', 'notification']),
    getTypes () {
      return [
        { type: 'info', icon: 'info', color: '#10a7c9' },
        { type: 'success', icon: 'check', color: '#13a800' },
        { type: 'error', icon: 'priority_high', color: '#c70000' },
        { type: 'copy', icon: 'check', color: '#13a800' },
      ]
    },
    getNotif () {
      return this.getTypes.filter(notif => notif.type === this.notification.type)
    },
    getIcon () {
      return this.getNotif[0].icon
    },
    getNotifMessage () {
      if (this.notification.type === 'error') {
        return this.getErrorType(this.notification)
      }
      return this.$t(`notifications.${this.notification.type}`)
    },
    cssStyle () {
      return {
        color: this.getNotif[0].color
      }
    }
  },
  methods: {
    closeNotification () {
      this.$store.dispatch(AppActions.ShowNotification, false)
    },
    getErrorType (error) {
      //return this.$t(`notifications.${error.errorCode}`, { error: this.notification.data.field })
      if (error && error.error && error.error.errorCode === 'media_in_use') {
        return this.$t(`notifications.media_in_use`)
      }
      return this.$t(`notifications.info`)
    }
  },
  mounted () {
    setTimeout( () => {
      this.$store.dispatch(AppActions.ShowNotification, false)
    },3000)
  }
}
</script>

<style lang="scss" scoped>

.notification {
  position: fixed;
  width: 100% !important;
  z-index: 20000000;
  top: 10%;
  .notif-content{
    position: relative;
    width: fit-content;
    margin: auto;
    background-color: #393838;
    padding: 10px 20px;
    border-radius: 50px;
    text-align: center;
    justify-content: center;
    .text {
      color: white;
    }

  }
}

.notification:hover {
  cursor: pointer;
}
.progress {
  padding: 2px;
  width: 100%;
  max-width: 500px;
  height: 5px;
}

.progress .progress__bar {
  height: 100%;
  width: 0%;
  background-color: var(--v-primary-base);
  animation: fill-bar 3s 1;
  animation-direction: reverse;
}

@keyframes fill-bar {
  from {width: 0%;}
  to {width: 100%;}
}

</style>
