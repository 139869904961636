import { UserActions } from '@/store'
import Api from '@/api/Api'
import Userapi from '@/api/UserApi'
import CursorService from '@/components/mixins/cursor_service'

const deconstructWatchers = (cursors) => {
  const properties = {}

  cursors.forEach(cursor => {
    properties[cursor.filter || 'query'] = {
      deep: true,
      handler: function (value) {
        const cancelToken = Api.cancelToken()
        this[cursor.name || 'cursor'].query = { ...value, cancelToken }
      }
    }
  })
  return properties
}

export default (options = {}) => {
  const { cursor, cursors } = options
  const innerCursors = []
  if (cursor === true) {
    innerCursors.push({ name: 'cursor', filter: 'query' })
  }

  if (cursors && cursors.length) {
    innerCursors.push(...cursors)
  }

  const data = {}
  innerCursors.forEach(cursor => {
    data[cursor.name] = {}
  })
  return {
    api: Userapi,
    data () {
      return data
    },
    watch: {
      ...deconstructWatchers(innerCursors)
    },
    methods: {
      findUser (username, force) {
        return this.$store.dispatch(UserActions.Fetch, { username, force })
      },

      searchUsers (query) {
        return this.$store.dispatch(UserActions.Search, query)
      },

      searchSubscriptions (query) {
        return this.$store.dispatch(UserActions.ActiveSubscriptions, query)
      }
    },
    beforeMount () {
      this.cancelToken = Api.cancelToken()
      this.cursors = innerCursors.map(cursor => {
        const query = this[cursor.filter || 'query']
        const cancelToken = Api.cancelToken()
        const c = CursorService(this.$store, {
          query: { ...query, cancelToken },
          action: cursor.action || UserActions.Search
        })
        c.$mount()
        this[cursor.name] = c
        return c
      })
    },
    destroyed () {
      this.cursors.forEach(c => c.$destroy())
    }
  }
}
