<template>
  <div class="card-user-top-content" :tabindex="0" @click="selected"
       :class="{mobile: isMobile,dark:darkTheme}">
    <div class="ml-1 mt-1 content-icon-shared" :class="{mobile: isMobile}">
      <c-btn icon small>
        <!--        <Shared sizeIcon="16"></Shared>-->
        <span class="icon-shared center">
        <Shared :user="user">
          <template #iconShared>
             <c-icon size="16" icon="Shared" :change-dark="false" color="white"></c-icon>
          </template>
        </Shared>
        </span>
      </c-btn>
    </div>
    <img :src="getSrcAvatar" style=" object-fit: cover" width="100%" height="175px">
    <div style="position: absolute">
        <span class="icon-top" :class="{mobile: isMobile}"
              :style="`background: ${getColor} 0% 0% no-repeat padding-box;`">
          <b>
            {{ userCount }}
          </b>
        </span>
    </div>
    <div class="px-auto content-username pt-2" style="height: 60px">
      <b style="display: flex;justify-content: center">
        {{ subString(user.displayName) }}
        <c-icon icon="Verified" v-if="user.trustedProfile" :size="12"></c-icon>
      </b>
      <p style="text-align: center">{{ subString(`@${user.username}`) }}</p>
    </div>
    <div class="price">
      <div class="col-12 button-price" :class="{mobile: isMobile}">
        <b v-html="subMonth"></b>
      </div>
      <!--<div class="button-discount" :class="{mobile: isMobile}">
        <b>+{{ $currency(this.cashReturn) }}</b>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Shared from '@/views/landing/specialCampaign/components/Shared.vue'

export default {
  name: 'CardUserTop',
  components: { Shared },
  props: {
    user: {
      type: Object,
      required: true
    },
    userCount: Number
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('preferences', ['darkTheme']),
    getSrcAvatar () {
      return this.user.avatarS3Route || '/images/no_content.png'
    },
    getColor () {
      if (this.userCount === 1) {
        return '#F2A411'
      }
      if (this.userCount === 2) {
        return '#9e9e9e'
      }
      if (this.userCount === 3) {
        return '#B0601E'
      }
      return '#F71953'
    },
    subMonth () {
      // añadir <br> despues de </b> cuando exista promo reembolso
      return `<b>${this.$currency(this.user.subscriptionPrice)}</b>/${this.$t('words.month')}`
    },
    monthSubscription () {
      return this.user.subscriptionOffers.find(s => s.type === 'month')
    },
    cashReturn () {
      return ((this.monthSubscription ? this.monthSubscription.basePrice : this.user.subscriptionPrice) * 25) / 100
    }
  },
  methods: {
    selected () {
      this.$emit('selected', this.user, this.userCount)
    },
    subString (value) {
      const length = value.length
      if (length > 13) {
        return `${value.substring(0, 14)}...`
      } else {
        return value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-top {
  position: absolute;
  top: -18px;
  left: 90px;
  color: white !important;
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #FFFFFF;
  border-radius: 5px 5px 23px 23px;
  width: 24px;
  height: 24px;
  text-align: center;

  &.mobile {
    width: 24px !important;
    left: 72px !important;
    height: 24px !important;
  }
}

.content-icon-shared {
  position: absolute;
  width: 184px;
  display: flex;
  justify-content: flex-end;

  &.mobile {
    width: 9rem !important;
  }
}

.icon-shared {
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.42;
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.card-user-top-content {
  box-shadow: 0px 3px 6px #DF38563D;
  border-radius: 8px;
  width: 196px;
  height: 289px;
  cursor: pointer;

  &.mobile {
    width: 171px !important;
  }

  &.dark {
    box-shadow: 0px 3px 6px white !important;
  }

  img {
    height: 175px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .content-username {
    height: 60px;
    border-bottom: solid 1px #E7E7E7;

    &.mobile {
      font-size: 14px;
    }
  }

  .price {
    background-color: rgba(63, 184, 72, 0.08);
    display: flex;
    align-items: center;
    padding: 3px;
    justify-content: space-between;
  }

  .button-price {
    // width: 96px;
    width: 100%;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;

    &.mobile {
      font-size: 13px !important;
    }

    b {
      color: #3FB848 !important;
      opacity: 1 !important;
    }
  }

  .button-discount {
    width: 96px;
    height: 40px;
    background-color: #effbec;
    color: #38CC0A;
    align-items: center;
    display: flex;
    justify-content: center;
    &.mobile{
      width: 72px !important;
      height: 40px !important;
    }
  }
}

</style>
