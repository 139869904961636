<template>
  <router-link
    :to="to"
    :target="targetBlank?'_blank':''"
    class="no-decoration">
    <slot name="dispalayname"></slot>
    <span style="display: flex; align-items: center">
            {{ `@${username}` }}
          <img
            v-if="trustedProfile"
            src="/images/check5.svg"
            height="12"
            style="margin-left:1px"
          />
          </span>
  </router-link>
</template>

<script>
export default {
  name: 'user-link',
  props: {
    targetBlank: {
      type: Boolean,
      default: true
    },
    to: {
      type: [Object, String],
      required: true
    },
    username: {
      type: String,
      required: true,
      default: 'user'
    },
    trustedProfile: Boolean
  }
}
</script>

<style scoped>

</style>
