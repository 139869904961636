<template>
  <v-card-text>
    <p>{{ $t('payments.products.success_message_1', { name: product.name, username: product.username }) }}</p>
    <p>{{ $t('payments.products.success_message_2', { username: product.username }) }}</p>
    <v-row>
      <v-col cols="6">
        <c-img class="rounded product mini"
          :src="product.preview.preview">
        </c-img>
      </v-col>
      <v-col cols="6">
        <UserAvatar :profile="false" :user="product.author" />
        <div class="my-8">
          <v-icon class="mr-1">shopping_basket</v-icon>
          <span>{{ product.name }}</span>
        </div>
        <div class="my-8">
          <v-icon class="mr-1">attach_money</v-icon>
          <Currency :amount="totalPrice" />
        </div>
        <c-btn @click="close">{{ $t('actions.show_product') }}</c-btn>
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
import { mapState } from 'vuex'
import UserAvatar from '@/components/users/UserAvatar'

export default {
  name: 'PaymentSuccess',
  components: { UserAvatar },
  props: {
    product: Object
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    vatPrice () {
      let vat = this.currentUser.billing.billing.vat
      return Math.ceil(this.product.totalPrice * vat / 100)
    },
    totalPrice () {
      return this.product.totalPrice + this.vatPrice
    },
  },
  methods: {
    close () {
      this.$emit('close')
      this.$router.replace({ name: 'product', params: { uuid: this.product.id } })
    }
  }
}
</script>
<style lang="scss" scoped>
.product.mini {
  max-height: 224px;
  max-width: 224px;
}
</style>
