<template>
  <section>
    <div class="center">
      <img src="/images/logo_horizontal_white_color.png" height="32" width="211" alt="loverfans_logo" class="mr-4"/>
      <img src="/images/logo_horizontal_darkfans_color.png" height="50" width="211" alt="darkfans_logo" />
    </div>
    <div class="center">
      <span class="cross">X</span>
    </div>
    <div class="center">
      <img src="./resources/desade.png" height="67" width="77" alt="desade_logo" />
    </div>

    <div class="col center mt-3" v-if="!isMobile">
      <div class="free-bonus">
        <span class="text-white">{{ $t('posts.card_overlay.free').toUpperCase() }}</span> <span class="bonus">{{  $t('promo_code.desade') }}</span>
      </div>
    </div>

    <div class="col center mt-3" v-else>
      <div class="free-bonus mobile">
        <div class="text-white">{{ $t('posts.card_overlay.free').toUpperCase() }}</div>
        <div class="row bonus">{{  $t('promo_code.desade') }}</div>
      </div>
    </div>

    <div class="mx-auto col-8" style="margin-top: 32px;">
      <SignUpPromos promo="DESADE"></SignUpPromos>
    </div>

  </section>
</template>

<script>
import SignUpPromos from './SignUpPromos'
import config from '@/project'
import { mapState } from 'vuex'

export default {
  name: 'desade',
  components: { SignUpPromos },
  data () {
    return {
      config
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
  },
  methods: {
    ended () {
      const today = new Date();
      var eventEnded = new Date(2023, 7, 7, 23, 59, 59)
      return (eventEnded - today) < 0
    }
  },
  mounted() {
    if (config.project === 'scatbook' || this.ended()) {
      this.$router.push('home')
    }
  }
}
</script>

<style lang="scss" scoped>

.cross {
  margin-left: 32px;
  margin-right: 32px;
  color: white;
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
}

.text-white {
  border-color: red;
  border-style: solid;
  border-width: thick;
  padding: 0 8px;
}

.bonus {
  border-color: red;
  border-width: thick;
  border-style: solid;
  background-color: red;
  margin-left: -22px;
}

.free-bonus {
  font: normal normal bold 72px/72px Montserrat;
  color: white;
  .text-green {
    color: #88FF00;
  }
  &.mobile {
    width: 90% !important;
    margin: auto;
    border-color: red;
    border-style: solid;
    border-width: thick;
    padding: 0 8px;
    text-align: center;
    .text-white {
      border-color: transparent;
      padding: 0 8px;
    }
  }
}

.row.bonus {
  display: block;
  margin-left: -10px !important;
}
</style>
