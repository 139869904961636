<template>
  <section>
    <AffiliationPreferencesForm v-if="isInfluencer" />
    <MailPreferencesForm />
    <TwitterPreferencesForm v-if="currentUser.isTwitterConnected" />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import AffiliationPreferencesForm from '../components/AffiliationPreferencesForm'
import MailPreferencesForm from '../components/MailPreferencesForm'
import TwitterPreferencesForm from '../components/TwitterPreferencesForm'

export default {
  name: 'PreferencesView',
  components: { AffiliationPreferencesForm, MailPreferencesForm, TwitterPreferencesForm },
  computed: {
    ...mapState('profile', ['currentUser', 'isFollower', 'isInfluencer', 'promotion'])
  }
}
</script>
