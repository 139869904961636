<template>
  <section>
    <!--    <PendingVerify v-if="isPending" />-->
    <section>
      <v-alert v-if="isRejected" text type="warning" color="warning">
        <span v-if="promotion.manualCheck">
          {{ promotion.details }}
        </span>
        <span v-else>
          {{
            $t(`promotion.user.rejected.${promotion.details}`, {
              email: `stars@${project.project}.com`
            })
          }}
        </span>
      </v-alert>
      <!--      <div class="d-flex justify-space-between align-center">
              <h1 class="giant-text giant-text bold my-2" style="line-height: 40px;">
                {{ $t("promotion.title") }}
              </h1>
              <span class="success&#45;&#45;text">
                <v-icon color="success">lock_outline</v-icon>
                {{ $t("signup.secure_net") }}
              </span>
            </div>
            <h2 class="title-text-2 roboto mt-2 mb-10">
              {{ $t("promotion.description") }}
            </h2>
            <h3>{{ $t("signup.personal_data") }}</h3>
            <AccountData @validForm="validFormAccountData" />-->
      <!--  <v-expansion-panels >
        <v-expansion-panel  class="mb-2">
          <v-expansion-panel-header>{{
            $t("signup.personal_data")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <AccountData @validForm="validFormAccountData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->
      <!--  <v-expansion-panels>
        <v-expansion-panel class="mb-2">
          <v-expansion-panel-header>{{
            $t("models.user.payment_person.title")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <PersonalData @validForm="validFormPersonalData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->
      <!--      <v-col>
              <v-form ref="form" v-model="validForm">
                <v-row>
                  <v-col cols="12" sm="6">
                    &lt;!&ndash;  <div class="campo-label">
                        {{ $t("models.user.subscription_price") }}
                      </div> &ndash;&gt;
                    <CurrencyInput
                      outlined
                      dense
                      :label="$t('models.user.subscription_price')"
                      v-model="subscriptionPrice"
                      hint="XXX,YY"
                      :rules="[$vRequired]"
                      min-value="500"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>-->

      <!--      <v-divider class="my-2"></v-divider>
            <v-row align="center">
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <c-btn :disabled="disabledButtonNext" @click.stop="showAcceptTermsDialog">
                  {{ $t("actions.next") }}
                </c-btn>
              </v-col>
            </v-row>-->

      <!--      <TermsDialog
              closeable
              v-model="showTerms"
              :loading="finishing"
              @submit="showVerificationYoti"
            />-->
      <VerifyYotiDialog
        closeable
        v-model="showDialogYoti"
        :before="preparingYoti"
        :subscriptionPrice="subscriptionPrice"
        :username="currentUser.username"
        :personalData="personalData"
        :accountData="accountData"
      />

      <v-row align="center">
        <v-col v-if="skipable" cols="auto">
          <c-btn outlined color="secondary" @click.stop="showVerificationYoti">
            {{ $t('actions.finish') }}
          </c-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </section>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import validatable from '@/components/mixins/validatable'
import DocumentPreview from '@/components/promote/DocumentPreview'
import TermsDialog from '@/components/terms/TermsDialog'
import VerifyYotiDialog from '@/components/verifyIdenty/VerifyYotiDialog'
import AccountData from '@/components/verifyIdenty/AccountData'
import PersonalData from '@/components/verifyIdenty/PersonalData'
import PendingVerify from '@/components/verifyIdenty/PendingVerify'

import project from '@/project'

export default {
  name: 'VerifyIdenty',
  mixins: [validatable('form')],
  components: {
    DocumentPreview,
    TermsDialog,
    VerifyYotiDialog,
    AccountData,
    PersonalData,
    PendingVerify
  },
  props: {
    dark: {
      type: Boolean,
      default: null
    },
    skipable: Boolean,
    create: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      panel: [0],
      documentTypes: [
        {
          text: this.$t('models.influencer_request.id_document_types.passport'),
          value: 'passport'
        },
        {
          text: this.$t(
            'models.influencer_request.id_document_types.id_document'
          ),
          value: 'id_document'
        }
      ],
      subscriptionPrice: null,
      dniImage1: {},
      dniImage2: {},
      verificationImage: {},
      idDocumentType: null,
      idDocumentNumber: null,
      idDocumentCountry: null,
      idDocumentExpiration: null,
      idDocumentS3Route1: null,
      idDocumentS3Route2: null,
      verificationPhotoS3Route: null,
      finishing: false,
      showTerms: false,
      showDialogYoti: true,
      preparingYoti: true,
      isValidAccountData: false,
      accountData: {},
      personalData: {},
      project
    }
  },

  computed: {
    ...mapState('profile', ['currentUser', 'promotion', 'isAdmin']),
    isRejected () {
      return this.promotion.status === 'rejected'
    },
    isPending () {
      return this.promotion.status === 'pending'
    },
    status () {
      return this.promotion.status
    },
    disabledButtonNext () {
      return !this.isValidAccountData || !this.validForm
    }
  },
  watch: {
    showDialogYoti (value) {
      if (!value) {
        this.$emit('close')
      }
    },
    status () {
      if (this.create && this.status === 'approved') {
        this.$router.push('/home')
      }
    }
  },

  methods: {
    showAcceptTermsDialog () {
      this.showTerms = !this.showTerms
      this.finishing = false
      this.preparingYoti = true
    },
    idData () {
      return {
        subscriptionPrice: this.subscriptionPrice,
        idDocumentType: this.idDocumentType,
        idDocumentNumber: this.idDocumentNumber,
        idDocumentCountry: this.idDocumentCountry,
        idDocumentExpiration: this.idDocumentExpiration,
        idDocumentS3Route1: this.dniImage1.location,
        idDocumentS3Route2: this.dniImage2.location,
        verificationPhotoS3Route: this.verificationImage.location
      }
    },
    async showVerificationYoti () {
      this.showTerms = false
      this.showDialogYoti = true
      await this.$store.dispatch(ProfileActions.AcceptStarTerms)
    },

    validFormAccountData (isValid, data) {
      this.isValidAccountData = isValid
      if (isValid) {
        this.accountData = data
      }
    },
    validFormPersonalData (isValid, data) {
      if (isValid) {
        this.personalData = data
      }
    }
  },
  async mounted () {
    this.subscriptionPrice = this.currentUser.subscriptionPrice
    this.idDocumentType = this.promotion.idDocumentType
    this.idDocumentNumber = this.promotion.idDocumentNumber
    this.idDocumentCountry = this.promotion.idDocumentCountry
    this.idDocumentExpiration = this.promotion.idDocumentExpiration
    this.idDocumentS3Route1 = this.promotion.idDocumentS3Route1
    this.idDocumentS3Route2 = this.promotion.idDocumentS3Route2
    this.verificationPhotoS3Route = this.promotion.verificationPhotoS3Route
  }
}
</script>
