<template>
  <section class="app-sticky d-flex flex-column border-left">
    <div class="title-row border-bottom pa-2">
      {{ $t('comments.title') }}
    </div>
    <div class="comments-container custom-scrollbar border-bottom py-2" >
      <ListView
        no-gutters
        :loading="loading"
        :items="reversedItems"
        cols="12"
        @top-reached="loadItems"
      >
        <template #default="{ item }">
          <CommentRow
            :key="`comment-${item.id}`"
            :comment="item"
            :author="author"
            @delete="deleteComment(item.id)"/>
        </template>
        <!--        @top-reached="topReachedLoadItem"-->
      </ListView>

    </div>
    <EmojiInput v-if="authenticated"
                solo flat
                v-model="comment"
                max-length="500"
                :class="{marginMobile: isMobile}"
                :disabled="loading"
                :rules="[$vBannedWords(comment)]">
      <c-btn icon
             @click.stop="sendComment">
        <v-icon>send</v-icon>
      </c-btn>
    </EmojiInput>

    <Dialog
      :show="showLoadFunds"
      @hide="() => (showLoadFunds = !showLoadFunds)"
      :title="$t('actions.load_funds').toUpperCase()"
    >
      <template slot="bodyDialog">
        <div>
          <LoadFunds
            :clearInit="showLoadFunds"
            :textLoadFund="textLoadFund"
          />
        </div>
      </template>
    </Dialog>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import CommentApi from '@/api/CommentApi'
import CommentRow from './CommentRow'
import CursorApi from '@/components/mixins/cursor_api'
import LoadFunds from "@/components/chat/components/LoadFunds.vue";
import Dialog from "@/components/chat/component/Dialog.vue";

export default {
  name: 'CommentList',
  components: {Dialog, LoadFunds, CommentRow },
  mixins: [CursorApi(CommentApi, 'list')],
  props: {
    uuid: [String, Number],
    author: String
  },
  data () {
    return {
      comment: '',
      query: {
        postId: this.uuid,
        perPage: 100
      },
      showLoadFunds: false,
      textLoadFund: ''
    }
  },
  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('profile', ['currentUser']),
    ...mapState('application', ['isMobile']),
  },
  methods: {
    async sendComment () {
      if (!this.comment || !this.comment.trim()) {
        return
      }
      const comment = {
        id: new Date().getTime(),
        date: new Date(),
        comment: this.comment.trim(),
        username: this.currentUser.username,
        avatarS3Route: this.currentUser.avatarS3Route
      }
      this.comment = ''
      const {error} = await CommentApi.create({
        postId: this.uuid,
        comment: comment.comment
      })
      if (!error) {
        this.items.unshift(comment)
      } else {
        this.textLoadFund = this.$t('payments.user_never_payed');
        this.showLoadFundsDialog();
      }
    },

    showLoadFundsDialog() {
      this.showLoadFunds = !this.showLoadFunds
    },

    async deleteComment (id) {
      await CommentApi.delete(id)
      this.resetCursor(true)
    },
  }
}
</script>
<style lang="scss">

.title-row {
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  display: flex;
  align-items: center;
}

.comments-container {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.marginMobile {
  margin-bottom: 40px !important;
}
</style>
