<template>
  <c-dialog
    v-model="showDialog"
    :closable="closeable"
    :actions="false">
    <v-card-text>
      <section class="conditions-wrapper">
        <TermsConditions type="influencer" />
        <div class="d-flex justify-end">
          <v-checkbox v-model="acceptedTerms">
            <template slot="label">
              <v-row no-gutters align="center">
                <span class="mr-1">{{$t('models.user.accept')}}</span>
                <a @click.stop :href="goToConditions" target="_blank"> {{ $t('application.menu.terms_conditions') }}</a>
              </v-row>
            </template>
          </v-checkbox>
        </div>
      </section>
    </v-card-text>
    <v-card-actions>
      <c-btn
        :loading="loading"
        :disabled="!acceptedTerms"
        color="success"
        @click="submit"
      >
        {{ $t('actions.ok') }}
      </c-btn>
    </v-card-actions>
  </c-dialog>
</template>
<script>
import TermsConditions from '@/components/terms/TermsConditions'

export default {
  name: 'TermsDialog',
  components: { TermsConditions },
  props: {
    value: Boolean,
    closeable: { type: Boolean, default: false },
    loading: { type: Boolean, default: false } 
  },
  data() {
    return {
      submitting: false,
      acceptedTerms: false
    }
  },
  computed: {
    showDialog: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
    goToConditions () {
      return `https://${this.$t('site')}/conditions`
    }
  },
  methods: {
    submit () {
      this.$emit('submit')
    }
  }
}
</script>
<style scoped>
.conditions-wrapper {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
