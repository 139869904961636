<template>
  <c-dialog persistent
    width="684"
    class="publication-dialog"
    v-bind:value="dialog"
    @input="close"
    @close="resetState">
    <template #title>
      <h1>{{ $t('tips.dialog.title') }}</h1>
    </template>
    <h2 class="mb-4">{{ $t('tips.dialog.hint') }}</h2>
    <v-form v-model="validForm" ref="form"
      @submit.prevent="submit">
      <span>{{ $t('tips.dialog.receiver') }}</span>
      <div class="my-2">
        <UserAvatar chip :user="user" />
      </div>
      <span>{{ $t('payments.buyer_name') }}</span>
      <v-text-field outlined
        dense
        persistent-hint
        type="text"
        v-model="buyerName"
        :rules="[$vRequired]"></v-text-field>
      <span>{{ $t('tips.dialog.amount') }}</span>
      <v-radio-group v-model="amountOption"
        class="ma-0" :row="!isMobile"
        @change="visibleInput = false">
        <v-radio v-for="option in options"
          :key="option.value"
          :disabled="isGreaterThanWallet(option.value)"
          :label="option.text"
          :value="option.value">
        </v-radio>
        <c-btn small
          :outlined="!visibleInput"
          @click="showInput">
          {{ $t('actions.other') }}
        </c-btn>
      </v-radio-group>
      <CurrencyInput v-if="visibleInput"
        outlined
        v-model="inputValue"
        :min-value="10"
        :max-value="currentWallet.amount" />
        <h1 class="small-text" >
          {{ $t('wallet.reload.remember') }}
          <router-link :to="{ name: 'wallet' }"
            class="no-decoration">
            {{ $t('words.here') }}
          </router-link>
        </h1>
    </v-form>
    <template #actions>
      <c-btn color="success"
        :disabled="!canSendTip"
        @click="sendTip">
        {{ $t('actions.send') }}
      </c-btn>
    </template>
  </c-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { ProfileActions } from '@/store'
import TipApi from '@/api/TipApi'
import validatable from '@/components/mixins/validatable'
import UserAvatar from '@/components/users/UserAvatar'

export default {
  name: 'TipDialog',
  mixins: [validatable('form')],
  components: { UserAvatar },
  props: {
    dialog: Boolean,
    user: Object,
    resource: String,
    resourceId: [String, Number]
  },
  data () {
    return {
      validForm: false,
      visibleInput: false,
      amountOptions: [1000, 2500, 5000, 7500],
      amountOption: '',
      inputValue: '',
      buyerName: '',
      selectedUser: ''
    }
  },
  computed: {
    ...mapGetters('profile', ['currentWallet']),
    ...mapState('profile', ['currentUser']),
    ...mapState('application', ['isMobile']),
    options () {
      return this.amountOptions.map(opt => ({ value: opt, text: this.$currency(opt) }))
    },
    amount () {
      return parseInt(this.amountOption || this.inputValue || 0, 10)
    },

    canSendTip () {
      return this.validForm && this.amount > 0 && this.buyerName && !this.isGreaterThanWallet(this.amount)
    }
  },

  methods: {
    isGreaterThanWallet (amount) {
      return amount > this.currentWallet.amount
    },
    showInput () {
      this.visibleInput = true
      this.amountOption = ''
    },
    async sendTip () {
      await TipApi.create({
        username: this.user.username,
        name: this.buyerName,
        tip: this.amount,
        resource: this.resource,
        resourceId: this.resourceId
      })
      this.$store.dispatch(ProfileActions.Fetch)
      this.close()
    },

    close () {
      this.$emit('update:dialog', false)
    },

    resetState () {
      this.validForm = false
      this.visibleInput = false
      this.amountOption = ''
      this.inputValue = ''
      this.selectedUser = ''
    }
  },
  mounted () {
    this.validForm = false
    this.showForm = true
    this.visibleInput = false
    this.amountOption = ''
    this.inputValue = ''
    this.buyerName = this.currentUser.fullName
    if (this.user.username) {
      this.selectedUser = this.user.username
    }
  }
}
</script>
<style lang="scss" scoped>
.form-container {
  border: none;
  height: 650px;
  width: 100%;
}
</style>
