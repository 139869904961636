'user strict'

import api from '@/api/Api'

export default {

  find (userId) {
    return api.get('/wallets', { userId })
  },

  events (query = {}, options) {
    return api.get('/wallets/events', query, options)
  },

  reload (userId, attrs = {}) {
    return api.post('/wallets/reload', { userId, ...attrs })
  },
  
  moveFunds({ fromCurrency, toCurrency, amount }) {
    return api.post('/wallets/move_funds', { fromCurrency, toCurrency, amount })
  }
}
