<template>
  <div class="d-flex flex-column justify-center align-center text-center fill-height pa-2"
    :class="{ backgrop: blurredBackground, gradient: hover }">
    <div class="top-right">
      <span class="rounded pa-1 translucient-dark">
        <v-icon color="white">{{ icon }}</v-icon>
        <span v-if="isVideo && duration" class="ml-1 small-text">{{ duration }}</span>
        <span v-else-if="isImage && post.multipleResources" class="ml-1 small-text">{{ post.resourcesCount }}</span>
      </span>
    </div>
    <h1 v-if="!!post.blockedCountry"
      class="title-text">
      {{ $t('blocked_countries.content_blocked')}}
    </h1>

    <div v-show="hover">
      <div class="d-flex flex-column justify-center align-center text-center">
        <v-icon class="large-icon semi-opaque">{{ lockIcon }}</v-icon>
        <template v-if="isOpen">
          <v-btn
            color="success"
            class="signup-button py-8"
            small :to="{ name: 'login' }">
            {{ $t(`posts.card_overlay.open_description`) }}
          </v-btn>
        </template>
        <template v-else-if="post.acquirable || isSubscription">
          <PaymentButton
            from-resource
            :dummy="dummyPayment"
            :x-small="isMobile"
            :post="!isSubscription"
            :subscription="isSubscription"
            :resource="paymentResource" 
            :resource-model="post" />
          <div class="white--text">{{ $t(`posts.card_overlay.${post.visibility}_description`) }}</div>
        </template>
        <div v-else class="lightbox white--text">{{ $t(`posts.card_overlay.private_description`) }}</div>
      </div>
    </div>
    <template v-if="!hover">
      <v-icon class="large-icon">lock_outline</v-icon>
      <img class="isologo mt-5" src="/images/isologo_white.svg?dfk22" />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import PaymentButton from '@/components/payments/PaymentButton'
const padTo = (elem, count, char) => ('' + elem).padStart(count, char)

export default {
  name: 'PostNoVisible',
  components: { PaymentButton },
  props: {
    post: Object,
    hover: Boolean,
    dummyPayment: Boolean
  },

  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser']),

    paymentResource () {
      return this.isSubscription ? this.post.author : this.post
    },

    blurredBackground () {
      return !(this.isVideo && this.isPremium)
    },
    isVideo () {
      return this.post.postType === 'video'
    },
    isImage () {
      return this.post.postType === 'image'
    },
    isSubscription () {
      return this.post.visibility === 'followers'
    },
    isPremium () {
      return this.post.visibility === 'premium'
    },

    isOpen () {
      return this.post.visibility === 'open'
    },

    nonExclusive () {
      return this.post.visibility !== 'exclusive'
    },

    isUserSelected () {
      return this.post.selectedUser === this.currentUser.username
    },

    icon () {
      return this.isVideo ? 'videocam'
        : this.post.hasGifs ? 'gif'
          : this.post.multipleResources ? 'photo_library'
            : 'camera_alt'
    },

    lockIcon () {
      return this.post.acquirable || this.isSubscription ? 'lock_open' : 'lock_outline'
    },

    iconClass () {
      return {
        'translucient-dark': this.post.hasGifs,
        rounded: this.post.hasGifs
      }
    },
    duration () {
      if (!this.post.metadata) return ''
      const length = this.post.metadata.clip ? this.post.metadata.clip.duration : '0'
      const minutes = Math.floor(length / 60)
      const seconds = length % 60
      if (isNaN(minutes) || isNaN(seconds)) return ''
      return `${padTo(minutes, 2, '0')}:${padTo(seconds, 2, '0')}`
    }
  }
}
</script>
<style lang="scss" scoped>
.backgrop {
  backdrop-filter: blur(25px);
}
.gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
}
.top-right {
  position: absolute;
  top: 8px;
  right: 10px;
}
.isologo {
  height: 25px;
}
.signup-button {
  max-width: 250px;
  ::v-deep .v-btn__content {
    width: 80%;
    white-space: normal;
  }
}
</style>
