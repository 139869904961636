<template>
  <c-dialog 
    width="684"
    :value="value"
    @input="emitInput">
    <template #title>
      <h1>{{ $t('products.auction.terms.title') }}</h1>
    </template>
    <p>1. {{ $t('products.auction.terms.point_1', { amount: reservedAmount }) }}</p>
    <p>2. {{ $t('products.auction.terms.point_2', { amount: reservedAmount }) }}</p>
    <p>3. {{ $t('products.auction.terms.point_3') }}</p>
    <p>4. {{ $t('products.auction.terms.point_4') }}</p>
    <p>5. {{ $t('products.auction.terms.point_5') }}</p>
    <p>6. {{ $t('products.auction.terms.point_6') }}</p>
    <p>7. {{ $t('products.auction.terms.point_7') }}</p>
    <p>8. {{ $t('products.auction.terms.point_8') }}</p>
  </c-dialog>
</template>
<script>
import { mapGetters } from 'vuex'

const ReservedEurAmount = 5000;

export default {
  name: 'TermsDialog',
  props: {
    value: Boolean
  },
  computed: {
    ...mapGetters('profile', ['currentWallet']),
    reservedAmount () {
      const currency = this.currentWallet.currency
      const amount = currency === 'EUR' ? ReservedEurAmount : ReservedEurAmount * 1.25
      return this.$currency(amount, this.currentWallet.currency)
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', !!value)
    }
  }

}
</script>
