<template>
  <section class="registerForm">
    <v-row align="center" class="header" :style="{color: isDark ? 'white' : ''}">
      <v-col> {{ $t(`index.signUp.title`) }}</v-col>
      <v-col class="d-flex flex-row-reverse">
        <v-radio-group v-model="signUpForm" row :dark="isDark" class="justify-end">
          <v-radio
            value="fan"
          >
            <template slot="label">
              <span style="font-family: Quicksand, sans-serif"
                    :style="{color: isDark ? 'white' : ''}">{{ $t(`index.signUp.fan`) }}</span>
            </template>
          </v-radio>
          <v-radio
            value="star"
          >
            <template slot="label">
              <span style="font-family: Quicksand, sans-serif"
                    :style="{color: isDark ? 'white' : ''}">{{ $t(`index.signUp.creator`) }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="validForm"
            @submit.prevent="submit">
      <v-row justify="center" dense>
        <v-col :cols="isMobile ? 12 : 10" sm="12">
          <UsernameInput outlined
                         dense
                         hide-details="auto"
                         v-model="username"
                         :rules="[$vRequired]"/>
        </v-col>
        <v-col :cols="isMobile ? 12 : 10" sm="12">
          <v-text-field outlined
                        dense
                        :label="$t('models.user.email')"
                        v-model="email"
                        hide-details="auto"
                        :rules="[$vRequired,$vEmail]"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 10" sm="12">
          <PasswordInput outlined
                         dense
                         ref="password"
                         hide-details="auto"
                         v-model="password"
                         :rules="[$vRequired]"/>
        </v-col>
        <v-col :cols="isMobile ? 12 : 10" sm="12" v-if="!showCode">
          <PasswordInput outlined
                         dense
                         hide-details="auto"
                         :label="$t('models.user.password_confirmation')"
                         v-model="passwordConfirmation"
                         :override-rules="[$vRequired,$vConfirmation('password')]"/>
        </v-col>
        <v-col :cols="isMobile ? 12 : 10" sm="12">
          <v-text-field outlined
                        v-if="showCode"
                        dense
                        :label="$t('models.user.bonusCode')"
                        v-model="bonusCode"
                        hide-details="auto"></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-0 pt-0">
        <v-col cols="12 viewMore pt-0" @click="showLOPD = !showLOPD"> {{ $t('actions.more_info') }}</v-col>
        <v-col cols="12 tracking-in-expand-forward-top" v-if="showLOPD"><p
          v-html="$t('application.menu.management')"></p></v-col>
      </v-row>

      <div class="d-block justify-center">
        <v-checkbox outlined
                    dense class="mt-0"
                    v-model="acceptedTerms"
                    :error="termsError">
          <template slot="label">
            <span>
              {{ $t('models.user.accept') }}&nbsp;
              <a @click.stop :href="goToConditions" target="_blank"> {{ $t('application.menu.terms_conditions') }}</a>
            </span>
          </template>
        </v-checkbox>
        <v-checkbox outlined
                    dense class="my-0"
                    v-model="acceptedMarketing">
          <template slot="label">
            <span>{{ $t('application.menu.marketing') }}
            </span>
          </template>
        </v-checkbox>
      </div>

      <v-alert type="error"
               v-if="signupError"
               icon="warning"
               dense outlined>
        <span v-html="signupError"/>
        <!--        {{ signupError }}-->
      </v-alert>
      <div class="d-flex pb-5 pt-5 justify-center ">
        <recaptcha :captchaVersion="captchaVersion" ref="recaptcha" @verify="saveTokenRecaptcha"/>
      </div>
      <c-btn depressed
             block :rounded="false"
             style="text-transform: uppercase;background-color: #41b849 !important;"
             color="#41b849"
             @click.stop="onSubmit">
        <span style="font-family: Quicksand, sans-serif; color: white; font-weight: 600">{{
            $t('actions.signup')
          }}</span>
      </c-btn>

    </v-form>
  </section>
</template>

<script>
import {SessionActions} from '@/store'
import validatable from '@/components/mixins/validatable'
import project from '@/project'
import {mapState} from 'vuex'
import utils from "@/utils";
import Recaptcha from "@/components/verifyIdenty/Recaptcha.vue";

export default {
  name: 'SimpleSignUpForm',
  components: {Recaptcha},
  mixins: [validatable('form')],
  props: {
    dark: {type: Boolean, default: false},
    origin: {type: String, default: 'Landing'},
    star: {type: Boolean, default: false},
    promoCode: {type: String, default: null},
    noRedirect: {type: Boolean, default: false}
  },
  data() {
    return {
      username: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      termsError: false,
      acceptedTerms: false,
      acceptedMarketing: false,
      isAdult: true,
      signupError: '',
      signUpForm: 'fan',
      showLOPD: false,
      showCode: false,
      bonusCode: null,
      project,

      captchaToken: '',
      captchaVersion: 3,
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    isDark() {
      return !project.project === 'loverfans'
    },
    goToConditions() {
      return `https://${this.$t('site')}/conditions`
    }
  },
  watch: {
    acceptedTerms(value) {
      this.termsError = !value
      if (value) {
        this.signupError = ''
      }
    }
  },
  methods: {
    executeRecaptcha() {
      this.$refs.recaptcha.execute()
    },
    user() {
      return {
        username: this.username.trim(),
        email: this.email.trim(),
        password: this.password.trim(),
        acceptedTerms: this.acceptedTerms,
        acceptedMarketing: this.acceptedMarketing,
        isAdult: this.isAdult,
        lang: this.$i18n.locale.trim(),
        /*captchaVersion: this.captchaVersion,*/
        captchaToken: this.captchaToken
      }
    },
    async saveTokenRecaptcha(response) {
      this.captchaToken = response
    },
    async onSubmit() {

      this.termsError = !this.acceptedTerms
      if (this.termsError) {
        this.signupError = this.$t('remote_errors.must_accept_terms')
        return
      }

      const user = this.bonusCode ? {...this.user(), bonusCode: this.bonusCode} : this.user()
      const {data, error} = await this.$store.dispatch(SessionActions.Signup, user)
      if (data.errorCode && data.errorCode.includes('bonusCode')) {
        this.signupError = this.$t(`remote_errors.bonus_code`)
      }
      if (!error) {
        this.$gtm('user_registration_complete', undefined, this.user().email)
        if (this.signUpForm === 'star') {
          this.$router.replace({
            name: 'become_star',
            params: {username: this.username, email: this.mail, password: this.password}
          })
        } else {
          this.$emit('submit', data)
          if (this.noRedirect) {
            this.$emit('complete')
            return
          }
          const origin = sessionStorage.getItem('origin')
          if (origin && !origin.includes('/login')) {
            this.$router.replace(origin)
            sessionStorage.removeItem('origin')
          } else {
            this.$router.replace('/home')
          }
        }
      } else {
        if (data.errorCode === 'invalid_captcha') {
          this.signupError = this.$t(`remote_errors.${data.errorCode}`)
        }
        if (data.errorCode === 'invalid_username') {
          this.signupError = this.$t(`remote_errors.${data.errorCode}`)
          this.$vuetify.goTo(0)
        } else if (data.data && data.data.field) {
          const field = this.$t(`models.user.${data.data.field}`)
          this.signupError = this.$t(`remote_errors.${data.errorCode}`, {field, value: data.data.value})
          this.$vuetify.goTo(0)
        }
      }
    },
    redirect() {
      const origin = sessionStorage.getItem('origin')
      if (origin && origin !== '/login') {
        this.$router.replace(origin)
        sessionStorage.removeItem('origin')
      } else {
        if (this.signUpForm === 'star') {
          this.$router.replace({
            name: 'become_star',
            params: {username: this.username, email: this.mail, password: this.password}
          })
        } else {
          this.$router.replace('/home')
        }
      }
    }
  },
  beforeDestroy() {
    if (this.authenticated) {
      /* this.$gtag.event('signUp', { event_category: this.origin })*/
    }
    this.$emit('logged')
    this.$emit('close')
  },
  mounted() {
    setTimeout(() => {
      this.executeRecaptcha()
    }, 1000)
    if (this.$route.query && this.$route.query.promoCode) {
      this.showCode = true
      this.bonusCode = this.$route.query.promoCode
      this.signUpForm = 'star'
    } else {
      this.signUpForm = this.star ? 'star' : 'fan'
    }
  }
}
</script>

<style lang="scss">

.header {
  font-family: 'Quicksand', sans-serif !important;
}

.v-text-field {
  font-family: 'Quicksand', sans-serif !important;
}

.registerForm {
  margin-top: auto;
  margin-right: auto;
  font-family: 'Quicksand', sans-serif !important;
  font-size: 16px;
}

.tracking-in-expand-forward-top {
  animation: tracking-in-expand-forward-top 0.05s both
}

@keyframes tracking-in-expand-forward-top {
  0% {
    letter-spacing: -.2em;
    transform: translateZ(-700px) translateY(-100px);
    opacity: 0
  }
  40% {
    opacity: .6
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1
  }
}

.viewMore {
  color: var(--v-primary-base);
  cursor: pointer;
}
</style>
