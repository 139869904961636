<template>
  <section>
    <!--  <v-alert text type="info" color="blue">
      {{ $t("promotion.state.pending") }}
    </v-alert> -->
    <v-row justify="center">
      <v-col :cols="isMobile ? 11 : 8">
        <p class="campo-label">
          Tu proceso de alta como creador de contenido ha finalizado y, si todo
          está correcto, recibirás en breve un email de confirmación confirmado
          la verificación de tu cuenta.
        </p>
      </v-col>
      <v-col :cols="isMobile ? 11 : 8">
        <p class="campo-label">
          Somos muy rápidos :-) pero si crees que está tardando más de lo
          habitual (máximo de 12 horas) por favor escríbenos a
          <a
            :href="`mailto:stars@${project.project}.com`"
            target="_blank"
            style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;font-weight: normal;text-decoration: none;"
            :style="{
              color: isDark ? '#FFFFFF' : '#000000',
              borderBottom: isDark ? '2px solid white' : '2px solid black'
            }"
          >stars@{{ project.project }}.com</a
          >
          por si hubiera algún problema.
        </p>
      </v-col>
    </v-row>
    <!--  <v-divider class="my-2"></v-divider>
      <v-row align="center">
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <c-btn :disabled="!validForm" @click.stop="showAcceptTermsDialog">
            {{ $t("actions.next") }}
          </c-btn>
        </v-col>
      </v-row> -->
    <v-row justify="center" style="margin-bottom: 90px;margin-top: 40px;">
      <v-btn
        @click="goTo('/')"
        :style="{
          backgroundColor: isDark ? '#B0601E' : '#f71953',
          color: '#FFFFFF'
        }"
      >{{ $t('actions.go_to_item', { item: $t('generic.home') }) }}
      </v-btn
      >
    </v-row>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import project from '@/project'
import { ProfileActions } from '@/store'

export default {
  name: 'SignUpStarFormFinal',
  props: {
    isDark: Boolean
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['promotion']),
    colorDest () {
      return {
        color: this.isDark ? '#B0601E' : '#f71953'
      }
    },
    isPending () {
      return this.promotion.status === 'pending'
    }
  },

  data () {
    return {
      project,
      interval: null
    }
  },
  mounted () {
    this.refreshStatus()
  },
  methods: {
    goTo () {
      this.$router.push('/')
    },
    refreshStatus () {
      this.interval = setInterval(() => {
        this.$store.dispatch(ProfileActions.Fetch)
      }, 60000)
    }
  },
  destroyed () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.campo-label {
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
}
</style>
