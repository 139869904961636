<template>
  <DummyLoader
    card
    :cols="perPage"
    :itemCount="perPage * 4"
    :loading="isLoading">
    <Slider
      icon="shopping_cart"
      :title="$t('market.sales')"
      :items="sales"
      :loading="loadingSales"
      :per-page="perPage"
      :showAllLink="{ name: 'marketplace_sales'}">
      <template #empty>
        <h1 class="title-text px-5">{{ $t('listview.no_sales')}}</h1>
      </template>
      <template #default="{ item }">
        <ProductCard extended ratio="1.3" :product="item" />
      </template>
    </Slider>
    <v-divider class="my-10"></v-divider>
    <Slider
      icon="thumbs_up_down"
      :title="$t('market.auctions')"
      :items="auctions"
      :loading="loadingAuctions"
      :per-page="perPage"
      :showAllLink="{ name: 'marketplace_auctions'}">
      <template #empty>
        <h1 class="title-text px-5">{{ $t('listview.no_auctions')}}</h1>
      </template>
      <template #default="{ item }">
        <ProductCard extended ratio="1.3" :product="item" />
      </template>
    </Slider>
    <v-divider class="my-10"></v-divider>
    <Slider
      icon="access_time"
      :title="$t('market.recent')"
      :videos="recents"
      :loading="loadingRecents"
      :per-page="perPage">
      <template #empty>
        <h1 class="title-text px-5">{{ $t('listview.no_follower_content')}}</h1>
      </template>
      <template #default="{ item }">
        <ProductCard extended ratio="1.3" :product="item" />
      </template>
    </Slider>
    <v-divider class="my-10"></v-divider>
  </DummyLoader>
</template>
<script>
import { mapState } from 'vuex'
import ProductCard from '@/components/products/ProductCard'
import ProductApi from '@/api/ProductApi'

export default {
  name: 'Marketplace',
  components: { ProductCard },
  data () {
    return {
      loadingRecents: false,
      loadingSales: false,
      loadingAuctions: false,
      sales: [],
      auctions: [],
      recents: [],
      firstLoad: 3
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    isLoading () {
      return this.firstLoad > 0
    },
    perPage () {
      return this.isMobile ? 1 : 4
    }
  },
  methods: {
    loadProducts (query, firstLoad) {
      const params = {
        perPage: 50,
        includeTags: true,
        ...this.$store.getters['preferences/selected_tags_ids'],
        ...query
      }
      return ProductApi.list(params).then(response => {
        if (firstLoad) {
          this.firstLoad = this.firstLoad - 1
        }
        return response.error ? [] : response.data.data
      })
    },
    async loadSales (firstLoad) {
      this.loadingSales = true
      this.sales = await this.loadProducts({ productType: 'sale', acquired: false }, firstLoad)
      this.loadingSales = false
    },
    async loadAuctions (firstLoad) {
      this.loadingAuctions = true
      this.auctions = await this.loadProducts({ productType: 'auction', acquired: false }, firstLoad)
      this.loadingAuctions = false
    },
    async loadRecents (firstLoad) {
      this.loadingRecents = true
      this.recents = await this.loadProducts({ recommended: true, acquired: false, includeTags: false }, firstLoad)
      this.loadingRecents = false
    }
  },
  mounted () {
    this.firstLoad = 3
    this.loadSales(true)
    this.loadAuctions(true)
    this.loadRecents(true)
  }
}
</script>
