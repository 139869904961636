<template>
  <section style="width: 100%;">
    <div class="d-flex align-center">
      <v-img
        max-width="350"
        src="/images/logo_horizontal.svg?dfk23" alt="logo" title="Logo">
      </v-img>
    </div>
    <component :is="stepComponent"
      skipable
      two-rows
      :dark="false"
      :taggable="false"
      @skip="skip"
      @submit="next"></component>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Promotion from '@/components/sessions/PromotionCheck'
import PersonalData from '@/components/users/UserPersonalSettings'
import Identification from '@/components/users/UserVerificationView'
import Tags from '@/components/tags/TagProfile'
import SocialMedia from '@/components/sessions/SocialMediaForm'
import config from '@/project'

export default {
  name: 'PromoteView',
  components: { Promotion, PersonalData, Identification, Tags, SocialMedia },
  props: {
    step: String
  },
  data () {
    return {
      nextStep: {
        promotion: 'personal-data',
        'personal-data': 'identification',
        identification: 'tags',
        tags: 'social-media',
        'social-media': 'promotion'
      }
    }
  },
  computed: {
    ...mapState('profile', ['isInfluencer']),
    stepComponent () {
      return this.step.toLowerCase()
    }
  },
  methods: {
    next () {
      const step = this.nextStep[this.step.toLowerCase()]
      if (step === 'social-media' && !config.twitter_enabled) {
        this.skip()
        return
      }
      this.$router.replace({ name: 'promote', params: { step } })
    },
    skip () {
      const origin = sessionStorage.getItem('origin')
      if (origin) {
        this.$router.replace(origin)
        sessionStorage.removeItem('origin')
      } else {
        this.$router.replace('/stars/discover')
      }
    }
  },
  mounted () {
    if (this.isInfluencer) {
      this.skip()
    }
  }
}
</script>
