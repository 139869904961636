import PostApi from '@/api/PostApi'
import CommentApi from '@/api/CommentApi'

const actionsTypes = {
  Set: 'set',
  /*   Search: 'search', */
  Recommended: 'recommended',
  Timeline: 'set_timeline',
  Fetch: 'fetch',
  /* Create: 'create', */
  Update: 'update',
  /*   Delete: 'delete', */
  /*   Acquire: 'acquire', */
  ToggleLike: 'toggle_like',
  /* ToggleSave: 'toggle_save', */
  /* ToggleShare: 'toggle_share' */
  SetUser: 'set_ser',
  CreateComment: 'create_comment',
  HiddenDialog: 'dialog_hidden',
  ShowDialog: 'dialog_show'
}
const state = {
  timeline: [],
  metaData: {},
  posts: {},
  user: {},
  promises: {},
  dialogSignup: false,
  hasMoreElements: true
}

const getters = {
  timeline: (state) => state.timeline.map(postId => state.posts[postId])
}

const mutations = {
  reset: (state) => {
    state.timeline = []
    state.posts = {}
    state.promises = {}
    state.user = {}
  },

  insert_post (state, post) {
    this.commit('posts/set_post', post)
  },

  remove_post (state, postId) {
    state.promises[postId] = null
    state.posts[postId] = null
    const index = state.timeline.findIndex(p => p.id === postId)
    if (index !== -1) {
      state.timeline.splice(index, 1)
    }
  },

  set_post (state, post) {
    const index = state.timeline.findIndex(p => p.id === post.id)
    if (index !== -1) {
      Object.keys(post).forEach(key => {
        state.timeline[index][key] = post[key]
      })
    }
  },

  set_post_promise (state, id, promise) {
    state.promises[id] = promise
  },
  set_timeline (state, {
    data,
    metadata
  }) {
    state.timeline = data
    state.metaData = metadata
    state.hasMoreElements = metadata.hasMoreElements
  },
  set_user (state, data) {
    state.user = data
  },
  create_comment (state, comment) {
    const index = state.timeline.findIndex(p => p.id === comment.postId)
    if (index !== -1) {
      state.timeline[index].commentList.push({
        avatarS3Route: comment.avatarS3Route,
        comment: comment.comment,
        date: comment.date,
        id: comment.id,
        postId: comment.postId,
        userId: comment.userId,
        username: comment.username
      })
    }
  },
  dialog_show_hide (state, value) {
    state.dialogSignup = value
  }
}

const fetchPost = (context, postId) => {
  const promise = PostApi.find(postId).then(({
    data,
    error
  }) => {
    if (!error) {
      context.commit('set_post', data)
      return context.state.posts[postId]
    } else {
      context.commit('set_post_promise', postId, null)
    }
    return data
  })
  context.commit('set_post_promise', postId, promise)
  return promise
}

const actions = {
  [actionsTypes.Set]: (context, post) => {
    context.commit('set_post', post)
  },
  /* [actionsTypes.CreateComment]: async (context, comment) => {
    const {
      data,
      error
    } = await CommentApi.create(comment)
    if (!error) {
      data.postId = comment.postId
      context.commit('create_comment', data)
      return data
    }
  }, */
  [actionsTypes.Timeline]: (context, data) => {
    context.commit('set_timeline', data)
  },

  [actionsTypes.Fetch]:
    (context, {
      id,
      force
    }) => {
      if (context.state.promises[id]) {
        return context.state.promises[id]
      }
      const post = context.state.posts[id]
      if (!post || force) {
        return fetchPost(context, id)
      }
      return post
    },
  [actionsTypes.SetUser]: (context, user) => {
    context.commit('set_user', user)
  },
  [actionsTypes.Update]:
    async (context, post) => {
      const response = await PostApi.update(post)
      if (!response.error) {
        context.dispatch(actionsTypes.Fetch, {
          id: post.id,
          force: true
        })
      }
      return response
    },
  [actionsTypes.ToggleLike]:
    async (context, id) => {
      const post = context.state.timeline.find(p => p.id === id)
      if (!post) {
        return
      }
      const liked = post.liked
      post.liked = !post.liked
      if (!liked) {
        post.likesCount++
      } else {
        post.likesCount--
      }
      context.commit('set_post', post)
      const action = liked ? PostApi.unlike : PostApi.like
      await action(id)
    },
  [actionsTypes.HiddenDialog]: (context) => {
    context.commit('dialog_show_hide', false)
  },
  [actionsTypes.ShowDialog]: (context) => {
    context.commit('dialog_show_hide', true)
  }
  /*   [actionsTypes.Recommended]: async (context, query) => {
      const response = await PostApi.recommended(query)
      if (!response.error) {
        response.data.data = response.data.data.map(post => {
          context.commit('set_post', post)
          return context.state.posts[post.id]
        })
      }
      return response
    }, */

  /*  [actionsTypes.Timeline]:
     async (context, query) => {
       const response = await PostApi.timeline(query)
       if (!response.error) {
         response.data.data = response.data.data.map(post => {
           context.commit('set_post', post)
           return context.state.posts[post.id]
         })
       }
       return response
     }, */

  /*  [actionsTypes.Create]: (context, post) => {
     return PostApi.create(post)
       .then(({
         data,
         error
       }) => {
         if (!error) {
           context.commit('insert_post', data)
         }
         return {
           data: context.state.posts[data.id],
           error
         }
       })
   }, */
  /*  [actionsTypes.Delete]: async (context, postId) => {
     const response = await PostApi.delete(postId)
     if (!response.error) {
       context.commit('remove_post', postId)
     }
     return response
   }, */

  /*   [actionsTypes.Acquire]: async (context, payload) => {
      const response = await PostApi.buy(payload.id, payload.attrs)
      if (!response.error) {
        context.dispatch(actionsTypes.Fetch, {
          id: payload.id,
          force: payload.force
        })
      }
      return response
    }, */

  /*   [actionsTypes.ToggleSave]: async (context, id) => {
      const post = context.state.posts[id]
      if (!post) {
        return
      }
      const action = post.saved ? PostApi.unsave : PostApi.save
      context.commit('set_post', {
        id,
        saved: !post.saved
      })
      return action(id)
    }, */

  /* [actionsTypes.ToggleShare]: async (context, id) => {
    const post = context.state.posts[id]
    if (!post) {
      return
    }
    const action = post.shared ? PostApi.unshare : PostApi.share
    context.commit('set_post', {
      id,
      shared: !post.shared
    })
    return action(id)
  } */
}

export const Actions = actionsTypes

export default {
  namespaceName: 'stories',
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
