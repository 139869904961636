var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"box-title"},[_c('strong',[_c('span',{staticClass:"date"},[_vm._v("⚬")]),_vm._v(" "+_vm._s(_vm.$t("chats.user_preferences.title_1")))]),_c('span',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.dateCreateUser)+" ")]),(_vm.isNew)?_c('span',{staticClass:"new"},[_vm._v(" NEW")]):_vm._e()]),(_vm.statistics)?_c('div',[_c('div',{staticClass:"box-title"},[_c('strong',[_c('span',{staticClass:"date"},[_vm._v("⚬")]),_vm._v(" "+_vm._s(_vm.$t("chats.user_preferences.title_2")))])]),_c('div',{staticClass:"user-score-data"},[_c('div',{staticClass:"item-user-score-data"},[_c('span',{staticClass:"item-user-score-data-title",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              ("chats.user_preferences.score_text.sub." + (_vm.statistics.subscriptionPosition !== 0 ? 'rank' : 'no_rank')),
              { porcent: _vm.maskedValue(_vm.statistics.subscriptionPosition) }
            )
          )}})]),_c('div',{staticClass:"item-user-score-data"},[_c('span',{staticClass:"item-user-score-data-title",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              ("chats.user_preferences.score_text.buy." + (_vm.statistics.buyingContentPosition !== 0 ? 'rank' : 'no_rank')),
              { porcent: _vm.maskedValue(_vm.statistics.buyingContentPosition) }
            )
          )}})]),_c('div',{staticClass:"item-user-score-data"},[_c('span',{staticClass:"item-user-score-data-title",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              ("chats.user_preferences.score_text.text." + (_vm.statistics.sextingPosition !== 0 ? 'rank' : 'no_rank')),
              { porcent: _vm.maskedValue(_vm.statistics.sextingPosition) }
            )
          )}})]),_c('div',{staticClass:"item-user-score-data"},[_c('span',{staticClass:"item-user-score-data-title",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              ("chats.user_preferences.score_text.tips." + (_vm.statistics.tipsPosition !== 0 ? 'rank' : 'no_rank')),
              { porcent: _vm.maskedValue(_vm.statistics.tipsPosition) }
            )
          )}})]),_c('div',{staticClass:"spacer"})])]):_vm._e(),_c('div',{staticClass:"box-title"},[_c('strong',[_c('span',{staticClass:"date"},[_vm._v("⚬")]),_vm._v(" "+_vm._s(_vm.$t("chats.user_preferences.title_3")))])]),_c('div',{staticClass:"row justify"},[_c('p',{staticClass:"mt-1 col-8 colinput"},[_vm._v(" "+_vm._s(this.$t("application.chat_setting.default_price_sexting"))+" ")]),_c('InputCurrency',{staticClass:"inputCurrency col-4 colinput",attrs:{"disabled":_vm.disabled,"currency":_vm.currency,"defaultPrice":_vm.currentPrice},on:{"newPrice":_vm.setPriceConversations}})],1),_c('div',{staticClass:"row justify"},[_c('p',{staticClass:" mt-1 col-8 colinput"},[_vm._v(" "+_vm._s(this.$t("application.chat_setting.default_price_media"))+" ")]),_c('InputCurrency',{staticClass:"inputCurrency col-4 colinput",attrs:{"currency":_vm.currency,"defaultPrice":_vm.mediaPrice},on:{"newPrice":_vm.setPriceMediaFile}})],1),_c('div',{staticClass:"row justify"},[_c('p',{staticClass:"mt-1 col-8 colinput"},[_vm._v(" "+_vm._s(this.$t("application.chat_setting.default_price_video"))+" ")]),_c('InputCurrency',{staticClass:"inputCurrency col-4 colinput",attrs:{"currency":_vm.currency,"defaultPrice":_vm.videoPrice},on:{"newPrice":_vm.setPriceVideoChat}})],1),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"center rows"},[_c('Button',{attrs:{"color":"#41b849","text":"GUARDAR","loading":_vm.loadingButton},on:{"handler":_vm.saveNewPrice}})],1),_c('div',{staticClass:"spacer"})])}
var staticRenderFns = []

export { render, staticRenderFns }