<template>
  <section>
    <v-data-table
      class="elevation-2 test small-text"
      :headers="headers"
      :items="items"
      :loading="loading"
      item-key="username"
      must-sort
      :sort-by.sync="sort"
      :sort-desc.sync="sortDesc"
      :page.sync="page"
      @update:sort-desc="changeSort"
      :items-per-page.sync="perPage"
      :server-items-length="totalItems"
    >
      <template #top>
        <ListViewTitle
          :is-title="noTitle"
          :title="tableTitle"
          :action="titleAction"
        />
        <v-row align="center" class="px-2">
          <v-col cols="12" sm="3">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              :label="$t('admin.users.username_email')"
              v-model="search"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="px-2">
          <v-col cols="auto">
            <v-select
              outlined
              dense
              hide-details
              v-model="role"
              :items="userRoles"
            >
            </v-select>
          </v-col>
          <v-col cols="auto">
            <v-select
              outlined
              dense
              clearable
              hide-details
              :label="$t('admin.users.promotion_status')"
              :items="promotionStatuses"
              v-model="promotionStatus"
            />
          </v-col>
        </v-row>
      </template>

      <template #item.username="{ item }">
        <router-link
          :to="{ name: 'partner_view', params: { username: item.username } }"
          class="no-decoration"
        >
          <div class="title-text-2 roboto">{{ item.displayName }}</div>
          <div class="ml-1">{{ `@${item.username}` }}</div>
        </router-link>
      </template>
      <template #item.blocked="{ item }">
        {{ $t(`types.boolean.${item.blocked || false}`) }}
      </template>
      <template #item.role="{ item }">
         <v-chip v-if="item.isAgent" :class="roleClass['studio']">
          <span>{{ $t(`models.roles.studio`) }}</span>
        </v-chip>
        <v-chip  v-else :class="roleClass[item.role]">
          <span>{{ $t(`models.roles.${item.role}`) }}</span>
        </v-chip>
      </template>
      <template #item.agentPayout="{ item }">
        <span v-if="!editMode || selectedUser.id !== item.id"
          >{{ $t(`types.boolean.${item.agentPayout || false}`) }}
        </span>
        <v-row v-else align="center">
          <v-col cols="6">
            <v-checkbox
              dense
              v-model="item.agentPayout"
            />
          </v-col>
          <v-col cols="3">
            <v-icon @click="close()">close</v-icon>
          </v-col>
          <v-col cols="3">
            <v-icon @click="saveEdit(item)">check_circle</v-icon>
          </v-col>
        </v-row>
      </template>
      <template #item.fee="{ item }">
        <span v-if="!editMode || selectedUser.id !== item.id"
          >{{ item.fee || 0 }}%
        </span>
        <v-row v-else>
          <v-col cols="8">
            <v-text-field
              hide-details="auto"
              outlined
              dense
              :rules="[$vInteger, $vMin(0), $vMax(100)]"
              label="% Fee"
              v-model="item.fee"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="2"
            style="margin-top: auto; margin-bottom: auto; display: inherit"
          >
            <v-icon @click="close()">close</v-icon>
            <v-icon @click="saveEdit(item)">check_circle</v-icon>
          </v-col>
        </v-row>
      </template>
      <template #item.promotion="{ item }">
        <h4
          :style="{
            color:
              item.promotion.status === 'rejected'
                ? '#ff6868'
                : item.promotion.status === 'pending'
                ? '#b0b0b0'
                : item.promotion.status === 'inactive'
                ? '#a3a3a3'
                : '#00dc00'
          }"
        >
          {{
            item.promotion.status === "approved"
              ? item.verifiedAt
              : item.promotion.status
          }}
        </h4>
        <!--<a
          v-if="item.promotion.status !== 'inactive'"
          text
          @click.prevent="selectUser(item)"
          >{{ $t("admin.users.promotion.review") }}</a
        >-->
      </template>
      <template #item.actions="{ item }">
        <v-icon @click="edit(item)" v-if="!editMode">edit</v-icon>
        <v-icon @click="impersonate(item)">supervised_user_circle</v-icon>
        <confirm-btn
          v-if="isAdmin"
          icon
          :dark="darkTheme"
          :question="
            $t('agents.delete_partner_question', {
              agent: agentUsername,
              partner: item.username
            })
          "
          color="red"
          @confirmed="deletePartner(item)"
        >
          <v-icon>delete</v-icon>
        </confirm-btn>
      </template>
    </v-data-table>
    <c-dialog persistent width="800" :actions="false" v-model="dialog">
      <template slot="title">
        <h1>{{ $t("admin.users.promotion.review") }}</h1>
      </template>
      <PromotionForm
        v-bind="selectedUser.promotion"
        :username="selectedUser.username"
        @close="hideDialog"
        hide-buttons
      />
    </c-dialog>
  </section>
</template>
<script>
import AgentApi from '@/api/AgentApi'
import dialog from '@/components/mixins/dialog'
import table from '@/components/mixins/table'
import PromotionForm from '@/components/admin/users/PromotionForm'
import { mapState } from 'vuex'
import cookies from '@/cookies'

export default {
  name: 'PartnersTable',
  mixins: [table(AgentApi, 'listPartners'), dialog],
  components: { PromotionForm },
  props: {
    pageItems: { type: Number, default: 10 },
    title: String,
    noTitle: { type: Boolean, default: true },
    agentUsername: String
  },
  data () {
    return {
      headers: [
        { text: this.$t('models.user.username'), value: 'username' },
        { text: this.$t('models.user.role'), value: 'role' },
        { text: this.$t('models.user.blocked'), value: 'blocked' },
        { text: this.$t('models.user.createdAt'), value: 'createdAt' },
        { text: this.$t('models.influencer_request.verifiedAt'), value: 'promotion' },
        { text: this.$t('agents.agentPayout'), value: 'agentPayout' },
        { text: this.$t('application.fee'), value: 'fee' },
        { text: this.$t('models.ranking.earningCount'), value: 'earningCount' },
        { text: this.$t('models.ranking.postsCount'), value: 'postCount' },
        { text: this.$t('models.ranking.fansCount'), value: 'fansCount' },
        { text: this.$t('actions.actions'), value: 'actions', sortable: false }
      ],
      sort: 'username',
      order: 'asc',
      search: undefined,
      selectedUser: {},
      role: '',
      promotionStatus: '',
      perPage: 5,
      editMode: false,
      roleClass: {
        admin: 'blue-inverted',
        influencer: 'pink-inverted',
        follower: 'yellow-inverted',
        studio: 'green-inverted'
      }
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isAdmin']),
    ...mapState('preferences', ['darkTheme']),
    tableTitle () {
      return this.title || this.$t('agents.title')
    },
    promotionStatuses () {
      return [{
        text: this.$t('admin.users.promotion.statuses.approved'),
        value: 'approved'
      }, {
        text: this.$t('admin.users.promotion.statuses.pending'),
        value: 'pending'
      }, {
        text: this.$t('admin.users.promotion.statuses.standby'),
        value: 'standby'
      }, {
        text: this.$t('admin.users.promotion.statuses.rejected'),
        value: 'rejected'
      }]
    },
    titleAction () {
      if (!this.noTitle) {
        return {
          text: this.$t('actions.show_all'),
          action: () => this.$router.push({ name: 'partner_partners' })
        }
      }
    },
    userRoles () {
      return [{
        text: this.$t('words.all'),
        value: ''
      }, {
        text: this.$t('models.roles.follower'),
        value: 'follower'
      }, {
        text: this.$t('models.roles.influencer'),
        value: 'influencer'
      }, {
        text: this.$t('models.roles.studio'),
        value: 'studio'
      }]
    },
    query () {
      const query = {
        search: this.search,
        role: this.promotionStatus ? '' : this.role,
        promotionStatus: this.promotionStatus,
        order: `${this.sort}:${this.order}`,
        isAgent: false
      }
      if (query.role === 'studio') {
        query.role = ''
        query.isAgent = true
      }
      if (this.isAdmin) {
        query.agentUsername = this.agentUsername
      }
      return query
    }
  },
  methods: {
    selectUser (user) {
      this.selectedUser = user
      this.showDialog()
    },
    edit (user) {
      this.selectedUser = user
      this.editMode = true
    },
    async deletePartner (item) {
      await AgentApi.removePartner(this.agentUsername, item.username)
      this.loadItems()
    },
    async saveEdit (user) {
      const { error } = this.isAdmin
        ? await AgentApi.updateAdminPartner(this.agentUsername, { partner: user.username, fee: user.fee, agentPayout: user.agentPayout || 0 })
        : await AgentApi.updatePartner({ partner: user.username, fee: user.fee || 0 })
      if (!error) {
        this.editMode = false
        this.selectedUser = {}
      }
    },
    close () {
      this.editMode = false
      this.selectedUser = {}
    },
    impersonate (user) {
      cookies.set('AuthToken', user.authToken)
      const location = `${window.location.origin}/${user.username}`
      window.open(location, '_blank')
    },
    mapItem (user) {
      const feeplatform = user.earningCountRealTime * (20 / 100)
      const earningCount = user.earningCountRealTime - feeplatform
      return {
        ...user,
        createdAt: this.$datetime(user.createdAt),
        verifiedAt: this.$date(user.promotion && user.promotion.verifiedAt),
        earningCount: this.$currency(earningCount),
        postCount: user.ranking.postCount,
        fansCount: user.ranking.fansCount
      }
    },
    changeSort (order) {
      this.order = order ? 'desc' : 'asc'
    }
  },
  beforeMount () {
    this.perPage = this.pageItems
  }
}
</script>
<style lang="scss" scoped>
.dark {
  thead tr th {
    color: #f9f9f9 !important;
  }

  tbody :hover {
    background-color: #575858 !important;
  }
}

.v-icon {
  height: 16px !important;
  width: 16px !important;
  margin-right: 5px;
}

.v-btn--icon {
  height: 16px !important;
  width: 16px !important;
}
</style>
