<template>
  <v-dialog
    persistent
    v-bind="$attrs"
    v-on:input="$emit('input', $event)"
    :width="widthRes"
    class="p-0"
  >

    <!-- <Logo color="white" class="mb-4 center"/> -->
    <v-card
      flat
      tile
      :class="background"
      class="border"
      style="overflow: hidden;"
      :style="{borderRadius: borderRadius, borderColor: `${borderColor} !important`}"
    >
      <div>
        <div class="row">
          <Logo :color="logoColor" class="mb-4 center" style="margin: auto;margin-top: 24px;"/>
          <div class="close" v-if="closable">
            <v-btn icon @click.stop="close" color="black">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="center">
          <slot name="left"/>
          <slot name="center"/>
          <slot name="rigth"/>
        </div>
        <slot/>
      </div>

    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import Logo from '@/views/landing/specialCampaign/components/Logo'

export default {
  name: 'CDialog',
  components: { Logo },
  props: {
    actions: {
      type: Boolean,
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: true
    },
    canSaveFunction: {
      type: String,
      default: null
    },
    closable: {
      type: Boolean,
      default: true
    },
    background: String,
    borderRadius: {
      type: String,
      default: '0px'
    },
    borderColor: {
      type: String,
      default: 'white'
    },
    logoColor: {
      type: String,
      default: 'black'
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    widthRes () {
      return this.isMobile ? '358px' : '712px'
    }
  },

  methods: {
    close () {
      this.$emit('input', false)
      this.$emit('close')
    }
    /* saveFunction() {
      document.getElementById("hiddenLink").click();
    } */
  }
}
</script>
<style lang="scss" scoped>
@import "../_styles";

.close {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.border {
  border: solid 2px !important;
  border-radius: 20px;
}

.bg {
  // background: url(../resources/fondo_carnival.svg) no-repeat center right; background-size: cover; min-height: 380px;
  //border: 2px solid var(--unnamed-color-ffffff);
  //background: transparent linear-gradient(20deg, #F0A500 0%, #E45826 100%) 0% 0% no-repeat padding-box;
  //border-radius: 13px;
  //box-shadow: 0px 8px 12px #0000008F;
  //border: 2px solid #FFFFFF;
  opacity: 1;
}

.bg-mobile {
  // background: url(../resources/fondo_carnival_mobile.svg) no-repeat center right; background-size: cover; min-height: 380px;
  //border: 2px solid var(--unnamed-color-ffffff);
  //background: transparent linear-gradient(20deg, #F0A500 0%, #E45826 100%) 0% 0% no-repeat padding-box;
  //border-radius: 13px;
  //box-shadow: 0px 8px 12px #0000008F;
  //border: 2px solid #FFFFFF;
  opacity: 1;
}

@media (max-width: 700px) {

  .bg {
    // background: url(../resources/fondo_modal_mobile.svg) no-repeat center right;
    background-size: cover;
    min-height: 380px;
    //border: 2px solid var(--unnamed-color-ffffff);
    //background: transparent linear-gradient(20deg, #F0A500 0%, #E45826 100%) 0% 0% no-repeat padding-box;
    //box-shadow: 0px 8px 12px #0000008F;
    //border: 2px solid #FFFFFF;
    //border-radius: 13px;
    opacity: 1;
  }
}
</style>
