<template>
  <section>
    <div class="mb-2">
      {{ $t('subscriptions.monthly_price') }}:
      <strong class="primary--text">{{ $currency(currentUser.subscriptionPrice, currentUser.billing.currency) }}</strong>
      <c-btn x-small
        class="ml-2 mb-1"
        @click="emitConfig">
        {{ $t('actions.change') }}
      </c-btn>
    </div>
    <v-chip  v-for="offer in enabledOffers"
      :key="offer.type"
      outlined
      color="primary"
      class="mr-2 mb-1">
        {{ $t(`subscriptions.discounts.enabled_${offer.type}`, { price: offerPrice(offer) }) }}
    </v-chip>
  </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'SubscriptionInfo',
  computed: {
    ...mapState('profile', ['currentUser']),
    enabledOffers () {
      return this.currentUser.subscriptionOffers.filter(o => o.enabled)
    }
  },
  methods: {
    offerPrice (offer) {
      const discountPrice = (parseFloat(offer.discount) / 100 * this.currentUser.subscriptionPrice)
      const price = offer.months * (this.currentUser.subscriptionPrice - discountPrice)
      return this.$currency(price, this.currentUser.billing.currency)
    },
    emitConfig () {
      this.$emit('open-config', 'offers')
    }
  }
}
</script>
