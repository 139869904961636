<template>
  <v-form
    v-model="validForm" ref="form"
    @submit.prevent="submit">
    <FileUploader class="mb-5"
      autoupload
      :value="files"
      max-images="5"
      content-type="image/*"
      @file-removed="removeFile"
      @file-uploaded="addFile" />
    <v-text-field outlined
      :label="$t('models.product.name')"
      v-model="name"
      counter="75"
      :rules="[$vRequired, $vBannedWords(name), $vNoHtml(name)]">
    </v-text-field>
    <v-text-field outlined
      :label="$t('models.product.description')"
      v-model="description"
      :rules="[$vRequired, $vBannedWords(description), $vNoHtml(description)]">
    </v-text-field>
    <v-row>
      <v-col cols="12" :sm="saleSoclums">
        <v-select outlined disabled
          :label="$t('models.product.sale_type')"
          :value="productType"
          :items="saleTypes">
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" v-if="isAuction">
        <v-text-field outlined
          :disabled="!!product.bidsCount"
          persistent-hint
          :label="$t('models.product.auction_duration')"
          v-model="duration"
          :hint="durationHint"
          :rules="[$vInteger]">
        </v-text-field>
      </v-col>
    </v-row>
    <template v-if="isSale">
      <v-checkbox color="primary"
        :label="$t('models.product.infinite_stock')"
        v-model="infiniteStock">
      </v-checkbox>
      <p class="small-text">
        {{ $t('models.product.infinite_stock_hint') }}
      </p>
    </template>
    <v-row align="center">
      <v-col cols="2" class="text-right mb-8">
        <v-icon>monetization_on</v-icon>
        <span class="small-text">{{ $t('models.product.price') }}</span>
      </v-col>
      <v-col cols="10">
        <CurrencyInput outlined
          color="orange"
          :disabled="!!product.bidsCount"
          v-model="initialPrice"
          :hint="priceHint"
          :rules="[$vRequired]"
          minValue="500" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" :sm="deliveryColumns">
        <v-select outlined hide-details
          :label="$t('models.product.delivery_type')"
          v-model="deliveryType"
          :items="deliveryTypes"
          :rules="[$vRequired]">
        </v-select>
      </v-col>
      <v-col v-if="isPacket"
        class="flex-grow">
        <CurrencyInput outlined
          color="orange"
          :disabled="!!product.bidsCount"
          :label="$t('products.shipping_costs')"
          v-model="shippingCost"
          :hint="deliveryPriceHint"
          minValue="0" />
      </v-col>
    </v-row>
    <v-card-actions>
      <div>
        <b>Total: {{ totalPrice }}</b>
      </div>
      <v-spacer />
      <c-btn text color="secondary"
        @click="close('close')">
          {{ $t('actions.close') }}
      </c-btn>
      <c-btn :disabled="!submittable"
        @click.stop.prevent="submit">
        {{ $t("actions.publish") }}
      </c-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import ProductService from '@/components/products/mixins/product_service'
import validatable from '@/components/mixins/validatable'
import { FileWrapper } from '@/components/mixins/file_manager'

const Deliveries = ['digital', 'packet']
const Sales = ['sale', 'auction']

export default {
  name: 'EditForm',
  mixins: [ProductService(), validatable('form')],
  props: {
    product: Object
  },
  data () {
    return {
      productType: '',
      name: '',
      description: '',
      deliveryType: '',
      initialPrice: '',
      shippingCost: 0,
      infiniteStock: false,
      duration: null,
      newFiles: [],
      initialFiles: [],
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    saleSoclums () {
      return this.isAuction ? 6 : 12
    },
    deliveryColumns () {
      return this.isPacket ? 6 : 12
    },
    files () {
      return this.initialFiles.concat(this.newFiles)
    },
    deliveryTypes () {
      return Deliveries.map(value => ({ text: this.$t(`models.product.delivery_types.${value}`), value }))
    },
    durationHint () {
      return this.product.bidsCount ? this.$t('products.edit.auction_no_editable') : this.$t('products.auction.duration_hint')
    },
    priceHint () {
      return this.product.bidsCount ? this.$t('products.edit.auction_no_editable') : 'XXX,YY'
    },
    deliveryPriceHint () {
      return this.product.bidsCount ? this.$t('products.edit.auction_no_editable') : this.$t('products.shipping_cost_hint')
    },

    saleTypes () {
      return Sales.map(value => ({ text: this.$t(`models.product.sale_types.${value}`), value }))
    },

    isAuction () {
      return this.productType === 'auction'
    },

    isSale () {
      return this.productType === 'sale'
    },

    isPacket () {
      return this.deliveryType === 'packet'
    },
    submittable () {
      return this.files.length && this.validForm
    },
    totalPrice () {
      return this.$currency((this.initialPrice || 0) + (this.shippingCost || 0))
    }
  },
  methods: {
    resetState () {
      this.productType = this.product.productType
      this.name = this.product.name
      this.description = this.product.description
      this.deliveryType = this.product.deliveryType
      this.initialPrice = this.product.initialPrice
      this.shippingCost = this.product.shippingCost || 0
      this.infiniteStock = this.product.infiniteStock
      this.duration = this.product.duration
      this.initialFiles = this.product.resources.map(res => {
        const uri = res.url.split('?')[0]
        return new FileWrapper({
          key: uri,
          name: uri,
          preview: res.url,
          uploaded: true,

        })
      })
      this.newFiles = []
    },

    addFile (file) {
      this.newFiles.push(file)
    },

    removeFile (file) {
      let fileIndex = this.initialFiles.findIndex(f => f.key === file.key)
      if (fileIndex !== -1) {
        this.initialFiles.splice(fileIndex, 1)
        return
      }
      fileIndex = this.newFiles.findIndex(f => f.name === file.name)
      if (fileIndex !== -1) {
        this.newFiles.splice(fileIndex, 1)
        return
      }
    },

    async onSubmit () {
      const { data, error } = await this.updateProduct({
        id: this.product.id,
        productType: this.productType,
        deliveryType: this.deliveryType,
        name: this.name,
        description: this.description,
        initialPrice: this.initialPrice,
        shippingCost: this.shippingCost,
        infiniteStock: this.infiniteStock,
        duration: this.duration,
        initialResources: this.initialFiles.map(f => f.key),
        newResources: this.newFiles.map(f => f.location)
      })
      // TODO notify error
      this.$eventBus.publish('notification', {
        error,
        message: this.$t('snackbar.update_success')
      })
      this.close('submit', data)
    },

    close (event, value) {
      this.resetState()
      this.$emit(event, value)
    }
  },

  mounted () {
    this.resetState()
  }
}
</script>
