<template>
  <v-form
    v-model="validForm" ref="form"
    @submit.stop.prevent="submit">
    <v-card-text>
      <v-select outlined hide-details
        :label="$t('subscriptions.trials.max_fans')"
        v-model="maxFans"
        :items="fansItems"
        :rules="[$vRequired]">
      </v-select>
    </v-card-text>
    <v-card-text>
      <v-select outlined hide-details
        :label="$t('subscriptions.trials.duration')"
        v-model="duration"
        :items="durationItems"
        :rules="[$vRequired]">
      </v-select>
    </v-card-text>
<!--    <v-row align="center">
      <v-col cols="auto">
        <v-checkbox color="primary"
          class="ml-3"
          :label="$t('subscriptions.trials.credit_card')"
          v-model="creditCard">
        </v-checkbox>
      </v-col>
      <v-col cols="4">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon v-on="on">info</v-icon>
        </template>
        <p class="small-text">{{ $t('subscriptions.trials.credit_card_hint_1', { price }) }}</p>
        <p class="small-text">{{ $t('subscriptions.trials.credit_card_hint_2', { price }) }}</p>
      </v-tooltip>
      </v-col>
    </v-row>
-->
    <v-card-text>
      <v-select outlined hide-details
        :label="$t('subscriptions.trials.expiration')"
        v-model="expiration"
        :items="expirationItems"
        :rules="[$vRequired]">
      </v-select>
    </v-card-text>
    <v-card-text>
      {{ trialDescription }}
    </v-card-text>
    <v-card-actions class="justify-end">
      <c-btn
        text
        color="secondary"
        @click="emitCancel">
        {{ $t('actions.close') }}
      </c-btn>
      <c-btn color="primary"
        @click="submit">
        {{ $t('actions.save') }}
      </c-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import validatable from '@/components/mixins/validatable'
import messages from '../locales/all.json'

export default {
  name: 'TrialForm',
  mixins: [validatable('form')],
  i18n: { messages },
  data () {
    return {
      maxFans: 1,
      expiration: 7,
      duration: 3,
      creditCard: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    fansItems () {
      return Array.from({ length: 100 }, (item, index) => ({
        value: index + 1, text: this.$tc('subscriptions.trials.max_fans_count', index + 1)
      }))
    },
    durationItems () {
      return Array.from({ length: 30 }, (item, index) => ({
        value: index + 1, text: this.$tc('subscriptions.trials.duration_count', index + 1)
      }))
    },
    expirationItems () {
      return Array.from({ length: 30 }, (item, index) => ({
        value: index + 1, text: this.$tc('subscriptions.trials.duration_count', index + 1)
      }))
    },
    trialDescription () {
      const key = this.creditCard ? 'subscriptions.trials.trial_description_card' : 'subscriptions.trials.trial_description_no_card'
      return this.$t(key, { price: this.price, days: this.durationItems[this.duration - 1].text })
    },
    price () {
      return this.$currency(this.currentUser.subscriptionPrice, this.currentUser.billing.currency)
    },
    formData () {
      return {
        maxFans: this.maxFans,
        expiration: this.expiration,
        duration: this.duration,
        creditCard: false
      }
    }
  },
  methods: {
    async onSubmit () {
      const { data, error } = await this.$store.dispatch(ProfileActions.CreateTrial, this.formData)
      if (!error) this.emitSubmitted({ trial: data })
    }
  }
}
</script>
<style lang="scss" scoped>
.label {
  width: 120px;
  i {
    margin-right: 5px;
  }
}
</style>
