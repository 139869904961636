<template>
  <v-form ref="form" autocomplete="off" v-model="validForm"
          :dark="false"
          @submit.prevent="submit">
    <v-alert type="error"
             v-if="signupError"
             icon="warning"
             dense outlined>
      {{ signupError }}
    </v-alert>
    <v-row justify="center" dense>
      <v-col cols="12 my-2">
        <UsernameInput outlined
                       class="transparent-background"
                       dense
                       rounded
                       isPromo
                       dark
                       hide-details="auto"
                       v-model="username"
                       :rules="[$vRequired]"/>
      </v-col>
      <v-col cols="12 my-2">
        <v-text-field outlined
                      color="white"
                      class="transparent-background"
                      dense
                      rounded
                      dark
                      hide-details="auto"
                      :label="$t('models.user.email')"
                      v-model="email"
                      :rules="[$vRequired,$vEmail]"></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12 my-2">
        <PasswordInput outlined
                       dense
                       class="transparent-background"
                       dark
                       ref="password"
                       rounded
                       hide-details="auto"
                       v-model="password"
                       :rules="[$vRequired]"/>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12 my-2">
        <v-text-field outlined
                      color="white"
                      class="transparent-background"
                      dense
                      dark
                      rounded
                      hide-details="auto"
                      :label="$t('models.user.bonusCode')"
                      v-model="bonusCode"></v-text-field>
      </v-col>
    </v-row>

    <!--<div class="d-flex justify-left my-4">
      <div class="col-12 viewMore pt-0" @click="showLOPD = !showLOPD"> {{ $t('actions.more_info') }}</div>
    </div>
    <div class="d-flex justify-left">
      <div class="col-12 tracking-in-expand-forward-top" v-if="showLOPD"><p v-html="$t('application.menu.management')"></p></div>
    </div>
-->
    <div class="d-flex justify-left my-2">
      <v-checkbox dense
                  class="mt-0 border-gray"
                  color="white"
                  dark
                  v-model="acceptedTerms"
                  :rules="[$vBoolRequired]">
        <template #label>
          <div style="display:flex; font-size: 14px; color: white">
            {{ $t('models.user.accept') }}&nbsp;
            <a class="ml-1" style="color: white;text-decoration-line: underline" @click.stop :href="goToConditions"
               target="_blank">
              {{ $t('application.menu.terms_conditions') }}</a>
          </div>
        </template>
      </v-checkbox>
    </div>

  <!--<div class="d-flex justify-left">
      <v-checkbox dense
                  :class="isMobile ? 'mt-5' : 'mt-0'"
                  class="border-gray"
                  color="white"
                  dark
                  v-model="acceptedMarketing">
        <template #label>
          <div style="display:flex; font-size: 14px; color: white">
            {{ $t('application.menu.marketing') }}
          </div>
        </template>
      </v-checkbox>
    </div> -->
    <c-btn block
           :class="{'mt-4':isMobile}"
           style="background-color: #3FB848 !important"
           background-color
           type="submit"
           @click.stop.prevent="submit">
      <b style="height: 40px" class="center">{{ $t('actions.signup') }}</b>
    </c-btn>
  </v-form>
</template>

<script>
import { SessionActions } from '@/store'
import validatable from '@/components/mixins/validatable'
import { mapState } from 'vuex'

export default {
  name: 'SignUpPromos',
  mixins: [validatable('form')],
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    challenge: {
      type: Boolean,
      default: false
    },
    autofocus: Boolean,
    origin: {
      type: String,
      default: 'Landing'
    },
    small: {
      type: Boolean,
      default: false
    },
    promo: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      actionUser: true,
      validForm: false,
      username: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      acceptedTerms: false,
      acceptedMarketing: false,
      isAdult: true,
      bonusCode: '',
      showLOPD: false,
      signupError: ''
    }
  },
  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('application', ['isMobile']),
    goToConditions () {
      return `https://${this.$t('site')}/conditions`
    }
  },
  methods: {

    user () {
      return {
        username: this.username.trim(),
        email: this.email.trim(),
        password: this.password.trim(),
        acceptedTerms: this.acceptedTerms,
        isAdult: this.isAdult,
        lang: this.$i18n.locale.trim(),
        bonusCode: this.bonusCode,
        acceptedMarketing: this.acceptedMarketing
      }
    },
    async onSubmit () {
      this.signupError = null
      const {
        data,
        error
      } = await this.$store.dispatch(SessionActions.Signup, this.user())
      if (!error) {
        this.$router.replace({ name: 'become_star', params: { username: this.username, email: this.mail, password: this.password } })
      } else {
        if (data.errorCode.includes('bonusCode')) {
          this.signupError = this.$t(`remote_errors.bonus_code`)
        }
        if (data.data && data.data.field) {
          const field = this.$t(`models.user.${data.data.field}`)
          const error = this.$t(`remote_errors.${data.errorCode}`, {
            field,
            value: data.data.value
          })
          this.signupError = error
          this.$vuetify.goTo(0)
        }
      }
    },
    redirect () {
      const origin = sessionStorage.getItem('origin')
      if (origin && origin !== '/login') {
        this.$router.replace(origin)
        sessionStorage.removeItem('origin')
      } else {
        if (this.challenge) {
          this.$emit('logged')
        } else {
          this.$router.replace('/home')
        }
      }
    }
  },
  beforeDestroy () {
    if (this.authenticated) {
      this.$gtag.event('signUp', { event_category: this.origin })
    }
  },
  mounted() {
    if (this.promo) {
      this.bonusCode = this.promo
    }
  }
}
</script>
<style lang="scss" scoped>
.transparent-background >>> .v-input__slot {
  background-color: transparent !important;
  border-color: #8D8D8D;
}

.transparent-background >>> .v-label {
  background-color: transparent !important;
  border-color: #8D8D8D;
  padding: 0 6px;
}

.border-gray >>> svg {
  fill: #8D8D8D !important;
}

.viewMore {
  color: white;
}

.tracking-in-expand-forward-top {
  width: min-content;
  color: white;
}

</style>
