<template>
  <v-data-table
    class="elevation-2"
    must-sort
    :headers="headers"
    :loading="loading"
    :items="items"
    item-key="username"
    :page.sync="page"
    :items-per-page.sync="perPage"
    :server-items-length="totalItems"
    :mobile-breakpoint="0"
    :sort-by.sync="sort"
    :sort-desc.sync="sortDesc">
    <template #top>
      <v-row no-gutters>
        <v-col cols="12" sm="3" class="pa-2">
          <v-text-field
            rounded
            outlined
            dense
            hide-details
            clearable
            class="ma-2"
            :label="$t('actions.search')"
            v-model="search"
            @input="startLoading"
            @click:clear="stopLoading">
          </v-text-field>
        </v-col>
      </v-row>
    </template>
    <template #item.username="{ item }">
      <router-link :to="{ name: 'influencer', params: { username: item.username } }">
        {{ `@${item.username}` }}
      </router-link>
    </template>
    <template #item.role="{ item }">
      <span class="large-text success--text"> {{ item.role }}</span>
    </template>
    <template #item.fee="{ item }">
      <template v-if="item.fee > 0">
        <span class="large-text success--text">+</span>
        <Currency unsigned :amount="item.fee"></Currency>
      </template>
      <template v-else>-</template>
    </template>
  </v-data-table>
</template>
<script>
import table from '@/components/mixins/table'
import UserApi from '@/api/UserApi'

export default {
  name: 'ReferralTAble',
  mixins: [table(UserApi, 'referred')],
  props: {
    sortable: { type: Boolean, default: true }
  },
  data () {
    return {
      headers: [
        { text: this.$t('models.referral.username'), value: 'username' },
        { text: this.$t('models.referral.date'), value: 'createdAt' },
        { text: this.$t('models.referral.role'), value: 'role' },
        { text: this.$t('models.referral.fee'), value: 'fee' }
      ],
      search: '',
      sort: 'fee',
      sortDesc: true
    }
  },
  computed: {
    query () {
      return { search: this.search }
    }
  },
  methods: {
    mapItem (item) {
      return {
        ...item,
        createdAt: this.$date(item.createdAt)
      }
    }
  }
}
</script>
<style lang="scss">

.dark {

  thead tr th {
    color: #F9F9F9 !important;
  }

  tbody :hover {
    background-color: #575858 !important;
  }
}

</style>
