<template>
  <section>
    <div class="tooltip">
      <slot></slot>
      <span class="tooltiptext">{{ text }}</span>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Tooltip',
  props: {
    text: { type: String ,default:''}
  }
}
</script>
<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: flex;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 17%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
