<template>
  <section>
    <h1 class="mb-1">{{ $t('blocked_countries.title')}}
      <slot name="actions"></slot>
    </h1>
    <p v-if="description">{{ $t('blocked_countries.description')}}
    <p v-if="!bannedCountries.length"
      class="px-5">
      {{ $t('blocked_countries.no_countries')}}
    </p>
    <template v-for="country in bannedCountries">
      <v-chip outlined :close="editable"
        :key="country"
        :value="country"
        color="primary"
        class="mx-1"
        @click:close="$emit('unblock', country)">
        {{ $country(country) }}
      </v-chip>
    </template>
  </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'BlockedCountryInfo',
  props: {
    editable: Boolean,
    description: { type: Boolean, default: true }
  },

  data () {
    return {
      loadedCountries: []
    }
  },

  computed: {
    ...mapState('profile', ['bannedCountries'])
  }
}
</script>
