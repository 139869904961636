<template>
  <section :class="getPromoClass" class="background-promo">
    <div class="form" :class="getClass">
      <div class="center row" :style="getStyle">
        <component :is="getPromo" ></component>
      </div>
    </div>
  </section>
</template>

<script>
import bsummit from './bsummit'
import desade from './desade'
import commupdate2023 from './commupdate2023'
import sanctuary2023 from './sanctuary2023'
import { mapState } from 'vuex'
export default {
  name: 'LandingCode',
  components: {
    bsummit,
    desade,
    commupdate2023,
    sanctuary2023
  },
  computed: {
    ...mapState('application', ['isMobile']),
    getPromo () {
      return this.$route.name
    },
    getPromoClass () {
      const classCss = this.getPromo + (this.isMobile ? '-mobile' : '')
      return classCss
    },
    getClass () {
      return {
        'my-auto': !this.isMobile,
        center: !this.isMobile
      }
    },
    getStyle () {
      return {
        'padding-top': this.isMobile ? '5%' : ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.bsummit {
  background-image: url("./resources/bsummit-background.png");
}
.desade {
  background-image: url("./resources/desade-background.png");
}
.sanctuary2023 {
  background-image: url("./resources/sanctuary-background.png");
}
.sanctuary2023-mobile {
  background-image: url("./resources/sanctuary-background-mobile.png");
}
/*.commupdate2023 {
  background-image: url("./resources/desade-background.png");
}*/

.background-promo {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 100% !important;
}

.form {
  height: inherit;
}

@media (max-width: 760px) {

  .background-promo {
    height: auto !important;
  }

  .bsummit {
    background-image: url("./resources/bsummit-background-mobile.png");
  }

  .form {
    padding-bottom: 50px;
  }
}
</style>
