<template>
  <div class="center" style="position: relative;">
    <div class="position-middle">
      <div class="circle backgrop gradient">
        <v-icon x-large class="white-light-text">
          play_arrow
        </v-icon>
      </div>
    </div>
    <video
      ref="video"
      controlslist="nodownload"
      :src="src"
      class="video-responsive"
      :class="classImg"
      alt=""
    ></video>
  </div>
</template>
<script>
export default {
  name: "VVideo",
  props: {
    primary: Boolean,
    src: String
  },
  computed: {
    classImg() {
      return {
        "primary-video": this.primary,
        "secondary-video": !this.primary
      };
    }
  }
};
</script>
<style lang="scss" scoped>
video {
  width: 100%;
  height: 100%;
}
.primary-video {
  max-width: 350px;
  max-height: 350px;
  //width: 350;
  width: 100%;
  min-height: 300px;
}
.secondary-video {
  max-width: 100px !important;
  max-height: 100px !important;
  min-height: 100px;
}
.video-responsive {
  /* height: auto; */
  object-fit: contain;
  background: #6e6c6c;
  border-radius: 4px;
  /* min-width: 40px  */ /*operahack*/
}
.gradient {
  background: linear-gradient(
    to bottom,
    rgba(188, 188, 188, 0.5),
    rgba(188, 188, 188, 0.5)
  );
}
.ajust {
  position: absolute;
  left: 55%;
  top: 70%;
}
.position-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
</style>
