<template>
  <v-card>
    <v-card-title class="headline">{{ $t('email_validation.title') }}</v-card-title>
    <v-card-text v-if="validating">{{ $t('email_validation.validating')}}</v-card-text>
    <v-card-text v-else-if="error">
      {{ $t("email_validation.validation_error") }}
    </v-card-text>
    <v-card-text v-else>{{ $t('email_validation.validated')}}</v-card-text>
    <div v-if="validating"
      class="text-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate>
      </v-progress-circular>
    </div>
    <v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <c-btn v-if="isSuccess"
          depressed
          color="primary"
          :to="{name: 'home'}">
          {{ $t("actions.continue") }}
        </c-btn>
        <c-btn v-if="error"
          depressed
          color="primary"
          @click.stop="requestEmail">
          {{ $t("actions.request_email") }}
        </c-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { SessionActions } from '@/store'
import AccountApi from '@/api/AccountApi'

export default {
  name: 'EmailValidation',
  props: ['email_token'],
  data () {
    return {
      validating: true,
      error: false
    }
  },

  computed: {
    isSuccess () {
      return !this.validating && !this.error
    }
  },

  methods: {
    requestEmail () {
      return AccountApi.requestValidation()
    }
  },

  async mounted () {
    const { data, error } = await AccountApi.validate(this.email_token)
    if (!error) {
      this.$store.dispatch(SessionActions.SetSession, data)
      this.$router.push('/')
    }
    this.validating = false
    this.error = error
  }
}
</script>
