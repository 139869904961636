module.exports = {
  title: 'Scatbook FAQ',
  question_1: 'IS IT NECESSARY TO REGISTER ON SCATBOOK IF I HAVE AN ACCOUNT ON LOVERFANS?',
  answer_1: 'No. Your username and password have been recorded in Scatbook so you do not have to do anything. You just have to login in https://scatbook.com/login with the same data that you access Loverfans',
  question_2: 'DO I HAVE TO UPLOAD THE CONTENT THAT I ALREADY HAD IN MY LOVERFANS ACCOUNT?',
  answer_2: 'No. We have already taken care of moving all the content to your new Scatbook account automatically. Remember that from now on our specialized platform to upload this type of content is Scatbook to avoid confusion for users and to make the portal a reference for extreme content (mainly scat) <strong>you can only upload this type of content here</strong>.',
  question_3: 'WHAT HAPPENS TO THE REST OF MY ACCOUNT? (Preferences, followers, fans)',
  answer_3: 'All your preferences, payment data, followers and fans <strong>will be duplicated automatically</strong> in your Scatbook account, in this way you will have the opportunity to attract your followers by offering two types of differentiated content: on the one hand extreme content (scat) and on the other hand other content side of a more classic cut (Loverfans)',
  question_4: 'WHAT HAPPENS TO MY FANS (PAID SUBSCRIBERS)?',
  answer_4: 'Your paid subscribers will continue to be active in Loverfans as they have been up to now so that they can continue to enjoy the content you have in your account, including the videos they have purchased from “Mediateca” (paid videos outside of subscription). As we have also mentioned, these will double as followers on Scatbook, so you have a unique opportunity to gain loyalty from your fans on two different payment platforms.',
  question_5: 'DO MY USERS HAVE TO RE-REGISTER ON SCATBOOK?',
  answer_5: 'No. Following the same policy as with content creators, users who have an account on Loverfans will have an account on Scatbook, so this change does not affect them either.',
  question_6: 'CAN I KEEP USING MY LOVERFANS ACCOUNT?',
  answer_6: 'Yes, your Loverfans account will remain active along with your paid subscribers. The difference is that <strong>from now on extrem (scat) content must be uploaded to Scatbook</strong> using your Loverfans account for conventional content.',
  question_7: 'MY CONTENT IS EXCLUSIVELY FROM SCAT',
  answer_7: '<strong>If your content is exclusively from scat</strong> and although your Loverfans account will remain active <strong>you must upload all your content to Scatbook</strong> although we encourage you to continue updating Loverfans with conventional content. Don\'t worry as your Loverfans users will be sent to Scatbook via a notice on your Loverfans profile',
  question_8: 'WILL YOU DELETE MY SCAT CONTENT FROM LOVERFANS?',
  answer_8: 'Yes, <strong>after a period of adaptation the scat-themed content will be deleted from Loverfans and transferred to Scatbook automatically</strong>. It is the only way to make Scatbook the reference fan platform for extreme content.',
  question_9: 'WHAT DIFFERENCES ARE THERE BETWEEN LOVERFANS AND SCATBOOK?',
  answer_9: 'None! The operation is the same, your account is the same, the means of payment are the same. <strong>EVERYTHING IS THE SAME</strong>. The difference is that creators of extreme-themed content (scat) will be able to enjoy a specialized environment, with specific traffic and with the peace of mind of being part of the only fan platform oriented to this type of content.',
  question_10: 'DOES THE FORM OF PROMOTION CHANGE SOMETHING?',
  answer_10: 'No. The same tools that you had available in Loverfans are available in Scatbook. You will only have to use the promotion tools of each platform depending on the type of content<br>If your content is extreme (scat), use Scatbook\'s link codes, banners and automatic tweets (remember that you have to give permissions to our new Twitter account @scatbooksocial) You can continue promoting the rest of the content on Loverfans as before.',
  question_11: 'DO I HAVE TO CHANGE MY OLD PROMOTIONS OF RRSS (TWITTER AND OTHERS)?',
  answer_11: 'No. Promoting uploaded content from Loverfans to Scatbook will bring the user to your Scatbook account so you don\'t need to change anything.'
}
