<template>
  <div>
    <div class="new-message" :class="cssClass">
      <section class="no-border-blue" v-show="showAttachments">
        <MessageAttachmentsForm
          v-on:reset="resetFormMessage(false)"
          v-on:save-file="saveFiles"
          :files="files"
          @new-price="saveAttachmetPrice"
          @loading="loadingAttachments"
        />
      </section>
      <section class="no-border-blue" v-show="showPriceConversation">
        <PriceConversation
          v-on:changePrice="showPriceConversationDialog"
          v-on:reset="resetFormMessage(false)"
        />
      </section>
      <section class="no-border-blue" v-show="showRecordingsAudio">
        <MessageAudioForm
          :initRecord="showRecordingsAudio"
          v-on:reset="resetFormMessage(false)"
          v-on:new-price="saveAttachmetPrice"
          v-on:save-file="saveFileAudio"
          @loading="loadingAttachments"
        />
      </section>
      <InputText
        v-on:newText="saveMessage"
        @onSendMessage="onSendMessage"
        :disabled="isBlocked"
        ref="inputTextMessage"
        class="input-text"
        :class="{ errorForm: hasError.length > 0 }"
        :clearInputText="clearInputText"
        :has-error="hasError"
        :first-load.sync="firstLoad"
        @hasBannedWord="isBannedWord"
        @required="isRequired"
        @hasHtmlLink="isHtmlTags"
        @hasPhone="isPhone"
        @hasLink="isLink"
      >
        <Button
          color="#6c757d"
          @handler="onSendMessage"
          text="➤"
          :loading="loadingSendMessage"
          :disabled="disabledSendMessage"
        ></Button>
      </InputText>
      <Dialog
        :show="showTips"
        @hide="() => (showTips = !showTips)"
        :title="$t('actions.send_tip').toUpperCase()"
      >
        <template slot="bodyDialog">
          <div>
            <SendTips
              @close="() => (showTips = !showTips)"
              :clearInit="showTips"
            />
          </div>
        </template>
      </Dialog>
      <Dialog
        :show="showLoadFunds"
        @hide="() => (showLoadFunds = !showLoadFunds)"
        :title="$t('actions.load_funds').toUpperCase()"
      >
        <template slot="bodyDialog">
          <div>
            <LoadFunds
              :clearInit="showLoadFunds"
              :textLoadFund="textLoadFund"
            />
          </div>
        </template>
      </Dialog>
      <WarningDialog
        :show="isNotifiedUpdated"
        :textWarning="warningTextBuyChat"
        :textButtonOK="$t('actions.continue').toUpperCase()"
        hide-cancel-button
        @handlerButtonOK="showWarningBoyChatDialog"
        @handlerButtonCancel="showWarningBoyChatDialog"
      />
      <WarningDialog
        :show="showErrorSendChat"
        :textWarning="textErrorSendChat"
        :textButtonOK="$t('actions.continue').toUpperCase()"
        hide-cancel-button
        @handlerButtonOK="showErrorSendChatDialog"
      />
      <WarningDialog
        :show="showWarningMediaPrice"
        :textWarning="textWarningMediaPrice"
        :textButtonOK="$t('actions.continue').toUpperCase()"
        @handlerButtonOK="aceptWarningMediaPrice"
        @handlerButtonCancel="showWarningMediaPrice = false"
      ></WarningDialog>
      <div class="send-file-chat">
        <div class="send-file-icons">
          <icon-button
            type="multi"
            :size="20"
            :disabled="isBlocked"
            @handler="showAttachmentsDialog"
          />
          <icon-button
            type="microf"
            :size="20"
            :disabled="isBlocked"
            @handler="showRecordingsAudioDialog"
          />
        </div>
        <div class="send-cash">
          <span
            v-if="isFree"
            class="type-chat free"
            :class="{ disabled: isBlocked }"
            @click="onFreeIcon"
          >
            Free
          </span>
          <Tooltip v-else :text="tooltipPrice">
            <span
              class="type-chat pay"
              :class="{ disabled: isBlocked }"
              @click="onFreeIcon"
            >{{ currency }}</span
            >
          </Tooltip>
          <div
            class="buttons"
            v-if="isBothFollowers"
            :class="{ green: !isBlocked, disabled: isBlocked }"
            @click="showTipsDialog"
          >
            <icon-button
              class="color-white"
              type="cash"
              :size="15"
              :text="$t('actions.tip').toUpperCase()"
              :disabled="isBlocked"
            />
          </div>
          <div
            v-if="canVideoCall"
            class="buttons"
            :class="{ red: !isBlocked, disabled: isBlocked }"
            @click="onVideoChat"
          >
            <icon-button type="video-chat" :size="20" :disabled="isBlocked"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapGetters} from 'vuex'
import {ChatActions, ProfileActions, AppActions} from '@/store'
import dialog from '@/components/mixins/dialog'
import validatable from '@/components/mixins/validatable'
import CursorService from '@/components/mixins/cursor_api'
import MultimediaMessageDialog from '../MultimediaMessageDialog'

import MessageAttachmentsForm from './MessageAttachmentsForm.vue'
import WarningDialog from '../../component/WarningDialog'
import Dialog from '../../component/Dialog'
import IconButton from '../../component/IconButton.vue'
import InputText from '../../component/InputText.vue'
import LoadFunds from '../LoadFunds'
import SendTips from './SendTips'
import PriceConversation from './PriceConversation'
import Button from '../../component/Buttons.vue'
import Spinner from '../../component/Spinner.vue'
import Tooltip from '../../component/Tooltip.vue'
import messages from '../../locales/all.json'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import MessageAudioForm from './MessageAudioForm'

export default {
  mixins: [dialog, validatable('form'), CursorService(ConversationsApi)],
  i18n: {messages},
  components: {
    MultimediaMessageDialog,
    IconButton,
    InputText,
    Dialog,
    WarningDialog,
    MessageAttachmentsForm,
    PriceConversation,
    SendTips,
    LoadFunds,
    Button,
    MessageAudioForm,
    Tooltip,
    Spinner
  },
  data() {
    return {
      message: '',
      messagePrice: 0,
      showAttachments: false,
      showTips: false,
      showLoadFunds: false,
      showErrorSendChat: false,
      textErrorSendChat: '',
      showPriceConversation: false,
      wihtAttachment: false,
      files: [],
      idAttachemntFiles: [],
      mediaFile: [],
      fileAudio: [],
      clearInputText: false,
      attachmetPrice: 0,
      showWarning: false,
      hasBannedWord: false,
      hasHtmlTags: false,
      hasPhone: false,
      hasRequired: true,
      hasLink: false,
      loadingSendMessage: false,
      loadingAttach: false,
      showRecordingsAudio: false,
      firstLoad: true,
      showWarningMediaPrice: false,
      aceptMediaPrice: false,
      textLoadFund: ''
    }
  },
  props: {
    blocked: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('chats', ['selectedChat', 'viewAttachmentForm']),
    ...mapState('application', ['isMobile']),
    ...mapGetters('chats', ['getPreferences', 'isNotified']),
    ...mapState('profile', ['currentUser', 'isAdmin', 'isInfluencer', 'isAccountManager']),
    darkTheme() {
      return this.$vuetify.theme.dark
    },
    cssClass() {
      return {
        dark: this.darkTheme,
        marginMobile: this.isMobile
      }
    },
    canVideoCall() {
      return this.selectedChat.videochatEnabled && this.selectedChat.receiver.onlineStatus === 'online' && this.selectedChat.sender.onlineStatus === 'online'
    },
    disabledSendMessage() {
      const existAtt = !this.viewAttachmentForm
      const existErr = this.hasError.length > 0
      const exitMessageCount = this.message.length > 1500
      /* console.log('existAtt', existAtt, this.viewAttachmentForm)
      console.log('existErr', existErr, this.hasError)
      console.log('isBlocked', this.isBlocked)
      console.log('exitMessageCount', exitMessageCount)
      console.log('loadingAttach', this.loadingAttach)
      console.log(
        'disabled',
        this.isBlocked ||
          existErr ||
          this.loadingAttach ||
          existAtt ||
          exitMessageCount
      )
      console.log('------------') */
      /*  <!-- :disabled="
            isBlocked ||
              hasError.length > 0 ||
              loadingAttach ||
              existViewAttachmentForm
          " --> */
      return (
        this.isBlocked ||
        existErr ||
        this.loadingAttach ||
        existAtt ||
        exitMessageCount
      )
    },
    isBlocked() {
      return !this.selectedChat.sender.approved
    },
    receiver() {
      return this.selectedChat.receiver
    },
    isFree() {
      return (
        this.selectedChat.price === 0 && this.selectedChat.sender.price === 0
      )
    },
    currency() {
      return this.currentUser.wallet.currency === 'EUR' ? '€' : '$'
    },
    warningTextBuyChat() {
      const price = this.selectedChat.price
      const localePrice = this.$currency(price)
      return `${this.$t(
        'messages.premium.warning_1'
      )} ${this.$t('messages.premium.warning_2', {price: localePrice})}`
    },

    isBothFollowers() {
      return (
        !this.isInfluencer && this.selectedChat.receiver.role != 'follower'
      )
    },
    tooltipPrice() {
      const sendPrice = this.$currency(this.selectedChat.price)
      const preferencePrice = this.$currency(this.selectedChat.sender.price)
      let text = ''
      if (this.isAdmin || this.isAccountManager || this.isInfluencer) {
        if (
          this.selectedChat.receiver.role === 'admin' ||
          this.selectedChat.receiver.role === 'account_manager' ||
          this.selectedChat.receiver.role === 'influencer'
        ) {
          text = this.$t('chats.tooltipPrice', {
            price: sendPrice,
            preferencePrice: preferencePrice
          })
        } else {
          text = this.$t('chats.tooltipPriceCreator', {
            preferencePrice: preferencePrice
          })
        }
      } else {
        text = this.$t('chats.tooltipPriceUser', {
          price: sendPrice
        })
      }
      return text
    },
    hasError() {
      const errors = []
      if (this.fileAudio.length > 0) return errors
      if (this.viewAttachmentForm.length > 0) return errors
      if (this.hasBannedWord) {
        errors.push({
          error: 'bannedWord',
          field: this.hasBannedWord
        })
      }
      if (!this.hasRequired) {
        errors.push({
          error: 'textFieldRequired',
          field: true
        })
      }
      if (this.hasHtmlTags) {
        errors.push({
          error: 'noHtmlTags',
          field: true
        })
      }
      if (this.hasPhone) {
        errors.push({
          error: 'noPhoneNumber',
          field: true
        })
      }
      if (this.hasLink) {
        errors.push({
          error: 'noLinksAllowed',
          field: true
        })
      }
      return errors
    },
    isNotifiedUpdated() {
      return (
        !this.isNotified &&
        !this.isInfluencer &&
        !this.isAdmin &&
        !this.isAccountManager &&
        this.selectedChat.price > 0
      )
    },
    textWarningMediaPrice() {
      const priceAdd = this.selectedChat.mediaPrice + this.selectedChat.price
      const mediaPrice = this.$currency(priceAdd)
      return this.$t('chats.attachment.price_send_file', {price: mediaPrice})
    }
  },
  mounted() {
    this.$store.dispatch(AppActions.UpdateHasMessages, true)
  },
  methods: {
    isBannedWord(value) {
      this.hasBannedWord = value
    },
    isHtmlTags(value) {
      this.hasHtmlTags = value
    },
    isPhone(value) {
      this.hasPhone = value
    },
    isLink(value) {
      this.hasLink = value
    },
    isRequired(value) {
      if (this.fileAudio.length > 0 || this.files.length > 0) {
        this.hasRequired = true
      } else {
        this.hasRequired = (value || '').trim()
      }
    },
    showAttachmentsDialog() {
      this.$emit('go-to-botom')
      this.$store.dispatch(ChatActions.DeleteAttachmentForm)
      this.showPriceConversation = false
      this.showRecordingsAudio = false
      if (this.blocked) {
        return
      }
      this.files = []
      this.showAttachments = !this.showAttachments
    },
    showRecordingsAudioDialog() {
      this.$emit('go-to-botom')
      this.showRecordingsAudio = false
      this.showAttachments = false
      if (this.blocked) {
        return
      }
      // this.files = []
      this.showRecordingsAudio = !this.showRecordingsAudio
    },
    showLoadFundsDialog() {
      this.showLoadFunds = !this.showLoadFunds
    },
    showErrorSendChatDialog() {
      this.showErrorSendChat = !this.showErrorSendChat
    },
    async showWarningBoyChatDialog() {
      const preferences = Object.assign({}, this.getPreferences)
      preferences.notified = true
      const id = this.selectedChat.id

      const {error} = await ConversationsApi.preferences(id, preferences)
      if (!error) {
        this.$store.dispatch(ChatActions.UpdateAttributes, {notified: true})
      }
    },

    showPriceConversationDialog() {
      this.$emit('go-to-botom')
      this.showAttachments = false
      this.showPriceConversation = !this.showPriceConversation
    },
    onFreeIcon() {
      if (this.blocked) {
        return
      }
      if (this.isInfluencer || this.isAdmin || this.isAccountManager) {
        this.showPriceConversationDialog()
      } else {
        this.showLoadFundsDialog()
      }
    },
    showTipsDialog() {
      if (this.blocked) {
        return
      }
      this.showTips = !this.showTips
    },
    saveMessage(newText) {
      this.firstLoad = false
      this.message = newText
    },
    async onSendMessage() {
      this.firstLoad = false
      /* if (!this.disabledSendMessage) {
        return
      } */

      const send = {
        price: this.attachmetPrice,
        message: '',
        mediaIds: []
      }
      const fileAudio = this.fileAudio
      let mediaFileTemp = []
      if (this.viewAttachmentForm) {
        mediaFileTemp = this.viewAttachmentForm
        send.mediaIds = this.viewAttachmentForm.map(f => f.id)
      }
      if (fileAudio.length > 0) {
        mediaFileTemp.push(fileAudio[0])
        send.mediaIds.push(fileAudio[0].id)
      }
      if (
        !this.aceptMediaPrice &&
        !this.isInfluencer &&
        mediaFileTemp.length > 0
      ) {
        this.showWarningMediaPrice = true
        return
      }
      this.message = this.message.endsWith('\n')
        ? this.message.slice(0, -1)
        : this.message
      const msg = {
        conversationId: this.selectedChat.id,
        userId: this.currentUser.id,
        attachments: mediaFileTemp,
        createdAt: new Date(),
        message: this.message.trim(),
        isPurchased: true,
        receivers: [],
        price: this.attachmetPrice,
        idTemp: new Date().getTime()
      }
      send.message = msg.message
      this.$emit('add-message', msg)
      this.firstLoad = true
      this.resetFormMessage(true)
      const {
        data,
        error
      } = await ConversationsApi.createMessage(
        this.selectedChat.id,
        send
      )
      if (!error) {
        msg.isPurchased = true
        msg.attachments = mediaFileTemp
        msg.id = data.id
        this.$emit('refresh-message-after-send', msg)
        this.$store.dispatch(ProfileActions.Fetch)
        // this.loadingSendMessage = false
      } else {
        this.$emit('error-message-send', msg)
        // this.loadingSendMessage = false
        this.errorOnSendMessage(data, msg)
      }
      this.aceptMediaPrice = false
    },
    resetFormMessage(all = false) {
      this.$emit('go-to-botom')
      this.showTips = false
      this.showAttachments = false
      this.clearInputText = !this.clearInputText
      this.showWarning = false
      this.showPriceConversation = false
      this.files = []
      this.fileAudio = []
      this.attachmetPrice = 0
      this.showRecordingsAudio = false
      this.$store.dispatch(ChatActions.DeleteAttachmentForm)
      if (all) {
        this.message = ''
      }
    },
    errorOnSendMessage({errorCode}, msg) {
      if (errorCode === 'not_enough_funds') {
        this.textLoadFund = (msg.attachments && msg.attachments.length) > 0
          ? this.$t('chats.attachment.error_wallet_not_enought_amount', {userReceiver: this.selectedChat.receiver.displayName})
          : this.$t('payments.wallet_not_enought_amount');
        this.showLoadFundsDialog();
      } else if (errorCode === 'user_never_payed') {
        this.textLoadFund = this.$t('payments.user_never_payed');
        this.showLoadFundsDialog();
      } else if (errorCode === 'conversation_blocked') {
        this.textErrorSendChat = this.$t('blocked_users.dialog.blocked', {
          username: this.selectedChat.receiver.displayName
        });
      } else {
        this.textErrorSendChat = this.$t('chats.error.send');
        this.showErrorSendChat = true;
      }
    },

    /*errorOnSendMessage({errorCode}, msg) {
      if (errorCode === 'not_enough_funds') {
        if (msg.attachments.length > 0) {
          this.textLoadFund = this.$t(
            'chats.attachment.error_wallet_not_enought_amount',
            {userReceiver: this.selectedChat.receiver.displayName}
          )
        } else {
          this.textLoadFund = this.$t('payments.wallet_not_enought_amount')
        }
        this.showLoadFundsDialog()
        return
      }
      if (errorCode === 'user_never_payed') {
        this.textLoadFund = this.$t('payments.wallet_not_enought_amount')
        return this.showLoadFundsDialog()
      }
      if (errorCode === 'conversation_blocked') {
        this.textErrorSendChat = this.$t('blocked_users.dialog.blocked', {
          username: this.selectedChat.receiver.displayName
        })
      } else {
        this.textErrorSendChat = this.$t('chats.error.send')
      }
      this.showErrorSendChat = true
    },*/
    saveAttachmetPrice(price) {
      this.attachmetPrice = price
    },
    saveFiles(files) {
      this.files = files
      this.hasRequired = this.files.length > 0
    },
    saveFileAudio(data) {
      this.fileAudio.push(data)
      this.hasRequired = this.files.length > 0
    },
    onVideoChat() {
      this.$store.dispatch(ChatActions.ShowVideoChat, true)
      this.$store.dispatch(ChatActions.ShowCarousel, false)
    },
    loadingAttachments(value) {
      this.loadingSendMessage = value
      this.loadingAttach = value
    },
    aceptWarningMediaPrice() {
      this.aceptMediaPrice = true
      this.showWarningMediaPrice = false
      this.onSendMessage()
    }
  }
}
</script>

<style lang="scss">
.new-message {
  flex: 0 0 10%;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #ffffff;

  &.dark {
    background-color: #1f2c34 !important;
  }
}

.form-chat {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 5px;
  width: 100%;
}

.form-chat-input {
  width: 100%;
  display: inline-block;
  border: 1px solid #d7d7d7;
  height: 40px;
  padding: 7px 15px;
  margin: 4px;
  font-size: 0.9375em;
  border-radius: 30px;
}

.form-chat-button {
  border: 0;
  margin-left: 5px;
  border: 0;
  margin-left: 5px;
  background-color: #6c757d;
  padding: 6px 8px;
  border-radius: 30px;
  cursor: pointer;
}

.type-chat {
  color: #6c757d;
  border-radius: 20px;
  padding: 4px 8px;
}

.free {
  background-color: #e8f9ff;
  border: 1px solid deepskyblue;
  margin-right: 5px;

  &.disabled {
    background-color: #9b9b9b3b;
    border: 1px solid #9b9b9b3b;
  }
}

.pay {
  background-color: #f3d3d3;
  border: 1px solid #d22121;

  &.disabled {
    background-color: #9b9b9b3b;
    border: 1px solid #9b9b9b3b;
  }
}

.send-button {
  color: #ffffff;
}

.send-file-chat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  padding: 0 5px;
}

.send-file-icons {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  height: 30px;
  width: 30px;
  padding: 5px;
  padding-left: 10px;
  border-radius: 20px;
}

.send-cash {
  display: flex;
  flex-direction: row;
}

.buttons {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-self: center;
  border-radius: 20px;
  font-size: 0.8em;
  margin-top: 0;
  margin-left: 5px;
  padding: 4px 10px;
  color: #ffffff !important;

  &.green {
    background-color: #4fbf39;
  }

  &.red {
    background-color: #d22121;
  }

  &.disabled {
    background-color: #9b9b9b3b;
  }

  &.disabled:hover {
    cursor: not-allowed;
  }
}

.buttons img {
  margin: 0 5px;
}

.no-border-blue {
  outline: none;
}

.color-white {
  filter: brightness(0) saturate(100%) invert(95%) sepia(5%) saturate(0%) hue-rotate(53deg) brightness(105%) contrast(106%) !important;
}

.input-text {
  .count-character {
    right: 4rem;
  }
}

.marginMobile {
  margin-bottom: 30px !important;
}
</style>
