<template>
  <v-menu v-bind="$attrs"
    v-model="showMenu">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" class="transparent-background">
        <v-icon>more_vert</v-icon>
      </v-btn>
      <c-dialog v-model="dialog">
        <v-card-text>
          <h2 class="mb-2">{{ $t('models.complaint.reason_question')}}</h2>
          <v-text-field
            outlined
            v-model="comment"
            :label="$t('models.complaint.comment')"
            :rules="[$vRequired]">
          </v-text-field>
        </v-card-text>
        <template #actions>
          <c-btn
            :disabled="!comment"
            @click="report">
            {{ $t('actions.report') }}
          </c-btn>
        </template>
      </c-dialog>
    </template>
    <v-list dense>
      <v-list-item v-if="copyLink"
        @click.stop="copyLink">
        <v-list-item-content>
          {{ $t('actions.copy_link') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>link</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="isAdmin || isAccountManager || removeItem"
         color="error"
        @click="remove">
        <v-list-item-content>
          {{ $t('actions.delete') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="error">delete_outline</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-else-if="isAccountManager || isContentManager || isController"
         color="error"
        @click="reject">
        <v-list-item-content>
          {{ $t('actions.reject') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="error">block</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="reportItem"
        @click="delayedShowDialog">
        <v-list-item-content>
          {{ $t('actions.report') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="error">flag</v-icon>
        </v-list-item-action>
      </v-list-item>
      <slot></slot>
    </v-list>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex'
import PostApi from '@/api/admin/PostApi'
import ComplaintApi from '@/api/ComplaintApi'
import dialog from '@/components/mixins/dialog'

export default {
  name: 'ReportMenu',
  mixins: [dialog],
  props: {
    id: [String, Number],
    resource: { type: String, default: '' },
    itemLink: String,
    reportItem: Boolean,
    removeItem: Boolean
  },
  data () {
    return {
      showMenu: false,
      comment: ''
    }
  },
  computed: {
    ...mapState('profile', ['isAdmin', 'isAccountManager', 'isContentManager', 'isController'])
  },
  methods: {
    delayedShowDialog() {
      setTimeout(() => this.showDialog(), 10)
    },
    copyLink () {
      const copyText = document.createElement('textarea')
      copyText.value = this.itemLink
      copyText.setAttribute('readonly', '')
      copyText.style.position = 'absolute'
      copyText.style.left = '-9999px'
      document.body.appendChild(copyText)
      copyText.select()
      document.execCommand('copy')
      document.body.removeChild(copyText)
      this.showMenu = false
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.clipboard_copied')
      })
    },

    async reject () {
      await PostApi.update({
        id: this.id,
        status: 'rejected',
        rejection: {
          reason: 'not_allowed',
          detail: this.$t(`posts.approval.rejection.reasons.not_allowed`)
        }
      })
      this.showMenu = false
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.report_success')
      })
    },

    async report () {
      await ComplaintApi.create({ resource: this.resource, resourceId: this.id, comment: this.comment })
      this.showMenu = false
      this.hideDialog()
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.report_success')
      })
    },

    remove () {
      this.showMenu = false
      this.$emit('remove-item')
    }
  }
}
</script>
<style scoped>
.transparent-background {
  background-color: transparent !important;
}
</style>
