<template>
  <GenericWarning
    v-model="warning"
    :goAhead="showContinue"
    @accept="$emit('continue')"
    @close="warning = false"
  >
    <template #title>
      <span v-html="getTitle"></span>
    </template>
    <template #subtitle>
      <span v-html="getSubtitle"></span>
    </template>
    <template #body>
      <span v-html="getBody"></span>
    </template>
    <template #checkbox v-if="!showContinue">
      <v-checkbox :label="$t('modal.not_show_again')"
                  dense
                  hide-details
                  @change="dontShowAgain">
        <span>{{ $t(`modal.not_show_again`)}}</span>
      </v-checkbox>
    </template>
  </GenericWarning>
</template>

<script>
import config from '@/project'
import cookies from '@/cookies'
import GenericWarning from './GenericWarning'

export default {
  name: 'PaymentWarning',
  components: { GenericWarning },
  props: {
    showContinue: {type: Boolean, default: false}
  },
  data () {
    return {
      warning: true,
      creatorMail: config.locales.contact_email_creator,
      financialMail: config.locales.contact_email_financial,
      config
    }
  },
  computed: {
    getTitle () {
      const title = this.$t('withdraws.warningPaypal2023.title')
      return `<h1 class="warning-title">${title}</h1>`
    },
    getSubtitle () {
      const subtitle = this.$t('withdraws.warningPaypal2023.subtitle')
      return `<p class="warning-subtitle">${subtitle}</p>`
    },
    getBody () {
      const desc1 = this.$t('withdraws.warningPaypal2023.desc1')
      const desc2 = this.$t('withdraws.warningPaypal2023.desc2')
      const desc3 = this.$t('withdraws.warningPaypal2023.desc3')
      const list1 = this.$t('withdraws.warningPaypal2023.list1')
      const list2 = this.$t('withdraws.warningPaypal2023.list2')
      const list3 = this.$t('withdraws.warningPaypal2023.list3')
      const list4 = this.$t('withdraws.warningPaypal2023.list4')
      const desc4 = this.$t('withdraws.warningPaypal2023.desc4')
        .replace('[STARS_SUPPORT_MAIL]', `<a href="mailto:${this.creatorMail}">${this.creatorMail}</a>`)
        .replace('[FINANCIAL_SUPPORT_MAIL]', `<a href="mailto:${this.financialMail}">${this.financialMail}</a>`)
      return `
        <br>
        <p class="normal-desc">${desc1}</p>
        <br>
        <p class="normal-desc bold underlined">${desc2}</p>
        <br>
        <p class="normal-desc">${desc3}</p>
        <ul>
            <li class="normal-list underlined">${list1}</li>
            <li class="normal-list underlined">${list2}</li>
            <li class="normal-list">${list3}</li>
            <li class="normal-list">${list4}</li>
        </ul>
        <br>
        <p class="normal-desc">${desc4}</p>
      `
    }
  },
  methods: {
    dontShowAgain () {
      cookies.set('notShowPaypal2023', true)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
