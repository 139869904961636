<template>
  <v-tooltip v-if="tooltip"
             :top="tooltipPosition === 'top'"
             :right="tooltipPosition === 'right'"
             :bottom="tooltipPosition === 'bottom'"
             :left="tooltipPosition === 'left'"
             :value="hover">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on"
             v-bind="$attrs"
             depressed
             :rounded="rounded"
             :outlined="outline"
             :class="css"
             :dark="dark"
             :style="buttonContentStyle"
             style="background-color: #3FB848 !important;  color: white; font-weight: 600 ;"
             class="b-custom"
             :disabled="disabled || isLoading"
             :loading="isLoading"
             @click.stop="doRequest"
             @mouseover="hover = true"
             @mouseleave="hover = false">
        <template v-if="hover">
          <slot name="hover">
            <slot></slot>
          </slot>
        </template>
        <template v-else>
          <slot></slot>
        </template>
        <template #loader>
          <v-progress-circular :color="loadingColor" indeterminate></v-progress-circular>
        </template>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
  <v-btn v-else
         v-bind="$attrs"
         depressed
         :rounded="rounded"
         :outlined="outline"
         :class="css"
         class="b-custom"
         :style="buttonContentStyle"
         style="background-color: #3FB848;  color: white; font-weight: 600 ;"
         :color="currentColor"
         :disabled="disabled || isLoading"
         :loading="isLoading"
         @click.stop="doRequest"
         @mouseover="hover = true"
         @mouseleave="hover = false">
    <template v-if="hover">
      <slot name="hover">
        <slot></slot>
      </slot>
    </template>
    <template v-else>
      <slot></slot>
    </template>
    <template #loader>
      <v-progress-circular :color="loadingColor" indeterminate></v-progress-circular>
    </template>
  </v-btn>
</template>
<script>

export default {
  name: 'cd-btn',
  props: {
    transform: {
      type: String,
      default: 'uppercase'
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    loadingColor: {
      type: String,
      default: 'secondary'
    },
    hoverColor: {
      type: String,
      default: null
    },
    tooltip: {
      type: [String, Boolean],
      default: false
    },
    tooltipPosition: {
      type: String,
      default: 'bottom'
    },
    css: [Object, String],
    outlined: Boolean,
    rounded: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    dark: {
      type: Boolean,
      default: null
    },
    hoverOutlined: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      isRequesting: false,
      hover: false
    }
  },
  computed: {
    isLoading () {
      return this.loading || this.isRequesting
    },
    currentColor () {
      return this.hover && this.hoverColor ? this.hoverColor : this.color
    },
    buttonContentStyle () {
      return { 'text-transform': this.transform }
    },
    innerHoverOutlined () {
      return this.hoverOutlined === null ? this.outlined : this.hoverOutlined
    },
    outline () {
      return (!this.hover && this.outlined) || (this.hover && this.innerHoverOutlined)
    },
    isDark () {
      if (this.dark === null) {
        return null
      }
      return this.dark
    }
  },
  methods: {
    async doRequest (e) {
      this.isRequesting = true
      const clickListener = this.getClickListener()
      if (clickListener) {
        await clickListener(e)
      }
      this.isRequesting = false
    },

    getClickListener () {
      return this.$listeners.click
    }
  }
}
</script>

<style lang="scss" scoped>
.b-custom {
  background-color: #3FB848 !important;
  color: white !important;
  font-weight: 600 !important;
}
</style>
