<template>
  <svg :height="`${size}px`" :width="`${size}px`" id="perfil-mensaje" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <rect  id="Rectángulo_7103" data-name="Rectángulo 7103" width="24" height="24" fill="none"/>
    <path  :fill="color" id="Icon_material-mail-outline" data-name="Icon material-mail-outline" d="M24.6,6H5.4A2.4,2.4,0,0,0,3.012,8.4L3,22.8a2.407,2.407,0,0,0,2.4,2.4H24.6A2.407,2.407,0,0,0,27,22.8V8.4A2.407,2.407,0,0,0,24.6,6Zm0,16.8H5.4v-12l9.6,6,9.6-6ZM15,14.4l-9.6-6H24.6Z" transform="translate(-3 -4)" />
  </svg>
</template>

<script>
export default {
  name: 'Message',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
