<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
    <path
      :fill="fillColor"
      fill-rule="evenodd"
      d="M21.6 2.13a8.656 8.656 0 0 1-2.545.716 4.534 4.534 0 0 0 1.95-2.513 8.728 8.728 0 0 1-2.817 1.101A4.37 4.37 0 0 0 14.954 0c-2.446 0-4.43 2.035-4.43 4.544 0 .356.038.702.114 1.035-3.683-.19-6.947-1.997-9.134-4.75a4.627 4.627 0 0 0-.6 2.287c0 1.576.782 2.967 1.971 3.782a4.35 4.35 0 0 1-2.008-.566v.055c0 2.203 1.528 4.04 3.556 4.456-.371.107-.763.16-1.167.16-.285 0-.564-.027-.834-.08.564 1.804 2.2 3.118 4.139 3.153A8.75 8.75 0 0 1 0 15.96 12.338 12.338 0 0 0 6.793 18c8.152 0 12.609-6.923 12.609-12.928 0-.198-.004-.396-.012-.59a9.095 9.095 0 0 0 2.21-2.351" />
</svg>

</template>
<script>
export default {
  name: 'BlockUserIcon',
  props: {
    fill: String
  },
  computed: {
    fillColor () {
      return this.fill
        ? this.fill === 'primary'
          ? this.$vuetify.theme.themes.light.primary
          : this.fill
        : '#1da1f2'
    }
  }
}

</script>
