<template>
  <v-row v-bind="$attrs">
    <v-col v-if="showTopLoading"
      cols="12" v-intersect="checkTop" height="150">
      <v-row align="center" justify="center" class="my-5">
        <v-progress-circular v-if="loading"
          indeterminate
          class="p-4"
          color="primary">
        </v-progress-circular>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="showHeader">
      <slot name="header"></slot>
    </v-col>
    <v-col cols="12" v-if="isEmpty">
      <slot name="empty">
        <h1 class="title-text px-5" v-if="emptyText">
          {{ emptyText }}
        </h1>
      </slot>
    </v-col>
    <v-col v-for="(item, index) in items"
      :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl"
      :class="itemClass"
      :key="index">
      <slot :item="item" :index="index"></slot>
    </v-col>
    <v-col cols="12">
    </v-col>
    <slot name="footer"></slot>
    <v-col v-if="showBottomLoading"
      cols="12" v-intersect="checkBottom" height="150">
      <v-row align="center" justify="center" class="my-5">
        <v-progress-circular v-if="loading"
          indeterminate
          class="p-4"
          color="primary">
        </v-progress-circular>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'ListView',
  inheritAttrs: false,
  props: {
    cols: [Number, String],
    sm: [Number, String],
    md: [Number, String],
    lg: [Number, String],
    xl: [Number, String],
    items: Array,
    itemClass: [String, Object],
    loading: Boolean,
    emptyText: String,
    itemMinHeight: [String, Number]
  },
  data () {
    return {
      topReached: false,
      bottomReached: false
    }
  },
  computed: {
    showHeader () {
      return !!this.$slots.header
    },
    isEmpty () {
      return !this.loading && (!this.items || !this.items.length)
    },
    showTopLoading () {
      return this.$listeners['top-reached']
    },
    showBottomLoading () {
      return this.$listeners['bottom-reached']
    }
  },
  methods: {
    checkTop (entries) {
      const isVisible = entries[0].isIntersecting
      if (!this.topReached && isVisible) {
        this.$emit('top-reached')
        this.topReached = true
      }
      this.topReached = isVisible
    },
    checkBottom (entries) {
      const isVisible = entries[0].isIntersecting
      if (!this.bottomReached && isVisible) {
        this.$emit('bottom-reached')
      }
      this.bottomReached = isVisible
    }
  }
}
</script>
