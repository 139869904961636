<template>
  <section class="pt-3">
    <div style="text-align: center" v-if="loading">
      <Spinner color="primary" />
    </div>
    <div :class="classGrid">
      <div v-for="(item, index) in items" :key="index">
        <slot :item="item" :index="index"></slot>
      </div>
    </div>

    <div v-if="totalPage > 2" class="row-pag">
      <div class="" v-for="(p, i) in paginationsCursor" :key="'A' + i">
        <div
          class="button-pag"
          :class="classSelected(p)"
          @click="selectedPag(p)"
        >
          {{ p.name }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Spinner from '@/components/chat/component/Spinner'
export default {
  name: 'Paginations',
  components: { Spinner },
  props: {
    items: { type: Array },
    loading: { type: Boolean, default: false },
    perPage: { type: Number },
    totalItems: { type: Number, default: 2 },
    grid: { type: Boolean, default: false }
  },
  data () {
    return {
      pageSelected: 1,
      tempViewPag: []
    }
  },
  computed: {
    classGrid () {
      return {
        'grid-content': this.grid
      }
    },
    totalPage () {
      return 1 + Math.ceil(this.totalItems / this.perPage)
    },
    paginations () {
      const totalPage = this.totalPage
      const aux = []
      let n = 0
      while (n <= totalPage) {
        if (n === 0) {
          aux.push({ id: n, name: '‹' })
        } else if (n === totalPage) {
          aux.push({ id: n, name: '›' })
        } else {
          aux.push({ id: n, name: `${n}` })
        }
        n += 1
      }
      return aux
    },
    paginationsCursor () {
      const paginations = this.paginations
      const totalPage = this.totalPage
      const page = this.pageSelected
      const aux = []
      const endPag = this.paginations.length - 1
      const point = { id: -1, name: '...' }
      if (totalPage < 7) {
        return paginations
      } else {
        if (page < 4) {
          aux.push(paginations[0])
          aux.push(paginations[1])
          aux.push(paginations[2])
          aux.push(paginations[3])
          aux.push(paginations[4])
          aux.push(point)
          aux.push(paginations[endPag - 1])
          aux.push(paginations[endPag])
          return aux
        }
        if (page > totalPage - 4) {
          aux.push(paginations[0])
          aux.push(paginations[1])
          aux.push(point)
          aux.push(paginations[endPag - 4])
          aux.push(paginations[endPag - 3])
          aux.push(paginations[endPag - 2])
          aux.push(paginations[endPag - 1])
          aux.push(paginations[endPag])
          return aux
        }
        if (page > 2 && page < totalPage - 1) {
          aux.push(paginations[0])
          aux.push(paginations[1])
          aux.push(point)
          aux.push(paginations[page - 1])
          aux.push(paginations[page])
          aux.push(paginations[page + 1])
          aux.push(point)
          aux.push(paginations[endPag - 1])
          aux.push(paginations[endPag])
          return aux
        }
      }
    }
  },
  methods: {
    classSelected (pag) {
      const endPag = this.paginations.length - 1
      let selected = false
      if (pag.id !== 0 && pag.id !== endPag) {
        selected = this.pageSelected === pag.id
      }
      return{
        dark:this.$vuetify.theme.dark,
        selected:selected
      }
    },
    selectedPag (pag) {
      const endPag = this.paginations.length - 1
      let auxPage = 1
      if (pag.id === -1) {
        return
      }
      if (pag.id === 0 && this.pageSelected > 2) {
        auxPage = this.pageSelected - 1
      } else if (pag.id === endPag && this.pageSelected < endPag) {
        auxPage = this.pageSelected + 1
      } else {
        auxPage = pag.id
      }
      if (auxPage === 0) {
        auxPage = 1
      }
      if (auxPage === endPag) {
        auxPage = endPag - 1
      }
      this.pageSelected = auxPage
      this.$emit('pageSelected', this.pageSelected)
    }
  }
}
</script>
<style lang="scss" scoped>
.row-pag {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 10px;
}
.button-pag {
  padding: 0 7px;
  cursor: pointer;
  margin-left: 6px;
  color: #333;
  margin-top: 5%;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #000;
    border-color: #dee2e6;
  }
  &.selected {
    color: #fff;
    text-decoration: none;
    background-color: #000;
    border-color: #dee2e6;
    &.dark{
       background-color:gray !important
    }
  }
  &.dark{
    background-color: var(--v-background-lighten1) !important;
  }
}
.grid-content {
  display: flex;
  justify-content: center;
  //justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.disabled {
  cursor: no-drop;
  background: #dee2e6;
  color: black;
}
</style>
