<template>
  <section>
    <v-alert v-if="isRejected"
             text type="warning" color="warning">
      {{ promotion.details }}
    </v-alert>
    <div class="d-flex justify-space-between align-center">
      <h1 class="giant-text giant-text bold my-2">{{ $t("promotion.title") }}</h1>
      <span class="success--text">
        <v-icon color="success">lock_outline</v-icon>
        {{ $t('signup.secure_net') }}
      </span>
    </div>
    <h2 class="title-text-2 roboto mt-2 mb-10">{{ $t("promotion.description") }}</h2>

    <v-form ref="form" v-model="validForm">
      <v-row>
        <v-col cols="12">
          {{ $t("promotion.document_data") }}
        </v-col>
        <v-col cols="12" sm="6">
          <v-select outlined
                    :label="$t('models.influencer_request.id_document_type')"
                    v-model="idDocumentType"
                    :items="documentTypes"
                    :rules="[$vRequired]">
          </v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <CountrySelect outlined
                         :label="$t('models.influencer_request.id_document_country')"
                         v-model="idDocumentCountry"
                         :rules="[$vRequired]" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field outlined
                        :label="$t('models.influencer_request.id_document_number')"
                        v-model="idDocumentNumber"
                        :rules="[$vRequired]"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field outlined
                        :label="$t('models.influencer_request.id_document_expiration')"
                        v-model="idDocumentExpiration"
                        :rules="[$vRequired]"></v-text-field>
        </v-col>
        <v-col cols="12">
          {{ $t("promotion.subscription_price") }}
        </v-col>
        <v-col cols="12" sm="6">
          <CurrencyInput outlined
                         :label="$t('models.user.subscription_price')"
                         v-model="subscriptionPrice"
                         hint="XXX,YY"
                         :rules="[$vRequired]"
                         min-value="500" />
        </v-col>
      </v-row>
    </v-form>

    <v-divider class="my-2"></v-divider>
    <v-row no-gutters>
      <v-col cols="12">
        <div class="d-flex align-center">
          <v-icon large :class="{ 'success--text': isDniUploaded }">
            {{ dniIcon }}
          </v-icon>
          <div class="title-text mx-5">
            1. {{ $t('promotion.dni') }}
          </div>
        </div>
        <p class="mx-10">{{ $t('promotion.dni_description') }}</p>
        <v-alert v-if="invalidIdDocument"
                 type="warning" text icon="warning">
          {{ $t('promotion.dni_invalid') }}
        </v-alert>
      </v-col>

      <v-col cols="12" sm="6">
        <DocumentPreview :src="idDocumentS3Route1 || `/images/validation/documento-frontal.png`"
                         :title="false"
                         @file-added="dni1Uploaded" />
      </v-col>
      <v-col cols="12" sm="6">
        <DocumentPreview :src="idDocumentS3Route2 || `/images/validation/documento-trasero.png`"
                         :title="false"
                         @file-added="dni2Uploaded" />
      </v-col>
    </v-row>

    <v-divider class="my-2"></v-divider>
    <DocumentPreview :src="verificationPhotoS3Route || `/images/validation/comprobante_frontal.jpg`"
                     @file-added="verificationUploaded">
      <span slot="title">2. {{ $t('promotion.verification') }}</span>
      <span slot="description">{{ $t('promotion.verification_description') }}</span>
      <template v-if="invalidVerification" slot="warn-message">
        <v-alert type="warning" text icon="warning">
          {{ $t('promotion.verification_invalid') }}
        </v-alert>
      </template>
    </DocumentPreview>

    <v-divider class="my-2"></v-divider>
    <TermsDialog
      closeable
      v-model="showTerms"
      :loading="finishing"
      @submit="submit"
    />

    <v-row align="center">
      <v-col v-if="skipable"
             cols="auto">
        <c-btn
          outlined
          color="secondary"
          @click.stop="skip">
          {{ $t('actions.finish') }}
        </c-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <c-btn :disabled="!validForm || !filesAdded"
               @click.stop="showAcceptTermsDialog">
          {{ $t('actions.next') }}
        </c-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import validatable from '@/components/mixins/validatable'
import DocumentPreview from '@/components/promote/DocumentPreview'
import TermsDialog from '@/components/terms/TermsDialog'
import isDniNumber from '@/plugins/dni_format_validation.js';
import isPassportNumber from '@/plugins/passport_format_validation.js';
import isDriverNumber from '@/plugins/driver_license_format_validation.js';

export default {
  name: 'UserVerificationView',
  mixins: [validatable('form')],
  components: { DocumentPreview, TermsDialog },
  props: {
    dark: { type: Boolean, default: null },
    skipable: Boolean
  },
  data () {
    return {
      documentTypes: [
        { text: this.$t('models.influencer_request.id_document_types.passport'), value: 'passport' },
        { text: this.$t('models.influencer_request.id_document_types.id_document'), value: 'id_document' },
        { text: this.$t('models.influencer_request.id_document_types.driver_license'), value: 'driver_license' },
      ],
      subscriptionPrice: null,
      dniImage1: {},
      dniImage2: {},
      verificationImage: {},
      idDocumentType: null,
      idDocumentNumber: null,
      idDocumentCountry: null,
      idDocumentExpiration: null,
      idDocumentS3Route1: null,
      idDocumentS3Route2: null,
      verificationPhotoS3Route: null,
      finishing: false,
      showTerms: false
    }
  },

  computed: {
    ...mapState('profile', ['currentUser', 'promotion']),
    isRejected () {
      return this.promotion.status === 'rejected'
    },
    invalidIdDocument () {
      return this.isRejected && !this.promotion.idDocumentValid
    },
    invalidVerification () {
      return this.isRejected && !this.promotion.verificationPhotoValid
    },
    isDniUploaded () {
      return (!this.invalidIdDocument && this.idDocumentS3Route1 && this.idDocumentS3Route2) || (this.dniImage1.uploaded && this.dniImage2.uploaded)
    },
    isVerificationUploaded () {
      return (!this.invalidVerification && this.verificationPhotoS3Route) || this.verificationImage.uploaded
    },
    dniIcon () {
      return this.isDniUploaded ? 'check_circle' : 'check_circle_outline'
    },
    filesAdded () {
      return this.isDniUploaded && this.isVerificationUploaded
    },
    validDocument () {
      return true
      if (this.idDocumentType === 'passport') {
        return !!isPassportNumber(this.idDocumentNumber, this.idDocumentCountry) || this.$t('errors.invalidNif')
      } else if (this.idDocumentType === 'id_document') {
        return !!isDniNumber(this.idDocumentNumber, this.idDocumentCountry) || this.$t('errors.invalidNif')
      } else if (this.idDocumentType === 'driver_license') {
        return !!isDriverNumber(this.idDocumentNumber, this.idDocumentCountry) || this.$t('errors.invalidNif')
      } else {
        return false;
      }
    }
  },

  methods: {
    showAcceptTermsDialog () {
      this.showTerms = true
      this.finishing = false
    },
    idData () {
      return {
        subscriptionPrice: this.subscriptionPrice,
        idDocumentType: this.idDocumentType,
        idDocumentNumber: this.idDocumentNumber,
        idDocumentCountry: this.idDocumentCountry,
        idDocumentExpiration: this.idDocumentExpiration,
        idDocumentS3Route1: this.dniImage1.location,
        idDocumentS3Route2: this.dniImage2.location,
        verificationPhotoS3Route: this.verificationImage.location
      }
    },
    dni1Uploaded (file) {
      this.dniImage1 = file
    },
    dni2Uploaded (file) {
      this.dniImage2 = file
    },
    verificationUploaded (file) {
      this.verificationImage = file
    },
    skip () {
      this.$emit('skip')
    },
    async onSubmit () {
      this.finishing = true
      await this.$store.dispatch(ProfileActions.AcceptStarTerms)
      const { data, error } = await this.$store.dispatch(ProfileActions.UpdatePromotion, this.idData())
      if (!error) {
        this.$emit('submit', data)
      }
      this.finishing = false
      this.showTerms = false
    }
  },
  async mounted () {
    this.subscriptionPrice = this.currentUser.subscriptionPrice
    this.idDocumentType = this.promotion.idDocumentType
    this.idDocumentNumber = this.promotion.idDocumentNumber
    this.idDocumentCountry = this.promotion.idDocumentCountry
    this.idDocumentExpiration = this.promotion.idDocumentExpiration
    this.idDocumentS3Route1 = this.promotion.idDocumentS3Route1
    this.idDocumentS3Route2 = this.promotion.idDocumentS3Route2
    this.verificationPhotoS3Route = this.promotion.verificationPhotoS3Route
  }
}
</script>
