import Vue from 'vue'

const vueInstance = new Vue()

const eventBus = {
    subscribe(event, callback) {
        vueInstance.$on(event, callback)
        return function () {
            vueInstance.$off(event, callback)
        }
    },
    unsubscribe(event, callback) {
        vueInstance.$off(event, callback)
    },

    publish(event, payload) {
        vueInstance.$emit(event, payload)
    }
}

const EventBusPlugin = {
    install(Vue, options) {
        Vue.prototype.$eventBus = eventBus
    }
}

export const EventBus = eventBus
export default EventBusPlugin
