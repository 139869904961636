<template>
  <c-btn small text @click.stop="showDialog" >
    {{ $t('products.update_delivery_info') }}
    <c-dialog v-model="dialog">
      <template #title>
        <span class="title-text-2 semibold">{{ $t(`products.delivery.detail`)}}</span>
      </template>
      <v-form v-model="validForm" ref="form"
        @submit.prevent="submit">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field outlined
                :label="$t('models.user.email')"
                type="text"
                v-model="email"
                :rules="digitalRules"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined
                :label="$t('models.user.name')"
                type="text"
                v-model="name"
                :rules="packageRules"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined
                :label="$t('models.user.country')"
                v-model="country"
                :rules="packageRules"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined
                :label="$t('models.user.province')"
                type="text"
                v-model="province"
                :rules="packageRules"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined
                :label="$t('models.user.city')"
                type="text"
                v-model="city"
                :rules="packageRules"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined
                :label="$t('models.user.postal_code')"
                type="text"
                v-model="postalCode"
                :rules="packageRules"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined
                :label="$t('models.user.address')"
                type="text"
                v-model="address"
                :rules="packageRules"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <template #actions>
        <c-btn @click.stop.prevent="submit"
          :disabled="!validForm">
          {{ $t('actions.save') }}
        </c-btn>
      </template>
    </c-dialog>
  </c-btn>
</template>
<script>
import { mapState } from 'vuex'
import { ProductActions } from '@/store'
import dialog from '@/components/mixins/dialog'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'DeliveryForm',
  mixins: [dialog, validatable('form')],
  props: {
    product: { type: Object, default: () => ({}) },
    purchase: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      email: null,
      name: null,
      country: null,
      postalCode: null,
      province: null,
      city: null,
      address: null
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    isDigital () {
      return this.product.deliveryType === 'digital'
    },
    packageRules () {
      return this.isDigital ? [] : [this.$vRequired]
    },

    digitalRules () {
      return this.isDigital ? [this.$vRequired] : []
    }
  },

  methods: {
    attrs () {
      const attrs = {
        email: this.email,
        name: this.name,
        country: this.country,
        postalCode: this.postalCode,
        province: this.province,
        city: this.city,
        address: this.address
      }

      return attrs
    },
    async onSubmit () {
      const { error } = await this.$store.dispatch(ProductActions.UpdateDeliveryInfo, {
        productId: this.product.id, deliveryInfo: this.attrs()
      })
      if (!error) {
        this.$eventBus.publish('notification', {
          message: this.$t('snackbar.save_success')
        })
      }
      this.hideDialog()
    }
  },
  created () {
    const profile = this.currentUser
    this.email = this.purchase.email || profile.email
    this.name = this.purchase.fullName || profile.fullName
    this.country = this.purchase.country || this.$country(profile.country)
    this.postalCode = this.purchase.postalCode || profile.postalCode
    this.province = this.purchase.province || profile.province
    this.city = this.purchase.city || profile.city
    this.address = this.purchase.address || profile.address
  }
}
</script>
