<template>
  <section>
    <span class="title-text-3 roboto upper light-text">{{ priceText }} </span>
    <Currency v-if="checkBids" class="large-text bold" :class="priceClass"
      :currency="product.currency" :amount="product.price" />
    <div v-if="product.shippingCost"
      class="normal-text small-text">
      {{ $t('products.buy_product.shipping_cost_description', { price: shippingCostPrice })}}
    </div>
    <div v-if="product.bidder" class="greyish-text">
      {{ $t('products.current_bidder_user', { username: product.bidder }) }}
    </div>
    <div v-if="!isWinner && product.currentBidder" class="green--text">
      {{ $t('products.auction.current_bidder') }}
    </div>
    <div v-if="isWinner" class="green--text">
      {{ $t('products.auction_winner_info') }}
    </div>
  </section>
</template>
<script>

export default {
  name: 'AuctionPriceDescription',
  props: {
    product: Object
  },
  computed: {
    isWinner () {
      return this.product.finished && this.product.currentBidder
    },
    priceText () {
      if (this.product.finished  && !this.product.bidsCount) {
        return this.$t('products.auction.no_winner')
      }

      const key = this.product.finished  ? 'winner' : 'current'
      return this.$t(`products.auction.${key}_bid`)
    },
    priceClass () {
      return {
        'blue--text': this.product.currentBidder,
        'error--text': !this.product.currentBidder
      }
    },
    checkBids () {
      return !this.product.finished || (this.product.finished && this.product.bidsCount)
    },
    shippingCostPrice () {
      return this.$currency(this.product.shippingCost)
    }
  }
}
</script>
