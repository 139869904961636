<template>
  <div class="file-uploader" :class="{ 'black-theme': darkTheme }">
    <v-card
      flat
      outlined
      @drop.prevent="droppedFile"
      @dragover.prevent
      @dragenter.prevent="dragEvent"
      @dragleave.prevent="dragEvent"
    >
      <v-card-text class="file-uploader-container" :class="isDragging">
        <v-row
          no-gutters
          justify="center"
          align="center"
          @click.stop="launchInputFile"
        >
          <span>{{ componentLabel }}</span>
        </v-row>
        <v-slide-group
          v-if="files.length >= 1"
          show-arrows
          center-active
          mandatory
          v-model="selectedIndex"
        >
          <v-slide-item
            v-for="(file, index) in files"
            :key="`slide-${index}`"
            class="files-container"
          >
            <v-card flat class="pointer ma-2 pa-1 file-preview rounded">
              <c-img
                v-if="file.isImage()"
                contain
                :width="thumbSize"
                :height="thumbSize"
                :src="file.preview"
              />
              <AudioPlayer
                v-else-if="file.isAudio() && permitTypeAudio"
                :sizeIcon="74"
                :src="file.preview"
              />
              <template v-else-if="file.isVideo()">
                <div v-if="isSafari" class="no-media-content">
                  <span class="d-block rounded bordered pa-2">{{
                    $t("file_uploader.ios_preview_disabled")
                  }}</span>
                </div>
                <video v-else :src="file.preview" @click.stop />
              </template>
              <div v-else class="no-media-content"></div>
              <div class="preview-actions text-center">
                <c-btn
                  icon
                  small
                  color="error"
                  css="mr-1"
                  @click.stop="removeFile(file, index)"
                >
                  <v-icon>delete_outline</v-icon>
                </c-btn>
                <c-btn
                  icon
                  small
                  css="disable-events ml-1"
                  :loading="file.loading"
                  :color="file.uploaded ? 'success' : ''"
                >
                  <v-icon>check</v-icon>
                </c-btn>
              </div>
            </v-card>
          </v-slide-item>
          <div class="plus-container" @click="launchInputFile">
            <div class="plus-btn">
              <span>+</span>
            </div>
          </div>
        </v-slide-group>
      </v-card-text>
    </v-card>
    <p class="small-text">
      {{ maxFilesHint }}
      <span v-if="maxVideos" class="small-text">{{
        $t(`file_uploader.max_video_size`)
      }}</span>
    </p>
    <p v-if="tooManyFiles" class="small-text error--text">
      {{ $t(`errors.discarted_too_many_files`, { max: maxFiles }) }}
    </p>
    <p>
      <template v-for="file in uploadedFiles">
        <span :key="file.key" class="x-small-text success--text mx-2 my-1">{{
          file.key
        }}</span>
      </template>
    </p>
    <p v-if="errorMessage" class="small-text error--text">
      {{ errorMessage }}
    </p>
    <template v-for="file in uploadingFiles">
      <v-row no-gutters align="center" :key="file.key">
        <v-col cols="3" class="x-small-text truncate pr-1">
          {{ file.key }}
        </v-col>
        <v-col cols="9" v-if="file.loading">
          <v-progress-linear
            :value="file.progress"
            color="indigo"
          ></v-progress-linear>
        </v-col>
        <v-col cols="9" v-else-if="file.error" class="error--text small-text">
          {{ $t("file_uploader.error") }}
          <c-btn x-small @click="uploadFile(file)">{{
            $t("file_uploader.retry")
          }}</c-btn>
        </v-col>
      </v-row>
    </template>

    <v-file-input
      class="d-none"
      ref="inputFile"
      type="file"
      :accept="contentType"
      :multiple="maxFiles > 1"
      :rules="rules"
      @change="filesSelected"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import FileManager from "@/components/mixins/file_manager";
import AudioPlayer from "@/components/chat/components/viewFiles/AudioPlayer";

export default {
  name: "FileListUploader",
  mixins: [FileManager],
  components: { AudioPlayer },
  props: {
    value: { type: Array, default: () => [] },
    label: String,
    contentType: { type: String, default: "image/*, video/*, .quicktime" },
    maxVideos: [String, Number],
    maxImages: [String, Number],
    multipleTypes: Boolean,
    autoupload: { type: Boolean, default: false },
    cols: Number,
    rules: { type: Array, default: () => [] },
    thumbSize: { type: [String, Number], default: 74 },
    permitTypeAudio: { type: Boolean, default: false }
  },

  data: () => ({
    tooManyFiles: false,
    errorMessage: "",
    chunkLength: 8,
    dragging: false,
    selectedIndex: null
  }),

  computed: {
    ...mapState("preferences", ["darkTheme"]),
    isSafari() {
      return platform.name === "Safari";
    },
    colSize() {
      const totalFiles = this.files.length;
      return totalFiles < 2 ? 12 : totalFiles < 5 ? 6 : 3;
    },
    isDragging() {
      return {
        dragging: this.dragging
      };
    },
    chunkedFiles() {
      const chunked = [];
      for (let i = 0; i < this.files.length; i += this.chunkLength) {
        const sliced = this.files.slice(i, i + this.chunkLength);
        const mapped = sliced.map((file, index) => ({
          file,
          index: i + index
        }));
        chunked.push(mapped);
      }
      return chunked;
    },
    fileStyle() {
      return {
        height: this.files.length > 2 ? "125px" : "250px",
        "max-height": this.files.length > 2 ? "125px" : "250px"
      };
    },
    autouploadFile() {
      return this.autoupload;
    },
    maxFileNumber() {
      return this.maxFiles;
    },

    isEmpty() {
      return !this.files.length;
    },
    maxVideosInt() {
      return parseInt(this.maxVideos, 10);
    },
    maxImagesInt() {
      return parseInt(this.maxImages, 10);
    },
    isVideo() {
      return !!this.files.find(f => f.isVideo());
    },
    isImage() {
      return !!this.files.find(f => f.isImage());
    },
    uploadedFiles() {
      return this.files.filter(f => f.uploaded);
    },
    maxFiles() {
      return this.isVideo ? this.maxVideosInt : this.maxImagesInt;
    },
    maxFilesHint() {
      let items1, items2;
      let count = 0;
      if (this.maxImages) {
        items1 = this.$tc("file_uploader.max_images", this.maxImagesInt);
        count++;
      }

      if (this.maxVideos) {
        items2 = this.$tc("file_uploader.max_videos", this.maxVideosInt);
        count++;
      }

      return this.$tc("file_uploader.max_items_hint", count, {
        items1,
        items2
      });
    },
    uploadingFiles() {
      return this.files.filter(f => f.loading || f.error);
    },
    componentLabel() {
      return this.label || this.$t("posts.form.upload_file_text");
    },
    itemMdClass() {
      return this.cols ? this.cols : parseInt(this.maxFiles) > 1 ? 6 : 12;
    }
  },

  watch: {
    value(value) {
      this.files = value;
    }
  },

  methods: {
    launchInputFile() {
      this.$refs.inputFile.$refs.input.click();
    },
    dragEvent() {
      this.dragging = !this.dragging;
    },

    startRotating(file) {
      const canvas = document.createElement("CANVAS");
      const img = new Image();
      img.src = file.preview;
      file.loading = true;
      img.onload = () => {
        canvas.width = img.height;
        canvas.height = img.width;
        this.rotate(file, canvas, img);
      };
    },

    rotate(file, canvas, img) {
      const ctx = canvas.getContext("2d");
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(Math.PI / 2);
      ctx.drawImage(img, -img.width * 0.5, -img.height * 0.5);
      canvas.toBlob(blob => {
        URL.revokeObjectURL(file.preview);
        file.preview = URL.createObjectURL(blob);
        file.blob = blob;
        this.uploadFile(file);
      });
    },

    droppedFile(event) {
      if (!event.dataTransfer.files || !event.dataTransfer.files.length) {
        return;
      }
      this.addFiles(event.dataTransfer.files);
    },

    async filesSelected(files) {
      if (!files || !files.length) {
        return;
      }
      await this.addFiles(files);
      this.$refs.inputFile.$refs.input.value = "";
    },

    beforeAddFile(file) {
      this.errorMessage = "";
      if (!this.permitTypeAudio) {
        this.errorMessage = this.$t("errors.mime_type_not_valid", {
          file: file.key
        });
      } else if (!file.isMedia()) {
        this.errorMessage = this.$t("errors.mime_type_not_valid", {
          file: file.key
        });
      }
      if (
        !this.multipleTypes &&
        ((this.isVideo && file.isImage()) ||
          (this.isImage && file.isVideo()) ||
          (this.isAudio && file.isAudio() && this.permitTypeAudio))
      ) {
        this.errorMessage = this.$t("errors.same_mime_type");
      }
      if (file.isLarger(this.maxFileSize)) {
        this.errorMessage = this.$tc("errors.files_size_exceded", 1, {
          files: file.key
        });
      }
      return !this.errorMessage;
    },

    onFileAdded(file) {
      this.$emit("input", this.files);
    },

    onFileUploaded(file) {
      this.$emit("input", this.files);
    },

    onFileRemoved(file) {
      this.tooManyFiles = false;
      this.$emit("input", this.files);
    },
    onTooManyFiles(value) {
      this.tooManyFiles = value;
    }
  }
};
</script>
<style lang="scss" scoped>
.file-uploader {
  cursor: pointer;
  &.black-theme {
    border: 1px groove gray;
  }
  .file-uploader-container {
    f .files-container {
      max-width: 100%;
    }

    .file-preview {
      text-align: center;
      position: relative;
      height: 100%;
      margin: 3px;
      padding: 3px;
      overflow: hidden;
      .file-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      video {
        max-width: 100%;
        max-height: 150px;
        margin: 0 auto;
      }

      .no-media-content {
        max-width: 100%;
        max-width: 150px;
        height: 100%;
      }

      .preview-actions {
        position: absolute;
        bottom: 3px;
        left: 3px;
        right: 3px;
      }
    }
  }
}

.dragging {
  border: black 0.5px solid;
  font-weight: bold;
}
.disable-events {
  pointer-events: none;
}
.plus-container {
  width: 30px;
  text-align: center;
  display: flex;
  vertical-align: middle;
  background-color: #dfe3e9;
  border-radius: 7px;
  margin: 14px 0px;
  .plus-btn {
    margin: auto;
    font-size: 45px;
    width: 30px;
    height: 30px;
    span {
      margin: auto;
    }
  }
}
</style>
