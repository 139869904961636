<template>
  <c-dialog persistent
    width="684"
    :value="value"
    @input="hideDialog">
    <template slot="title">
      <span class="title-text-2 semibold">{{ $t('blocked_users.title')}}</span>
    </template>
    <v-card-text>
      {{ $t('blocked_users.description')}}
    </v-card-text>
    <v-card-text>
    <c-btn x-small
      class="mb-1"
      @click="showDialog">
      {{ $t('blocked_users.block_user') }}
    </c-btn>
    </v-card-text>
    <v-card-text v-if="!blockedUsers.length">
      {{ $t('blocked_users.no_users')}}
    </v-card-text>
    <v-row>
      <v-col v-for="user in blockedUsers"
        :key="user.username"
        cols="12">
        <UserAvatar :user="user">
          <c-btn x-small
            class="mb-1"
            @click="unblockUser(user)">
            {{ $t('actions.unblock') }}
          </c-btn>
        </UserAvatar>
      </v-col>
    </v-row>
    <UserSearchDialog
      v-model="dialog"
      @user-selected="userSelected" />
    <BlockUserWarningDialog
      v-model="confirmationDialog"
      :user="selectedUser" />

  </c-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import dialog from '@/components/mixins/dialog'
import UserAvatar from '@/components/users/UserAvatar'
import UserSearchDialog from '@/components/users/UserSearchDialog'
import BlockUserWarningDialog from '@/components/users/blocking/BlockUserWarningDialog'

export default {
  name: 'BlockedUsersDialog',
  mixins: [dialog],
  components: { UserAvatar, UserSearchDialog, BlockUserWarningDialog },
  props: {
    dark: { type: Boolean, default: null },
    value: { type: Boolean, default: true }
  },
  data () {
    return {
      confirmationDialog: false,
      selectedUser: null
    }
  },
  computed: {
    ...mapState('profile', ['blockedUsers'])
  },
  methods: {
    hideDialog () {
      this.$emit('input', false)
    },
    userSelected (user) {
      this.selectedUser = user
      this.confirmationDialog = true
    },
    unblockUser (user) {
      this.$store.dispatch(ProfileActions.UnblockUser, user)
    }
  }
}
</script>
