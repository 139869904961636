<template>
  <div class="d-flex align-center justify-center">
    <span v-if="dense">{{ $t(`langs.${$i18n.locale}`) }}</span>
    <span v-else>{{ $t(`langs.names.${$i18n.locale}`) }}</span>
    <v-select
      block
      solo
      flat
      hide-details
      v-bind="$attrs"
      :dense="dense"
      class="locale-select"
      :class="classes"
      v-model="$i18n.locale"
      :items="locales"
      append-icon="language"
      @change="updateLocale">
      <template v-slot:prepend>
        <span></span>
      </template>
      <template #item="{ item }">
        <span>{{ $t(`langs.names.${item}`) }}</span>
      </template>
      <template #selection>
        <c-btn icon color="secondary">
          <c-icon icon="Dots"/>
        </c-btn>
      </template>
    </v-select>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import i18n from '@/i18n'

export default {
  name: 'cd-local-select',
  props: {
    dense: Boolean
  },
  data () {
    return {
      locales: ['en', 'es', 'de', 'fr', 'it', 'pt']
    }
  },

  computed: {
    ...mapState('session', ['authenticated']),
    classes () {
      return { dense: this.dense }
    }
  },

  methods: {
    updateLocale (value) {
      this.$i18n.loadLocale(value)
      this.$timeago.locale = value
      this.$vuetify.lang.current = value
      if (this.authenticated) {
        this.$store.dispatch(ProfileActions.UpdateAccountData, { lang: value })
      }
    }
  },
  mounted () {
    this.$vuetify.lang.current = i18n.locale
  }
}
</script>
<style lang="scss">
@use 'src/scss/colors';

.locale-select {
  background-color: transparent;

  .v-input__control, .v-input__slot {
    background-color: transparent !important;
  }

  .v-input__slot {
    padding: 0 !important;
  }

  &.dense {
    width: 20px;
  }
}

.v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
  display: none !important;
}

</style>
