<template>
  <c-btn v-if="themesEnabled"
         text rounded
         transform="none"
         :color="darkTheme ? 'white' : 'black'"
         hover-color="primary"
         :style="`width: ${width}px !important;`"
         v-on:click="changeTheme">
    <v-icon center>compare</v-icon>
  </c-btn>
</template>

<script>
import { mapState } from 'vuex'
import { PreferenceActions } from '@/store'
import config from '@/project'

export default {
  name: 'SwitchThemeButton',
  props: {
    width: {
      type: [Number, String],
      default: 20
    }
  },
  data () {
    return {
      themesEnabled: config.enable_themes
    }
  },
  computed: {
    ...mapState('preferences', ['darkTheme'])
  },
  methods: {
    changeTheme () {
      this.$vuetify.theme.dark = !this.darkTheme
      this.$store.dispatch(PreferenceActions.UpdateDarkTheme, this.$vuetify.theme.dark)
    }
  }
}
</script>
