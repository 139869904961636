<template>
  <c-dialog
    width="684"
    v-model="dialog">
    <template slot="title">
      <span class="title-text-2 semibold">{{ $t(`application.install.title`)}}</span>
    </template>

    <v-card-text>
      <p class="pl-5">
        Quiere instalar Loverfans para que sea más fácil acceder a ella?
      </p>
    </v-card-text>
    <template #actions>
      <c-btn @click="install">{{ $t('actions.install') }}</c-btn>
    </template>
  </c-dialog>
</template>
<script>
import cookies from '@/cookies'
import dialog from '@/components/mixins/dialog'

export default {
  name: 'InstallDialog',
  mixins: [dialog],

  watch: {
    $pwaInstall: function (value) {
      this.prompt()
    }
  },
  methods: {
    install () {
      this.$pwaInstall[0].promtp()
    },
    prompt () {
      const shown = cookies.get('install-app')
      if (!shown && this.$pwaInstall.length) {
        this.showDialog()
        cookies.set('install-app', 'true')
      }
    }
  },
  mounted () {
    this.prompt()
  }
}
</script>
