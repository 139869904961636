<template>
  <section class="video-chat flex-column flex-grow-1">
    <div v-if="calling" class="calling">{{ $t('actions.calling') }}...</div>
    <div v-if="hanging" class="calling">{{ $t('actions.hanging') }}...</div>
    <HeaderVideoChat
      :displayNameUser="displayNameUser"
      :avatar="avatar"
      :price="selectedChat.videochatPrice"
      @close-video-chat="closeVideoChat"
    />
    <section class="video-container" :class="darkTheme">
      <div v-if="!inVideoCall">
        <video
          v-if="camActive"
          controlslist="nodownload"
          id="webcam"
          ref="webcam"
          autoplay="true"
          muted
          playsinline
          class="live-view rounded main"
        ></video>
        <img v-else :src="avatar" alt="" class="main"/>
      </div>

      <div
        v-show="inVideoCall"
        ref="publisherContainer"
        id="publisherContainer"
        class="main"
        :style="`height:${screenHeight - 65}px`"
      ></div>
      <div
        ref="thumbContainer"
        id="thumbContainer"
        class="d-absolute thumb"
      ></div>
    </section>
    <PanelControl
      :micActive="micActive"
      :camActive="camActive"
      :inVideoCall="inVideoCall"
      :soundActive="soundActive"
      :joinVideoCall="joinVideoCall"
      @hidenShowMic="hidenShowMic"
      @hidenShowCam="hidenShowCam"
      @switchAudio="switchAudio"
      @switchCamera="switchCamera"
      @switchMicro="switchMicro"
      @call="startCall"
      @hang="hang"
    />
    <WarningDialog
      :show="processCall"
      :textWarning="getMessageError"
      :textButtonOK="$t('actions.continue').toUpperCase()"
      hide-cancel-button
      @handlerButtonOK="closeWarning"
    />
    <WarningDialog
      :show="camError"
      textWarning=""
      :textButtonOK="$t('actions.continue').toUpperCase()"
      hide-cancel-button
      @handlerButtonOK="reloadCam"
    >
      <div
        v-html="
          $t('chats.video_chat.error.cams1', {
            project: getProyect.name,
            icon: getIconCamera,
            url: getProyect.site
          })
        "
      ></div>
    </WarningDialog>
    <WarningDialog
      :show="videoChatDisable"
      :textWarning="$t('chats.video_chat.error.videochat')"
      :textButtonOK="$t('actions.continue').toUpperCase()"
      hide-cancel-button
      @handlerButtonOK="closeVideoChat"
    />
    <WarningDialog
      :show="showFreeSetting"
      :textWarning="$t('chats.video_chat.error.freeSetting')"
      :textButtonOK="$t('actions.continue')"
      :textButtonCancel="$t('actions.cancel')"
      @handlerButtonOK="showSetting"
      @handlerButtonCancel="showFreeSetting = !showFreeSetting"
    />
    <WarningDialog
      :show="showLoadFundsCreators"
      :textWarning="
        $t('chats.video_chat.error.hasMoneyFollower', { user: displayNameUser })
      "
      :textButtonOK="$t('actions.continue')"
      hide-cancel-button
      @handlerButtonOK="closeLoadFundsCreators"
    />
    <Dialog
      :show="showLoadFunds"
      @hide="() => (showLoadFunds = !showLoadFunds)"
      :title="$t('actions.load_funds').toUpperCase()"
    >
      <template slot="bodyDialog">
        <div>
          <LoadFunds
            :clearInit="showLoadFunds"
            :textLoadFund="$t('chats.video_chat.notification.errorCall_nofund')"
          />
        </div>
      </template>
    </Dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { AppActions, ChatActions, ProfileActions } from '@/store'
import OpenTok from '@opentok/client'
import VideoChatApi from '@/api/conversations/VideoChatApi'
import AvatarUser from '@/components/chat/component/AvatarUser'
import IconButton from '@/components/chat/component/IconButton'
import HeaderVideoChat from '@/components/chat/components/videoChat/HeaderVideoChat'
import PanelControl from '@/components/chat/components/videoChat/PanelControl'
import WarningDialog from '@/components/chat/component/WarningDialog'
import audio from '@/components/chat/assets/audio/ringtone.mp3'
import LoadFunds from '@/components/chat/components/LoadFunds'
import Dialog from '@/components/chat/component/Dialog'
import Config from '@/project'

const getSession = data => OpenTok.initSession(data.apiKey, data.sessionId)
const ringtonCallWaiting = new Audio(audio)

export default {
  name: 'VideoChat',
  components: {
    HeaderVideoChat,
    AvatarUser,
    IconButton,
    PanelControl,
    WarningDialog,
    LoadFunds,
    Dialog
  },
  computed: {
    ...mapState('application', [
      'screenHeight',
      'isMobile',
      'dataCall',
      'joinVideoCall'
    ]),
    ...mapState('chats', ['selectedChat', 'showVideoChat']),
    ...mapState('profile', ['currentUser', 'isFollower']),
    avatar () {
      return this.$route.query.call
        ? this.dataCall.avatar
        : this.selectedChat.receiver.avatar || '/images/no_content.png'
    },
    darkTheme () {
      return {
        dark: this.$vuetify.theme.dark
      }
    },
    getProyect () {
      return {
        name: Config.locales.project,
        site: Config.locales.site
      }
    },
    getIconCamera () {
      return `<img src="/chat/camera.png" style="height: 20px;position: relative; top: 5px; width: 20px;" alt="">`
    },
    displayNameUser () {
      return this.username
    },
    username () {
      return this.$route.query.call
        ? this.$route.query.username
        : this.selectedChat.receiver.username
    },
    getMessageError () {
      let text = this.$t('chats.video_chat.error.error_default', {
        user: this.username
      })
      const error = this.connectionError ? this.connectionError.errorCode : ''
      if (error === 'user_on_videochat') {
        text = this.$t('chats.video_chat.error.buzy', { user: this.username })
      }
      /* if (error === "no-funds") {
        text = this.$t("chats.video_chat.notification.errorCall_nofund");
      } */
      return text
    },
    countSubscribers () {
      let stream = {
        id:
          this.subscriber && this.subscriber.stream
            ? this.subscriber.stream.id
            : null
      }
      return this.session ? this.session.getSubscribersForStream(stream) : []
    }
  },
  watch: {
    countSubscribers () {
      if (this.countSubscribers.length > 0) {
        this.calling = false
        this.profileFetchInterval = setInterval(() => {
          this.$store.dispatch(ProfileActions.Fetch)
        }, 60000)
        ringtonCallWaiting.pause()
      }
    }
  },
  data () {
    return {
      sessionId: null,
      apiKey: null,
      token: null,
      session: null,
      publisher: null,
      subscriber: null,

      processCall: false,
      callStarted: false,
      micActive: true,
      camActive: true,
      soundActive: true,

      camSuccess: false,

      camError: false,
      videoChatDisable: false,

      connectionError: null,

      inVideoCall: false,
      calling: false,
      hanging: false,

      stream: null,
      showLoadFunds: false,
      showLoadFundsCreators: false,

      timeout: null,
      showFreeSetting: false
    }
  },

  methods: {
    closeLoadFundsCreators () {
      this.showLoadFundsCreators = false
      this.closeVideoChat()
    },
    closeVideoChat () {
      this.videoChatDisable = false
      this.stopVideocall()
    },
    closeWarning () {
      this.processCall = false
      this.callStarted = !this.callStarted
      this.closeVideoChat()
    },

    // Access webcam
    async startWebcam () {
      const constraints = {
        video: true,
        audio: true
      }
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        this.handleSuccess(stream)
      } catch (error) {
        this.errorWebCam(error)
      }
    },

    // Success
    handleSuccess (stream) {
      const webcam = document.getElementById('webcam')
      webcam.srcObject = stream
    },

    // error Permission denied
    errorWebCam (error) {
      console.error(error)
      this.camError = true
    },

    reloadCam () {
      this.camError = false
      this.closeWebcam()
      this.hang()
    },
    async startCall () {
      this.closeWebcam()
      if (!this.username) return

      if (!this.session) {
        const {
          data,
          error
        } = await VideoChatApi.call(this.username)
        if (!error) {
          this.calling = true
          this.inVideoCall = true
          this.apiKey = data.apiKey
          this.sessionId = data.sessionId
          this.token = data.token

          this.session = await getSession(data)
          this.startPublisher()
          this.startSubscriber()

          if (!this.joinVideoCall) {
            ringtonCallWaiting.play()
          }
          this.timeout = setTimeout(() => {
            if (this.countSubscribers.length === 0) {
              const type = 'rejected-call'
              this.$emit('rejected-time-call', type, this.username)
              this.hang()
            }
          }, 30000)

          const dataSave = {
            videochatId: data.id
          }
          this.$store.dispatch(AppActions.SetDataCall, dataSave)
        } else {
          this.connectionError = data
          const error = data.errorCode
          console.log(data.errorCode)
          if (error === 'not_enough_funds') {
            if (this.isFollower) {
              this.showLoadFunds = true
            } else {
              this.showLoadFundsCreators = true
            }
          } else if (error === 'videochat_disabled') {
            this.videoChatDisable = true
          }
        }
      }
    },
    startPublisher () {
      const pubOptions = {
        insertMode: 'append',
        width: '150px',
        height: '150px',
        resolution: '1280x720',
        fitMode: 'contain',
        publishAudio: this.micActive,
        publishVideo: this.camActive,
        showControl: false,
        style: {
          buttonDisplayMode: 'off',
          backgroundImageURI: this.currentUser.avatarS3Route
        }
      }
      const thumbContainer = document.getElementById('thumbContainer')
      this.registerListeners()
      this.publisher = OpenTok.initPublisher(
        thumbContainer,
        pubOptions,
        error => {
          if (!error) {
            this.connect()
          } else {
            if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
              // Access denied can also be handled by the accessDenied event
              console.log(
                'Please allow access to the Camera and Microphone and try publishing again.'
              )
            }
          }
        }
      )
      /* this.publisher.on("videoElementCreated", event => {
        this.activeVideo = event.element;
      }); */
    },
    async startSubscriber () {
      this.connected = true
      const subOptions = {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        resolution: '1024×768',
        fitMode: 'contain',
        subscribeToAudio: this.micActive,
        subscribeToVideo: this.camActive,
        style: {
          buttonDisplayMode: 'off',
          backgroundImageURI: this.selectedChat.receiver.avatar
        }
      }
      const publisherContainer = document.getElementById('publisherContainer')
      this.session.on('streamCreated', event => {
        this.subscriber = this.session.subscribe(
          event.stream,
          publisherContainer,
          subOptions,
          this.handleError
        )
      })
      this.connect()
    },
    connect () {
      this.session.connect(this.token, error => {
        this.connectionError = error
        this.connected = !error
        if (!error) {
          if (this.publisher) {
            this.session.publish(this.publisher)
          }
        } else {
          this.closeSession()
        }
      })
    },
    registerListeners () {
      // reason  "clientDisconnected" , "forceDisconnected" , "forceUnpublished" o "networkDisconnected".
      if (!this.session) {
        return
      }
      this.session.on('streamDestroyed', event => {
        //event.preventDefault();
        if (!this.isPublisher) {
          this.stopVideocall()
        }

        if (event.reason === 'networkDisconnected') {
          console.log('Your network connection terminated.')
        }
      })
    },
    handleError (error) {
      if (error) {
        console.error(error.message)
      }
    },
    async hang () {
      this.stopVideocall()
      await VideoChatApi.rejectCall(this.username)
    },

    closeStreams () {
      this.closeWebcam()
      this.closeSession()
    },

    closeWebcam () {
      const webcam = document.getElementById('webcam')
      if (webcam && webcam.srcObject) {
        const stream = webcam.srcObject
        const tracks = webcam.srcObject.getTracks()
        for (let i = 0; i < tracks.length; i++) {
          tracks[i].stop()
          stream.removeTrack(tracks[i])
        }
        webcam.srcObject = null
        webcam.remove()
      }
    },

    closeSession () {
      if (this.session) {
        if (this.publisher) {
          this.session.unpublish(this.publisher)
          this.publisher.off()
          this.publisher.destroy()
        }
        if (this.subscriber) {
          this.session.unsubscribe(this.subscriber)
        }
        this.session.off()
        this.session.disconnect()
      }
      this.publisher = null
      this.subscriber = null
      this.session = null
    },

    async stopVideocall () {
      this.closeStreams()
      this.camActive = false
      this.activeVideo = null
      this.processCall = false
      this.hanging = false
      this.calling = false
      this.inVideoCall = false

      this.$store.dispatch(AppActions.SetJoinVideoCall, false)
      clearInterval(this.profileFetchInterval)
      clearInterval(this.timeout)
      ringtonCallWaiting.pause()
      this.$store.dispatch(ChatActions.ShowVideoChat, false)
    },

    hidenShowMic (deviceId) {
      this.micActive = !this.micActive
      if (this.publisher) {
        this.publisher.publishAudio(this.micActive)
      } else if (this.subscriber) {
        this.subscriber.subscribeToAudio(this.micActive)
      }
      if (this.micActive) {
        this.switchAudio(deviceId)
      }
    },

    hidenShowCam (deviceId) {
      this.camActive = !this.camActive
      if (this.publisher) {
        this.publisher.publishVideo(this.camActive)
      } else if (this.subscriber) {
        this.subscriber.subscribeToVideo(this.camActive)
      }
      const webcam = document.getElementById('webcam')
      if (!this.inVideoCall) {
        if (!this.camActive) {
          webcam.srcObject.getTracks().map(track => track.stop())
          webcam.srcObject = null
        } else {
          setTimeout(() => {
            this.startWebcam()
          }, 10)
        }
      } else {
        this.switchCamera(deviceId)
      }
    },
    switchAudio () {
      this.soundActive = !this.soundActive
    },
    async switchCamera (deviceId) {
      this.videoDeviceId = deviceId
      if (this.publisher) {
        this.publisher
          .setVideoSource(deviceId)
          .then()
          .catch(error => console.error(error.name))
      } else {
        const webcam = document.getElementById('webcam')
        const constraints = { deviceId: { exact: deviceId } }
        await navigator.mediaDevices
          .getUserMedia({ video: constraints })
          .then(stream => (webcam.srcObject = stream))
          .catch(e => console.error(e))
      }
    },
    async switchMicro (deviceId) {
      this.videoDeviceId = deviceId
      if (this.publisher) {
        this.publisher
          .setAudioSource(deviceId)
          .then()
          .catch(error => console.error(error.name))
      } else {
        const webcam = document.getElementById('webcam')
        const constraints = { deviceId: { exact: deviceId } }
        await navigator.mediaDevices
          .getUserMedia({ audio: constraints })
          .then(stream => (webcam.srcObject = stream))
          .catch(e => console.error(e))
      }
    },
    freeSetting () {
      if (this.camError) return
      if (this.selectedChat.receiver.role === 'follower') {
        this.showFreeSetting =
          this.selectedChat.sender.videochatPrice === 0 && !this.isFollower
      }
    },
    showSetting () {
      this.reloadCam()
      this.$store.dispatch(ChatActions.ShowSetting, true)
      this.$store.dispatch(ChatActions.ShowCarousel, false)
    }
  },
  async mounted () {
    await this.startWebcam()
    this.freeSetting()
    this.wsVideoChatReject = this.stopVideocall.bind(this)
    this.$ws.subscribe('videochat-rejected', this.wsVideoChatReject)
  },

  destroyed () {
    // clean your initial query object
    const query = { param: [] }

    // Now replace it
    this.$router.replace({
      query: query
    })
    this.closeStreams()
    this.$ws.unsubscribe('videochat-rejected', this.wsVideoChatReject)
  }
}
</script>

<style lang="scss" scoped>
.header-video-call {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  flex-direction: row;
  align-content: space-around;
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
}

.price-close-div {
  display: flex;

  .price {
    color: white;
    font-weight: bold;
    padding-right: 10px;
  }
}

.main {
  /* display: inline-block ; */
  width: 100%;
  height: 100%;
}

.thumb {
  position: absolute;
  right: 20px;
  bottom: 80px;
}

.image-video-call {
  position: relative;
  /*  height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.video-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  background-color: #9c9c9c;
  /* background-color: rgb(255, 255, 255); */
  &.dark {
    background-color: rgba(113, 113, 113, 0.675);
  }
}

.video-chat {
  z-index: 12;
}

.calling {
  z-index: 12;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  opacity: 0.8;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  color: white;
}
</style>
