<template>
  <v-card-text>
    <p>{{ $t('payments.wallets.success_message_1') }}</p>
    <p>{{ $t('payments.wallets.success_message_2', { amount: $currency(currentWallet.amount, currentWallet.currency) }) }}</p>
    <c-btn @click="close">
      {{ $t('actions.go_to_item', { item: $t('models.wallets.singular_name') }) }}
    </c-btn>
  </v-card-text>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { ProfileActions } from '@/store'
import config from '@/project'

export default {
  name: 'WalletInfo',
  computed: {
    ...mapGetters('profile', ['currentWallet']),
    ...mapState('profile', ['currentUser'])
  },
  methods: {
    close () {
      if (config.payments.darkfans_redirect) {
        window.location = `https://darkfans.com/wallet?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
        return
      }
      this.$emit('close')
      this.$router.replace({ name: 'wallet' })
    }
  },
  beforeMount () {
    this.$store.dispatch(ProfileActions.Fetch)
  }
}
</script>
