<template>
  <section>
    <h1 class="montserrat giant-text bold my-2">{{ $t("signup.promotion_check.title") }}</h1>
    <h2 class="title-text-2 roboto my-2">{{ $t("signup.promotion_check.description") }}</h2>
    <h2 class="title-text-2 roboto my-2">{{ $t("signup.promotion_check.description_1") }}</h2>
    <!-- <h2 class="title-text-2 roboto my-2">{{ $t("signup.promotion_check.description_2") }}</h2> -->
    <h2 class="title-text-2 roboto my-2">{{ $t("signup.promotion_check.description_3") }}</h2>

    <div class="text-center mt-5">
      <c-btn @click="submit">{{ $t('signup.promotion_check.next') }}</c-btn>
    </div>
    <div class="text-center mt-5">
      <c-btn text color="secondary" @click="skip">{{ $t('signup.promotion_check.skip') }}</c-btn>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PromotionCheck',
  methods: {
    submit () {
      this.$emit('submit')
    },
    skip () {
      this.$emit('skip')
    }
  }
}
</script>
