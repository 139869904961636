<template>
  <div class="">
    <div class="header-chat" :class="classCss">
      <div class="header">
        <div class="profile" @click="showSetting()">
          <AvatarUser
            :avatar="selectedChat.receiver.avatar"
            :height="34"
            :width="34"
            :username="selectedChat.receiver.displayName"
            :trustedProfile="selectedChat.receiver.trustedProfile"
            :onlineStatus="selectedChat.receiver.onlineStatus"
            style="border-radius: 20px"
          >
          </AvatarUser>
        </div>
        <div class="spacer" @click="userData()"></div>
        <div class="options">
          <icon-button
            type="user"
            class="ico-margin"
            @handler="userData"
          />
          <icon-button
            type="settings"
            class="ico-margin"
            @handler="showSetting"
          />

          <icon-button
            type="search"
            class="ico-margin"
            :isDark="darkTheme"
            @handler="showInputSearch"
          />

          <icon-button type="close" class="ico-margin" @handler="emitBack"/>
        </div>
      </div>
    </div>
    <div v-if="inputSearch" class="input-result-search">
      <input
        type="text"
        :placeholder="$t('actions.search')"
        class="input-text"
        :class="classCss"
        @input="onSearch"
        v-model="textSearch"
        ref="inputText"
      />
      <!-- <Button
           v-if="totalMatchSearch === 0"
          color="#6c757d"
          @handler="onSearch"
          :text="$t('actions.search')"
        ></Button>  -->
      <icon-button
        type="arrow"
        :disabled="disabledPrevious"
        :class="disabledPreviousClass"
        @handler="nextResult"
      />
      <icon-button
        type="arrow"
        class="revert selected-icon"
        :disabled="disabledNext"
        :class="disabledNextClass"
        @handler="previousResult"
      />
      <span>{{ indexMatchSearch }}/{{ totalMatchSearch }}</span>
    </div>
    <Dialog
      :show="showUserSetting"
      @hide="() => (showUserSetting = !showUserSetting)"
    >
      <template slot="bodyDialog">
        <div>
          <UserSetting
            v-on:close="() => (showUserSetting = !showUserSetting)"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { ChatActions } from '@/store'
import dialog from '@/components/mixins/dialog'
import validatable from '@/components/mixins/validatable'
import AvatarUser from '@/components/chat/component/AvatarUser'
import IconButton from '@/components/chat/component/IconButton'
import UserSetting from './UserSetting'
import Dialog from '@/components/chat/component/Dialog'
import Button from '@/components/chat/component/Buttons.vue'

const chatStatus = ['approved', 'premium', 'blocked']

export default {
  name: 'ChatHeader',
  mixins: [dialog, validatable('form')],
  components: {
    AvatarUser,
    IconButton,
    UserSetting,
    Dialog,
    Button
  },
  props: {
    totalMatchSearch: {
      type: Number,
      default: 0
    },
    indexMatchSearch: {
      type: Number,
      default: 0
    },
    hiddenInputSearch: {
      type: Boolean,
      defualt: true
    }
  },
  data () {
    return {
      receiver: {},
      chatOption: 'approved',
      messagePrice: 0,
      showUserSetting: false,
      inputSearch: false,
      textSearch: '',
      timeOut: null,
    }
  },
  async mounted () {
    this.setupChatOption()
  },
  computed: {
    ...mapGetters('profile', ['currentWallet']),
    ...mapState('application', ['isMediumScreenOrGreater']),
    ...mapState('profile', ['currentUser', 'isAdmin', 'isInfluencer', 'isAccountManager']),
    ...mapState('chats', ['selectedChat']),
    isCurrentUser () {
      return this.selectedChat.receiver.username === this.currentUser.username
    },
    darkTheme () {
      return this.$vuetify.theme.dark
    },
    classCss () {
      return { dark: this.darkTheme }
    },
    isReceiverInfluencer () {
      return (
        !this.isCurrentUser && this.selectedChat.receiver.role === 'influencer'
      )
    },
    canBePremium () {
      const { role } = this.selectedChat.receiver
      return this.isInfluencer && !this.isReceiverInfluencer && role !== 'admin'
    },
    ownUser () {
      return (
        this.users.find(u => u.username === this.currentUser.username) || {}
      )
    },
    messageCost () {
      const {
        messagePrice,
        currency
      } = this.selectedChat.receiver
      const vat = (100 + this.currentUser.billing.vat) / 100
      return this.$currency(Math.ceil(messagePrice * vat, currency))
    },
    statusHintText () {
      const {
        messagePrice,
        currency
      } = this.selectedChat.sender
      const price = this.$currency(messagePrice, currency)
      return this.$i18n.t(`messages.status.${this.chatOption}_hint`, { price })
    },
    isPremium () {
      return this.chatOption === 'premium'
    },
    statusSelectOptions () {
      return chatStatus
        .filter(s => s !== 'premium' || this.canBePremium)
        .map(status => ({
          text: this.$i18n.t(`messages.status.${status}`),
          value: status
        }))
    },
    /* hasFunds () {
      const currVat = this.currentUser.billing.vat || 0
      const vat = (100 + currVat) / 100
      const price = Math.ceil((this.receiver.messagePrice || 0) * vat)
      return (
        this.isCurrentUser || this.isAdmin || this.isAccountManager || this.currentWallet.amount >= price
      )
    }, */
    /* relationText () {
      if (this.receiver.subscribedBy) {
        return this.$t('models.user.subscribed_by')
      } else if (this.receiver.followedBy) {
        return this.$t('models.user.followed_by')
      }
      return ''
    }, */
    disabledPrevious () {
      return (
        this.totalMatchSearch === 0 ||
        this.indexMatchSearch === 0 ||
        this.indexMatchSearch === 1
      )
    },
    disabledNext () {
      return (
        this.totalMatchSearch === 0 ||
        this.indexMatchSearch === this.totalMatchSearch
      )
    },
    disabledPreviousClass () {
      return {
        disabled: this.disabledPrevious
      }
    },
    disabledNextClass () {
      return {
        disabled: this.disabledNext
      }
    }
  },
  watch: {
    hiddenInputSearch () {
      this.inputSearch = false
    },
    inputSearch () {
      this.textSearch = ''
      this.$emit('clear-search')
      if (this.inputSearch) {
        this.$emit('want-to-search')
      }
    }
  },
  methods: {
    emitBack () {
      this.$store.dispatch(ChatActions.SelectChat, null)
      this.$store.dispatch(ChatActions.ShowSetting, false)
      this.$store.dispatch(ChatActions.ShowCarousel, false)
    },
    showSetting () {
      this.$store.dispatch(ChatActions.ShowSetting, true)
      this.$store.dispatch(ChatActions.ShowCarousel, false)
    },
    showChangeMessagePrice () {
      if (this.chatOption === 'premium') {
        this.showDialog()
      } else if (this.chatOption === 'blocked') {
        this.blockChat()
      } else {
        this.approveChat()
      }
    },
    setupChatOption () {
      const current = this.selectedChat.sender
      if (!current.approved) {
        this.chatOption = 'blocked'
      } else if (current.messagePrice) {
        this.chatOption = 'premium'
        this.messagePrice = current.messagePrice
      } else {
        this.chatOption = 'approved'
      }
    },
    approveChat () {
      this.$store.dispatch(ChatActions.UpdateChat, {
        approved: true,
        notified: true,
        messagePrice: 0
      })
      this.$store.dispatch(ChatActions.UpdateAttributes, {
        sender: {
          approved: true,
          notified: true
        }
      })
    },
    blockChat () {
      this.$store.dispatch(ChatActions.UpdateChat, {
        approved: false,
        notified: true,
        messagePrice: 0
      })
      this.$store.dispatch(ChatActions.UpdateAttributes, {
        sender: {
          approved: false,
          notified: true
        }
      })
    },
    savePremium () {
      this.$store.dispatch(ChatActions.UpdateChat, {
        approved: true,
        notified: true,
        messagePrice: this.messagePrice
      })
      this.$store.dispatch(ChatActions.UpdateAttributes, {
        sender: {
          approved: true,
          notified: true,
          messagePrice: this.messagePrice
        }
      })
      this.hideDialog()
    },
    userData () {
      if (this.selectedChat.receiver.role === 'follower') {
        this.showUserSetting = !this.showUserSetting
      } else {
        this.$router.push({
          name: 'influencer',
          params: { username: this.selectedChat.receiver.username }
        })
      }
    },
    showInputSearch () {
      this.inputSearch = !this.inputSearch
      if (this.inputSearch) {
        setTimeout(() => {
          this.$refs.inputText.focus()
        }, 100)
      }
    },
    onSearch () {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.$emit('search-text', this.textSearch)
      }, 1000)
    },
    nextResult () {
      this.$emit('next-result')
    },
    previousResult () {
      this.$emit('previous-result')
    }
  }
}
</script>
<style lang="scss" scoped>
.header-chat {
  background-color: #ffffff;
  padding: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: 4rem;

  &.dark {
    background-color: #1f2c34 !important;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.options {
  align-items: center;
  display: flex;
}

.ico-margin {
  margin: 3px;
  margin-right: 0;
}

.input-result-search {
  display: flex;
  align-items: center;
  margin: 4px 10px;
  width: 95%;

  .revert {
    transform: rotate(180deg);
  }

  .input-text {
    width: -webkit-fill-available;
    display: inline-block;
    width: 100%;
    border: 1px solid #d7d7d7;
    height: 30px;
    padding: 7px 15px;
    font-size: 0.9375em;
    border-radius: 30px;
    background: white;

    &.dark {
      background-color: #1f2c34 !important;
    }
  }

  .text-result {
    margin: 0 4px;
    opacity: 0.5;
  }

  .disabled {
    opacity: 0.3;
  }
}
</style>
