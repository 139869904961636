<template>
  <router-link v-if="!disabled"
    v-bind="$attrs">
    <slot/>
  </router-link>

  <span v-else
    v-bind="$attrs"
    @click.stop="$emit('optional-clicked')">
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'OptionalLink',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
