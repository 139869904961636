<template>
  <section>
    <template v-if="showInfo">
      <slot />
      <div v-if="errorMessage"
        class="error--text error-message">
        {{ errorMessage }}
      </div>
<!--      <PaymentBuyerName
        v-if="allowWallet || isCreditCardEnabled"
        v-model="buyerName" />-->
      <PaymentSource v-if="allowWallet"
        v-model="paymentOption"
        :error.sync="paymentOptionError"
        :price="price" />
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-checkbox outlined
                      color="primary"
                      v-model="confirmPayment"
                      @click.prevent="startPayment"
                      :rules="[$vBoolRequired]">

          </v-checkbox><span v-html="$t(`models.payment.accept.${resource === 'subscription' ? 'subscription' : 'unique'}`, {contract: getContractCondition, support: getSupportMail, period: getPeriodSelected, amount: getAmount })"></span>
          <!-- <span v-html="$t(`models.payment.accept.${resource === 'subscription' ? 'subscription' : 'unique'}`, {contract: getContractCondition, support: getSupportMail, period: getPeriodSelected, amount: getAmount })"></span>-->
        </v-col>
      </v-row>
      <PaymentActions
        id="paymentNext"
        :disable-next="disableNext"
        @close="$listeners.close"
        @next="startPayment" />
    </template>

    <PaymentProgress v-else-if="isPaying" />

    <template v-else-if="isTruspay">
      <TruspayForm class="form-container" :url="paymentUrl" />
    </template>

    <template v-else-if="showCreditCardPayment">
      <IframedForm class="form-container" :url="paymentUrl" />
    </template>
    <slot v-else-if="walletPaymentSuccess"
      name="wallet-payment-success"
      @close="$listeners.close"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import config from '@/project'
import payments from '@/components/mixins/payments'
import PaymentSource from '@/components/payments/forms/partials/PaymentSource'
import PaymentActions from '@/components/payments/forms/partials/PaymentActions'
import PaymentProgress from '@/components/payments/forms/partials/PaymentProgress'
import IframedForm from '@/components/payments/forms/partials/IframedForm'
import TruspayForm from '@/components/payments/forms/partials/TruspayForm'

export default {
  name: 'PaymentForm',
  mixins: [payments],
  components: { TruspayForm, IframedForm, PaymentSource, PaymentActions, PaymentProgress },
  props: {
    disableNextButton: Boolean,
    allowWallet: Boolean,
    provider: String,
    paymentFormUrl: String,
    price: Number,
    vatPostPrice: { type: Number, default: 0 },
    resource: String,
    resourceId: [String, Number],
    subscription: { type: Object, default: () => ({}) },
    walletPaymentSuccess: Boolean,
    error: Boolean,
    errorMessage: String
  },
  data () {
    return {
      showInfo: true,
      buyerName: '',
      paymentOption: 'card',
      paymentOptionError: false,
      visibleIframe: true,
      project: config,
      confirmPayment: false,
      config
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    vatPrice () {
      if (!this.subscription) {
        const price = this.price
        const vat = this.currentUser.billing.vat
        const expenses = (price * this.currentUser.billing.exes) / 100
        const vatAmount = Math.ceil((price + expenses) * vat / 100)

        return Math.ceil(vatAmount + expenses)
      }
      const offer = this.subscription
      const vat = (this.currentUser.billing.vat || 22) / 100
      const exes = this.currentUser.billing.exes / 100
      const price = offer.basePrice - ((offer.basePrice * offer.discount) / 100)
      const totalAmount = price * offer.months
      const expenses = totalAmount * exes
      const vatAmount = (totalAmount + expenses) * vat
      return expenses + vatAmount
    },
    isSpecialOfferActive () {
      const today = new Date()
      return config.specialOffer.active && config.specialOffer.startDate < today && config.specialOffer.endDate > today && config.specialOffer.discount > 0
    },
    getAmount () {
      if (this.resource === 'subscriptions') {
        return `${this.$currency((this.specialOffer(this.subscription.basePrice) - ((this.specialOffer(this.subscription.basePrice) * this.subscription.discount) / 100)) * this.subscription.months)} + ${this.$currency(this.vatPrice * (this.isSpecialOfferActive ? (100 - config.specialOffer.discount) / 100 : 1))}`
      } else if (this.resource === 'wallets') {
        return `${this.$currency(this.price)}`
      } else {
        return `${this.$currency(this.price)} + ${this.$currency(this.vatPostPrice)}`
      }
    },
    getPeriodSelected () {
      return this.resource === 'subscriptions' ? this.$t(`models.payment.${this.subscription.type}`) : this.$t('models.payment.unique')
    },
    getSupportMail () {
      return `soporte@${this.project.project}.com`
    },
    getContractCondition () {
      return '/contract-conditions'
    },
    showPaymentFrom () {
      return this.error || this.showInfo
    },
    isTruspay () {
      return this.provider === 'trustpay'
    },
    disableNext () {
      return this.error || this.disableNextButton || !this.buyerName || this.paymentOptionError || !this.confirmPayment
    },
    showCreditCardPayment () {
      return !this.error && !this.showInfo && this.paymentFormUrl
    },
    isPaying () {
      return !this.error && !this.showCreditCardPayment && !this.walletPaymentSuccess
    },
    paymentUrl () {
      return this.paymentFormUrl
    }
  },
  watch: {
    paymentOption (value) {
      this.$emit('payment-selected', value)
    },
    error (value) {
      if (value) this.showInfo = true
      this.error = false
    }
  },
  methods: {
    async startPayment () {
      this.$emit('payment', { name: this.buyerName, paymentType: this.paymentOption })
      this.showInfo = false
    },
    specialOffer (amount) {
      if (config.specialOffer.active && config.specialOffer.startDate < new Date() && config.specialOffer.endDate > new Date() && config.specialOffer.discount > 0) {
        return amount * (100 - config.specialOffer.discount) / 100
      }
      return amount
    },

    orderReceived (message) {
      const origin = window.location.origin
      const successUrl = `${origin}${this.$route.path}?payment=success&resource=${this.resource}&resourceId=${this.resourceId}`
      const failedUrl = `${origin}${this.$route.path}?payment=failed&resource=${this.resource}`
      let redirectTo = successUrl
      if (message.provider === 'inetcash') {
        redirectTo = message.status === 'commited' ? successUrl : failedUrl
        if (this.resource === 'subscription') {
          message.status === 'commited' ? this.$gtm('subscription_complete') : this.$gtm('subscription_error')
        } else if (this.resource === 'wallets') {
          message.status === 'commited' ? this.$gtm('reload_wallet_complete') : this.$gtm('reload_wallet_error')
        } else {
          if (this.paymentOption === 'card') {
            message.status === 'commited' ? this.$gtm('premium_content_card_complete') : this.$gtm('premium_content_card_error')
          } else {
            message.status === 'commited' ? this.$gtm('premium_content_wallet_complete') : this.$gtm('premium_content_card_error')
          }
        }
      }
      window.location = redirectTo
    }
  },
  mounted () {
    this.showInfo = true
    this.visibleIframe = true
    this.paymentOption = 'card'
    const name = this.currentUser.name || ''
    const surname = this.currentUser.surname || ''
    this.buyerName = `${name} ${surname}`.trim() || this.currentUser.username

    this.wsOrderReceived = this.orderReceived.bind(this)
    this.$ws.subscribe('order-received', this.wsOrderReceived)
  },
  destroyed () {
    this.$ws.unsubscribe('order-received', this.wsOrderReceived)
  }
}
</script>
<style lang="scss">
.form-container {
  border: none;
  height: 750px;
  width: 100%;
}

.error-message {
  margin: 15px 0;
}
</style>
