const loadCookies = () => {
  const map = {}
  var cookies = document.cookie.split('; ') || []
  cookies.forEach((cookie) => {
    if (!cookie.includes('invalid')) {
      const splitted = cookie.split('=')
      if (splitted.length > 1) {
        const [key, value] = splitted
        map[key] = value.split(';')[0]
      }
    }
  })
  return map
}

const persistCookie = (name, value) => {
  document.cookie = `${name}=${value}`
}

function setLocalStorageWithExpiration(key, value, expirationMinutes = 60) {
  const expirationMilliseconds = expirationMinutes * 60 * 1000;
  const expirationTime = new Date().getTime() + expirationMilliseconds;
  const data = {
    value: value,
    expiration: expirationTime,
  };
  localStorage.setItem(key, JSON.stringify(data));
}

function getLocalStorageWithExpiration(key) {
  const storedData = localStorage.getItem(key);
  if (storedData) {
    const data = JSON.parse(storedData);
    const currentTime = new Date().getTime();
    if (currentTime < data.expiration) {
      return data.value;
    } else {
      localStorage.removeItem(key);
    }
  }
  return null;
}


const cookies = {
  cookies: loadCookies(),

  set(name, value, props = {}) {
    this.cookies[name] = value
    const time = props.time || 214748347
    persistCookie(name, `${value}; Path=/; max-age=${time};`)
  },

  get(name) {
    const cookies = loadCookies()
    return cookies[name]
  },

  getBoolean(name) {
    const value = this.get(name)
    return value === true || value === 'true'
  },

  getInt(name) {
    return parseInt(this.get(name, 10))
  },

  delete(name) {
    this.cookies[name] = undefined
    persistCookie(name, 'invalid; Path=/; Max-Age=-99999999;')
  },
  expiration(name, value, hours = 24) {
    const dateExpirations = new Date();
    dateExpirations.setTime(dateExpirations.getTime() + (hours * 60 * 60 * 1000));
    persistCookie(name, `${value}; Path=/; expires=${dateExpirations.toString()};`)
  },
  setLocalStorage(name, value, min = 60) {
    setLocalStorageWithExpiration(name, value, min)
  },
  getLocalStorage(name) {
    return getLocalStorageWithExpiration(name)
  }
}

export default cookies
