<template>
  <v-menu
    ref="menu"
    v-model="showPicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        outlined
        :value="formattedDate"
        :label="inputLabel"
        clearable
        readonly
        append-icon="event"
        v-on="on"
        :rules="rules"
        @click:clear="clearDates">
      </v-text-field>
    </template>
    <v-date-picker ref="picker"
      :value="isoValue"
      :max="new Date().toISOString().substr(0, 10)"
      min="1900-01-01"
      @change="changeValue">
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: 'VBirthday',
  props: {
    value: [Object, String, Array],
    range: Boolean,
    label: String,
    rules: Array,
  },
  data () {
    return {
      showPicker: false,
      isoDate: null
    }
  },

  computed: {
    inputLabel () {
      return this.label || this.$t('types.birthdate')
    },

    isoValue () {
      if (!this.value) {
        return ''
      }
      return new Date(this.value).toISOString().substr(0, 10)
    },

    formattedDate () {
      return this.$date(this.value)
    }
  },

  watch: {
    showPicker (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  methods: {
    clearDates () {
      this.changeValue('')
    },

    changeValue (value) {
      this.$emit('input', value)
      this.$emit('change', value)
      this.showPicker = false
      this.$refs.menu.save(value)
    }
  }
}
</script>
