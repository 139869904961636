<template>
  <span class="">
    <div class="row" style="position: absolute">
      <BackRow style="position: absolute" color="white" />
    </div>
    <div class="row mb-12 center" style="margin-top: 32px; align-self: center">
      <div class="col-12 center" style="margin: auto">
        <Logo :class="`col-${isMobile ? 8 : 2}`" style="padding:0; margin-bottom: 10px;" :width="200" maxHeight="54px">
          <!--<template #before>
            <div class="adornos1 mr-1">
              <img :src="getSrc1" alt="" class="image1" />
              <img v-if="!isMobile" :src="getSrc2" alt="" class="image2" />
            </div>
          </template>
          <template #after>
            <div class="adornos1 ml-1">
              <img v-if="!isMobile" :src="getSrc3" alt="" class="image3" />
              <img v-if="!isMobile" :src="getSrc4" alt="" class="image4" />
              <img :src="getSrc5" alt="" class="image5" />
            </div>
          </template> -->
        </Logo>
      </div>
      <div class="row" style="position: relative; z-index: 1000">
        <div class="col-12 center">
          <img src="./resources/left_mask.svg" style="margin-right: 8px"/>
          <p class="text" v-html="$t('campaign.carnival')"></p>
          <img src="./resources/right_mask.svg" style="margin-left: 8px"/>
        </div>
        <div class="col-11 center" style="margin: auto">
          <p class="bonus" v-html="$t('campaign.bonus')"></p>
        </div>
      </div>
      <!-- <div :class="`col-${isMobile ? 12 : 5}`" :style="{margin: 'auto', 'text-align': isMobile ? 'center' : 'right'}">
        <img :src="getSrc6" alt="" class="image5" />
      </div>
      -->
    </div>
    <CountdownTime
      :vertical="false"
      fixedBottom
      :one-line="!isMobile"
      :title="$t('dialog.text1')"
      back-ground-color="#F0FF42"
      color="#000000"
      class="mt-4 mb-4"/>
    <ListCreator style="margin-top: 40px !important; min-width: 355px; z-index: 1000;" noCard/>
    <div class="row center mt-5" style="position: relative; z-index: 999; margin-bottom: 64px;">
      <span class="desc2"> {{ $t("landing.text3") }}</span>
    </div>
  </span>
</template>

<script>
import Logo from "@/views/landing/specialCampaign/components/Logo";
import messages from "@/views/landing/specialCampaign/locales/all.json";
import ListCreator from "@/views/landing/specialCampaign/components/ListCreator";
import CountdownTime from "@/views/landing/specialCampaign/components/CountdownTime";
import project from "@/project";
import { mapState } from "vuex";

export default {
  name: "FollowLanding",
  components: { Logo, ListCreator, CountdownTime },
  i18n: { messages },
  data() {
    return {
      project
    };
  },
  computed: {
    ...mapState("application", ["isMobile"]),
    getSrc() {
      return require(`@/views/landing/specialCampaign/resources/img-example.jpg`);
    },
    getSrc1() {
      return require(`@/views/landing/specialCampaign/resources/adorno-1.png`);
    },
    getSrc2() {
      return require(`@/views/landing/specialCampaign/resources/adorno-2.png`);
    },
    getSrc3() {
      return require(`@/views/landing/specialCampaign/resources/adorno-3.png`);
    },
    getSrc4() {
      return require(`@/views/landing/specialCampaign/resources/adorno-4.png`);
    },
    getSrc5() {
      return require(`@/views/landing/specialCampaign/resources/adorno-5.png`);
    },
    getSrc6() {
      return require(`@/views/landing/specialCampaign/resources/sticker-nye-${this.project.project}-${this.$i18n.locale}.svg`);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./_styles";

.text-discounts {
  width: 100%;
  margin-top: 4%;
  p {
    text-align: center;
    font: normal normal bold 30px/27px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}
.line {
  width: 205px;
  height: 0px;
  border: 1px solid var(--unnamed-color-ffffff);
  border: 1px solid #ffffff;
  opacity: 1;
}
.adornos1 {
  display: flex !important;
  align-items: flex-start !important;
}

.desc1 {
  font: 18px/22px var(--unnamed-font-family-montserrat);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
}
.content-title1 {
  display: flex;
  align-items: center;
  justify-content: center;
  .title1 {
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: 24px/40px Bodoni 72;
    letter-spacing: 0.96px;
  }
}
.desc2 {
  font: 14px/20px var(--unnamed-font-family-montserrat);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
}
.image1 {
  width: 63px;
  max-width: fit-content;
}
.image2 {
  width: 57px;
  max-width: fit-content;
}
.image3 {
  width: 45px;
  max-width: fit-content;
}
.image4 {
  width: 39px;
  max-width: fit-content;
}
.image5 {
  width: 100%;
  height: 330px;
  max-width: fit-content;
}

.text {
  text-align: center;
  color: white;
  font-size: 24px;
  font-family: Montserrat !important;
  font-weight: bold;
  line-height: 34px;
}
@media (max-width: 700px) {
  .line {
    width: 59px;
  }
  .content-title1 {
    .title1 {
      font-size: 13px;
    }
  }
  .desc1 {
    padding: 0 5px;
    font-size: 16px;
  }
  .desc2 {
    padding: 0 5px;
  }
  .image1 {
    width: 47px;
  }
  .image5 {
    width: 100%;
    height: 150px!important;
    max-width: fit-content;
  }

  .text {
    text-align: center;
    color: white;
    font-size: 20px;
    font-family: Montserrat !important;
    font-weight: bold;
    line-height: 24px;
  }
}

.separator {
  border-bottom: 1px solid white;
  height: 1px;
  padding: 0;
  margin: auto;
}
.bonus {
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
}
</style>
