<template>
  <section>
    <FileListUploader
      ref="uploader"
      class="mb-5"
      autoupload
      permitTypeAudio
      multipleTypes
      v-model="upLoadFilesTemp"
      @file-removed="deleteFile"
      max-videos="5"
      max-images="20"
    />
  </section>
</template>

<script>
import MediaFileApi from '@/api/conversations/MediaFileApi'
import { mapState } from 'vuex'
import Button from './Buttons.vue'
export default {
  name: 'Attachments',
  components: {
    Button
  },
  props: {
    reset: { type: Boolean, default: false },
    noUploadedMedia : {type:Boolean, default: false}
  },
  computed: {
    ...mapState('chats', ['deleteAttachmenForm']),
    validFiles () {
      return !this.upLoadFilesTemp.find(f => !f.uploaded)
    }
  },
  watch: {
    reset () {
      if (this.reset) {
        this.upLoadFilesTemp = []
      }
    },
    deleteAttachmenForm () {
      if (this.deleteAttachmenForm.length > 0) {
        let index = -1
        this.deleteAttachmenForm.map(vaf => {
          index = this.upLoadFilesTemp.findIndex(f => f.mediafile.id === vaf.id)
        })
        if (index !== -1) {
          this.upLoadFilesTemp.splice(index, 1)
        }
      }
    },
    async validFiles () {
      this.$emit('loading', true)
      if (this.validFiles) {
        const files = this.upLoadFilesTemp.slice()
        const promise = files.map(async f => {
          if (!f.hasOwnProperty('mediafile') && !this.noUploadedMedia) {
            const { data, error } = await MediaFileApi.create(f.location, 'aws')
            if (!error) {
              f.mediafile = data
            }
          }
        })
        await Promise.all(promise)
        this.$emit('up-media-file', files)
        this.$emit('loading', false)
      }
    }
  },
  data () {
    return {
      upLoadFilesTemp: [],
      showLoading: true,
      selectedIndex: null
    }
  },
  methods: {
    deleteFile (file, index) {
      this.upLoadFilesTemp.slice(index, 1)
      this.$emit('up-media-file', this.upLoadFilesTemp)
      if (file.hasOwnProperty('mediafile')) {
        MediaFileApi.delete(file.mediafile.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner-load {
  width: 200px;
  height: 100px;
}
</style>
