<template>
  <section>
    <v-form v-model="validForm"  ref="form"
      @submit.prevent="submit">
      <ListViewTitle :title="$t('twitter_preferences.title')">
        <template #icon>
          <v-icon>$vuetify.icons.twitter</v-icon>
        </template>
      </ListViewTitle>
      <v-row no-gutters>
        <v-col cols="6">
          <v-checkbox color="primary" v-if="isInfluencer"
            hide-details
            :label="$t('twitter_preferences.subscriber')"
            v-model="config.notifySubscriber"></v-checkbox>
          <v-checkbox color="primary" v-if="isInfluencer"
            hide-details
            :label="$t('twitter_preferences.follower')"
            v-model="config.notifyFollower"></v-checkbox>
          <v-checkbox color="primary" v-if="isInfluencer"
            hide-details
            :label="$t('twitter_preferences.premium_content')"
            v-model="config.notifyPremiumContent"></v-checkbox>
          <v-checkbox color="primary" v-if="isInfluencer"
            hide-details
            :label="$t('twitter_preferences.lives')"
            v-model="config.notifyLives"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox color="primary" v-if="isInfluencer"
            hide-details
            :label="$t('twitter_preferences.other_content')"
            v-model="config.notifyOtherContent"></v-checkbox>
          <v-checkbox color="primary" v-if="isInfluencer"
            hide-details
            :label="$t('twitter_preferences.product')"
            v-model="config.notifyProduct"></v-checkbox>
          <v-checkbox color="primary" v-if="isInfluencer"
            hide-details
            :label="$t('twitter_preferences.purchased')"
            v-model="config.notifyPurchased"></v-checkbox>
          <v-checkbox color="primary" v-if="isInfluencer"
            hide-details
            :label="$t('twitter_preferences.auction_finished')"
            v-model="config.notifyAuctionFinished"></v-checkbox>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <c-btn @click.stop.prevent="submit">{{ $t('actions.save') }}</c-btn>
      </v-card-actions>
    </v-form>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import TwitterApi from '@/api/TwitterApi'
import validatable from '@/components/mixins/validatable'
import messages from '../locales/all.json'

export default {
  name: 'TwitterPreferencesForm',
  mixins: [validatable('form')],
  i18n: { messages },
  data () {
    return {
      loading: false,
      config: {}
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer'])
  },
  methods: {
    async onSubmit () {
      this.loading = true
      await TwitterApi.update(this.config)
      this.loading = false
    }
  },
  mounted () {
    this.config = this.currentUser.twitterPreferences
  }
}
</script>
