<template>
  <v-card
    flat
    :href="darkfansLink"
    :to="resourceRoute"
    :target="darkfansLink ? '_blank' : ''"
    class="post-card"
  >
    <Box
      class="post-content"
      :ratio="ratio"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <Resource
        class="bg-black"
        rounded
        nocontrols
        no-resize
        autoload
        muted
        clip
        preview
        :hover="hover"
        :autoplay="hover"
        :resource="post.preview"
        style="height: 100% !important"
        :cardView="true"
      >
        <component
          :is="cardComponent"
          class="post-responsive"
          dummy-payment
          :post="post"
          :hover="hover"
          v-bind="$props"
        ></component>
      </Resource>
    </Box>
    <PostCardExtension v-if="extended" class="mt-2" v-bind="$props" />
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import PostDraft from "@/components/posts/PostDraft";
import PostCardExtension from "@/components/posts/PostCardExtension";
import PostNoVisible from "@/components/posts/PostNoVisible";
import CardOverlay from "@/components/posts/overlays/CardOverlay";
import Resource from "@/components/posts/Resource";
import cookies from "@/cookies";
import { PreferenceActions } from "@/store";
import config from "@/project";

export default {
  name: "PostCard",
  components: {
    PostDraft,
    PostCardExtension,
    PostNoVisible,
    CardOverlay,
    Resource
  },
  props: {
    post: Object,
    ratio: { type: [String, Number], default: 1 },
    extended: Boolean
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    ...mapState("profile", ["currentUser", "isFollower"]),
    ...mapState("application", ["tags"]),
    mustRedirect() {
      return (
        config.payments.darkfans_redirect &&
        (this.post.author.subscribed || this.post.acquired)
      );
    },

    resourceRoute() {
      if (this.mustRedirect) {
        return undefined;
      }
      return { name: "post", params: { uuid: this.post.id } };
    },

    cardComponent() {
      return this.post.draft
        ? "PostDraft"
        : this.post.visible
        ? "CardOverlay"
        : "PostNoVisible";
    },
    darkfansLink() {
      if (this.mustRedirect) {
        if (this.post.author.scatProfile) {
          this.setCookiesScat();
        }
        return `https://darkfans.com/posts/${this.post.id}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`;
      }
      return false;
    }
  },
  methods: {
    setCookiesScat() {
      let catCookies = cookies.get("categories-prefs") || "";
      const cookieScat = catCookies.split(",").includes("scat");
      if (!cookieScat) {
        cookies.set(
          "categories-prefs",
          catCookies ? catCookies.concat(",scat") : "scat"
        );
      }
      catCookies = cookies.get("categories-prefs");
      const categories = [];
      const tags = this.tags.filter(tag =>
        catCookies.split(",").includes(tag.name)
      );
      tags.map(tag => ({ ...tag, text: this.$t(`tags.${tag.name}`) }));
      tags.map(tag => categories.push(tag));
      this.$store.dispatch(PreferenceActions.UpdateTagPrefs, true);
      //this.$store.dispatch(PreferenceActions.UpdateCategory, categories);
    }
  }
};
</script>
<style lang="scss" scoped>
.video-player.rounded {
  height: 100% !important;
}
.post-card {
  .post-content {
    position: relative;
  }
  .post-responsive {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.bg-black {
  background-color: black;
}
</style>
