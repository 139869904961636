<template>
  <!-- !getTagPref -->
  <c-dialog v-if="!tagPrefDialog"
            persistent
            :actions="false"
            width="684"
            style="z-index: 500"
            :value="!tagPrefDialog"
            @close="closeTags">
    <template slot="default">
      <v-col style=" text-align: center;" cols="12">
        <h1 class="title1" :class="{dark:$vuetify.theme.dark}" :style="{marginTop: isMobile ? 0 : '10%'}">
          {{ $t('category.title') }}</h1>
        <v-col style="margin: auto; text-align: center;" cols="12">
          <h4 style="color: #797979 !important;">{{
              $t('category.subtitle', { project: project.locales.project })
            }}</h4>
        </v-col>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col
            :cols="isMobile?6:3"
            style="text-align: center">
            <v-btn
              fab
              text
              @click="selectTag('all')">
              <v-row>
                <v-col cols="12" class="cat-btn" :class="classSelect(['all'])">
                  <v-img class="cat" width="70px" rounded
                         :src="`/images/preferences/${project.project}-todos.svg`"></v-img>
                </v-col>
              </v-row>
            </v-btn>
            <v-row class="mt-2">
              <v-col cols="12 ">
                <span>{{ $t('category.all') }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            :cols="isMobile?6:3"
            style="text-align: center">
            <v-btn
              fab
              text
              @click="selectTag('genders', ['female', 'couple'])">
              <v-row>
                <v-col cols="12" class="cat-btn" :class="classSelect(['female', 'couple'])">
                  <v-img class="cat" width="70px" rounded
                         :src="`/images/preferences/${project.project}-heteros.svg`"></v-img>
                </v-col>
              </v-row>
            </v-btn>
            <v-row class="mt-2">
              <v-col cols="12 ">
                <span>{{ $t('category.straight') }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            :cols="isMobile?6:3"
            style="text-align: center">
            <v-btn
              fab
              text
              @click="selectTag('categories', ['homo'])">
              <v-row>
                <v-col cols="12" class="cat-btn" :class="classSelect(['homo'])">
                  <v-img class="cat" width="70px" rounded
                         :src="`/images/preferences/${project.project}-gay-chicos.svg`"></v-img>
                </v-col>
              </v-row>
            </v-btn>
            <v-row class="mt-2">
              <v-col cols="12 ">
                <span>{{ $t('category.homo') }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            :cols="isMobile?6:3"
            style="text-align: center">
            <v-btn
              fab
              text
              @click="selectTag('genders', ['trans'])">
              <v-row>
                <v-col cols="12" class="cat-btn" :class="classSelect(['trans'])">
                  <v-img class="cat" width="70px" rounded
                         :src="`/images/preferences/${project.project}-trans.svg`"></v-img>
                </v-col>
              </v-row>
            </v-btn>
            <v-row class="mt-2">
              <v-col cols="12 ">
                <span>{{ $t('category.trans') }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="font-size: 14px;text-align: center">
            <p v-html="$t(`application.preferences.management`)"/>
          </v-col>
        </v-row>

      </v-col>
      <v-divider style="border-color: #707070; margin-top: 30px;" :style="cssDivider"></v-divider>
      <v-col style="margin: auto" :cols="isMobile?12:7">
        <c-btn @click="selectTags()" :disabled="!keys.length" style="width: 100%;">{{ $t('actions.enter') }}</c-btn>
      </v-col>
    </template>
  </c-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { PreferenceActions } from '@/store'
import cookies from '@/cookies'
import project from '@/project'

export default {
  name: 'TagPreferencesDialog',
  data () {
    return {
      project,
      keys: [],
      tagPrefDialog: true
    }
  },
  computed: {
    ...mapState('preferences', ['tagPrefs']),
    ...mapState('profile', ['currentUser']),
    ...mapState('session', ['authenticated']),
    ...mapState('application', ['isMobile', 'tags']),
    cssDivider () {
      return {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: this.isMobile ? '100%' : '90%'
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.tagPrefDialog = !this.authenticated || (this.tagPrefs || cookies.get(`tagPrefs-${this.currentUser.id}`))
    })
  },
  methods: {
    classSelect (keys) {
      const index = this.keys.findIndex(k => keys.includes(k))
      return {
        select: index !== -1
      }
    },
    selectTag (type, keys = []) {
      const index = this.keys.findIndex(elemt => keys.includes(elemt))
      if (type === 'all') {
        this.keys = this.keys.length === 5 ? [] : ['all', 'homo', 'female', 'couple', 'trans']
      } else if (index !== -1) {
        this.keys.splice(index, 1)
      } else {
        this.keys.push(keys)
        this.keys = this.keys.flat()
      }
    },
    closeTags () {
      this.keys = ['all', 'homo', 'female', 'couple', 'trans']
      this.selectTags()
    },
    selectTags () {
      const genders = []
      const categories = []
      const ages = []
      if (this.keys.length === 5) {
        this.keys.splice(0, 1)
        genders.push(...this.tags.filter(tag => tag.section === 'gender'))
        categories.push(...this.tags.filter(tag => tag.section === 'category'))
        ages.push(...this.tags.filter(tag => tag.section === 'age'))
        if (project.project !== 'scatbook') {
          categories.splice(categories.indexOf(tag => tag.name === 'scat'), 1)
        }
      } else {
        const tags = this.tags.filter(tag => this.keys.includes(tag.name))
          .map(tag => ({
            ...tag,
            text: this.$t(`tags.${tag.name}`)
          }))
        if (this.keys.includes('trans') || this.keys.includes('female') || this.keys.includes('couple')) {
          tags.forEach(tag => genders.push(tag))
        } else if (this.keys.includes('homo')) {
          tags.forEach(tag => categories.push(tag))
        }
      }
      this.$store.dispatch(PreferenceActions.UpdateTagPrefs, true)
      this.$store.dispatch(PreferenceActions.UpdateGender, genders)
      this.$store.dispatch(PreferenceActions.UpdateCategory, categories)
      this.$store.dispatch(PreferenceActions.UpdateAge, ages)
      cookies.set(`tagPrefs-${this.currentUser.id}`, true)
      this.tagPrefDialog = true
    }
  }
}
</script>
<style lang="scss" scoped>
.cat {
  //top: 10px;
  //height: 50px;
  min-height: 50px;
  max-width: 70px;
  min-width: 70px;
  margin: auto 0;
}

.lips :hover {
  border-style: groove;
  border-radius: 100%;
  border-color: #DF3856;
}

.select {
  border-color: #df3856 !important;
}

.v-btn__content :hover {
  background: none;
}

.title1 {
  color: #242424 !important;
  font-size: 24px;
  font-weight: 600;
  margin: auto;
  line-height: 29px;

  &.dark {
    color: white !important;
  }
}

.cat-btn {
  margin: auto;
  height: 98.8px;
  border-color: #e1e1e1;
  border-style: solid;
  border-radius: 100%;
}

.cat-btn:hover {
  border-color: #df3856;
}

</style>
