<template>
  <section>
    <div style="width: 197px;" class="mx-auto my-3" v-if="vertical">
      <p class="center text">{{ title }}</p>
      <vue-countdown-timer
        :day-txt="'days'"
        :hour-txt="'hours'"
        :interval="1000"
        :minutes-txt="'minutes'"
        :seconds-txt="'seconds'"
        :start-time="startTime"
        :end-time="endTime"
        label-position="begin"
        @end_callback="endEvent()"
      >
        <template slot="countdown" slot-scope="scope">
          <div class="row count-Upper" style="justify-content: center;">
            <div class="col padding">
              <div class="number">{{ scope.props.days }}</div>
              <div class="text">
                {{
                  scope.props.days === 1
                    ? $t('countdown.day')
                    : $t('countdown.days')
                }}
              </div>
            </div>
            <div class="col padding">
              <div class="number">
                {{ scope.props.hours }}
              </div>
              <div class="text">
                {{
                  scope.props.hours === 1
                    ? $t('countdown.hour')
                    : $t('countdown.hours')
                }}
              </div>
            </div>
            <div class="col padding">
              <div class="number">
                {{ scope.props.minutes }}
              </div>
              <div class="text">
                {{ $t('countdown.mint') }}
              </div>
            </div>
            <div class="col padding">
              <div class="number">
                {{ scope.props.seconds }}
              </div>
              <div class="text">
                {{ $t('countdown.sec') }}
              </div>
            </div>
          </div>
        </template>

        <!--  <template slot="end-text" slot-scope="scope">
          <div class="countdown-box" style="justify-content: center;">
            <div class="countdown-box-item fuente-destacada" :style="cssBoxStyle">
              <div class="number">00</div>
              <div class="countdown-box-titulo">
                {{
                  scope.props.days === 1
                    ? $t("types.date.day")
                    : $t("types.date.days")
                }}
              </div>
            </div>
            <div class="countdown-box-item fuente-destacada" :style="cssBoxStyle">
              <div class="number">00</div>
              <div class="countdown-box-titulo number">
                {{
                  scope.props.hours === 1
                    ? $t("types.date.hour")
                    : $t("types.date.hours")
                }}
              </div>
            </div>
            <div class="countdown-box-item fuente-destacada" :style="cssBoxStyle">
              <div class="countdown-box-numero">00</div>
              <div class="countdown-box-titulo">
                {{
                  scope.props.minutes === 1
                    ? $t("types.date.minute")
                    : $t("types.date.minutes")
                }}
              </div>
            </div>
            <div class="countdown-box-item fuente-destacada" :style="cssBoxStyle">
              <div class="countdown-box-numero">00</div>
              <div class="countdown-box-titulo">
                {{
                  scope.props.seconds === 1
                    ? $t("types.date.second")
                    : $t("types.date.seconds")
                }}
              </div>
            </div>
          </div>
        </template> -->
      </vue-countdown-timer>
    </div>
    <div v-else :class="{'fixed-bottom': fixedBottom, mobile: isMobile}">
      <vue-countdown-timer
        :day-txt="'days'"
        :hour-txt="'hours'"
        :interval="1000"
        :minutes-txt="'minutes'"
        :seconds-txt="'seconds'"
        :start-time="startTime"
        :end-time="endTime"
        label-position="begin"
        @end_callback="endEvent()"
      >
        <template slot="countdown" slot-scope="scope">
          <div style="position: inherit !important;">
            <v-img class="counter-img-left" src="/images/landing/counter-left.svg" width="170" v-if="!isMobile"></v-img>
            <v-img class="counter-img-center" src="/images/landing/counter-center.svg" width="170"
                   v-if="isMobile"></v-img>
            <v-img class="counter-img-right" src="/images/landing/counter-right.svg" width="170"
                   v-if="!isMobile"></v-img>
          </div>
          <div class="row count-footer" style="justify-content: center;font-weight: 600;" v-if="!isMobile">
            <div class="col title-counter remaining" style="font-family: Montserrat, sans-serif !important;"
                 :style="{color: `${color} !important`}">
              {{ $t('dialog.text1') }}
            </div>
            <div class="col-2 remaining">
              <div class="number-counter" :style="{color: `${color} !important`}">
                {{ scope.props.days }}
                <span class="text-counter" style="margin-left: 3px;">
                  {{
                    scope.props.days === 1
                      ? $t('countdown.day')
                      : $t('countdown.days')
                  }}
                </span>
              </div>
            </div>
            <div class="col-2 remaining">
              <div class="number-counter" :style="{color: `${color} !important`}">
                {{ scope.props.hours }}
                <span class="text-counter" style="margin-left: 3px;">
                  {{
                    scope.props.hours === 1
                      ? $t('countdown.hour')
                      : $t('countdown.hours')
                  }}
                </span>
              </div>
            </div>
            <div class="col-2 remaining">
              <div class="number-counter" :style="{color: `${color} !important`}">
                {{ scope.props.minutes }}
                <span class="text-counter" style="margin-left: 3px;"> {{ $t('countdown.mint') }} </span>
              </div>
            </div>
            <div class="col-2 remaining">
              <div class="number-counter" :style="{color: `${color} !important`}">
                {{ scope.props.seconds }}
                <span class="text-counter" style="margin-left: 3px;">{{ $t('countdown.sec') }}</span>
              </div>
            </div>
          </div>
          <div class="row count-footer" style="justify-content: center;" v-else>
            <div class="col-1 remaining mobile">
              <div class="number-counter mobile" :style="{color: `${color} !important`}">
                {{ scope.props.days }}
                <span class="text-counter">
                  {{
                    scope.props.days === 1
                      ? $t('countdown.day')
                      : $t('countdown.days')
                  }}
                </span>
              </div>
            </div>
            <div class="col-1 remaining mobile">
              <div class="number-counter mobile" :style="{color: `${color} !important`}">
                {{ scope.props.hours }}
                <span class="text-counter">
                  {{
                    scope.props.hours === 1
                      ? $t('countdown.hour')
                      : $t('countdown.hours')
                  }}
                </span>
              </div>
            </div>
            <div class="col-1 remaining mobile">
              <div class="number-counter mobile" :style="{color: `${color} !important`}">
                {{ scope.props.minutes }}
                <span class="text-counter"> {{ $t('countdown.mint') }} </span>
              </div>
            </div>
            <div class="col-1 remaining mobile">
              <div class="number-counter mobile" :style="{color: `${color} !important`}">
                {{ scope.props.seconds }}
                <span class="text-counter">{{ $t('countdown.sec') }}</span>
              </div>
            </div>
          </div>
          <div class="col-3 title-counter remaining mobile" :style="cssStyleCounter" v-if="!oneLine">
            <span style="margin: auto;font-family: Montserrat_SemiBold, sans-serif !important">{{
                $t('dialog.text1')
              }}</span>
          </div>
        </template>
      </vue-countdown-timer>
    </div>
  </section>
</template>

<script>
import project from '@/project'
import messages from '@/views/landing/specialCampaign/locales/all.json'
import { mapState } from 'vuex'

export default {
  name: 'CountdownTime',
  i18n: { messages },
  props: {
    title: String,
    fixedBottom: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: true
    },
    oneLine: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'black'
    },
    backGroundColor: {
      type: String,
      default: 'white'
    }
    /* startTime: { type: Date, default: "2022-12-16 00:00:00" },
    endTime: { type: Date, default: "2023-1-2 23:59:59" }, */
    /* classCustom: String */
  },
  computed: {
    ...mapState('application', ['isMobile']),
    cssStyleCounter () {
      return {
        color: this.color,
        backgroundColor: this.backGroundColor
      }
    },
    startTime () {
      return project.campaign.started
    },
    endTime () {
      return project.campaign.ended
    }
  },
  methods: {
    endEvent () {
      if (project.campaign.specialEnabled) return
      this.$router.push({ name: 'login' })
    }
  }
  /* beforeMount() {
    if (!this.eventStarted(new Date()) || this.eventEnded(new Date())) {
      this.endEvent();
    }
  } */
}
</script>

<style lang="scss" scoped>
@import "../_styles";

.count-Upper {
  .number {
    text-align: center;
    font: normal normal bold 22px/24px Montserrat;
    letter-spacing: 0px;
    color: #ffcc3e;
  }

  .text {
    color: #000000;
    text-align: center;
    font: normal normal medium 14px/20px Montserrat;
    letter-spacing: 0px;
  }
}

.padding {
  padding: 0px;
}

.fixed-bottom {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  height: 40px !important;
  background: #DF3856 0% 0% no-repeat padding-box;
  opacity: 1;
  min-width: -webkit-fill-available;
  min-width: -moz-available;

  &.mobile {
    height: 72px !important;
    background: #fed1da 0% 0% no-repeat padding-box !important;
  }
}

.count-footer {
  .title-counter {
    font-family: Montserrat_SemiBold, sans-serif !important;
    font-size: 12px;
  }

  .number-counter {
    font-family: Montserrat_Bold, sans-serif !important;
    font-size: 20px;
    position: relative;
    max-height: 52px;
    line-height: 14px;
    text-align: center;
    display: inline-flex;
  }

  .text-counter {
    font-family: Montserrat, sans-serif !important;
    font-size: 12px;
    font-weight: 400;
  }

  .remaining {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    max-width: fit-content;
    display: block ruby;

    &.mobile {
      max-height: 40px;
      display: flex;
    }
  }
}

.col-12.title-counter.remaining.mobile {
  height: 42px !important;
  padding: 0px;
  color: #DF3856 !important;
}

.col-3.title-counter.remaining.mobile {
  text-align: center !important;
  font-size: 12px !important;
  color: #FFFFFF !important;
  background-color: #DF3856 !important;
  min-width: 100%;
}

.decoration {
  position: relative;
  top: -24px;

  &.mobile {
    position: absolute;
    top: -20px;
    left: calc(50% - 100px);
  }

  &.right {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.counter-img-left {
  position: absolute;
  z-index: 1000;
  bottom: 0px !important;
  left: 15%;
}

.counter-img-center {
  position: absolute;
  z-index: 1000;
  top: -32px !important;
  left: 32%;
}

.counter-img-right {
  position: absolute;
  z-index: 1000;
  bottom: 0px !important;
  right: 15%;
}
</style>
