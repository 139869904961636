<template>
  <section class="complaint container">
    <c-btn @click="$router.back()">
      {{ $t("actions.back") }}
    </c-btn>
    <div class="row mt-3">
      <div class="col-6 head-1 left">
        <img
          :src="getArtemisaSrc"
          style="margin-top: 4px;width: 270px;height: 15px;max-width: 100%;margin-right: 5px;"
          v-if="project.showFooterImages"
        />
        <span v-else> Artemisa 3000 Tech Solutions SL </span><br />
      </div>
      <div class="col-6 head-1 right">
        Complaints Policy and Procedures
      </div>
      <div class="col-11 title">
        <img
          :src="getTitle"
          style="margin-top: 4px;width: 488px; height: 64px;max-width: 100%;margin-right: 5px;"
        />
        <!-- <span class="sect-1">
          getTitle
          Artemisa 3000 Tech Solutions SL
        </span>
        <br />
        <span class="sect-2">
          Complaints Policy and Procedures
        </span> -->
      </div>
      <div class="col-8"></div>
      <div class="col-4 head-1 left">
        Revision Date: 26 May 2024
      </div>
    </div>
    <div class="row">
      <b><h1>Removal of Content depicting a user</h1></b>
    </div>
    <div class="row">
      <p>
        You have the right to delete your presence on the internet. Please
        follow this procedure and provide the requested information to start the
        procedure of the content removal, where you are depicted. Please note
        that the result of the investigations could be that the request does not
        apply.
        <br />
        In case of disagreement regarding an appeal, you must allow such
        disagreement to be resolved by a neutral body. So any potential
        statements contradicting this will need to be reworded or removed.
      </p>
    </div>
    <div class="row mt-3">
      <div class="col-6 border">Managed By:</div>
      <div class="col-6 border">Monitoring Team</div>
    </div>
    <div class="row">
      <div class="col-6 border">Resolution informed to:</div>
      <div class="col-6 border">Claimant and Content Owner</div>
    </div>
    <div class="row">
      <div class="col-6 border">Results in:</div>
      <div class="col-6 border">7 days</div>
    </div>
    <div class="row">
      <div class="col-6 border">Possible Results:</div>
      <div class="col-6 border">
        Content removed or no further actions are taken
      </div>
    </div>
    <div class="row">
      <div class="col-6 border">Appealing available:</div>
      <div class="col-6 border">Yes</div>
    </div>
    <div class="row">
      <div class="col-6 border">Reporting Channels:</div>
      <div class="col-6 border">
        <a
          :href="`mailto:stars@${project.project}.com`"
          target="_blank"
          style="color:var(--unnamed-color-base)"
        >
          stars@{{ project.project }}.com
        </a>
      </div>
    </div>
    <div class="row mt-3">
      <b><h1>Information to be included</h1></b>
    </div>
    <ol>
      <li>A sample of the mentioned content, detailing who are you at it;</li>
      <li>An official document to prove your identity;</li>
      <li>
        A description of where the claimed material is located on our website
      </li>
    </ol>
    <div class="row mt-3">
      <b><h1>Procedure</h1></b>
    </div>
    <ol>
      <li>
        Our team receives and reviews the claim. Additional facts may be requested.
      </li>
      <li>
        The content owner is contacted to provide the depicted people's legal age checks and
        their consent to appear in the given content.
      </li>
      <li>
        A decision is made and informed: content will be removed, and no further actions will be
        taken.
      </li>
      <li>
        Appealing: You may reply to our decision with additional facts or reasons. The case will
        be escalated to a neutral body to make a final decision, which we will execute and
        communicate to all the affected people as soon as we receive it.
      </li>
    </ol>
    <div class="row mt-3">
      <b><h1>Unauthorized Content</h1></b>
    </div>
    <div class="row">
      <p>
        The Website and the Creator Terms and Conditions specify the not
        accepted content to be published via our website.
        <br />
        Our team reviews all the content uploaded by users prior to posting. If there are doubts
        about the reviewed material, the content owner is contacted to provide further details.
        Alternatively, the content may be directly deleted
        <br />
        Despite being authorized, as specific content could still be offensive to someone, we provide
        the electronic mechanism to report any content online based on the user’s goodwill.
      </p>
    </div>
    <div class="row mt-3">
      <div class="col-6 border">Managed By:</div>
      <div class="col-6 border">Monitoring Team</div>
    </div>
    <div class="row">
      <div class="col-6 border">Resolution informed to:</div>
      <div class="col-6 border">Claimant and Content Owner</div>
    </div>
    <div class="row">
      <div class="col-6 border">Results provided in:</div>
      <div class="col-6 border">
        24 hours. The Content is disconnected immediately..
      </div>
    </div>
    <div class="row">
      <div class="col-6 border">Possible Results:</div>
      <div class="col-6 border">
        Content removed or no further actions are taken
      </div>
    </div>
    <div class="row">
      <div class="col-6 border">Appealing available:</div>
      <div class="col-6 border">Yes</div>
    </div>
    <div class="row">
      <div class="col-6 border">Reporting Channels:</div>
      <div class="col-6 border">
        A denounce button linked to any content published, or via
        <a
          :href="`mailto:stars@${project.project}.com`"
          target="_blank"
          style="color:var(--unnamed-color-base)"
        >
          stars@{{ project.project }}.com
        </a>
      </div>
    </div>
    <div class="row mt-3">
      <b><h1>Information to be included</h1></b>
    </div>
    <ol>
      <li>
        If reported via the denounce button: a reason from a list must be
        provided. The rest of information is gathered automatically;
      </li>
      <li>
        If reported via email: the model, date / time when the content has been
        posted, your description of the content reported, clearly stating why
        you consider it to be non authorized and screenshots of the related
        content, if available.
      </li>
    </ol>
    <div class="row mt-3">
      <b><h1>Procedure</h1></b>
    </div>
    <ol>
      <li>
        Our team receives and reviews the claim. Additional facts may be requested.
      </li>
      <li>
        The content is immediately disconnected from the public view and analyzed.
      </li>
      <li>
        A decision is made: content is blocked or rereleased for public viewing.
      </li>
      <li>
        Appealing: To follow the process again with new facts and referencing previous
        claims.
      </li>
    </ol>
    <div class="row mt-3">
      <b><h1>Content Copyright Infringement</h1></b>
    </div>
    <div class="row">
      <p>
        Copyright owners who believe their work has been used without authorization can file a
        complaint to investigate the issue.
      </p>
    </div>
    <div class="row mt-3">
      <div class="col-6 border">Managed By:</div>
      <div class="col-6 border">Monitoring Team</div>
    </div>
    <div class="row">
      <div class="col-6 border">Resolution informed to:</div>
      <div class="col-6 border">Claimant and Content Owner</div>
    </div>
    <div class="row">
      <div class="col-6 border">Results in:</div>
      <div class="col-6 border">7 days</div>
    </div>
    <div class="row">
      <div class="col-6 border">Possible Results:</div>
      <div class="col-6 border">
        Content removed or no further actions are taken
      </div>
    </div>
    <div class="row">
      <div class="col-6 border">Appealing available:</div>
      <div class="col-6 border">
        No. Resolution based on the evidence provided.
      </div>
    </div>
    <div class="row">
      <div class="col-6 border">Reporting Channels:</div>
      <div class="col-6 border">
        <a
          :href="`mailto:stars@${project.project}.com`"
          target="_blank"
          style="color:var(--unnamed-color-base)"
        >
          stars@{{ project.project }}.com
        </a>
      </div>
    </div>
    <div class="row mt-3">
      <b><h1>Information to be included</h1></b>
    </div>
    <ol>
      <li>
        The claimant's details, such as Full name, address, telephone number, and email
        address, including the complainant’s physical or electronic signature;
      </li>
      <li>
        A description of the copyrighted work or other intellectual property claimed to have been
        infringed, including a clear explanation with evidence about the intellectual property
        rights violation;
      </li>
      <li>
        A description of where the claimed material is located on our website;
      </li>
      <li>
        A statement of the claimant’s good faith belief that the disputed use is not authorized by
        the copyright owner, its agent, or the law;
      </li>
      <li>
        A statement of the claimant, made under penalty of perjury, that the information in the
        claim is accurate and that you are the copyright or intellectual property owner or have
        been authorized to act on the owner’s behalf;
      </li>
      <li>
        An electronic or physical signature of the person authorized to act on behalf of the
        owner of the copyright or other intellectual property interest (if applicable);
      </li>
    </ol>
    <div class="row mt-3">
      <b><h1>Procedure</h1></b>
    </div>
    <ol>
      <li>
        Our team receives and reviews the claim. Additional facts may be requested.
      </li>
      <li>
        A decision is taken: content is removed, or no further action is taken.
      </li>
    </ol>
    <div class="row mt-3">
      <b><h1>Content Infringement Counter-Notice</h1></b>
    </div>
    <div class="row">
      <p>
        In the event of removing content without infringing any copyright, or
        having the authorization from the copyright owner, the user has the
        right to claim the restoration of the deleted content.
      </p>
    </div>
    <div class="row mt-3">
      <div class="col-6 border">Managed By:</div>
      <div class="col-6 border">Monitoring Team</div>
    </div>
    <div class="row">
      <div class="col-6 border">Resolution informed to:</div>
      <div class="col-6 border">Claimant and Content Owner</div>
    </div>
    <div class="row">
      <div class="col-6 border">Results in:</div>
      <div class="col-6 border">7 days</div>
    </div>
    <div class="row">
      <div class="col-6 border">Possible Results:</div>
      <div class="col-6 border">
        Content restored or no further actions are taken
      </div>
    </div>
    <div class="row">
      <div class="col-6 border">Appealing available:</div>
      <div class="col-6 border">
        No. Resolution based on the evidence provided.
      </div>
    </div>
    <div class="row">
      <div class="col-6 border">Reporting Channels:</div>
      <div class="col-6 border">
        <a
          :href="`mailto:stars@${project.project}.com`"
          target="_blank"
          style="color:var(--unnamed-color-base)"
        >
          stars@{{ project.project }}.com
        </a>
      </div>
    </div>
    <div class="row mt-3">
      <b><h1>Information to be included</h1></b>
    </div>
    <ol>
      <li>
        The claimant's details, such as Full name, address, telephone number, and email
        address, including the complainant’s physical or electronic signature;
      </li>
      <li>
        Identification of the Content that has been removed or to which access has been
        disabled and the location at which the Content appeared before it was removed or
        disabled;
      </li>
      <li>
        A clear explanation with evidence about the intellectual property rights violation;
      </li>
      <li>
        A description of where the claimed material is located on our website;
      </li>
      <li>
        A statement agreeing the jurisdiction of the federal court to be in Reno, Nevada;
      </li>
      <li>
        A statement accepting service of process from the person who provided notification of
        the alleged infringement;
      </li>
      <li>
        A statement of the claimant’s good faith belief that the disputed use is not authorized by
        the copyright owner, its agent, or the law;
      </li>
      <li>
        An electronic or physical signature of the person authorized to act on behalf of the
        copyright owner or other intellectual property interest (if applicable).
      </li>
    </ol>
    <div class="row mt-3">
      <b><h1>Procedure</h1></b>
    </div>
    <ol>
      <li>
        Our team receives and reviews the claim. Additional facts may be requested.
      </li>
      <li>
        A decision is taken: content is restored, or no further action is taken.
      </li>
    </ol>
    <div class="row mt-3">
      <b><h1>Record Keeping</h1></b>
    </div>
    <div class="row">
      <p>
        A register of complaints will be kept by
        <img
          :src="getArtemisaSrc"
          style="margin-top: 4px;width: 200px;height: 10px;margin-right: 5px;"
        />. The following for each complaint:
      </p>
    </div>
    <ol class="mt-2">
      <li>Details of the complainant and the nature of the complaint;</li>
      <li>Date logged;</li>
      <li>Action taken;</li>
      <li>Date of resolution and reason for decision;</li>
      <li>Indication of complainant being notified of outcome;</li>
      <li>Complainant response and any further action.</li>
    </ol>
    <div class="mt-3">
      Any personal data will be anonymized as part of the Record Keeping
      process, starting when a claim is resolved.
      <br />
      Copies of all correspondence and other materials received by
      <img
        :src="getArtemisaSrc"
        style="margin-top: 4px;width: 200px;height: 10px;margin-right: 5px;"
      /><br />
      regarding complaints will be kept for 7 years.
      <br />
      The complaints register and files will be confidential. Access is restricted to the Unit
      Managers, the Chief Operating Officer, the Chief Executive, the Compliance Manager, and
      the Director.
    </div>
    <c-btn class="mt-3" @click="$router.back()">
      {{ $t("actions.back") }}
    </c-btn>
  </section>
</template>

<script>
import project from '@/project'
export default {
  name: 'CompliancePolicy',
  data () {
    return {
      project
    }
  },
  computed: {
    getArtemisaSrc () {
      return `images/artemisa_${
        this.$vuetify.theme.dark ? 'white' : 'black'
      }.png`
    },
    getTitle () {
      return 'images/title_compliance.jpg'
    }
  }
}
</script>

<style lang="scss" scoped>
.complaint {
  font-family: Montserrat, sans-serif !important;
}
.head-1 {
  font-size: 18px;
  &.left {
    text-align: left !important;
  }

  &.right {
    text-align: right !important;
  }
}

.title {
  text-align: center;
  .sect-1 {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }
  .sect-2 {
    font-size: 28px;
    font-weight: 400;
    color: grey;
    text-align: center;
    line-height: 20px;
  }
}
.border {
  border: solid 1px;
}
</style>
