<template>
  <ListView
    cols="12" sm="6" md="3"
    :items="cursor.items"
    :loading="cursor.loading"
    :empty-text="$t('listview.no_followers')"
    @bottom-reached="cursor.loadItems">
    <template #default="{ item }">
      <section class="px-2 py-2">
        <UserCard :user="item" />
      </section>
    </template>
  </ListView>
</template>
<script>
import UserService from '@/components/users/mixins/user_service'
import UserCard from '@/components/users/UserCard'

export default {
  name: 'UserFollowers',
  mixins: [UserService({ cursor: true })],
  components: { UserCard },
  data () {
    return {
      query: { followers: true }
    }
  }
}
</script>
