<template>
  <section>
    <SubscriptionInfo />
  </section>
</template>
<script>
import SubscriptionInfo from '../components/SubscriptionInfo'

export default {
  name: 'SubscriptionConfigView',
  components: { SubscriptionInfo }
}
</script>
