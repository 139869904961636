<template>
  <section>
    <ProductActions :product="product">
      <AuctionPriceDescription :product="product" />
    </ProductActions>
    <AuctionTimeDescription
      class="mb-2"
      :product="product" />
      
    <BidButton v-if="isBiddable"
      :product="product" />
    <PaymentButton v-else-if="isAcquirable"
      :product="product" />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import AuctionTimeDescription from './auctions/AuctionTimeDescription'
import AuctionPriceDescription from './auctions/AuctionPriceDescription'
import BidButton from './auctions/BidButton'
import PaymentButton from './payment/PaymentButton'
import ProductActions from './ProductActions'

export default {
  name: 'AuctionDescription',
  components: { AuctionTimeDescription, AuctionPriceDescription, BidButton, PaymentButton, ProductActions },
  props: {
    product: Object
  },
  data () {
    return {
      bidAmount: null
    }
  },
  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('profile', ['currentUser']),
    author () {
      return this.product.author || {}
    },
    
    isPublisher () {
      return this.author.username === this.currentUser.username
    },
    isBiddable () {
      return !this.product.currentBidder && this.authenticated && !this.isFinished && !this.isPublisher
    },
    isFinished () {
      return this.finishDate.getTime() < new Date().getTime()
    },
    isAcquirable () {
      return this.isFinished && !this.product.acquired && this.product.currentBidder
    },
    finishDate () {
      return new Date(this.product.finishAt)
    }
  }
}
</script>
