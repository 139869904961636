<template>
  <section>
    <section class="titleTab" :class="classTitle">
      <div v-for="(item, id) in attrs" :key="id" class="titleTab-sel">
        <div v-if="item.show" @click="clickTab(item)">
          <icon-button
            v-if="item.icon"
            :type="item.iconType"
            class="icons"
            :class="classSelected(item)"
            :size="22"
            :text="$t(item.title).toUpperCase()"
          />
          <div v-if="!item.icon">
            <span class="no-icons" :class="classSelected(item)">{{
              $t(item.title).toUpperCase()
            }}</span>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import IconButton from '@/components/chat/component/IconButton.vue'
export default {
  name: 'TitleOptionTab',
  components: { IconButton },
  props: {
    attrs: { type: Array },
    defaultSelected: { type: Number, default: 0 },
    forceSelected:{ type: Object}
  },
  data () {
    const tabSelected = this.attrs[0].call
    return {
      tabSelected
    }
  },
  watch:{
    forceSelected(){
      this.tabSelected = this.forceSelected.call
    }
  },
  computed: {
    classTitle () {
      return {
        'space-around': this.attrs.length > 0,
        'dark': this.$vuetify.theme.dark
      }
    }
  },
  mounted () {
    this.clickTab(this.attrs[this.defaultSelected])
  },
  methods: {
    classSelected (item) {
      return {
        selected: this.tabSelected === item.call,
        dark: this.$vuetify.theme.dark
      }
    },
    clickTab (item) {
      this.$emit('selectTab', item.call)
      this.tabSelected = item.call
    }
  }
}
</script>
<style lang="scss" scoped>
.titleTab {
  display: flex;
  margin: auto;
  font-size: 12px;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  background-color: #dfe3e9;
  &.dark {
    background-color: #9b9b9b3b !important;
  }
}
.titleTab-sel {
  padding: 3px;
  &:hover {
    // background-color: var(--v-primary-lighten5);
    border-radius: 20px;
  }
}
.space-around {
  justify-content: space-around;
  height: 40px;
  align-items: center;
}
.selected {
  padding: 3px;
  font-weight: bold;
  color: var(--v-primary-base);
  filter: invert(32%) sepia(61%) saturate(1889%) hue-rotate(321deg)
    brightness(89%) contrast(96%);
  /*   color: #009eff; */
}

.icons {
  display: flex;
}
.no-icons {
  display: flex;
  padding: 3px 6px;
  cursor: pointer;
}
</style>
