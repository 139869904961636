<template>
  <v-row align="center">
    <v-col :cols="this.isMobile ? 12 : 4">
      {{ withdrawData.fullName }}
    </v-col>
    <v-col :cols="this.isMobile ? 12 : 2">
      {{ $t(`withdraws.withdraw_methods.types.${method.type}`) }}
    </v-col>
    <v-col :cols="this.isMobile ? 4 : 2">
      {{ method.currency }}
    </v-col>
    <v-col :cols="this.isMobile ? 4 : 2">
      {{ `${method.accountId.substring(0, 13)}...` }}
    </v-col>
    <v-col :cols="this.isMobile ? 4 : 2">
<!--      <v-btn icon
             @click="showDialog" v-if="!isTransferWise">
        <v-icon>edit</v-icon>
      </v-btn>-->
      <v-btn icon
             color="red"
             @click="deleteMethod">
        <v-icon>delete_forever</v-icon>
      </v-btn>
    </v-col>
    <WithdrawMethodDialog v-model="dialog" :method="method" is-edit/>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { WithdrawActions } from '@/store'
import dialog from '@/components/mixins/dialog'
import WithdrawMethodDialog from '@/components/withdraws/WithdrawMethodDialog'

export default {
  name: 'WithdrawMethods',
  mixins: [dialog],
  components: { WithdrawMethodDialog },
  props: {
    method: Object
  },
  computed: {
    ...mapState('withdraws', ['withdrawData', 'withdrawMethods']),
    ...mapState('application', ['isMobile']),
    isTransferWise () {
      return this.withdrawMethods[0].type === 'transfer_wise'
    }
  },
  methods: {
    deleteMethod () {
      this.$store.dispatch(WithdrawActions.DeleteMethod, this.method.id)
    }
  }
}
</script>
