import api from '@/api/Api'

export default {
  list () {
    return api.get('/categories')
  },
  create (category) {
    return api.post('/categories', category)
  },

  update (category) {
    return api.put(`/categories/${category.id}`, category)
  },

  delete (id) {
    return api.delete(`/categories/${id}`)
  },

  listUsedByUser(username) {
    return api.get('/categories/by_user', { username })
  }
}
