<template>
  <section>
    <h1>GENERAL CONTRACTING TERMS FOR USERS</h1>
    <br>
    <h2>1. IDENTIFICATION</h2>

    <ul>
      <li><b>Owner</b>: ARTEMISA3000 TECH SOLUTIONS, S.L. (hereinafter, “ARTEMISA”)</li>
      <li><b>Registered Office</b>: Calle Goya nº47, Planta 7, Pta. 12, 28001 Madrid (Spain</li>
      <li><b>Tax ID. No.</b>: B-88466743</li>
      <li><b>Public Registry</b>: [29 de Agosto de 2019, número 1741 de protocol, inscrita en el Registro Mercantil de
        Madrid, tomo 39620, folio 10, hoja número M-703152, inscripción primera]
      </li>
      <li><b>Telephone</b>: +34656897133</li>
      <li><b>E-mail</b>: <a :href="`mailto:${project.locales.contact_email}`"
                            target="_blank">{{ project.locales.contact_email }}</a></li>
    </ul>
    <br>

    <h2>2. OBJECT</h2>
    <p>
      This document sets forth the terms and conditions (hereinafter, the <b>"Terms"</b>) governing the contracting of
      digital content and/or physical products (hereinafter, the <b>"Contents"</b> and the <b>"Products"</b>) offered by
      content creators registered for such purpose on the platform located at the URL <a :href="project.locales.site"
                                                                                         target="_blank">{{ project.locales.site }}</a>
      (hereinafter, the <b>"Platform"</b>).
    </p>
    <br>
    <p>
      By checking the corresponding box during the contracting procedure, as well as following all the steps of such
      procedure, constitutes the express acceptance of these Terms by the User, being equally valid as their personal,
      handwritten or digital signature. Thus, the User represents being a person with sufficient capacity to acquire the
      obligations arising from their actions within the Platform, having previously read and understood the contents
      herein.
    </p>
    <br>
    <p>
      Any matter not expressly provided for in these Terms shall be understood to be reserved to
      {{ project.locales.project_upper }}, without
      prejudice to any applicable law that may be in force.
    </p>
    <br>
    <p>
      Likewise, {{ project.locales.project_upper }} reserves the right to carry out modifications and/or updates to
      these Terms, which shall be
      previously notified to the User. Upon notification, and in any case, if the User continues using the Platform and
      contracting Contents and/or Products through the same after the entry into force of such modifications, it shall
      be expressly understood that the User accepts such modifications and/or updates.
    </p>
    <br>
    <p>
      If any modifications and/or updates are accepted by the User, the new Terms shall fully replace the Terms in force
      up to that moment and shall become effective as of their implementation date.
    </p>
    <br>
    <p>
      The contracting process is available in Spanish and English, as chosen by the User through the Platform settings.
    </p>
    <br>
    <br>

    <h2>3. CONTRACTING CONTENTS AND PRODUCTS</h2>
    <p>
      On the Platform, the User can access different audiovisual content (hereinafter, the <b>“Contents”</b>) and
      physical or digital products (hereinafter, the <b>“Products”</b>) published on the Platform by content creators
      who are registered herein (hereinafter, the<b>“Content Creator”</b>).
    </p>
    <br>
    <p>
      The Contents published by each Content Creator may be available to the User in different modalities, either
      through payment of the corresponding fee, or free of charge:
    </p>
    <br>
    <ul>
      <li><b>Free Content:</b> published by the Content Creators for their open access by all Platform Users without a
        need to contract them. Any User (anonymous or registered) can view them, but only registered Users can only
        interact with these Contents (such as leaving comments or contacting the Content Creator).
      </li>
      <li><b>Paid Content:</b> made available for purchase to Users, who may expressly contract them. They can be
        purchased through the following methods:
        <ul>
          <li>i: <u>Subscription</u>: Users of the Platform may purchase subscriptions to Content Creators of their
            choice, which implies that they will be able to access all the Content that the Content Creator has reserved
            for this modality, without prejudice to the possibility of offering "premium" Content (i.e., exclusive
            access outside of the Subscription Content). Subscriptions are monthly by default, although the Content
            Creator may set different periodicities (per quarter, per semester and per annum). Subscriptions will
            automatically renew at their expiration date, on a date-to-date basis, unless the contracting User cancels
            their Subscription as indicated in Clause 4.2.
          </li>
          <li>ii: <u>Individually paid content</u>: Users of the Platform may purchase access to certain paid Content on
            a "pay-per-view" or “pay-per-access” basis. Said Content will be added to the User's "media library", where
            they will be able to access it upon contracting it. Contracting each Content under this modality will give
            the User access to the Content in question, without limitation, until the User unsubscribes from the
            Platform.
          </li>
        </ul>
      </li>
    </ul>
    <br>
    <p>
      The Products are offered by the Content Creators themselves through the corresponding section within their
      profiles. The publication of Products for sale at any given time shall remain at the discretion of each Content
      Creator. These Products may consist of:
    </p>
    <br>
    <ul>
      <li><b>Physical Products</b>: second-hand physical goods that each Content Creator may offer for sale in their
        respective profile. The price of these Products will be set by the Content Creator and their contracting will be
        carried out through the procedure enabled in the Platform, making the payment through the system offered by the
        Platform itself. In case of doubt, the User may contact the Content Creator through the internal messaging
        system of the Platform
      </li>
      <li><b>Digital Products</b>: shall refer to specific digital content that the Content Creator offers on an
        exclusive basis, apart from those published on the Platform for the generality of Users. These digital Products
        may be created in a personalized manner for the User who contracts them, and their conditions may be agreed
        directly between the Content Creator and the User through the private messaging channel of the Platform.
        {{ project.locales.project_upper }} will not assume any role in the negotiations between both parties,
        notwithstanding that the contract
        shall be formalized through the Platform by means of the contracting procedure enabled therein
      </li>
    </ul>
    <br>
    <p>Furthermore, if the Content Creator has enabled this option, the User may interact with each Content Creator
      through the Platform’s private messaging channel. The use of this channel between the User and a Content Creator
      may be subject to the payment of a fee for each message sent, the amount of which shall be set by each Content
      Creator at their own discretion. Such price must be paid by the User at the time of sending each message, using
      the funds previously loaded in the User's account.</p>
    <br>
    <br>

    <h2>4. PAYMENT AND INVOICING TERMS</h2>
    <h3>4.1. Prices</h3>
    <p>
      The price of each Content and Product marketed on the Platform is set by the Content Creator themselves and will
      be indicated in the profile header of each creator . The price shown does not include Value Added Tax (VAT), which
      will be expressly added during the contracting procedure.
    </p>
    <br>
    <p>
      Regarding the Contents, the final price of each purchase will include all applicable increments or discounts,
      expenses charged to the User, and additional costs corresponding to technology costs, accessory services, means of
      payment, etc., if any. All these amounts will be shown to the User in an itemized manner during the contracting
      procedure, which shall also express the total price.
    </p>
    <br>
    <p>
      With regards to the Products, the Content Creators themselves will agree with the User the terms of acquisition of
      a Product, which implies that the purchase terms may differ from those applicable to Contents and/or published by
      the Content Creator for general purchases of their Products. However, any flexibility as to such terms will be at
      the Content Creator’s sole discretion.
    </p>
    <br>
    <br>
    <h3>4.2. Payment and invoicing</h3>
    <h4>4.2.1. Contents</h4>
    <p>
      When the User purchases Contents through the Platform, they shall pay the corresponding price through any of the
      payment methods available on the Platform at that time.
    </p>
    <br>
    <p>
      Once payment has been confirmed, the Platform will send the User an email containing all the information relating
      to the contract: the purchased Content, its unit price, applicable taxes, as well as the total price of the
      contract and, in the case of Subscriptions, their duration and the frequency with which payments will occur.
    </p>
    <br>
    <p>
      In the case of Subscriptions, the price of the Subscription purchased by the User will be charged in accordance
      with the periodicity indicated in the chosen subscription plan, up until the User decides to cancel it, which may
      be done at any time before its renewal date. If you cancel your Subscription, you may continue to access the
      Contents included in the Subscription until expiration of the then-current Subscription term. To cancel a
      Subscription, the User must access the "My Subscriptions" section of their profile on the Platform, where they can
      cancel each Subscription individually by clicking on the "Subscribe/Cancel" button. It will also be possible to
      cancel active Subscriptions through the payment processing platform <a href="www.marketbilling.net"
                                                                             target="_blank">www.marketbilling.net</a>,
      property of ARTEMISA3000 TECH SOLUTIONS, S.L., through which the payments are processed and from whom the User
      receives the information of each Subscription they acquire.
    </p>
    <br>
    <p>
      If at the time of debiting, the User’s payment method is not available or the User does not have sufficient funds
      for the corresponding payment, successive daily debit attempts will be made for a maximum period of ten (10)
      calendar days from that date. If, within such period, the charge cannot be made due to lack of funds, the User
      will be automatically unsubscribed. From the first unsuccessful charge attempt, the User's access to the
      Subscription affected by the lack of payment will be suspended until the charge has been successfully completed or
      until the cancellation occurs as indicated, whichever occurs first
    </p>
    <br>
    <p>
      If, after such period, the User has not proceeded with the payment, {{ project.locales.project_upper }} will
      terminate the contract, ending
      said User’s Subscription, without prejudice that, if this situation is recurrent,
      {{ project.locales.project_upper }} may suspend the User's
      access to the Platform.
    </p>
    <br>
    <p>
      Payments will be processed through the platform <a href="https://www.marketbilling.net" target="_blank">https://www.marketbilling.net </a>,
      property of ARTEMISA3000 TECH SOLUTIONS, S.L., which is hosted under an SSL secure protocol and to which the User
      will be redirected during the contracting procedure. In any case, although this platform will issue the
      corresponding confirmations, the processing of the User's cards will be carried out by the corresponding external
      financial entity.
    </p>
    <br>
    <p>
      In any case, the contracting will only be effective when {{ project.locales.project_upper }} receives confirmation
      of payment by the bank
      that owns the secure payment gateway. If the transaction is for any reason denied by that entity, or the full
      amount corresponding to the total sum of the order (including surcharges for handling fees and bank transfer fees)
      is not provided, the transaction will be suspended, and the User will be informed that it has not been processed.
    </p>
    <br>
    <br>
    <h4>4.2.2. Products</h4>
    <p>
      Payment of the price that each Content Creator has set for each Product will occur through the Platform, by the
      means of payment selected by the User from among those offered, although the management of the delivery of the
      purchased Product will be the sole and exclusive responsibility of the Content Creator.
      {{ project.locales.project_upper }} only intervenes
      in the contracting between the User and the Content Creator as manager of the Platform where the payment is
      channeled, but the contracting itself is completely unrelated to {{ project.locales.project_upper }} who,
      therefore, assumes no liability
      whatsoever regarding said contracting.
    </p>
    <br>
    <br>

    <h2>5. CONTRACTING PROCEDURE</h2>
    <p>
      In accordance with the provisions of Article 23 of the Spanish Law 34/2002, of July 11, 2002, on information
      society services and electronic commerce, contracts entered into electronically shall produce all the effects
      provided for by law when the consent and other requirements necessary for their validity are met.
    </p>
    <br>
    <p>
      In any case, the electronic medium containing the contract concluded by electronic means shall be admissible as
      documentary evidence in the event of a dispute between the parties.
    </p>
    <br>
    <p>
      To these effects, it will be understood that following all the phases of the contracting procedure and the payment
      of the corresponding Price necessarily implies the provision of the consent required for the contracting.
    </p>
    <br>
    <p>
      Similarly, and in accordance with the provisions of Article 27 of the Spanish Law 34/2002, on information society
      services and electronic commerce, all information relating to the contracting procedure is made available to Users
      prior to initiating the contracting procedure, although it shall only apply if the User decides to proceed with
      the contracting through the Platform.
    </p>
    <br>
    <br>

    <h3>5.1. Precontractual Information</h3>
    <p>
      Access to the contracting process is completely free, without any additional associated cost, apart from those
      inherent to the User having an Internet connection.
    </p>
    <br>
    <p>
      All paid Content and Products available within the Platform are described in their corresponding sections, not
      being understood to include those items that have not been expressly indicated therein.
    </p>
    <br>
    <p>
      The contracting of Content and Products in any mode of contracting as described in section 3 ("Contracting Content
      and Products") of these Terms must be made by expressly accepting this document and, where appropriate, any
      specific terms that may be applicable to any Content or Product in particular, in which case it will be specified
      in the corresponding section.
    </p>
    <br>
    <p>
      The contract between {{ project.locales.project_upper }} and the User is understood to be perfected from the
      moment the User completes the
      contracting procedure by pressing the button << PAY NOW >>. Following all the phases of the electronic contracting
      procedure and including all the requested data, together with checking the corresponding box relating to
      acceptance of these Terms, shall be understood as a direct manifestation of the User's will to accept these Terms.
    </p>
    <br>
    <p>
      {{ project.locales.project_upper }} will file the electronic document in which this contract is formalized. This
      electronic document will be
      accessible to the User by e-mail to the address associated with the profile of the User in question and shall
      include these Terms.
    </p>
    <br>
    <p>
      {{ project.locales.project_upper }} will introduce adequate and sufficient means to identify and correct technical
      errors in the management
      of information, up to the extent of our responsibility.
    </p>
    <br>
    <p>
      The language in which the contracting procedure will be carried out, and in which the contract is formalized, will
      be either Spanish or English, at the User's choice through the Platform’s settings.
    </p>
    <br>
    <br>

    <h3>5.2. Contracting Procedure</h3>
    <p>
      The contracting process for any paid Content or Product is carried out electronically through the Platform. The
      User’s prior registration within the Platform is required in all cases.
    </p>
    <br>
    <p>
      The complete process to be followed by all Users wishing to purchase any Content or Products subject to payment as
      offered by the Content Creators is as follows:
    </p>
    <br>
    <ol>
      <li>
        <p>The User must log in to their account and select the Content they wish to purchase (either a subscription to
          a Content Creator or a specific Content), considering the conditions and prices established by the Content
          Creator in their profile.</p>
        <br>
        <p>In the case of Products, once the User has viewed and selected the Product they wish to purchase (whether
          physical or digital), if the User is unsure about the applicable conditions, they may contact the
          corresponding Content Creator to resolve any doubts the User may have. Such interaction will occur through the
          Platform’s internal messaging channel, although the eventual hiring and payment will be carried out via the
          Platform’s technical means.</p>
        <br>
      </li>
      <li>Once this has been completed, the electronic purchase process must be initiated by clicking on the
        corresponding button.
      </li>
      <li>
        <p>To proceed with the payment, the User must enter the data relating to the available form of payment that the
          User wishes to use (in case that there is more than one). At no time {{ project.locales.project_upper }} nor
          the Content Creator will
          have access to the User's bank details, which will be managed directly by the corresponding banking entities
          in the payment gateway</p>
        <br>
        <p>In addition, in the case of acquisition of Products, the User must provide the data requested, which will
          include in any case name, surname, email and address. The data relating to shipment of the purchased Product
          shall be provided directly to the Content Creator through the Platform's private messaging channel.</p>
        <br>
      </li>
      <li>Before confirming the purchase, the User must read and expressly accept these Terms by checking the
        appropriate box provided for this purpose.
      </li>
      <li>Once the purchase is completed, a confirmation screen will be displayed. The User will automatically receive
        an e-mail confirming that the purchase has been successful. Said e-mail will describe the purchase, as well as
        its characteristics (price, duration of the subscription, if applicable) and complete details of the person
        processing the purchase. This document will serve as evidence in the event of a claim. If the User does not
        receive such email, they should check the "junk mail" or "spam" folder and, if not found in that section, the
        User should contact {{ project.locales.project_upper }} as soon as possible
      </li>
      <li>The User may manage their active subscriptions in the control panel of their user account on the Platform,
        through which, upon identification, they may cancel any one of them as appropriate. The User will receive
        detailed information of each purchase by email, but it can also be accessed in <a
          href="https://www.marketbilling.net" target="_blank">https://www.marketbilling.net</a>, the platform through
        which payments are processed.
      </li>
      <li>
        <p>In the case of Content, the purchased Content will be available to the User from the moment the corresponding
          payment is processed.</p>
        <br>
        <p>In the case of purchase of Products, physical or digital, for which delivery requires an action by the
          Content Creator, {{ project.locales.project_upper }} will transfer the information related to the purchase to
          the corresponding Content
          Creator so that they can, where appropriate, manage the delivery of the purchased Product, whether this should
          be done electronically (in the case of Products of a digital nature) or via postage (in the case of Products
          that constitute physical goods). To this end, the User must provide the Content Creator with their shipping
          data (telematic or postal) to manage the shipment or delivery, as described in the following section
          ("Delivery")</p>
        <br>
      </li>
    </ol>
    <br>
    <h2>6. DELIVERY</h2>
    <p>Once the corresponding payment confirmation has been received, the Contents or Products acquired by the User will
      be delivered as follows:</p>
    <br>
    <ul>
      <li>Contents: the Contents that the User acquires as part of a Subscription or under a pay-per-view or
        pay-per-access mode will be automatically enabled for viewing by the User in the corresponding Content Creator
        profile in the case of subscription, in the "My Purchases" section of their user account in the case of
        individual purchases of contents (PPV/PPA) within the User's own profile or in the history of files exchanged
        through the instant messaging system if the Content has been purchased and/or delivered through this means. In
        the case of exclusive Content made specifically for the User, it will be delivered by the Content Creator
        through the means agreed upon by the User and the Content Creator at the time of agreeing on the characteristics
        of such exclusive Content via the internal messaging channel.
      </li>
      <li>
        <p> Products: Products that a User may purchase from a Content Creator will be delivered by such Content Creator
          to the address (postal or e-mail, depending on the nature of the purchased Product) that the User has
          indicated for this purpose in the contracting procedure.</p>
        <br>
        <p>In the case of digital Products (e.g., photo or video packs), they will be delivered via email or private
          message on the Platform.</p>
        <br>
        <p>In the case of physical Products, their delivery may be limited to certain territories. To determine whether
          a physical Product is available for shipment to the User's address, the system will use the country associated
          with the User's profile (indicated by the User during the registration process). If available, the purchase of
          such Product will be enabled for the User and, if selected, the User will be redirected to check-out to pay
          the price of the Product, to which the corresponding shipping costs will be added depending on the country to
          which the Product is to be shipped. Once the payment has been made, the Content Creator will contact the User
          through the Platform's private messaging system to agree on the address to which the purchased Product should
          be delivered.</p>
        <br>
        <p>If delivery to the User's country is not available, the User will be invited to contact the Content Creator
          directly to arrange a possible sale and delivery of the Product.</p>
        <br>
      </li>
    </ul>
    <br>

    <h2>7. RIGHT OF WITHDRAWAL</h2>
    <p>The User acknowledges and accepts that, given the nature of the goods and services offered on the Platform, there
      will be no right of withdrawal in their favor in accordance with the provisions of the Spanish Royal Legislative
      Decree 1/2007 of 16 November, approving the revised text of the General Law for the Protection of Consumers and
      Users and other complementary laws.</p>
    <br>
    <p>Regarding the Contents:</p>
    <br>
    <ol>
      <li><u>In Subscription or Pay-per-Content modality</u>: Access to the Contents will be automatically enabled,
        which implies that execution of the contract will begin from the moment the payment is made and, consequently,
        there will be no right of withdrawal in favor of the contracting User under Article 103.m) of the Spanish Royal
        Legislative Decree 1/2007;
      </li>
      <li><u>Custom Contents made for the User</u>: there shall be no right of withdrawal in accordance with article
        103.c) of the Spanish Royal Legislative Decree 1/2007
      </li>
    </ol>
    <p>On the other hand, with respect to the Products that may be offered by the Content Creators and that are acquired
      by the User:</p>
    <br>
    <ul>
      <li><u>Digital Products</u>: unless the Content Creator agrees otherwise with the User or offers other conditions,
        there will generally be no right of withdrawal with respect to Products of a digital nature, on the same terms
        as indicated in the preceding paragraphs for Subscription, Pay-per-Access or Custom Content;
      </li>
      <li><u>Physical Products</u>: given its nature, there will be no right of withdrawal for the User for reasons of
        health protection and hygiene. In any case, if the User has the right to withdraw from the contract in
        accordance with the Spanish Royal Legislative Decree 1/2007, this right must be exercised before the Content
        Creator itself.
      </li>
    </ul>
    <br>

    <h2>8. RETURNS AND REFUNDS</h2>
    <p>By default, the User shall not have the right to request any refund or reimbursement for purchases made on the
      Platform, except in the case of duplicate charges by the payment gateway. {{ project.locales.project_upper }} will
      evaluate each case
      individually.</p>
    <br>
    <p>To request a refund in appropriate cases, the User must send an email to {{ project.locales.contact_email }}
      providing all
      information relating to the undue charge and providing evidence from their bank account, such as duplicate
      payment. {{ project.locales.project_upper }} will resolve these claims in less than 7 days from the last
      communication.</p>
    <br>
    <p>If {{ project.locales.project_upper }} approves a refund, it will be made to the same card from which the
      purchase was made by the User,
      within 10 working days from confirmation of the refund. No refunds will be made by other means, such as checks or
      cash.</p>
    <br>
    <br>

    <h2>9. USER RESPONSABILITIES</h2>
    <p>The User shall be responsible for:</p>
    <br>
    <ol>
      <li>Any act that contravenes the content of these Terms and/or any other conditions in force on the Platform, the
        law, morality, customs or generally accepted social norms and public order
      </li>
      <li>Any damage or loss that may result from misuse, negligence or breach by the User in their use of the Platform
        and/or the Content or Products, including, but not limited to, these Terms, applicable law and/or any
        instructions, guidelines or recommendations made by {{ project.locales.project_upper }}.
      </li>
      <li>The accuracy and completeness of the information provided by the User in accepting and executing the Terms
      </li>
      <li>Compliance with these Terms, including the payment of the corresponding Prices.</li>
    </ol>
    <p>The User agrees to indemnify, defend and hold {{ project.locales.project_upper }} harmless, including its
      officers, directors,
      shareholders, successors, affiliates, employees, agents and representatives, from and against any costs, claims,
      demands, liabilities, expenses, losses, damages, including attorneys' fees, arising or resulting from the use of
      the Platform and the Content and Products purchased through the Platform by the User. The User also agrees to
      indemnify {{ project.locales.project_upper }} for legal fees incurred by the latter, acting reasonably, in the
      investigation or enforcement
      of its rights under these Terms</p>
    <br>
    <br>

    <h2>10. {{ project.locales.project_upper }}’ LIMITED LIABILITY</h2>
    <p>The User acknowledges and accepts that {{ project.locales.project_upper }}’ only liability with respect to the
      contracts that Users make
      through the Platform will be in relation to the management of the same and the intermediation in the payment of
      the Contents and Products.</p>
    <br>
    <p>In this regard, in addition to what is described in the Legal Notice, {{ project.locales.project_upper }} is not
      liable for:</p>
    <br>
    <ul>
      <li>The Content published by the Content Creators on the Platform, including all the elements and components that
        integrate them;
      </li>
      <li>The Products that the Content Creators may offer for sale through the sections enabled for this purpose;</li>
      <li>The management, execution and delivery of contracts made by the User directly with a Content Creator, for
        example, for the purchase of a Product, including, among others, the management of the shipment in the case of
        physical Products;
      </li>
      <li>The content of messages that may be exchanged by the User with Content Creators.</li>
    </ul>
    <p>{{ project.locales.project_upper }} cannot guarantee the reliability, lawfulness and usefulness of the contents
      published by third parties
      through the Platform, including but not limited to the Contents themselves and any other contents, information,
      texts (e.g., comments), among others, and consequently shall not be liable for them. This also applies to
      information contained on other platforms or websites that are linked to from the Platform.
      {{ project.locales.project_upper }} is not liable
      for any damages that may arise from the use of such information. If the User becomes aware of the existence of any
      content that is illegal, unlawful or infringing upon the rights of third parties, they must immediately notify
      {{ project.locales.project_upper }} so that appropriate measures can be taken.</p>
    <br>
    <p>If the User becomes aware of the publication of any inaccurate, outdated or false information or of the existence
      of any illicit, illegal or unlawful content, or content that could involve an infringement of intellectual
      property rights, applicable regulations on the protection of personal data and/or of any other right, they must
      immediately notify {{ project.locales.project_upper }} so that we can proceed to adopt the appropriate
      measures.</p>
    <br>
    <br>

    <h2>11. TERM AND TERMINATION</h2>
    <p>These Terms shall enter into force between {{ project.locales.project_upper }} and the User from the moment the
      User expressly accepts them
      by checking the corresponding box during the contracting procedure of any Content or Product that is subject to
      payment as available on the Platform, and shall remain in force as long as the User continues to make use of any
      such Content or Products, until terminated by either party in accordance with the terms hereof.</p>
    <br>
    <p>However, certain provisions hereof are expressly or impliedly intended to continue in effect after termination or
      expiration, thus they shall remain in effect and continue to be enforceable against the User in accordance with
      their terms even when these Terms are no longer in effect.</p>
    <br>
    <p>The User acknowledges that the contracting of certain Contents and the acquisition of Products entails the
      payment of the corresponding Price, which will depend on the type of contracting selected (subscription, PPV or
      purchase). Failure to pay the corresponding Price will mean that the User will not have access to the selected
      Content or Product, thus the User may only access those who have been effectively paid, in addition to any Content
      that is made available for free on the Platform.</p>
    <br>
    <p>Additionally, the User acknowledges that these Terms may be terminated early and, therefore, any Subscription,
      Purchase or Acquisition in force may be revoked in the following circumstances:</p>
    <br>
    <ul>
      <li><u>Termination for convenience</u>. The User may terminate the contract at any time prior to the expiration of
        a billing period. If termination occurs halfway during any billing period, the User may consume the remaining
        term of his Subscription until the expiration of the corresponding Subscription Period.
      </li>
      <li><u>Termination due to breach</u>. {{ project.locales.project_upper }} may terminate the contract in case of
        breach of these Terms by the
        User and, in particular, in case of delay in the payment of the Price of any Content or Product contracted by
        the User, notwithstanding that {{ project.locales.project_upper }}, in its discretion and taking into account
        the nature of the breach,
        may grant the User a maximum period to remedy the breach. {{ project.locales.project_upper }} reserves the right
        to suspend the User's
        access to the Platform (or any affected Content) during the cure period. In any case, such termination will not
        exempt the User from paying the Price that would have been pending payment
      </li>
      <li><u>Termination due to improper us</u>. {{ project.locales.project_upper }} may suspend or terminate the
        contract with a User in the
        event of actual knowledge of misuse or use that is contrary to the provisions of these Terms, as well as in the
        case of receiving a notice or notification by which {{ project.locales.project_upper }} is informed that any use
        by a User may constitute
        a criminal or unlawful activity under the laws in force in Spain or in the User’s country of origin.
      </li>
      <li><u>Termination due to interruption or suspension of the Platform</u>. In the event that
        {{ project.locales.project_upper }} decides to
        interrupt or suspend the activity of the Platform, we may terminate the contract.
      </li>
    </ul>
    <br>

    <h2>CONFIDENTIALITY</h2>
    <p>{{ project.locales.project_upper }} may access confidential information shared by Users, in order to provide the
      services of the Platform
      and/or manage the contractual relationship with them. In the event that this occurs, we undertake to: (i) use the
      confidential information received solely and exclusively for the fulfillment of these Terms and the provision of
      the contracted services; (ii) strictly keep the secrecy of confidential information, avoiding its disclosure or
      provision, in whole or in part, to any third party without the prior and express consent of the User; (iii) treat
      the confidential information with the utmost discretion and use it solely and exclusively in connection with the
      provision of services; (iv) implement the security measures reasonably necessary for the purpose of complying with
      this clause</p>
    <br>
    <p>The above confidentiality obligations shall not apply with respect to information that: (i) is in the public
      domain or was made public by the User; (ii) was lawfully obtained from third parties by
      {{ project.locales.project_upper }} without
      restriction for its disclosure; (iii) was developed independently and legitimately by
      {{ project.locales.project_upper }}; and (iv) was
      required by a judicial or administrative authority in the exercise of its competence and only to the extent that
      the disclosure of the information was necessary to comply with that requirement.</p>
    <br>
    <br>

    <h2>13. MISCELLANEOUS</h2>
    <p>The headings of the different clauses are merely informative and do not affect, qualify or encourage the
      interpretation of these Terms.</p>
    <br>
    <p>All the clauses or matters in these Terms must be interpreted independently. In the event that any clause is
      declared null by judicial or arbitral resolution, the rest of the clauses shall not be affected and will remain in
      full force and effect. The null clause will be replaced by another that preserve the effects pursued by this
      document</p>
    <br>
    <p>{{ project.locales.project_upper }} may modify the terms and conditions set forth herein, in whole or in part, at
      any time. Changes will be
      posted on the Platform in a manner similar to that in which these Terms are available and, in the case of
      affecting current Subscriptions or Contracts, by email.</p>
    <br>
    <br>

    <h2>14. GOVERNING LAW AND COMPETENT JURISDICTION</h2>
    <p>These Terms, including the use that the User makes of the Platform and the Content and Products offered on it are
      governed by the applicable Spanish legislation in force at any given time.</p>
    <br>
    <p>In case of controversy, action or claim that may arise in relation to the interpretation and application of these
      Terms, the User and {{ project.locales.project_upper }} submit to the exclusive jurisdiction of the Courts of the
      city of Madrid (Spain)
      with express and voluntary waiver of any other jurisdiction, unless otherwise provided by a mandatory legal
      provision.</p>
    <br>
    <p>In any case, the User may resort to the European Online Dispute Resolution (ODR) Platform through the following
      link: <a href="https://ec.europa.eu/consumers/odr/main/index.cfm" target="_blank">https://ec.europa.eu/consumers/odr/main/index.cfm</a>
    </p>
    <br>
  </section>
</template>

<script>
import project from '@/project'

export default {
  name: 'EN',
  data() {
    return {
      project
    }
  }
}
</script>

<style scoped>

</style>
