<template>
<section>
  <v-row>
    <v-col cols="12" sm="6">
      <c-img class="rounded thumb"
        :src="previewRoute">
      </c-img>
    </v-col>
    <v-col cols="12" sm="6">
      <UserAvatar :profile="false" :user="product.author" />
      <div class="mt-1 semibold">
        {{ product.name }}
      </div>
      <div>
        {{ product.description }}
      </div>
      <div class="mt-4 mb-1 semibold">
        {{ $t('payments.info.product_price', { price: $currency(specialOffer(product.price)) }) }}
      </div>
      <div v-if="product.shippingCost"
        class="my-1 semibold">
        {{ $t('payments.info.shipping_price', { price: $currency(product.shippingCost) }) }}
      </div>
      <VatExplain :price="vatPrice" />
      <div v-if="reservedAmount"
        class="my-1 semibold">
        {{ $t('payments.info.reserved_amount', { amount: $currency(reservedAmount) }) }}
      </div>
      <div class="mt-1 mb-4 semibold">
        {{ totalPriceText }}
      </div>
    </v-col>
    <v-col cols="12">
      <PaymentSource
        v-model="paymentType"
        :price="totalPrice"
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" class="d-flex">
      <v-checkbox outlined
                  color="primary"
                  v-model="confirmPayment"
                  :rules="[$vBoolRequired]"></v-checkbox><span v-html="$t('models.payment.accept', {contract: getContractCondition, support: getSupportMail, period: getPeriodSelected, amount: getAmount })"></span>
    </v-col>
  </v-row>
  <v-card-actions>
    <v-spacer />
    <c-btn
      text color="secondary"
      @click="close">
        {{ $t('actions.close') }}
    </c-btn>
    <c-btn
      :disabled="!paymentType || !confirmPayment"
      @click="next">
      {{ $t('actions.next') }}
    </c-btn>
  </v-card-actions>
</section>
</template>
<script>
import { mapState } from 'vuex'
import UserAvatar from '@/components/users/UserAvatar'
import PaymentSource from '@/components/payments/forms/partials/PaymentSource'
import project from '@/project'
import VatExplain from "../../payments/VatExplain";
import config from "@/project";

export default {
  name: 'ProductInfoForm',
  components: {VatExplain, UserAvatar, PaymentSource  },
  props: {
    product: Object,
    vatPostPrice: Number
  },
  data () {
    return {
      paymentType: '',
      error: false,
      errorMessage: '',
      confirmPayment: false,
      project
    }
  },
  computed: {
    ...mapState('application', ['billing']),
    ...mapState('profile', ['currentUser']),
    getAmount () {
      return `${this.$currency(this.product.price)} + ${this.$currency(this.vatPostPrice)}`
    },
    getContractCondition () {
      return `/contract-conditions`
    },
    getSupportMail () {
      return `soporte@${this.project.project}.com`
    },
    getPeriodSelected () {
      return this.$t('models.payment.unique')
    },
    vatPriceText () {
      const key = this.billing.code === 'CA' ? 'payments.info.vat_ca_price' : 'payments.info.vat_price'
      const price = this.$currency(this.vatPrice, this.product.currency)
      return this.$t(key, { price })
    },

    isWallet () {
      return this.paymentType === 'wallet'
    },
    vatPrice () {
      const price = this.product.price + this.product.shippingCost
      let vat = this.currentUser.billing.vat / 100
      let expenses = (price * this.currentUser.billing.exes) / 100
      const vatAmount = (price + expenses) * vat

      return Math.ceil(vatAmount + expenses)
    },
    totalPrice () {
      return this.specialOffer(this.product.price) + this.product.shippingCost + this.vatPrice - this.reservedAmount
    },
    totalPriceText () {
      return this.$t('payments.info.total_price', { price: this.$currency(this.totalPrice, this.product.currency) })
    },
    reservedAmount () {
      const bid = this.product.bid || {};
      return bid.reservedAmount || 0;
    },

    previewRoute () {
      return this.product.resources && this.product.resources[0].preview
    }
  },
  methods: {
    specialOffer (amount) {
      if (config.specialOffer.active && config.specialOffer.startDate < new Date() && config.specialOffer.endDate > new Date() && config.specialOffer.discount > 0) {
        return amount * (100 - config.specialOffer.discount) / 100
      }
      return amount
    },
    close () {
      this.$emit('close')
    },
    next () {
      this.$emit('next', this.paymentType)
    }
  }
}
</script>
<style lang="scss" scoped>
.thumb {
  height: 224px;
  width: 224px;
  max-height: 224px;
  max-width: 224px;
  .v-image__image {
    filter: blur(1rem);
  }
}
</style>
