<template>
  <div class="back-row">
    <c-btn text v-bind="$attrs" :color="color" @click.stop="backClicked">
      <v-icon>arrow_back</v-icon>
      <span v-if="!isMobile && showLabel"> {{ $t('actions.back') }} </span>
    </c-btn>
    <slot></slot>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'BackRow',
  computed: {
    ...mapState('application', ['isMobile'])
  },
  props: {
    color: {
      type: String,
      default: 'secundary'
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    backClicked () {
      if (!this.$listeners.back) {
        this.$router.back()
      }
      this.$emit('back')
    }
  }
}
</script>
<style lang="scss" scoped>
.back-row {
  height: 64px;
  display: flex;
  align-items: center;
}
</style>
