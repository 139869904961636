<template>
  <section>
    <ListView
      cols="12"
      no-gutters
      :loading="loading"
      :items="items"
      align="start"
      justify="start"
      @bottom-reached="loadItems">
      <template #header>
        <ListViewTitle :title="$t('notifications.title')" />
        <c-dialog persistent
          width="684"
          class="publication-dialog"
          v-model="dialog"
          :actions="false">
          <template #title>
            <h1>{{ 'Usuarios' }}</h1>
          </template>
          <ListView
            cols="12"
            :items="dialogUsers">
            <template #default="{ item }">
              <UserAvatar :username="item" />
            </template>
          </ListView>
        </c-dialog>
      </template>
      <template #empty>
        <h1 class="title-text px-5">{{ $t('listview.no_notifications')}}</h1>
      </template>
      <template #default="{ item, index }">
        <optional-link
          :disabled="!item.link"
          :to="item.link"
          class="no-decoration normal-text pointer"
          @optional-clicked="popDialog(item)">
          <v-list-item class="notification bordered rounded">
            <v-list-item-avatar size="52" class="circle bordered">
              <v-icon :color="notificationColor(item)">{{ notificationIcon(item) }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="notificationText(item)">
              </v-list-item-title>
              <v-list-item-subtitle v-if="isMobile">
                <strong v-html="notificationUsers(item)"></strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <timeago class="roboto small-text" :datetime="item.date"></timeago>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <c-btn icon
                     @click.prevent.stop="remove(item, index)">
                <v-icon color="primary">delete_forever</v-icon>
              </c-btn>
            </v-list-item-action>
          </v-list-item>
        </optional-link>
      </template>
    </ListView>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import dialog from '@/components/mixins/dialog'
import CursorService from '@/components/mixins/cursor_api'
import NotificationApi from '@/api/NotificationApi'
import OptionalLink from '@/components/custom/OptionalLink'
import UserAvatar from '@/components/users/UserAvatar'

const linkableTypes = ['tip', 'new_trial', 'new_subscription', 'new_follower']
const icons = {
  new_trial: { icon: 'stars', color: 'primary' },
  new_subscription: { icon: 'stars', color: 'primary' },
  new_follower: { icon: 'assistant', color: 'green' },
  post_liked: { icon: 'favorite', color: 'pink darken-1' },
  post_saved: { icon: 'bookmark', color: 'deep-purple darken-1' },
  post_shared: { icon: 'cached', color: 'indigo darken-1' },
  new_comment: { icon: 'comment', color: 'blue darken-1' },
  tip: { icon: 'monetization_on', color: 'success' },
  post_published: { icon: 'insert_photo', color: 'teal' },
  post_acquired: { icon: 'insert_photo', color: 'teal' },
  product_published: { icon: 'shopping_basket', color: 'orange' },
  product_acquired: { icon: 'shopping_basket', color: 'orange' },
  product_bidded: { icon: 'shopping_basket', color: 'orange' },
  product_auction_end: { icon: 'shopping_basket', color: 'orange' },
  product_auction_winner: { icon: 'shopping_basket', color: 'orange' },
  comment_mention: { icon: 'chat_bubble', color: 'blue darken-1' },
  post_mention: { icon: 'chat_bubble', color: 'blue darken-1' }
}
const link = (type, resource, resourceId, users) => {
  if (!resourceId) {
    if (linkableTypes.includes(type) && users.length === 1) {
      const username = users[0].replace('@', '')
      return { name: 'influencer', params: { username } }
    } else {
      return null
    }
  }
  return { name: resource, params: { uuid: resourceId } }
}

export default {
  name: 'NotificationsView',
  mixins: [dialog, CursorService(NotificationApi)],
  components: { OptionalLink, UserAvatar },
  computed: {
    ...mapState('application', ['isMobile'])
  },
  data () {
    return {
      dialogUsers: []
    }
  },
  methods: {
    notificationIcon (n) {
      return icons[n.type].icon
    },
    notificationColor (n) {
      return icons[n.type].color
    },
    mapItem (n) {
      const users = n.users.split(',').map(u => `@${u}`)
      let usernames
      let more
      let amount
      if (users.length < 3) {
        usernames = users[0]
        more = users[1]
      } else {
        usernames = users.slice(0, 2).join(', ')
        more = users.length === 3 ? users[2] : this.$t('words.more_count', { count: users.length - 2 })
      }
      if (n.notificationType === 'tip') {
        amount = this.$currency(n.resource)
      }
      return {
        id: n.id,
        type: n.notificationType,
        key: `notifications.${n.notificationType}`,
        users,
        count: users.length,
        usernames: usernames,
        more: more,
        amount: amount,
        date: new Date(n.createdAt),
        link: link(n.notificationType, n.resource, n.resourceId, users)
      }
    },

    notificationText (n) {
      const key = n.key.concat(this.isMobile ? '.mobile' : '.desktop')
      return this.$tc(key, n.count, { usernames: n.usernames, more: n.more, amount: n.amount })
    },

    notificationUsers (n) {
      return this.$tc('notifications.users', n.count, { usernames: n.usernames, more: n.more })
    },

    popDialog (n) {
      if (n.count > 1) {
        this.dialogUsers = n.users.map(u => u.replace('@', ''))
        this.showDialog()
      }
    },

    async remove (n, index) {
      const { data, error } = await NotificationApi.delete(n.id)
      if (!error) {
        this.items.splice(index, 1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.notification {
  margin: 5px 0;
}
</style>
