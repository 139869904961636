<template>
  <v-form class="profile-form border-top"
    ref="form"
    @submit.prevent="submit">
    <c-img class="profile-banner"
      aspect-ratio="4.6" height="131"
      gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75) 99%"
      :src="banner">
      <v-btn icon rounded @click="bannerEdit"><v-icon color="white">camera_enhance</v-icon></v-btn>
    </c-img>
    <div class="profile-avatar circle">
      <v-avatar size="75">
        <c-img height="75"
          placeholder="false"
          :src="avatar">
          <v-btn icon rounded @click="avatarEdit"><v-icon color="white">camera_enhance</v-icon></v-btn>
        </c-img>
      </v-avatar>
    </div>

    <Field
      :title="$t('models.user.displayName')"
      @newValue="saveDisplayName"
      @hasError="displayNameError"
      :rows="1"
      :maxLength="50"
      :showEmojis="false"
      :content="currentUser.displayName"
      :load-default="true"
    ></Field>

    <Field
      :title="$t('models.user.description')"
      @newValue="saveDescription"
      @hasError="descriptionError"
      :rows="4"
      :maxLength="1500"
      :showEmojis="false"
      :content="currentUser.description"
      :load-default="true"
    ></Field>

    <!--<v-text-field outlined class="mb-4"
      persistent-hint
      :label="$t('models.user.displayName')"
      type="text"
      v-model="displayName"
      :rules="[$vNoHtml(displayName)]"
      :hint="$t('models.user.displayName_hint')"></v-text-field>-->

    <!--<v-textarea outlined
      counter="500"
      class="my-5"
      :label="$t('models.user.description')"
      :rules="[$vMaxLength(500), $vBannedWords(description), $vNoHtml(description), $vNoLink(description)]"
      v-model="description">
    </v-textarea>-->
    <v-card-actions>
      <v-spacer />
      <c-btn
        text color="secondary"
        @click="emitClose">
          {{ $t('actions.close') }}
      </c-btn>
      <c-btn @click.stop.prevent="submit" :disabled="displayNameHasError || descriptionHasError">
        {{ $t('actions.save') }}
      </c-btn>
    </v-card-actions>

    <c-dialog v-model="dialog" @close="restoreImage()" >
      <c-croppa
        :ratio="croppaRatio"
        :width="croppaWidth"
        :cover="!editAvatar"
        :restore="restore"
        @generatedFile="updateImage">
      </c-croppa>
    </c-dialog>
  </v-form>

</template>
<script>
import { mapState } from 'vuex'
import { existBannedWords, noHtml, noLink } from '@/plugins/form_validations'
import validatable from '@/components/mixins/validatable'
import FileManager from '@/components/mixins/file_manager'
import { ProfileActions } from '@/store'
import CCroppa from '@/components/custom/Croppa'
import Field from '@/components/custom/Field'
import dialog from '@/components/mixins/dialog'

export default {
  name: 'ProfileForm',
  components: { CCroppa, Field },
  mixins: [validatable('form'), FileManager, dialog],
  data () {
    return {
      bannerFile: {},
      avatarFile: {},
      displayName: '',
      description: '',
      validForm: true,
      editAvatar: false,
      restore: false,
      displayNameHasError: false,
      descriptionHasError: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapState('preferences', ['darkTheme']),
    hasError () {
      const errors = []
      if (this.hasBannedWord) {
        errors.push({ error: 'bannedWord', field: this.hasBannedWord })
      }
      if (this.hasPhone) {
        errors.push({ error: 'noPhoneNumber', field: true })
      }
      if (this.hasLink) {
        errors.push({ error: 'noLinksAllowed', field: true })
      }
      if (this.hasHtml) {
        errors.push({ error: 'noHtmlTags', field: true })
      }
      this.$emit('errors', errors)
      return errors
    },
    banner () {
      return this.bannerFile.preview || this.currentUser.bannerS3Route
    },
    avatar () {
      return this.avatarFile.preview || this.currentUser.avatarS3Route
    },
    croppaRatio () {
      return this.editAvatar ? 1 : 4.3
    },
    croppaWidth () {
      return this.editAvatar ? 350 : 500
    }
  },

  methods: {
    emitClose () {
      this.$emit('close')
    },
    displayNameError (error) {
      this.displayNameHasError = error
    },
    descriptionError (error) {
      this.descriptionHasError = error
    },
    saveDescription (newDescription) {
      this.description = newDescription
    },
    saveDisplayName (newDisplayName) {
      this.displayName = newDisplayName
    },
    isBannedWord (value) {
      this.hasBannedWord = existBannedWords(value)
    },
    isHtmlTags (value) {
      this.hasHtmlTags = noHtml(value)
    },
    isPhone (value) {
      this.hasPhone = noHtml(value)
    },
    isLink (value) {
      this.hasLink = noLink(value)
    },
    restoreImage () {
      this.restore = !this.restore
      this.clear()
      this.hideDialog()
    },
    bannerEdit () {
      this.editAvatar = false
      this.showDialog()
    },
    avatarEdit () {
      this.editAvatar = true
      this.showDialog()
    },
    updateImage (file) {
      if (this.editAvatar) {
        this.avatarFile = file[0]
      } else {
        this.bannerFile = file[0]
      }
      this.hideDialog()
    },

    attrs () {
      const attrs = {
        displayName: this.displayName,
        description: this.description
      }
      if (this.bannerFile.location) {
        attrs.bannerS3Route = this.bannerFile.location
      }
      if (this.avatarFile.location) {
        attrs.avatarS3Route = this.avatarFile.location
      }
      return attrs
    },

    async onSubmit () {
      const uploading = []
      if (this.bannerFile.preview) {
        uploading.push(this.uploadFile(this.bannerFile))
      }
      if (this.avatarFile.preview) {
        uploading.push(this.uploadFile(this.avatarFile))
      }

      await Promise.all(uploading)
      await this.$store.dispatch(ProfileActions.UpdateAccountData, this.attrs())
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.save_success')
      })
      this.emitClose()
    }
  },
  mounted () {
    this.avatarFile = {}
    this.bannerFile = {}
    this.displayName = this.currentUser.displayName
    this.description = this.currentUser.description
  }
}
</script>
<style lang="scss">
.profile-form {
  color: white !important;
  position: relative;

  .profile-avatar {
    position: absolute;
    top: 22px;
    left: 22px;
    bottom: 22px;
    height: 79px;
    width: 79px;
    background-color: white;
  }

  .profile-avatar, .profile-banner {
    margin-bottom: 15px;
    .v-responsive__content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .semi-opaque:hover {
    opacity: 1;
  }
}
</style>
