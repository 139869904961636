<template>
  <InputSearchUser
    class="col-12"
    @listUser="addListAndUser"
    :searchList="searchList"
    :defaultContent="defaultContent"
    includedListUser
  >
    <div class="radio-check">
      <input
        type="radio"
        :name="nameRadio"
        :value="false"
        v-model="searchList"
      />
      <label>{{ $t("actions.search_users") }}</label>
      <input
        type="radio"
        class="input"
        :name="nameRadio"
        :value="true"
        v-model="searchList"
      />
      <label>{{ $t("actions.search_lists") }}</label>
    </div>
  </InputSearchUser>
</template>

<script>
import InputSearchUser from '@/components/chat/component/InputSearchUser'
export default {
  name: 'SearchUserNewBroadcast',
  components: {
    InputSearchUser
  },
  props: {
    nameRadio: { type: String, default: 'search_user' }
  },
  data () {
    return {
      defaultContent: [],
      searchList: false
    }
  },
  methods: {
    addListAndUser (list) {
      this.$emit('list-users', list)
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-check {
  display: flex;
  align-items: center;
  accent-color: var(--v-primary-base);
  label {
    margin-left: 5px;
  }
  .input {
    margin-left: 10px;
  }
}
</style>
