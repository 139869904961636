<template>
  <div>
    <img :src="src" class="img-responsive img" :class="classImg" alt="" />
    <!-- <img src="/images/no_content.png" alt="" :class="classImg" class="img-responsive img" /> -->
  </div>
</template>
<script>
export default {
  name: "VImage",
  props: {
    primary: Boolean,
    src: String
  },
  computed: {
    classImg() {
      return {
        "primary-img": this.primary,
        "secondary-img": !this.primary
      };
    },
    noImage() {
      if (!this.reference) {
        return "/images/no_content.png";
      }
      const { preview } = this.reference;
      if (preview.type === "image") {
        return preview.preview;
      }
      return preview.cover;
    }
  }
};
</script>
<style lang="scss" scoped>
.primary-img {
  max-width: 350px;
  //width: 350px;
  max-height: 350px;
  min-height: 300px;
  &.img {
    width: 100%;
    height: 100%;
  }
}
.secondary-img {
  max-width: 100px !important;
  max-height: 100px !important;
  min-height: 100px;
  &.img {
    width: 100%;
    height: 100%;
  }
}
.img-responsive {
  /* height: auto; */
  object-fit: cover;
  border-radius: 4px;
  /* min-width: 40px  */ /*operahack*/
}
</style>
