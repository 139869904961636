import api from '@/api/Api'

export default {

  affiliatePreferences () {
    return api.get('/affiliate_preferences')
  },

  updateAffiliatePreferences (preferences) {
    return api.post('/affiliate_preferences', preferences)
  },

  mailPreferences () {
    return api.get('/mail_preferences')
  },

  updateMailPreferences (preferences) {
    return api.post('/mail_preferences', preferences)
  }
}
