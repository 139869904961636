<template>
  <section>
    <c-btn
      @click="$router.back()">
      {{ $t('actions.back') }}
    </c-btn>
    <h1>18 U.S.C SECTION 2257 COMPLIANCE NOTICE - {{$t('project_upper')}}</h1>
    <p>All models, actors, actresses and other persons that appear in any visual depiction of actual sexually explicit conduct appearing or otherwise contained in this Website were over the age of eighteen years at the time of the creation of such depictions.</p>
    <p>All other visual depictions displayed on this Website are exempt from the provision of 18 U.S.C. section 2257 and 28 C.F.R. 75 because said visual depictions do not consist of depictions of conduct as specifically listed in 18 U.S.C section 2256 (2) (A) through (D), but are merely depictions of non-sexually explicit nudity, or are depictions of simulated sexual conduct, or are otherwise exempt because the visual depictions were created prior to July 3, 1995.</p>
    <p>With respect to all visual depictions displayed on this website, whether of actual sexually explicit conduct, simulated sexual content or otherwise, all persons in said visual depictions were at least 18 years of age when said visual depictions were created.</p>
    <p>The original records required pursuant to 18 U.S.C. section 2257 and 28 C.F.R. 75 for all materials contained in the website are kept by the following Custodian of Records: </p>
    <br>
    <br>
    <img :src="`images/artemisa_${darkTheme ? 'white' : 'black'}.png`" style="margin-bottom: -3px; width: 180px; height: 15px" v-if="project.showFooterImages"/>
    <span v-else>Artemisa 3000 Tech Solutions SL </span>
    <br>CALLE GOYA, NUM 47 PLANTA 7, PUERTA 12 28001 MADRID
  </section>
</template>

<script>
import project from '@/project'

export default {
  name: 'PrivacyView',
  data () {
    return {
      project
    }
  },
  computed: {
    dark () {
      return this.$vuetify.theme.dark
    }
  }
}
</script>
