<template>
  <Cover class="white-text">
    <div class="bold upper">
      {{ $t('posts.card_overlay.followers_text_1') }}
    </div>
    <div class="mb-1 upper">
      {{ $t('posts.card_overlay.followers_text_2') }}
    </div>
    <SubscriptionForm
      class="subs-form"
      :user="user"
      v-model="offer"
      @input-select-price="createSubscription"

    />
    <c-btn color="success"
           style="max-width: fit-content"
      @click="createSubscription">
      {{ $t('subscriptions.descriptions_2.make_me_fan') }}
    </c-btn>
    <c-dialog
      width="684"
      :actions="false"
      v-model="dialog">
      <template #title>
        <h1>{{ $t(`payments.title.subscription`, { username: user.username }) }}</h1>
      </template>
      <iframe ref="iframe" class="form-container" :src="paymentUrl"></iframe>
    </c-dialog>
  </Cover>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import dialog from '@/components/mixins/dialog'
import Cover from './Cover'
import SubscriptionForm from './SubscriptionForm'
import SubscriptionApi from '@/api/SubscriptionApi'

export default {
  name: 'SubscribeOverlay',
  mixins: [dialog],
  components: { Cover, SubscriptionForm },
  props: {
    user: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      offer: 'month',
      paymentUrl: null
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapState('application', ['affiliate', 'affiliateSource']),
    ...mapGetters('revolution', ['getSfsUser'])
  },
  methods: {
    async createSubscription () {
      const origin = `${window.location.origin}/${this.user.username}?oa=${this.currentUser.sessionToken}`
      const affiliate = this.affiliate
      const affiliateSource = this.affiliateSource
      const spam4spam = this.getSfsUser(this.user.username) || {}
      const { data, error } = await SubscriptionApi.create({
        name: this.currentUser.name || this.currentUser.username,
        influencer: this.user.username,
        origin,
        type: this.offer,
        affiliate,
        affiliateSource,
        sfsUsername: spam4spam.affiliate,
        sfsSource: spam4spam.source
      })
      if (error) {
        this.selected = null
        this.errorMessage = data.error
      } else if (data.redirectUrl) {
        const slash = data.redirectUrl.includes('?') ? '&' : '?';
        window.location = `${data.redirectUrl}${slash}provider=${data.project}`
      } else {
        this.paymentUrl = data.url
        this.showDialog()
      }
    }
  }
}
</script>
<style scoped>
.subs-form {
  max-width: 550px;
  width: 100%;
}
</style>
