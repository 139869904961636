<template>
  <v-app :style="rootStyle">
    <v-content>
      <v-container fill-height
        class="unlogged center" :style="fullWidth">
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-content>
    <app-footer v-if="footerLayout" app></app-footer>
    <TagPreferencesDialog v-if="isPromoView" />
    <cookies-modal></cookies-modal>
  </v-app>
</template>

<script>
import AppFooter from '@/components/layouts/AppFooter'
import TagPreferencesDialog from '@/components/tags/TagPreferencesDialog'
import project from '@/project'
import CookiesModal from '@/components/cookies/cookies'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: { AppFooter, TagPreferencesDialog, CookiesModal },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    minClass () {
      return {
        minFooter: this.minimizeFooter
      }
    },
    rootStyle () {
      return {
        background: this.$vuetify.theme.themes[this.theme].background
      }
    },
    isPromoView () {
      return this.$route.name === 'specialPromo' || this.$route.name === 'specialPromoView'
    },
    fullWidth () {
      const full = this.$route.name === 'academy' || this.isPromoView
      if (full) {
        return {
          maxWidth: 'inherit',
          margin: 0,
          padding: 0,
          backgroundColor: this.darkTheme ? '#2D3237' : 'transparent'
        }
      }
      return {
        maxWidth: '960px !important'
      }
    },
    footerLayout(){
      return this.$route.name !== 'catchment' && this.$route.name !== 'rrss'
    }
  },
  mounted () {
    this.$vuetify.theme.dark = project.theme === 'dark'
  }
}
</script>
<style lang="scss">
.unlogged {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  margin: 0 auto;
}
</style>
