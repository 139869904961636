exports.isValid = function (value) {
  const validChars = 'trwagmyfpdxbnjzsqvhlcket';
  const dniRexp = /^[0-9]{8}[trwagmyfpdxbnjzsqvhlcket]{1}$/i;
  const nieRexp = /^[XYZ]{1}[0-9]{7}[trwagmyfpdxbnjzsqvhlcket]{1}$/i;
  const string = ((value == null) ? '' : value).toString().toLowerCase();
  if (!dniRexp.test(string) && !nieRexp.test(string)) return false;

  const nie = string
    .replace(/^[x]/, '0')
    .replace(/^[y]/, '1')
    .replace(/^[z]/, '2');

  const letter = string.substr(-1);
  const charIndex = parseInt(nie.slice(0, -1), 10) % 23;
  return validChars.charAt(charIndex) === letter;
};
