<template>
  <div class="center">
    <div class="spacer-red"></div>
  </div>
</template>
<script>
export default {
  name: "Spacer",
};
</script>

<style lang="scss" scoped>
.spacer-red {
  width: 545px;
  height: 1px;
  margin-top: 80px;
  margin-bottom: 80px;
  /* UI Properties */
  background: #ec0c4e 0% 0% no-repeat padding-box;
  opacity: 0.24;
}
@media (max-width: 700px) {
  .spacer-red {
    width: 320px;
  }
}
</style>
