import store, { AppActions, RevolutionActions } from '@/store'

export default (to) => {
  if (to.query.af) {
    store.dispatch(AppActions.UpdateAffiliate, {
      affiliate: to.query.af,
      affiliateSource: to.query.utm_source || ''
    })
  }
  if (to.params.username && to.query.cr) {
    store.dispatch(RevolutionActions.AddCreator, {
      affiliate: to.query.cr,
      username: to.params.username,
      source: to.query.utm_source || ''
    })
  }
}
