<template>
  <div>
    <Attachments
      @loading="setloadingButton"
      @up-media-file="setMediaFile"
      noUploadedMedia
      class="ml-2 mr-2 mt-2"
    />
    <Button
      color="#41b849"
      :text="$t('actions.save').toUpperCase()"
      :loading="loadingButton"
      :disabled="disabled"
      @handler="saveFiles"
    />
  </div>
</template>
<script>
import Attachments from "@/components/chat/component/Attachments";
import Button from "@/components/chat/component/Buttons";
import { AppActions } from "@/store";
import MediaFileApi from '@/api/conversations/MediaFileApi'

export default {
  name: "NewFile",
  components: { Attachments, Button },
  data() {
    return {
      files: [],
      loadingButton: false,
      disabled: true
    };
  },
  methods: {
    setloadingButton(loading) {
      this.disabled = loading;
      this.loadingButton = loading;
    },
    setMediaFile(files) {
      this.files = files;
    },
    async saveFiles() {
      this.loadingButton = true
        const files = this.files.slice()
        const promise = files.map(async f => {
          if (!f.hasOwnProperty('mediafile')) {
           await MediaFileApi.create(f.location, 'aws')
          }else{
            console.log(`fail uploaded media id:${f.name}`)
          }
        })
        await Promise.all(promise)
      this.loadingButton = false
      this.$store.dispatch(AppActions.SetNotification, { type: "success" });
      this.$store.dispatch(AppActions.ShowNotification, true);
      this.$emit("save-new-files");
    }
  }
};
</script>
