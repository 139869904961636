<template>
  <c-btn block
    @click.stop.prevent="dialog = true">
    {{ $t('products.buy_product.sale') }}
    <ProductPaymentDialog
      v-model="dialog"
      :product="product" />
  </c-btn>
</template>
<script>

import ProductPaymentDialog from './ProductPaymentDialog.vue'

export default {
  name: 'PaymentButton',
  components: { ProductPaymentDialog },
  props: {
    product: Object,
  },
  data () {
    return {
      dialog: false
    }
  }

}
</script>
