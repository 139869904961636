'user strict'

import api from '@/api/Api'

export default {

  create (product) {
    return api.post('/products', product)
  },

  update (product) {
    return api.put(`/products/${product.id}`, product)
  },

  find (id) {
    return api.get(`/products/${id}`)
  },

  delete (id) {
    return api.delete(`/products/${id}`)
  },

  list (query, options) {
    return api.get('/products', query, options)
  },

  listByUser (query, options) {
    return api.get('/products', query, options)
  },

  listAcquired (query, options) {
    return api.get('/products/acquired', query, options)
  },

  save (id) {
    return api.post(`/products/${id}/save`)
  },

  unsave (id) {
    return api.delete(`/products/${id}/save`)
  },

  buy (id, attrs) {
    return api.post(`/products/${id}/buy`, attrs)
  },

  requestPayment (id, attrs) {
    return api.post(`/products/${id}/request_payment`, attrs)
  },

  bid (id, amount) {
    return api.post(`/products/${id}/bid`, { amount })
  },

  updateDelivery (id, deliveryInfo) {
    return api.put(`/products/${id}/delivery`, deliveryInfo)
  },

  updateStatus (id, attrs) {
    return api.put(`/products/${id}/status`, { ...attrs })
  },

  recommendations (query = {}, options) {
    return api.get('/products/recommended', query, options)
  },

  listComments (query, options) {
    return api.get(`/products/${query.productId}/comments`, query, options)
  },

  sendComment (id, comment) {
    return api.post(`/products/${id}/comments`, comment)
  },

  deleteComment (id, comment) {
    return api.delete(`/products/${id}/comments/${comment.id}`)
  }

}
