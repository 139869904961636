<template>
  <v-row no-gutters>
    <v-col cols="12" class="mb-1">
      {{ inputLabel }}
    </v-col>
    <v-col cols="4" md="3" class="pr-2">
      <v-text-field outlined :dense="dense"
        hide-details
        :label="$t('types.date.day')"
        type="number"
        :error="!validDate"
        v-model="day"
        :rules="rules"
        @input="emitInput"
        @change="emitChange"></v-text-field>
    </v-col>
    <v-col cols="4" md="3" class="pr-2">
      <v-text-field outlined :dense="dense"
        hide-details
        :label="$t('types.date.month')"
        type="number"
        :error="!validDate"
        v-model="month"
        :rules="rules"
        @input="emitInput"
        @change="emitChange"></v-text-field>
    </v-col>
    <v-col cols="4" md="6">
      <v-text-field outlined :dense="dense"
        hide-details
        :label="$t('types.date.year')"
        type="number"
        :error="!validDate"
        v-model="year"
        :rules="rules"
        @input="emitInput"
        @change="emitChange"></v-text-field>
    </v-col>
    <v-col v-if="!hideDetails" cols="12">
      <div class="v-text-field__details error-message">
        <div class="v-messages theme--light error--text"
          v-if="!validDate">{{ $t('types.date.invalid') }}</div>
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'DateInput',
  props: {
    label: String,
    value: [Date, String],
    rules: { type: Array, default: () => [] },
    hideDetails: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    transform: { type: Boolean, default: false }
  },
  data () {
    return {
      day: '',
      month: '',
      year: '',
      date: null
    }
  },
  computed: {
    inputLabel () {
      return this.label ? this.label : this.$('types.date.name')
    },
    validDate () {
      if (!this || !this.day || !this.month || !this.year) return true
      return !isNaN(this.formedDate.getTime())
    },
    dateString () {
      return `${this.year}-${this.month}-${this.day}`
    },
    formedDate () {
      return new Date(this.dateString)
    }
  },

  methods: {
    isFilled () {
      return this.day && this.month && this.year
    },
    emitInput () {
      this.forwardEvent('input')
    },
    emitChange () {
      this.forwardEvent('change')
    },
    forwardEvent (event) {
      if (this.isFilled) {
        const value = this.validDate
          ? this.transform
            ? this.formedDate
            : this.dateString
          : null
        this.$emit(event, value)
      }
    }
  },
  mounted () {
    if (!this.value) {
      return
    }

    if (typeof this.value === 'string') {
      this.date = new Date(this.value)
    } else {
      this.date = this.value
    }
    this.day = this.date.getDate()
    this.month = this.date.getMonth() + 1
    this.year = this.date.getFullYear()
  }
}
</script>
<style lang="scss" scoped>
.error-message {
  margin-top: 3px;
  margin-bottom: 8px;
  padding: 0 12px;
}
</style>
