<template>
  <v-card flat tile>
    <v-form ref="form" v-model="validForm"
      @submit.prevent="submit">
      <v-card-title>{{ username }}</v-card-title>
      <v-card-title>{{ $t("admin.users.promotion.document_info") }}</v-card-title>

      <v-col cols="12">Validation Method:
        <span style="margin: auto; font-weight: bold"
              :style="{color: status === 'approved' ? '#00a433' : (status === 'rejected' ? '#ff4747' : '#8d8d8d')}">
          {{ yotiSessionId ? 'YOTI - ID:' : 'MANUAL'}} {{yotiSessionId}}
        </span></v-col>
      <v-card-text>
        <v-row>
          <v-col cols="6">Tipo: {{ $t(`models.influencer_request.id_document_types.${idDocumentType}`) }}</v-col>
          <v-col cols="6">Número: {{ idDocumentNumber }}</v-col>
          <v-col cols="6">País: {{ $country(idDocumentCountry) }}</v-col>
          <v-col cols="6">Caducidad: {{ idDocumentExpiration }}</v-col>
        </v-row>
      </v-card-text>

      <v-card-title>{{ $t("admin.users.promotion.photo_verification") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div>{{ $t('models.influencer_request.id_document') }}</div>
            <v-row no-gutters justify="space-around">
              <div class="bordered rounded">
                <c-img :src="idDocumentS3Route1"
                  width="200" height="200"
                  class="pointer"
                  @click.native="showPhoto(idDocumentS3Route1)"></c-img>
              </div>
              <div class="bordered rounded">
                <c-img :src="idDocumentS3Route2"
                  width="200" height="200"
                  class="pointer"
                  @click.native="showPhoto(idDocumentS3Route2)"></c-img>
              </div>
            </v-row>
            <v-checkbox v-model="validDocument" class="mx-2" :disabled="hideButtons"
              :label="$t('admin.users.promotion.verify_document')">
            </v-checkbox>
          </v-col>
          <v-col cols="6">
            <span>{{ $t('models.influencer_request.verification_photo') }}</span>
            <div class="bordered rounded">
              <c-img :src="verificationPhotoS3Route"
                width="200" height="200"
                class="pointer"
                @click.native="showPhoto(verificationPhotoS3Route)"></c-img>
            </div>
            <v-checkbox v-model="validPhoto" class="mx-2" :disabled="hideButtons"
              :label="$t('admin.users.promotion.verify_document')">
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-select v-if="!hideButtons"
          outlined
          dense
          clearable
          hide-details
          :label="$t('admin.users.promotion_status')"
          :items="promotionStatuses"
          v-model="customStatus"
          :rules="[$vRequired]"
        />
        <v-col v-else >
          <h2 style="">{{ $t('admin.users.promotion_status') }}:</h2><br>
          <h3 :style="{ color: status === 'rejected' ? '#ff6868' : (status === 'pending' ? '#5b5b5b' : (status === 'inactive' ? '#a3a3a3' : '#00dc00')) }">{{ status }}</h3>
          <h4 v-if="status === 'rejected'">
            {{ details }}
          </h4>
        </v-col>
      </v-card-text>
      <v-card-text v-if="!isApproved && !hideButtons">
        <v-text-field outlined
          :label="$t('admin.users.promotion.detail')"
          :placeholder="$t('admin.users.promotion.reject_detail_placeholder')"
          type="text"
          hide-details
          :disabled="hideButtons"
          :rules="[$vRequired]"
          v-model="customDetails"></v-text-field>
        <span>{{ $t('admin.users.promotion.reject_detail_mandatory') }}</span>
      </v-card-text>
      <v-card-actions class="justify-end">
        <c-btn color="secondary" @click="$listeners.close">
          {{ $t('actions.close') }}
        </c-btn>
        <c-btn :disabled="!validForm" v-if="!hideButtons"
          @click.stop.prevent="submit">
          {{ $t('actions.send') }}
        </c-btn>
      </v-card-actions>
      <carousel v-if="showCarousel" v-model="showCarousel"
        :resources="resources" :selected="selectedResource"
      ></carousel>
    </v-form>
  </v-card>
</template>
<script>
import validatable from '@/components/mixins/validatable'
import UserApi from '@/api/admin/UserApi'

export default {
  name: 'PromotionForm',
  mixins: [validatable('form')],
  props: {
    username: String,
    idDocumentType: String,
    idDocumentNumber: String,
    idDocumentCountry: String,
    idDocumentExpiration: String,
    idDocumentS3Route1: String,
    idDocumentS3Route2: String,
    verificationPhotoS3Route: String,
    idDocumentValid: Boolean,
    verificationPhotoValid: Boolean,
    details: String,
    status: String,
    hideButtons: { type: Boolean, default: false },
    yotiSessionId: String
  },

  data () {
    return {
      customStatus: '',
      validDocument: null,
      validPhoto: null,
      customDetails: '',
      showCarousel: false,
      selectedFile: null
    }
  },

  computed: {
    promotionStatuses () {
      return [{
        text: this.$t('admin.users.promotion.statuses.standby'),
        value: 'standby',
      }, {
        text: this.$t('admin.users.promotion.statuses.rejected'),
        value: 'rejected',
      }, {
        text: this.$t('admin.users.promotion.statuses.approved'),
        value: 'approved',
      }]
    },
    isApproved () {
      return this.customStatus === 'approved'
    },
    promotion () {
      return {
        username: this.username,
        idDocumentValid: !!this.validDocument,
        verificationPhotoValid: !!this.validPhoto,
        status: this.customStatus,
        details: this.customDetails
      }
    },
    selectedResource () {
      return this.resources.find(r => r.url === this.selectedFile)
    },
    resources () {
      return [
        { url: this.idDocumentS3Route1 },
        { url: this.idDocumentS3Route2 },
        { url: this.verificationPhotoS3Route }
      ]
    }
  },

  watch: {
    username () {
      this.setupValues()
    }
  },

  methods: {
    showPhoto (pic) {
      this.selectedFile = pic
      this.showCarousel = true
    },
    async onSubmit () {
      await UserApi.updatePromotion(this.promotion)
      this.$emit('submit')
      this.$emit('close')
    },

    setupValues () {
      this.customStatus = this.status
      this.validForm = (this.idDocumentValid && this.verificationPhotoValid) || !!this.details
      this.validDocument = this.idDocumentValid
      this.validPhoto = this.verificationPhotoValid
      this.customDetails = this.details
    }
  },

  mounted () {
    this.setupValues()
  }
}
</script>
