<template>
  <c-dialog
    v-bind="$attrs"
    :persistent="persistent"
    width="500"
    :value="dialog"
    :actions="false"
    :fullscreen="false"
    :closable="!persistent"
    @input="emitCancel">
    <p>{{ $t('tags.warning.text') }}</p>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn
          rounded depressed
          color="success"
          @click="emitCancel"
        >
          {{ $t('tags.warning.keep') }}
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          rounded depressed
          color="error"
          @click="emitAccept">
          {{ $t('tags.warning.remove') }}
        </v-btn>
      </v-col>
    </v-row>
  </c-dialog>
</template>
<script>
export default {
  name: 'ScatWarningDialog',
  props: {
    dialog: { type: Boolean, default: true },
    persistent: Boolean
  },
  methods: {
    emitAccept () {
      this.$emit('accepted')
      this.$emit('update:dialog', false)
    },
    emitCancel () {
      this.$emit('canceled')
      this.$emit('update:dialog', false)
    }
  }
}
</script>
