<template>
  <section>
    <TitleOptionTab :attrs="title" @selectTab="onTab" />
    <NewBroadcastMessage
      v-if="broadcastMessage"
      @createBroadcast="changeToList()"
    ></NewBroadcastMessage>
    <ScheduleMessages v-if="scheduleMessage"></ScheduleMessages>
  </section>
</template>

<script>
import IconButton from '@/components/chat/component/IconButton.vue'
import { mapState } from 'vuex'
import NewBroadcastMessage from '@/components/chat/components/newChat/NewBroadcastMessage'
import ScheduleMessages from '@/components/chat/components/newChat/ScheduleMessages'
import TitleOptionTab from '@/components/chat/component/TitleOptionTab'

export default {
  name: 'BroadcastMessageMenu',
  components: {
    ScheduleMessages,
    NewBroadcastMessage,
    IconButton,
    TitleOptionTab
  },
  data () {
    return {
      broadcastMessage: false,
      scheduleMessage: false,
      title: [
        {
          iconType: 'newMessage',
          title: 'chats.new_message',
          call: 'new',
          icon: true,
          show: true
        },
        {
          iconType: 'schedule',
          title: 'chats.schedule_message.title',
          call: 'list',
          icon: true,
          show: true
        }
      ]
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
     darkTheme(){
      return this.$vuetify.theme.dark
    },
  },
  methods: {
    onTab (tab) {
      this.broadcastMessage = tab === 'new'
      this.scheduleMessage = tab === 'list'
    },
    newScheduleMessage () {
      this.scheduleMessage = !this.scheduleMessage
      this.broadcastMessage = false
    },
    newBroadcastMessage () {
      this.broadcastMessage = !this.broadcastMessage
      this.scheduleMessage = false
    },
    changeToList () {
      this.scheduleMessage = true
      this.broadcastMessage = false
    }
  }
}
</script>

<style lang="scss" scoped>
.icons {
  display: flex;
}

.search-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  height: 37px;

  input {
    width: 100%;
    display: inline-block;
    border: 1px solid #d7d7d7;
    height: 40px;
    padding: 7px 15px;
    margin: 4px;
    font-size: 0.9375em;
    border-radius: 30px;
  }
}
select {
  width: 100%;
  display: inline-block;
  border: 1px solid #d7d7d7;
  height: 40px;
  padding: 7px 15px;
  margin: 4px;
  font-size: 0.9375em;
  border-radius: 30px;
}
</style>
