var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"hide-default-footer":_vm.noPagination,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"item-key":"id","locale":_vm.$i18n.locale,"page":_vm.page,"items-per-page":_vm.perPage,"server-items-length":_vm.totalItems},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BackRow'),(!_vm.noFilter)?_c('v-row',{staticClass:"px-2",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('date-picker-input',{attrs:{"range":"","label":_vm.$t('labels.filter_dates')},model:{value:(_vm.filterDates),callback:function ($$v) {_vm.filterDates=$$v},expression:"filterDates"}})],1)],1):_vm._e()]},proxy:true},{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.billNumber || item.uuid)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"currency":item.currency,"amount":item.amount}})]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fee || 0)+"% - "),_c('Currency',{attrs:{"currency":item.currency,"amount":item.feeAmount}})]}},{key:"item.vat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vat || 0)+"% - "),_c('Currency',{attrs:{"currency":item.currency,"amount":item.vatAmount}})]}},{key:"item.taxes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.taxes || 0)+"% - "),_c('Currency',{attrs:{"currency":item.currency,"amount":item.taxAmount}})]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"currency":item.currency,"amount":item.totalAmount}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('p',{on:{"click":function($event){return _vm.openDialogRejected(item.status, item.details)}}},[_vm._v(" "+_vm._s(_vm.$t(("models.withdraw.statuses." + (item.status))))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isCommited(item))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.download(item)}}},[_c('v-icon',[_vm._v("file_download")])],1):_vm._e()]}}])}),_c('c-dialog',{attrs:{"width":"400","value":_vm.showDetailError},on:{"input":_vm.close},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.$t("models.withdraw.statuses.rejected")))])]},proxy:true}])},[_c('v-icon',[_vm._v("warnind_cicle")]),_vm._v(" "+_vm._s(_vm.detailsError)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }