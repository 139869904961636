<template>
  <div ref="container">
    <iframe
      id="TrustPayFrame"
      class="form"
      allow="payment *; clipboard-write;"
      sandbox="allow-forms allow-scripts allow-modals allow-popups allow-same-origin allow-top-navigation"
      :src="`${url}&IsRedirect=true`">
    </iframe>
    <a id="launch-trustpay" ref="showForm" href="#" class="show-popup">Pay via TrustPay</a>
  </div>
</template>
<script>

export default {
  name: 'PaymentForm',
  props: {
    url: String,
  },
  methods: {
    showIframe () {
      const iframe = document.getElementById("TrustPayFrame")
      iframe.style.opacity = 1
      iframe.style.backgroundColor = 'transparent'
      iframe.style.width = "100%"
      iframe.style.height = "100%"
      iframe.focus()
      iframe.contentWindow.postMessage('focus-event', '*')
      iframe.contentWindow.postMessage('redirectMessage', '*')
      iframe.contentWindow.postMessage('authorizationMessage', '*')
    }
  },
  mounted() {
    window.onTrustPayPopupLoaded = () => {
      this.showIframe()
    }
  }
}
</script>
<style scoped>
  .show-popup {
    visibility: hidden;
  }

  .form {
    display: block;
    margin: 0 auto;
    width: 90%;
    min-width: 250px;
    min-height: 600px;
    border: none;
  }
</style>