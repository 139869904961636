<template>
  <div class="title-text-3 roboto">
    <div><strong>{{ $t('products.sale.ref_number', { ref: purchase.ref }) }}</strong></div>
    <div>{{ $t('products.delivery.shipment.title', { name: purchase.username }) }}</div>
    <p>{{ $date(purchase.date) }}</p>
    <div class="semibold my-2">{{ $t('products.delivery.shipment.sent_to') }}:</div>
    <div>
      {{ $t('products.delivery.shipment.email', { email: purchase.email }) }}
    </div>
    <div>
      {{ purchase.name }}
    </div>
    <template v-if="isPacket">
      <div>{{ purchase.address }}</div>
      <div>
        {{ purchase.city }}, {{ purchase.province }} {{ purchase.postalCode }}
      </div>
      <div>{{ $country(purchase.country) }}</div>
    </template>
    <span v-if="!purchase.completed">{{ $t('products.delivery.not_completed', { name: purchase.name }) }}</span>
    <div v-else-if="!purchase.sent"
      class="semibold mb-1">{{ $t('products.delivery.not_sent', { name: product.author.username }) }}</div>
    <div v-else
      class="semibold mb-1">
      {{ $t('products.delivery.sent', { name: product.author.username }) }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'SaleInfo',
  props: {
    product: { type: Object },
    purchase: { type: Object, default: () => ({}) }
  },
  computed: {
    isPacket () {
      return this.product.deliveryType === 'packet'
    }
  }
}
</script>
