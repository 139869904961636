<template>
  <section>
    <iframe
      v-if="paymentUrl != ''"
      class="mx-auto my-auto wallet-content"
      :class="classWallet"
      ref="iframe"
      sandbox="allow-forms allow-scripts allow-modals allow-popups allow-same-origin allow-top-navigation"
      :src="paymentUrl"
    ></iframe>
    <!-- <div v-if="paymentUrl != ''" class="mx-auto my-auto" :class="classWallet" >
    </div> -->
    <div v-show="paymentUrl === ''">
      <p class="text-info">{{ textLoadFund }}</p>
      <div class="box-spacearound whitesmoke" :class="{ dark: darkTheme }">
        <form class="form-amount">
          <p>{{ formText }}</p>
          <div>
            <InputCurrency
              v-if="price > 0"
              :currency="currency"
              :defaultPrice="priceDeafult"
              v-on:newPrice="newPrice"
            />
            <InputCurrency v-else :currency="currency" v-on:newPrice="newPrice" />
            <!--  <input type="text" placeholder="28$" /> -->
            <div v-if="maxAmountError"
              class="error--text"
            >
              {{ $t('errors.no_max_amount', { amount: $currency(maxAmount) }) }}
            </div>
          </div>
          <Button
            :disabled="disabledButton"
            color="#41b849"
            text="CHECKOUT"
            :loading="loadingButton"
            @handler="sendReload"
          />
        </form>
      </div>
      <p
        class="center text-info"
        v-html="
          $t('chats.load_funds_info', {
            priceStart: $currency(1000),
            priceEnd: $currency(maxAmount)
          })
        "
      ></p>
      <div class="box-amount">
        <div class="box-button" @click="setDefaultAmount(1000)">
          <div class="box-button-txt">10{{ currency }}</div>
        </div>
        <div class="box-button" @click="setDefaultAmount(2000)">
          <div class="box-button-txt">20{{ currency }}</div>
        </div>
        <div class="box-button" @click="setDefaultAmount(5000)">
          <div class="box-button-txt">50{{ currency }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Button from '../component/Buttons'
import InputCurrency from '../component/InputCurrency'
import WalletApi from '@/api/WalletApi'
import payments from '@/components/mixins/payments'
import config from '@/project'

const MaxAmount = 20000

export default {
  name: 'LoadFunds',
  mixins: [payments],
  components: {
    Button,
    InputCurrency
  },
  props: {
    clearInit: { type: Boolean },
    price: { type: Number },
    textLoadFund: { type: String, default: '' }
  },
  data () {
    return {
      amount: 1000,
      amountTemp: 1000,
      maxAmount: MaxAmount,
      showLoadFunds: false,
      paymentUrl: '',
      loadingButton: false,
      maxAmountError: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapGetters('profile', ['currentWallet']),
    ...mapState('application', ['isMobile']),
     darkTheme(){
      return this.$vuetify.theme.dark
    },
    currency () {
      return this.currentWallet.currency === 'EUR' ? '€' : '$'
    },
    formText () {
      return this.$t('models.payment.amount')
    },
    classWallet () {
      return {
        wallet: this.paymentUrl !== '' && !this.isMobile,
        phone: this.isMobile
      }
    },
    priceDeafult () {
      const price = this.price
      if (price <= 1000) {
        return 1000
      } else {
        return price
      }
    },
    disabledButton () {
      return this.amount < 1000 || this.maxAmountError
    },
    origin () {
      if (config.payments.darkfans_redirect) {
        return `https://darkfans.com/${this.$route.path}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
      }
      return `${window.location.origin}${this.$route.path}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
    }
  },
  watch: {
    clearInit () {
      this.amountTemp = 1000
      this.amount = 0
      this.paymentUrl = ''
      this.maxAmountError = false
    }
  },
  methods: {
    newPrice (price) {
      this.maxAmountError = price > MaxAmount
      this.amount = price
    },
    setDefaultAmount (price) {
      this.amount = price
      this.sendReload()
    },

    async sendReload () {
      if (this.disabledButton) {
        return
      }
      this.loadingButton = true
      const userId = this.currentUser.id
      const sendLoad = {
        amount: this.amount,
        name: this.currentUser.username,
        origin: this.origin
      }
      const { data, error } = await WalletApi.reload(userId, sendLoad)
      this.loadingButton = false
      if (error) return

      if (data.redirectUrl) {
        const slash = data.redirectUrl.includes('?') ? '&' : '?';
        window.location = `${data.redirectUrl}${slash}provider=${data.project}`
      } else {
        this.provider = data.provider
        this.paymentUrl = data.url
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box-spacearound {
  display: flex;
  justify-content: space-around;
  font-family: 'Quicksand', sans-serif;
  padding: 5px 5px;
  background: whitesmoke;
  border-radius: 20px;
  align-items: center;
  &.dark {
    background-color: #3b3b3b;
  }
}
.form-amount {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  margin: 5px;
}
.text-info {
  font-family: 'Quicksand', sans-serif;
  font-size: 17px;
  padding: 5%;
}
.form-amount input {
  display: inline-block;
  border: 1px solid #d7d7d7;
  height: 30px;
  padding: 7px 15px;
  margin: 4px;
  font-size: 0.9375em;
  border-radius: 30px;
}
.box-amount {
  display: flex;
  justify-content: space-around;
  padding: 5%;
}
.center {
  margin: 0 auto;
  display: block;
  text-align: center;
  float: none;
  width: 100%;
}
.wallet {
  max-width: 100%;
  width: 40rem;
}
.wallet-content {
  height: 37rem !important;
  max-height: 100%;
  &.phone {
    height: 30rem !important;
  }
}
.box-button {
  padding: 20px 10px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid #b8b8b8;
  background: rgb(20, 175, 255);
  background: linear-gradient(
    32deg,
    rgba(23, 117, 148, 1) 0%,
    rgba(20, 175, 255, 1) 100%
  );
}
.box-button-txt {
  font-size: 1.5em;
  color: #ffffff;
}
.yelow {
  color: #ffe405;
}
</style>
