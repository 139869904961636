<template>
  <v-form ref="form"
    v-model="validForm"
    @submit.prevent="submit">
    <v-card-text>
      <span class="title-text-3 roboto upper light-text">
        {{ $t(`products.auction.current_bid`) }} 
        {{ $currency(product.price, product.currency) }}
      </span>
      <CurrencyInput
        outlined
        :disabled="disabled"
        :min-value="product.price + 1"
        :maxValue="900000"
        v-model="amount" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <c-btn
        text color="secondary"
        @click="close">
          {{ $t('actions.close') }}
      </c-btn>
      <c-btn
        :loading="loading"
        :disabled="disabled || !validForm"
        @click="submit">
        {{ $t('actions.bid') }}
      </c-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import validatable from '@/components/mixins/validatable'

export default {
  name: 'BidForm',
  mixins: [validatable('form')],
  props: {
    disabled: Boolean,
    loading: Boolean,
    product: Object
  },
  data () {
    return {
      amount: null
    }
  },
  methods: {
    onSubmit () {
      this.$emit('submit', this.amount)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
