'user strict'

import api from '@/api/Api'

export default {

  request (email) {
    return api.post('/passwords', { email })
  },

  reset (token, password) {
    return api.put('/passwords', { token, password })
  }
}
