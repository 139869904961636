<template>
  <c-dialog v-if="loading"
    v-model="loading"
    :actions="!nextProvider"
    @close="hideDialog" >
    <div class="paying-progress">
      <span class="progress-text title-text-1 light-text mx-auto mb-5">
        {{ $t('payments.paying') }}
      </span>
      <v-progress-circular class="progress-icon"
        indeterminate color="primary" size="48"></v-progress-circular>
    </div>
  </c-dialog>
  <c-dialog v-else-if="nextProvider"
    width="684"
    :actions="false"
    v-model="dialog">
    <template #title>
      <h1>{{ $t('payments.failed') }}</h1>
    </template>
    <CascadeForm v-if="payment"
      :payment="payment"
      :provider="nextProvider" />
  </c-dialog>
  <component v-else-if="hasPaymentResponse && dialog"
    v-model="dialog"
    :is="responseComponent"
    :payment="payment"
    :resource="payment.resource"
    :resourceId="realResourceId"
    :id="realResourceId"
    @close="hideDialog" />
</template>
<script>
import PaymentApi from '@/api/PaymentApi'
import dialog from '@/components/mixins/dialog'
import SuccessPaymentDialog from '@/components/payments/dialogs/SuccessPaymentDialog'
import FailedPaymentDialog from '@/components/payments/dialogs/FailedPaymentDialog'
import CascadeForm from '@/components/payments/forms/CascadeForm'

export default {
  name: 'PaymentResponseDialog',
  mixins: [dialog],
  components: { CascadeForm, SuccessPaymentDialog, FailedPaymentDialog },
  data () {
    return {
      loading: false,
      success: false,
      payment: null,
      nextProvider: null
    }
  },

  computed: {
    query () {
      return this.$route.query
    },
    isNewVersionResponse () {
      return !!this.query.orderId
    },
    isInetcashResponse () {
      return !!this.query.shopid
    },
    hasPaymentResponse () {
      return !!this.query.gui || this.isNewVersionResponse || !!this.isInetcashResponse
    },
    responseComponent () {
      if (this.success) {
        return 'SuccessPaymentDialog'
      } else {
        return 'FailedPaymentDialog'
      }
    },
    realResourceId () {
      if (!this.payment) {
        return
      }
      return (this.payment.resourceModel && this.payment.resourceModel.username) || this.payment.resourceId
    }
  },

  methods: {
    async fetchPaymentResource () {
      if (this.isNewVersionResponse) {
        this.pollResource({ orderId: this.query.orderId })
      } else if (this.isInetcashResponse) {
        this.pollResource({ shopid: this.query.shopid })
      } else {
        this.pollResource({ uuid: this.query.gui })
      }
    },
    setupNextProvider () {
      const { uuid, backup, backupProvider } = this.payment
      if (backup && backup.gatewayUrl) {
        const origin = `${window.location.origin}${this.$route.path}`
        window.location = `${backup.gatewayUrl}?paymentId=${uuid}&provider=${backup.project}&lang=${this.$i18n.locale}&origin=${origin}`
      } else {
        this.nextProvider = backupProvider
      }
    },
    pollResource (params) {
      this.loading = true
      this.pollingTask = setInterval(async () => {
        const { data } = await PaymentApi.findResource(params)
        if (data.status !== 'pending') {
          this.payment = data
          this.success = data.status === 'commited'
          if (!this.success) {
            this.setupNextProvider()
          }
          this.loading = false
          clearInterval(this.pollingTask)
        }
      }, 3000)
    },
    onClose () {
      clearInterval(this.pollingTask)
    }
  },

  async mounted () {
    if (this.hasPaymentResponse) {
      await this.fetchPaymentResource()
      this.showDialog()
    }
  }
}
</script>
<style lang="scss" scoped>
.paying-progress {
  height: 550px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  .progress-text {
    width: 250px;
  }
  .progress-icon {
    display: block;
    width: 50px;
    margin: 50px auto;
  }
}
</style>
