import EmptyRouterView from '@/views/EmptyRouterView'
import ChatDashboard from '@/components/chat/ChatDashboard'
import BulkContracts from '@/views/admin/BulkContracts.vue'

const AdminUsersView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/UsersView.vue')
const AdminUserView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/UserView.vue')
const AdminComplaintsView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/ComplaintsView.vue')
const AdminServiceView = () => import(/* webpackChunkName: "group-admin" */ './../../components/invoicing/views/InvoiceView.vue')
const AdminBillingView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/BillingView.vue')
const AdminPaymentView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/payments/PaymentView.vue')
const AdminWithdrawView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/payments/WithdrawView.vue')
const AdminBonusesView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/bonus/BonusesView.vue')
const AdminBonusView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/bonus/BonusView.vue')
const PostPromo = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/posts/PostPromo.vue')
const AdminCategoryView = () => import(/* webpackChunkName: "group-admin" */ './../../views/CategoriesView.vue')
const AdminAgentListView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/AgentListView.vue')
const AdminAgentView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/AgentView.vue')
const AdminPartnerView = () => import(/* webpackChunkName: "group-admin" */ './../../views/PartnerView.vue')
const AddFollowers = () => import(/* webpackChunkName: "group-admin" */ './../../components/admin/superuser/AddFollowers.vue')
const DelegateMessage = () => import(/* webpackChunkName: "group-admin" */ './../../components/admin/superuser/DelegateMessage.vue')
const WalletTransfer = () => import(/* webpackChunkName: "group-admin" */ '../../components/admin/wallet_transfer/WalletTransfer.vue')
const WalletTransferView = () => import(/* webpackChunkName: "group-admin" */ '../../components/admin/wallet_transfer/WalletTransferView.vue')
const ModifyWallet = () => import(/* webpackChunkName: "group-admin" */ '../../components/admin/wallet_transfer/ModifyWallet.vue')

const ValidateView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/validate/ValidateView.vue')
const PostsPendingView = () => import(/* webpackChunkName: "group-admin" */ '../../views/admin/validate/posts/PostsPendingView.vue')
const PostsReviewedView = () => import(/* webpackChunkName: "group-admin" */ '../../views/admin/validate/posts/PostsReviewedView.vue')
const ProductsPendingView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/validate/products/ProductsPendingView.vue')
const ProductsReviewedView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/validate/products/ProductsReviewedView.vue')

const Development = () => import('./../../views/admin/Development')

const routes = [
  {
    path: '/admin/users',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'admin_users',
      component: AdminUsersView
    }, {
      path: ':username',
      name: 'admin_user',
      props: true,
      component: AdminUserView
    }]
  },
  {
    path: '/admin/messages',
    name: 'admin_messages',
    component: ChatDashboard,
    meta: { authenticated: true }
  },
  {
    path: '/admin/complaints',
    name: 'admin_complaints',
    component: AdminComplaintsView,
    meta: { authenticated: true }
  },
  {
    path: '/admin/service',
    name: 'admin_service',
    component: AdminServiceView,
    meta: { authenticated: true }
  },
  {
    path: '/admin/bonus',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'admin_bonuses',
      component: AdminBonusesView
    }, {
      path: ':bonusCode',
      name: 'admin_bonus',
      props: true,
      component: AdminBonusView
    }]
  },
  {
    path: '/admin/validation',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'admin_validation',
      component: ValidateView
    }, {
      path: 'posts',
      name: 'admin_posts_validation',
      component: EmptyRouterView,
      children: [
        {
          path: 'pending',
          name: 'admin_post_validate_pending',
          component: PostsPendingView
        }, {
          path: 'reviewed',
          name: 'admin_post_validate_reviewed',
          component: PostsReviewedView
        }
      ]
    }, {
      path: 'products',
      name: 'admin_products_validation',
      component: EmptyRouterView,
      children: [
        {
          path: 'pending',
          name: 'admin_products_validate_pending',
          component: ProductsPendingView
        }, {
          path: 'reviewed',
          name: 'admin_products_validate_reviewed',
          component: ProductsReviewedView
        }
      ]
    }]
  },
  {
    path: '/admin/promo',
    name: 'admin_post_promo',
    component: PostPromo,
    meta: { authenticated: true }
  },
  {
    path: '/admin/add_followers',
    name: 'admin_add_followers',
    component: AddFollowers,
    meta: { authenticated: true }
  },
  {
    path: '/admin/xsale',
    name: 'admin_delegate_messages',
    component: DelegateMessage,
    meta: { authenticated: true }
  },
  {
    path: '/admin/wallet_transfer',
    component: WalletTransferView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'admin_wallet_transfer',
      component: EmptyRouterView
    }, {
      path: 'wallet_transfer',
      name: 'admin_wallet_transfer',
      component: WalletTransfer
    }, {
      path: 'platform_transfer',
      name: 'admin_platform_transfer',
      component: ModifyWallet
    }]
  },
  {
    path: '/admin/development',
    name: 'admin_development',
    component: Development,
    meta: { authenticated: true }
  },
  {
    path: '/admin/billing',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'admin_billing',
      component: AdminBillingView
    }, {
      path: 'payments',
      name: 'admin_payments',
      component: AdminPaymentView
    }, {
      path: 'withdraws',
      name: 'admin_withdraws',
      component: AdminWithdrawView
    }]
  },
  {
    path: '/admin/categories',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'admin_categories',
      component: AdminCategoryView
    }]
  },
  {
    path: '/admin/agents',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'admin_agents',
      component: AdminAgentListView
    }, {
      path: ':username',
      name: 'admin_agent',
      props: true,
      component: AdminAgentView
    }, {
      path: ':username/partners/:partner',
      name: 'admin_partner',
      props: true,
      component: AdminPartnerView
    }]
  },
  {
    path: '/admin/bulk_contracts',
    name: 'admin_bulk_contracts',
    component: BulkContracts,
    meta: { authenticated: true }
  }
]

export default routes
export const AdminRouteNames = [
  'admin_users',
  'admin_user',
  'admin_messages',
  'admin_complaints',
  'admin_service',
  'admin_bonuses',
  'admin_bonus',
  'admin_validation',
  'admin_posts_validation',
  'admin_post_validate_pending',
  'admin_post_validate_reviewed',
  'admin_products_validate_pending',
  'admin_products_validate_reviewed',
  'admin_post_promo',
  'admin_add_followers',
  'admin_delegate_messages',
  'admin_development',
  'admin_billing',
  'admin_payments',
  'admin_withdraws',
  'admin_categories',
  'admin_agents',
  'admin_agent',
  'admin_partner',
  'admin_bulk_contracts',
  'admin_wallet_transfer'
]
