<template>
  <div>
    <div class="post-overlay-wrapper">
      <template v-if="isAuthor">
        <div class="top-left">
          <v-chip small class="translucient-dark">
            <v-icon small color="white" class="mr-1">remove_red_eye</v-icon>
            <span>{{ visibilityText }}</span>
          </v-chip>
          <div>
            <v-chip v-if="isProgrammed">
              {{ $datetime(post.publishedAt) }}
            </v-chip>
          </div>
          <div>
            <ApprovalStatus v-if="showApprovalStatus" :post="post" />
          </div>
        </div>
      </template>
      <div class="top-right">
        <span class="rounded pa-1 translucient-dark">
          <v-icon color="white">{{ typeIcon }}</v-icon>
          <span v-if="isVideo && duration" class="ml-1 small-text">{{ duration }}</span>
          <span v-else-if="isImage && post.multipleResources" class="ml-1 small-text">{{ post.resourcesCount }}</span>
        </span>
      </div>
      <div class="d-flex flex-column justify-center align-center fill-height text-center p-2">
        <v-icon v-if="isVideo"
          x-large
          class="white-light-text">
          play_circle_outline
        </v-icon>
        <div v-show="hover"
          class="post-card-actions translucient-dark">
          <span class="white--text mx-1">
            <v-icon color="white">favorite_border</v-icon>
            <span class="bold">{{ post.likesCount }}</span>
          </span>
          <span class="white--text mx-1">
            <v-icon color="white">chat_bubble_outline</v-icon>
            <span class="bold">{{ post.commentsCount }}</span>
          </span>
          <div class="flex-grow-1"></div>
          <span class="white--text mx-1">
            <v-icon color="white">share</v-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ApprovalStatus from '../ApprovalStatus.vue'
const padTo = (elem, count, char) => ('' + elem).padStart(count, char)

export default {
  name: 'CardOverlay',
  components: { ApprovalStatus },
  props: {
    post: Object,
    hover: Boolean
  },

  computed: {
    ...mapState('profile', ['currentUser']),
    showApprovalStatus () {
      return this.isAuthor
    },
    isVideo () {
      return this.post.postType === 'video'
    },
    isImage () {
      return this.post.postType === 'image'
    },
    isAuthor () {
      return this.post.username === this.currentUser.username
    },
    isExclusive () {
      return this.post.visibility === 'exclusive'
    },
    visibilityText () {
      const { price, currency } = this.post
      return this.$t(`posts.card_visibility.${this.post.visibility}`, { amount: this.$currency(price, currency) })
    },
    typeIcon () {
      return this.post.hasGifs ? 'gif'
        : this.isVideo ? 'videocam'
        : this.post.multipleResources ? 'photo_library'
        : 'camera_alt'
    },
    isProgrammed () {
      const date = new Date(this.post.publishedAt)
      const now = new Date()
      return now.getTime() < date.getTime()
    },
    duration () {
      if (!this.post.metadata) return ''
      const length = this.post.metadata.clip ? this.post.metadata.clip.duration : '0'
      const minutes = Math.floor(length / 60)
      const seconds = length % 60
      if (isNaN(minutes) || isNaN(seconds)) return ''
      return `${padTo(minutes, 2, '0')}:${padTo(seconds, 2, '0')}`
    }
  }
}
</script>

<style lang="scss" scoped>
.post-overlay-wrapper {
  position: relative;
  height: 100%;
}
.top-left {
  position: absolute;
  top: 8px;
  left: 10px;
}
.top-right {
  position: absolute;
  top: 8px;
  right: 10px;
}

.post-card-actions {
  position: absolute;
  bottom: 0;
  color: white;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
}

</style>
