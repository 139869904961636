<template>
  <section>
    <div class="row p-0">
      <div
        v-for="(attach, index) in mediaIdsTemp.slice(
          0,
          isSizeFour ? 4 : mediaIdsTemp.length
        )"
        :key="index"
        class="col items"
      >
        <div class="icon-close" :class="classDark">
          <icon-button
            :size="10"
            type="close"
            disabledDarkTheme
            @handler="() => deleteFile(attach.mediaId)"
          />
        </div>
        <img
          v-if="attach.attachmentType === 'image'"
          :src="attach.path"
          alt=""
          class="imagen-view"
        />
        <video
        controlslist="nodownload"
          v-if="attach.attachmentType === 'video'"
          class="imagen-view"
          :src="attach.path"
        ></video>
        <div v-if="attach.attachmentType === 'audio'" class="audio">
          <AudioPlayer :src="attach.path" :iconBottom="false" />
        </div>
      </div>
    </div>
    <button v-if="viewButton" class="collapsible" @click="openCollapse" :class="classDark">
      {{ $t('actions.see_all') }}
    </button>
  </section>
</template>

<script>
import AudioPlayer from '@/components/chat/components/viewFiles/AudioPlayer'
import IconButton from '../../component/IconButton.vue'
export default {
  name: 'ViewMultipleFile',
  components: { IconButton, AudioPlayer },
  props: {
    mediaIds: {
      type: Array
    }
  },
  data () {
    return {
      mediaIdsTemp: [],
      mediaIdsVisible: [],
      mediaIdsHidden: [],
      isSizeFour: true
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    mediaIds () {
      this.init()
    }
  },
  computed: {
    darkTheme(){
      return this.$vuetify.theme.dark
    },
    classDark(){
      return { dark:this.darkTheme}
    },
    viewButton () {
      return this.mediaIdsTemp.length > 4
    }
  },
  methods: {
    init () {
      this.mediaIdsTemp = this.mediaIds
    },
    openCollapse () {
      this.isSizeFour = !this.isSizeFour
    },
    deleteFile (id) {
      this.$emit('delete-id', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsible {
  cursor: pointer;
  width: 100%;
  border-top: 1px solid black;
  text-align: right;
  outline: none;
  font-size: 15px;
  &.dark{
    border-color: gray !important;
  }
}
.audio {
  width: 80px;
  height: 80px;
  cursor: pointer;
  .audio-play {
    width: 100%;
    height: 100%;
  }
}

.content {
  display: none;
  overflow: hidden;
  background-color: transparent;
}
.imagen-view {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.items {
  position: relative;
  max-width: 110px;
  max-height: 110px;
}
.icon-close {
  left: 70px;
  position: relative;
  /*   margin-right: -15px; */
}
</style>
