<template>
  <svg
    :height="`${size}px`" :width="`${size}px`"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    viewBox="0 0 1.171875 1.171875"
    version="1.1"
    id="svg5"
    sodipodi:docname="check5.svg"
    inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)">
    <metadata
      id="metadata11">
      <rdf:RDF>
        <cc:Work
          rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <defs
      id="defs9"/>
    <sodipodi:namedview
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="1272"
      inkscape:window-height="771"
      id="namedview7"
      showgrid="false"
      inkscape:zoom="6.4892615"
      inkscape:cx="17.550565"
      inkscape:cy="15.009393"
      inkscape:window-x="2391"
      inkscape:window-y="101"
      inkscape:window-maximized="0"
      inkscape:current-layer="svg5"/>
    <g
      id="Layer_129"
      data-name="Layer 129"
      transform="matrix(0.05859375,0,0,0.06093321,-0.1171875,-0.14525362)"
      style="fill:#0088fc;fill-opacity:1">
      <path
        d="M 22,12 C 22,11 21.06,10.4 20.31,9.95 A 6,6 0 0 1 19.64,9.51 5.13,5.13 0 0 1 19.93,8.72 C 20.26,7.92 20.68,6.93 20.09,6.12 19.5,5.31 18.42,5.39 17.55,5.47 a 5.91,5.91 0 0 1 -0.83,0 C 16.65,5.29 16.56,4.92 16.5,4.68 16.31,3.83 16.06,2.77 15.09,2.46 14.12,2.15 13.34,2.85 12.69,3.41 A 6,6 0 0 1 12,4 6,6 0 0 1 11.31,3.46 C 10.66,2.9 9.85,2.2 8.91,2.51 7.97,2.82 7.69,3.86 7.5,4.71 7.44,4.95 7.35,5.32 7.28,5.5 a 5.91,5.91 0 0 1 -0.83,0 C 5.58,5.42 4.51,5.33 3.91,6.15 3.31,6.97 3.74,7.95 4.07,8.75 A 5.13,5.13 0 0 1 4.36,9.54 6,6 0 0 1 3.69,9.98 C 2.94,10.4 2,11 2,12 c 0,1 0.94,1.6 1.69,2.05 a 6,6 0 0 1 0.67,0.44 5.13,5.13 0 0 1 -0.29,0.79 c -0.33,0.8 -0.75,1.79 -0.16,2.6 0.59,0.81 1.67,0.73 2.54,0.65 a 5.91,5.91 0 0 1 0.83,0 c 0.07,0.18 0.16,0.55 0.22,0.79 0.19,0.85 0.44,1.91 1.41,2.22 0.97,0.31 1.75,-0.39 2.4,-1 A 6,6 0 0 1 12,20 a 6,6 0 0 1 0.69,0.54 3.23,3.23 0 0 0 1.93,1 1.6,1.6 0 0 0 0.47,-0.08 c 1,-0.31 1.22,-1.37 1.41,-2.22 0.06,-0.24 0.15,-0.61 0.22,-0.79 a 5.91,5.91 0 0 1 0.83,0 c 0.87,0.08 1.94,0.17 2.54,-0.65 0.6,-0.82 0.17,-1.8 -0.16,-2.6 a 5.13,5.13 0 0 1 -0.29,-0.79 6,6 0 0 1 0.67,-0.44 C 21.06,13.6 22,13 22,12 Z m -6.22,-1.88 -4,5 A 1,1 0 0 1 11.06,15.5 H 11 a 1,1 0 0 1 -0.71,-0.29 l -2,-2 a 1.0040916,1.0040916 0 0 1 1.42,-1.42 l 1.21,1.21 3.3,-4.12 a 1,1 0 0 1 1.56,1.24 z"
        id="path2"
        style="fill:#0088fc;fill-opacity:1"/>
    </g>
  </svg>

</template>

<script>
export default {
  name: 'Verified',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
