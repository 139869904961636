<template>
  <v-row align="center">
    <v-col cols="auto">
      <span>{{ $tc('posts.participants.current_participants_count', totalParticipants) }}</span>
    </v-col>
    <v-col cols="auto">
      <c-btn text small transform="none"
        @click="dialog = true" style="text-transform: Capitalize">
        {{ $t('actions.add') }}
      </c-btn>
    </v-col>
    <c-dialog
      :actions="false"
      v-model="dialog"
      @input="reset">
      <template #title><h1>{{ $t('posts.participants.text') }}</h1></template>
      <v-row align="center"
        v-for="p in currentParticipants"
        :key="`current-${p.participant.id}`">
        <v-col cols="12">{{ $t('models.post.participant_chip', { number: p.index + 1}) }}</v-col>
        <v-col cols="3">
          <div class="d-flex">

            <c-img :src="p.participant.dni1Path"
                   width="80" height="80"
                   class="pointer mr-2"
                   style="border-radius: 20px"
                   @click.native="showPhoto(p, p.participant.dni1Path)"></c-img>
            <c-img :src="p.participant.dni2Path"
                   width="80" height="80"
                   class="pointer mr-2"
                   style="border-radius: 20px"
                   @click.native="showPhoto(p, p.participant.dni2Path)"></c-img>
            <!--<v-img class="document-thumb" :src="p.participant.dni1Path" />
            <v-img class="document-thumb" :src="p.participant.dni2Path" />-->
          </div>
        </v-col>
        <v-col cols="2 ml-4">
          <c-img :src="p.participant.selfiePath"
                 width="80" height="80"
                 class="pointer"
                 style="border-radius: 20px"
                 @click.native="showPhoto(p, p.participant.selfiePath)"></c-img>
          <!--<v-img class="document-thumb" :src="p.participant.selfiePath" />-->
        </v-col>
        <v-col cols="4">
          <c-btn text small transform="none"
            @click="downloadAgreement(p.participant.agreementPath)">
            {{ $t('posts.participants.download_agreement') }}
          </c-btn>
          <!--<a id="hiddenLink" :href="p.participant.agreementPath" class="d-none" download></a>-->
        </v-col>
        <v-col cols="2">
          <c-btn icon @click="deleteParticipant(p.participant)">
            <v-icon>delete</v-icon>
          </c-btn>
        </v-col>
      </v-row>
      <v-row align="center"
        v-for="participant,index in added"
        :key="`added-${index}`">
        <v-col cols="12">{{ $t('models.post.added_participant_chip', { number: index + 1}) }}</v-col>
        <v-col cols="3">
          <div class="d-flex">
            <v-img class="document-thumb" :src="participant.dni1.preview" />
            <v-img class="document-thumb" :src="participant.dni2.preview" />
          </div>
        </v-col>
        <v-col cols="2">
          <v-img class="document-thumb" :src="participant.selfie.preview" />
        </v-col>
        <v-col cols="5">
          <span>{{ $t('posts.participants.agreement_uploaded')}}</span>
        </v-col>
        <v-col cols="2">
          <c-btn icon @click="deleteAdded(index)">
            <v-icon>delete</v-icon>
          </c-btn>
        </v-col>
      </v-row>
      <ParticipantRowForm
        v-for="participant, index in partiallyAdded"
        :key="`added-${index}`"
        class="bordered mb-2"
        :value="participant"
        @finish="updateAdded(index, $event)"
        @remove="removeNewParticipant(index)"
      />
      <v-card-actions>
        <c-btn color="secondary"
          @click="addNewParticipant">
          {{ $t('actions.add_more') }}
        </c-btn>
      </v-card-actions>
      <v-card-actions>
        <v-spacer />
        <c-btn text color="secondary" @click="reset">
          {{ $t('actions.cancel') }}
        </c-btn>
        <c-btn :disabled="!!partiallyAdded.length" @click="submit">
          {{ $t('actions.ok') }}
        </c-btn>
      </v-card-actions>
    </c-dialog>
    <v-dialog v-model="showCarousel">
      <carousel v-if="showCarousel" v-model="showCarousel"
                :resources="resources" :selected="selectedResource"
                @hideCarousel="showCarousel = false"
      ></carousel>
    </v-dialog>
  </v-row>
</template>
<script>
import ParticipantRowForm from './ParticipantRowForm.vue'

export default {
  name: 'EditParticipantList',
  components: { ParticipantRowForm },
  props: {
    participants: { type: Object, default: () => ({ current: [], added: [], deleted: [] }) }
  },
  data () {
    return {
      dialog: false,
      showForm: false,
      current: [],
      added: [],
      partiallyAdded: [],
      deleted: [],
      showCarousel: false,
      participant: null,
      selectedFile: null
    }
  },
  computed: {
    totalParticipants () {
      return (this.current ? this.current.length : 0) + this.added.length - this.deleted.length
    },
    currentParticipants () {
      return this.current
        .map((participant, index) => ({ index, participant }))
        .filter(p => !this.isDeleted(p.participant))
    },
    hasChanged () {
      return this.added.length + this.deleted.length
    },
    selectedResource () {
      return this.resources.find(r => r.url === this.selectedFile)
    },
    resources () {
      return [
        { url: this.participant.participant.dni1Path },
        { url: this.participant.participant.dni2Path },
        { url: this.participant.participant.selfiePath },
      ]
    }
  },
  watch: {
    participants: {
      handler () {
        this.reset()
      },
      immediate: true
    }
  },
  methods: {
    showPhoto (p, pic) {
      this.participant = p
      this.selectedFile = pic
      this.showCarousel = true
    },
    reset () {
      this.current = this.participants.current
      this.added = JSON.parse(JSON.stringify(this.participants.added))
      this.deleted = JSON.parse(JSON.stringify(this.participants.deleted))
      this.showForm = false
      this.dialog = false
    },
    resetChanges () {
      this.added = []
      this.deleted = []
      this.partiallyAdded = []
      this.submit()
    },
    isDeleted(participant) {
      return this.deleted.find(p => p.id === participant.id)
    },
    addNewParticipant() {
      this.partiallyAdded.push({ dni1: null, dni2: null, selfie: null, agreement: null })
    },
    removeNewParticipant(index) {
      this.partiallyAdded.splice(index, 1)
    },
    updateAdded(index, newAdded) {
      this.added.push(newAdded)
      setTimeout(() => {
        this.removeNewParticipant(index)
      }, 50)
    },
    deleteAdded(index) {
      this.added.splice(index)
    },
    deleteParticipant(participant) {
      this.deleted.push(participant)
    },
    downloadAgreement (path) {
      window.open(path, '_blank')
      // document.getElementById('hiddenLink').click()
    },
    submit () {
      this.$emit('change', { added: this.added, deleted: this.deleted })
      this.dialog = false
      this.showForm = false
    }
  }
}
</script>
<style scoped>
.document-thumb {
  height: 56px;
  width: 56px;
}
</style>
