<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-icon><v-icon color="success">check_box</v-icon></v-list-item-icon>
      <v-list-item-content>
        <i18n path="withdraws.withdraw_methods.e_pay_services.info.description_1" tag="span">
          <a place="link" href="https://epayservices.com/en/cash-remittance.html" rel="_blank">
            {{ $t('words.here') }}
          </a>
        </i18n>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon><v-icon color="success">check_box</v-icon></v-list-item-icon>
      <v-list-item-content>
        <i18n path="withdraws.withdraw_methods.e_pay_services.info.description_2" tag="span">
          <a place="link" href="https://online.epayservices.com/register" rel="_blank">ePayServices</a>
        </i18n>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon><v-icon color="success">check_box</v-icon></v-list-item-icon>
      <v-list-item-content>{{ $t('withdraws.withdraw_methods.e_pay_services.info.description_3') }}</v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'EPayMethodInfo'
}
</script>
