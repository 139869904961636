<template>
  <c-dialog v-model="showDialog" @close="close"  :actions="false">
    <div>
      <iframe
        :src="buildYotiSrc()"
        v-if="sessionId && clientSessionToken"
        style="height:605px; width:100%; border:none;"
        allow="camera"
      ></iframe>
      <div class="progress" v-else>
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <v-row>
        <c-btn @click="close" class="ml-auto" color="secondary">
          {{ $t('actions.close') }}
        </c-btn>
      </v-row>
    </div>
  </c-dialog>
</template>

<script>
import UserApi from '@/api/UserApi'
import config from '@/project'
import { ProfileActions } from '@/store'

export default {
  name: 'verifyYoti',
  props: {
    value: Boolean,
    subscriptionPrice: {
      type: Number,
      default: 500
    },
    username: String,
    before: Boolean,
    personalData: Object,
    accountData: Object
  },

  data () {
    return {
      sessionId: null,
      clientSessionToken: null
    }
  },
  computed: {
    showDialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    async before () {
      if (this.before) {
        await this.yotiSession()
      }
    }
  },
  async mounted () {
    await this.yotiSession()
  },
  methods: {
    async yotiSession () {
      this.sessionId = null
      this.clientSessionToken = null
      const userData = {
        locale: this.$vuetify.lang.current,
        username: 'alisan',
        subscriptionPrice: 500
      }
      const {
        data,
        error
      } = await UserApi.yoti(userData)
      if (!error) {
        this.sessionId = data.sessionId
        this.clientSessionToken = data.clientSessionToken
        this.$store.dispatch(
          ProfileActions.UpdateYotiSession,
          data.sessionId
        )
      }
    },
    buildYotiSrc () {
      return `${config.yoti.url}?sessionID=${this.sessionId}&sessionToken=${this.clientSessionToken}`
    },
    emitShowDialog (value) {
      this.$emit('input', value)
    },
    async close () {
      this.showDialog = false
      await this.$store.dispatch(ProfileActions.Fetch, {
        username: this.username,
        force: true
      })
    }
  }
  /*  async mounted() {
    await this.yotiSession();
  } */
}
</script>
<style lang="scss" scoped>
.progress {
  height: 100%;
  width: 100%;
  min-height: 605px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
