<template>
  <div>
    <ListAttachView :message="message" :blocked="isBlock"/>
    <AttachUnlock
      :message="message"
      :isPurchased="isPurchased"
      @forUnblock="forUnblock"
    />
    <WarningDialog
      :show="showBuyMessage"
      :textWarning="textBuy"
      :textButtonOK="$t('actions.buy').toUpperCase()"
      @handlerButtonCancel="showBuy"
      @handlerButtonOK="buyMessages"
      :loading="loadingButton"
    />

    <Dialog
      :show="showLoadFunds"
      @hide="() => (showLoadFunds = !showLoadFunds)"
      :title="$t('actions.load_funds').toUpperCase()"
    >
      <template slot="bodyDialog">
        <div>
          <LoadFunds :price="messageLocal.price"/>
        </div>
      </template>
    </Dialog>

    <WarningDialog
      :show="showErrorBuyMessage"
      :textWarning="textWarningWallet"
      :textButtonOK="$t('actions.reload').toUpperCase()"
      @handlerButtonCancel="showErrorBuy"
      @handlerButtonOK="showFunds">
      <template #plus>
        <p style="font-size: 12px;">
          {{
            `*${$t('subscriptions.vat_explain', {price: $currency(getPriceExes)})}`
          }}
        </p>
      </template>
    </WarningDialog
    >

  </div>
</template>

<script>
import AttachUnlock from '@/components/chat/components/attachView/AttachUnlock'
import ListAttachView from '@/components/chat/components/attachView/ListAttachView'
import WarningDialog from '@/components/chat/component/WarningDialog.vue'
import Dialog from '@/components/chat/component/Dialog.vue'

import LoadFunds from '@/components/chat/components/LoadFunds.vue'
import {mapState} from 'vuex'
import {PostActions, ChatActions} from '@/store'
import buyAttach from '@/components/chat/mixin/buyAttach'

export default {
  name: 'AttachView',
  mixins: [buyAttach],
  components: {
    AttachUnlock,
    ListAttachView,
    WarningDialog,
    LoadFunds,
    Dialog
  },
  props: {
    message: Object
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer', 'idAdmin']),
    isPurchased() {
      return this.message.isPurchased && this.message.price > 0
    },
    isAutorId() {
      return this.currentUser.id === this.message.userId
    },
    hasReference() {
      return this.message.attachments.length > 0
    },
    isBlock() {
      return !(
        this.message &&
        (!this.message.price || this.message.isPurchased)
      )
    },
  },
  methods: {

    async loadReference() {
      if (!this.hasReference) return
      this.loading = true
      if (!this.message.resourceId) return
      this.reference = await this.$store.dispatch(PostActions.Fetch, {
        id: this.message.resourceId,
        force: true
      })
      this.loading = false
      this.$emit('resource-loaded')
    },
    selectedResource(index = 0) {
      this.$store.dispatch(
        ChatActions.CarouselItemsList,
        this.message.attachments
      )
      this.$store.dispatch(ChatActions.CarouselItemSelected, index)
      this.$store.dispatch(ChatActions.ShowCarousel, true)
    },
    goToResource() {
      if (!this.hasReference) {
        return
      }
      if (this.isVisible) {
        this.$router.push({
          name: 'post',
          params: {uuid: this.reference.id}
        })
      }
    },
    /* emitRefreshMesage(message) {
      this.$emit("refresh-message", message);
    }, */
    forUnblock() {
      this.showBuy()
    }
  },
  mounted() {
    this.loadReference()
  }
}
</script>
