import api from '@/api/Api'

export default {
  create (path, provider) {
    return api.post('/media', { path, provider })
  },
  list () {
    return api.get('/media')
  },
  listType (query) {
    return api.get(`/media`, query)
  },
  find (id) {
    return api.get(`/media/${id}`)
  },
  update (id) {
    return api.put(`/media/${id}`)
  },
  delete (id) {
    return api.delete(`/media/${id}`)
  }
}
