<template>
  <v-form
    v-model="validForm" ref="form"
    @submit.prevent="submit">
    <v-menu v-model="showMenu"
      offset-y>
      <template #activator="{ on }">
        <v-text-field
          ref="searchField"
          id="searchField"
          autofocus
          rounded
          filled
          dense
          single-line
          hide-details
          clearable
          append-icon="search"
          :label="$t('actions.search')"
          :class="searchClass"
          v-model="search"
          @blur="lostFocus"
          @input="startLoading"
          @click:clear="reset"
          @click:append="submit">
        </v-text-field>
      </template>
      <v-card flat :loading="loading">
      <v-list dense :class="searchClass">
        <span v-if="!bannedWords">
          <template v-for="user in items">
            <v-list-item :key="user.username">
              <v-list-item-content>
                <UserAvatar :profile="false" :user="user" />
              </v-list-item-content>
            </v-list-item>
          </template>
        </span>
        <v-list-item @click="submit">
          <v-list-item-content>
            {{ $t('actions.show_all') }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    </v-menu>
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import table from '@/components/mixins/table'
import validatable from '@/components/mixins/validatable'
import UserAvatar from '@/components/users/UserAvatar'
import UserApi from '@/api/UserApi'
import { existBannedWords } from '@/plugins/form_validations'
import config from '@/project'

export default {
  name: 'Search',
  mixins: [table(UserApi), validatable('form')],
  components: { UserAvatar },
  props: { includeTags: Boolean },
  data () {
    return {
      validForm: true,
      showMenu: false,
      search: '',
      loadItemsTimeout: 250,
      perPage: 3,
      forbWord: ['scat',
        'shit',
        'poop',
        'farts',
        'pooping',
        'smearing',
        'toilet',
        'diapers',
        'piss',
        'enema',
        'mierda',
        'caca',
        'pedo',
        'cagar',
        'baño',
        'pañales',
        'mear',
        'pis',
        'enema'],
      config
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    searchClass () {
      return {
        collapsed: this.isMobile
      }
    },
    bannedWords () {
      const text = this.search.split('')
      if (config.project === 'loverfans') {
        text.forEach((word) => {
          if (this.forbWord.includes(word.toLowerCase())) {
            return true
          }
        })
      }
      return existBannedWords(this.search.toLowerCase())
    },
    query () {
      return {
        includeTags: this.includeTags || false,
        username: this.search,
        role: 'influencer'
      }
    }
  },
  methods: {
    reset () {
      this.showMenu = false
      this.search = ''
      this.lostFocus()
    },
    onSubmit () {
      this.$router.push({ name: 'search', query: { search: this.search } })
    },
    lostFocus () {
      if (!this.showMenu) {
        this.$emit('focused')
      }
    },
    onLoadStart () {
      this.showMenu = true
    },
    onLoadItems () {
      return !this.bannedWords
    }
  },
  mounted () {
    setTimeout(function () {
      document.getElementById('searchField').focus()
    }, 20)
  }
}
</script>

<style lang="scss" scoped>
.search {
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;

  &.v-input--is-focused {
    width: 100%;
  }
}
</style>
