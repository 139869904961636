import Vue from 'vue'

export default (store, { query, action }) => {
  return new Vue({
    render () {
      return {}
    },
    data () {
      return {
        loading: false,
        perPage: 15,
        items: [],
        nextCursor: '',
        query: query,
        loadOnMounted: true
      }
    },
    computed: {
      reversedItems () {
        return [...this.items].reverse()
      }
    },

    watch: {
      perPage (vaue) {
        this.resetCursor(true)
      },
      query (value) {
        this.resetCursor(false)
        this.startLoading()
      }
    },

    methods: {
      resetCursor (reload = false) {
        this.nextCursor = ''
        this.items = []
        if (reload) {
          this.cancelLoading()
          this.loadItems()
        }
      },

      stopLoading () {
        this.cancelLoading()
        this.onLoadStop()
        clearTimeout(this.timeout)
      },

      startLoading () {
        this.stopLoading()
        if (this.onLoadStart() === false) {
          return
        }
        this.timeout = setTimeout(() => {
          this.loadItems()
        }, 250)
      },

      cancelLoading () {
        if (this.cancelToken) {
          this.cancelToken.cancel()
        }
        this.cancelToken = null
      },

      async loadItems () {
        if (this.loading || this.onLoadItems() === false) {
          return
        }
        this.loading = true
        const query = { ...(this.query || {}) }
        query.nextCursorToken = this.nextCursor
        query.limit = query.limit || this.perPage
        this.cancelToken = query.cancelToken
        const { data, error } = await store.dispatch(action, query)
        this.cancelToken = null
        if (!error) {
          this.items.splice(this.items.length, 0, ...data.data.map(this.mapItem))
          if (data.metadata.nextCursorToken) {
            this.nextCursor = data.metadata.nextCursorToken
          }
        }

        this.loading = false
        this.onLoadFinished(data, error)
      },

      unshiftItem (item) {
        this.items.unshift(item)
      },

      pushItem (item) {
        this.addItem(item)
      },

      addItem (item, comparator) {
        if (!comparator) {
          this.items.push(item)
        } else {
          const index = this.items.findIndex(comparator)
          this.items.splice(index, 0, item)
        }
      },

      removeItem (finder) {
        const postIndex = this.items.findIndex(finder)
        if (postIndex !== -1) {
          this.items.splice(postIndex, 1)
        }
      },

      mapItem (item) {
        return item
      },

      // Callbacks
      onLoadStart () {

      },

      onLoadItems () {

      },

      onLoadFinished () {

      },

      onLoadStop () {

      }
    },

    mounted () {
      if (this.loadOnMounted) this.loadItems()
    }
  })
}
