<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-icon><v-icon color="success">check_box</v-icon></v-list-item-icon>
      <v-list-item-content>{{ $t('withdraws.withdraw_methods.btc.info.description_1') }}</v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon><v-icon color="success">check_box</v-icon></v-list-item-icon>
      <v-list-item-content>
        <i18n path="withdraws.withdraw_methods.btc.info.description_2" tag="span">
          <a place="link" href="https://www.coinbase.com/signup" rel="_blank">Coinbase</a>
        </i18n>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon><v-icon color="success">check_box</v-icon></v-list-item-icon>
      <v-list-item-content>{{ $t('withdraws.withdraw_methods.btc.info.description_3') }}</v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'BtcMethodInfo'
}
</script>
