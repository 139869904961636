<template>
  <div>
    <section @click="selectedItem" :class="classSelected">
      <!-- <input
      v-if="withCheck"
      type="checkbox"
      @click="selectedItem"
      class="check_vault"
    /> -->
      <div v-if="isImage" class="imagen" :class="classMini">
        <img :src="row.url" />
      </div>
      <div v-if="isVideo" class="imagen" :class="classMini">
        <img :src="row.poster" />
      </div>
      <div v-if="isAudio" class="audio" :class="classMini">
        <AudioPlayer :src="row.url" :iconBottom="withCheck" />
      </div>
    </section>
    <div class="icon-delete" v-if="onDelete">
      <div class="icon-delete-content" :class="dark">
        <IconButton :size="10" type="delete" @handler="showWarnDelete = true" />
      </div>
    </div>
    <WarningDialog
      :show="showWarnDelete"
      :textWarning="$t('application.chat_setting.vault.delete_warning')"
      :textButtonOK="$t('actions.delete').toUpperCase()"
      @handlerButtonOK="deleteFileVault"
      @handlerButtonCancel="showWarnDelete = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AudioPlayer from "@/components/chat/components/viewFiles/AudioPlayer";
import IconButton from "@/components/chat/component/IconButton";
import WarningDialog from "@/components/chat/component/WarningDialog";

export default {
  name: "ListVaultRow",
  components: { AudioPlayer, IconButton, WarningDialog },
  props: {
    row: { type: Object },
    index: { type: Number, default: 0 },
    withCheck: { type: Boolean, default: false },
    classSelect: { type: Boolean, default: false },
    mini: { type: Boolean, default: false },
    onDelete: { type: Boolean, default: false }
  },
  data() {
    return {
      showWarnDelete: false,
    };
  },
  computed: {
    ...mapState("chats", ["viewAttachmentForm"]),
    isImage() {
      return this.row.type === "image";
    },
    isAudio() {
      return this.row.type === "audio";
    },
    isVideo() {
      return this.row.type === "video";
    },
    classMini() {
      return {
        mini: this.mini,
        dark: this.$vuetify.theme.dark
      };
    },
    dark() {
      return {
        dark: this.$vuetify.theme.dark
      };
    },
    classSelected() {
      const index = this.viewAttachmentForm.findIndex(
        f => f.id === this.row.id
      );
      return {
        selected: index !== -1 && this.classSelect
      };
    }
  },
  methods: {
    selectedItem() {
      this.$emit("view-file", this.row, this.index);
    },
    deleteFileVault() {
      this.$emit("delete-file", this.row);
      this.showWarnDelete = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.mini {
  width: 60px !important;
  height: 60px !important;
}
.imagen {
  width: 100px;
  cursor: pointer;
  height: 100px;
  padding: 4px;
  border: solid white 1px;
   &.dark{
     border-color:#2d3237 !important
   }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  /*   &:active {
    border: solid var(--v-primary-base) 1px;
  } */
}
.selected {
  border: solid var(--v-primary-base) 1px;
}
.audio {
  width: 100px;
  height: 100px;
  cursor: pointer;
  padding: 5px;
  .audio-play {
    width: 100%;
    height: 100%;
  }
}
.check_vault {
  width: 15px;
  height: 15px;
  accent-color: var(--v-primary-base);
  position: relative;
  top: 26px;
  /* right: 0; */
  left: 80px;
}
.icon-delete {
  display: flex;
  margin-top: -15px;
  justify-content: flex-end;
}
.icon-delete-content {
  background: white;
  border: solid 1px rgb(0 0 0 / 87%);
  border-radius: 50px;
  padding: 3px 0px;
  &.dark {
    background: #2d3237 !important;
    border-color:white
  }
}
</style>
