<template>
  <section>
    <div class="col-12 list-chip" v-if="selectedUser.length > 0">
      <span class="chip" v-for="(item, index) in selectedUser" :key="index"
        ><img
          v-if="item.isList"
          src="/chat/ico-list.svg"
          height="10"
          width="10"
          class="icon-list"/>
        {{ item.name }}
        <img
          src="/chat/ico-close.svg"
          height="8"
          width="8"
          class="icon-close"
          @click="onDeleteUser(item)"
      /></span>
    </div>
    <slot />
    <div
      :tabindex="1"
      @blur="
        () => {
          users = [];
          search = '';
        }
      "
    >
      <div class="search-chat">
        <input
          type="text"
          v-model="search"
          autocomplete="off"
          name="newsletter"
          :placeholder="$t('messages.user_search')"
          class=""
          @input="listItems"
          @focus="loadListView"
        />
      </div>
      <div
        class="list-user"
        :style="`right:${widthRem}rem;left: ${widthRem}rem;`"
        :class="classDarkTheme"
      >
        <div class="row my-8 center" v-if="loading">
          <v-progress-circular color="#E400A0" indeterminate class="p-4">
          </v-progress-circular>
        </div>
        <ListView
          v-if="!loading"
          no-gutters
          :items="users"
          cols="12"
          item-class="border-bottom"
          :dark="darkTheme"
          align="start"
          justify="start"
        >
          <template #default="{ item }">
            <UserSearch
              :user="item"
              :eventSelectUser="true"
              @selectUser="onSelectUser"
            ></UserSearch>
          </template>
        </ListView>
      </div>
    </div>
  </section>
</template>

<script>
import UserSearch from "@/components/chat/components/UserSearch";
import UserApi from "@/api/UserApi";
import ConversationsApi from "@/api/conversations/ConversationsApi";
import { mapState } from "vuex";

export default {
  name: "InputSearchUser",
  components: { UserSearch },
  props: {
    reset: { type: Boolean, default: false },
    defaultContent: { type: Array },
    searchList: { type: Boolean, default: false },
    includedListUser: { type: Boolean, default: false },
    widthRem: { type: Number, default: 5 }
  },
  data() {
    return {
      message: false,
      broadcastMessage: false,
      users: [],
      loading: false,
      timeOut: null,
      search: "",
      selectedUser: [],

      followers: [],
      usersList: []
    };
  },
  computed: {
    ...mapState("preferences", ["darkTheme"]),
    classDarkTheme() {
      return {
        dark: this.darkTheme
      };
    }
  },
  watch: {
    defaultContent() {
      if (this.defaultContent.length > 0) {
        this.defaultContent.map(u => {
          u.name = u.username;
          this.onSelectUser(u);
        });
      }
    },
    reset() {
      if (this.reset) {
        this.selectedUser = [];
        this.users = [];
      }
    },
    searchList() {
      this.users = [];
      this.loadListView();
    },
    search() {
      if (this.search.length === 0) {
        this.users = [];
        this.loadListView();
      }
    }
  },
  created() {
    this.listFollowers();
    if (this.includedListUser) {
      this.listUsers();
    }
  },
  methods: {
    loadListView() {
      if (!this.searchList) {
        this.users = this.followers;
      } else {
        this.users = this.usersList;
      }
    },
    async listUsers() {
      const { data, error } = await ConversationsApi.findList({
        search: this.search
      });
      if (!error) {
        const isList = true;
        this.usersList = this.setList(data.data, isList);
      }
    },
    async listFollowers() {
      const { data, error } = await UserApi.list({
        followers: true,
        perPage: 90
      });
      if (!error) {
        this.followers = this.setList(data.data);
      }
    },
    async listUserSearch() {},
    setList(data, isList = false) {
      const users = [];
      if (isList) {
        data.map(list => {
          users.push({
            name: list.name,
            isList: true,
            avatar: require("../../../../public/chat/ico-list2.svg"),
            users: list.users.map(u => u.username)
          });
        });
      } else {
        data.forEach(user => {
          users.push({
            name: user.username,
            isList: isList,
            avatar: user.avatarS3Route
          });
        });
      }
      return users;
    },
    listItems() {
      clearTimeout(this.timeOut);
      if (this.search.length == 0) {
        this.users = [];
        return;
      }
      if (this.searchList) {
        this.timeOut = setTimeout(async () => {
          this.loading = true;
          const that = this;
          that.users = [];
          const { data, error } = await ConversationsApi.findList({
            search: this.search
          });
          if (!error) {
            const isList = true;
            that.users = this.setList(data.data, isList);
            /*  data.data.map(list => {
              that.users.push({
                name: list.name,
                isList: true,
                avatar: require("@/components/chat/assets/images/ico-list2.svg"),
                users: list.users.map(u => u.username)
              });
            }); */
          }
          this.loading = false;
          return that.users;
        }, 1000);
      } else {
        this.timeOut = setTimeout(async () => {
          this.loading = true;
          const that = this;
          that.users = [];
          const { data, error } = await UserApi.list({ username: this.search });
          if (!error) {
            that.users = this.setList(data.data);
            /*  data.data.forEach(user => {
              that.users.push({
                name: user.username,
                isList: false,
                avatar: user.avatarS3Route
              });
            }); */
          }
          this.loading = false;
          return that.users;
        }, 1000);
      }
    },
    onSelectUser(user) {
      const index = this.selectedUser.findIndex(u => u.name === user.name);
      if (index == -1) {
        this.selectedUser.push(user);
      }
      this.$emit("listUser", this.selectedUser);
    },
    onDeleteUser(user) {
      const index = this.selectedUser.findIndex(u => u.name === user.name);
      if (index !== -1) {
        this.selectedUser.splice(index, 1);
      }
      this.$emit("listUser", this.selectedUser);
    }
  }
};
</script>

<style lang="scss" scoped>
.search-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  height: 37px;

  input {
    width: 100%;
    display: inline-block;
    border: 1px solid #d7d7d7;
    height: 40px;
    padding: 7px 15px;
    margin: 4px;
    font-size: 0.9375em;
    border-radius: 30px;
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.center {
  justify-content: center;
  align-content: center;
}
.list-chip {
  display: flex;
  flex-wrap: wrap;
}
.chip {
  white-space: nowrap;
  background-color: var(--v-primary-base);
  border-radius: 100px;
  color: white;
  font-size: 13px;
  font-family: "Montserrat-Bold", sans-serif;
  padding: 1px 5px;
  margin: 2px;
  z-index: 1;
}
.list-user {
  /* display: flex;
  width: fit-content; */
  margin-top: -3px;
  /*   width: 87%; */
  /*  margin-left: 4%;
  margin-right: 8%; */
  /* right: 5rem;
  left: 5rem; */
  // border: solid red 1px;
  position: absolute;
  background: #f6f6f6;
  &.dark {
    background: black;
  }
  z-index: 2;

  max-height: 20rem;
  overflow-x: hidden;
}
.icon-close {
  filter: brightness(0) invert(1);
  cursor: pointer;
}
.icon-list {
  filter: brightness(0) invert(1);
}
</style>
