<template>
  <section class="d-flex flex-column flex-grow-1">
    <BroadcastHeader />
    <BroadcastList />
  </section>
</template>
<script>
import BroadcastHeader from './components/BroadcastHeader'
import BroadcastList from './components/BroadcastList'

export default {
  name: 'BroadcastPage',
  components: { BroadcastHeader, BroadcastList }
}
</script>
