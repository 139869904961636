<template>
  <v-row align="center" justify="space-between">
    <v-col cols="auto" class="bold">
      <slot></slot>
    </v-col>
    <v-col cols="auto">
      <c-btn icon
        :color="saveColorClass"
        hover-color="deep-purple darken-1"
        :tooltip="$t('actions.save')"
        @click.stop.prevent="toogleSave">
        <v-icon v-if="product.saved">bookmark</v-icon>
        <v-icon v-else>bookmark_border</v-icon>
      </c-btn>
      <ShareButton
        :text="$t('posts.share_text')"
        :url="url" />
    </v-col>
  </v-row>
</template>
<script>
import actionable from '@/components/mixins/actionable'
import ProductApi from '@/api/ProductApi'
import ShareButton from '@/components/custom/ShareButton'

export default {
  name: 'ProductActions',
  mixins: [actionable('product', ProductApi)],
  components: { ShareButton },
  props: {
    product: Object
  },
  computed: {
    url () {
      const origin = window.location.origin
      return `${origin}/products/${this.product.id}`
    }
  }
}
</script>
