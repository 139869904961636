<template>
  <div class="col">
    <div class="row" style="">
      <BackRow style="position: absolute"/>
      <Logo class="center col-12" color="black"/>
    </div>

    <div class="center mt-3">
      <p class="text-month">{{ $t('landing.title1') }}</p>
    </div>
    <v-row class="center">
      <p class="text-black">BLACK</p>
      <p class="text-friday">Friday</p>
    </v-row>
    <v-row class="text-1 center">
      <p>{{ $t('landing.text1') }}</p>
    </v-row>
    <v-row class="text-1 center">
      <p>{{ $t('landing.text2') }}</p>
    </v-row>
    <v-row class="text-2 center spacer-top">
      <p>
        {{ $t('landing.text3') }}
        <br/><br/>
        <span v-html="$t('landing.text4')"></span>
        <br/><br/>
        {{ $t('landing.text5', { project: project.locales.project }) }}
        <br/>
        <br/>
        <b @click="goResources" class="link_dow">{{ $t('landing.text6') }}</b>
      </p>
    </v-row>
    <div class="space center">
      <div class="center-color"></div>
    </div>
    <v-row>
      <p class="text-title">{{ $t('landing.title2') }}</p>
      <br/>
      <br/>
    </v-row>

    <v-row>
      <p class="text-4">
        <span class="text-title">1 .</span> {{ $t('landing.text7') }}
      </p>
    </v-row>
    <v-row>
      <p class="text-4">
        <span class="text-title">2 .</span>
        <span v-html="$t('landing.text8',{project:project.locales.project})"></span>
      </p>
    </v-row>
    <v-row>
      <p class="text-4">
        <span class="text-title">3 .</span>
        <span v-html="$t('landing.text9')"></span>
      </p>
    </v-row>
    <div class="space  center">
      <div class="center-color"></div>
    </div>
    <v-row>
      <p class="text-title">{{ $t('landing.title3') }}</p>
      <br/>
      <br/>
    </v-row>
    <v-row>
      <p v-html="$t('landing.text10')"></p>
      <br/><br/>
      <span v-html="$t('landing.text11')"></span>
    </v-row>
    <v-row class="center spacer-top spacer-bottom ">
      <v-img :src="getSrc" class="img"></v-img>
    </v-row>
    <v-row>
      <p v-html="$t('landing.text12')"></p>
    </v-row>
    <v-row class="mt-5">
      <b @click="goResources" class="link_dow">{{ $t('landing.text13') }}</b>
    </v-row>
    <div class="space  center">
      <div class="center-color"></div>
    </div>
    <v-row class="text-title center spacer-top">
      <p style="text-align: center;">
        {{ $t('landing.title4') }}</p>
    </v-row>
    <v-row class="center spacer-top"
    >
      <button type="button" class="button-green" @click="joinTelegram">
        <b>{{ $t('landing.button') }}</b>
      </button>
    </v-row
    >
    <v-row class="center spacer-top spacer-bottom">
      <p style="text-align: center;">
        {{ $t('landing.text14') }}
        <a
          :href="`mailto:stars@${project.project}.com`"
          target="_blank"
          style="color:var(--unnamed-color-base)"
        >
          stars@{{ project.project }}.com
        </a>
      </p>
    </v-row>
  </div>
</template>

<script>
import project from '@/project'
import Logo from '@/views/landing/specialCampaign/components/Logo'
import messages from '@/views/landing/specialCampaign/locales/all.json'

export default {
  name: 'CreatorLanding',
  components: { Logo },
  i18n: { messages },
  computed: {
    getSrc () {
      return require(`@/views/landing/specialCampaign/resources/img-example.jpg`)
    }
  },
  data () {
    return {
      project
    }
  },
  created () {
    const root = document.documentElement
    if (this.project.project === 'loverfans') {
      const baseColor = '#ffffff'
      root.style.setProperty('--unnamed-color-banner', baseColor)
    }
    if (project.project === 'scatbook') {
      const bannerColor = '#000000'
      root.style.setProperty('--unnamed-color-banner', bannerColor)
      const baseColor = '#B0601E'
      root.style.setProperty('--unnamed-color-base', baseColor)
    }
    if (this.project.project === 'darkfans') {
      const baseColor = '#000000'
      root.style.setProperty('--unnamed-color-banner', baseColor)
    }
  },
  methods: {
    joinTelegram () {
      let pr =
        this.project.project === 'loverfans'
          ? 'loverfans_creators'
          : 'joinchat/Wv13jxPxhQ5hNDJk'

      window.open(`https://t.me/${pr}`, '_blank')
    },
    goResources () {
      this.$router.push('/resources')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./_styles";

.spacer-top {
  margin-top: 48px;
}

.spacer-bottom {
  margin-bottom: 48px;
}

.banner {
  background: var(--unnamed-color-banner) 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #f7195329;
  opacity: 1;
  padding-top: 24px;
  padding-bottom: 24px;
}

.text-month {
  text-align: left;
  font: normal normal normal 51px/74px Satisfy-Regular !important;
  letter-spacing: 0px;
  color: var(--unnamed-color-base);
  opacity: 1;
  position: relative;
  top: 24px;
}

.text-black {
  text-align: left;
  font: normal normal 900 59px/72px Montserrat !important;
  letter-spacing: 0px;
  opacity: 1;
  color: var(--unnamed-color-base);
  background: black;
  padding: 5px;
}

.text-friday {
  text-align: left;
  font: normal normal normal 62px/89px Satisfy-Regular !important;
  letter-spacing: 0px;
  color: var(--unnamed-color-base);
  position: relative;
  top: 0;
}

.text-1 {
  text-align: center;
  font: normal normal bold 30px/41px Montserrat !important;
  letter-spacing: 0px;
  color: var(--unnamed-color-base);
  opacity: 1;
}

.text-2 {
  text-align: left;
  letter-spacing: 0px;
  /* color: #000000; */
  opacity: 1;
}

.space {
  margin-top: 48px;
  margin-bottom: 48px;
  width: 100%;
  height: 2px;
  background: #a0a0a0c8 0% 0% no-repeat padding-box;
}

.center-color {
  width: 88px;
  height: 2px;
  background: var(--unnamed-color-base) 0% 0% no-repeat padding-box;
}

.text-title {
  font: normal normal bold 18px/41px Montserrat !important;
  letter-spacing: 0px;
  color: var(--unnamed-color-base);
}

.text-left {
  text-align: left;
}

.text-4 {
  text-align: left;
  font: normal normal medium 16px/22px Montserrat !important;
  letter-spacing: 0px;
  /* color: #000000; */
}

.button-green {
  width: 384px;
  height: 40px;
  background: var(--unnamed-color-38cc0a) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: white;
}

.link_dow {
  cursor: pointer;
  text-decoration: underline;
}

.img {
  max-width: 400px;
  width: 100%;
  height: 100%;
}
</style>
