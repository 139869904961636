<template>
  <v-row no-gutters>
    <v-col cols="12" class="semibold truncate" >{{ post.title }}</v-col>
    <v-col cols="12" class="post-card-info x-small-text">
      <span>{{ $date(post.publishedAt) }}</span>
      <UserAvatar :profile="false" small :user="post.author" />
    </v-col>
  </v-row>
</template>
<script>
import UserAvatar from '@/components/users/UserAvatar'

export default {
  name: 'PostCardExtension',
  components: { UserAvatar },
  props: {
    post: Object
  }
}
</script>
<style lang="scss" scoped>
.post-card-info {
  color: #546e7a;
  .name, .username {
    color: #546e7a;
  }
  i {
    color: rgba(0, 0, 0, 0.38);
  }
}
</style>
