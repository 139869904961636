import Api from '@/api/Api'
// TODO Move everything to stores
export default (api, apiMethod = 'list') => ({
  data () {
    return {
      loading: false,
      perPage: 15,
      items: [],
      nextCursor: '',
      hasMoreElements: true,
      loadOnMounted: true
    }
  },
  computed: {
    reversedItems () {
      return [...this.items].reverse()
    }
  },

  watch: {
    perPage (vaue) {
      this.resetCursor(true)
    },
    query (value) {
      this.resetCursor(false)
      this.$emit('update:filter', value)
      this.startLoading()
    }
  },

  methods: {
    resetCursor (reload = false) {
      this.nextCursor = ''
      this.items = []
      if (reload) {
        this.loadItems()
      }
    },

    stopLoading () {
      this.onLoadStop()
      if (this.cancelToken) {
        this.cancelToken.cancel()
      }
      clearTimeout(this.timeout)
    },

    startLoading () {
      this.stopLoading()
      if (this.onLoadStart() === false) {
        return
      }
      this.timeout = setTimeout(() => {
        this.loadItems()
      }, 250)
    },

    async loadItems () {
      if (this.loading || this.onLoadItems() === false) {
        return
      }
      this.loading = true
      const cancelToken = Api.cancelToken()
      this.cancelToken = cancelToken

      const query = this.query || {}
      query.nextCursorToken = this.nextCursor
      query.limit = query.limit || this.perPage
      const {
        data,
        error
      } = await api[apiMethod](query, { cancelToken: cancelToken.token })
      if (!error) {
        this.items.splice(this.items.length, 0, ...data.data.map(this.mapItem))
        if (data.metadata.nextCursorToken) {
          this.nextCursor = data.metadata.nextCursorToken
        }
        this.hasMoreElements = data.metadata.hasMoreElements
        this.$emit('loaded', data.data)
      }
      this.cancelToken = null
      this.loading = false
      this.onLoadFinished(data, error)
    },

    async reloadItem (item, index) {
      const {
        data,
        error
      } = await api.find(item.id)
      if (!error) {
        this.items.splice(index, 1, data)
      }
    },

    mapItem (item) {
      return item
    },

    // Callbacks
    onLoadStart () {

    },

    onLoadItems () {

    },

    onLoadFinished () {

    },

    onLoadStop () {

    },
  },

  mounted () {
    if (this.loadOnMounted) this.loadItems()
  }
})
