<template>
  <c-dialog persistent
    :value="value"
    width="684"
    :actions="false"
    @input="emitShowDialog">
    <template #title>
      <h1>{{ $t('users.search_user') }}</h1>
    </template>
    <v-row align="center" v-if="allowRelations">
      <v-col cols="auto" v-if="allowFansSelect">
        <c-btn @click="multipleSelected('all_fans')">Todos los fans</c-btn>
      </v-col>
      <v-col cols="auto" v-if="allowFollowersSelect">
        <c-btn @click="multipleSelected('all_followers')">Todos los followers</c-btn>
      </v-col>
    </v-row>
    <v-card-text>
      <v-text-field
        autofocus
        rounded
        outlined
        dense
        hide-details
        class="ma-2"
        :label="$t('actions.search')"
        v-model="search"
        @input="startLoading">
      </v-text-field>
    </v-card-text>
    <v-card flat :loading="loading">
      <v-card-text class="overflow-container-wrapper">
        <div class="overflow-container custom-scrollbar">
          <template v-for="user in users">
            <slot name="user" :user="user">
              <div class="py-2 border-bottom"
                :key="user.username" >
                <UserAvatar
                  :profile="false"
                  :linkable="false"
                  :user="user"
                  @click.native.stop.prevent="selectUser(user)" />
              </div>
            </slot>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </c-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { UserActions } from '@/store'
import UserAvatar from '@/components/users/UserAvatar'

export default {
  name: 'UserSearchDialog',
  components: { UserAvatar },
  props: {
    value: Boolean,
    allowFansSelect: Boolean,
    allowFollowersSelect: Boolean,
    query: { type: Object, default: () => ({}) },
    searchAction: { type: String, default: () => UserActions.Search }
  },
  data () {
    return {
      loading: false,
      search: '',
      users: []
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    allowRelations () {
      return this.allowFansSelect || this.allowFollowersSelect
    }
  },

  methods: {
    emitShowDialog (value) {
      this.$emit('input', value)
    },
    stopLoading () {
      clearTimeout(this.timeout)
    },
    startLoading () {
      this.stopLoading()
      this.timeout = setTimeout(() => {
        this.loadUsers()
      }, 1000)
    },
    async loadUsers () {
      this.loading = true
      const query = { ...this.query, includeScat: true }
      const { data, error } = await this.$store.dispatch(this.searchAction, { ...query, username: this.search, nextCursorToken: '' })
      if (!error) {
        this.users = data.data.filter(u => u.username !== this.currentUser.username)
      }
      this.loading = false
    },

    selectUser (user) {
      console.log(user)
      this.$emit('user-selected', user)
      this.$emit('input', false)
    },

    multipleSelected (type) {
      this.$emit('multiple-selected', type)
      this.$emit('input', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.overflow-container-wrapper {
  position: relative;
  height: 250px;
  max-height: 100%;
  .overflow-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow-x: hidden;
  }
}
</style>
