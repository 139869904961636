<template>
  <section>
    <div class="title" v-if="totalitems > 0">
      <h5>Selection</h5>
      <hr />
    </div>

    <div class="list-selected">
      <div
        v-for="(item, index) in viewAttachmentForm"
        :key="index"
        @click="deleteResource(item)"
      >
        <div class="icon-close">
          <icon-button :size="10" type="close" />
        </div>
        <div>
          <ListVaultRow :row="item" class="view-attach-form" :mini="true"></ListVaultRow>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Paginations from '@/components/chat/component/Paginations'
import ListVaultRow from '@/components/chat/components/vault/ListVaultRow'
import ViewMultipleFile from '@/components/chat/components/viewFiles/ViewMultipleFile'
import IconButton from '@/components/chat/component/IconButton.vue'
import TitleOptionTab from '@/components/chat/component/TitleOptionTab'
import { ChatActions } from '@/store'
import { mapState } from 'vuex'
export default {
  name: 'ViewAttachmentForm',
  components: {
    Paginations,
    ListVaultRow,
    ViewMultipleFile,
    IconButton,
    TitleOptionTab
  },
  data () {
    return {
      perPage: 6,
      page: 1
    }
  },
  computed: {
    ...mapState('chats', ['viewAttachmentForm']),
    totalitems () {
      return this.viewAttachmentForm.length
    },
    items () {
      return this.viewAttachmentForm.map(f => {
        f.path = f.url
        f.attachmentType = f.type
      })
    }
  },
  methods: {
    pageSelected (page) {
      this.page = page
    },
    deleteResource (item) {
      this.$store.dispatch(ChatActions.SetAttachmentForm, item)
    }
  }
}
</script>
<style lang="scss" scoped>
.view-attach-form {
  &.imagen {
    width: 55px !important;
    height: 55px !important;
  }
}
.list-selected {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.title {
  justify-items: center;
  padding-top: 10px;
}
.icon-close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  position: relative;
  top: 17px;
  right: 2px;
}
</style>
