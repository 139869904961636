<template>
  <section>
    <c-dialog persistent v-if="isLoverfans"
              :key="dialogKey"
              width="684"
              class="publication-dialog"
              :css-style="{'position: relative': isLoverfans}"
              :css-class="cssClass"
              :actions="false"
              :closeaction="false"
              height="130px"
              v-bind:value="value"
              @input="close"
              @close="close">
      <template slot="title">
        <div class="row">
          <div class="col-12">
            <div class="row d-flex" style="align-items: center">
              <v-avatar size="48" v-if="!isMobile">
                <c-img placeholder="false" height="48" :src="currentUser.avatarS3Route"></c-img>
              </v-avatar>
              <p class="ml-2" style="font-weight: 600; font-size: large;">
                {{ $t('notifications.post_published.mobile')}}
              </p>
            </div>
          </div>
        </div>
      </template>
      <div class="col-12">
        <c-btn medium
               :outlined="!isPost"
               css="ma-2"
               :color="isPost ? 'primary' : 'secondary'"
               @click.stop="select('post')">
          <v-icon>add_a_photo</v-icon>
          <span v-if="!isMobile">{{ $t('publication.type_post') }}</span>
        </c-btn>
        <PromoBtn isDialog :selected="isPromoPost"
                  :color="isPromoPost ? 'primary' : 'secondary'" @promoPost="select('promo')"></PromoBtn>
      </div>
      <div class=" col-12 information" v-if="type === 'promo' && !isLoverfans">
        <h2>{{ $t('publication.promotional_3') }}</h2>
        {{ $t('publication.promotional_4') }}
        <br>
        <span>
          <a href="/campaigns/tiktakfans/promo1/index.html">
            TikTakFans
          </a>
        </span>
        <!--<a href="/academy/follow_rabbit" target="_blank" class="item link semi-opaque">
          {{ $t('publication.more_info') }}
        </a>-->
      </div>
      <component
        ref="dialogForm"
        :type="type"
        :is="form"
        :attached="getAttached"
        :all-fans="getAllFans"
        :all-followers="getAllFollowers"
        :publicMandatory="type === 'promo'"
        :schedulable="type !== 'promo'"
        @submit="close"
        @close="close">
      </component>
    </c-dialog>
    <c-dialog persistent v-else
              :key="dialogKey"
              width="684"
              class="publication-dialog"
              :actions="false"
              v-bind:value="value"
              @input="close"
              @close="close">
      <template slot="title">
        <v-avatar size="48" v-if="!isMobile">
          <c-img placeholder="false" height="48" :src="currentUser.avatarS3Route"></c-img>
        </v-avatar>
        <c-btn small
               :outlined="!isPost"
               css="ma-2"
               :color="isPost ? 'primary' : 'secondary'"
               @click.stop="select('post')">
          <v-icon>add_a_photo</v-icon>
          <span v-if="!isMobile">{{ $t('publication.type_post') }}</span>
        </c-btn>
        <c-btn small
               v-if="!isLoverfans"
               :outlined="!isProduct"
               css="ma-2"
               :color="isProduct ? 'primary' : 'secondary'"
               @click.stop="select('product')">
          <v-icon>shopping_basket</v-icon>
          <span v-if="!isMobile">{{ $t('publication.type_product') }}</span>
        </c-btn>
        <c-btn small
               v-if="!isLoverfans"
               :outlined="!isConference"
               css="ma-2"
               :color="isConference ? 'primary' : 'secondary'"
               @click.stop="select('conference')">
          <v-icon>radio_button_checked</v-icon>
          <span v-if="!isMobile">{{ $t('publication.type_conference') }}</span>
        </c-btn>
        <PromoBtn isDialog :selected="isPromoPost"
                  :color="isPromoPost ? 'primary' : 'secondary'" @promoPost="select('promo')"></PromoBtn>
      </template>
      <div class=" col-12 information" v-if="type === 'promo'">
        <h2>{{ $t('publication.promotional_3') }}</h2>
        {{ $t('publication.promotional_4') }}
        <br>

        <span>
<!--        <a href="/academy/chick_next_door">
        {{ $t('publication.more_info') }}
      </a>
        <br>
      <router-link :to="'special/nextdoor/'"
                   class="no-decoration">
        TheChickNextDoor
      </router-link> - -->
      <a href="/campaigns/tiktakfans/promo1/index.html">
        TikTakFans
      </a>
    </span>
        <!--<a href="/academy/follow_rabbit" target="_blank" class="item link semi-opaque">
          {{ $t('publication.more_info') }}
        </a>-->
      </div>
      <component
        ref="dialogForm"
        :type="type"
        :is="form"
        :attached="getAttached"
        :all-fans="getAllFans"
        :all-followers="getAllFollowers"
        :publicMandatory="type === 'promo'"
        :schedulable="type !== 'promo'"
        @submit="close"
        @close="close">
      </component>
    </c-dialog>

  </section>
</template>
<script>
import { mapState } from 'vuex'
import PostForm from '@/components/posts/PostForm'
import ProductForm from '@/components/products/ProductForm'
import ConferenceForm from '@/components/conferences/ConferenceForm'
import PromoBtn from '@/components/custom/PromoBtn'
import project from '@/project'

export default {
  name: 'PostDialog',
  components: { PromoBtn },
  props: {
    value: Boolean,
    attached: {
      type: String,
      default: null
    },
    allFans: Boolean,
    allFollowers: Boolean,
    type: {
      type: String,
      default: 'post'
    }
  },

  data () {
    return {
      dialogKey: new Date().getTime(),
      project
    }
  },

  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapState('application', ['isMobile']),
    isLoverfans () {
      return project.project === 'loverfans'
    },
    cssClass () {
      if (!this.isLoverfans) return
      return 'fixed-top-publication'
    },
    isPost () {
      return this.type === 'post'
    },
    isProduct () {
      return this.type === 'product'
    },
    isConference () {
      return this.type === 'conference'
    },
    isPromoPost () {
      return this.type === 'promo'
    },
    getAllFans () {
      return this.isPromoPost ? false : this.allFans
    },
    getAllFollowers () {
      return this.isPromoPost ? false : this.allFollowers
    },
    getAttached () {
      return this.isPromoPost ? null : this.attached
    },
    form () {
      return this.isProduct
        ? ProductForm
        : this.isConference
          ? ConferenceForm
          : PostForm
    },
    hasPromoPost () {
      return this.project.campaign.post
    },
    isOngoing () {
      return this.started(new Date()) && !this.ended(new Date())
    }
  },

  methods: {
    started (today) {
      const users = this.project.campaign.users
      let activeUser = true
      if (users.length > 0) {
        activeUser = false
        const index = users.findIndex(u => u === this.currentUser.username)
        activeUser = index !== -1
      }

      // LOS MESES SON DE 0 - 11)
      const eventStarted = this.project.campaign.started
      const activeDate = (today - eventStarted) > 0
      return activeDate && activeUser
    },
    ended (today) {
      // LOS MESES SON DE 0 - 11)
      var eventEnded = this.project.campaign.ended
      return (eventEnded - today) < 0
    },
    select (type) {
      this.$emit('update:type', type)
    },
    close () {
      this.dialogKey = new Date().getTime()
      this.$emit('update:type', 'post')
      this.$emit('input', false)
    }
  },
  mounted () {
    if (this.$route.query.sp === 'promo') {
      this.type = 'promo'
    }
  }
}
</script>
<style lang="scss" scoped>

.information {
  font-size: 14px;
  font-weight: normal;
  border-style: ridge;
  border-left-width: inherit;
  border-right-width: inherit;
  border-bottom-width: inherit;
}

</style>
