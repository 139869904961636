<template>
  <v-banner class="app-fullpage">
    <template>
      {{ alertText }}
      <c-btn x-small :to="link">{{ $t('actions.bring_me_there') }}</c-btn>
    </template>
  </v-banner>
</template>
<script>

export default {
  name: 'PaymentBanner',
  props: {
    alert: Object
  },
  computed: {
    alertText () {
      return this.$t(`alerts.${this.alert.action}`)
    },
    link () {
      return { name: this.alert.resource, params: { uuid: this.alert.resourceId } }
    }
  }
}
</script>
