<template>
  <section
    ref="carousel"
    class="carousel"
    :tabindex="1"
    @keyup.esc="closeCarousel"
  >
    <div class="close" @click="closeCarousel">&times;</div>
    <div class="row item" style="height: 96%; color: white">
      <button
        type="button"
        v-if="showButtonNext"
        @click="prevCarousel()"
        style="filter: brightness(0) saturate(100%) invert(98%) sepia(100%) saturate(7%) hue-rotate(186deg) brightness(101%) contrast(101%);"
        :disabled="resources.length === 0"
      >
        <img src="/chat/ico-prev.svg" />
      </button>
      <div class="col-10" style="height: inherit">
        <img
          :src="getResource.url"
          v-if="getResource.type === 'image'"
          width="100%"
          style="object-fit: contain; height: inherit"
        />
        <VideoPlayer v-else-if="isFromBunny"
          contain
          height="100%"
          width="100%"
          :resource="getResource"
        />
        <video
          v-else
          controls
          controlslist="nodownload"
          width="100%"
          :src="getResource.url"
          class="message-video"
          style="height: inherit;"
        />
        <div class="col-12 center" style="margin: auto; text-align: center;">
          <span
            class="resource"
            v-for="(index, i) in resources.length"
            :key="i"
            @click="changeResource(index)"
          >
            <span
              class="material-icons"
              style="font-size: 18px; vertical-align: text-bottom;"
              :class="{ selected: index == selectedItem + 1 }"
              >fiber_manual_record</span
            >
          </span>
        </div>
      </div>
      <button
        type="button"
        v-if="showButtonNext"
        @click="nextCarousel()"
        style="filter: brightness(0) saturate(100%) invert(98%) sepia(100%) saturate(7%) hue-rotate(186deg) brightness(101%) contrast(101%);"
      >
        <img src="../../../../public/chat/ico-next.svg" />
      </button>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { ChatActions } from "@/store";
import VideoPlayer from '@/components/custom/VideoPlayer.vue'

export default {
  name: "Carousel",
  components: { VideoPlayer },
  computed: {
    ...mapState("chats", ["carouselItems", "carouselItemSelected"]),
    isFromBunny() {
      return this.getResource.location === 'bunny'
    },
    getResource() {
      return this.resources[this.selectedItem];
    },
    showButtonNext() {
      return this.carouselItems.length !== 1;
    }
  },
  props: {
    resources: Array
  },
  data() {
    return {
      selectedItem: 0
    };
  },
  methods: {
    changeSelected(index) {
      this.selectedItem = index;
    },
    closeCarousel() {
      this.$store.dispatch(ChatActions.CarouselItemsList, null);
      this.$store.dispatch(ChatActions.ShowCarousel, false);
    },
    changeResource(index) {
      this.selectedItem = index - 1;
      this.$store.dispatch(ChatActions.CarouselItemSelected, this.selectedItem);
    },
    prevCarousel() {
      if (this.selectedItem === 0) {
        this.selectedItem = this.resources.length - 1;
      } else {
        this.selectedItem--;
      }
    },
    nextCarousel() {
      if (this.resources.length === this.selectedItem + 1) {
        this.selectedItem = 0;
      } else {
        this.selectedItem++;
      }
    }
  },
  mounted() {
    this.selectedItem = this.carouselItemSelected;
    this.$refs.carousel.focus();
  }
};
</script>

<style lang="scss" scoped>
.item {
  margin: auto;
  display: flex;
  width: 90%;
  height: inherit;
  padding: 10px;
}

@keyframes zoom {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  left: 95%;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  opacity: 0.7;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .item {
    width: 100%;
  }
}

.resource {
  vertical-align: middle;
  color: gray;
}

.resource .selected {
  color: white;
  font-size: 22px !important;
}

.resource:hover {
  cursor: pointer;
}
</style>
