<template>
  <section>
    <Paginations
      :loading="loading"
      :items="items"
      :perPage="perPage"
      :totalItems="totalItems"
      @pageSelected="pageSelected"
    >
      <template #default="{ item }">
        <ViewListUserRow
          :row="item"
          @deleteItemList="deleteItemList"
          @editItemList="editItemList"
          @selectedList="selectedList"
          :noEdit="noEdit"
        />
      </template>
    </Paginations>
  </section>
</template>

<script>
import ConversationsApi from '@/api/conversations/ConversationsApi'
import ViewListUserRow from './ViewListUserRow.vue'
import Paginations from '@/components/chat/component/Paginations'
import table from '@/components/mixins/table'

export default {
  name: 'ViewListUser',
  mixins: [table(ConversationsApi, 'findList')],
  components: { ViewListUserRow, Paginations },
  props: {
    noEdit: { type: Boolean, defualt: false }
  },
  data () {
    return {
      listUser: []
    }
  },
  methods: {
    async deleteItemList (id) {
      const { error } = await ConversationsApi.deleteList(id)
      if (!error) {
        this.loadItems()
      }
    },
    pageSelected (pag) {
      this.page = pag
    },
    editItemList (userList) {
      this.$emit('editItemList', userList)
    },
    selectedList (userList) {
      this.$emit('selectedList', userList)
    }
    
  }
}
</script>
