<template>
  <v-list
    rounded
    class="app-navigation custom-scrollbar title-text-2"
    style="overflow-x: hidden;height: 100%;width:auto"
    :class="{
      mini: minimified,
      'app-sticky': sticky
    }"
  >
    <v-list-item-group
      class="title-text-2 semibold"
      color="primary"
      :class="resizeBtn"
    >
      <v-list-item
        v-if="authenticated"
        class=""
        :class="{ 'justify-center': minimified }"
      >
        <UserAvatar rounded :mini="minimified" :user="currentUser">
          <div slot="name" class="title-text-3 bold">
            {{ currentUser.displayName }}
          </div>
          <div slot="username" class="regular-text light-text">
            {{ `@${currentUser.username}` }}
          </div>
        </UserAvatar>
      </v-list-item>

      <v-list-item
        v-else
        class="navigation-item"
        :class="{ 'justify-center': minimified }"
      >
        <v-btn :to="{name: 'login'}" color="primary">{{ $t('actions.login') }}</v-btn>
      </v-list-item>
      <v-list-item class="develop-notify col-12" v-if="config.isDevelop">
        <h5>DESARROLLO</h5>
      </v-list-item>
      <v-expand-x-transition v-if="!maintance && (!isMobile || (isMobile && !isLoverfans))">
        <v-list-item >
          <v-list-item-content>
            <PublicationLauncher :icon="minimified"/>
          </v-list-item-content>
        </v-list-item>
      </v-expand-x-transition>

      <div class="col-12 navigation-item center-drop" v-if="isInfluencer" style="height: 40px !important;" @click="showPanelElements = !showPanelElements">
        <div>
          <v-icon>bar_chart</v-icon><span class="additional_text" :class="{dark: darkTheme,mobile: isMobile}" v-if="!minimified">{{ $t(`application.menu.my_panel`) }}</span>
        </div>
        <v-icon >
          {{ !showPanelElements ? 'arrow_drop_down' : 'arrow_drop_up' }}
        </v-icon>
      </div>
      <span v-if="showPanelElements">
        <v-list-item
          class="navigation-item justify-end"
          style="height: 40px !important;"
          v-for="item in creatorPanelSections()"
          :key="item.label"
          :to="item.link"
          :href="item.href"
          :target="item.redirect ? '_blank' : ''"
          @click="hideSettings(); item.actions();"
        >
          <v-list-item-content>
            <v-list-item-title class="center-drop">
              <div v-if="!minimified">{{ $t(`application.menu.${item.label}`) }}</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </span>

      <v-list-item
        class="navigation-item justify-end"
        style="height: 40px !important;"
        v-for="item in availableSections"
        :key="item.title"
        :to="item.link"
        :href="item.href"
        :target="item.redirect ? '_blank' : ''"
        @click="hideSettings(); item.actions();"
      >
        <v-list-item-icon>
          <span v-if="item.icon === 'release-alert'">
            <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="20px" viewBox="0 0 22 22">
              <path fill="currentColor" d="m8.6 22.5l-1.9-3.2l-3.6-.8l.35-3.7L1 12l2.45-2.8l-.35-3.7l3.6-.8l1.9-3.2L12 2.95l3.4-1.45l1.9 3.2l3.6.8l-.35 3.7L23 12l-2.45 2.8l.35 3.7l-3.6.8l-1.9 3.2l-3.4-1.45zM12 17q.425 0 .713-.288T13 16t-.288-.712T12 15t-.712.288T11 16t.288.713T12 17m-1-4h2V7h-2z" />
            </svg>
          </span>
          <span v-else-if="item.icon === 'my_subs'">
            <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 22 22" :style="{'opacity: 0.7': !darkTheme}">
              <path fill="currentColor" d="M11.182,11.188c-1.964,0-5.818.934-5.818,2.875V15.5H17V14.063c0-1.941-3.855-2.875-5.818-2.875m0-1.437A2.875,2.875,0,1,0,8.273,6.875,2.892,2.892,0,0,0,11.182,9.75M3.909,11.906l-.436-.359C2.018,10.181,1,9.319,1,8.241A1.6,1.6,0,0,1,2.6,6.659a1.712,1.712,0,0,1,1.309.575,1.712,1.712,0,0,1,1.309-.575,1.559,1.559,0,0,1,1.6,1.581c0,1.078-1.018,1.941-2.473,3.306Z"/>
            </svg>
          </span>
          <v-icon v-else :color="item.color">{{ item.icon }}</v-icon>
          <span v-if="unreadMessages && isIconMessage(item)" class="notification-unread-message">{{
              countUnreadMessages
            }}</span>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="center-drop">
            <div v-if="!minimified">{{ $t(`application.menu.${item.title}`) }}</div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
    <v-list-item-group color="primary" v-if="authenticated">
      <!--<v-list-item
        class="navigation-item"
        :class="{ 'justify-center': minimified }"
      >
        <UserAvatar :mini="minimified" :user="currentUser">
          <div slot="name" class="title-text-3 bold greyish-text">
            {{ currentUser.displayName }}
          </div>
          <div slot="username" class="regular-text light-text">
            {{ `@${currentUser.username}` }}
          </div>
        </UserAvatar>
      </v-list-item>-->
      <v-list-item
        class="navigation-item justify-end"
        :to="{ name: 'settings' }"
      >
        <v-list-item-icon>
          <v-icon>settings</v-icon>
        </v-list-item-icon>
        <v-list-item-content v-if="!minimified">
          <div class="center-drop" style="font-weight: 600;">
            {{ $t('application.menu.settings') }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>

    <div class="col-12 navigation-item center-drop" style="max-height: 40px !important;" @click="showMoreElements = !showMoreElements">
      <div>
        <v-icon>more_horiz</v-icon><span class="additional_text"  :class="{dark: darkTheme,mobile: isMobile}" v-if="!minimified">{{ $t(`application.menu.more`) }}</span>
      </div>
      <v-icon >
        {{ !showMoreElements ? 'arrow_drop_down' : 'arrow_drop_up' }}
      </v-icon>
    </div>
    <span v-if="showMoreElements">
        <v-list-item
          class="navigation-item justify-end"
          style="height: 40px !important;"
          v-for="item in moreElementsSection()"
          :key="item.label"
          :to="item.link"
          :href="item.href"
          :target="item.redirect ? '_blank' : ''"
          @click="hideSettings(); item.actions();"
        >
          <v-list-item-content style="font-weight: 600;">
            <v-list-item-title class="center-drop" v-if="item.label !== 'lang'">
              <div >{{ $t(`application.menu.${item.label}`) }}</div>
            </v-list-item-title>
            <v-list-item-title class="center-drop" v-else>
              <div>{{ country.value }} - <span style="text-transform: Uppercase">{{ country.el }}</span></div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </span>
    <div class="flex-grow-1"></div>

    <c-dialog persistent
              width="400"
              :actions="false"
              v-model="dialog">
      <template slot="title">
        <h1>{{ $t(`application.menu.${showLang ? 'lang' : 'legal'}.dialog`) }}</h1>
      </template>
      <div class="p-6 countries" v-if="showLang">
        <v-list style="overflow: hidden;">
          <v-list-item
            dense
            v-for="(item, index) in countries"
            :key="index"
          >
            <v-list-item-title @click="setLocale(item)" style="font-family: Quicksand, sans-serif;"><span
              class="flag"><img width="20" height="13" :src="item.image" alt="country"></span> {{ item.value }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
      <div class="p-6 countries" v-if="showLegal">
        <div class="px-3">
          <div class="mb-2" v-for="section in legalSections" :key="section.label">
            <router-link class="legal-link" :to="{name: section.to}">{{$t(`application.menu.${section.label}`)}}</router-link>
          </div>
        </div>
      </div>
    </c-dialog>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'
import { ChatActions, PreferenceActions, SessionActions } from '@/store'
import PublicationLauncher from '@/components/custom/PublicationLauncher'
import UserAvatar from '@/components/users/UserAvatar'
import config from '@/project'
import dialog from '@/components/mixins/dialog'
import cookies from '@/cookies'

export default {
  name: 'AppNavigation',
  components: { PublicationLauncher, UserAvatar },
  mixins: [dialog],
  props: { sticky: Boolean, maintance: Boolean },
  data () {
    return {
      locales: ['en', 'es', 'de', 'fr', 'it', 'pt'],
      countries: [],
      country: {},
      showPanelElements: false,
      showMoreElements: false,
      showLang: false,
      showLegal: false,
      config
    }
  },
  computed: {
    ...mapState('application', [
      'isMobile',
      'isLargeScreen',
      'hasMessages',
      'countUnreadMessages'
    ]),
    ...mapState('profile', [
      'currentUser',
      'isInfluencer',
      'isFollower',
      'isAdmin',
      'isController',
      'isAccountManager',
      'isAgent'
    ]),
    ...mapState('session', ['authenticated']),
    ...mapState('preferences', ['darkTheme']),
    resizeBtn () {
      return {
        'resize-btn': this.$i18n.locale === 'de'
      }
    },
    unreadMessages () {
      return this.countUnreadMessages !== ''
    },
    availableSections () {
      const items = this.allSections()
      return items
        .filter(item => this.isAvailable(item))
        .map(item => {
          if (item.title === 'messages') {
            item.icon = 'chat'
            item.color = this.hasMessages ? 'primary' : ''
          }
          return item
        })
    },

    minimified () {
      return !this.isMobile && !this.isLargeScreen
    },

    isDevelopfunction () {
      return this.isAdmin
    },
    legalSections () {
      return [
        {
          label: 'legal.legal_warning',
          to: 'conditions'
        }, {
          label: 'legal.conditions',
          to: 'contract-conditions'
        }, {
          label: 'legal.privacy',
          to: 'privacy'
        }, {
          label: 'legal.cookies',
          to: 'cookies'
        }, {
          label: 'legal.usc',
          to: 'complaint_2257'
        }, {
          label: 'legal.dmca',
          to: 'dmca'
        }, {
          label: 'legal.complaints',
          to: 'compliance-policy'
        }
      ]
    },
    isLoverfans () {
      return config.project === 'loverfans'
    }
  },

  methods: {
    langs () {
      var elements = this.locales
      for (var el of elements) {
        this.countries.push({
          image: el === 'en' ? '/images/usa-flag-icon-20.jpg' : 'https://flagcdn.com/28x21/' + el.toLowerCase() + '.png',
          el: el,
          value: this.$t(`langs.names.${el}`)
        })
      }
      this.country = this.countries.find(element => element.el === this.getLocale()) || this.countries[0]
    },
    setLocale (item) {
      this.$i18n.loadLocale(item.el)
      this.$timeago.locale = item.el
      this.$vuetify.lang.current = item.el
      this.country = item
      cookies.set('locale', item.el)
      this.dialog = !this.dialog
    },
    getLocale () {
      return cookies.get('locale')
    },
    showPanel () {
      this.showPanelElements = !this.showPanelElements
    },
    isAvailable (item) {
      if (item.show === false) {
        return false
      }
      if (item.only) {
        return [item.only].flat().includes(this.currentUser.role)
      }
      if (item.except) {
        return ![item.except].flat().includes(this.currentUser.role)
      }
      return true
    },
    changeTheme () {
      this.$vuetify.theme.dark = !this.darkTheme
      this.$store.dispatch(PreferenceActions.UpdateDarkTheme, this.$vuetify.theme.dark)
    },
    changeLangDialog () {
      this.showLang = true
      this.showLegal = false
      this.dialog = !this.dialog
    },
    showLegalDialog () {
      this.showLang = false
      this.showLegal = true
      this.dialog = !this.dialog
    },
    allSections () {
      const redirect = config.payments.darkfans_redirect && this.isFollower
      if (!this.authenticated) {
        return [
          {
            icon: 'stars',
            title: 'influencers',
            link: { name: 'stars_discover' },
            show: true
          },
          {
            icon: 'theaters',
            title: 'stories',
            href: `${config.domains.baseUrl}/campaigns/tiktak/index.html`,
            redirect: true,
            show: true
          },
          {
            icon: 'radio_button_checked',
            title: 'lives',
            link: { name: 'conferences' },
            show: false
          },
          {
            icon: 'video_library',
            title: 'videotube',
            link: { name: 'medialib' },
            show: true
          },
          {
            icon: 'shopping_basket',
            title: 'market',
            link: { name: 'marketplace' },
            show: false
          }
        ]
      } else {
        return [
          {
            icon: 'people',
            title: 'users',
            link: { name: 'controller_users' },
            only: ['controller']
          },
          {
            icon: 'supervised_user_circle',
            title: 'agents',
            link: { name: 'partners' },
            show: !!this.isAgent
          },
          {
            icon: 'release-alert',
            title: 'become_creator',
            link: { name: 'become_star' },
            only: ['follower']
          },
          {
            icon: 'chat',
            title: 'messages',
            redirect: redirect,
            href: redirect ? `https://darkfans.com/messages?oa=${this.currentUser.sessionToken}&utm_source=${config.project}` : undefined,
            link: redirect ? undefined : { name: 'messages' },
            except: ['admin', 'account_manager']
          },
          {
            icon: 'radio_button_checked',
            title: 'lives',
            link: { name: 'conferences' },
            show: !this.isLoverfans
          },
          {
            icon: 'stars',
            title: 'influencers',
            link: { name: 'stars' },
            except: ['admin', 'account_manager']
          },
          // {
          //   icon: 'theaters',
          //   title: 'stories',
          //   href: `${config.domains.baseUrl}/campaigns/tiktak/index.html`,
          //   redirect: true
          // },
          {
            icon: 'bookmarks',
            title: 'favorites',
            link: { name: 'favorites' },
            except: ['admin', 'account_manager']
          },
          {
            icon: 'my_subs',
            title: 'subscriptions',
            link: { name: 'profile_section', params: { username: this.currentUser.username, tab: this.isInfluencer ? 'fans' : 'subscriptions' } },
            except: ['admin', 'account_manager']
          },
          {
            icon: 'video_library',
            title: 'videotube',
            link: { name: 'medialib' },
            except: ['admin', 'account_manager']
          },
          {
            icon: 'people',
            title: 'users',
            link: { name: 'admin_users' },
            only: ['admin', 'account_manager']
          },
          {
            icon: 'supervised_user_circle',
            title: 'agents',
            link: { name: 'admin_agents' },
            only: 'admin'
          },
          {
            icon: 'class',
            title: 'categories',
            link: { name: 'admin_categories' },
            only: ['admin', 'account_manager']
          },
          {
            icon: 'approval',
            title: 'post_promo',
            link: { name: 'admin_post_promo' },
            show: this.currentUser.username === 'jgar' || this.isAdmin
          },
          {
            icon: 'approval',
            title: 'post_promo',
            link: { name: 'post_promo_controller' },
            show: this.isController
          },
          {
            icon: 'chat',
            title: 'messages',
            link: { name: 'admin_messages' },
            only: ['admin', 'account_manager']
          },
          {
            icon: 'flag',
            title: 'complaints',
            link: { name: 'admin_complaints' },
            only: ['admin', 'account_manager']
          },
          {
            icon: 'flag',
            title: 'complaints',
            link: { name: 'complaints' },
            only: 'controller'
          },
          {
            icon: 'how_to_reg',
            title: 'admin',
            link: { name: 'admin_service' },
            only: 'admin'
          },
          {
            icon: 'euro',
            title: 'bonus',
            link: { name: 'admin_bonuses' },
            only: 'admin'
          },
          {
            icon: 'beenhere',
            title: 'validation',
            link: { name: 'admin_validation' },
            only: ['admin', 'account_manager']
          },
          {
            icon: 'beenhere',
            title: 'validation',
            link: { name: 'controller_validation' },
            only: ['controller']
          },
          {
            icon: 'attach_money',
            title: 'billing',
            link: { name: 'admin_billing' },
            only: ['admin', 'account_manager']
          },
          {
            icon: 'group_add',
            title: 'addFollowers',
            link: { name: 'admin_add_followers' },
            only: ['admin', 'account_manager']
          },
          {
            icon: 'dynamic_feed',
            title: 'xsale',
            link: { name: 'admin_delegate_messages' },
            only: ['admin', 'account_manager']
          },
          {
            icon: 'account_balance_wallet',
            title: 'wallet_transfer',
            link: { name: 'admin_wallet_transfer' },
            only: ['admin']
          },
          {
            icon: 'settings',
            title: 'development',
            link: { name: 'admin_development' },
            show: this.isDevelopfunction
          }
        ]
      }
    },
    moreElementsSection () {
      return [
        {
          icon: '',
          label: 'theme',
          actions: this.changeTheme
        },
        {
          icon: '',
          label: 'lang',
          actions: this.changeLangDialog
        },
        {
          label: 'faq',
          link: '/faq'
        },
        {
          label: 'contact',
          link: '/contact'
        },
        {
          label: 'legal.title',
          actions: this.showLegalDialog
        },
        {
          label: 'logout',
          actions: () => { this.$store.dispatch(SessionActions.Logout) }
        }
      ]
    },
    creatorPanelSections () {
      return [
        {
          icon: 'show_chart',
          label: 'statistics',
          link: { name: 'statistics' },
          only: 'influencer'
        },
        {
          icon: 'record_voice_over',
          label: 'referrals',
          link: { name: 'referrals' },
          only: 'influencer'
        },
        {
          icon: 'photo_filter',
          label: 'resources',
          link: { name: 'resources' },
          only: 'influencer',
          show: config.enable_resources
        },
        {
          icon: 'live_help',
          label: 'tutorial',
          link: { name: 'tutorials' },
          only: 'influencer'
        }
      ]
    },
    hideSettings () {
      this.$store.dispatch(ChatActions.SelectChat, null)
      this.$store.dispatch(ChatActions.ShowChatSetting, false)
      this.$store.dispatch(ChatActions.ShowChatNew, false)
      this.$store.dispatch(ChatActions.ShowSetting, false)
      this.$store.dispatch(ChatActions.ShowVideoChat, false)
      this.$store.dispatch(ChatActions.ShowCarousel, false)
    },
    isIconMessage (item) {
      return item.icon === 'chat'
    }
  },
  mounted () {
    this.langs()
  }
}
</script>
<style lang="scss" scoped>
.mini {
  max-width: 100%;
}

.resize-btn {
  min-width: fit-content;
}

.notification-unread-message {
  margin-top: -6px;
  margin-left: -7px;
}

.develop-notify {
  background-color: #DA15FCA3;
  width: 100%;
  text-align: center;
}

.center-drop {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.center-drop:hover {
  cursor: pointer;
  border-radius: 32px;
  background-color: rgba(180, 180, 180, 0.18) !important;
}

.additional_text {
  color: #000000DE;
  margin-left: 36px;
  font-weight: 600;
  &.dark{
    color: #F9F9F9;
  }
}

.legal-link {
  text-decoration: none;
  color: inherit;
}

.legal-link:hover {
  color: var(--v-primary-base);
}
</style>
