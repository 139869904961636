<template>
  <section class="d-flex flex-column flex-grow-1">
    <div class="messages-container flex-grow-1">
      <div ref="messageList" class="messages-list custom-scrollbar">
        <ListView
          no-gutters
          :loading="loading"
          :items="reversedItems"
          cols="12"
          align="start"
          justify="start"
          @top-reached="loadItems"
        >
          <template #default="{ item }">
            <BroadcastRow :broadcast="item" @resource-loaded="goToBottom" />
          </template>
        </ListView>
      </div>
    </div>
  </section>
</template>
<script>
import BroadcastRow from './BroadcastRow'

export default {
  name: 'BroadcastList',
  components: { BroadcastRow },
  methods: {
    decodedMessage (message) {
      return message.message
    },
    onLoadFinished () {
      const el = this.$refs.messageList
      const previewHeight = el.scrollHeight
      this.$nextTick(() => {
        el.scrollTop = el.scrollHeight - previewHeight
      })
    },
    mapItem (msg) {
      return {
        ...msg,
        date: this.$datetime(msg.date),
        message: this.decodedMessage(msg)
      }
    },

    goToBottom () {
      const el = this.$refs.messageList
      this.$nextTick(() => {
        el.scrollTop = el.scrollHeight
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.messages-container {
  position: relative;
  .messages-list {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow-x: hidden;
  }
}
</style>
