<template>
  <v-form v-model="validForm"  ref="form"
    @submit.prevent="submit">
    <v-card-text>
      <v-row align="center">
        <v-col cols="6" sm="8" class="label">
          <span>{{ $t('chat_preferences.new_chat_state') }}</span>
        </v-col>
        <v-col cols="6" sm="4">
          <v-select
            outlined
            hide-details
            :items="states"
            v-model="defaultState" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="6" sm="8" class="label">
          <span>{{ $t('chat_preferences.message_price') }}</span>
        </v-col>
        <v-col cols="6" sm="4">
          <CurrencyInput outlined
            v-model="messagePrice"
            hint="XXX,YY" />
        </v-col>
      </v-row>
      <v-row v-if="hasChangedPrice"
        align="center">
        <v-col cols="6" sm="8" class="label text-right">
          <span>{{ $t('chat_preferences.apply_price_to') }}</span>
        </v-col>
        <v-col cols="6" sm="4">
         <v-select
            outlined
            dense
            hide-details
            :items="newPriceOptions"
            v-model="applyPrice" />
        </v-col>
      </v-row>
    </v-card-text>

    <ListViewTitle
      :title="$t('chat_preferences.welcome_messages')"
    />
    <v-card-text>
      <EmojiInput
        outlined
        :dark="darkTheme"
        v-model="newFanMessage"
        max-length="1500"
        rows="3"
        :label="$t('chat_preferences.new_fan_message')">
      </EmojiInput>
    </v-card-text>
    <v-card-text>
      <EmojiInput
        outlined
        :dark="darkTheme"
        v-model="newFollowerMessage"
        max-length="1500"
        rows="3"
        :label="$t('chat_preferences.new_follower_message')">
      </EmojiInput>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <c-btn :disabled="!validForm"
        @click.stop.prevent="submit">
        {{ $t('actions.save') }}
      </c-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import validatable from '@/components/mixins/validatable'
import messages from '../locales/all.json'

export default {
  name: 'ChatPreferencesForm',
  mixins: [validatable('form')],
  i18n: { messages },
  data () {
    return {
      states: [
        { text: this.$t('chat_state.open'), value: 'open' },
        { text: this.$t('chat_state.pending'), value: 'pending' },
        { text: this.$t('chat_state.paid'), value: 'paid' }
      ],
      newPriceOptions: [
        { text: this.$t('chat_preferences.dont_apply_price'), value: 'none' },
        { text: this.$t('chat_preferences.apply_price_ony_paid'), value: 'paid' },
        { text: this.$t('chat_preferences.apply_price_all'), value: 'all' }
      ],
      currentPrefs: null,
      defaultState: 'pending',
      messagePrice: null,
      applyPrice: 'none',
      newFanMessage: '',
      newFollowerMessage: ''
    }
  },
  computed: {
     darkTheme(){
      return this.$vuetify.theme.dark
    },
    hasChangedPrice () {
      return this.currentPrefs && this.currentPrefs.messagePrice !== this.messagePrice
    },
    attrs () {
      return {
        defaultState: this.defaultState,
        messagePrice: this.messagePrice,
        applyPrice: this.applyPrice,
        newFanMessage: this.newFanMessage,
        newFollowerMessage: this.newFollowerMessage
      }
    }
  },
  methods: {
    async onSubmit () {
      this.loading = true
      // await ChatApi.updatePreferences(this.attrs)
      this.currentPrefs = this.attrs
      this.loading = false
      this.$emit('submitted')
    },

    async loadPreferences () {
      /*const { data } = await ChatApi.preferences()
      this.currentPrefs = data
      this.defaultState = this.currentPrefs.defaultState
      this.messagePrice = this.currentPrefs.messagePrice
      this.newFanMessage = decodeURI(this.currentPrefs.newFanMessage)
      this.newFollowerMessage = decodeURI(this.currentPrefs.newFollowerMessage)*/
    }
  },
  mounted () {
    this.loadPreferences()
  }
}
</script>
