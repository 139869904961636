import { ProductActions } from '@/store'
import ProductApi from '@/api/ProductApi'
import CursorService from '@/components/mixins/cursor_service'

const deconstructWatchers = (cursors) => {
  const properties = {}

  cursors.forEach(cursor => {
    properties[cursor.filter || 'query'] = {
      deep: true,
      handler: function (value) {
        this[cursor.name || 'cursor'].query = value
      }
    }
  })
  return properties
}

export default (options = {}) => {
  const { cursor, cursors } = options
  const innerCursors = []
  if (cursor === true) {
    innerCursors.push({ name: 'cursor', filter: 'query' })
  }

  if (cursors && cursors.length) {
    innerCursors.push(...cursors)
  }

  const data = { }
  innerCursors.forEach(cursor => {
    data[cursor.name] = {}
  })
  return {
    api: ProductApi,
    data () {
      return data
    },
    watch: {
      ...deconstructWatchers(innerCursors)
    },
    methods: {
      findProduct (id, force) {
        return this.$store.dispatch(ProductActions.Fetch, { id, force })
      },

      createProduct (attrs) {
        return this.$store.dispatch(ProductActions.Create, attrs)
      },

      updateProduct (attrs) {
        return this.$store.dispatch(ProductActions.Update, attrs)
      },

      deleteProduct (id) {
        return this.$store.dispatch(ProductActions.Delete, id)
      },

      searchProducts (query) {
        return this.$store.dispatch(ProductActions.Search, query)
      },

      subscribeMutation (mutation, handler) {
        this.subscriptions = this.subscriptions || []
        const subscription = this.$store.subscribe((storeMutation, state) => {
          if (storeMutation.type === mutation) {
            handler(storeMutation, state)
          }
        })
        this.subscriptions.push(subscription)
      },

      subscribeProductCreated () {
        this.subscribeMutation('products/insert_prodcut', (mutation, state) => {
          this.onProductCreated(state.products.products[mutation.payload.id])
        })
      },

      subscribeProductDeleted () {
        this.subscribeMutation('products/remove_product', (mutation) => {
          this.onProductDeleted(mutation.payload)
        })
      },

      onProductCreated () {
      },

      onProductDeleted () {
      }
    },
    beforeMount () {
      this.subscriptions = this.subscriptions || []
      this.cursors = innerCursors.map(cursor => {
        const c = CursorService(this.$store, {
          query: this[cursor.filter || 'query'],
          action: cursor.action || ProductActions.Search
        })
        c.$mount()
        this[cursor.name] = c
        return c
      })
    },
    destroyed () {
      this.cursors.forEach(c => c.$destroy())
      this.subscriptions.forEach(unsubscribe => unsubscribe())
    }
  }
}
