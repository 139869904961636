'user strict'

import api from '@/api/Api'

export default {

  listUsers (query, options) {
    return api.get('/affiliates/users', query, options)
  },

  feedUsers (query, options) {
    return api.get('/affiliates/feed/users', query, options)
  },

  listPosts (query, options) {
    return api.get('/affiliates/posts', query, options)
  },

  feedPosts (query, options) {
    return api.get('/affiliates/feed/posts', query, options)
  }

}
