<template>
  <v-form
    @keydown.esc="destroy"
    v-model="validForm" ref="form"
    @submit.stop.prevent="submit">
    <v-col cols="12" v-if="post.promo">{{$t('publication.promotional') }}</v-col>
    <v-text-field
      outlined
      :label="$t('posts.form.title')"
      :rules="[$vRequired, $vBannedWords(newTitle), $vNoHtml(newTitle)]"
      v-model="newTitle">
    </v-text-field>
    <v-spacer></v-spacer>
    <EmojiInput
      outlined required
      v-model="newContent"
      max-length="1500"
      :placeholder="$t('posts.form.content')"
      :rules="[$vRequired, $vBannedWords(newContent), $vNoHtml(newContent)]">
    </EmojiInput>
    <FileInput v-if="canHaveTeaser"
      class="d-block mb-4"
      autoupload color="primary"
      :preview="false"
      v-model="newTeaser"
      :label="$t('posts.form.edit_teaser')"
      :hint="teaserHint"
      :error="hasTeaserError"
      max-files="1"
      contentType="video/*, .quicktime, .gif"
      @file-added="teaserAdded" />
    <CategoriesInput v-model="newCategories" :max="5" />
    <v-row align="center">
      <v-col cols="auto">
        <span>{{ $t('posts.mentions.text') }}</span>
      </v-col>
      <v-col cols="4">
        <c-btn small text
               css="ma-2"
               @click.stop="showMentionDialog">
          {{ $t('actions.add') }}
        </c-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-chip v-for="user in mentions"
        :key="user"
        class="mx-1"
        dense outlined filter close
        @click:close="removeMentionedUser(user)">
        @{{ user }}
      </v-chip>
    </v-row>
    <EditParticipantList
      :participants="participants"
      @change="updateParticipants"
    />
    <v-row>
      <v-col cols="12" md="6">
        <date-picker-input v-model="startDate"
          :rules="[$vRequired]"
          :disabled="post.promotion"
          :label="$t('models.post.starts_date')" />
      </v-col>
      <v-col cols="12" md="6">
        <time-picker-input v-model="startTime"
          :rules="[$vRequired]"
          :disabled="post.promotion"
          :label="$t('models.post.starts_time')" />
      </v-col>
      <v-col cols="12" v-if="isInvalidDate">
        <span class="small-text">
          {{ $t('models.posts.auto_publish') }}
        </span>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12">{{ visibilityHint }}</v-col>
      <v-col cols="auto" class="label small-text">
        <v-icon>visibility</v-icon>
        <span>{{ $t('posts.form.visibility_message') }}</span>
      </v-col>
      <v-col sm="5">
        <v-select outlined hide-details
          :disabled="!canChangeVisibility"
          v-model="newVisibility"
          :items="visibilityTypes"
          :rules="[$vRequired]">
        </v-select>
      </v-col>
      <v-col cols="12" md="">{{ visibilityDescription }}</v-col>
    </v-row>
    <v-row v-if="showPrice" align="center">
      <v-col cols="auto" class="label small-text">
        <v-icon>monetization_on</v-icon>
        <span>{{ $t('models.post.price') }}</span>
      </v-col>
      <v-col>
        <CurrencyInput outlined
          color="orange"
          v-model="newPrice"
          hint="XXX,YY"
          :rules="[$vRequired]"
          maxValue="42000"
          minValue="500" />
      </v-col>
    </v-row>
    <v-row v-if="showChoseUser" align="center">
      <v-col cols="auto" class="label small-text">
        <v-icon>person</v-icon>
        <span>{{ $t('models.post.selected_user') }}</span>
      </v-col>
      <v-chip v-for="user in selectedUsers"
        :key="user.username"
        class="mx-1"
        dense outlined filter close
        @click:close="removeSelectedUser(user)">
        {{ user.username }}
      </v-chip>
      <v-col v-if="!isExclusive"
        cols="4">
        <c-btn small text
          css="ma-2"
          @click.stop="showDialog">
          {{ $t('actions.chose') }}
        </c-btn>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer />
      <c-btn
        text color="secondary"
        @click.stop="emitClose">
          {{ $t('actions.close') }}
      </c-btn>
      <c-btn :disabled="!submitable"
        @click.stop.prevent="submit">
        {{ $t("actions.edit") }}
      </c-btn>
    </v-card-actions>
    <UserSearchDialog
      v-model="dialog"
      @user-selected="addSelectedUser" />
    <UserSearchDialog
      v-model="mentionDialog"
      @user-selected="addMentionedUser" />
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import { PostActions } from '@/store'
import validatable from '@/components/mixins/validatable'
import UserSearchDialog from '@/components/users/UserSearchDialog'
import CategoriesInput from '@/components/custom/CategoriesInput'
import EditParticipantList from '@/components/posts/participants/EditParticipantList'

const MaxTeaserGifSize = 1024 * 1024 * 15 // 15MB

export default {
  name: 'EditPostForm',
  mixins: [validatable('form')],
  components: { EditParticipantList, UserSearchDialog, CategoriesInput },
  props: {
    post: Object
  },
  data () {
    return {
      visibilityTypes: [{
        text: this.$t('models.post.visibility_types.open'),
        value: 'open'
      }, {
        text: this.$t('models.post.visibility_types.followers'),
        value: 'followers'
      }, {
        text: this.$t('models.post.visibility_types.premium'),
        value: 'premium'
      }, {
        text: this.$t('models.post.visibility_types.exclusive'),
        value: 'exclusive'
      }],
      newTitle: '',
      newContent: '',
      newPrice: 0,
      newVisibility: '',
      newTeaser: [],
      newCategories: [],
      startDate: null,
      startTime: null,
      dialog: false,
      members: [],
      teaserError: false,
      newParticipants: [],
      deletedParticipants: [],
      mentions: [],
      mentionDialog: false,
      showForm: true
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    hasTeaserError () {
      return !!this.newTeaser.length && this.teaserError
    },
    teaserHint () {
      return this.hasTeaserError ? this.$t('errors.teaser_gif_size_error')
      : this.$t('posts.form.edit_teaser_hint')
    },
    canHaveTeaser () {
      return this.isVideo && (this.isFollower || this.isPremium)
    },
    publishAt () {
      if (!this.startDate || !this.startTime) {
        return null
      }
      return new Date(`${this.startDate}T${this.startTime}`)
    },
    isVideo () {
      return this.post.postType === 'video'
    },
    isOpen () {
      return this.post.visibility === 'open'
    },
    isFollower () {
      return this.post.visibility === 'followers'
    },
    isExclusive () {
      return this.post.visibility === 'exclusive'
    },
    isPremium () {
      return this.post.visibility === 'premium'
    },
    canChangeVisibility () {
      return this.isOpen || this.isFollower
    },

    visibilityDescription () {
      return this.post.visibility === ''
        ? ''
        : this.$t(`posts.form.type_description.${this.post.visibility}`)
    },
    visibilityHint () {
      return this.isOpen ? '' : this.$t('posts.edit.visibility_hint')
    },

    showPrice () {
      return this.isPremium || this.isExclusive ||
        this.newVisibility === 'exclusive' || this.newVisibility === 'premium'
    },
    showChoseUser () {
      return this.isExclusive || this.newVisibility === 'exclusive'
    },
    isInvalidDate () {
      if (!this.publishAt) {
        return false
      }
      return this.publishAt.getTime() < new Date().getTime()
    },

    decodedContent () {
      try {
        return decodeURI(this.post.content)
      } catch (err) {
        return this.post.content
      }
    },

    participants () {
      return {
        current: this.post.participants,
        added: this.newParticipants,
        deleted: this.deletedParticipants
      }
    },

    submitable () {
      const validTeaser = !this.newTeaser.length || !!this.newTeaser.find(f => f.uploaded)
      return !this.hasTeaserError && this.validForm && validTeaser
    }
  },

  methods: {
    showMentionDialog() {
      this.mentionDialog = true
    },
    teaserAdded (file) {
      this.teaserError = file.name.toLowerCase().endsWith('.gif') && (file.size > MaxTeaserGifSize)
      this.newTeaser = [file]
    },

    addMentionedUser({ username }) {
      if (this.mentions.includes(username)) return
      this.mentions.push(username)
    },

    removeMentionedUser(username) {
      const index = this.mentions.findIndex(m => m === username)
      this.mentions.splice(index, 1)
    },
    addSelectedUser (user) {
      this.selectedUsers.push(user)
    },

    removeSelectedUser (user) {
      const index = this.selectedUsers.findIndex(u => u.username === user.username)
      if (index >= 0) {
        this.selectedUsers.splice(index, 1)
      }
    },

    emitClose () {
      this.$emit('close')
    },

    showDialog () {
      this.dialog = true
    },

    newData () {
      const data = {
        id: this.post.id,
        title: this.newTitle,
        visibility: this.newVisibility,
        content: encodeURI(this.newContent),
        publishAt: this.publishAt,
        mentions: this.mentions,
        price: this.newPrice,
        categoryIds: this.newCategories,
        deletedParticipants: this.deletedParticipants.map(p => p.id),
        newParticipants: this.newParticipants.map(p => ({
          dni1Path: p.dni1.location, dni2Path: p.dni2.location, selfiePath: p.selfie.location, agreementPath: p.agreement.location
        }))
      }

      if (this.newTeaser.length) {
        data.teaser = this.newTeaser.map(f => f.location)[0]
      }

      return data
    },

    updateParticipants ({ added, deleted }) {
      this.newParticipants = added
      this.deletedParticipants = deleted
    },

    addParticipant (participant) {
      this.newParticipants.push(participant)
    },

    removeNewParticipant (index) {
      this.newParticipants.splice(index)
    },

    deleteParticipant (participant) {
      const deleted = this.deletedParticipants.find(p => p.id === participant.id)
      if (deleted) return
      this.deletedParticipants.push(participant)
    },

    removeDeletedParticipant (participant) {
      const index = this.deletedParticipants.findIndex(p => p.id === participant.id)
      if (index === -1) return
      this.deletedParticipants.splice(index)
    },

    async onSubmit () {
      const { data, error } = await this.$store.dispatch(PostActions.Update, this.newData())
      const notification = { message: this.$t('snackbar.update_success') }
      if (error) {
        notification.message = this.$t(`remote_errors.${data.errorCode}`)
        notification.error = true
      }
      this.$eventBus.publish('notification', notification)
      this.emitClose()
    },
    destroy () {
      this.$refs.at.closePanel()
    },
  },
  mounted () {
    const padTo = (elem, count, char) => (`${elem}`).padStart(count, char)
    this.mentions = (this.post.mentions || []).map(m => m.username)
    this.newTitle = this.post.title
    this.newContent = this.decodedContent
    this.newVisibility = this.post.visibility
    this.newPrice = this.post.price
    this.newCategories = this.post.categories.map(c => c.id)
    this.participants.current = JSON.parse(JSON.stringify(this.post.participants || []))
    const publishDate = new Date(this.post.publishedAt)
    this.startDate = 'YYYY-MM-DD'.replace('YYYY', publishDate.getFullYear())
      .replace('MM', padTo(publishDate.getMonth() + 1, 2, '0'))
      .replace('DD', padTo(publishDate.getDate(), 2, '0'))
    this.startTime = 'HH:MM:00'.replace('HH', padTo(publishDate.getHours(), 2, '0'))
      .replace('MM', padTo(publishDate.getMinutes(), 2, '0'))
  }
}
</script>
<style lang="scss">
  .atwho-inner {
    .atwho-view {
      position: sticky !important;
    }
  }
</style>
