<template>
  <v-card-text>
    <h2>{{ $t('payments.products.error.text') }}</h2>
    <p>{{ $t(`payments.products.error.${error}`) }}</p>
    <p>{{ $t('payments.products.error.text_2') }}</p>
    <v-row justify="end">
      <v-col cols="auto">
        <c-btn @click="close">{{ $t('actions.close') }}</c-btn>
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
export default {
  name: 'PaymentFail',
  props: {
    error: String
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
