<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-icon><v-icon color="success">check_box</v-icon></v-list-item-icon>
      <v-list-item-content style="text-decoration: underline">{{ $t('withdraws.withdraw_methods.paypal.info.fee') }}</v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon><v-icon color="success">check_box</v-icon></v-list-item-icon>
      <v-list-item-content>{{ $t('withdraws.withdraw_methods.paypal.info.description_1') }}</v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon><v-icon color="success">check_box</v-icon></v-list-item-icon>
      <v-list-item-content>
        <i18n path="withdraws.withdraw_methods.paypal.info.description_2" tag="span">
          <a place="link" href="https://www.paypal.com/for-you/account/create-account" rel="_blank">PayPal</a>
        </i18n>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon><v-icon color="success">check_box</v-icon></v-list-item-icon>
      <v-list-item-content>{{ $t('withdraws.withdraw_methods.paypal.info.description_3') }}</v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'PaypalMethodInfo'
}
</script>
