<template>
  <div class="d-flex chat-row" :class="chatRowClass">
    <AvatarUser
      :avatar="chat.receiver.avatar"
      :height="34"
      :width="34"
      :username="chat.receiver.displayName"
      :subText="messageText_1"
       :trustedProfile="chat.receiver.trustedProfile"
      :onlineStatus="chat.receiver.onlineStatus"
      style="border-radius: 20px; margin-left: 0px;"
    >
    </AvatarUser>
    <div class="notifications">
      <div class="center notifications-date" v-if="showSubMenu">
        <input
          class="regular-check sub"
          type="checkbox"
          :id="`chat-${chat.id}`"
          :name="chat.receiver.displayName"
          :value="selected"
          @change="emitEvent()">
      </div>
      <div class="center notifications-date" v-else>
        <span v-if="chat.fan" class="material-icons fan">star</span>
        <span v-if="chat.tip" class="material-icons tip">attach_money </span>
        <icon-button
          v-if="isSilenced"
          :size="15"
          type="mute"
          style="margin:0"
        />
        <icon-button
          v-if="isBlocked"
          :size="15"
          type="block"
          style="margin:0"
        />
        <span
          v-if="!chat.isRead && chat.unreadMessages > 0"
          class="unreadMessages"
          >{{ chat.unreadMessages }}</span
        >
      </div>
      <div class="date-last-message">
        <p>{{ dateSend }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AvatarUser from '@/components/chat/component/AvatarUser'
import IconButton from '@/components/chat/component/IconButton'

export default {
  name: 'ChatRow',
  components: { AvatarUser, IconButton },
  props: {
    chat: Object,
    showSubMenu: Boolean,
    deleteChat: Boolean,
    selectedAll: Boolean
  },
  data () {
    return {
      selected: false
    }
  },
  watch: {
    isSelectedAll (value) {
      if (value) {
        this.selected = true
        document.getElementById(`chat-${this.chat.id}`).checked = true
      }
    }
  },
  computed: {
    ...mapState('chats', ['selectedChat']),
    ...mapState('application', ['isMobile']),
    isSelectedAll () {
      return this.selectedAll
    },
    darkTheme(){
      return this.$vuetify.theme.dark
    },
    chatRowClass () {
      const isActive =
        this.selectedChat && this.chat.id === this.selectedChat.id
      return {
        unread: !this.chat.isRead && this.chat.unreadMessages > 0,
        dark: this.darkTheme,
        active: isActive
      }
    },
    isBlocked () {
      return !this.chat.sender.approved
    },
    isSilenced () {
      return this.chat.sender.archived
    },
    cssChatStyle () {
      return {
        maxWidth: this.hasNotifications ? '75%' : '80%'
      }
    },
    hasNotifications () {
      return this.chat.tip || this.chat.fan
    },
    chatUsernameClass () {
      const isActive =
        this.selectedChat && this.chat.id === this.selectedChat.id
      return {
        dark: this.darkTheme,
        noActive: !isActive
      }
    },
    messageText_1 () {
      const content = this.decodeLastMessage(this.chat.lastMessageContent || '')
      const valueMaxLength = 20
      if (content.length > valueMaxLength) {
        let result = content.substring(0, valueMaxLength)
        return (result = `${result}...`)
      } else {
        return content
      }
    },
    messageText () {
      const content = this.chat.lastMessageContent || ''
      try {
        return decodeURI(decodeURI(decodeURI(content)))
      } catch (ex) {
        try {
          return decodeURI(decodeURI(content))
        } catch (ex) {
          try {
            return decodeURI(content)
          } catch (ex) {
            return content
          }
        }
      }
    },
    dateSend () {
      const lastMessageAt = this.chat.lastMessageAt
      const lastMessageAtDate = this.$date(lastMessageAt)
      const today = this.$date(new Date())
      const isToday = lastMessageAtDate.startsWith(today)
      if (isToday) {
        const result = this.$datetime(lastMessageAt)
        return result.substring(11, result.length - 3)
      } else {
        return this.$date(lastMessageAt)
      }
    }
  },
  methods: {
    emitEvent () {
      if (this.deleteChat) {
        this.$emit('removeChatId')
      } else {
        this.$emit('readChatId')
      }
    },
    decodeLastMessage (message) {
      try {
        return decodeURIComponent(decodeURIComponent(decodeURIComponent(message)))
      } catch (ex) {
        try {
          return decodeURIComponent(decodeURIComponent(message))
        } catch (ex) {
          try {
            return decodeURIComponent(message)
          } catch (ex) {
            return message
          }
        }
      }
    }
  },
  mounted() {
    if (this.selectedAll && this.showSubMenu) {
      this.selected = true
      document.getElementById(`chat-${this.chat.id}`).checked = true
    }
  }
}
</script>
<style lang="scss">
.chat-row {
  contain: content;
  cursor: pointer;
  align-items: center;
  margin-right: -1px;
  background-color: #f0f0f0;
  border-left: 5px solid transparent;
  &.dark {
    background-color: #2d3237;
    &.active {
      background-color: gray;
    }
  }
  &.dark:hover {
    background-color: #3b3b3b;
  }
  &.active {
    background-color: #ffffff;
    border-left: 5px solid var(--v-primary-base);
    border-right: 1px solid white;
  }
  &:hover {
    background-color: #e6e6e6;
  }
}

.chat-resume {
  width: 80%;
}

.time {
  width: 20%;
}
.unread {
  background-color: #f3fff5;
  &.dark {
    background-color: #121b22 !important;
    &.active {
      border-color: #a3a2a2 !important;
      border-left: 5px solid var(--v-primary-base);
      border-right: 1px solid var(--v-primary-base);
      border-top: 1px groove var(--v-primary-base);
    }
  }
}

.notifications {
  margin: auto;
  margin-right: 10px;
}
.notifications-date {
  justify-content: flex-end !important;
  align-items: center;
  margin-top: 15px;
}
.material-icons.fan {
  font-size: 17px;
  color: #ffc107;
}
.material-icons.tip {
  font-size: 17px;
  color: #5de729;
}
.material-icons.blocked {
  font-size: 17px;
  color: #414141;
}

.unreadMessages {
  font-size: 10px;
  background-color: #666666;
  color: #ffffff;
  height: 16px;
  width: 17px;
  border-radius: 50%;
  padding: auto;
  text-align: center;
  font-weight: bold;
}

.date-last-message {
  position: relative;
  top: 4px;
  font-size: 11px;
  text-align: right;
}


.regular-check {
  -webkit-appearance: none;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
  inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 4px;
  border-radius: 1px;
  width: 12px !important;
  height: 12px !important;
  display: inline-block;
  position: relative;
  background-color: transparent;
  margin-right: 10px;
  .main {
    position: absolute;
    top: -25px;
    background: #fff;
    padding: 0 10px;
  }
  &.sub {
    margin-left: 25px;
  }
}
.regular-check:checked {
  background-color: var(--v-primary-base);
}
.regular-check:checked:disabled {
  background-color: #939393;
}
</style>
