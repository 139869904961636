<template>
  <div class="product-card-actions translucient-dark">
    <v-icon color="white">bookmark_border</v-icon>
    <div class="flex-grow-1"></div>
    <c-btn small
      :to="{ name: 'product', params: { uuid: product.id } }">{{ $t('actions.show') }}</c-btn>
  </div>
</template>
<script>
export default {
  name: 'BottomBarOverlay',
  props: {
    product: Object
  }
}
</script>
<style lang="scss" scoped>
  .product-card-actions {
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 4px;
  }
</style>
