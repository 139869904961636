<template>
  <div class="discovery-card">
    <h1 class="title-text bold mb-4">{{ titleText }}</h1>
    <div class="title-text-2 roboto">{{ descriptionText }}</div>
<!--    <div class="my-4">-->
<!--      <c-btn-->
<!--        :to="{ name: 'stars_discover' }">{{ $t('actions.discover_them') }}-->
<!--      </c-btn>-->
<!--    </div>-->
  </div>
</template>
<script>

export default {
  name: 'StarsDiscovery',
  props: {
    empty: Boolean
  },
  computed: {
    titleText () {
      return this.empty
        ? this.$t('users.discovery.empty_title')
        : this.$t('users.discovery.title')
    },
    descriptionText () {
      return this.empty
        ? this.$t('users.discovery.empty_description')
        : this.$t('users.discovery.description')
    }
  }
}
</script>
<style scoped>
.discovery-card {
  width: 400px;
  text-align: center;
  margin: 16px auto;
}
</style>
