export const events = [
  {
    event: 'user_registration',
    trackAction: 'user_registration_complete'
  },
  {
    event: 'log_in',
    trackAction: 'log_in'
  },
  {
    event: 'log_in',
    trackAction: 'twitter_log_in'
  },
  {
    event: 'star_registration',
    trackAction: 'star_registration_step1'
  },
  {
    event: 'star_registration',
    trackAction: 'star_registration_step2'
  },
  {
    event: 'star_registration',
    trackAction: 'star_registration_step3'
  },
  {
    event: 'star_registration',
    trackAction: 'star_registration_step4'
  },
  {
    event: 'star_registration',
    trackAction: 'star_registration_complete'
  },
  {
    event: 'subscription',
    trackAction: 'subscription_step1'
  },
  {
    event: 'subscription',
    trackAction: 'subscription_step2'
  },
  {
    event: 'subscription',
    trackAction: 'subscription_complete'
  },
  {
    event: 'subscription',
    trackAction: 'subscription_error'
  },
  {
    event: 'reload_wallet',
    trackAction: 'reload_wallet_step1'
  },
  {
    event: 'reload_wallet',
    trackAction: 'reload_wallet_step2'
  },
  {
    event: 'reload_wallet',
    trackAction: 'reload_wallet_complete'
  },
  {
    event: 'reload_wallet',
    trackAction: 'reload_wallet_error'
  },
  {
    event: 'tip',
    trackAction: 'tip_complete'
  },
  {
    event: 'premium_content',
    trackAction: 'premium_content_card_step1'
  },
  {
    event: 'premium_content',
    trackAction: 'premium_content_card_complete'
  },
  {
    event: 'premium_content',
    trackAction: 'premium_content_card_error'
  },
  {
    event: 'premium_content',
    trackAction: 'premium_content_wallet_complete'
  }

]
