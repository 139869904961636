<template>
  <div class="d-flex flex-column justify-center align-center text-center fill-height pa-2  gradient">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Cover'
}
</script>
<style lang="scss" scoped>
.backgrop {
  backdrop-filter: blur(25px);
}
.gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50));
}
</style>
