<template>
  <c-dialog persistent
    width="684"
    :value="value"
    @input="close">
    <template #title>
      <h1>{{ $t('wallet.currency_wallet', { currency: wallet.currency}) }}</h1>
    </template>
    <v-row>
      <v-col cols="6">{{ $t('wallet.subscriptions_balance') }}</v-col>
      <v-col cols="6">
        <span class="semibold">{{ $currency(wallet.subscriptionsAmount, wallet.currency) }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('wallet.sales_balance') }}</v-col>
      <v-col cols="6">
        <span class="semibold">{{ $currency(wallet.sellsAmount, wallet.currency) }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('wallet.tips_balance') }}</v-col>
      <v-col cols="6">
        <span class="semibold">{{ $currency(wallet.tipsAmount, wallet.currency) }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('wallet.conference_balance') }}</v-col>
      <v-col cols="6">
        <span class="semibold">{{ $currency(wallet.conferenceAmount, wallet.currency) }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('wallet.chats_balance') }}</v-col>
      <v-col cols="6">
        <span class="semibold">{{ $currency(wallet.chatsAmount, wallet.currency) }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('wallet.movement_balance') }}</v-col>
      <v-col cols="6">
        <span class="semibold">{{ $currency(wallet.movementAmount, wallet.currency) }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('wallet.withdrew_balance') }}</v-col>
      <v-col cols="6">
        <span class="primary--text semibold">{{ $currency(wallet.withdrewAmount, wallet.currency) }}</span>
      </v-col>
    </v-row>
  </c-dialog>
</template>
<script>
import WalletWithdraw from './WalletWithdraw'

export default {
  name: 'WalletDetailDialog',
  components: { WalletWithdraw },
  props: {
    value: Boolean,
    wallet: Object
  },
  methods: {
    close () {
      this.$emit('input', false)
    },
  }
}
</script>
