<template>
  <div class="d-flex flex-column justify-center align-center fill-height">
    <c-btn small :color="buttonColor">{{ $t('actions.show_details') }}</c-btn>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'CardOverlay',
  props: {
    product: Object
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    ...mapState('profile', ['currentUser']),
    buttonColor () {
      return this.darkTheme ? 'secondary' : 'white'
    },
    finishDate () {
      return this.isAuction ? new Date(this.product.finishAt) : null
    },
    isPublisher () {
      return this.product.username === this.currentUser.username
    },
    isSale () {
      return this.product.productType === 'sale'
    },
    isAuction () {
      return !this.isSale
    },
    isFinished () {
      return this.isAuction && (this.product.finishDate.getTime() > new Date().getTime())
    },
    isPurchasable () {
      return !this.product.acquired && !this.isPublisher && !this.isFinished
    },
    label () {
      return this.$t(`products.buy_product.${this.product.productType}`)
    }
  }
}
</script>
