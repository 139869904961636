<template>

  <section>
    <v-col :cols="isMobile ? 12 : 10" style="margin: auto">
      <v-row class="modal-header" :style="getBorderStyle">
        <img :width="isMobile ? 200 : 250"
             :src="getSrc" align="left" style="margin-top: auto; margin-right: auto; margin-bottom: 10px"/>
        <v-col style="text-align: right" >RECIBO DE COMPRA</v-col>

      </v-row>
      <v-spacer style="height: 25px"></v-spacer>
    </v-col>
    <v-col :cols="isMobile ? 12 : 10" style="margin-left: auto">

      <v-row style="margin: auto">

        <v-col cols="12">
          <h1 style="font-size: 25px">LoverFans.com</h1>
        </v-col>
        <v-col cols="6">
          <p>Domicilio en CALLE GOYA, NUM 47 PLANTA 7, PUERTA 12</p>
          <p>28001 MADRID</p>
        </v-col>
        <v-col cols="6" >
          <p><b>Web:</b> https://loverfans.com</p>
          <p><b>Email:</b> soporte@loverfans.com</p>
        </v-col>
      </v-row>

      <v-spacer style="height: 40px"></v-spacer>

      <v-row style="margin: auto">
        <v-col cols="6">
          <h1 style="font-size: 25px">Cliente:</h1>
        </v-col>
        <v-col cols="6">
          <h1 style="font-size: 25px">Pedido: {{factura.id}}</h1>
        </v-col>
      </v-row>
      <v-row style="margin: auto">
        <v-col cols="6" >
          <p>{{ factura.fullName}}</p>
          <p>{{ factura.userName}}</p>
          <p>{{ factura.userMail}}</p>
        </v-col>
        <v-col cols="6" >
          <p><b>Estado: </b> {{ factura.status}}</p>
          <p><b>Fecha: </b> {{ getDate(factura.date)}}</p>
          <p><b>Método de Pago: </b> {{ factura.metodoPago}}</p>
        </v-col>
      </v-row>
    </v-col>

    <v-spacer style="height: 25px"></v-spacer>

    <v-col :cols="isMobile ? 12 : 8" style="margin: auto">
      <v-row style="border-bottom: inset;" :style="getBorderStyle">
        <v-col cols="8">
          <p><b>{{factura.content}}</b></p>
          <p><b>Impuestos</b></p>
        </v-col>
        <v-col cols="4" style="text-align: right">
          <p>{{factura.amount}}</p>
          <p>{{factura.impuesto}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <b>Total:</b>
        </v-col>
        <v-col cols="6" style="text-align: right">
          <p>{{factura.total}}</p>
        </v-col>
      </v-row>
    </v-col>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Utils from '@/utils'
import dialog from '@/components/mixins/dialog'

export default {
  name: 'BuyBill',
  mixins: [dialog],
  props: {
    value: Boolean,
    type: { type: String, default: 'withdraw' },
    bill: { type: Object, default: null }
  },
  data () {
    return {
      dialogKey: new Date().getTime(),
      factura: {}
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('preferences', ['darkTheme']),
    getBorderStyle () {
      return {
        borderBottom: 'inset',
        borderBottomColor: this.darkTheme ? 'rgb(165,164,164)' : 'rgb(45,45,45)'
      }
    },
    getSrc () {
      return '/images/' + (this.darkTheme ? 'logo_horizontal_white_color' : 'logo_horizontal') + '.svg?dfk23'
    }
  },
  methods: {
    getTotal (amount) {
      return amount + (amount * this.bill.vat / 100)
    },
    getDate (date) {
      return Utils.formatDate(date)
    },
    startDownloading () {
      this.$emit('download', this.factura)
      this.$emit('input', false)
      this.$emit('close')
    }
  },
  mounted () {
    this.factura = {
      fullName: this.currentUser.fullName,
      userName: this.currentUser.userName,
      userMail: this.currentUser.email,
      id: this.bill.id,
      status: 'Completado',
      date: this.bill.date || this.bill.billedAt,
      metodoPago: this.bill.creditCard ? 'Tarjeta de credito' : 'Monedero',
      content: this.bill.type || this.bill.content,
      amount: this.$currency(this.bill.amount, this.bill.currency),
      impuesto: this.$currency(this.bill.vat, this.bill.currency),
      total: this.$currency(this.bill.total ? this.bill.total : this.getTotal(this.bill.amount), this.bill.currency)
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-header {
    border-bottom: inset;
  }
</style>
