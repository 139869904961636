<template>
  <div class="count" :tabindex="1">
    <p class="numgal">+{{ count }}</p>
  </div>
</template>

<script>
export default {
  name: "CountPlus",
  props: {
    count: Number
  },
};
</script>
<style lang="scss" scoped>
.count {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.numgal {
  font-size: 2.5em;
  line-height: 1;
}
</style>
