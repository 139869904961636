<template>
  <section
    class="button"
    :class="classCss"
    :style="`background-color:${getColor}; border: 1px ${getColor} solid;`"
    @click="handlerButton"
  >
    <div class="">
      <slot></slot>
      <Spinner v-if="loading"></Spinner>
      <div v-else>
        <p>{{ text.toUpperCase() }}</p>
      </div>
    </div>
  </section>
</template>
<script>
import Spinner from './Spinner'
export default {
  name: 'Buttons',
  components: { Spinner },
  props: {
    disabled: { type: Boolean, default: false },
    text: { type: String, default: '' },
    color: { type: String, default: '#4fbf39' },
    loading: { type: Boolean, default: false },
    close: { type: Boolean, default: false }
  },
  computed: {
    classCss () {
      return { disabled: this.disabled }
    },
    getColor () {
      return this.close ? '#f44336' : this.color
    }
  },
  methods: {
    handlerButton () {
      if (this.disabled) {
        return
      }
      this.$emit('handler')
    }
  }
}
</script>
<style lang="scss">
.button {
  align-self: center;
  border-radius: 20px;
  font-size: 0.8em;
  margin-top: 0;
  margin-left: 5px;
  padding: 3px 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.42857143;
  text-align: center;
  align-items: center;

  cursor: pointer;
  &:hover {
    border-color: #ffffff !important;
    background-color: black !important;
  }
  &.disabled {
    background-color: #9b9b9b3b !important;
    border-color: #9b9b9b3b !important;
    color: #333333 !important;
  }
  &.disabled:hover {
    cursor: not-allowed;
  }
}
</style>
