<template>
  <div class="title-text-3 roboto">
    <div><strong>{{ $t('products.sale.ref_number', { ref: sale.ref }) }}</strong></div>
    <div>{{ $t('products.delivery.shipment.title', { name: sale.username }) }}</div>
    <p>{{ $date(sale.date) }}</p>
    <div class="semibold my-2">{{ $t('products.delivery.shipment.sent_to') }}:</div>
    <div>
      {{ $t('products.delivery.shipment.email', { email: sale.email }) }}
    </div>
    <div>
      {{ sale.name }}
    </div>
    <template v-if="isPacket">
      <div>{{ sale.address }}</div>
      <div>
        {{ sale.city }}, {{ sale.province }} {{ sale.postalCode }}
      </div>
      <div>{{ $country(sale.country) }}</div>
    </template>
    <span v-if="!sale.completed">{{ $t('products.delivery.not_completed', { name: sale.name }) }}</span>
    <div v-else-if="!isSent"
      class="semibold mb-1">{{ $t('products.delivery.not_sent', { name: product.author.username }) }}</div>
    <div v-else
      class="semibold mb-1">
      {{ $t('products.delivery.sent', { name: product.author.username }) }}
    </div>
    <div v-if="sale.completed && !isSent">
      <c-btn @click.stop="markAsSent">
        {{ $t('products.delivery.mark_sent') }}
      </c-btn>
    </div>
  </div>
</template>
<script>
import { ProductActions } from '@/store'

export default {
  name: 'SaleInfo',
  props: {
    product: { type: Object },
    sale: { type: Object, default: () => ({}) }
  },
  data () {
    return { isSent: false }
  },
  watch: {
    sale(value) {
      this.isSent = value.sent
    }
  },
  computed: {
    isPacket () {
      return this.product.deliveryType === 'packet'
    }
  },
  methods: {
    async markAsSent () {
      const { error } = await this.$store.dispatch(ProductActions.MarkAsSent, { productId: this.product.id, paymentId: this.sale.ref })
      if (!error) {
        this.$eventBus.publish('notification', {
          message: this.$t('snackbar.save_success')
        })
        this.isSent = true
      }
    }
  },
  mounted () {
    this.isSent = this.sale.sent
  }
}
</script>
