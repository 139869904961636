<template>
  <c-dialog persistent
    width="684"
    :value="value"
    @input="hideDialog">
    <div class="text-center mb-6">
      <h1>{{ $t('blocked_users.dialog.question', { username: this.user.username }) }}</h1>
      <p class="my-4">{{ $t('blocked_users.dialog.description') }}</p>
      <c-btn @click="blockUser">
        {{ $t('types.boolean.true') }}
      </c-btn>
    </div>
    <ul class="x-small-text"
      style="list-style-type: none">
      <li>{{ $t('blocked_users.dialog.description_1') }}</li>
      <li>{{ $t('blocked_users.dialog.description_2') }}</li>
      <li>{{ $t('blocked_users.dialog.description_3') }}</li>
    </ul>

  </c-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'

export default {
  name: 'BlockUserWarningDialog',
  props: {
    user: Object,
    dark: { type: Boolean, default: null },
    value: { type: Boolean, default: true }
  },
  computed: {
    ...mapState('profile', ['blockedUsers'])
  },
  methods: {
    hideDialog () {
      this.$emit('input', false)
    },
    async blockUser () {
      await this.$store.dispatch(ProfileActions.BlockUser, this.user)
      this.hideDialog()
    }
  }
}
</script>
