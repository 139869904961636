<template>
    <v-row no-gutters>
      <v-col cols="12" class="product-card-title bold">{{ product.name }}</v-col>
      <v-col cols="12" class="product-card-info">
        <v-row no-gutters align="center" justify="space-between">
          <div class="bold">
            <Currency :price="product.price" />
            <span v-if="isAuction && isAuthor" class="ml-1">
              ({{ $tc('products.auction.bid_count', bidsCount, {count: product.bidsCount}) }})
            </span>
          </div>
          <span class="small-text">
            {{ $date(product.publishedAt) }}
          </span>
        </v-row>
        <UserAvatar small :user="author" />
      </v-col>
    </v-row>
</template>
<script>
import { mapState } from 'vuex'
import UserAvatar from '@/components/users/UserAvatar'

export default {
  name: 'ProductCard',
  components: { UserAvatar },
  props: {
    product: Object
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    author () {
      return this.product.author
    },
    isAuction () {
      return this.productType === 'auction'
    },
    isAuthor () {
      return this.username === this.currentUser.username
    }
  }
}
</script>
<style lang="scss" scoped>

.product-card-title {
  max-height: 42px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.product-card-info {
  color: #546e7a;
}

</style>
