<template>
  <section>
    <v-row justify="center">
      <v-col :cols="isMobile ? 11 : 8">
        <p class="campo-label">{{ $t('promotion.request_finished.text_1') }}</p>
      </v-col>
      <v-col :cols="isMobile ? 11 : 8">
        <p class="campo-label">
          {{ $t('promotion.request_finished.text_2', {email: `stars@${project.project}.com`}) }}
          <!--          <i18n path="promotion.request_finished.text_2" class="campo-label">
                      <template #email>
                        <a :href="`mailto:stars@${project.project}.com`"
                           target="_blank"
                           class="email-link">
                          stars@{{ project.project }}.com
                        </a>
                      </template>
                    </i18n>-->
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" style="margin-bottom: 90px;margin-top: 40px;">
      <v-btn @click="goTo()" :style="{backgroundColor: isDark ? '#B0601E' : '#f71953', color: '#FFFFFF'}" >{{ $t('actions.go_to_item', {item: $t('generic.home')}) }} ➣</v-btn>
    </v-row>
  </section>

</template>

<script>
import { mapState } from 'vuex'
import project from '@/project'

export default {
  name: 'SignUpStarFormFinal',
  props: {
    isDark: Boolean,
    homeButton: { type: Boolean, default: false }
  },
  computed: {
    ...mapState('application', ['isMobile'])
  },
  methods: {
    goTo () {
      const origin = sessionStorage.getItem('origin')
      if (origin) {
        this.$router.replace(origin)
        sessionStorage.removeItem('origin')
      } else {
        this.$router.replace('/home')
      }
    }
  },
  data () {
    return {
      project
    }
  }
}
</script>

<style lang="scss" scoped>

  .campo-label {
    font-size: 16px;
    font-family: 'Quicksand', sans-serif;
  }

  .email-link {
    color: rgba(0,0,0,.87);
    text-decoration: underline;
  }

</style>
