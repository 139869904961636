<template>
  <span>{{ formatted }}</span>
</template>
<script>
export default {
  name: 'Currency',
  props: {
    currency: String,
    amount: [String, Number],
    price: [String, Number],
    unsigned: Boolean,
    cents: { type: Boolean, default: true }
  },
  computed: {
    value () {
      return this.price !== null && this.price !== undefined
        ? this.price
        : this.amount
    },

    formatted () {
      let masked = this.$currency(this.value, this.currency)
      if (this.unsigned) {
        masked = masked.replace('+', '').replace('-', '')
      }
      return masked
    }
  }
}

</script>
