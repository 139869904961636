<template>
  <v-form v-model="validForm" ref="form"
    :dark="false"
    @submit.prevent="submit">
    <div class="d-flex justify-space-between align-center">
      <h1 class="montserrat giant-text bold my-2">{{ $t("signup.form.title") }}</h1>
      <span class="success--text">
        <v-icon color="success">lock_outline</v-icon>
        {{ $t('signup.secure_net') }}
      </span>
    </div>
    <h2 class="title-text-2 roboto my-2">{{ $t("signup.form.description") }}</h2>
    <v-alert type="error"
      v-if="signupError"
      icon="warning"
      dense outlined>
      {{ signupError }}
    </v-alert>
    <v-row>
      <v-col cols="12" sm="6">
        <username-input outlined autofocus
          v-model="username"
          :rules="[$vRequired]" />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field outlined
          persistent-hint
          :label="$t('models.user.displayName')"
          v-model="displayName"
          :rules="[$vBannedWords(displayName),$vMaxLength(50)]"
          :hint="$t('models.user.displayName_hint')"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field outlined
          ref="email"
          :label="$t('models.user.email')"
          v-model="email"
          :rules="[$vRequired,$vEmail]"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field outlined
          :label="$t('models.user.email_confirmation')"
          v-model="emailConfirmation"
          :rules="[$vRequired,$vConfirmation('email')]"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <PasswordInput outlined
          ref="password"
          v-model="password"
          :rules="[$vRequired]" />
      </v-col>
      <v-col cols="12" sm="6">
        <PasswordInput outlined
          :label="$t('models.user.password_confirmation')"
          v-model="passwordConfirmation"
          :override-rules="[$vRequired,$vConfirmation('password')]" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field outlined
                      :label="$t('models.user.telegram')"
                      v-model="telegram"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field outlined
                      :label="$t('models.user.twitter')"
                      v-model="twitterUser"></v-text-field>
      </v-col>
      <v-col cols="12" sm="10" style="margin: auto">
        <v-text-field outlined
                      :label="$t('models.user.skype')"
                      v-model="skype"></v-text-field>
      </v-col>
      <v-col cols="12" sm="10" style="margin: auto" >

        <v-row>
          <v-col cols="4" >
            <v-select  outlined
                       dense
                       hide-details
                       v-model="prefix"
                       :items="countries">
              <template v-slot:selection="{ item }">
                <img width="32" height="24" :src="item.image">{{ item.value }}
              </template>
              <template v-slot:item="{ item }">
                <img :src="item.image">{{ item.value }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="8" >
            <v-text-field outlined
                          :label="$t('models.user.mobile')"
                          v-model="mobile"></v-text-field>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        {{ $t('application.menu.management') }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-checkbox outlined
          color="primary"
          :label="$t('models.user.isAdult')"
          v-model="isAdult"
          :rules="[$vBoolRequired]"></v-checkbox>
        <v-checkbox outlined
          color="primary"
          v-model="acceptedTerms"
          :rules="[$vBoolRequired]">
          <template slot="label">
            {{$t('models.user.accept')}}&nbsp;
            <a @click.stop :href="goToConditions" target="_blank"> {{ $t('application.menu.terms_conditions') }}</a>
          </template>
        </v-checkbox>
        <v-checkbox outlined
                    color="primary"
                    v-model="acceptedMarketing"
                    :rules="[$vBoolRequired]">
          <template slot="label">
            {{ $t('application.menu.marketing') }}
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <div class="d-flex pb-1 pt-1 justify-center ">
      <recaptcha :captchaVersion="captchaVersion" ref="recaptcha" @verify="saveTokenRecaptcha"/>
    </div>
    <v-row align="center" justify="space-between">
      <v-col cols="auto">
        <c-btn rounded outlined :to="{ name: 'login' }"
          color="secondary">
          {{ $t('actions.back') }}
        </c-btn>
      </v-col>
      <v-col cols="auto">
        <c-btn rounded
          :disabled="!validForm"
          @click.stop.prevent="submit">
          {{ $t('actions.next') }}
        </c-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { SessionActions } from '@/store'
import validatable from '@/components/mixins/validatable'
import Recaptcha from "@/components/verifyIdenty/Recaptcha.vue";

export default {
  name: 'SignupForm',
  components: {Recaptcha},
  mixins: [validatable('form')],
  props: {
    origin: { type: String, default: 'Landing' }
  },
  data () {
    return {
      displayName: '',
      username: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      acceptedTerms: false,
      acceptedMarketing: false,
      isAdult: false,
      countries: [],
      telegram: '',
      twitterUser: '',
      skype: '',
      prefix: '',
      mobile: '',
      signupError: '',
      captchaToken: '',
      captchaVersion: 3
    }
  },
  computed: {
    goToConditions () {
      return `https://${this.$t('site')}/conditions`
    }
  },

  methods: {
    user () {
      return {
        username: this.username.trim(),
        displayName: this.displayName.trim(),
        email: this.email.trim(),
        password: this.password.trim(),
        acceptedTerms: this.acceptedTerms,
        acceptedMarketing: this.acceptedMarketing,
        isAdult: this.isAdult,
        lang: this.$i18n.locale.trim(),
        skype: this.skype,
        telegram: this.telegram,
        twitterUser: this.twitterUser,
        prefix: this.prefix,
        mobile: this.mobile,
        captchaToken: this.captchaToken
      }
    },
    async saveTokenRecaptcha(response) {
      this.captchaToken = response
    },
    executeRecaptcha() {
      this.$refs.recaptcha.execute()
    },
    async onSubmit () {
      const { data, error } = await this.$store.dispatch(SessionActions.Signup, this.user())
      if (!error) {
        /*this.$gtag.event('signUp', { event_category: this.origin })
        this.$gtm.trackEvent({
          event: 'GA4',
          category: "ux",
          action: "complete_register_clic",
          label: "complete_register",
          value: 5000,
        });*/
        this.$emit('submit', data)
      } else {
        if (data.data && data.data.field) {
          const field = this.$t(`models.user.${data.data.field}`)
          const error = this.$t(`remote_errors.${data.errorCode}`, { field, value: data.data.value })
          this.signupError = error
          this.$vuetify.goTo(0)
        }
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.executeRecaptcha()
    }, 1000)
    var elements = this.$t('countries_v2')
    for (var el of elements) {
      this.countries.push({
        image: 'https://flagcdn.com/32x24/' + el.value.toLowerCase() + '.png',
        value: el.prefix
      })
    }
  }
}
</script>
<style lang="scss">
  .v-select__selections {
    min-height: 56px !important;
  }

  div.v-input__append-inner {
    margin: auto !important;
  }
</style>
