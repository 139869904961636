<template>
  <section class="banner">
    <span v-if="influencer">
      <a v-if="!checkLanguage" href="/academy/1.html" target="_blank" class="no-decoration">
        <img src="/promotions/images/promotion_academy_star_eng.gif" width="100%" height="100%" />
      </a>
      <a v-else href="/academy/1.html" target="_blank" class="no-decoration">
        <img src="/promotions/images/promotion_academy_star_spa.gif" width="100%" height="100%" />
      </a>
    </span>
    <span v-else>
        <img v-if="!checkLanguage" src="/promotions/images/promotion_academy_fans_eng.gif" width="100%" height="100%" />
        <img v-else src="/promotions/images/promotion_academy_fans_spa.gif" width="100%" height="100%" />
    </span>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PromotionalBanner',
  props: {
    influencer: { type: Boolean, default: false }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    cols () {
      return this.isMobile ? 12 : 'auto'
    },
    checkLanguage () {
      return this.$i18n.locale === 'es'
    }
  }
}
</script>
<style lang="scss" >

.banner {
  background-color: #020101;
  width: 100%;
  margin: auto;
  .desc-banner {
    font-size: 20px;
    line-height: 24px;
    color: #d5d5d5;

    &.mobile {
      font-size: 20px !important;
      line-height: 20px !important;
    }
  }
  .banner-separator {
    align-self: stretch;
    background-color: #088a7c;
    width: 10px;
  }
}

</style>
