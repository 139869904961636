<template>
  <section>
    <WarningDialog
      :show="showWarning"
      :textWarning="text"
      :textButtonOK="textButtonOk"
      @handlerButtonCancel="close"
      @handlerButtonOK="setPreferences"
      :loading="loadingButton"
    />
    <div class="box-infochat" :class="ccsClass">
      <div v-if="isInfluencer" class="box-infochat-icon" :class="ccsClass" @click="userSetting">
        <icon-button type="user" />
        <p class="box-infochat-text" :class="ccsClass">
          {{ $t('application.chat_setting.user_setting') }}
        </p>
      </div>
      <div v-else class="box-infochat-icon" :class="ccsClass" @click="userSetting">
        <icon-button type="user" />
        <p class="box-infochat-text" :class="ccsClass">
          {{ $t('application.chat_setting.user_profile') }}
        </p>
      </div>
      <div
        v-if="isInfluencer || isAdmin || isAccountManager"
        class="box-infochat-icon"
        :class="ccsClass"
        @click="clearConversation"
      >
        <icon-button type="delete" />
        <p class="box-infochat-text" :class="ccsClass">
          {{ $t('application.chat_setting.delete') }}
        </p>
      </div>
      <div class="box-infochat-icon" :class="ccsClass" @click="silence">
        <icon-button type="mute" />
        <p class="box-infochat-text" :class="ccsClass">
          {{
            canSilence
              ? $t('application.chat_setting.mute')
              : $t('application.chat_setting.unmute')
          }}
        </p>
      </div>
      <div class="box-infochat-icon" :class="ccsClass" @click="block">
        <icon-button type="block" />
        <p class="box-infochat-text" :class="ccsClass">
          {{ canBlock ? $t('actions.block') : $t('actions.unblock') }}
        </p>
      </div>
      <div class="box-infochat-icon" :class="ccsClass" @click="translate">
        <icon-button type="translate" class="translate" />
        <p class="box-infochat-text" :class="ccsClass">
          {{
            withTranslate ? $t('actions.translate') : $t('actions.nottranslate')
          }}
        </p>
      </div>
    </div>
    <Dialog
      :show="showUserSetting"
      @hide="() => (showUserSetting = !showUserSetting)"
    >
      <template slot="bodyDialog">
          <UserSetting
            @close="() => (showUserSetting = !showUserSetting)"
          /> 
      </template>
    </Dialog>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import IconButton from '../component/IconButton'
import WarningDialog from '../component/WarningDialog'
import UserSetting from './UserSetting'
import Dialog from '../component/Dialog'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import { ChatActions } from '@/store'

export default {
  components: {
    IconButton,
    WarningDialog,
    UserSetting,
    Dialog
  },
  data () {
    return {
      showWarning: false,
      showUserSetting: false,
      text: '',
      textButtonOk: '',
      type: '',
      loadingButton: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isAdmin', 'isInfluencer', 'isAccountManager']),
    ...mapState('chats', ['selectedChat', 'isBlocked']),
    ...mapGetters('chats', ['getPreferences']),
     darkTheme(){
      return this.$vuetify.theme.dark
    },
    ccsClass () {
      return {
        dark: this.darkTheme
      }
    },
    receiverUsername () {
      return { user: this.selectedChat.receiver.username }
    },
    isCurrentUser () {
      return this.selectedChat.sender.username === this.currentUser.username
    },
    canBlock () {
      return this.selectedChat.sender.approved
    },
    withTranslate () {
      return !this.selectedChat.sender.translate
    },
    canSilence () {
      return !this.selectedChat.sender.archived
    }
  },
  methods: {
    close () {
      this.showWarning = !this.showWarning
    },
    userSetting () {
      if (this.isInfluencer) {
        this.showUserSetting = !this.showUserSetting
      } else {
        this.$router.push({
          name: 'influencer',
          params: { username: this.selectedChat.receiver.username }
        })
      }
    },
    noRead () {
      this.text = ''
    },
    clearConversation () {
      this.showWarning = true
      this.textButtonOk = this.$t('application.chat_setting.delete_btn')
      this.text = this.$t(
        'application.chat_setting.delete_text',
        this.receiverUsername
      )
      this.type = 'delete'
    },
    silence () {
      const action = this.canSilence
        ? this.$t('application.chat_setting.mute_btn')
        : this.$t('application.chat_setting.unmute_btn')
      this.createWarning(action)
      this.type = this.canSilence ? 'silence' : 'unsilence'
    },
    block () {
      const action = this.canBlock
        ? this.$t('actions.block')
        : this.$t('actions.unblock')
      this.createWarning(action)
      this.type = this.canBlock ? 'block' : 'unblock'
    },
    translate () {
      const action = this.withTranslate
        ? this.$t('actions.translate')
        : this.$t('actions.nottranslate')
      this.type = this.withTranslate ? 'translate' : 'nottranslate'
      this.createWarning(action)
    },
    createWarning (action) {
      const user = this.selectedChat.receiver.username
      this.text = this.$t('chats.want', { action: action, user: user })
      this.textButtonOk = action.toUpperCase()
      this.showWarning = true
    },
    async setPreferences () {
      this.loadingButton = true
      const id = this.selectedChat.id
      const preferences = Object.assign({},this.getPreferences)
      if (this.type === 'block') {
        preferences.approved = false
      }
      if (this.type === 'unblock') {
        preferences.approved = true
      }
      if (this.type === 'silence') {
        preferences.archived = true
      }
      if (this.type === 'delete') {
        this.deleteconversation()
        return
      }
      if (this.type === 'unsilence') {
        preferences.archived = false
      }
      if (this.type === 'nottranslate') {
        preferences.translate = false
      }
      if (this.type === 'translate') {
        preferences.translate = true
      }
      const { data, error } = await ConversationsApi.preferences(
        id,
        preferences
      )
      if (!error) {
        const selectedChat = Object.assign({}, this.selectedChat)
        selectedChat.sender.translate = preferences.translate
        selectedChat.approved = preferences.approved
        selectedChat.sender.archived = preferences.archived

        this.$store.dispatch(ChatActions.UpdateAttributes, selectedChat)
        this.$store.dispatch(ChatActions.RefreshConversation, {
          action: 'updated',
          value: true,
          idChat: this.selectedChat.id
        })
        this.$emit('reload-conversations')
        this.loadingButton = false
      }
      this.$store.dispatch(ChatActions.ShowSetting, false)
      this.$store.dispatch(ChatActions.ShowCarousel, false)
      this.close()
    },
    async deleteconversation () {
      const conversationsId = this.selectedChat.id
      const { data, error } = await ConversationsApi.deleteConversations(
        conversationsId
      )
      if (!error) {
        this.$store.dispatch(ChatActions.RefreshConversation, {
          action: 'delete',
          value: true,
          idChat: this.selectedChat.id
        })
        this.loadingButton = false
        this.$store.dispatch(ChatActions.SelectChat, null)
      }
      this.$store.dispatch(ChatActions.ShowSetting, false)
      this.$store.dispatch(ChatActions.ShowCarousel, false)
      this.close()
    }
  }
}
</script>
<style lang="scss">
.box-infochat {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  border-bottom: 1px solid #eaeaea;
  &.dark {
    background-color: #121b22;
    border-bottom: 1px solid #121b22;
  }
}

.box-infochat :hover {
  cursor: pointer;
  background-color: #d2d2d2;

  &.dark {
    color: #121b22;
  }
  &.dark:hover {
    background-color: #1c2a33;
  }
}

.box-infochat-icon {
  display: flex;
  flex-direction: row;
  padding: 4px 20px;

  &.dark {
    background-color: #121b22;
  }
  &.dark:hover {
    background-color: #1c2a33;
  }
}

.box-infochat-text {
  padding: 2px 8px;
}

.v-application p {
  margin-bottom: 0 !important;
}
.translate {
  filter: invert(39%) sepia(8%) saturate(6%) hue-rotate(18deg) brightness(96%)
    contrast(88%);
}
</style>
