<template>
  <component :size="size" :color="setColor" :is="selectedIcon"/>
</template>

<script>
import Comments from '@/components/icons/Comments.vue'
import Like from '@/components/icons/Like.vue'
import LikeFill from '@/components/icons/LikeFill.vue'
import Shared from '@/components/icons/Shared.vue'
import Emojis from '@/components/icons/Emojis.vue'
import Send from '@/components/icons/Send.vue'
import Down from '@/components/icons/Down.vue'
import { mapState } from 'vuex'
import VideoCall from '@/components/icons/VideoCall.vue'
import Message from '@/components/icons/Message.vue'
import Verified from '@/components/icons/Verified.vue'
import Back from '@/components/icons/Back.vue'
import Dots from '@/components/icons/Dots.vue'

export default {
  name: 'c-icon',
  components: {
    Comments,
    Like,
    LikeFill,
    Shared,
    Emojis,
    Send,
    Down,
    VideoCall,
    Message,
    Verified,
    Back,
    Dots
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String
    },
    changeDark: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    selectedIcon () {
      return this.icon
      /* const iconComponent = Vue.component(`${this.icon}`, {
        template: '<div v-html="ulrIcon"/>',
        data () {
          return {
            ulrIcon: require(`/images/icons/${this.icon}.svg`)
          }
        }
      })
      return iconComponent */
    },
    setColor () {
      if (this.changeDark) {
        return this.darkTheme ? 'white' : '#00000066'
      }
      return this.color
    }
  },
  data () {
    return {
      comments: ''
    }
  }
}
</script>

<style lang="scss" scoped>

/*.img {
  width: 100%;
  height: 100%;
}

.filterDark {
  filter: invert(100%) sepia(0%) saturate(7469%) hue-rotate(32deg) brightness(109%) contrast(100%) !important;
}

.filterGray {
  filter: invert(95%) sepia(0%) saturate(2%) hue-rotate(168deg) brightness(85%) contrast(91%);
  !*filter: grayscale(100%) !important;*!
}*/
</style>
