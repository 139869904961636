<template>
  <iframe
    v-bind="$attrs"
    :src="resource.url"
    style="border: none;"
    loading="lazy"
    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
    allowfullscreen="true"
  />
</template>

<script>

export default {
  name: 'BunnyVideo',
  props: {
    resource: Object,
    height: Number,
    width: Number,
    contain: Boolean,
  }
}
</script>
