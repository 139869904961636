<template>
  <v-form v-model="validForm" ref="form"
    @submit.prevent="submit">
    <v-card-text>
      <h1>{{ $t('settings.twitter.signup')}}</h1>
      <p class="my-8">{{ $t("settings.twitter.description") }}</p>
      <v-alert type="error"
        v-if="saveError"
        icon="warning"
        dense outlined>
        {{ saveError }}
      </v-alert>

      <UsernameInput outlined autofocus
        class="mb-4"
        v-model="username"
        :rules="[$vRequired]" />

      <v-text-field outlined class="mb-4"
        persistent-hint
        :label="$t('models.user.displayName')"
        type="text"
        v-model="displayName"
        :rules="[$vBannedWords(displayName),$vMaxLength(50)]"
        :hint="$t('models.user.displayName_hint')"></v-text-field>

      <v-text-field outlined
        persistent-hint
        class="mb-4"
        :label="$t('models.user.email')"
        prepend-inner-icon="email"
        type="text"
        :rules="[$vRequired,$vEmail]"
        v-model="email">
      </v-text-field>
      <p>{{ $t('settings.account.change_password') }}</p>
      <PasswordInput outlined
        class="mb-4"
        ref="password"
        v-model="password" />
      <PasswordInput outlined
        :label="$t('models.user.password_confirmation')"
        v-model="passwordConfirmation"
        :override-rules="[$vConfirmation('password')]" />
      <v-checkbox outlined
        color="primary"
        :label="$t('models.user.isAdult')"
        v-model="isAdult"
        :rules="[$vBoolRequired]"></v-checkbox>
      <v-checkbox outlined
        color="primary"
        v-model="acceptedTerms"
        :rules="[$vBoolRequired]">
        <template slot="label">
          {{$t('models.user.accept')}}&nbsp;
          <a @click.stop :href="goToConditions" target="_blank"> {{ $t('application.menu.terms_conditions') }}</a>
        </template>
      </v-checkbox>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <c-btn :disabled="!validForm"
        @click.stop.prevent="submit">
        {{ $t('actions.finish') }}
      </c-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import { SessionActions } from '@/store'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'UserTwitterForm',
  mixins: [validatable('form')],
  data () {
    return {
      saveError: false,
      displayName: null,
      username: null,
      email: null,
      password: '',
      passwordConfirmation: '',
      acceptedTerms: false,
      isAdult: false
    }
  },

  computed: {
    ...mapState('profile', ['currentUser']),
    goToConditions () {
      return `https://${this.$t('site')}/conditions`
    }
  },

  methods: {

    attrs () {
      const attrs = {
        displayName: (this.displayName || '').trim(),
        username: this.username.trim(),
        email: (this.email || '').trim(),
        acceptedTerms: this.acceptedTerms,
        isAdult: this.isAdult
      }

      if (this.password) {
        attrs.password = this.password
      }

      return attrs
    },

    async onSubmit () {
      this.saveError = false
      const { data, error } = await this.$store.dispatch(SessionActions.FinishSignup, this.attrs())
      if (!error) {
        this.$eventBus.publish('notification', {
          message: this.$t('snackbar.save_success')
        })

      } else {
        if (data.data && data.data.field) {
          const field = this.$t(`models.user.${data.data.field}`)
          const error = this.$t(`remote_errors.${data.errorCode}`, { field, value: data.data.value })
          this.saveError = error
          this.$vuetify.goTo(0)
        }
      }
    }
  },
  created () {
    const profile = this.currentUser
    this.displayName = profile.displayName
    this.username = profile.username
    this.email = profile.email
    this.emailValidated = profile.emailValidated
  }
}
</script>
