<template>
  <v-card flat>
    <v-card-title>{{ $t(`application.maintance.warning_1`) }}</v-card-title>
    <v-card-text>{{ $t(`application.maintance.warning_2`) }}</v-card-text>
    <v-card-text>{{ $t(`application.maintance.warning_3`) }}</v-card-text>
    <div class="text-center mt-4">
      <img :src="`/images/${brandingLogo}.svg?dfk23`"
          height="30" />
    </div>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Maintance',
  computed: {
    ...mapState('preferences', ['darkTheme']),
    brandingLogo () {
      if (!this.darkTheme) {
        return this.isMobile ? 'logo' : 'logo_horizontal'
      } else {
        return this.isMobile ? 'logo_white' : 'logo_horizontal_white'
      }
    }
  }
}
</script>
