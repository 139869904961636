<template>
  <c-dialog
    width="684"
    v-model="value">
    <template slot="title">
      <v-icon color="error">highlight_off</v-icon>
      <span class="title-text-2 semibold">{{ $t(`payments.failed`)}}</span>
    </template>

    <v-card-text>
      <p class="pl-5">
        <v-icon color="error">report</v-icon>
        {{ $t(`payments.errors.695`) }}
      </p>
      <p>{{ $t(`payments.${resource}.failed_message`) }}</p>
    </v-card-text>

  </c-dialog>
</template>
<script>

export default {
  name: 'FailedPaymentDialog',
  props: {
    value: Boolean,
    resource: String,
    resourceId: String
  },
  data () {
    return {
      error: null

    }
  }
}
</script>
