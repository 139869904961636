<template>
  <section>
    <c-btn
      @click="$router.back()">
      {{ $t('actions.back') }}
    </c-btn>
    <h1>DMCA NOTICE & TAKEDOWN POLICY AND PROCEDURES</h1>
    <br>
    <p>This Digital Millennium Copyright Act policy (“Policy”) applies to the {{ project.locales.site }} website (“Website” or “Service”) and any of its related products and services (collectively, “Services”) and outlines how <img :src="getArtemisaSrc" style="width: 190px;height: 12px;margin-right: 4px;"/>(doing business as “{{ project.project.toUpperCase() }}”, “we”, “us” or “our”). addresses copyright infringement notifications and how you (“you” or “your”) may submit a copyright infringement complaint.</p>
    <br>
    <p>Protection of intellectual property is of utmost importance to us and we ask our users and their authorized agents to do the same. It is our policy to expeditiously respond to clear notifications of alleged copyright infringement that comply with the United States Digital Millennium Copyright Act (“DMCA”) of 1998, the text of which can be found at the U.S. Copyright Office website.</p>
    <br>
    <p>If you believe that your copywriten work has been copied in a way that constitutes copyright infringement, please provide {{ project.project.toUpperCase()}} copyright agent the following information:</p>
    <br>
    <ol>
      <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest.</li>
      <li>Description of the copyrighted work or other intellectual property that you claim has been infringed.</li>
      <li>A description of where the material that you claim is infringing is located on the SITE (preferably including specific url's associated with the material).</li>
      <li>Your address, telephone number, and email address.</li>
      <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law. and,</li>
      <li>A statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.</li>
    </ol>
    <br>
    <p>Please, send your Notice of Claimed Infringement either to e-mail DMCA@{{ project.locales.site }}</p>
    <br>
    <h1>Copyright Contact Information :</h1>
    <br>
    <p>DMCA Complaints<br>
    <img :src="getArtemisaSrc" style="margin-top: 4px;width: 220px;height: 15px;margin-right: 5px;" v-if="project.showFooterImages"/>
    <span v-else> Artemisa 3000 Tech Solutions SL </span><br>
    <img :src="getCIFSrc" style="" class="img-cif" v-if="project.showFooterImages"/>
    <span v-else> B88466743 </span><br>
    CALLE GOYA, NUM 47 PLANTA 7, PUERTA 12 28001<br>
    Madrid, España<br>
    DMCA@{{project.locales.site}}<br>
    </p>
  </section>
</template>

<script>
import project from '@/project'

export default {
  name: 'DMCA',
  data () {
    return {
      project
    }
  },
  computed: {
    getArtemisaSrc () {
      return `images/artemisa_${this.$vuetify.theme.dark ? 'white' : 'black'}.png`
    },
    getCIFSrc () {
      return `/images/cif_${this.$vuetify.theme.dark ? 'white' : 'black'}.png`
    }
  }
}
</script>

<style scoped>

</style>
