
<script>
const sizerStyle = (ratio) => {
  ratio = parseFloat(ratio)
  const height = 100 / ratio
  return `padding-bottom: ${height}%`
}

export default {
  name: 'Box',
  functional: true,
  props: {
    ratio: { type: [String, Number], default: 1 }
  },

  render (createElement, context) {
    const style = sizerStyle(context.props.ratio)
    const resizer = createElement('div', { style })
    const slots = context.slots().default
    const slot = slots[0]
    slot.data.staticClass = ['box-content', slot.data.staticClass || ''].join(' ')
    slots.unshift(resizer)
    context.data.class = `box ${context.data.class || ''}`
    return createElement('div', context.data, slots)
  }
}
</script>
<style lang="scss" scoped>
.box {
  position: relative;
 ::v-deep .box-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
