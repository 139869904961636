<template>
  <v-text-field
    v-bind="$attrs"
    :label="inputLabel"
    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
    :type="showPassword ? 'text' : 'password'"
    :value="value"
    :rules="allRules"
    :hide-details="hideDetails"
    @input="emitInput"
    :dark="dark"
    :class="classCss"
    @click:append="showPassword = !showPassword"></v-text-field>
</template>
<script>

export default {
  name: 'PasswordInput',
  props: {
    value: [String, Number],
    rules: {
      type: Array,
      default: () => []
    },
    overrideRules: {
      type: Array,
      default: () => null
    },
    label: String,
    dark: {
      type: Boolean,
      default: false
    },
    bgWhite: Boolean,
    hideDetails: String
  },
  data () {
    return {
      showPassword: false
    }
  },
  computed: {
    allRules () {
      return this.overrideRules || [...this.rules, this.$vPassword]
    },
    inputLabel () {
      return this.label || this.$t('models.user.password')
    },
    classCss () {
      return {
        'bg-white': this.bgWhite
      }
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}

</script>

<style scoped>
.bg-white >>> .v-input__slot {
  background-color: white !important;
}

</style>
