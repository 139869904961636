<template>
  <section>
    <div class="form-input-text">
      <p>{{ $t('application.chat_setting.albums.row.name') }}</p>
      <input
        type="text"
        placeholder=""
        class="input-text"
        v-model="nameAlbum"
        :class="darkTheme"
      />
    </div>
    <div class="col-12" v-if="editFile.length > 0">
      <ViewMultipleFile :mediaIds="editFile" v-on:delete-id="deleteAttach" />
    </div>
    <div class="col-12">
      <button class="btn-att" @click="onShowAttach">
        {{ $t('chats.attachment.title') }}
      </button>
    </div>
    <Dialog
      :show="showAttach"
     @hide="onShowAttach"
      :showBotton="true"
      :modeMobile="isMobile"
      :title="$t('chats.attachment.title').toUpperCase()"
      @acept-dialog="aceptAtt"
    >
      <template slot="bodyDialog">
        <div>
          <MessageAttachmentsForm
            :noClose="true"
            :noPrice="true"
            :noAlbums="true"
            @reset="reset"
            :defaultSelectedTab="1"
            :files="[]"
          />
        </div>
      </template>
    </Dialog>
    <!-- <Attachments @up-media-file="upMediaFile" :reset="reset" /> -->
    <div :class="classButonsEdit">
      <Buttons
        :text="$t('actions.save')"
        :loading="loadingButton"
        :disabled="isDidabled"
        @handler="createAlbum"
      />
      <Buttons
        v-if="editAlbum"
        :text="$t('actions.cancel')"
        close
        @handler="cancelEditAlbum"
      />
    </div>
  </section>
</template>

<script>
import Attachments from '@/components/chat/component/Attachments'
import Buttons from '@/components/chat/component/Buttons'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import ViewMultipleFile from '@/components/chat/components/viewFiles/ViewMultipleFile'
import MessageAttachmentsForm from '@/components/chat/components/newMessage/MessageAttachmentsForm'
import Dialog from '@/components/chat/component/Dialog'
import { AppActions } from '@/store'
import { mapState } from 'vuex'

export default {
  name: 'CreateAlbums',
  components: {
    Attachments,
    Buttons,
    ViewMultipleFile,
    MessageAttachmentsForm,
    Dialog
  },
  props: {
    isEdit: { type: Boolean, default: false },
    editAlbum: { type: Object }
  },
  data () {
    return {
      nameAlbum: '',
      loadingButton: false,
      filesLocal: [],
      editFile: [],
      showAttach: false
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('chats', ['viewAttachmentForm']),
    isDidabled () {
      return !(
        this.nameAlbum.length > 0 &&
        (this.filesLocal.length > 0 || this.editFile.length > 0)
      )
    },
    darkTheme(){
      return {dark: this.$vuetify.theme.dark}
    },
    reset () {
      return this.filesLocal.length === 0
    },
    classButonsEdit () {
      return {
        'footer-botton': this.editAlbum
      }
    }
  },
  mounted () {
    if (this.isEdit) {
      this.nameAlbum = this.editAlbum.name
      this.editFile = this.editAlbum.mediaIds
    }
  },
  methods: {
    onShowAttach () {
      this.showAttach = !this.showAttach
    },
    async createAlbum () {
      this.loadingButton = true
      const body = {
        name: this.nameAlbum,
        mediaIds: []
      }
      if (this.isEdit) {
        this.updateAlbum(body)
      }
      if (!this.isEdit) {
        body.mediaIds = this.editFile.map(f => f.id)
        const { data, error } = await ConversationsApi.createAlbum(body)
        if (!error) {
          this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
          this.resetForm()
        } else {
          this.$store.dispatch(AppActions.SetNotification, {
            type: 'error',
            error: data
          })
        }
      }
      this.$store.dispatch(AppActions.ShowNotification, true)
      this.loadingButton = false
    },
    async updateAlbum (body) {
      this.editFile.forEach(f => {
        body.mediaIds.push(f.id)
      })
      const { data, error } = await ConversationsApi.updateAlbum(
        this.editAlbum.id,
        body
      )
      if (!error) {
        this.resetForm()
        this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
      } else {
        this.$store.dispatch(AppActions.SetNotification, {
          type: 'error',
          error: data
        })
      }
    },
    cancelEditAlbum () {
      this.$emit('cancel-edit-album')
    },
    resetForm () {
      this.nameAlbum = ''
      this.filesLocal = []
      this.editFile = []
      this.$emit('removeDefaultContent')
    },
    deleteAttach (id) {
      const index = this.editFile.findIndex(f => f.id == id)
      if (index !== -1) {
        this.editFile.splice(index, 1)
      }
    },
    aceptAtt () {
      const att = this.viewAttachmentForm.slice()
      att.map(f => {
        f.attachmentType = f.type
        delete f.path
        f.path = f.url
        this.editFile.push(f)
      })
      this.filesLocal = this.viewAttachmentForm
    }
  }
}
</script>
<style lang="scss" scoped>
.form-input-text {
  display: flex;
  align-content: flex-start;
  justify-content: center;
  padding: 10px 0;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 7px;
  p {
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
  .input-text {
    width: 96%;
    display: inline-block;
    border: 1px solid #d7d7d7;
    height: 30px;
    padding: 7px 15px;
    margin: 4px;
    font-size: 0.9375em;
    border-radius: 30px;
    background: white;
    &.dark{
      background-color: #272727 !important;
    }
  }
}
.footer-botton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-att {
  text-decoration: underline;
}
</style>
