<template>
  <div class="container">
    <c-btn
      @click="back()">
      {{ $t('actions.back') }}
    </c-btn>
    <br>
    <br>
    <div ref="content" class="my-5 mx-2 ">
      <component :is="getComponentLang" class="mt-5 ">
      </component>
    </div>
    <br>
    <br>
    <c-btn @click="back()">
      {{ $t('actions.back') }}
    </c-btn>
  </div>
</template>

<script>
import ES from '@/components/contract/locales/ES.vue'
import EN from '@/components/contract/locales/EN.vue'
import cookies from '@/cookies'
import { mapState } from 'vuex'

export default {
  name: 'ContractConditions',
  components: {
    ES,
    EN
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser']),
    getComponentLang () {
      return this.lang === 'es' ? ES : EN
    },
    lang () {
      return cookies.get('locale')
    }
  },
  methods: {
    back () {
      this.$router.back() || this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>

.textarea-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding: 50px;
}

.custom-textarea textarea {
  padding: 20px 0 !important;
  font-family: 'Sacramento', cursive;
  font-size: 30px;
  text-align: center;
}
</style>
