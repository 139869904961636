<template>
  <c-dialog
    v-bind="$attrs"
    :persistent="persistent"
    width="684"
    :value="dialog"
    :actions="false"
    :closable="!persistent"
    @input="hideDialog">
    <template slot="title">
      <span class="title-text-2 semibold">{{ $t(`tags.title`)}}</span>
    </template>

    <TagProfile @submit="hideDialog" />
  </c-dialog>
</template>
<script>
import TagProfile from '@/components/tags/TagProfile'

export default {
  name: 'TagProfileDialog',
  components: { TagProfile },
  props: {
    dialog: { type: Boolean, default: true },
    persistent: Boolean
  },
  methods: {
    hideDialog () {
      this.$emit('update:dialog', false)
    }
  }
}
</script>
