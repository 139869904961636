<template>
  <v-row>
    <v-col>
      <div class="user-info">
        <div class="d-flex">
          <UserAvatar
            vertical
            x-large
            profile
            :user="user"
            @click.native="enableCarousel"
          >
          </UserAvatar>
        </div>

        <v-col cols="12">
          <v-row align="center">
            <div slot="name" class="title-text-2 bold white--text mx-2 center">
              {{ user.displayName }}
            </div>
            <img
              v-if="user.trustedProfile"
              src="/images/check5.svg"
              height="16"
            />
            <div slot="username" class="regular-text white-light-text mx-2">
              {{ `@${user.username}` }}
            </div>
          </v-row>
          <v-row align="center">
            <v-menu offset-y bottom>
              <template v-slot:activator="{ on, attrs }">
                <c-btn
                  icon
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  class="btnShared"
                >
                  <img
                    height="40"
                    class="ml-auto"
                    :src="
                      `${
                        showAffilateLink
                          ? '/images/icon_shared_profile_cash.svg'
                          : '/images/icon_shared_profile.svg'
                      } `
                    "
                  />
                  <div class="tooltip">
                    <span>{{ $t("share.profile") }}</span>
                  </div>
                  <!-- v-on:mouseleave="hover = false" -->
                </c-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, index) in itemsShared" :key="index">
                  <v-list-item @click="item.click">
                    <span class="menu-text">{{ item.title }}</span>
                    <img height="40" class="ml-auto" :src="item.iconSrc" />
                  </v-list-item>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-if="(!isCurrentUser && authenticated) || !edittingMode"
              icon
              :href="chatHrefIcon"
              :to="chatRoute"
              :target="redirect ? '_blank' : ''"
            >
              <v-icon color="white">mail_outline</v-icon>
            </v-btn>
            <!-- <v-btn icon @click="copyLink()">
              <v-icon v-if="showAffilateLink" fill="white" fill-second="success"
                >$vuetify.icons.affiliate_link</v-icon
              >
              <v-icon v-else color="white">link</v-icon>
            </v-btn> -->
            <v-btn v-if="showBlock" icon @click="swithcBlocked">
              <v-icon :fill="blockUserColor">$vuetify.icons.block_user</v-icon>
              <BlockUserWarningDialog v-model="showBlockDialog" :user="user" />
            </v-btn>
            <v-menu
              bottom
              offset-y
              v-if="(!isCurrentUser && authenticated) || !edittingMode"
            >
              <template v-slot:activator="{ on, attrs }">
                <c-btn
                  icon
                  color="white"
                  class="my-auto"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </c-btn>
              </template>
              <v-list dense>
                <v-list-item @click="chatHref">
                  <span class="menu-text">
                    {{ $t("users.profile.info.message") }}</span
                  >
                </v-list-item>
                <v-list-item @click="toggleFollow" v-if="notMasterUser">
                  <span class="menu-text">{{ followingText }} </span>
                </v-list-item>
                <v-list-item @click="copyLink">
                  <span class="menu-text">{{
                    $t("users.profile.info.copy")
                  }}</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>

        <!--        <v-row style="margin-top: 5px;" v-if="showScatLink">-->
        <!--          <img height="22" src="/images/scatbook/logo.svg"/>-->
        <!--          <a :href="`https://scatbook.com/${user.username}`" style="text-align: center; vertical-align: super; color: #F2F2F2" target="_blank" class="no-decoration">-->
        <!--            {{ $t('users.profile.scatbook') }}-->
        <!--          </a>-->
        <!--        </v-row>-->

        <div class="row star-info" v-if="showInfo">
          <div class="py-2 px-2" v-if="isCurrentUser">
            {{ followingsNumber }}
          </div>
          <div class="py-2 px-2">
            {{ followersNumber }}
          </div>
          <v-chip color="translucient" class="py-2 px-2">
            {{ subscribersNumber }}
          </v-chip>
        </div>
        <v-col v-if="isMobile" cols="12" sm="" class="user-description">
          <div>
            <v-tooltip v-model="show" top>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  class="truncate two-lines"
                  @click="show = !show"
                >
                  {{ user.description }}
                </span>
              </template>
              <span>{{ user.description }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </div>
    </v-col>
    <v-col v-if="!isMobile" cols="12" sm="" class="user-description">
      <div class="truncate five-lines">
        {{ user.description }}
      </div>
    </v-col>
    <Carousel v-model="showCarousel" :resources="resources"></Carousel>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { ProfileActions, UserActions } from '@/store'
import UserAvatar from '@/components/users/UserAvatar'
import BlockUserWarningDialog from '@/components/users/blocking/BlockUserWarningDialog'
import config from '@/project'

export default {
  name: 'CoverPortrait',
  components: { UserAvatar, BlockUserWarningDialog },
  props: {
    user: Object,
    edittingMode: Boolean
  },
  data () {
    return {
      show: false,
      showCarousel: false,
      enabledScatModal: config.enable_scat_modal,
      showBlockDialog: false,
      itemsShared: [
        {
          title: this.$t('users.profile.share.share_twitter'),
          iconSrc: '/images/icon_shared_twitter.svg',
          click: this.twitterUrl.bind(this)
        },
        {
          title: this.$t('users.profile.share.share_facebook'),
          iconSrc: '/images/icon_shared_facebook.svg',
          click: this.facebookUrl.bind(this)
        },
        {
          title: this.$t('users.profile.share.share_instagram'),
          iconSrc: '/images/icon_shared_instagram.svg',
          click: this.instagramUrl.bind(this)
        },
        {
          title: this.$t('users.profile.share.share_whatsapp'),
          iconSrc: '/images/icon_shared_whatsapp.svg',
          click: this.whatsappUrl.bind(this)
        },
        {
          title: this.$t('users.profile.share.share_gmail'),
          iconSrc: '/images/icon_shared_gmail.svg',
          click: this.gmailUrl.bind(this)
        },
        {
          title: this.$t('users.profile.share.copy_link'),
          iconSrc: '/images/icon_shared_copiar_perfil.svg',
          click: this.copyLink.bind(this)
        }
      ]
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer']),
    ...mapState('application', ['isMobile']),
    ...mapState('session', ['authenticated']),
    redirect () {
      return config.payments.darkfans_redirect && !this.isInfluencer
    },
    notMasterUser () {
      return this.user.username ? this.user.username.toLowerCase() !== config.project.toLowerCase() : false
    },
    chatHrefIcon () {
      if (!this.redirect) return undefined
      return `https://darkfans.com/messages?oa=${this.currentUser.sessionToken}&username=${this.user.username}&utm_source=${config.project}`
    },
    chatRoute () {
      if (this.redirect) return undefined
      return { name: 'messages', query: { username: this.user.username } }
    },
    showInfo () {
      if (this.isCurrentUser) {
        return true
      } else if (this.user.showStats) {
        return (
          this.authenticated &&
          this.user.followersCount > 99 &&
          this.user.subscribersCount > 19
        )
      }
      return false
    },
    showBlock () {
      return !this.isCurrentUser && this.isInfluencer && this.user.role !== 'admin' && this.user.role !== 'controller'
    },
    isCurrentUser () {
      return (
        this.authenticated && this.user.username === this.currentUser.username
      )
    },

    subscribersNumber () {
      const count = this.user.subscribersCount || 0
      return this.$tc('user_profile.subscribers_count', count, { count })
    },

    followersNumber () {
      const count = this.user.followersCount || 0
      return this.$tc('user_profile.followers_count', count, { count })
    },

    postNumber () {
      const count = this.user.postCount || 0
      return this.$tc('user_profile.post_count', count, { count })
    },

    premiumNumber () {
      const count = this.user.premiumCount || 0
      return this.$tc('user_profile.premium_count', count, { count })
    },

    followingsNumber () {
      const count = this.user.followingCount || 0
      return this.$tc('user_profile.followings_count', count, { count })
    },

    resources () {
      return [
        { url: this.user.avatarS3Route },
        { url: this.user.bannerS3Route }
      ]
    },

    showScatLink () {
      return (
        this.enabledScatModal &&
        this.user.scatProfile &&
        this.user.profileMigrated &&
        (this.isCurrentUser || this.user.following || this.user.subscribed)
      )
    },

    blockUserColor () {
      return this.user.blockListed ? 'primary' : 'white'
    },

    showAffilateLink () {
      return (
        !this.isCurrentUser &&
        this.isInfluencer &&
        this.currentUser.affiliatedProfile &&
        this.user.affiliationEnabled
      )
    },

    profileLink () {
      let link = `https://${config.project}.com/${this.user.username}`
      if (this.showAffilateLink) { link = `${link}?cr=${this.currentUser.username}&utm_source=${config.project}` }
      return link
    },
    textSend () {
      return this.$t('users.profile.share.text_send', {
        url: this.profileLink,
        plaform: config.locales.project_upper
      })
    },
    followingText () {
      return this.user.following
        ? this.$t('actions.unfollow')
        : this.$t('actions.follow')
    }
  },
  methods: {
    enableCarousel () {
      this.showCarousel = true
    },
    copyLink () {
      this.showCarousel = false
      const copyText = document.createElement('textarea')
      copyText.value = this.profileLink
      copyText.setAttribute('readonly', '')
      copyText.style.position = 'absolute'
      copyText.style.left = '-9999px'
      document.body.appendChild(copyText)
      copyText.select()
      document.execCommand('copy')
      document.body.removeChild(copyText)
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.clipboard_copied')
      })
    },
    twitterUrl () {
      window.open(`https://twitter.com/intent/tweet?text=${this.textSend}`)
    },
    whatsappUrl () {
      window.open(`https://api.whatsapp.com/send?text=${this.textSend}`)
    },
    copyLinkMsg () {
      const copyText = document.createElement('textarea')
      copyText.value = this.textSend
      copyText.setAttribute('readonly', '')
      copyText.style.position = 'absolute'
      copyText.style.left = '-9999px'
      document.body.appendChild(copyText)
      copyText.select()
      document.execCommand('copy')
      document.body.removeChild(copyText)
    },
    facebookUrl () {
      this.copyLinkMsg()
      window.open('https://www.facebook.com')
    },
    instagramUrl () {
      this.copyLinkMsg()
      window.open('https://www.instagram.com')
    },
    async swithcBlocked () {
      if (this.user.blockListed) {
        await this.$store.dispatch(ProfileActions.UnblockUser, this.user)
      } else {
        this.showBlockDialog = true
      }
    },
    gmailUrl () {
      window.location.href = `mailto:?subject=${this.textSend}&body=${this.textSend}`
    },
    chatHref () {
      if (this.redirect) {
        window.open(
          `https://darkfans.com/messages?oa=${this.currentUser.sessionToken}&username=${this.user.username}&utm_source=${config.project}`
        )
      } else {
        this.$router.push({
          name: 'messages',
          query: { username: this.user.username }
        })
      }
    },
    toggleFollow () {
      if (!this.authenticated) {
        sessionStorage.setItem('origin', this.$router.currentRoute.fullPath)
        sessionStorage.setItem('autoFollow', this.user.username)
        this.$router.push('/login')
      }
      return this.$store.dispatch(UserActions.ToggleFollow, this.user.username)
    }
  }
}
</script>
<style lang="scss" scoped>
.user-description {
  align-self: flex-end;
}
.row .star-info {
  flex-wrap: nowrap;
  white-space: nowrap;
}

.scatProfile {
  width: 185px;
  height: 21px;
  margin: 0.5px 0 0.5px 9px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
}

.menu-text:hover {
  color: var(--v-primary-base);
}
.btnShared {
  .tooltip {
    display: none;
    position: absolute;
    span {
      background: #5f6264;
      text-transform: capitalize;
      padding: 8px;
      border-radius: 10px;
      position: relative;
      top: 32px;
    }
  }
}

.btnShared:hover {
  .tooltip {
    display: flex;
  }
}
</style>
