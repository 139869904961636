<template>
  <v-card flat
    :href="darkfansLink"
    :to="resourceRoute"
    :target="mustRedirect ? '_blank' : ''"
    class="product-card">
    <Box class="product-content"
      :ratio="ratio"
      @mouseover="hover = true"
      @mouseleave="hover = false">
      <Resource rounded no-resize
        :resource="product.preview">
        <template v-if="!extended">
          <div class="top-left text-center">
            <v-chip><currency :price="product.price"></currency></v-chip>
            <div v-if="isAuction"
              class="white-text">
              ({{ $tc('products.auction.bid_count', product.bidsCount, {count: product.bidsCount}) }})
            </div>
          </div>
          <div>
            <v-tooltip v-model="show" top>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="product-title truncate two-lines bottom-left white--text title-text" @click="show = !show">
                  {{ product.name }}
                </span>
              </template>
              <span>{{ product.name }}</span>
            </v-tooltip>
          </div>
        </template>
        <component v-if="hover"
          :is="overlayComponent"
          :product="product">
        </component>
      </Resource>
    </Box>
    <ProductCardExtension v-if="extended"
      class="mt-2"
      v-bind="$props" />
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import BottomBarOverlay from '@/components/products/BottomBarOverlay'
import CardOverlay from '@/components/products/CardOverlay'
import ProductCardExtension from '@/components/products/ProductCardExtension'
import Resource from '@/components/posts/Resource'
import config from '@/project'

export default {
  name: 'ProductCard',
  components: { ProductCardExtension, CardOverlay, BottomBarOverlay, Resource },
  props: {
    ratio: { type: [String, Number], default: 1 },
    extended: Boolean,
    product: Object
  },
  data () {
    return {
      show: false,
      hover: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    mustRedirect () {
      return config.payments.darkfans_redirect && this.product.acquired && this.currentUser.username !== this.product.username
    },
    darkfansLink () {
      if (this.mustRedirect) {
        return `https://darkfans.com/products/${this.product.id}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
      }
      return undefined
    },
    resourceRoute () {
      if (this.mustRedirect) {
        return undefined
      }
      return { name: 'product', params: { uuid: this.product.id}}
    },

    sizerStyle () {
      const ratio = parseFloat(this.ratio)
      const height = 100 / ratio
      return { 'padding-bottom': `${height}%` }
    },
    overlayComponent () {
      return this.extended ? 'BottomBarOverlay' : 'CardOverlay'
    },
    isAuction () {
      return this.product.productType === 'auction'
    }
  }
}
</script>
<style lang="scss">
.product-card {
  .v-responsive__content {
    width: 100% !important;
    max-width: 100% !important;
  }
  .product-title {
    font-size: 16px;
  }
  .product-content {
    position: relative
  }
  .product-responsive {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .top-left {
    position: absolute;
    top: 8px;
    left: 10px;
  }
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 10px;
    right: 10px;
  }

  .product-card-actions {
    position: absolute;
    bottom: 0;
  }
}
</style>
