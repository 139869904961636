const SymbolMask = (attrs) => {
  const symbol = attrs.symbol || '€'
  const symbolLeft = attrs.symbolLeft || false
  const space = attrs.symbolSpace === false ? '' : ' '
  return symbolLeft ? `${symbol}${space}%{amount}` : `%{amount}${space}${symbol}`
}

const EurConfig = {
  symbol: '€', symbolLeft: false, decimals: 2, decimalDelimiter: ',', thousandsDelimiter: '.',
  factor: 100,
}
const UsdConfig = {
  symbol: '$', symbolLeft: true, decimals: 2, decimalDelimiter: '.', thousandsDelimiter: ',',
  factor: 100,
}

class Currency {
  constructor (attrs = EurConfig) {
    this.config = attrs || EurConfig
    this.template = SymbolMask(this.config)
  }

  setCurrency(curr = 'EUR') {
    if (curr === 'USD') {
      this.config = UsdConfig
    } else {
      this.config = EurConfig
    }
    this.template = SymbolMask(this.config)
  }

  formatAmount (value, cents = true) {
    let amount = parseFloat(value || 0, 10)
    amount = cents ? amount / this.config.factor : amount
    return amount.toFixed(this.config.decimals)
  }

  mask (amount) {
    const thousandsFormat = `$1${this.config.thousandsDelimiter}$2$3`
    let currencyAmount = amount.replace('.', this.config.decimalDelimiter)
    while (true) {
      const valueSep = currencyAmount.replace(/(\d)(\d{3})($|,|\.)/g, thousandsFormat)

      if (currencyAmount === valueSep) {
        break
      }

      currencyAmount = valueSep
    }

    return this.template.replace('%{amount}', currencyAmount)
  }

  format (val, cents = true) {
    const value = parseInt(val || 0, 10)
    if (value && isNaN(value)) {
      console.warn(`Formatting not integer amount: ${val}`)
    }
    const amount = this.formatAmount(value, cents)
    return this.mask(amount)
  }

  // FIXME
  // Returns array in order
  // $1,123,231.23 -> ["$", "+", "1,123,231", ".",  "23"]
  // And with keyed items: { type: "symbol", value: "$"}
  formatParts (value, cents = true) {
    const formatted = this.format(value, cents)
    const sign = value < 0 ? '-' : '+'
    let [int, decimal] = formatted.replace('+', '').replace('-', '').split(this.decimalDelimiter)
    int = int.replace(this.thousandsDelimiter, '')
    return { sign, int, decimal }
  }
}

export const currency = new Currency(EurConfig)

export default {
  install (Vue) {
    Vue.prototype.$currencyFormatter = currency
    Vue.prototype.$currency = (value, curr) => {
      if (curr) {
        const formatter = curr === 'EUR' ? new Currency(EurConfig) : new Currency(UsdConfig)
        return formatter.format(value)
      }
      return currency.format(value)
    }
  }
}
