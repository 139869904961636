<template>
  <section>
<!--    <div class="center" v-if="!isMobile">
      <img src="/images/logo_horizontal_white_color.png" height="32" width="211" alt="loverfans_logo" class="mr-4"/>
      <span class="cross">X</span>
      <img src="./resources/commupdate.png" height="67" width="196" alt="commupdate_logo"/>
    </div>
    <span v-else>
      <div class="center">
        <img src="/images/logo_horizontal_white_color.png" height="32" width="211" alt="loverfans_logo" class="mr-4"/>
      </div>
      <div class="center">
        <span class="cross">X</span>
      </div>
      <div class="center">
        <img src="./resources/commupdate.png" height="67" width="196" alt="commupdate_logo"/>
      </div>
    </span>

    <div class="center" :class="`my-${isMobile ? 8 : 5}`">
      <img src="./resources/bsummit-separator.png" height="16" width="160" alt="bsummit_separator"/>
    </div>-->

<!--    <div class="col center mt-3" v-if="!isMobile">
      <div class="free-bonus">
        <span class="text-white">{{ $t('posts.card_overlay.free').toUpperCase() }}</span> <span
        class="bonus">{{ $t('promo_code.commUpdate') }}</span>
      </div>
    </div>-->

<!--    <div class="col center mt-3" v-else>
      <div class="free-bonus mobile">
        <div class="text-white">{{ $t('posts.card_overlay.free').toUpperCase() }}</div>
        <div class="row bonus">{{ $t('promo_code.commUpdate') }}</div>
      </div>
    </div>

    <div class="mx-auto col-8" style="margin-top: 32px;">
      <SignUpPromos promo="commupdate2023"></SignUpPromos>
    </div>-->

  </section>
</template>

<script>
import SignUpPromos from './SignUpPromos'
import { mapState } from 'vuex'
import config from '@/project'

export default {
  name: 'commupdate2023',
  components: { SignUpPromos },
  data () {
    return {
      config
    }
  },
  computed: {
    ...mapState('application', ['isMobile'])
  },
  methods: {
    ended () {
      const today = new Date()
      var eventEnded = new Date(2023, 7, 9, 23, 59, 59)
      return (eventEnded - today) < 0
    }
  },
  created () {
    window.location.href = `https://${config.locales.site}/campaigns/promocode/commupdate2023/index.html`
  },
  mounted () {
    if (config.project === 'scatbook' || this.ended()) {
      this.$router.push('home')
    }
  }
}
</script>

<style lang="scss" scoped>

.cross {
  margin-left: 32px;
  margin-right: 32px;
  color: white;
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
}

.text-white {
  padding: 0 8px;
}

.bonus {
  margin-left: -10px;
}

.free-bonus {
  font: normal normal bold 72px/72px Montserrat;
  color: white;

  .text-green {
    color: #88FF00;
  }

  &.mobile {
    width: 90% !important;
    margin: auto;
    padding: 0 8px;
    text-align: center;

    .text-white {
      padding: 0 8px;
    }
  }
}

.row.bonus {
  display: block;
  margin-left: -10px !important;
}
</style>
