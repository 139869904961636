<template>
  <section>
    <div class="collapsible" @click="onOpenClose" :class="classTitle">
      <h5>{{ title }}</h5>
      <p class="icon-title">➤</p>
    </div>
    <div class="content" v-show="collapse">
      <slot />
    </div>
  </section>
</template>
<script>
export default {
  name: 'TitleCollapse',
  props: {
    title: { type: String, default: 'No title' },
    openCollapse: { type: Boolean, default: false }
  },
  data () {
    return {
      collapse: this.openCollapse
    }
  },
  computed: {
    classTitle () {
      return {
        dark: this.$vuetify.theme.dark
      }
    }
  },
  methods: {
    onOpenClose () {
      this.collapse = !this.collapse
      if (this.collapse) {
        this.$emit('open-collapse')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsible {
  background-color: #dfe3e9;
  cursor: pointer;
  padding-left: 10px;
  width: 100%;
  height: 30px;
  border: none;
  display: flex;
  align-items: center;
  outline: none;
  font-size: 15px;
  &.dark {
    background-color: #9b9b9b3b !important;
  }
}

.active,
.collapsible:hover {
  background-color: #777;
  color: #f1f1f1;
}

.content {
  padding: 0 18px;
  overflow: hidden;
}

.icon-title {
  /*  transform: rotate(90deg);  */
  font-size: 15px;
  margin-left: 5px;
  color: var(--v-primary-base);
}
</style>
