export default {
  data () {
    return { dialog: false }
  },

  watch: {
    dialog (value) {
      if (!value) {
        this.$emit('on-close')
        this.onClose()
      } else {
        this.$emit('on-open')
        this.onOpen()
      }
    }
  },

  methods: {
    toggleDialog () {
      this.dialog = !this.dialog
    },
    showDialog () {
      this.dialog = true
    },
    hideDialog () {
      this.dialog = false
    },
    onOpen () {

    },
    onClose () {

    }
  }
}
