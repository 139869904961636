<template>
  <v-card flat class="conference-card"
    :href="href" :to="link" :target="mustRedirect ? '_blank' : ''">
    <v-hover v-slot:default="{ hover }">
      <c-img :aspect-ratio="ratio"
        :src="author.bannerS3Route"
        no-resize
        gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)"
        class="rounded">
        <v-chip v-if="!extended" class="top-left">
          {{ $t('models.conferences.name') }}
        </v-chip>
        <v-icon v-if="isActive"
          class="top-right live-animation"
          color="primary">radio_button_checked</v-icon>
        <template v-if="hover">
          <div v-if="!authenticated"
            class="d-flex fill-height align-center">
            <div class="text-center mx-auto">
            <div class="white--text">{{ $t(`models.conference.type_description.unsigned`) }}</div>
            <c-btn small :to="{ name: 'login' }">
              {{ $t('actions.login') }}
            </c-btn>
            </div>
          </div>
          <div v-else
            class="conference-card-actions translucient-dark">
            <div>
              <v-icon dark>attach_money</v-icon>
              {{ typePrice }}
            </div>
            <v-spacer></v-spacer>
            <c-btn icon small color="white">
              <v-icon>bookmark_border</v-icon>
            </c-btn>
            <c-btn icon small color="white">
              <v-icon>share</v-icon>
            </c-btn>
          </div>
        </template>
      </c-img>
    </v-hover>

    <template v-if="extended">
      <div clas="mt-2 bold">{{ title }}</div>
      <UserAvatar small :user="author" />
      <div class="d-flex conference-card-info align-center">
        <v-icon v-if="isActive" small>radio_button_checked</v-icon>
        <span class="small-text">{{ activeTime }}</span>
        <v-spacer></v-spacer>
        <div v-if="totalFans">
          <v-icon small>person</v-icon>
          {{ totalFans }}
        </div>
      </div>
    </template>
  </v-card>
</template>
<script>
import UserAvatar from '@/components/users/UserAvatar'
import config from '@/project'
import { mapState } from 'vuex'

const minutesUntilNow = (date) => {
  const time = new Date(date).getTime()
  const now = new Date().getTime()
  let minutes = parseInt((now - time) / (1000 * 60), 10)
  minutes = minutes < 1 ? 1 : minutes
  return `${minutes} min`
}

export default {
  name: 'ConferenceCard',
  components: { UserAvatar },
  props: {
    ratio: { type: [String, Number], default: 1 },
    extended: Boolean,
    id: [String, Number],
    type: String,
    selectedUser: String,
    status: String,
    currency: String,
    minutePrice: Number,
    title: String,
    startsAt: [Date, String],
    totalFans: { type: [String, Number], default: 1 },
    author: Object
  },
  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('profile', ['currentUser']),
    link () {
      if (this.mustRedirect) return undefined
      return this.canJoin ? { name: 'conference', params: { uuid: this.id } } : null
    },
    href () {
      if (this.mustRedirect && this.canJoin) return `https://darkfans.com/lives/${this.id}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
      return undefined
    },
    mustRedirect () {
      return config.payments.darkfans_redirect && this.currentUser.username !== this.author.username && this.author.subscribed
    },
    typePrice () {
      return this.isPublic ? this.$t('models.conference.types.public')
        : this.isPremium ? this.minutePriceLabel
          : this.exclusivePrice
    },
    isPublisher () {
      return this.author.username === this.currentUser.username
    },
    isPublic () {
      return this.type === 'public'
    },
    isExclusive () {
      return this.type === 'exclusive'
    },
    isPremium () {
      return this.type === 'premium'
    },
    canJoin () {
      return this.authenticated && (this.isPublisher || (!this.isExclusive || (this.selectedUser === this.currentUser.username)))
    },
    isActive () {
      return this.status === 'active'
    },
    activeTime () {
      return this.isActive ? minutesUntilNow(this.startsAt) : this.$datetime(this.startsAt)
    },
    exclusivePrice () {
      return this.canJoin ? this.minutePriceLabel : this.$t('models.conference.types.exclusive')
    },
    minutePriceLabel () {
      const amount = this.$currency(this.minutePrice, this.currency)
      return this.$t('conferences.minute_price_label', { amount })
    }
  }
}
</script>
<style lang="scss">
.conference-card {
  .v-responsive__content {
    width: 100% !important;
    max-width: 100% !important;
  }

  .top-left {
    position: absolute;
    top: 8px;
    left: 10px;
  }
  .top-right {
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 10px;
    right: 10px;
  }
  &-title {
    max-height: 42px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &-info {
    color: #546e7a;
    .name, .username {
      color: #546e7a;
    }
    i {
      color: rgba(0, 0, 0, 0.38);
    }
  }

  &-actions {
    position: absolute;
    bottom: 0;
    color: white;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 4px;
  }
}

@keyframes rotate {
  from {
    transform: scale(0.4, 0.4);
    -o-transform: scale(0.4, 0.4);
    -ms-transform: scale(0.4, 0.4);
    -moz-transform: scale(0.4, 0.4);
    -webkit-transform: scale(0.4, 0.4);
  }
  to {
    transform: scale(1,1);
    -o-transform: scale(1,1);
    -ms-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -webkit-transform: scale(1,1);
  }
}
.live-animation {
  animation: rotate 1s ease infinite;
}
</style>
