<template>
  <input
    type="datetime-local"
    v-model="selectedDate"
    name="schedule"
    placeholder="Select Date time"
    :min="newMinDate"
    @change="emitDate()"
  />
</template>

<script>
export default {
  name: 'Calendar',
  props: {
    minDate: { type: Date, default: null },
    selDate: { type: Date, default: null }
  },
  data () {
    return {
      selectedDate: '',
      newMinDate: ''
    }
  },
  methods: {
    emitDate () {
      this.$emit('update-date', this.selectedDate)
    },
    transformDate (date, nextDay = false) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate() + (nextDay ? 1 : 0)
      const hours = date.getHours()
      const minutes = date.getMinutes()
      return `${year}-${(month < 10 ? '0' + month : month)}-${(day < 10 ? '0' + day : day)}T${(hours < 10 ? '0' + hours : hours)}:${(minutes < 10 ? '0' + minutes : minutes)}`
    }
  },
  mounted () {
    const today = new Date()
    this.selectedDate = this.transformDate(this.selDate || today, true)
    if (this.minDate) {
      this.newMinDate = this.transformDate(today)
    } else {
      this.newMinDate = this.transformDate(this.minDate)
    }
  }
}
</script>

<style scoped>

</style>
