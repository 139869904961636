<template>
  <section class="border-top">
    <section class="head-modals rows" v-if="!noClose">
      <div class="cols">
        <IconButton :size="10" type="close" @handler="close" />
      </div>
    </section>
    <TitleOptionTab
      :attrs="title"
      :defaultSelected="defaultSelectedTab"
      @selectTab="onTab"
    />
    <div class="spacer"></div>
    <Vault
      v-if="vault"
      :username="username"
      :withCheck="true"
      :classSelect="true"
      class="ml-2 mr-2"
    />
    <ListAlbums
      v-if="album"
      :username="username"
      :listGrid="true"
      :per_page="6"
      :withCheck="true"
      class="ml-2 mr-2"
    ></ListAlbums>
    <div>
      <Attachments
        v-if="upload"
        :reset="reset"
        @loading="loading"
        @up-media-file="upMediaFile"
        class="ml-2 mr-2"
      />
    </div>
    <ViewAttachmentForm class="ml-2 mr-2" />
    <div class="spacer"></div>
    <section v-if="disabledLoading" class="spinner-loader center">
      <div>
        <Spinner :size="30" color="black" />
      </div>
    </section>
    <div v-if="viewPriceInput && !noPrice" class="center rows">
      <p class="title-input-price">
        {{ $t('actions.set_price').toUpperCase() }}
      </p>
      <InputCurrency
        :currency="currency"
        :defaultPrice="mediaPrice"
        @newPrice="newPrice"
      />
    </div>
    <slot></slot>
  </section>
</template>

<script>
import { mapState} from 'vuex'
import Button from '../../component/Buttons.vue'
import IconButton from '../../component/IconButton.vue'
import validatable from '@/components/mixins/validatable'
import InputCurrency from '../../component/InputCurrency.vue'
import Attachments from '../../component/Attachments.vue'
import Spinner from '../../component/Spinner.vue'
import TitleOptionTab from '@/components/chat/component/TitleOptionTab'
import Vault from '@/components/chat/components/vault/Vault'
import ListAlbums from '@/components/chat/components/albums/ListAlbums'
import ViewAttachmentForm from '@/components/chat/components/newMessage/ViewAttachmentForm'
import { ChatActions } from '@/store'
import ConversationsApi from '@/api/conversations/ConversationsApi'

export default {
  name: 'MessageAttachmentsForm',
  mixins: [validatable('form')],
  props: {
    files: { type: Array },
    username: { type: String },
    noClose: { type: Boolean, default: false },
    noAlbums: { type: Boolean, default: false },
    noPrice: { type: Boolean, default: false },
    noUpload: { type: Boolean, default: false },
    defaultSelectedTab: { type: Number, default: 0 }
  },
  components: {
    Button,
    IconButton,
    InputCurrency,
    Attachments,
    Spinner,
    TitleOptionTab,
    Vault,
    ListAlbums,
    ViewAttachmentForm
  },
  data () {
    return {
      title: [
        {
          iconType: 'upload',
          title: 'actions.upload_new',
          call: 'upload',
          icon: true,
          show: true
        },
        {
          iconType: 'vault',
          title: 'actions.upload_vault',
          call: 'vault',
          icon: true,
          show: true
        },
        {
          iconType: 'album',
          title: 'actions.upload_album',
          call: 'album',
          icon: true,
          show: true
        }
      ],
      filesLocal: [],
      viewPrice: false,
      loadingAttach: false,
      vault: false,
      album: false,
      upload: false,
      defaultPriceLocal: 0
    }
  },
  computed: {
    ...mapState('profile', [
      'currentUser',
      'isFollower'
    ]),
    ...mapState('chats', ['selectedChat', 'viewAttachmentForm']),
    currency () {
      return this.currentUser.wallet.currency === 'EUR' ? '€' : '$'
    },
    disabledLoading () {
      return this.viewPrice
    },
    mediaPrice () {
      if (this.selectedChat) {
        return `${this.selectedChat.sender.mediaPrice}`
      } else {
        return `${this.defaultPriceLocal}`
      }
    },
     darkTheme(){
      return this.$vuetify.theme.dark
    },
    cssClass () {
      return {
        dark: this.darkTheme
      }
    },
    viewPriceInput () {
      return (
        this.viewAttachmentForm.length > 0 &&
        !this.isFollower &&
        !this.viewPrice
      )
    },
    reset () {
      return this.filesLocal.length === 0
    }
  },
  watch: {
    files () {
      if (this.files.length === 0) {
        this.filesLocal = []
      }
    }
  },
  mounted () {
    this.$store.dispatch(ChatActions.DeleteAttachmentForm)
    if (this.isFollower) {
      this.title.splice(2, 1)
    }
    if (this.noAlbums) {
      this.title.splice(2, 1)
    }
    if (this.noUpload) {
      this.title.splice(0, 1)
    }
    if (!this.selectedChat) this.priceSetting()
  },
  methods: {
    close () {
      this.$emit('reset')
      this.filesLocal = []
      this.$emit('loading', false)
    },
    upMediaFile (files = []) {
      files.forEach(f => {
        if (f.mediafile) {
          this.$store.dispatch(ChatActions.SetAttachmentForm, f.mediafile)
        }
      })
      this.filesLocal = files
      this.$emit('save-file', files)
    },
    loading (value) {
      this.viewPrice = value
      this.$emit('loading', value)
    },
    newPrice (price) {
      this.$emit('new-price', price)
    },
    onTab (call) {
      this.vault = call === 'vault'
      this.album = call === 'album'
      this.upload = call === 'upload'
    },
    async priceSetting () {
      if (this.username) {
        this.defaultPriceLocal = 500
      } else {
        const { data, error } = await ConversationsApi.settings()
        if (!error) {
          this.defaultPriceLocal = data.mediaPrice
        }
      }
    }
  }
}
</script>

<style lang="scss">
.title-input-price {
  margin-right: 10px;
}
.box-adjuntar-mediafile {
  bottom: 94px;
  right: 0;
  width: 100%;
  background-color: white;
  padding: 5px;
  border-top: 1px solid #dfe3e9;
  outline: none;
}

.content-adjuntar-mediafile {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #dfe3e9;
  padding: 10px;
  &.dark {
    background-color: darkgray;
  }
}
.etiqueta-fichero {
  background-color: #97e5ff;
  color: #000000;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  padding: 0px 5px;
  margin-right: 1px;
  font-size: 0.9em;
  display: inline-block;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner-loader {
  position: inherit;
  background: rgba(126, 126, 126, 0.2);
  z-index: 11000;
  overflow-y: hidden;
  /* height: 30px; */
  width: 100%;
}
</style>
