<template>
  <c-btn
    small
    :icon="icon"
    :disabled="disabled"
    css="ma-1"
    color="success"
    :tooltip="$t('actions.send_tip')"
    @click.stop.prevent="showDialog">
    <v-icon>monetization_on</v-icon>
    <span v-if="!icon" >{{ $t('actions.send_tip') }}</span>
    <TipDialog
      :dialog.sync="dialog"
      :user="user"
      :resource="resource"
      :resourceId="resourceId" />
  </c-btn>
</template>

<script>
import dialog from '@/components/mixins/dialog'
import TipDialog from '@/components/tips/TipDialog'

export default {
  name: 'TipButton',
  mixins: [dialog],
  components: { TipDialog },
  props: {
    icon: Boolean,
    disabled: Boolean,
    user: Object,
    resource: String,
    resourceId: [Number, String],
    showInit: Boolean
  },
  mounted() {
    if (this.showInit) {
      this.dialog = true
    }
  }
}
</script>
