<template>
  <section class="d-flex flex-column flex-grow-1">
    <ChatHeader
      :totalMatchSearch="totalMatchSearch"
      :indexMatchSearch="indexMatchSearch"
      :hidden-input-search="hiddenInputSearch"
      @want-to-search="wantToSearch"
      @search-text="searchText"
      @next-result="nextResult"
      @previous-result="previousResult"
      @clear-search="clearSearch"
    />
    <div class="messages-container flex-grow-1">
      <div ref="messageList" class="messages-list custom-scrollbar">
        <ListView
          no-gutters
          :loading="loading"
          :items="showDateItems"
          cols="12"
          align="start"
          justify="start"
          @top-reached="topReachedLoadItem"
        >
          <template #default="{ item }">
            <div
              v-if="item.showDate"
              class="chat-date"
              :class="classChatDateDark"
            >
              {{ dateSend(item) }}
            </div>
            <div :ref="`M${item.id}`" :tabindex="1">
              <MessageRow
                :message="item"
                @resource-loaded="goToBottom"
                @refresh-message="refreshMessage"
                @delete-message="deleteMessage"
              />
            </div>
          </template>
        </ListView>
      </div>
    </div>
    <!--    <form ref="form" @submit.stop.prevent="submit"></form>-->
    <NewMessageForm
      v-if="!userDelete"
      @add-message="addMessage"
      @go-to-bottom="goToBottom"
      @refresh-message-after-send="refreshMessageAfterSend"
      @error-message-send="deleteMessage"
      :blocked="blocked"
    />
    <div v-else style="height: 30px"></div>
    <WarningDialog
      :show="showWarning"
      :textWarning="$t(`chats.warningDeleteUser.text`)"
      :textButtonOK="$t('actions.ok').toUpperCase()"
      hideCancelButton
      @handlerButtonOK="showWarning = false"
    ></WarningDialog>
  </section>
</template>
<script>
import {mapState, mapGetters} from 'vuex'
import {ChatActions, AppActions, UserActions} from '@/store'
import CursorService from '@/components/mixins/cursor_api'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import validatable from '@/components/mixins/validatable'
import MessageRow from './MessageRow'
import ChatHeader from '@/components/chat/components/ChatHeader'

import NewMessageForm from '@/components/chat/components/newMessage/NewMessageForm'
import WarningDialog from '@/components/chat/component/WarningDialog'

export default {
  name: 'ChatMessageList',
  mixins: [validatable('form'), CursorService(ConversationsApi, 'listMessage')],
  components: {
    MessageRow,
    NewMessageForm,
    ChatHeader,
    WarningDialog
  },
  props: {
    blocked: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      message: '',
      page: 1,
      perPage: 100,
      query: {},
      dates: '',
      totalMatchSearch: 0,
      indexMatchSearch: 0,
      resultSearch: [],
      hiddenInputSearch: false,
      selectedTemp: null,

      areThreeMonths: false,
      loadThreeMonth: false,
      userDelete: false,
      showWarning: false
    }
  },
  computed: {
    ...mapGetters('profile', ['currentWallet']),
    ...mapState('profile', ['currentUser', 'isAdmin', 'isAccountManager', 'isInfluencer']),
    ...mapState('chats', ['selectedChat', 'listChat']),
    isApproved() {
      return (
        this.isCurrentUser ||
        this.isAdmin ||
        this.isAccountManager ||
        (this.selectedChat.receiver.approved &&
          this.selectedChat.sender.approved)
      )
    },
    receiver() {
      return this.selectedChat.receiver
    },
    hasFunds() {
      const currVat = this.currentUser.billing.vat || 0
      const vat = (100 + currVat) / 100
      const price = Math.ceil(
        (this.selectedChat.receiver.messagePrice || 0) * vat
      )
      return (
        this.isCurrentUser || this.isAdmin || this.isAccountManager || this.currentWallet.amount >= price
      )
    },
    classChatDateDark() {
      return {
        dark: this.$vuetify.theme.dark
      }
    },
    showDateItems() {
      this.items.map((item, index) => {
        const dataCurrent = this.$date(item.createdAt)
        item.showDate = true
        let indexAuxDate = index + 1
        if (index === this.items.length - 1) {
          indexAuxDate = index
          return
        }
        const dateMessageNext = this.$date(this.items[indexAuxDate].createdAt)
        item.showDate = dataCurrent !== dateMessageNext
        this.isThreeMonths(item)
      })
      return [...this.items].reverse()
    }
  },
  watch: {
    selectedChat(chat) {
      this.resetCursor(true)
    }
  },
  methods: {
    onLoadItems() {
      this.query.id = this.selectedChat.id
      return !!this.selectedChat.id
    },
    onLoadFinished() {
      const el = this.$refs.messageList
      if (el && el.scrollHeight) {
        const previewHeight = el.scrollHeight
        this.$nextTick(() => {
          el.scrollTop = el.scrollHeight - previewHeight
        })
      }
      if (!this.areThreeMonths && this.hasMoreElements && this.loadThreeMonth) {
        this.loadItems()
        this.goToBottom()
      }

    },
    onMessageReceived(message) {
      if (this.selectedChat.id !== parseInt(message.conversationId)) {
        return
      }
      this.$store.dispatch(ChatActions.MarkRead)
      this.maskIsRead()
      this.addMessage(message)
    },
    refreshMessageAfterSend(message) {
      const index = this.items.findIndex(msg => msg.idTemp === message.idTemp)
      if (index !== -1) {
        delete message.idTemp
        this.refreshMessage(message)
      }
    },
    addMessage(message) {
      const msg = this.mapItem(message)
      this.items.splice(0, 0, msg)

      this.$store.dispatch(ChatActions.UpdateAttributes, {
        lastMessageContent: msg.message,
        lastMessageAt: new Date()
      })
      this.goToBottom()
    },

    goToBottom() {
      const el = this.$refs.messageList
      if (el && el.scrollHeight) {
        this.$nextTick(() => {
          el.scrollTop = el.scrollHeight
        })
      }
    },
    refreshMessage(message) {
      const index = this.items.findIndex(msg => msg.id === message.id)
      if (index !== -1) {
        this.items.splice(index, 1, message)
      }
    },
    deleteMessage(message) {
      const index = this.items.findIndex(msg => msg.id === message.id)
      const indexTemp = this.items.findIndex(
        msg => msg.idTemp === message.idTemp
      )
      if (index !== -1 || indexTemp !== -1) {
        this.items.splice(index, 1)
        this.deleteLastMessage(message)
      }
    },
    deleteLastMessage(message) {
      if (
        this.selectedChat &&
        this.selectedChat.id === parseInt(message.conversationId)
      ) {
        const chat = this.selectedChat
        chat.lastMessageContent = ''
        this.$store.dispatch(ChatActions.UpdateAttributes, chat)
      }
    },
    updatedMessage(chat) {
      if (this.selectedChat && this.selectedChat.id === chat.id) {
        this.$store.dispatch(ChatActions.UpdateAttributes, chat)
      }
    },
    maskIsRead() {
      this.items.forEach(m => {
        if (!m.isRead) {
          m.message = `${m.message} `
          m.isRead = true
          this.refreshMessage(m)
        }
      })
    },
    visibilityListener() {
      if (
        document.visibilityState === 'visible' &&
        this.$router.currentRoute.name === 'messages'
      ) {
        this.areThreeMonths = false
        this.loadThreeMonth = false

        this.nextCursor = ''
        this.items = []
        this.totalMatchSearch = 0
        this.indexMatchSearch = 0
        this.hiddenInputSearch = !this.hiddenInputSearch
        this.$store.dispatch(AppActions.UpdateHasMessages, true)
        this.loadItems()
      }
    },
    dateSend(message) {
      const lastMessageAt = message.createdAt
      const lastMessageAtDate = this.$date(lastMessageAt)
      const today = this.$date(new Date())
      const isToday = lastMessageAtDate.startsWith(today)
      if (isToday) {
        return this.$t('words.today')
      } else {
        return this.$date(lastMessageAt)
      }
    },
    isThreeMonths(msg) {
      if (this.areThreeMonths) return
      const today = new Date()
      const beforethreeMonths = today.setMonth(today.getMonth() - 3)
      const lastMessageAtDate = new Date(msg.createdAt)
      this.areThreeMonths = lastMessageAtDate < beforethreeMonths
    },
    wantToSearch() {
      if (!this.areThreeMonths) {
        this.loadThreeMonth = true
        this.loadItems()
      }
    },
    topReachedLoadItem() {
      if (!this.hasMoreElements) return
      if (!this.loadThreeMonth) this.loadItems()
    },
    async searchText(text) {
      if (text.length === 0) {
        this.clearSearch()
        return
      }
      if (text.length > 2) {
        const aux = []
        this.items.map(m => {
          const msg = m.message.toLowerCase()
          if (msg.includes(text.toLowerCase())) {
            aux.push(m.id)
          }
        })
        if (aux.length > 0) {
          this.resultSearch = aux
          this.totalMatchSearch = aux.length
          if (this.totalMatchSearch > 0) {
            this.indexMatchSearch = 1
            this.searchPositionInMessageList()
          }
        } else {
          this.clearSearch()
        }
      }
    },
    nextResult() {
      if (this.indexMatchSearch > 1) {
        this.indexMatchSearch = this.indexMatchSearch - 1
        this.cursorPositions(null, false)
        this.searchPositionInMessageList()
      }
    },
    previousResult() {
      if (this.indexMatchSearch <= this.totalMatchSearch) {
        this.indexMatchSearch = this.indexMatchSearch + 1
        this.cursorPositions(null, false)
        this.searchPositionInMessageList()
      }
    },
    clearSearch() {
      this.totalMatchSearch = 0
      this.indexMatchSearch = 0
      this.loadThreeMonth = false
      this.cursorPositions(null, false)
      this.goToBottom()
    },
    searchPositionInMessageList() {
      const idSearch = this.resultSearch[this.indexMatchSearch - 1]
      const indexItems = this.items.findIndex(msg => msg.id === idSearch)
      if (indexItems !== -1) {
        this.cursorPositions(indexItems)
      }
    },
    cursorPositions(index, selected = true) {
      if (selected) {
        const messsage = this.items[index]
        const ref = `M${messsage.id}`
        this.$refs[ref].classList.toggle('selected-search-chat')
        this.$refs[ref].focus()
      } else {
        this.items.map(i => {
          const ref = `M${i.id}`
          this.$refs[ref].classList.remove('selected-search-chat')
        })
      }
    }
  },
  async mounted() {
    this.wsMessageReceived = this.onMessageReceived.bind(this)
    this.$ws.subscribe('conversation-message', this.wsMessageReceived)

    this.wsMessageUpdated = this.updatedMessage.bind(this)
    this.$ws.subscribe('conversation-updated', this.wsMessageUpdated)

    this.wsRefreshMessage = this.refreshMessage.bind(this)
    this.$ws.subscribe('conversation-message-updated', this.wsRefreshMessage)

    this.docVisibilityListener = this.visibilityListener.bind(this)
    document.addEventListener('visibilitychange', this.docVisibilityListener)

    const receiver = await this.$store.dispatch(UserActions.Fetch, {
      username: this.selectedChat.receiver.username,
      force: true
    })
    if (Object.keys(receiver).length === 1) {
      this.showWarning = true
      this.userDelete = true
    }
  },
  destroyed() {
    this.$ws.unsubscribe('conversation-updated', this.wsMessageUpdated)
    this.$ws.unsubscribe('conversation-message', this.wsMessageReceived)
    this.$ws.unsubscribe('conversation-message-updated', this.wsRefreshMessage)
    document.removeEventListener(
      'visibilitychange',
      this.docVisibilityListener
    )
  }
}
</script>
<style lang="scss" scoped>
.messages-container {
  position: relative;

  .messages-list {
    background-image: url("../../../../public/chat/background-chat-image.png");
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-repeat: repeat-x;
    overflow-x: hidden;

    &.custom-scrollbar-dark {
      background-color: #121b22 !important;
    }
  }
}

.chat-date {
  background: #e0f2fa;
  color: #4b4b4b;
  margin: auto;
  text-align: center;
  border: 1px solid #cfe2ea;
  border-radius: 14px;
  padding-left: 10px;
  padding-right: 10px;
  width: 30%;
  display: flex;
  justify-content: center;

  &.dark {
    background: #296f8f !important;
    border-color: #296f8f !important;
  }
}

.selected-search-chat {
  background: #e0f2fa82;
  outline: none;
}
</style>
