import i18n from '@/i18n'
import cookies from '@/cookies'

function setupLocale (i18n) {
  const cookieLocale = cookies.get('locale')
  const navigatorLang = navigator.language || navigator.userLanguage || 'en'
  const locale = cookieLocale || navigatorLang.split('-')[0]
  i18n.loadLocale(locale)
}

export default {
  i18n,
  install (Vue) {
    i18n.install(Vue)
    setupLocale(i18n)
  }
}
