<template>
  <div v-if="product.sales.length"
    class="title-text-3 roboto">
    <h2>{{ $t(`products.sales`) }}</h2>
    <v-row
      v-for="sale, index in product.sales"
      :key="`sale-${index}`"
      align="center">
      <v-col class="flex-grow-1">
        <v-row no-gutters>
          <v-col cols="12">
            {{ sale.ref }} - {{ sale.name }}
          </v-col>
          <v-col cols="12">
            <span class="small-text"
              :class="deliveryClass(sale)">
              {{ $date(sale.date) }} - {{ deliveryStatus(sale) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <c-btn small @click="selectSale(sale)">{{ $t('actions.show') }}</c-btn>
      </v-col>
    </v-row>
    <c-dialog v-model="dialog">
      <SaleInfo
        :product="product"
        :sale="selected" />
    </c-dialog>
  </div>
</template>
<script>
import SaleInfo from './SaleInfo'
import dialog from '@/components/mixins/dialog'

export default {
  name: 'SaleList',
  components: { SaleInfo },
  mixins: [dialog],
  props: {
    product: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    deliveryClass (sale) {
      return { 'green--text': sale.sent }
    },
    deliveryStatus (sale) {
      if (sale.sent) return this.$t('products.delivery.sent')
      else return this.$t('products.delivery.not_sent')
    },
    selectSale(acq) {
      this.selected = acq
      this.showDialog()
    }
  }
}
</script>
