<template>
  <div style="position: relative;">
    <Picker v-show="showEmojiPicker"
            :data="emojiIndex"
            :class="emojiPickerClass"
            :showPreview="false"
            @select="addEmoji"/>
    <div class="d-flex align-center py-2" :class="mentionMultilineClass">
      <at-ta ref="at" :members="members" name-key="name"
             @at="handleMention">
        <template #item="{ item }">
          <v-avatar :size="35">
            <c-img c-img rounded
                   :src="item.avatar"
                   width="35"
                   height="35"></c-img>
          </v-avatar>
          <span style="margin-left: 5px" v-text="item.name"></span>
        </template>

        <v-textarea ref="textarea"
                    v-bind="$attrs"
                    class="emoji-input flex-grow-1"
                    :label="label"
                    :placeholder="placeholderValue"
                    v-model="message"
                    :disabled="disabled"
                    :rows="rows"
                    :rules="rules"
                    :counter="maxLength"
                    v-on="$listeners"
                    @input="$emit('input', $event)"
                    @click="showEmojiPicker = false"
                    @blur="handleBlur"
                    @focus="handleFocus">
          <template #append-outer>
            <v-btn icon
                   :class="{ 'triggered': showEmojiPicker }"
                   class="emoji-trigger"
                   @click="toggleEmojiPicker">
              <v-icon>tag_faces</v-icon>
            </v-btn>
            <slot></slot>
          </template>
        </v-textarea>
      </at-ta>
    </div>
  </div>
</template>
<script>
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import data from 'emoji-mart-vue-fast/data/all.json'
import { mapState } from 'vuex'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import AtTa from 'vue-at/dist/vue-at-textarea'
import Api from '@/api/Api'
import UserApi from '@/api/UserApi'

const emojiIndex = new EmojiIndex(data)

export default {
  name: 'EmojiInput',
  components: {
    Picker,
    AtTa
  },
  props: {
    required: Boolean,
    rows: {
      type: [Number, String],
      default: 2
    },
    disabled: Boolean,
    value: String,
    label: String,
    placeholder: String,
    id: {
      type: [String, Number],
      default: ''
    },
    maxLength: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      emojiIndex: emojiIndex,
      cursorPosition: 0,
      showEmojiPicker: false,
      cancelLoadingUsers: false,
      message: '',
      members: []
    }
  },
  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('profile', ['currentUser']),
    ...mapState('preferences', ['darkTheme']),
    rules () {
      const rules = [this.$vBannedWords]
      if (this.required) {
        rules.push(this.$vRequired)
      }
      if (this.maxLength) {
        rules.push(this.$vMaxLength(parseInt(this.maxLength, 10)))
      }
      rules.push(this.$vNoHtml(this.message))
      rules.push(this.$vPhone(this.message))
      return rules
    },
    emojiPickerClass () {
      return {
        dark: this.darkTheme
      }
    },
    placeholderValue () {
      return this.placeholder || this.$t('models.comment.placeholder')
    },
    mentionMultilineClass () {
      return {
        'atwho-single-line': this.members.length === 1,
        'atwho-two-line': this.members.length === 2,
        'atwho-multi-line': this.members.length > 2
      }
    }
  },
  watch: {
    value (value) {
      this.message = value
    }
  },
  methods: {
    sortList () {
      this.members.sort((a, b) => (a.name > b.name) ? 1 : -1)
      return this.members
    },
    toggleEmojiPicker () {
      this.showEmojiPicker = !this.showEmojiPicker
    },
    addEmoji (emoji) {
      const textarea = this.$refs.textarea.$el
      const start = this.message.substring(0, this.cursorPosition)
      const end = this.message.substring(this.cursorPosition)
      this.message = start + emoji.native + end
      textarea.focus()
      this.$emit('input', this.message)
      this.$nextTick(() => {
        textarea.selectionEnd = this.cursorPosition + emoji.native.length
        this.toggleEmojiPicker()
      })
    },
    async loadUsers (username = '') {
      if (this.cancelLoadingUsers) {
        this.cancelLoadingUsers.cancel()
      }
      const cancelToken = Api.cancelToken()
      this.cancelLoadingUsers = cancelToken
      const {
        data,
        error
      } = await UserApi.list({
        username,
        role: 'influencer'
      }, {
        cancelToken: cancelToken.token
      })
      if (!error) {
        const users = data.data.filter(u => u.username !== this.currentUser.username)
        this.members = users.map(u => ({
          name: u.username,
          avatar: u.avatarS3Route
        }))
      }
    },
    handleMention (username) {
      if (this.id) {
        return
      }
      this.loadUsers(username)
    },
    destroy () {
      this.$refs.at.closePanel()
    },

    handleFocus () {
      if (this.hideOnBlur) {
        clearTimeout(this.blurTimer)
      }
    },

    handleBlur (event) {
      this.saveCursorPosition(event.target)
      if (this.hideOnBlur) {
        this.blurTimer = setTimeout(() => {
          this.destroy()
        }, 100)
      }
    },

    usersReceived (message) {
      if (message.conferenceId === this.id) {
        message.users.forEach(user => {
          if (!this.members.find(member => member.name === user && user !== this.currentUser.username)) {
            this.addUser(user)
          }
        })
      }
    },
    async addUser (user) {
      const {
        data,
        error
      } = await UserApi.list({ username: user })
      if (!error) {
        data.data.forEach(user => {
          this.members.push({
            name: user.username,
            avatar: user.avatarS3Route
          })
        })
      }
    },
    saveCursorPosition (target) {
      this.cursorPosition = target.selectionEnd
    }
  },
  mounted () {
    if (this.id) {
      this.wsUsersReceived = this.usersReceived.bind(this)
      this.$ws.subscribe('conference-update-users', this.wsUsersReceived)
    }
    this.message = this.value
    const events = ['keyup', 'keydown', 'keypress', 'focus', 'blur']
    events.forEach(event => {
      this.$refs.textarea.$el.addEventListener(event, $event => this.$emit(event, $event))
    })
  },
  beforeDestroy () {
    this.$ws.unsubscribe('conference-update-users', this.wsUsersReceived)
  }
}
</script>
<style lang="scss" scoped>
.custom-input {
  border: red solid 1px;
  border-radius: 16px;
  height: 32px;
}

.emoji-input:focus {
  outline: none;
}

.emoji-mart {
  position: absolute;
  top: -250px;
  right: 0;
  z-index: 10;
  width: 100% !important;
  height: 250px;
  max-height: 250px;

  &.dark {
    background-color: #2d3237 !important;

    &.emoji-mart-category-label {
      background-color: #c51162 !important;
    }
  }

}

.emoji-mart-category-label span {
  background-color: #2d3237 !important;
}

.emoji-mart-anchor-selected {
  color: #c51162 !important;

  .emoji-mart-anchor-bar {
    background-color: #DF3856 !important;
  }
}

::v-deep .atwho-wrap {
  .atwho-panel {
    position: absolute !important;
    width: 100%;
    left: 0 !important;
  }
}

.atwho-single-line {
  ::v-deep .atwho-wrap {
    .atwho-panel {
      top: -55px !important;
    }
  }
}

.atwho-two-line {
  ::v-deep .atwho-wrap {
    .atwho-panel {
      top: -105px !important;
    }
  }
}

.atwho-multi-line {
  ::v-deep .atwho-wrap {
    .atwho-panel {
      top: -155px !important;
    }
  }
}
</style>
