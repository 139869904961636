<template>
  <section>
    <section class="fixed " v-if="visibilityHeader">
      <div class="container promo">
        <div>
          <Logo/>
        </div>
        <div class="c-slot">
          <slot/>
          <c-btn @click="register"
                 style="background-color: #3FB848 !important; height: 40px!important; color: white !important;">
            <b>Registro</b>
          </c-btn>
        </div>
      </div>
    </section>
    <div :tabindex="0" v-intersect="handleScroll">
      <div></div>
    </div>
  </section>

</template>

<script>

import Logo from '@/components/custom/newDesign/headers/Logo.vue'
import { mapState } from 'vuex'

export default {
  name: 'PromoHeader',
  components: { Logo },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('preferences', ['darkTheme'])
  },
  data () {
    return {
      visibilityHeader: false
    }
  },
  methods: {
    register () {
      this.$emit('register')
    },
    handleScroll () {
      const meScroll = window.scrollY
      if (meScroll < 10) {
        this.visibilityHeader = false
      } else {
        this.visibilityHeader = true
      }
    }
  }
}
</script>

<style scoped>
.fixed {
  position: fixed;
  border-bottom: #80808036 solid 1px;
  top: 0;
  background-color: white;
  z-index: 100;
  width: -webkit-fill-available;
  width: -moz-available;
}

.cd-btn {
  background-color: #00a643 !important;
  color: white !important;
}

.c-slot {
  display: flex;
  align-items: center;
}

.promo {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}

</style>
