<template>
  <c-btn block
    @click.stop.prevent="dialog = true">
    {{ buttonText }}
    <BidDialog
      v-model="dialog"
      :product="product" />
  </c-btn>
</template>
<script>

import BidDialog from './BidDialog.vue'

export default {
  name: 'BidButton',
  components: { BidDialog },
  props: {
    product: Object,
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    buttonText () {
      const key = this.product.currentBidder
          ? 'actions.bid_again'
          : 'actions.bid'
      return this.$t(key)
    }
  }

}
</script>
