<template>
    <v-row align="center" class="video-message-wrapper">
        <v-col cols="12">
            <span class="video-message ">{{ $t('actions.still_uploading')}}</span>
        </v-col>
        <v-col>
            <Spinner color="primary" />
        </v-col>
    </v-row>
  </template>
  <script>
  import Spinner from '@/components/chat/component/Spinner'
  export default {
    name: "Recoding",
    components: { Spinner }
  };
  </script>
  <style lang="scss" scoped>
  .video-message-wrapper {
    display:flex;
    text-align: center;
    height: 100px;
    width: 100%;
  }
  .video-message {
    padding: 8px;
    border-radius: 2px;
  }
  </style>
  