<template>
  <component v-if="available"
             :is="buttonType"
             v-bind="$attrs"
             :resource="resource"
             :isProfile="isProfile"
             :oldPrice="oldPrice"
             :campaign="campaign"
             :wallet="wallet"
             @click="checkPayment">
    <c-dialog v-if="authenticated && !dummy"
              width="684"
              :actions="false"
              v-model="dialog">
      <template #title>
        <h1>{{ dialogTitle }}</h1>
      </template>
      <component
        ref="paymentForm"
        :key="formKey"
        :is="paymentType"
        :resource="resource"
        v-bind="allProps"
        @close="hideDialog"
        @payment-success="onPaymentSuccess">
      </component>
    </c-dialog>
  </component>
</template>
<script>
import {mapState} from 'vuex'
import {PostActions, ProductActions, UserActions} from '@/store'
import dialog from '@/components/mixins/dialog'
import PostPayment from '@/components/payments/forms/PostPayment'
import ProductPayment from '@/components/payments/forms/ProductPayment'
import SubscriptionPayment from '@/components/payments/forms/SubscriptionPayment'
import WalletPayment from '@/components/payments/forms/WalletPayment'
import PurchaseButton from '@/components/payments/buttons/PurchaseButton'
import SubscribeButton from '@/components/payments/buttons/SubscribeButton'

export default {
  name: 'PaymentButton',
  mixins: [dialog],
  components: {PostPayment, ProductPayment, SubscriptionPayment, WalletPayment, PurchaseButton, SubscribeButton},
  props: {
    available: {type: Boolean, default: true},
    fromResource: {type: Boolean, default: false},
    dummy: Boolean,
    subscription: Boolean,
    post: Boolean,
    product: Boolean,
    wallet: Boolean,
    isProfile: {type: Boolean, default: true},
    resource: {type: Object, default: () => ({})},
    resourceModel: {type: Object, default: () => ({})},
    oldPrice: {type: Boolean, default: false},
    campaign: {type: Boolean, default: false},
    showInit: {type: Boolean, default: false}
  },

  data() {
    return {
      formKey: 0,
      paymentSuccess: false,
      user: {}
    }
  },

  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('profile', ['currentUser']),

    allProps() {
      return {...this.$props, ...this.$attrs, user: this.resource}
    },

    dialogTitle() {
      const type = this.paymentType.replace('Payment', '').toLocaleLowerCase()
      return this.$t(`payments.title.${type}`, {username: this.resource.username})
    },

    buttonType() {
      return this.subscription ? 'SubscribeButton' : 'PurchaseButton'
    },

    paymentType() {
      return this.post ? 'PostPayment'
        : this.product ? 'ProductPayment'
          : this.subscription ? 'SubscriptionPayment'
            : 'WalletPayment'
    }
  },

  watch: {
    dialog() {
      this.formKey = this.formKey + 1
    }
  },

  methods: {
    onPaymentSuccess() {
      this.paymentSuccess = true
      this.$emit('payment-success')
    },
    onClose() {
      if (this.paymentSuccess) {
        this.reloadResource()
      }
      this.$refs.paymentForm.resetState()
    },
    checkPayment() {
      if (!this.authenticated) {
        sessionStorage.setItem('origin', this.$router.currentRoute.fullPath)
        this.$router.push('/login')
      } else if (!this.dummy) {
        this.showDialog()
      } else {
        this.$parent.$el.click()
      }
    },
    reloadResource() {
      if (this.post) {
        this.$store.dispatch(PostActions.Fetch, {id: this.resource.id, force: true})
      } else if (this.product) {
        this.$store.dispatch(ProductActions.Fetch, {id: this.resource.id, force: true})
      } else if (this.subscription) {
        this.$store.dispatch(UserActions.Fetch, {id: this.resource.username, force: true})
      }
    }
  },
  mounted() {
    if (this.showInit) {
      this.dialog = true
    }
  }

}
</script>
