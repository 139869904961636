<template>
  <v-card-text>
    <p>{{ $t('payments.carts.success_message_1') }}</p>
    <p>{{ $t('payments.carts.success_message_2') }}</p>
    <h1>{{ $t('models.payment.items') }}</h1>
    <div v-for="item in items"
      :key="`item-detail-${item.resourceId}`"
      class="mt-1">
      <v-row>
        <v-col cols="4">
          <router-link :to="`/${item.resource}/${item.resourceId}`">
            {{ $t(`models.payment.types.${item.resource}`) }}
          </router-link>
        </v-col>
        <v-col cols="6">
          {{ item.name }}
        </v-col>
        <v-col cols="2">
          {{ $currency(item.price, item.currency) }}
        </v-col>
      </v-row>
    </div>
  </v-card-text>
</template>
<script>

export default {
  name: 'CartInfo',
  props: {
    payment: Array
  },
  computed: {
    items () {
      return this.payment.items || []
    }
  }
}
</script>
