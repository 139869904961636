<template>
  <div class="file-uploader"
    :class="{ 'black-theme': darkTheme }">
    <v-card
      flat outlined
      @drop.prevent="droppedFile"
      @dragover.prevent>
      <v-card-text
        class="file-uploader-container d-flex flex-column" :style="cssStyle">
        <v-row justify="center" align="center"
               @click.stop="launchInputFile" v-if="isLoverfans && (!files.length || files.length === 0)">
          <div class="">
            <img src="/images/add-photo.png" class="test" />
            <label
              ref="label"
              for="fileInput"
              class="newFormInput"
            >
              <div class="absolute inset-0 flex flex-col items-center justify-center p-12">
                <p class="color-primary font-bold text-center text-xs">{{ componentLabel }}</p>
                <p class="small-text">
                  {{ maxFilesHint }}
                  <span v-if="maxVideos" class="small-text">{{ $t('file_uploader.max_video_size') }}</span>
                </p>
                <p v-if="tooManyFiles" class="small-text error--text">
                  {{ $t(`errors.discarted_too_many_files`, { max: maxFiles }) }}
                </p>
              </div>
            </label>
          </div>
        </v-row>
        <v-row justify="center" align="center"
          @click.stop="launchInputFile" v-else>
          <span>{{ componentLabel }}</span>
        </v-row>
        <v-carousel v-if="files.length"
          :show-arrows="this.files.length > chunkLength"
          hide-delimiters
          height="190"
          class="files-container">
          <v-carousel-item
            v-for="(chunk, index) in chunkedFiles"
            :key="`chunked-${index}`">
            <v-row no-gutters>
              <v-col v-for="chunkFile in chunk"
                :key="`item-${chunkFile.index}`"
                :cols="colSize">
                <v-card flat color="blue-grey lighten-4"
                  class="file-preview rounded" :style="fileStyle"
                  @click.stop>
                  <c-img v-if="chunkFile.file.isImage()" contain
                    width="100%"
                    height="100%"
                    :src="chunkFile.file.preview" />
                  <template v-else-if="chunkFile.file.isVideo()">
                    <div v-if="isSafari" class="translucient-box">
                      <span>{{ $t('file_uploader.ios_preview_disabled')}}</span>
                    </div>
                    <video v-else
                      :src="chunkFile.file.preview"
                      @click.stop />
                  </template>
                  <div v-else class="no-media-content"></div>
                  <div class="preview-actions text-center">
                    <c-btn icon color="error" css="mr-1"
                      @click.stop="removeFile(chunkFile.file, chunkFile.index)">
                      <v-icon>delete_outline</v-icon>
                    </c-btn>
                    <c-btn icon css="mr-1" v-if="chunkFile.file.isImage()"
                      :loading="chunkFile.file.loading"
                      @click.stop="startRotating(chunkFile.file)">
                      <v-icon>rotate_right</v-icon>
                    </c-btn>
                    <c-btn icon css="disable-events ml-1"
                      :loading="chunkFile.file.loading"
                      :color="chunkFile.file.uploaded ? 'success' : ''">
                      <v-icon>cloud_upload</v-icon>
                    </c-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
    <span v-if="!isLoverfans">
      <p class="small-text">
        {{ maxFilesHint }}
        <span v-if="maxVideos" class="small-text">{{ $t('file_uploader.max_video_size') }}</span>
      </p>
      <p v-if="tooManyFiles" class="small-text error--text">
        {{ $t(`errors.discarted_too_many_files`, { max: maxFiles }) }}
      </p>
    </span>
    <p>
      <template v-for="file in uploadedFiles">
        <span :key="file.key" class="x-small-text success--text mx-2 my-1">{{ file.key }}</span>
      </template>
    </p>
    <p v-if="errorMessage" class="small-text error--text">
      {{ errorMessage }}
    </p>
    <template v-for="file in uploadingFiles">
      <v-row no-gutters align="center"
        :key="file.key">
        <v-col cols="3" class="x-small-text truncate pr-1">
          {{ file.key }}
        </v-col>
        <v-col cols="auto" v-if="file.loading || file.error" class="x-small-text pr-1 indigo--text">
          {{ file.progress }}%
        </v-col>
        <v-col v-if="file.loading">
          <v-progress-linear :value="file.progress" color="indigo"></v-progress-linear>
        </v-col>
        <v-col v-else-if="file.error && autoretry"
          class="error--text small-text">
          {{ $t("file_uploader.error") }}
          <c-btn x-small @click="retryFile(file)">
            <Timer :start-value="10000"
              @count-end="retryFile(file)">
              <template #label="{ count }">
                {{ $t("file_uploader.resume", { seconds: parseInt(count / 1000) })}}
              </template>
            </Timer>
          </c-btn>
        </v-col>
        <v-col v-else-if="file.error"
          class="error--text small-text">
          {{ $t("file_uploader.error") }}
          <c-btn x-small @click="retryFile(file)">{{ $t('file_uploader.retry')}}</c-btn>
        </v-col>
      </v-row>
    </template>

    <v-file-input class="d-none"
      ref="inputFile"
      type="file"
      :accept="contentType"
      :multiple="maxFiles > 1"
      :rules="rules"
      @change="filesSelected" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import FileManager from '@/components/mixins/file_manager'
import config from '@/project'

export default {
  name: 'FileUploader',
  mixins: [FileManager],
  props: {
    value: { type: Array, default: () => [] },
    label: String,
    contentType: { type: String, default: 'image/*, video/*, .quicktime' },
    maxVideos: [String, Number],
    maxImages: [String, Number],
    multipleTypes: Boolean,
    autoupload: { type: Boolean, default: false },
    autoretry: { type: Boolean, default: false },
    isPromo: { type: Boolean, default: false },
    cols: Number,
    rules: { type: Array, default: () => [] }
  },

  data: () => ({
    tooManyFiles: false,
    errorMessage: '',
    chunkLength: config.project === 'loverfans' ? 2 : 8,
    config
  }),

  computed: {
    ...mapState('preferences', ['darkTheme']),
    isLoverfans () {
      return config.project === 'loverfans'
    },
    isSafari () {
      return platform.name === 'Safari'
    },
    cssStyle () {
      if (this.isLoverfans) {
        return {
          height: '12rem',
          width: '100%',
          position: 'relative',
          display: 'flex',
          'border-radius': '0.5rem',
          'align-content': 'center',
          'justify-content': 'center',
          'align-items': 'center',
          'border-style': 'dashed',
          'border-width': 'thin',
          'border-color': 'var(--v-primary-base)'
        }
      }
    },
    colSize () {
      const totalFiles = this.files.length
      return totalFiles < 2
        ? 12
        : totalFiles < 5
          ? 6
          : 3
    },
    chunkedFiles () {
      const chunked = []
      for (let i = 0; i < this.files.length; i += this.chunkLength) {
        const sliced = this.files.slice(i, i + this.chunkLength)
        const mapped = sliced.map((file, index) => ({ file, index: i + index }))
        chunked.push(mapped)
      }
      return chunked
    },
    fileStyle () {
      return {
        height: '190px',
        'max-height': '190px'
      }
    },
    autouploadFile () {
      return this.autoupload
    },
    maxFileNumber () {
      return this.maxFiles
    },

    isEmpty () {
      return !this.files.length
    },
    maxVideosInt () {
      return parseInt(this.maxVideos, 10)
    },
    maxImagesInt () {
      return parseInt(this.maxImages, 10)
    },
    isVideo () {
      return !!this.files.find(f => f.isVideo())
    },
    isImage () {
      return !!this.files.find(f => f.isImage())
    },
    uploadedFiles () {
      return this.files.filter(f => f.uploaded)
    },
    maxFiles () {
      return this.isVideo || this.isPromo ? this.maxVideosInt : this.maxImagesInt
    },
    maxFilesHint () {
      if (this.isPromo) {
        return this.$tc('file_uploader.max_items_hint_promo')
      }
      let items1, items2
      let count = 0
      if (this.maxImages > 0) {
        items1 = this.$tc('file_uploader.max_images', this.maxImagesInt)
        count++
      }

      if (this.maxVideos) {
        items2 = this.$tc('file_uploader.max_videos', this.maxVideosInt)
        count++
      }

      return this.$tc('file_uploader.max_items_hint', count, { items1, items2 })
    },
    uploadingFiles () {
      return this.files.filter(f => f.loading || f.error)
    },
    componentLabel () {
      return this.label || this.$t('posts.form.upload_file_text')
    },
    itemMdClass () {
      return this.cols
        ? this.cols
        : parseInt(this.maxFiles) > 1
          ? 6
          : 12
    }
  },

  watch: {
    value (value) {
      this.files = value
    },
    maxVideos () {
      this.chunkedFiles.forEach((file, index) => this.removeFile(file, index))
    },
    maxImages () {
      this.chunkedFiles.forEach((file, index) => this.removeFile(file, index))
    }
  },

  methods: {
    launchInputFile () {
      this.$refs.inputFile.$refs.input.click()
    },

    startRotating (file) {
      const canvas = document.createElement('CANVAS')
      const img = new Image()
      img.src = file.preview
      file.loading = true
      img.onload = () => {
        canvas.width = img.height
        canvas.height = img.width
        this.rotate(file, canvas, img)
      }
    },

    rotate (file, canvas, img) {
      const ctx = canvas.getContext('2d')
      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate(Math.PI / 2)
      ctx.drawImage(img, -img.width * 0.5, -img.height * 0.5)
      canvas.toBlob((blob) => {
        URL.revokeObjectURL(file.preview)
        file.preview = URL.createObjectURL(blob)
        file.blob = blob
        this.uploadFile(file)
      })
    },

    droppedFile (event) {
      if (!event.dataTransfer.files || !event.dataTransfer.files.length) {
        return
      }
      let notAllowed = false
      event.dataTransfer.files.forEach(file => {
        const isImage = this.contentType.includes('image')
        const isVideo = this.contentType.includes('video')
        if (file.type.includes('image') && !isImage) {
          notAllowed = true
        }
        if (file.type.includes('video') && !isVideo) {
          notAllowed = true
        }
      })
      if (notAllowed) {
        return
      }
      this.addFiles(event.dataTransfer.files)
    },

    async filesSelected (files) {
      let notAllowed = false
      files.forEach(file => {
        const isImage = this.contentType.includes('image')
        const isVideo = this.contentType.includes('video')
        if (file.type.includes('image') && !isImage) {
          notAllowed = true
        }
        if (file.type.includes('video') && !isVideo) {
          notAllowed = true
        }
      })
      if (!files || !files.length || notAllowed) {
        return
      }
      await this.addFiles(files)
      this.$refs.inputFile.$refs.input.value = ''
    },

    beforeAddFile (file) {
      this.errorMessage = ''
      if (!file.isMedia()) {
        this.errorMessage = this.$t('errors.mime_type_not_valid', { file: file.key })
      }
      if ((this.isVideo && file.isImage()) || (this.isImage && file.isVideo())) {
        this.errorMessage = this.$t('errors.same_mime_type')
      }
      if (file.isLarger(this.maxFileSize)) {
        this.errorMessage = this.$tc('errors.files_size_exceded', 1, { files: file.key })
      }
      return !this.errorMessage
    },

    onFileAdded (file) {
      this.$emit('input', this.files)
    },

    onFileUploaded (file) {
      this.$emit('input', this.files)
    },

    onFileRemoved (file) {
      this.tooManyFiles = false
      this.$emit('input', this.files)
    },
    onTooManyFiles (value) {
      this.tooManyFiles = value
    }
  }
}
</script>
<style lang="scss" scoped>

.file-uploader {
  cursor: pointer;

  &.black-theme {
    border: 1px groove gray;
  }
  .file-uploader-container {
    min-height: 250px;
    .files-container {
      //min-height: 200px;
      max-width: 100%
    }

    .file-preview {
      text-align: center;
      position: relative;
      height: 100%;
      margin: 3px;
      padding: 3px;
      overflow: hidden;

      video {
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
      }

      .no-media-content {
        height: 100%;
      }

      .preview-actions {
        position: absolute;
        bottom: 3px;
        left: 3px;
        right: 3px;
      }
    }
  }
}

.disable-events {
  pointer-events: none;
}
.translucient-box {
  background-color: rgba(0,0,0, 0.4);
  padding: 16px;
  margin: 24px;
}

.newFormInput {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 3rem /* 48px */;
}
.color-primary {
  font-weight: 700;
  font-size: 12px;
  color: var(--v-primary-base) !important;
}
.test {
  z-index: 0;
  opacity: 0.3;
}
</style>
