<template>
  <section class="auto-message">
    <div class="col-12">
      <h5>{{ title }}</h5>
    </div>
    <div class="col-12">
      <InputText
        v-on:newText="onNewMessage"
        :load-message="newMessage"
        :disabled="loading"
        ref="inputTextMessage"
        :rows="3"
        no-resize
        :class="{ errorForm: hasError.length > 0 }"
        :has-error="hasError"
        :first-load.sync="firstLoad"
        @hasBannedWord="isBannedWord"
        @hasHtmlLink="isHtmlTags"
        @hasPhone="isPhone"
        @hasLink="isLink"
      ></InputText>
    </div>
    <div class="col-12" v-if="oldAttach.length > 0">
      <ViewMultipleFile :mediaIds="oldAttach" v-on:delete-id="deleteAttach" />
    </div>

    <div class="row" style="margin: 0px 5px;">
      <div class="col-12">
        <button class="btn-att" @click="onShowAttach">
          {{ $t('chats.attachment.title') }}
        </button>
        <!-- <Buttons @handler="onShowAttach" :text="$t('actions.attach')"></Buttons> -->
        <!-- <Attachments
          @up-media-file="newFile"
          @loading="waiting"
          :reset="reset"
        /> -->
      </div>
      <div
        class="row"
        style="margin: 0px 5px;"
        v-if="!waitingMedia && (newAttach.length || oldAttach.length)"
      >
        <div class="col-7">
          {{ $t('actions.set_price') }}
        </div>
        <div class="col-5">
          <InputCurrency
            :disabled="loading"
            :defaultPrice="newPrice"
            :currency="currency"
            @newPrice="price"
          ></InputCurrency>
        </div>
      </div>
    </div>
    <Dialog
      :show="showAttach"
      @hide="onShowAttach"
      :showBotton="true"
      :modeMobile="isMobile"
      :title="$t('chats.attachment.title').toUpperCase()"
      @acept-dialog="aceptAtt"
    >
      <template slot="bodyDialog">
        <div>
          <MessageAttachmentsForm
            :noClose="true"
            :noPrice="true"
            @reset="reset"
            :defaultSelectedTab="1"
            @save-file="newFile"
            :files="newAttach"
            @loading="waiting"
          />
        </div>
      </template>
    </Dialog>
  </section>
</template>

<script>
import { existBannedWords, noHtml, noLink } from '@/plugins/form_validations'
import InputCurrency from '@/components/chat/component/InputCurrency'
import InputText from '@/components/chat/component/InputText'
import Attachments from '@/components/chat/component/Attachments'
import ViewMultipleFile from '@/components/chat/components/viewFiles/ViewMultipleFile'
import { mapState } from 'vuex'
import Buttons from '@/components/chat/component/Buttons'
import MessageAttachmentsForm from '@/components/chat/components/newMessage/MessageAttachmentsForm'
import Dialog from '@/components/chat/component/Dialog'

export default {
  name: 'AutoMessage',
  components: {
    InputCurrency,
    Attachments,
    ViewMultipleFile,
    InputText,
    MessageAttachmentsForm,
    Dialog,
    Buttons,
  },
  props: {
    autoMessageObject: Object,
    title: String,
    loading: Boolean,
    saving: Boolean,
    currency: String,
    reset: Boolean
  },
  data () {
    return {
      newMessage: '',
      newPrice: null,
      oldAttach: [],
      newAttach: [],
      waitingMedia: false,
      hasBannedWord: false,
      hasPhone: false,
      hasLink: false,
      hasHtml: false,
      firstLoad: true,
      showAttach: false
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('chats', ['viewAttachmentForm']),
    hasError () {
      const errors = []
      if (this.hasBannedWord) {
        errors.push({ error: 'bannedWord', field: this.hasBannedWord })
      }
      if (this.hasPhone) {
        errors.push({ error: 'noPhoneNumber', field: true })
      }
      if (this.hasLink) {
        errors.push({ error: 'noLinksAllowed', field: true })
      }
      if (this.hasHtml) {
        errors.push({ error: 'noHtmlTags', field: true })
      }
      this.$emit('errors', errors)
      return errors
    },
    attrs () {
      return {
        message: this.newMessage || '',
        mediaIds: this.getMediaIds(this.oldAttach),
        price: this.newPrice
      }
    }
  },
  methods: {
    onNewMessage (event) {
      this.firstLoad = false
      this.newMessage = event
      this.isBannedWord(this.newMessage)
      this.isHtmlTags(this.newMessage)
      this.isPhone(this.newMessage)
    },
    getMediaIds (oldFile) {
      const ids = []
      oldFile.forEach(file => {
        ids.push(file.mediaId || file.id)
      })
      return ids
    },
    newFile (file) {
      this.newAttach = file
    },
    aceptAtt () {
      const att = this.viewAttachmentForm.slice()
      att.map(f => {
        f.attachmentType = f.type
        delete f.path
        f.path = f.url
        this.oldAttach.push(f)
      })
      this.newAttach = att
    },
    price (price) {
      this.newPrice = price
    },
    isBannedWord (value) {
      this.hasBannedWord = existBannedWords(value)
    },
    isHtmlTags (value) {
      this.hasHtmlTags = noHtml(value)
    },
    isPhone (value) {
      this.hasPhone = noHtml(value)
    },
    isLink (value) {
      this.hasLink = noLink(value)
    },
    deleteAttach (id) {
      this.oldAttach = this.oldAttach.filter(f => f.mediaId !== id)
    },
    waiting (value) {
      this.waitingMedia = value
    },
    save () {
      return this.attrs
    },
    onShowAttach () {
      this.showAttach = !this.showAttach
    }
  },
  beforeMount () {
    this.newMessage = this.autoMessageObject.message
    if (this.autoMessageObject.mediaIds.length > 0) {
      this.waitingMedia = false
      this.oldAttach = this.autoMessageObject.mediaIds
    }
    this.newPrice = this.autoMessageObject.price
  }
}
</script>

<style lang="scss" scoped>
.btn-att {
  text-decoration: underline;
}
.auto-message {
  width: -webkit-fill-available;
}
</style>
