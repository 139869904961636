<template>
  <v-card flat>
    <v-card-text>
      <v-form v-model="validForm" ref="form" @submit.prevent="submit">
        <p style="font-size: 14px;">
          <span
            v-html="
              $t('models.withdraw.move_funds_text', {
                currentInit: this.valueCurrency.init,
                currentEnd: valueCurrency.result
              })
            "
          ></span>
          <button
            type="button"
            class="boton verde redondo"
            style="margin-left:10px; padding: 2px 3px;line-height: 1em; font-size: 12px; margin-bottom:5px"
            @click="currencyChange = !currencyChange"
          >
            {{ $t("actions.change") }}
          </button>
        </p>
        <CurrencyInput
          outlined
          v-model="amount"
          :currency="valueCurrency.init_name"
          :max-value="wallet.unlockedAmount"
          :rules="[$vRequired]"
        />
        <div class="balance">
          <p class="box_fund" :class="classModals">
            {{ $t("models.withdraw.move_funds_available") }}:
            <strong class="count_fund" :class="classModals">{{
              $currency(getWalletData.unlockedAmount, valueCurrency.init_name)
            }}</strong>
          </p>
          <p class="box_total" :class="classModals" v-if="payout.newPayout">
            {{ $t("models.withdraw.move_funds_all_funds") }}:
            <strong class="funds_total" :class="classModals">{{
              $currency(getWalletData.amount, valueCurrency.init_name)
            }}</strong>
          </p>
        </div>
        <div class="spacer"></div>
        <div
          style="display: flex; flex-direction: column;    justify-content: center; margin-left: 2px;  padding: 10px;"
        >
          <button
            type="button"
            class="button-blue"
            style="padding: 3px 6px; font-size: 12px"
            @click="goTo()"
          >
            {{ $t("models.withdraw.button_tutorial") }}
          </button>
        </div>
        <div class="spacer"></div>
        <v-tooltip v-model="show" bottom v-if="currentUser.wallet.bonus">
          <template v-slot:activator="{ on }">
                  <span v-on="on" class="truncate ml-auto two-lines" @click="show = !show">
                    <div class="col-12 center bonus-warning">
                      {{ $t('models.withdraw.pending_bonus') }}
                      <v-icon class="mx-4" solo :dark="darkTheme">help_outline </v-icon>
                    </div>
                  </span>
          </template>
          <div class="tooltip-bonus">
            <div class="col">
              {{ $t(`promo_code.${bonusCode}_info`, {amount: $currency(requiredAmount, 'EUR'), bonus: $currency(bonusAmount, 'EUR'), code: bonusCode }) }}
            </div>
            <div class="col">
              {{ $t(`promo_code.${bonusCode}_condition`, {minAmount: $currency(minAmount, 'EUR'), bonus: $currency(bonusAmount, 'EUR') }) }}
            </div>
          </div>
        </v-tooltip>
        <div class="spacer"></div>
        <div class="all_wallet">
          <p class="title" :class="classModals">
            <strong>{{ $t("wallet.current_balance") }}</strong>
          </p>
          <div :class="boxClass">
            <div class="content-datospago center" :class="classModals">
              <p style="font-size: 14px; line-height: 1.4em">
                {{ $t("application.menu.wallet") }}:
                <strong>{{ $currency(usdWallet.amount, "USD") }}</strong>
              </p>
            </div>
            <div class="content-datospago center" :class="classModals">
              <p style="font-size: 14px; line-height: 1.4em">
                {{ $t("application.menu.wallet") }}:
                <strong>{{ $currency(eurWallet.amount, "EUR") }}</strong>
              </p>
            </div>
          </div>
        </div>
      </v-form>
    </v-card-text>
    <div class="center">
      <c-btn
        @click="submit"
        :disabled="!canMove"
        :rounded="false"
        :css="cssButton"
        color="green"
      >
        {{ $t("actions.move") }}
      </c-btn>
    </div>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import WalletApi from '@/api/WalletApi'
import { WithdrawActions, ProfileActions } from '@/store'
import validatable from '@/components/mixins/validatable'
import config from '@/project'

export default {
  name: 'WalletWithdraw',
  mixins: [validatable('form')],
  props: {
    wallet: Object,
    initCurrency: Boolean
  },
  data () {
    return {
      amount: 0,
      currencyChange: false,
      show: false,
      config
    }
  },
  created () {
    this.currencyChange = this.initCurrency
  },
  computed: {
    ...mapState('application', ['billing', 'isMobile']),
    ...mapState('profile', ['eurWallet', 'usdWallet', 'payout', 'currentUser']),
    bonusCode () {
      return this.currentUser.wallet.bonus.bonusCode
    },
    minAmount () {
      return this.currentUser.wallet.bonus.requiredBillingAmount
    },
    bonusAmount () {
      return this.currentUser.wallet.bonus.amount
    },
    requiredAmount () {
      return this.minAmount - (this.getWalletData.lockedAmount + this.getWalletData.unlockedAmount)
    },
    remainingAmount () {
      return this.wallet.amount - this.amount
    },
    getWalletData () {
      const wallet =
        this.valueCurrency.init_name === 'EUR'
          ? this.eurWallet
          : this.usdWallet
      return wallet
    },
    canMove () {
      return this.validForm && this.amount > 0 && this.remainingAmount >= 0
    },

    cssButton () {
      return {
        'text-color-button': true
      }
    },
    cssButtonChange () {
      return {
        'button-change': true
      }
    },
    valueCurrency () {
      const value = {
        init: '$',
        init_name: 'USD',
        result: '€',
        result_name: 'EUR'
      }
      if (this.currencyChange) {
        value.init = '€'
        value.init_name = 'EUR'
        value.result = '$'
        value.result_name = 'USD'
      }
      return value
    },
    boxClass () {
      return {
        boxrow: !this.isMobile,
        boxcolumn: this.isMobile
      }
    },
    classModals () {
      return {
        dark: this.$vuetify.theme.dark
      }
    }
  },
  methods: {
    goTo () {
      window.open(`http://www.${this.config.locales.site}/academy/payments.html `, '_blank')
    },
    async onSubmit () {
      const { error } = await WalletApi.moveFunds({
        fromCurrency: this.valueCurrency.init_name,
        toCurrency: this.valueCurrency.result_name,
        amount: this.amount
      })
      let key = 'request_success'
      if (error) {
        key = 'cannot_process_request'
      }
      this.$eventBus.publish('notification', {
        message: this.$t(`snackbar.${key}`),
        error: error
      })
      this.$store.dispatch(ProfileActions.Fetch)
      this.emitClose()
    },
    emitClose () {
      this.amount = 0
      this.$emit('close')
    }
  },
  beforeMount () {
    this.$store.dispatch(WithdrawActions.FetchData)
    this.$store.dispatch(WithdrawActions.FetchMethods)
  }
}
</script>

<style lang="scss" scoped>
.text-color-button {
  color: #fff !important;
}
.button-change {
  color: #fff !important;
  background: var(--v-green) !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  padding: 2px 3px !important;
  line-height: 2px !important;
  font-size: 12px !important;
}
.boxrow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  .content-datospago {
    padding: 10px;
    margin: 1px;
    background-color: #f4f4f4;
    width: 50%;
    &.dark {
      background-color: #535353 !important;
    }
  }
}
.boxcolumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  .content-datospago {
    padding: 10px;
    margin: 1px;
    background-color: #f4f4f4;
    &.dark {
      background-color: #535353 !important;
    }
  }
}
.boton {
  display: inline-block;
  color: #fff !important;
  background-color: #41b849 !important;
  padding: 10px 15px;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.42857143;
  text-align: center;
  /* white-space: nowrap; */
  vertical-align: middle;
  border-radius: auto;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background-image: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  background-color: inherit;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-appearance: none;
}

.balance {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}
.funds_total {
  font-size: 1.1em;
  line-height: 2em;
  color: #000000;
  padding: 3px 20px;
  border-radius: 50px;
  border: 1px solid #e3e3e3;
  background: #fff;
  margin-bottom: 1px !important;
  &.dark {
    background-color: rgb(170, 170, 170) !important;
    color: #000000 !important;
  }
}
.box_fund {
  padding: 5px 10px;
  margin-bottom: 1px !important;
  background-color: #cbf2f3;
  &.dark {
    background-color: #76c3c6 !important;
  }
}
.count_fund {
  font-size: 1.2em;
  line-height: 2em;
  border-radius: 50px;
  color: #41b849;
  background-color: white;
  padding: 3px 20px;
  border-radius: 50px;
  border: 1px solid #e3e3e3;
  &.dark {
    background-color: rgb(170, 170, 170) !important;
    color: #008000 !important;
  }
}
.box_total {
  display: block;
  padding: 5px 10px;
  margin-bottom: 1px;
  background-color: #cbf2f3;
  &.dark {
    background-color: #76c3c6 !important;
  }
}
.all_wallet {
  border-radius: 10px;
  border: 1px solid #d5d5d5;
  overflow: hidden;
  .title {
    text-align: center;
    font-size: 14px;
    line-height: 1.2em;
    background-color: #f4f4f4;
    padding: 6px;
    &.dark {
      background-color: #535353 !important;
    }
  }
}

.text-color-button {
  color: #fff !important;
}
.button-blue {
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 5px;
  color: #fff !important;
  background-color: #3b7ddd;
}
</style>
