<template>
  <svg xmlns="http://www.w3.org/2000/svg" :height="`${size}px`" :width="`${size}px`"  viewBox="0 0 24 24">
    <path  :fill="color" id="votar" d="M4.875,10.5H1.125A1.125,1.125,0,0,0,0,11.625v11.25A1.125,1.125,0,0,0,1.125,24h3.75A1.125,1.125,0,0,0,6,22.875V11.625A1.125,1.125,0,0,0,4.875,10.5ZM3,22.125A1.125,1.125,0,1,1,4.125,21,1.125,1.125,0,0,1,3,22.125ZM18,3.818c0,1.988-1.217,3.1-1.56,4.432h4.768A2.8,2.8,0,0,1,24,10.973a3.394,3.394,0,0,1-.911,2.306l-.005.005a3.916,3.916,0,0,1-.436,3.725,3.707,3.707,0,0,1-.768,3.5,2.488,2.488,0,0,1-.288,2.092C20.634,23.981,18.263,24,16.258,24h-.133a13.456,13.456,0,0,1-5.6-1.487,7.377,7.377,0,0,0-2.468-.758.563.563,0,0,1-.552-.562V11.172a.562.562,0,0,1,.167-.4c1.857-1.835,2.655-3.778,4.177-5.3a5.677,5.677,0,0,0,1.19-2.761C13.243,1.842,13.679,0,14.625,0,15.75,0,18,.375,18,3.818Z" />
  </svg>
</template>

<script>
export default {
  name: 'LikeFill',
  props: {
    size: {
      type: [String, Number],
      default: 22
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
