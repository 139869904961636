<template>
  <v-card-text>
    <p>{{ $t('payments.subscriptions.success_message_1', { username: id }) }}</p>
    <v-row>
      <v-col cols="6">
        <c-img class="rounded user mini circle"
          :src="user.avatarS3Route">
        </c-img>
      </v-col>
      <v-col cols="6">
        <div class="my-8">
          <span class="title-text-2 semibold">@{{ id }}</span>
        </div>
        <div class="my-8">
          <span>{{ user.description }}</span>
        </div>
        <c-btn @click="close" :target="redirect ? '_blank' : ''">{{ $t('actions.go_to_item', { item: id }) }}</c-btn>
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
import { mapState } from 'vuex'
import { UserActions } from '@/store'
import config from '@/project'

export default {
  name: 'SubscriptionInfo',
  props: {
    id: String
  },
  data () {
    return {
      user: {},
      redirect: config.payments.darkfans_redirect
    }
  },
  computed: {
    ...mapState('profile', ['currentUser'])
  },
  methods: {
    close () {
      if (config.payments.darkfans_redirect) {
        window.location = `https://darkfans.com/${this.id}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
        return
      }
      this.$emit('close')
      this.$router.replace({ name: 'influencer', params: { username: this.id } })
    }
  },
  async mounted () {
    this.user = await this.$store.dispatch(UserActions.Fetch, { username: this.id, force: true })
  }
}
</script>
<style lang="scss" scoped>
.user.mini {
  height: 224px;
  width: 224px;
  max-height: 224px;
  max-width: 224px;
}
</style>
