<template>
  <section>
    <TitleOptionTab
      :attrs="title"
      @selectTab="onTab"
      :forceSelected="forceSelected"
    />
    <div v-if="newFile">
      <NewFile @save-new-files="saveNewFiles" />
    </div>
    <ListVault
      v-else
      :onDelete="onDelete"
      :type="type"
      :classSelect="classSelect"
      :username="username"
      :withCheck="withCheck"
    />
  </section>
</template>
<script>
import TitleOptionTab from "@/components/chat/component/TitleOptionTab";
import ListVault from "@/components/chat/components/vault/ListVault";
import NewFile from "@/components/chat/components/vault/NewFile";

export default {
  name: "Vault",
  components: { TitleOptionTab, ListVault, NewFile },
  props: {
    withCheck: { type: Boolean, default: false },
    classSelect: { type: Boolean, default: false },
    username: { type: String },
    uploadFile: { type: Boolean, default: false },
    onDelete: { type: Boolean, default: false }
  },
  data() {
    return {
      title: [
        {
          iconType: "newMessage",
          title: "application.chat_setting.vault.title_1",
          call: "video",
          icon: false,
          show: true
        },
        {
          iconType: "newMessage",
          title: "application.chat_setting.vault.title_2",
          call: "image",
          icon: false,
          show: true
        },
        {
          iconType: "newMessage",
          title: "application.chat_setting.vault.title_3",
          call: "audio",
          icon: false,
          show: true
        },
        {
          iconType: "plus",
          title: "",
          call: "new",
          icon: true,
          show: true
        }
      ],
      video: false,
      audio: false,
      image: false,
      newFile: false,
      type: "video",
      forceSelected: null
    };
  },
  mounted() {
    if (!this.uploadFile) {
      this.title.pop();
    }
  },
  methods: {
    onTab(name) {
      this.image = name === "image";
      this.video = name === "video";
      this.audio = name === "audio";
      this.newFile = name === "new";
      this.type = name;
    },
    saveNewFiles() {
      this.forceSelected = this.title[0];
      this.onTab('video')
    }
  }
};
</script>
