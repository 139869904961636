<template>
  <div class="unlock" :class="classDark">
    <div class="container-attach txtmin">
      <span v-if="countVideo">
        <IconButton type="active-cam" :size="20" />
        <p :class="classDark">{{ countVideo }}</p>
      </span>
      <span v-if="countAudio">
        <IconButton type="active-audio" :size="20" />
        <p :class="classDark">{{ countAudio }}</p>
      </span>
      <span v-if="countImg">
        <IconButton type="multi" :size="20" />
        <p :class="classDark">{{ countImg }}</p>
      </span>
    </div>
    <div v-if="isSend">
      <div v-if="!isFollower && !isFree">
        <div class="lock" v-if="countSale > 0">
          {{ $t("chats.attachment.sold", { price: $currency(countSale) }) }}
        </div>
        <div v-else>
          <div class="lock">
            {{ $t("chats.attachment.sell", { price: getPrice }) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="!isFree">
        <div v-if="isPurchased">
          <div class="lock">
            {{ $t("chats.attachment.bought", { price: getPrice }) }}
          </div>
        </div>
        <div v-else style="padding-bottom: 10px; min-width: 190px;">
          <Buttons
            :text="$t('chats.attachment.unlock', { price: getPrice })"
            @handler="forUnblock"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Buttons from "@/components/chat/component/Buttons";
import IconButton from "@/components/chat/component/IconButton";
import { mapState } from "vuex";
export default {
  name: "AttachUnlock",
  components: { Buttons, IconButton },
  props: {
    message: Object,
    isPurchased: Boolean
  },
  computed: {
    ...mapState("profile", ["currentUser", "isFollower", "idAdmin"]),
    getPrice() {
      return this.$currency(this.message.price);
    },

    isSend() {
      return this.isAuthor;
    },
    countSale() {
      let count = 0;
      const price = this.message.price;
      this.message.receivers.forEach(r => {
        if (r.selled && price > 0) {
          count += price;
          this.checkSale = true;
        }
      });
      /* if (count === 0) {
        return (count = price);
      } */
      return count;
    },
    /* sale() {
      return this.message.price > 0 && this.isAuthor;
    }, */

    isAuthor() {
      return this.currentUser.id == this.message.userId;
    },
    isFree() {
      return this.message.price === 0;
    },
    countImg() {
      return this.message.attachments.filter(f => f.type === "image").length;
    },
    countVideo() {
      return this.message.attachments.filter(f => f.type === "video").length;
    },
    countAudio() {
      return this.message.attachments.filter(f => f.type === "audio").length;
    },
    classDark() {
      return {
        dark: this.$vuetify.theme.dark
      };
    }
  },
  methods: {
    forUnblock() {
      this.$emit("forUnblock");
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  &.dark {
    color: #f9f9f9 !important;
  }
}
.unlock {
  //width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.2);
  //padding-bottom: 10px;
  border-radius: 8px;
  margin: 10px 0;
  &.dark {
    border-color: #f9f9f9 !important;
  }
}
.lock {
  padding: 5px;
  margin: 10px;
  font-weight: bold;
  color: green !important;
  border: green solid 1px;
  border-radius: 4px;
  background-color: rgb(215, 247, 168);
}
.container-attach {
  display: flex;
  align-items: center;
  span {
    font-style: normal;
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: flex-start;
    align-items: center;
  }
}
.txtmin {
  span {
    font: normal 0.875em "Open Sans", Arial, Helvetica, sans-serif;
    line-height: 140%;
    text-decoration: none;
    margin: 5px 0;
    font-size: 0.8125em;
    color: #535353;
  }
}
</style>
