<template>
  <div v-if="switchModal">
    <iframe
      class="iframe"
      :src="uri"
    ></iframe>
  </div>
</template>

<script>
import project from '@/project'
import cookies from '@/cookies'
import utils from "@/utils";

export default {
  name: 'ModalAvn',
  data() {
    return {
      switchModal: false,
      uri: `https://${project.project}.com/campaigns/promocode/avn/modal.html`
    }
  },
  mounted() {
    this.showConditions()
    window.addEventListener('message', this.controllerMessage)
  },
  methods: {
    showConditions() {
      const showModalAvnFollower = cookies.get('showModalAvnFollower')
      const existCookie = utils.validValue(showModalAvnFollower)
      if (existCookie) {
        this.switchModal = JSON.parse(showModalAvnFollower)
      }
    },
    controllerMessage(event) {
      if (event.data === 'closeModalAvn') {
        cookies.set('showModalAvnFollower', false)
        this.switchModal = false
      }
    }
  }
}
</script>

<style lang="scss">
.iframe {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 20;
}
</style>
