<template>
  <div class="text-justify">
    <h1><span class="upper">{{ project.locales.site }}</span> PLATFORM ACCESS AGREEMENT</h1>
    <h2>FOR CONTENT CREATORS</h2>
    <br>
    <p>This <strong>{{ project.locales.site }}</strong> platform access agreement for content creators (hereinafter, the
      "Agreement") is
      entered into between:</p>

    <p>I. ARTEMISA3000 TECH SOLUTIONS, S.L., with N.I.F. B-88466743, and Registered office in Calle Goya nº47, Planta 7,
      Pta. 12, 28001 Madrid (Spain) (hereinafter, "ARTEMISA")</p>
    <p>II. The natural person identified in the signature section of this Agreement with their full name, official
      identification document number, and postal address (hereinafter, the "Content Creator").</p>
    <br>
    <h1>1.PURPOSE</h1>
    <p>This document is configured as the contract that regulates access to the platform located at
      <strong>{{ project.locales.site }}</strong>
      (hereinafter, the "Platform") by the Content Creators, which allows them to access the functionalities offered by
      the Platform to publish and make available their audiovisual content and services for users of the Platform.</p>

    <p>In this sense, ARTEMISA provides the Content Creator with a service consisting of enabling a space in the
      Platform where the Content Creator can offer their content, products, and services, if they so desire.</p>

    <p>For these purposes, the Content Creator acts within the scope of the Platform as a businessperson or
      professional, being their sole responsibility to diligently comply with the tax obligations that apply in their
      country of tax residence, as well as in any other jurisdictions that may be competent on the occasion of the
      development of their professional activity on the Platform.</p>

    <p>This Agreement is supplemented, in all that is applicable, both by the provisions of the Privacy Policy, and the
      terms of the Legal Notice, both of which are binding on any user of the Platform, regardless of their status as
      User or Content Creator.</p>

    <p>By entering into this Agreement via the registration process for Content Creators that may be available from time
      to time in the Platform, the signatory of this Agreement expressly accepts the contents thereof and acknowledges
      having sufficient capacity to acquire the obligations arising from their actions through the Platform, as well as
      having previously read and understood its contents.</p>
    <br>
    <h2>2. AVAILABLE FUNCTIONALITIES FOR CONTENT CREATORS</h2>

    <p>ARTEMISA provides the Content Creator with a service consisting in the provision of a platform where the Content
      Creator can carry out their professional activity, through the following functionalities:</p>

    <h3>a) Publication of contents:</h3>
    <p>Content Creators may, through the Platform, offer their audiovisual content (including videos, photos, and voice
      notes -audios-) to the Platform Users (hereinafter, the "Content"). To this end, Contents may be published with
      open access or may be subject to payment of a price to be determined by the Content Creator at their own
      discretion, either on a subscription basis (whereby the contracting User may access all of the Content Creator's
      content subject to this type of contracting) or on a pay-per-access basis (whereby the contracting User may access
      the specific content that they pay for), as the Content Creator chooses.</p>

    <p>In addition to the above, the Content Creator may participate in promotional campaigns that may be enabled on the
      Platform, by adhering to them, in order to offer more beneficial conditions to Users during certain periods, as
      well as for themselves (for example, promotions of 100% of their sales or extra monetary bonuses), which encourage
      the acquisition of their Contents and Products by Users.</p>

    <h3>b) Product sales:</h3>
    <p>Through their profile on the Platform, the Content Creator who so wishes may offer physical or digital products,
      whether exclusive or second-hand, for sale to Users (hereinafter, the "Products"). The purchase of these Products
      by Users shall be carried out through the Platform's contracting system, in accordance with the terms (price,
      availability, date, etc.) predefined by the Content Creator.</p>

    <p>In general, Products may only be shipped within the same country where the Content Creator resides (in case the
      Content Creator resides in the European Union, shipments shall cover the entire EU). The Content Creator will
      define the shipping costs, which will be unique within the territory of the European Union, and per country when
      outside of the European Union. For this reason, it will be particularly important that the Content Creator
      expressly informs about territorial shipping restrictions and configures it correctly when setting up their
      profile and publishing any Product, in order to avoid purchases from territories to which the Products cannot be
      shipped.</p>

    <p>Once a User has purchased a Product from the Content Creator, the Content Creator will receive a notice through
      the Platform informing them that someone has purchased one of their Products, plus information regarding such
      purchase. It shall be the Content Creator's responsibility to contact the User (through the Platform's internal
      messaging channel) to request the shipping address of the purchased Product, as well as to arrange for the
      shipping itself to the purchasing User.</p>

    <p>In the event that a User resides outside the territory to which shipments are made, the User may contact the
      Content Creator to negotiate a possible sale and shipment to their territory, being at the discretion of the
      Content Creator to accept or reject such request, as well as to establish their own conditions to carry out such
      extraordinary sale. In any event, the purchase of the Product shall be made through the Platform's contracting
      procedure, and the User shall pay shipping costs in the form of a "tip" to the Content Creator through the
      Platform.</p>

    <p>ARTEMISA shall not assume any responsibility in relation to the specific agreements that may be reached between
      the Content Creators and the Users in this regard.</p>

    <h3>c) Interaction with Users.</h3>
    <p>The Content Creator may interact with Users in various ways, publicly or privately.</p>

    <p>Private interaction will take place through the Platform's internal messaging channel, through which Users can
      contact Content Creators on a one-to-one basis. In addition to managing issues directly related to the contracts
      made by a User (e.g., to indicate the shipping address of a Product or the delivery of digital Content purchased
      by the User), the Content Creator may use this channel to offer the User Content (free of charge or on a paid
      basis -PPV-), as well as to make private video calls (subject to payment of the corresponding price, which will be
      priced per minute based on the rate EUROS/minute set by the Content Creator themselves, although they may also be
      made
      free of charge), sell packages of Content, and receive direct tips.</p>

    <p>Public interaction will be carried out by means of live broadcasting in an open room, to which the Platform Users
      will be able to access to view such broadcasting without access restriction and free of charge, notwithstanding
      the fact that they will be able to make payments (of the amount that each User wishes, without this being
      mandatory).</p>

    <h3>d) Invoicing and payment management:</h3>
    <p>ARTEMISA provides the Content Creator with a centralization and management service for contracting with the
      Users, in such a way that, in relation to the Content, Products, and/or services that the Users may be interested
      in contracting, ARTEMISA manages such contracting through the contracting procedure of the Platform.</p>

    <p>For these purposes, the Content Creator will set in their profile the price applicable to each Content, Product,
      and service offered on the Platform, although for customized Products or Content, or at the request of the User,
      the Content Creator may agree on a different price with the interested User through the internal messaging
      channel. In any case, the contracting will be formalized through the Platform, with ARTEMISA acting as an
      intermediary for the purposes of payment and invoicing.</p>
    <br>
    <h2>3. INTELLECTUAL PROPERTY RIGHTS</h2>

    <h3>3.1. Rights over the Contents.</h3>

    <p>All Content, of any nature, that the Content Creator may publish or offer on the Platform will be perpetually
      owned by the Content Creator, without ARTEMISA acquiring any right over such Content by the mere fact of hosting
      them to make them available to the Users of the Platform.</p>

    <p>In this sense, all intellectual property rights and image rights in and to any Content that is published by the
      Content Creator shall be owned by the Content Creator themselves. The Content Creator only grants ARTEMISA a
      license for the correct publication and exploitation of such Content on the Platform, as well as on other
      platforms owned or managed by ARTEMISA3000 TECH SOLUTIONS, S.L. and/or on third party websites hosted on its
      servers, in order to promote such content under the conditions set by the Content Creator in question. Such
      license shall be worldwide and shall be limited to the time that the Content Creator is registered on the Platform
      and/or until the Content in question is removed, either by the Content Creator itself or by ARTEMISA as a result
      of any infringement of rights, of the terms applicable to the Platform and/or of the legislation in force.
      However, in the case of paid Content or Content sold through the messaging channel of the Platform, the same will
      be assigned to the User who had acquired it and will continue to be available to the User for as long as the User
      remains registered on the Platform, so that the assignment of rights of the Content Creator is made, in these
      cases, in perpetuity.</p>

    <p>ARTEMISA does not intend to use the Content generated by the Content Creator for purposes other than the
      effective provision of the service and the promotion of the Platform, as specified in the Legal Notice.</p>

    <p>To this end, and without limitation, the aforementioned license will be made expressly for the hosting of the
      Contents on ARTEMISA servers (or those of any third party that ARTEMISA hires for this purpose), as well as for
      its availability on the Platform (and other platforms owned by ARTEMISA3000 TECH SOLUTIONS, S.L. or hosted on its
      servers) according to its nature and the type of access that had been configured for the Content in question.</p>

    <p>The Content Creator acknowledges and guarantees to be the owner of all rights to the Content published on the
      Platform, and that they are fully in accordance with the law and do not infringe or undermine the rights of third
      parties, including image rights, and will not involve any additional cost and/or expense for ARTEMISA, being the
      Content Creator in question able to freely dispose of them. Likewise, the Content Creator guarantees that the
      Content they publish is not of a confidential nature and is not harmful to third parties.</p>

    <p>The Content Creator acknowledges that they assume liability, holding ARTEMISA harmless, for any communication
      that they provide personally or on their behalf, including, without limitation, as to the accuracy, legality,
      originality, and ownership of such communication.</p>

    <h3>3.2. Rights over the Platform.</h3>

    <p>As described in the Legal Notice, ARTEMISA is the owner of the Platform, as well as of all its materials,
      information, and elements, or, if applicable, has the corresponding licenses for their exploitation in this
      context.</p>

    <p>Access to the Platform by the Content Creator in no case gives the Content Creator any rights over the Platform
      or its elements, except for a right of access and use the same, limited to the development of the professional
      activity of the Content Creator within the Platform. All intellectual property rights over the Platform are
      expressly reserved to ARTEMISA and, in particular, it is forbidden to modify, copy, reproduce, publicly
      communicate, transform or distribute, by any means and in any form, all or part of the Platform and/or its
      elements without the prior, express and written authorization of ARTEMISA or, where appropriate, the holder of
      the corresponding rights.</p>

    <p>This limited right of access and use of the Content Creator shall be expressly revoked at the time this Agreement
      is terminated by any of the circumstances contemplated herein.</p>
    <br>
    <h2>4. OBLIGATIONS FOR THE CONTENT CREATOR</h2>

    <p>The Content Creator undertakes to:</p>

    <p>a) Comply with all the obligations, rules of use and conditions established by ARTEMISA in the Platform in
      relation to the use thereof, as contained in this Agreement and in the Legal Notice.</p>

    <p>b) Diligently comply with tax obligations imposed by the competent tax authorities in their country of tax
      residence and in other jurisdictions that may be competent. In this sense, the Content Creator is obliged to seek
      tax advice in relation to the development of their professional activity through the Platform.</p>

    <p>c) Provide all the information necessary to manage their registration in the Platform, as well as to keep such
      information updated at all times. The Content Creator shall communicate to ARTEMISA any modification in such
      information in the event that this should occur.</p>

    <p>d) Communicate to ARTEMISA, at the time of registration in the Platform, their country of tax residence and
      communicate any change in such residence that may occur during the term of this Agreement.</p>

    <p>e) Offer their services, Contents, and Products on the Platform in accordance with the legislation in force at
      all times and with the terms established in this Agreement and in the Legal Notice (
      <a
        :href="`https://${project.locales.site}/conditions`">https://{{ project.locales.site }}/conditions</a>
      ), as well as in any other policies
      that may be published by ARTEMISA on the Platform at all times.</p>

    <p>f) Not to publish contents that violate the provisions of the Legal Notice (<a
      :href="`https://${project.locales.site}/conditions`">https://{{ project.locales.site }}/conditions</a>) regarding
      prohibited contents and
      conducts.</p>

    <p>g) If third parties appear in any Content, such third parties must in any case be of legal age in the
      jurisdiction where such Content is generated.</p>

    <p>In addition, the Content Creator must obtain prior consent from these third parties for the publication of the
      Content on the Platform. Such consent must be signed and expressly reflect the authorization of the participant
      regarding the transfer of their image rights for the exploitation of the Content on the Internet, and such
      authorization must be broad enough to cover the publication of the Content on the Platform and other platforms
      owned or managed by ARTEMISA3000 TECH SOLUTIONS, S.L., as described in this Agreement. ARTEMISA may request at
      any time that the Content Creator provides a copy of such consent, along with a copy of the official national
      identity document of that person and proof of identity of the same, and the Content Creator must comply with such
      request immediately. For this purpose, ARTEMISA has enabled a section in the Platform for the Content Creator to
      upload such documentation, which they are obliged to keep updated at all times with all relevant information.
      ARTEMISA may carry out the necessary verifications to ensure that the information is complete, true and updated,
      and may request the Content Creator at any time to provide or update it, if ARTEMISA deems that it is incomplete
      or outdated. The Content Creator shall comply with such request immediately. In any case, the Content Creator
      shall hold ARTEMISA harmless against any claim that such third parties may file for infringement of their
      rights.</p>

    <p>h) Manage the delivery of Content and Products contracted by each User, such management being the sole
      responsibility of the Content Creator as regards enabling the Content on the Platform and procuring the shipment
      of the Products to the address indicated by each User.</p>
    <br>
    <h2>5. OBLIGATIONS FOR ARTEMISA</h2>

    <p>ARTEMISA undertakes to:</p>

    <p>a) Provide its services in connection with the Platform in accordance with the legislation in force at any given
      time.</p>

    <p>b) Manage the contracts entered into by Users with Content Creators with due diligence and in accordance with
      good market practices.</p>

    <p>c) Provide the Content Creator, without undue delay, all information related to the purchases made by Users with
      respect to the Content and Products, so that the Content Creator can manage the production and delivery of such
      Content or Products to the relevant Users.</p>

    <p>d) Pay the amounts generated by the activity of the Content Creator through the Platform in accordance with the
      provisions herein.</p>
    <br>
    <h2>6. ECONOMIC CONDITIONS: PAYMENT AND INVOICING</h2>

    <h3>6.1. Pricing</h3>

    <p>The Content Creator shall establish the price it wishes for their Content and Products, as well as for the
      services offered through the internal messaging channel and by video call, in each type of contract (paid,
      subscription, etc.). This price will be the one to be paid by the Users who wish to purchase the Content, acquire
      the Product, exchange messages or make a video call with the Content Creator. Payment will in all cases be managed
      through the Platform.</p>

    <p>Although the Content Creator shall be free to set the price it wishes for its Content, Products and services,
      such price shall at all times be limited by the maximum amount established by ARTEMISA at any given time, which
      shall be generally applicable to all Content Creators on the Platform. This maximum limit may be modified by
      ARTEMISA at any time, and in the event that a downward modification would imply that certain Content, Products or
      services exceed the new maximum limit, the Content Creator will be warned by the Platform so that it can adjust
      the price of the Content, Product or service affected.</p>

    <p>In turn, as consideration for the provision of the service, the Content Creator agrees with ARTEMISA that
      ARTEMISA (i) will apply a surcharge to the price to be paid by the contracting User and (ii) will retain TWENTY
      PERCENT (20%) of the amount to be settled with the Content Creator for the Content, Products or services
      contracted by the Users. Thus, the total price to be paid by the User will include the price of the Content,
      Product or service contracted (on which ARTEMISA will retain the percentage indicated as consideration), plus a
      surcharge for management and service costs, plus the applicable Value Added Tax (VAT).</p>

    <h3>6.2. Payment and Billing</h3>

    <p>The amounts generated by the Content Creators activity through the Platform will be paid by ARTEMISA in
      accordance with the terms and procedures described in the following link: <a
        :href="`https://${project.locales.site}/academy/payments.html`">https://{{ project.locales.site }}/academy/payments.html</a>,
      provided
      that at the time the Content Creator requests the settlement, it has generated a minimum amount of ONE HUNDRED
      100 EUROS after deducting the 20% commission established in the preceding paragraph. That is to say, the Content
      Creator must have generated a minimum gross amount of 120 EUROS.</p>

    <p>The amounts that correspond to ARTEMISA as consideration for its services and bank expenses will be subtracted
      from the total gross amounts, and VAT and the corresponding withholding tax will be applied.</p>

    <p>Payment of the amount accrued for each billing period will be made upon request of the Content Creator (provided
      that it has exceeded the minimum gross amount of 120 EUROS indicated above). Upon receipt of a settlement request,
      ARTEMISA will validate the amount to verify that it is correct, and in that case will proceed to make the
      payment. Upon payment, the corresponding self-invoice will be issued within the Platform, which will be available
      to the Content Creator automatically from that moment onwards. In this sense, both Parties agree that the
      invoicing procedure will be carried out through the issuance of electronic self-invoices.</p>

    <p>In any case, in the event that ARTEMISA is required by any tax authority for the payment of taxes on behalf of
      the Content Creator, the amount required will be deducted from the settlement of future amounts due by ARTEMISA
      to the Content Creator in question.</p>
    <br>
    <h2>7. LIABILITY OF THE CONTENT CREATOR</h2>

    <p>The Content Creator shall be liable, and shall hold ARTEMISA harmless, for any third-party claim, action or
      sanction of any nature related to the activity of the Content Creator, the interactions with the Users, the
      Contents and Products published on the Platform, the delivery of these to the contracting Users, and any
      liabilities arising from the use of the same by the Users.</p>

    <p>The foregoing shall apply especially in relation to any third parties that may appear in the content published by
      the Content Creator on the Platform, with respect to which it is obliged to obtain due consent for the
      exploitation of their image on the Platform.</p>

    <p>Likewise, the Content Creator shall be responsible for and guarantees compliance with their legal obligations of
      any nature, including tax obligations, and shall hold ARTEMISA harmless in the event of any claim related to a
      breach of such obligations by the Content Creator.</p>

    <p>The Content Creator shall be liable for any actions that may be carried out by third parties using their Content
      Creator account and shall be personally liable to ARTEMISA and hold ARTEMISA harmless for any claims, actions,
      penalties or consequences of any nature whatsoever arising from such actions. In any case, the Content Creator
      shall disclose the terms governing the use of the Platform to any third party to whom they may give access to
      their Content Creator account.</p>

    <p>ARTEMISA reserves the right to immediately suspend access to the Platform by the Content Creator in the event of
      any breach of the guarantees and obligations contained in this clause, retaining the amounts that at that time had
      been generated and were pending payment to the Content Creator, and reserving the right to any type of economic
      claim, based on the damages that may be caused by misuse of the Platform by the Content Creator.</p>
    <br>

    <h2>8. LIMITATION OF ARTEMISA LIABILITY</h2>

    <p>The liability of ARTEMISA towards the Content Creator shall be limited to the management of the contracts,
      purchases and acquisitions made by the Users with the Content Creators through the Platform, as well as to the
      services provided by ARTEMISA to the Content Creator.</p>

    <p>In addition to the exclusions of liability contained in the Legal Notice, ARTEMISA shall not be liable in case
      of malfunctions of the Platform resulting from a misuse or a breach of the terms hereof by the Content
      Creator.</p>

    <p>In no event shall ARTEMISA be liable for any consequential damages, including without limitation lost benefits
      or lost profits, arising out of any breach of this Agreement.</p>

    <p>To the extent permitted by applicable law, ARTEMISA makes no warranty regarding the use of the Platform. The
      Platform and its functionalities are provided "as is" and ARTEMISA disclaims any other warranties or conditions,
      whether express or implied, including, without limitation, warranties, obligations or conditions of fitness for a
      particular purpose, quality, performance, availability, continuity, results and freedom from viruses.
      Notwithstanding the foregoing, ARTEMISA warrants that it has taken all necessary measures, within its
      capabilities and the state of the art, to ensure the operation of the Platform and to reduce Platform failures to
      a minimum. Furthermore, ARTEMISA disclaims any warranty regarding the compatibility of the Platform and its
      functionalities and services with the systems, applications, devices, networks and any other software or hardware
      used by the Content Creator, unless ARTEMISA has expressly declared its compatibility in writing.</p>
    <br>
    <h2>9. TERM AND TERMINATION</h2>

    <p>This Agreement shall enter into force between ARTEMISA and the Content Creator on the day on which the latter is
      registered on the Platform, which entails the signing of this Agreement, and shall remain in force as long as the
      Content Creator continues to be registered on the Platform, regardless of whether they actively use the same to
      offer their Content and Products to Users, until terminated by either party in accordance with the terms of this
      Agreement. In this regard, ARTEMISA reserves the right to perform the verifications and checks it deems necessary
      to validate the registration of the Content Creator, at any time after the signing of this Agreement.</p>

    <p>However, certain provisions of this document are expressly or impliedly intended to continue in effect upon
      termination or expiration, so that they shall remain in effect and continue to be enforceable against the Content
      Creator in accordance with their terms even when the Agreement is no longer in effect.</p>

    <p>The Content Creator acknowledges that this Agreement may be terminated early, and therefore their access to the
      Platform may be revoked, in the following circumstances:</p>

    <ul>
      <li>Termination for convenience. The Content Creator may terminate the Agreement at any time by unsubscribing from
        the Platform as described in the Legal Notice.
      </li>
      <li>Termination for breach. ARTEMISA may terminate the Agreement in case of breach of its terms and/or the Legal
        Notice by the Content Creator and, in particular, in case of breach of any obligations or prohibitions in force
        on the Platform from time to time. Depending on the nature of the breach, ARTEMISA may, at its discretion,
        grant the Content Creator a maximum period to remedy the breach. ARTEMISA reserves the right to suspend the
        Content Creator's access to the Platform during the cure period. In any event, such termination shall not exempt
        the Content Creator from fulfilling any obligations that may have been pending and that are still due.
      </li>
      <li>Termination due to interruption or suspension of the service. In the event that ARTEMISA decides to interrupt
        or suspend, provisionally or permanently, the service of the Platform, ARTEMISA may terminate the Contract
        unilaterally. In that case, ARTEMISA will communicate it with reasonable notice so that the Content Creator
        can, if necessary, manage the closure of any pending contracts or services with the Users.
      </li>
    </ul>

    <p>In the event of termination of this Agreement at the convenience of the Content Creator or due to interruption or
      suspension of the Platform service, the Content Creator shall lose their right of access to the Platform but may
      in any case settle with ARTEMISA any outstanding amounts at the date of termination, provided that such amounts
      exceed the minimum payable amount established in clause 6.2 of this Agreement. When the cause of termination is a
      breach by the Content Creator, the Content Creator shall lose the right to the liquidation of the amounts pending
      payment.</p>

    <p>The Content Creator shall not be entitled to any indemnity or compensation for the early termination of the
      Agreement. ARTEMISA reserves the right to claim from the Content Creator the corresponding compensation for any
      damages that may have been caused by the breach that gave rise to the termination, which will be collected
      cumulatively with the amounts that may have been retained under the preceding paragraph.</p>
    <br>
    <h2>10. SUPPORT SERVICE</h2>

    <p>In order to provide a support service to the Content Creators, ARTEMISA has enabled an electronic contact
      channel for the management of any queries, complaints or suggestions, as well as the resolution of incidents that
      may arise for the Content Creator in relation to the operation of the Platform and the services made available on
      it.</p>

    <p>This channel is available via e-mail at <a
      :href="`mailto:stars@${project.locales.site}`">stars@{{ project.locales.site }}</a>.</p>

    <p>ARTEMISA will attend the communications received through this channel from Monday to Friday, from 9:00 - 18:00
      CET, with a response commitment of 72 hours on working days.</p>
    <br>
    <h2>11. CONFIDENTIALITY</h2>

    <h3>11.1. Confidential Information</h3>
    <p>ARTEMISA may have access to confidential information shared by the Content Creator in order to provide the
      Platform services and/or manage the contractual relationship. In the event that this occurs, we undertake to:</p>
    <ol>
      <li>Use the confidential information received solely and exclusively for the fulfillment of the terms of this
        Agreement and the provision of the contracted services.
      </li>
      <li>Strictly guard the confidential information, avoiding its disclosure or provision, in whole or in part, to any
        third party without the prior and express consent of the Content Creator.
      </li>
      <li>Treat the confidential information with the utmost discretion and use it solely and exclusively in connection
        with the provision of the services.
      </li>
      <li>Implement the security measures reasonably necessary for the purpose of complying with this clause.</li>
    </ol>

    <h3>11.2. Exceptions</h3>
    <p>The foregoing confidentiality obligations shall not apply with respect to information that:</p>
    <ul>
      <li>Is in the public domain or was made public by the Content Creator.</li>
      <li>Was lawfully obtained from third parties by ARTEMISA without restriction on disclosure.</li>
      <li>Was independently and lawfully developed by ARTEMISA.</li>
      <li>Was required by a judicial or administrative authority in the exercise of its jurisdiction and only to the
        extent that disclosure of the information was necessary to comply with such requirement.
      </li>
    </ul>
    <br>
    <h2>12. MISCELLANEOUS</h2>

    <h3>12.1. Headings</h3>
    <p>The headings of the various clauses are merely informative only and shall not in any way affect, qualify or
      influence the interpretation of this Agreement.</p>

    <h3>12.2. Severability</h3>
    <p>All the clauses or matters of this Agreement must be interpreted independently. In the event that any clause is
      declared null by judicial or arbitral resolution, the rest of the clauses shall not be affected and will remain in
      full force and effect. The null clause will be replaced by another that preserves the effects pursued by this
      Agreement.</p>
    <br>
    <h2>13. MODIFICATIONS TO THE AGREEMENT</h2>

    <h3>13.1. Modification Notice</h3>
    <p>ARTEMISA may modify the terms and conditions of the Agreement at any time by giving prior notice to the Content
      Creator.</p>

    <h3>13.2. Communication of Modifications</h3>
    <p>Any modification will be communicated by ARTEMISA by means of e-mail and banner or notice on the Platform
      itself, at least 30 days prior to the entry into force of the new contractual
      conditions, so that the Content Creator has the opportunity to review them prior to their entry into force.</p>

    <h3>13.3. Acceptance of Modifications</h3>
    <p>Notwithstanding that ARTEMISA may, where appropriate, establish other mechanisms for the acceptance of the new
      conditions of the Agreement (of which the Content Creator will be informed at the time of notifying the changes),
      the Agreement will be deemed accepted if, after the notice period, the Content Creator continues to use the
      Platform to offer their Content, Products, and services. For this reason, if the Content Creator does not agree
      with the new terms and conditions of the Agreement, they must unsubscribe from the Platform before those new terms
      come into force, since, once that date has passed, the new terms and conditions will be enforceable against any
      Content Creator who continues to use the Platform, and it will be understood that they have tacitly accepted
      them.</p>
    <br>
    <h2>14. APPLICABLE LAW AND COMPETENT JURISDICTION</h2>

    <h3>14.1. Applicable Law</h3>
    <p>This Agreement, including the use that the Content Creator makes of the Platform and its functionalities, as well
      as in relation to the Content and Products offered therein, are governed by Spanish law in force at all times.</p>

    <h3>14.2. Competent Jurisdiction</h3>
    <p>In the event of any controversy, action, or claim that may arise in relation to the interpretation and
      application of this Agreement, both Parties submit to the exclusive jurisdiction of the Courts of the city of
      Madrid (Spain), expressly and voluntarily waiving any other jurisdiction unless otherwise provided by law.</p>

    <p>And, in witness whereof, this Agreement is entered into by and between ARTEMISA and the Content Creator
      indicated below on the date indicated below:</p>
    <br>
    <slot/>
    <br>

  </div>
</template>
<script>
import project from '@/project'

export default {
  name: 'En_PDF',
  data() {
    return {
      project
    }
  },
  mounted() {
    this.agregarMetaNotranslate();
  },
  beforeDestroy() {
    this.eliminarMetaNotranslate();
  },
  methods: {
    agregarMetaNotranslate() {
      const meta = document.createElement('meta');
      meta.name = 'google';
      meta.content = 'notranslate';
      document.head.appendChild(meta);
    },
    eliminarMetaNotranslate() {
      const meta = document.querySelector('meta[name="google"][content="notranslate"]');
      if (meta) {
        meta.remove();
      }
    },
  },
}
</script>
<style scoped lang="scss">

</style>
