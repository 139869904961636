<template>
  <section>
    <v-btn class="login-button"
           v-if="isBtn"
           :loading="loading"
           :style="{'background-color': isLogin ? '#3b7ddd !important' : color}"
           :small="small"
           :large="large"
           :block="block"
           @click="connectTwitter">
      <img src="images/twitter_blue_v2.svg" :height="small ? 20 : ''" :width="small ? 20 : ''" alt="twitter"/><span class="login-button" :style=cssStyle>&nbsp;&nbsp;{{ $t(`index.twitter`) }}</span>
    </v-btn>
    <v-col v-else @click="connectTwitter">
      <img src="images/twitter_blue_v2.svg" :height="small ? 20 : ''" :width="small ? 20 : ''" alt="twitter"/><span class="login-button" :style=cssStyle>&nbsp;&nbsp;{{ $t(`index.twitter`) }}</span>
    </v-col>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions, SessionActions } from '@/store'
import TwitterApi from '@/api/TwitterApi'
import config from '@/project'

export default {
  name: 'NewConnectTwitter',
  props: {
    isBtn: { type: Boolean, default: true },
    callbackUrl: String,
    connecting: Boolean,
    color: String,
    large: Boolean,
    small: Boolean,
    txtcolor: { type: String, default: '#FFFFFF' },
    fontSize: { type: String, default: '16px' },
    fontFamily: { type: String, default: 'Quicksand, sans-serif' },
    isLogin: Boolean,
    block: Boolean
  },
  data () {
    return {
      loading: false,
      twitterEnabled: config.twitter_enabled
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    buttonText () {
      return this.isConnected
        ? this.$t('actions.disconnect')
        : this.$t('actions.connect')
    },
    isConnected () {
      return this.currentUser && this.currentUser.isTwitterConnected
    },
    twitterLogin () {
      return {
        twitterLogin: true
      }
    },
    cssStyle () {
      return {
        color: this.txtcolor,
        fontSize: this.fontSize,
        fontFamily: this.fontFamily
      }
    }
  },
  methods: {
    async connectTwitter () {
      this.loading = true
      if (this.isConnected) {
        await this.disconnect()
      } else {
        await this.connect()
      }
      this.loading = false
    },

    async connect () {
      const path = this.callbackUrl || `${window.location.origin}${window.location.pathname}`
      const lang = this.$i18n.locale
      const { data, error } = await TwitterApi.requestToken(path, lang)
      if (!error) {
        window.location = data.url
      }
    },

    async disconnect () {
      await TwitterApi.disconnect()
      this.$store.dispatch(ProfileActions.Fetch)
    }
  },
  async mounted () {
    if (this.isConnected) {
      return
    }
    const params = this.$route.query
    if (params.oauth_token && params.oauth_verifier) {
      this.loading = true
      const { data, error } = await TwitterApi.requestAccess(
        params.oauth_token,
        params.oauth_verifier,
        this.$i18n.locale
      )
      if (!error) {
        this.$store.dispatch(SessionActions.SetSession, data)
        this.$emit('twitter-connected', data)
      }
      this.loading = false
    }
  }
}
</script>
<style lang="scss">

.login-button {
  font-weight: normal !important;
  line-height: 1.42857143;
}
</style>
