import api from '@/api/Api'
import xml2js from 'xml2js'

const MaxChunks = 10
const chunkMultipartUrls = (urls) => {
  const response = []
  for (let i = 0; i < urls.length; i += MaxChunks) {
    response.push(urls.slice(i, i + MaxChunks))
  }
  return response
}

const MinMultipartSize = 5 * 1024 * 1024

class UploadResponse {
  setTask (task) {
    this.task = task
  }

  cancel () {
    this.canceled = true
  }

  isCanceled () {
    return !!this.canceled
  }

  promise () {
    return this.task
  }
}

const trying = async (promise) => {
  try {
    const result = await promise
    return { success: true, result }
  } catch (er) {
    return { success: false }
  }
}

const awaitFinishAllPromises = async (promises) => {
  const safePromises = await Promise.all(promises.map(trying));
  safePromises.forEach((result) => {
    if (!result.success) throw new Error();
  })
}

export default {

  async changeFormat (file, contentType) {
    const { data } = await this.submit(file).promise()
    return api.post('/convert_file', { path: data.filename, contentType })
  },

  submit (file, progressCallback) {
    const uploadResponse = new UploadResponse()
    let task = null
    if (file.size > MinMultipartSize) {
      task = this.startMultipartUpload(file, { progressCallback, interrupter: uploadResponse })
    } else {
      task = this.directUpload(file)
    }
    uploadResponse.setTask(task)
    file.uploadTask = uploadResponse;
    return uploadResponse
  },

  async directUpload (file) {
    const { data, error } = await api.post('/s3_upload_url', { contentType: file.getType() })

    if (!error) {
      return this.uploadFile(data, file)
    } else {
      return { data, error }
    }
  },
  startUpload (size, contentType) {
    return api.post('/start_upload', { size, contentType })
  },

  completeUpload (filename, parts, uploadId) {
    let orderedParts = parts.sort((a, b) => a.number > b.number ? 1 : -1)
    // remove duplicate entries in case there are any part uploaded twice or more times
    const seen = {}
    orderedParts = orderedParts.filter((part) => {
      if (seen[part.number]) return false
      seen[part.number] = true
      return true
    })
    return api.post('/complete_upload', { filename, parts: orderedParts, uploadId })
  },

  async startMultipartUpload (file, uploadTask) {
    const { data, error } = await this.startUpload(file.size, file.getType())
    if (error) {
      throw new Error()
    }
    const detailedUrls = data.urls.map((u, index) => ({ url: u, index }))
    file.uploadDetails = { ...data, pendingUrls: detailedUrls, uploadedParts: [] }
    return this.uploadParts(file, uploadTask)
  },

  resumeMultipartUpload(file, progressCallback) {
    const uploadResponse = new UploadResponse()
    file.uploadTask = uploadResponse
    uploadResponse.setTask(this.uploadParts(file, { progressCallback, interrupter: uploadResponse }))
    return uploadResponse
  },

  // Uploads pending urls for a file
  async uploadParts(file, { interrupter, progressCallback }) {
    const { chunkSize, pendingUrls } = file.uploadDetails;
    const chunkedUrls = chunkMultipartUrls(pendingUrls);
    try {
      // Since urls are ordered, we will chunk entery upload urls array into chunks.
      // then we will iterate over the chunked urls and uploading each chunk in sequence
      // to avoid saturate the browser with hundreds of request at one time
      let i = 0
      while (i < chunkedUrls.length && !interrupter.isCanceled()) {
        const chunk = { size: chunkSize, urls: chunkedUrls[i] }
        await this.uploadChunk(file, chunk, progressCallback)
        i++
      }
      if (interrupter.isCanceled()) {
        return { error: true }
      }
      // If we get here then uploading was great so complete upload parts and return s3 key as filename
      const { filename, uploadId, uploadedParts } = file.uploadDetails;
      const { data: fileData, error: fileError } = await this.completeUpload(filename, uploadedParts, uploadId)
      return { data: { filename: fileData.key }, error: fileError }
    } catch (e) {
      console.error(e)
      return { error: true }
    }
  },

  // For each url the first byte position will be:
  // Already used urls (position attr + chunk index) * chunk size
  // since all parts has the same size except the last one
  // but slice method will return only the remaining bytes if the end is greater than length
  async uploadChunk (file, chunk, progressCallback) {
    const promises = chunk.urls.map(async (urlPart, index) => {
      const start = (urlPart.index) * chunk.size
      const end = (urlPart.index + 1) * chunk.size
      const blob = file.blob.slice(start, end)
      const upload = await this.uploadPart(blob, file.getType(), urlPart)
      file.uploadDetails.uploadedParts.push(upload);
      file.uploadDetails.pendingUrls = file.uploadDetails.pendingUrls.filter((url) => url.index !== urlPart.index)
      if (progressCallback) {
        progressCallback({ uploaded: blob.size })
      }
      return upload
    })
    // We cannot use Promise.all since we need to wait until all promises are completed
    // and Promise.all will throw with first error while the remaining promises will still execute.
    // awaitFinishAllPromises waits until all promises are finished and throws an Error if any of them failed.
    await awaitFinishAllPromises(promises);
  },

  async uploadPart (filePart, fileType, urlPart) {
    let result = { error: true }
    const options = { headers: { 'Content-Type': fileType }, withCredentials: false }
    result = await api.defaultRaw().put(urlPart.url, filePart, options)
    return {
      etag: result.headers.etag.replace('"', ''),
      number: urlPart.index + 1
    }
  },

  async uploadFile (s3Fields, file) {
    const formData = new FormData()
    formData.append('Content-Type', file.getType())
    Object.entries(s3Fields.fields).forEach(([k, v]) => {
      formData.append(k, v)
    })
    formData.append('file', file.blob)

    const { data, error } = await api.defaultRaw().post(s3Fields.url, formData)

    if (!error) {
      const parser = new xml2js.Parser()
      const parsed = await parser.parseStringPromise(data)
      return { data: { filename: parsed.PostResponse.Key[0] } }
    }
    return { error: true }
  }
}
