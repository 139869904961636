<template>
  <section>
    <h1>LEGAL NOTICE</h1>
    <p><b>Before accessing and using this platform, please read this document (the "Legal Notice")</b>, carefully, which
      establishes the conditions governing its access and use.</p>

    <p>Any person accessing, browsing or using this platform (the “<b>User</b>”) shall be subject to the provisions of
      this Legal Notice. The use of the platform implies the express and unreserved acceptance of all the terms set
      forth herein, equally valid to any written and signed agreement. If you do not agree with the contents of this
      document, please refrain from accessing, browsing or using the platform.</p>

    <h2>1. IDENTIFICACIÓN</h2>

    <ul>
      <li><b>Owner</b>: ARTEMISA3000 TECH SOLUTIONS, S.L. (hereinafter, “ARTEMISA”)</li>
      <li><b>Registered Office</b>: Calle Goya nº47, Planta 7, Pta. 12, 28001 Madrid (Spain)</li>
      <li><b>Tax ID. No.</b>: B-88466743</li>
      <li><b>Public Registry</b>: [29 de Agosto de 2019, número 1741 de protocol, inscrita en el Registro Mercantil de
        Madrid, tomo 39620, folio 10, hoja número M-703152, inscripción primera]
      </li>
      <li><b>Telephone</b>: +34656897133</li>
      <li><b>E-mail</b>: <a :href="`mailto:${project.locales.contact_email}`"
                            target="_blank">{{ project.locales.contact_email }}</a></li>
    </ul>
    <br>
    <h2>2. CONTENT WARNING</h2>

    <h3>2.1. Nature of the </h3>
    <p>Part of the content contained in this platform may be of an erotic or sexual nature. By accessing the platform,
      the User consents to viewing this type of content, knowing that in some cases it may be considered offensive or
      inappropriate. If this type of content is not to your preference or you believe it may offend your sensibility,
      you should leave the platform immediately</p>

    <h3>2.2. Legal Age</h3>
    <p>By accessing this platform, the User acknowledges, under their own responsibility, that they have reached legal
      age in their jurisdiction (18 years of age or older, according to their region).</p>
    <p>Access to this platform is strictly prohibited to underage persons, thus if you have not yet reached legal age in
      your jurisdiction (at least 18 years of age), you must leave the platform immediately. Further browsing shall be
      performed under the User's sole responsibility and may result in criminal consequences.</p>
    <p>Likewise, anyone who suspects that a User may be underage, must report it to <a
      :href="`mailto:${project.locales.contact_email}`" target="_blank">{{ project.locales.contact_email }}</a>.</p>
    <br>
    <h3>2.3. Warning and prohibition notice </h3>
    <p>It is forbidden by law to publish content depicting minors, or adults pretending to be minors, with the purpose
      of avoiding abuse and trafficking of minors.
    </p>
    <p>It is also absolutely forbidden to use this platform to promote, participate in or encourage prostitution or sex
      trafficking. Thus, it is strictly forbidden to exchange personal information for the purpose of having encounters
      outside the platform. It is also forbidden to charge for services rendered in person. It is also absolutely
      forbidden to use this platform to promote, participate in or encourage prostitution, pedophilia, sex trafficking,
      human traffic an any kind of human abuse.</p>
    <p>Any breach of the above shall be of the sole responsibility of the offending User.</p>
    <p>By accessing this platform, the User acknowledges and accepts that THE WEBSITE does not generate content, but
      rather acts as a medium through which Content Creators may disseminate their legal, original and, in the event
      that third parties participate, consented material.</p>
    <p>THE WEBSITE has technological and human resources deployed to comply with the basic regulation regarding content,
      with the aim of promoting the quality and legality of the material accessible from our platform, and reserves the
      right to use such resources to conduct periodic reviews and take appropriate action against anyone who fails to
      comply with the rules of access to the platform, especially those contained in this Legal Notice and any other
      legal text applicable to each User.</p>
    <br>
    <h3>3. PURPOSE</h3>
    <p>This Legal Notice establishes the conditions, rights and obligations governing access to and use of the platform
      located at the URL <a :href="`${project.locales.site}`" target="_blank">{{ project.locales.site }}</a>
      (hereinafter, the "Platform")
      and the services and content (including logos and trademarks) available on it, as well as the responsibilities and
      liabilities that may arise from its use.</p>
    <p>Therefore, the use of the Platform implies the express and unreserved acceptance of all the terms of this Legal
      Notice, having the same validity and effectiveness as any contract entered into in writing and signed. In this
      regard, ARTEMISA reserves the right to modify the layout, configuration and content of the Platform, including
      this Legal Notice. For this reason, we recommend that you frequently review this Legal Notice.</p>
    <p>This Legal Notice shall apply to any person accessing, browsing or using the Platform, regardless of their status
      as User (anonymous or registered) or Content Creator. Mere access to the Platform constitutes tacit acceptance of
      the contents of this document, which shall be complied with by each person accessing the Platform, without
      exception.</p>
    <p>In particular, by using the Platform, the User or Content Creator agrees to use the services and contents of the
      Platform in accordance with applicable law, the terms of this Legal Notice, and any special conditions applicable
      to specific products or services and acknowledges and agrees that such use shall be at their sole risk and / or
      responsibility.</p>
    <p>Should the User acquire any product or service offered on the Platform, such acquisition shall be governed by the
      General <a href="/contract-conditions">Contracting Terms for Users</a>, which they must expressly accept by
      checking the box provided for that
      purpose. No contracting may be carried out if the User does not accept them</p>
    <p>Likewise, the Content Creators of the Platform shall be subject in any case to the terms and conditions
      stipulated in the Content Creator Agreement, which they shall expressly sign in order to register on the Platform
      as a Content Creator.</p>
    <br>
    <h3>4. DESCRIPTION OF THE PLATFORM AND SERVICES OFFERED</h3>

    <p>The main purpose of the Platform is to provide Content Creators and Users with a web platform through which they
      can connect with each other. Content Creators can publish or offer their content (hereinafter, the "Content")
      and/or physical or digital products (hereinafter, the "Products"), and Users can access them through a remote
      contracting or purchasing procedure.</p>

    <p>These Contents will be available in different access modalities:</p>

    <ul>
      <li>Free Content, whose access does not require an express contracting procedure by the User, as they are openly
        available to all Users of the Platform.
      </li>
      <li>Monthly subscription-based Content, which requires the User to purchase a subscription to the corresponding
        Content Creator's channel.
      </li>
      <li>Paid Content (pay-per-view), which the User contracts to gain access to or visualize specific content by
        paying a fixed unit price, whether if said Content is exclusive or not.
      </li>
    </ul>

    <p>Likewise, Content Creators may enable in their respective profiles a "store" section, which shall function as a
      Marketplace, where they may offer for sale certain goods (physical or digital) (the “Products”).</p>

    <p>The contracting of paid Contents, as well as the purchase of the Products offered by each Content Creator, shall,
      in all cases, be subject to the acceptance of the corresponding General Contracting Terms.</p>
    <br>
    <h3>5. ACCESS AND REGISTRATION</h3>

    <p>Access to the Platform and to the free Content available therein shall not require registration by anonymous
      Users, who may therefore access them without the need to register.</p>

    <p>However, any interaction with the functionalities of the Platform, the Content and/or with the Content Creators
      themselves requires registration, and therefore anonymous Users must register to do so.</p>

    <p>For this reason, although mere access to the URL where the Platform is hosted will display the home page where
      the anonymous User will be required to register or, if already registered, to log in to their account, either as a
      User or as a Content Creator, the anonymous User will also be able to access the "free" open sections of the
      Platform through that home page.</p>
    <br>
    <h4>5.1. User Registration.</h4>

    <p>To register as a User, you must complete the form provided for this purpose within the Platform. Registration on
      the Platform is, unless otherwise indicated, free of charge. However, access to and use of certain Contents
      offered through the Platform may require specific contracting, which will be subject to the General Contracting
      Terms for Users as set out in the Platform at any given time, which the (registered) User must accept prior to
      contracting the Contents or Service in questio</p>

    <p>We are not responsible for the accuracy of the registration data provided by Users, thus each User shall be
      responsible for the possible consequences, errors and failures that may subsequently arise from the lack of data
      quality</p>

    <h5>5.1.1. Registration requirement</h5>

    <p>When registering, the User must enter all the (true and accurate) data requested in the registration form, as
      this shall constitute the sole manner through which we will be able to manage the services we provide and process
      the requests that the User may submit in the future through their account.</p>

    <p>At ARTEMISA’ sole discretion, we may offer registration to the User through social network plugins such as, for
      example, Twitter. In the case of opting for this method of registration, the User will use his or her access
      credentials to that social network to register on the Platform.</p>

    <p>In order to create a User account, each interested party must meet the following requirements:</p>

    <ul>
      <li>Be a natural person.</li>
      <li>Meet legal age on the applicable jurisdiction and, in any case, be at least of 18 years of age.</li>
      <li>Complete the account registration process</li>
      <li>Accept the Platform's Privacy Policy by ticking the corresponding box.</li>
      <li>Provide accurate, complete and up-to-date contact and billing information.</li>
    </ul>

    <p>The User account on the Platform is, in any case, personal and non-transferable.</p>

    <p>In the event that a User registered on the Platform wishes to register as a Content Creator, they may keep the
      access credentials assigned when registering as a User, but in any case they must complete the registration
      process as a Content Creator as described in section 4.2.</p>
    <br>
    <h5>5.1.2. Termination as a Registered User</h5>

    <p>Similarly, just as the User can register by following the instructions above and meeting the conditions described
      above, the User may also request deregistration from the Platform at any time, by accessing the settings panel in
      the User's profile.</p>

    <p>ARTEMISA reserves the right to refuse, at its discretion, any new registration by the User in the event that the
      deregistration has occurred for any of the cases described in the section "Rules of use of the Platform".</p>

    <h4>5.2. Content Creator Registration.</h4>

    <h5>5.2.1. Registration requirements.</h5>

    <p>Registration as a Content Creator is reserved for natural persons who are of legal age in their jurisdiction (and
      at least eighteen (18) years of age). In any case, access to the Platform is prohibited to Content Creators
      residing in or coming from any territory where pornography is prohibited, in particular, the following
      territories: Arabia Saudita, Catar, China, Emiratos Árabes, Irán, Kuwait, Omán, Uzbekistan and Yemen.</p>

    <p>To register as a Content Creator, the interested party shall provide, by means of the form provided for this
      purpose, the requested personal data, which will include in any case name, surname, identification number, email
      and phone number, as well as the appropriate ID documentation (copy of ID card, passport or driver's license). In
      addition, the interested User must pass a facial recognition test during the registration process in order to
      confirm that the person making the registration request coincides with the person appearing in the provided
      documentation.</p>

    <p>Once the above is completed, the applicant must sign the Content Creator Agreement granting status as a Content
      Creator and establishing the terms and conditions governing their use of the Platform and the publication of their
      Contents and Products</p>
    <br>
    <h5>5.2.2. Termination as Content Creator</h5>

    <p>The Content Creator may request to be removed from the Platform at any time through the settings panel of their
      Content Creator profile.</p>

    <p>Once the cancellation has been carried out, the Content Creator may request a new registration, which may be
      rejected by ARTEMISA, at its sole discretion, if the past cancellation was due to any of the cases described in
      the section "Rules of Use of the Platform" or due to a breach of the Content Creator Agreement</p>

    <h4>5.3. Password</h4>

    <p>The password associated with the User account will be personal and non-transferable and must be generated in
      accordance with the rules of robustness and complexity established on the Platform from time to time. If the User
      or Content Creator selects a password that does not comply with the minimum requirements, they will be notified
      and will be required to select another password that complies with the minimum requirements.</p>

    <p>By creating their User account, the User or Content Creator undertakes to make diligent use of it and to keep
      their password secret, not transmitting or disclosing it to any third party, not even to ARTEMISA or any
      supplier.</p>

    <p>The User or Content Creator shall be responsible for the proper custody and confidentiality of any account to
      which they have access and for any activity that occurs in such account, as well as for the unlawful use of the
      Platform by any illegitimate third party who accesses it using their password. The User or Content Creator must
      notify us immediately of any unauthorized access to or use of their account. ARTEMISA will not be liable for any
      loss due to stolen or hacked passwords. We do not have access to the password of our Platform Users and, for
      security reasons, we may only provide instructions on how to reset the password</p>

    <p>In any case, the Platform has the necessary functionalities to allow any User or Content Creator to change his or
      her password when he or she deems it appropriate, for example, in case of a suspicion or finding that the
      confidentiality of the password has been breached.</p>

    <p>ARTEMISA reserve the right to disable any User's or Content Creator's access credentials, at any time, if in our
      reasonable opinion the User or Content Creator in question has not complied with any of the provisions of this
      Legal Notice, the General Contracting Terms for Users or the Content Creator Agreement, as applicable, or any
      other legally binding document or text within the scope of the Platform.</p>
    <br>
    <h3>6. RULES OF USE OF THE PLATFORM</h3>

    <p>The use of the Platform for illegal or unauthorized purposes, with or without economic purpose, is not permitted.
      Consequences shall be the sole responsibility of the User or Content Creator, whichever is applicable.

      <h4>6.1. Platform prohibitions.</h4>

      <p>The following are some of the prohibited activities, which shall not be considered as an absolute list:</p>

      <ol>
        <li>Use the Platform in any manner that may cause damage, interruptions, inefficiencies or defects in the
          operation of the Platform or third party devices;
        </li>
        <li>Use the Platform for the transmission, installation or publication of any viruses, trojan horses, worms,
          logic bombs, malicious code or other harmful programs or files;
        </li>
        <li>Use the Platform to transmit material for advertising or promotional purposes, including spam, chain letters
          or the like
        </li>
        <li>Use the Platform in any manner that constitutes an infringement of the rights of ARTEMISA or any third
          party
        </li>
        <li>Use the Platform to transmit or post any material of a defamatory, offensive, racist, disparaging,
          denigrating or threatening nature or that may annoy, harm or merely affect any person;
        </li>
        <li>Use the Platform to collect personal data of other Users</li>
        <li>Use the Platform illegally, against good faith, morality and/or public order</li>
        <li>Accessing or registering on the Platform under a false identity, impersonating third parties or using a
          profile or performing any other action that may mislead other Users as to the identity of the origin of a
          message
        </li>
        <li>Gaining unauthorized access to any section of the Platform, other systems, networks or servers connected to
          the Platform, or to the services offered through the Platform by hacking, forgery, password mining or any
          other illegitimate means;
        </li>
        <li>Breach, or attempt to breach, the security or authentication measures of the Platform or any network
          connected thereto, or the security or protection measures inherent in the content offered on the Platform;
        </li>
        <li>Take any action that causes disproportionate or unnecessary saturation of the infrastructure of the Platform
          or our systems or networks, or the systems and networks connected thereto.
        </li>
      </ol>
      <br>
      <h4>6.2. Prohibitions on Contents.</h4>

      <p>In any case it is strictly forbidden to publish, share or link to content</p>

      <ol>
        <li>That may be considered a violation in any manner of the fundamental rights to honor, to personal and
          domestic privacy or to the personal image of third parties and especially regarding that of minors or
          vulnerable persons;
        </li>
        <li>That collect images or personal data of third parties without having obtained the appropriate consent of
          their owners
        </li>
        <li>That violate the secrecy of communications or that involve an infringement of intellectual property rights
          or of the rules governing the protection of personal data
        </li>
        <li>That are illegal, abusive, defamatory, denigratory, racist, discriminatory (whether for reasons of race,
          religion, gender, nationality, sexual orientation or identity, or anyother personal, physical, political or
          cultural circumstance), misleading, fraudulent or in any way contrary to morality or public order;
        </li>
        <li>That constitute child pornography or bestiality, promote pedophilia or any role akin to it;</li>
        <li>Which may fall into any of the following categories: drugs or incapacitation, rape or brutality, bestiality,
          injury, mutilation or gore, pedophilia, incest, necrophilia. In particular, without limitation, all those
          contents (audiovisual or of any other nature) that include, mention, simulate or promote, both in the content
          in question and in their descriptive texts, any of the following behaviors or 'keywords', in any of their
          variants: abuse, alcohol, asphyxiation, bestiality, brutality, chloroform, drugs, drunkenness or inebriation,
          force, harassment, hypnosis, incapacitation, incest, intoxication, injury, minority, mutilation, necrophilia,
          pedophilia, sedation, rape, unconsciousness, violence;
        </li>
        <li>That contain "spam" and/or links to sites unrelated to the Platform</li>
        <li>That include advertising or commercial communications, for the emission of messages for advertising purposes
          or for the collection of data for the same purpose;
        </li>
      </ol>
      <br>
      <h4>6.3. Code of conduct.</h4>

      <p>For the use of the services available through the Platform, all Users and Content Creators must comply with the
        following basic rules of conduct:</p>

      <ol>
        <li>Verify the legal age of any person appearing in the Content that the Content Creator posts or links to and
          obtain a signed written consent stating the nature of the adult content, and the possibility that it may be
          distributed worldwide. In addition, a copy of the ID or Passport must be kept as evidence of the legal age of
          all participants, along with the dated and signed consent;
        </li>
        <li>Not harass other Users or Content Creators;</li>
        <li>Not impersonate another person or falsify the information available in the profile in order to pretend to be
          another User or Content Creator;
        </li>
        <li>Not to use the Platform for the purpose of having meetings outside the Platform. The User or Content Creator
          who violates the provisions herein assumes a serious physical and/or psychological risk. ARTEMISA disclaims
          any liability if any person decides to engage in such encounters despite these warnings and the inherent risk;
        </li>
        <li>Not to publish private information that would allow someone to identify the location of the User or Content
          Creator or to deduce the way to contact them or their relatives outside the Platform. The purpose of this
          measure is to prevent harassment and even physical encounters with malicious persons.
        </li>
      </ol>
      <br>
      <h4>6.4. Consequences of non-compliance.</h4>

      <p>The User or Content Creator who breaches these prohibitions and/or rules of conduct shall be liable for any
        claims arising as a result thereof. Even if no claim by a third party arises, ARTEMISA reserves the right to
        take appropriate measures under applicable law in the exercise of its rights and obligations. Such measures may
        include, in particular, the suspension of access to the Platform or the possibility of participating in the
        spaces provided therein by the infringing User or Content Creator, who shall not be entitled to claim any
        compensation for damages caused by such suspension. Moreover, some of the above actions are classified as crimes
        under the Spanish Criminal Code, and ARTEMISA will bring any action contrary to the above to the attention of
        the competent authorities, cooperating with them by disclosing the identity of the infringing User or Content
        Creator when so required.</p>
      <p>ARTEMISA cannot control the content posted, shared or linked to by Users and Content Creators on the Platform
        and assumes no responsibility for such content. However, ARTEMISA reserves the right to monitor and/or moderate
        any content posted by Users or Content Creators and, if it violates this Legal Notice or any of the legal texts
        of the Platform, to edit or remove it. To this end, ARTEMISA may, at its discretion, implement technical
        measures or use material or human resources to carry out periodic checks of the content published on the
        Platform both by its Users and by the Content Creators, without this implying an assumption of responsibility by
        ARTEMISA beyond the liability that it is assigned by the regulations in force.</p>
      <p>Likewise, if a User or Content Creator finds any information or content on the Platform that may be unsuitable,
        contrary to current regulations, or contrary to the conditions set forth in the Platform, please inform ARTEMISA
        immediately through any of the different means of contact provided for this purpose.</p>
      <br>

      <h3>7. SUSPENSION OF SERVICE</h3>
      <p>ARTEMISA may suspend at any time and without notice the availability and use of the Platform with respect to
        all (or some) Users or Content Creators, which may involve loss of data or information.</p>
      <p>Particularly, if a User or Content Creator breaches this Legal Notice or any other provision, document or legal
        text applicable under the Platform, ARTEMISA may suspend or restrict that User or Content Creator’s use of the
        Platform, including terminating their assigned User, and such actions will not entitle the affected User or
        Content Creator to any compensation. ARTEMISA may also report and cooperate with law enforcement authorities if
        it detects any violation of applicable law or if it suspects that any User or Content Creator is committing a
        criminal offense.</p>
      <p>The lack of action against a User or Content Creator who breaches any of the provisions set forth in the legal
        texts of the Platform shall not imply in any case a waiver of the rights of ARTEMISA with respect to such
        breach. We will be able to use and exercise our rights and remedies in any other situation in which this Legal
        Notice or any other applicable conditions are breached.</p>
      <br>

      <h3>8. INTELLECTUAL PROPERTY RIGHTS</h3>
      <h4>8.1. Ownership of the Platform.</h4>
      <p>ARTEMISA owns or, where appropriate, has the corresponding licenses on the intellectual property rights of the
        Platform, as well as those corresponding to the information, materials, products and other content of the same,
        including, among others, source code, designs, navigation structure, images, graphics, text, programming codes,
        databases, trademarks, logos and any other distinctive signs and protectable elements that are displayed or used
        in the Platform.</p>
      <p>In no case shall it be understood that access, navigation and use of the Platform by the User or Content
        Creator implies a waiver, transfer, license or total or partial transmission of any intellectual property rights
        by ARTEMISA.</p>
      <p>In this sense, all intellectual property rights on the Platform are reserved and, in particular, it is
        forbidden to modify, copy, reproduce, publicly communicate, transform or distribute, by any means and in any
        form, all or part of it and/or its contents without the prior express written permission of ARTEMISA or, where
        appropriate, the owner of the corresponding rights.</p>
      <p>Based on this Legal Notice, ARTEMISA only grants the User and the Content Creator a limited, non-transferable,
        revocable, non-sublicensable and non-exclusive license to access and use the Platform on the User's device. Any
        rights not expressly granted are reserved by ARTEMISA.</p>
      <p>The User, as well as any third party, shall be restricted in particular, without limitation, from carrying out
        the following actions: (i) decompile or reverse engineer the Platform; (ii) link, mirror or frame any part of
        the Platform and/or its contents; (iii) deploy or launch any type of program to perform any action related to
        data mining or actions tending to damage the operability and functionality of the Platform; and (iv) remove,
        modify or any analogous action tending to alter the rights of ARTEMISA on its trademark/s, the Platform or its
        contents, or those of any third party.</p>
      <p>Likewise, no content of the Platform may be modified, copied, distributed, framed, reproduced, reused,
        downloaded, extracted, displayed, transmitted or sold in any way by any means, in whole or in part, without the
        prior written consent of ARTEMISA or its legitimate owner.</p>
      <p>Additionally, it is forbidden to remove or manipulate any copyright notices or any other credit that identifies
        the owners of the rights to the contents of the Platform, as well as technical protection devices, fingerprints
        or any protection mechanism or information incorporated into the Platform.</p>
      <br>
      <h4>8.2. Rights over Contents</h4>
      <p>Any intellectual property rights (including copyrights and trademark rights, amongst others) and image rights
        on any Contents published by the Content Creators (audiovisual content and photographs, texts or publications
        they make) or by the Users of the Platform (for example, comments) will be owned by them, as appropriate.
        Content Creators and Users, respectively, grant ARTEMISA a license over their respective contents for their
        correct publication and exploitation in the Platform, as well as for their commercial use with purposes of
        promoting the Platform on the Internet, specifically in websites owned by ARTEMISA3000 TECH SOLUTIONS, S.L.
        and/or in third party websites hosted in the latter’s servers. This license will be worldwide and limited to the
        time that the Content Creator or User is registered on the Platform and/or until the content in question is
        removed, either by the Content Creator or User who owns it, or by ARTEMISA as a result of any infringement of
        rights, of any terms applicable to the Platform and/or of the current legislation. In the case of ‘pay-per-view’
        paid Content or Content sold through the Platform’s messaging channel, such Contents will be assigned to the
        User who acquires them and will continue to be available to such User for as long as he remains registered on
        the Platform. In such case, the assignment of rights by the Content Creator is made, in these cases, in
        perpetuity. Notwithstanding the foregoing, the availability of such Content shall be limited to those Users who
        have expressly acquired the same through the channel provided for that purpose and in no case may be used,
        accessed or viewed by the generality of Users of the Platform.</p>
      <p>ARTEMISA does not intend to use the content generated by the Content Creators and its Users for purposes other
        than the effective provision of the service and the promotion of the Platform. In this sense, the Content
        Creator and each User acknowledge and accept that ARTEMISA may use, in whole or in part, (i) the free Content,
        (ii) the Users’ comments, and (iii) the captures and comments associated to the Paid or subscription-based
        Contents, for purposes of promoting the Platform, developed by itself or by third parties as indicated in the
        preceding paragraph.</p>
      <p>To this end, and without limitation, the aforementioned license shall expressly include the hosting of the
        Contents on ARTEMISA’ servers (or those of the third party that ARTEMISA hires for this purpose), as well as for
        its making available on the Platform in accordance with their nature and mode of access that was configured for
        the Content in question.</p>
      <p>In turn, each User or Content Creator shall obtain a limited right to view the contents within the Platform,
        granted by the owner thereof, for as long as they are available in the same. Access to the Platform does not
        entail any assignment of rights over them beyond what is expressly provided for in this paragraph. In
        particular, copying, downloading or any other exploitation of the contents other than viewing within the
        Platform is prohibited.</p>
      <p>Each Content Creator or User acknowledges and warrants to be the owner of all rights to the content posted on
        the Platform, and that such contents are fully compliant with law and do not infringe or impair the rights of
        third parties, including image rights, nor will involve any additional cost and/or expense for ARTEMISA, and
        guarantee that the User or Content Creator in question is entitled to freely dispose of them. Each User and
        Content Creator also guarantees that the content they publish is not confidential or harmful to third
        parties.</p>
      <p>Each Content Creator or User acknowledges that it assumes all liability, holding ARTEMISA harmless, for any
        communication provided personally or on their behalf, with such liability extending without restriction on the
        accuracy, legality, originality and ownership of the same.</p>
      <br>
      <h4>8.3. Notification of infringements</h4>
      <p>If the User suspects or is informed of the existence of any illicit or illegal content, including content that
        could infringe intellectual property rights of third parties, he/she must notify ARTEMISA through the following
        email address in order to take the appropriate measures:<a :href="`mailto:${project.locales.contact_email}`"
                                                                   target="_blank">{{
            project.locales.contact_email
          }}</a>
      </p>
      <p>Likewise, if a User considers that the Platform infringes their own intellectual property rights, or any other
        rights, they must send an email to ARTEMISA at the address indicated above, with the following information:</p>
      <ul>
        <li>identification of the User and, in the case of a legal entity, its representative, including contact
          information;
        </li>
        <li>relevant documentation supporting the request, identifying the holder of the infringed rights;</li>
        <li>detailed list of the rights that have allegedly been infringed by ARTEMISA or by any of the Users, as well
          as their exact location within the Platform;
        </li>
        <li>express and responsible statement that the content has been used without the consent of its owner.</li>
      </ul>
      <br>
      <h4>9. LINKS</h4>
      <p>It is strictly forbidden to establish links to the Platform from other websites or digital channels that
        contain materials, information or illicit, illegal, degrading, obscene and which, in general, contravene the
        laws, morality or public order, or generally accepted social norms.</p>
      <p>ARTEMISA does not have the power or human and technical means to know, control or approve all information,
        content, products or services provided by other websites that have established links to the Platform.</p>
      <p>Therefore, we do not assume any liability for any aspect relating to the websites that establish such a link to
        the Platform; specifically, but not limited to, on its operation, access, data, information, files, quality and
        reliability of its products and services, their own links and/or any of its contents in general.</p>
      <p>In any case, ARTEMISA reserves the right to prohibit links to the Platform and to demand their removal when
        they do not meet the conditions required in this section</p>
      <br>
      <h3>10. RESPONSIBILITIES, LIABILITY AND WARRANTIES</h3>
      <p>The User agrees to comply with their legal and contractual obligations generated by virtue of this document and
        shall be liable for any infringements in which they have personally incurred, holding ARTEMISA harmless against
        any error, fault or negligence not attributable to him/her, as well as for any damages arising from such
        infringements or errors</p>
      <p>ARTEMISA cannot guarantee the reliability, usefulness, timeliness or accuracy of absolutely all information and
        / or services on the Platform, especially those that are provided or published by third parties.</p>
      <p>Consequently, ARTEMISA does not guarantee and shall not be liable for:</p>
      <ol>
        <li>the veracity and continuity of the contents of the Platform;</li>
        <li>the absence of errors in such contents;</li>
        <li>the absence of viruses, trojan horses, worms, logic bombs and/or other malicious or technologically harmful
          components in the Platform or in the server that supplies it;
        </li>
        <li>damages caused to the devices or computer equipment of Users or third parties during the provision of the
          Platform service;
        </li>
        <li>the invulnerability of the Platform and/or the impossibility of violating the security measures adopted
          therein;
        </li>
        <li>the lack of usefulness or performance of the contents of the Platform;</li>
        <li>failures of the Platform caused by any type of attack on its servers or those of third party providers of
          the same, as well as failures or disconnections in telecommunications networks that produce the suspension,
          cancellation or interruption of service during or before its provision;
        </li>
        <li>any technical failure of any kind that hinders, delays or prevents the correct operation of the Platform;
        </li>
        <li>any damage or harm caused to himself or to a third party, by any person who violates the conditions, rules
          and instructions that ARTEMISA establishes in relation to the Platform or through the violation of its
          security systems.
        </li>
      </ol>
      <p>Notwithstanding the above, ARTEMISA has adopted all necessary measures, within our possibilities and the state
        of the art, to ensure the operation of the Platform and minimize system failures, both from a technical
        perspective, as well as in connection with the contents published by us on the Platform.</p>
      <p>In this regard, ARTEMISA cannot guarantee the reliability, legality and usefulness of the contents provided by
        third parties through the Platform, and consequently shall not be liable for them. This also applies to
        information contained on other platforms or websites that are linked from the Platform. We are not liable for
        any damages that may arise from the use of such information. If the User becomes aware of the existence or
        publication of any inaccurate, outdated and false information, or the existence of any content that is illegal,
        illicit, contrary to the law or that could involve an infringement of the rights of third parties (including,
        without limitation, intellectual property rights, the applicable regulations on the protection of personal data
        and/or any other right), the User must immediately notify ARTEMISA through the contact channels provided on the
        Platform so that we can take the appropriate measures.</p>
      <br>
      <h4>11. USER ASSISTANCE</h4>
      <p>For any queries, complaints or suggestions, ARTEMISA offers a user support service to our Users, available
        through the following contact channels:</p>
      <ul>
        <li>Teléfono: +34 91 041 68 23</li>
        <li>E-mail: <a :href="`mailto:${project.locales.contact_email}`"
                       target="_blank">{{ project.locales.contact_email }}</a></li>
      </ul>
      <p>Telephone service is limited to the following schedule:</p>
      <ul>
        <li>M-F: 9:00 - 18:00 CET</li>
      </ul>
      <p>We will make our best efforts to respond to complaints or inquiries received in the shortest possible time.</p>
      <h3>12. CONFIDENTIALITY AND DATA PROTECTION</h3>
      <p>n accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of
        27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the
        free movement of such data and repealing Directive 95/46/EC, and the Spanish Organic Law 3/2018, of 5 December,
        on the Protection of Personal Data and the guarantee of digital rights, all personal data provided by the User
        during their use of the Platform will be processed in accordance with the provisions of the <a href="/privacy"
                                                                                                       target="_blank">Privacy
          Policy</a>, which the User must read and, where appropriate, accept</p>
      <br>
      <h3>13. MISCELLANEOUS</h3>
      <p>The headings of the different clauses are merely informative and do not affect, qualify or encourage the
        interpretation of this Legal Notice.</p>
      <p>All the clauses or matters in this Legal Notice must be interpreted independently. In the event that any clause
        is declared null by judicial or arbitral resolution, the rest of the clauses shall not be affected and will
        remain in full force and effect. The null clause will be replaced by another that preserve the effects pursued
        by this Legal Notice.</p>
      <p>The User acknowledges and agrees that ARTEMISA may modify the terms and conditions set forth herein, in whole
        or in part, at any time. Changes will be posted on the Platform in a manner similar to that in which this Legal
        Notice is available. If you breach the terms of use applicable to the Platform, we reserve the right to suspend
        or restrict your use of the Platform, including termination of your User account without notice, and such
        actions shall not entitle you to any compensation from us or our suppliers.</p>
      <br>
      <h3>14. GOVERNING LAW AND JURISDICTION</h3>
      <p>This Legal Notice, the Platform and the use that Users make of it are governed by the applicable Spanish
        legislation in force at any given time</p>
      <p>However, in case of controversy, action or claim between our Users and us, the current regulations will
        determine the laws that govern the relationship between the parties and the corresponding valid jurisdiction. In
        this regard, provided that such regulations provide for the possibility of submitting to a particular
        jurisdiction, for any dispute, controversy, action or claim related to the services we provide through the
        Platform, Spanish law shall be applicable at the time of the dispute, and the Courts of the city of Madrid shall
        hear such matter, expressly and voluntarily waiving any other jurisdiction, unless otherwise provided by
        mandatory legal provision.</p>
      <p>To file complaints regarding the use of our Platform, the User may contact us by mail at the email or physical
        address indicated in this Legal Notice for this purpose. We undertake to seek at all times an amicable solution
        in case of conflict.</p>
      <br>
  </section>
</template>

<script>
import project from '@/project'

export default {
  name: "ES",
  data() {
    return {
      project
    }
  }

}
</script>

<style scoped>

</style>
