<template>
  <div>
    <div class="info-chat">
      <span class="date">{{ dateSend }}</span>
      <span v-if="isAuthor" class="check" :class="classIsRead"
        >{{ statusCheckSend }}{{ statusCheckReceived }}</span
      >
      <IconButton
        v-if="!isAuthor && getPreferencesTranslate.translate"
        :size="10"
        type="translate"
        class="translate"
        :disabled="true"
      />
      <IconButton
        v-if="(isInfluencer || isController) && isAuthor"
        :size="10"
        type="delete"
        @handler="clearMessage"
      />
    </div>
    <WarningDialog
      v-if="(isInfluencer || isController) && isAuthor"
      :show="showClearMessage"
      :textWarning="$t('chats.deleteMessage')"
      :textButtonOK="$t('actions.delete').toUpperCase()"
      @handlerButtonCancel="clearMessage"
      @handlerButtonOK="deleteMessage"
    />
    <WarningDialog
      :show="showErrorMessage"
      :textWarning="errorDeleteMessage"
      @handlerButtonCancel="closeErrorMessage"
      :hidenButton="true"
    />
  </div>
</template>
<script>
import utils from '@/utils'
import { mapState, mapGetters } from 'vuex'
import IconButton from '../component/IconButton.vue'
import WarningDialog from '../component/WarningDialog.vue'
import ConversationsApi from '@/api/conversations/ConversationsApi'

export default {
  name: 'InfoDateMessageRow',
  components: {
    IconButton,
    WarningDialog
  },
  props: {
    message: { type: Object },
    statusIsRead: { type: Boolean, default: false }
  },

  data () {
    return {
      hours: '09:31',
      date: '',
      statusCheckSend: '✓',
      statusCheckReceived: '✓',
      showClearMessage: false,
      showErrorMessage: false
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer', 'isController']),
    ...mapState('chats', ['selectedChat']),
    ...mapGetters('chats', ['getPreferencesTranslate']),
    isAuthor () {
      return this.currentUser.id === this.message.userId
    },
    errorDeleteMessage () {
      const user = this.selectedChat.receiver.username
      return this.$t('chats.errorDeleteMessage', { user: user })
    },
    isRead () {
      return this.statusIsRead
    },
    classIsRead () {
      return { read: this.isRead }
    },
    dateSend () {
      const createdAt = this.message.createdAt
      const result = this.$datetime(createdAt)
      return result.substring(0, result.length - 3)
    },
    hoursSend () {
      return utils.formatDateHours(this.message.createdAt, 'HH:MM')
    }
  },

  methods: {
    clearMessage () {
      this.showClearMessage = !this.showClearMessage
    },
    closeErrorMessage () {
      this.showErrorMessage = !this.showErrorMessage
    },
    async deleteMessage () {
      const conversationId = this.selectedChat.id
      const conversationMessageId = this.message.id
      const { data, error } = await ConversationsApi.deleteMessage(
        conversationId,
        conversationMessageId
      )
      if (!error) {
        this.$emit('successDelete', this.message)
      } else {
        this.showErrorMessage = !this.showErrorMessage
      }
      this.showClearMessage = !this.showClearMessage
    },
    destructuringDate () {
      this.date = new Date(this.message.createdAt).toLocaleDateString()
      this.hours = `${new Date(this.message.createdAt).getHours()}:${new Date(
        this.message.createdAt
      ).getMinutes()}`
    }
  }
}
</script>
<style lang="scss" scoped>
.info-chat {
  display: flex;
  justify-content: flex-end;
}
.date {
  font-size: 0.7em;
  margin-left: 5px;
  color: grey;
}
.check {
  font-size: 0.7em;
  margin-left: 5px;
  font-weight: bold;
}
.read {
  color: #31ae00;
}
.translate {
  filter: invert(39%) sepia(8%) saturate(6%) hue-rotate(18deg) brightness(96%)
    contrast(88%);
}
</style>
