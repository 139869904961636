<template>
  <div v-if="showContract">
    <v-row>
      <v-col class="ml-3">
        <h2 class="title-text semibold ">{{ $t('application.contract.title') }}</h2>
        <p v-if="currentUser.signedDocument">{{
            $t('application.contract.download_text_creators', {date: $date(new Date(currentUser.signedDocument.createdAt))})
          }}</p>
        <p v-else>{{ $t('application.contract.text_cretors') }}</p>
      </v-col>
    </v-row>
    <v-row class="justify-end mr-4">
      <c-btn v-if="currentUser.signedDocument" @click="downloadSignedContract">
        {{ $t('actions.download') }}
      </c-btn>
      <c-btn v-else @click="singDocument">
        {{ $t('actions.sign') }}
      </c-btn>
    </v-row>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import UserApi from '@/api/UserApi'
import {saveAs} from 'file-saver'

export default {
  name: 'PanelCreators',
  data() {
    return {
      downloadFile: null
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer', 'promotion']),
    isPending() {
      return this.promotion.status === 'pending'
    },
    showContract() {
      return this.isInfluencer || this.isPending
    }
  },
  async mounted() {
    if (this.currentUser.signedDocument) {
      const {
        data,
        error
      } = await UserApi.signedDocument()
      if (!error) {
        this.downloadFile = data.documentPath
      }
    }
  },
  methods: {
    downloadSignedContract() {
      saveAs(this.downloadFile)
    },
    singDocument() {
      this.$router.push('/contract')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
