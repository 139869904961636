<template>
  <div class="file-input"
    v-bind="$attrs"
    @click.stop="click">
    <template v-if="!isButton">
      <div class="file-input-wrapper d-flex align-center bordered rounded">
        <div v-if="preview"
          class="file-input-preview rounded">
          <c-img
            :height="previewSize"
            :width="previewSize"
            :src="imagePreview">
          </c-img>
        </div>
        <div class="file-input-label">
          <span v-if="fileNames">{{ fileNames }}</span>
          <slot v-else>{{ labelText }}</slot>
        </div>
        <div class="file-input-action">
          <c-btn v-if="files.length"
            icon
            color="secondary"
            @click.stop="clear">
            <v-icon>delete_forever</v-icon>
          </c-btn>
        </div>
      </div>
      <div v-if="uploadingFile.loading" class="mt-1">
        <v-progress-linear :value="uploadingFile.progress" color="indigo"></v-progress-linear>
      </div>
    </template>
    <c-btn v-else
      :icon="icon"
      :color="color"
      v-bind="$attrs"
      @click.stop="click">
      <slot>{{ labelText }}</slot>
    </c-btn>
    <div class="small-text w-100"
      :class="{ 'primary--text': error }">{{ hint }}</div>
    <v-file-input class="d-none"
      ref="inputFile"
      type="file"
      :accept="contentType"
      :multiple="maxFilesNumber > 1"
      :rules="rules"
      @change="filesSelected" />
  </div>
</template>
<script>
import FileManager from '@/components/mixins/file_manager'

export default {
  name: 'FileInput',
  mixins: [FileManager],
  props: {
    icon: { type: Boolean, default: false },
    button: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    color: { type: String, default: 'secondary' },
    label: { type: String },
    hint: { type: String },
    error: { type: Boolean },
    autoupload: { type: Boolean, default: false },
    preview: { type: Boolean, default: true },
    contentType: { type: String, default: 'image/*' },
    maxFiles: { type: [Number, String], default: 1 },
    onlyVideo: { type: Boolean, default: false },
    rules: Array
  },

  data: () => ({
    manageAutoupload: false,
    maxFilesNumber: null,
    imagePreview: null
  }),

  computed: {
    isButton () {
      return this.icon || this.button
    },
    fileNames () {
      return this.files.map(f => f.key).join(', ')
    },
    previewSize () {
      return this.dense ? 38 : 54
    },
    uploadedErrors () {
      return this.files.filter(f => f.uploaded).length !== this.files.length
    },
    labelText () {
      return this.label || this.$t('labels.file_input')
    },
    uploadingFile () {
      return this.files.find(f => f.loading) || {}
    }
  },

  methods: {
    click () {
      this.$refs.inputFile.$refs.input.click()
    },
    async filesSelected (files) {
      if (!files || !files.length) {
        return this.clear()
      }
      const maxFiles = parseInt(this.maxFiles, 10)
      if (maxFiles === 1 && this.files.length === 1) {
        this.clear()
      }
      await this.addFiles(files)
    },

    beforeAddFile (file) {
      this.errorMessage = ''
      if (this.contentType !== '*' && !file.isMedia()) {
        this.errorMessage = this.$t('errors.mime_type_not_valid', { file: file.key })
      }
      if (this.onlyVideo && !file.isVideo()) {
        this.errorMessage = this.$t('errors.only_video')
      }
      return !this.errorMessage
    },

    onFileAdded (file) {
      this.imagePreview = file.preview
      if (this.manageAutoupload || this.autoupload) {
        this.uploadFile(file)
      }
    },

    onTooManyFiles () {
      this.$emit('update:error', true)
      this.$emit('update:error-message', this.$t('errors.too_many_files', { max: this.maxFiles }))
    },

    onFileTooLarge (file) {

    },

    onUploadFinished () {
      this.$forceUpdate(this.files)
    }
  },
  mounted () {
    this.maxFilesNumber = parseInt(this.maxFiles)
    // if maxFiles == 1 and filename is given
    // we manage here the autoupload
    if (this.maxFilesNumber === 1) {
      this.manageAutoupload = true
    }
  }
}
</script>
<style lang="scss">
.file-input {
  display: flex;
  align-items: center;

  .file-input-wrapper {
    height: 56px;
    max-height: 56px;
    min-height: 56px;
    cursor: pointer;

    .file-input-preview {
      height: 54px;
      width: 54px;
    }
    .file-input-label {
      flex: 1 1 100%;
      min-width: 85px;
      padding: 0 8px;
    }
  }

  .file-input-action {
    height: 36px;
    width: 36px;
  }

  &.dense {
    .file-input-wrapper {
      height: 40px;
      max-height: 40px;
      min-height: 40px;
      .file-input-preview {
        height: 38px;
        width: 38px;
      }
    }
  }
}
</style>
