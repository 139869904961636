<template>
  <section class="avatar">
    <div class="col-auto mr-3 avatarChat center">
      <div class="status" :class="classIsOnline" />
      <img :src="getSrc" :height="height" :width="width" />
    </div>
    <div class="row details">
      <span class="user truncatable">
        <div class="name truncate" :class="{ dark: $vuetify.theme.dark }" style="align-items: center; display: flex;">
          <span :class="{ 'light-text': $vuetify.theme.dark }">{{ username }}</span>
          <img style="margin-left:1px" v-if="trustedProfile" src="/images/check5.svg" height="12"/>
        </div>
        <div class="username truncate lightest-text">
          <span :class="{ 'light-text': $vuetify.theme.dark }">{{ subText }}</span>
        </div>
      </span>
    </div>
  </section>
</template>

<script>

export default {
  name: 'AvatarUser',
  props: {
    avatar: String,
    username: { type: String, default: '' },
    trustedProfile: { type: Boolean, default: false },
    height: { type: Number, default: 34 },
    width: { type: Number, default: 34 },
    subText: { type: String, default: ' ' },
    onlineStatus: { type: String }
  },
  computed: {

    getSrc () {
      return this.avatar || '/images/no_content.png'
    },
    classIsOnline () {
      return {
        online: this.onlineStatus === 'online',
        offline: this.onlineStatus === 'offline',
        ausent: this.onlineStatus === 'absent'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
  align-items: center;
}
.name.truncate span {
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #333333 !important;
  &.light-text {
    color: #c3c3c3 !important;
  }
}
.status {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  top: -12px;
  left: 8px;
}
.online {
  border: #00ff00 solid 0.2px;
  background-color: #00ff00;
}
.ausent {
  border: #00ff00 solid 0.2px;
  background-color: white;
}
.offline {
  border: transparent solid 0.2px;
  background-color: transparent;
}
.username.truncate.lightest-text span {
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  color: #333333;
  &.light-text {
    color: #c3c3c3 !important;
  }
}

span.name {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: black;
  &.dark {
    color: #c5c5c5;
    &.noActive {
      color: #a9a9a9 !important;
    }
  }
}

span.messageText {
  color: black;
  &.dark {
    color: #c5c5c5;
    &.noActive {
      color: #a9a9a9 !important;
    }
  }
}
.avatarChat {
  max-height: -webkit-fill-available;
  vertical-align: middle;
  img {
    border-radius: 20px;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
  }
}

.truncate {
  max-width: 180px;
}
.avatar {
  display: flex;
}
.avatar:hover {
  cursor: pointer;
}
</style>
