<template>
  <section>
    <div class="create-list">
      <div class="form-input-text">
        <p>{{ $t("application.chat_setting.userList.row.name") }}</p>
        <input
          type="text"
          placeholder=""
          class="input-text"
          v-model="nameList"
          :class="darkTheme"
        />
      </div>
      <InputSearchUser
        @listUser="listUser"
        :reset="resetList"
        :defaultContent="defaultContent"
        :widthRem="2"
      />
      <div :class="classButonsEdit">
        <Buttons
          :text="$t('actions.save')"
          :loading="loadingButton"
          :disabled="isDidabled"
          @handler="saveListUser"
        />
        <Buttons
          v-if="editList"
          :text="$t('actions.cancel')"
          close
          @handler="cancelEditList"
        />
      </div>
    </div>
  </section>
</template>

<script>
import InputSearchUser from "@/components/chat/component/InputSearchUser";
import InputText from "@/components/chat/component/InputText";
import ConversationsApi from "@/api/conversations/ConversationsApi";
import Buttons from "@/components/chat/component/Buttons";
import { AppActions } from "@/store";

export default {
  name: "CreateListUser",
  components: { InputSearchUser, Buttons, InputText },
  props: {
    isEdit: { type: Boolean, default: false },
    editList: { type: Object }
  },
  data() {
    return {
      nameList: "",
      listUserCreate: [],
      loadingButton: false,
      defaultContent: []
    };
  },
  mounted() {
    if (this.isEdit) {
      this.nameList = this.editList.name;
      this.defaultContent = this.editList.users;
      this.listUser(this.editList.users);
    }
  },
  computed: {
    isDidabled() {
      return !this.nameList !== "" && !this.listUserCreate.length > 0;
    },
    darkTheme(){
      return {dark: this.$vuetify.theme.dark}
    },
    resetList() {
      return (
        this.listUserCreate.length === 0 && this.defaultContent.length === 0
      );
    },
    classButonsEdit() {
      return {
        "footer-botton": this.editList
      };
    }
  },
  methods: {
    listUser(listUser) {
      this.listUserCreate = listUser;
    },
    onNewMessage(sms) {
      this.nameList = sms;
    },
    async saveListUser() {
      this.loadingButton = true;
      const body = {
        name: this.nameList,
        users: this.listUserCreate.map(i => i.name)
      };

      if (this.isEdit) {
        const id = this.editList.id;
        const { data, error } = await ConversationsApi.updateList(id, body);
        if (!error) {
          this.reset();
          this.$store.dispatch(AppActions.SetNotification, { type: "success" });
        } else {
          this.$store.dispatch(AppActions.SetNotification, {
            type: "error",
            error: data
          });
        }
      } else {
        const { data, error } = await ConversationsApi.createList(body);
        if (!error) {
          this.reset();
          this.$store.dispatch(AppActions.SetNotification, { type: "success" });
        } else {
          this.$store.dispatch(AppActions.SetNotification, {
            type: "error",
            error: data
          });
        }
      }
      this.$store.dispatch(AppActions.ShowNotification, true);
      this.loadingButton = false;
    },
    cancelEditList() {
      this.$emit("cancel-edit-list");
    },
    reset() {
      this.nameList = "";
      this.listUserCreate = [];
      this.defaultContent = [];
      this.loadingButton = false;
      this.$emit("removeDefaultContent");
    }
  }
};
</script>

<style lang="scss" scoped>
.create-list {
  padding-top: 10px;
}

.form-input-text {
  display: flex;
  align-content: flex-start;
  justify-content: center;
  padding: 10px 0;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 7px;
  p {
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
  .input-text {
    width: 96%;
    display: inline-block;
    border: 1px solid #d7d7d7;
    height: 30px;
    padding: 7px 15px;
    margin: 4px;
    font-size: 0.9375em;
    border-radius: 30px;
    background: white;
    &.dark{
      background-color: #272727 !important;
    }
  }
}
.footer-botton {
  display: flex;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
}
</style>
