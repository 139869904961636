class LoverfansWebsocket {
  constructor (url) {
    this.url = url
    this.handlers = { }
  }

  connect (token) {
    this.disconnect()
    this.token = token
    this.ws = new WebSocket(this.url)
    this.ws.addEventListener('open', () => {
      this.send('subscribe', { token })
    })
    this.ws.addEventListener('message', (event) => {
      if (event.data) {
        this.handleMessage(JSON.parse(event.data))
      }
    })
    this.ws.addEventListener('close', (event) => {
      clearInterval(this.pingInterval)
      if (event.code !== 1000) {
        this.reconnect()
      }
    })

    this.pingInterval = setInterval(() => {
      this.send('ping', { timestamp: new Date().getTime() })
    }, 60000)
  }

  reconnect () {
    this.connect(this.token)
  }

  disconnect () {
    if (this.ws) {
      this.ws.close(1000)
      this.ws = null
    }
  }

  send (action, message) {
    if (!this.ws) {
      return
    }
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      return
    }
    this.ws.send(JSON.stringify({
      action,
      data: message || {}
    }))
  }

  subscribe (event, handler) {
    let eventHandlers = this.handlers[event]
    if (!eventHandlers) {
      eventHandlers = []
    }
    eventHandlers.push(handler)
    this.handlers[event] = eventHandlers
    return () => {
      this.unsubscribe(event, handler)
    }
  }

  unsubscribe (event, handler) {
    if (!this.handlers[event]) {
      return
    }

    const filtered = this.handlers[event].filter(h => h !== handler)
    this.handlers[event] = filtered
  }

  clear () {
    this.handlers = {}
  }

  handleMessage (data) {
    if (!data.action || !this.handlers[data.action]) {
      return
    }

    this.handlers[data.action].forEach(handler => handler(data.data))
  }
}

export default LoverfansWebsocket
