<template>
  <div id="modal2" class="fondo-trans" style="display: contents;overflow-y: hidden !important;">
    <div class="popup sombra redondo" style="max-width: 1000px;" :class="cssClass">

      <!-- Modal2 -->
      <v-btn v-if="!isInfluencer" class="close-btn" dark style="position: absolute; z-index: 10;" icon @click="goTo()"><v-icon size="14">close</v-icon></v-btn>
      <v-btn v-else class="close-btn" dark style="position: absolute; z-index: 10;" icon @click="goToResource()"><v-icon size="14">close</v-icon></v-btn>

      <div class="cont-modal2" v-if="isFollower || isAdmin || isAccountManager">
        <div class="adorno-calabaza">
          <img style="float: right; width: 300px;height:300px" class="img-responsive" src="images/promotions/calabaza-entera.svg">
        </div>
        <div class="adorno-murcielago">
          <img class="img-responsive" style="width: 300px;height:300px" src="images/promotions/murcielago.svg">
        </div>

        <div class="caja-borde">
          <div class="posicion-boton center" style="z-index: 2">
            <a @click="goTo()" class="boton negro boton-fix" style="font-family: Montserrat, sans-serif !important; font-weight: bold;" :class="{'l-size': !isMobile, 'm-size': isMobile }"> {{ $t(`actions.continue`)}} </a>
          </div>

          <v-col cols="12" class="text-center" style="height: 300px" v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>

          <div v-else class="zona-ficha" :style="{margin: isMobile ? '10px' : '25px'}">

            <!-- Posicion ficha en escritorio y tableta	 -->
            <div v-if="!isMobile" class="ficha-chica-caja center centrado" style="height: 270px" :style="cssStyle">
              <router-link :to="{name: 'influencer', params: {username: influencer.username}}">
                <div class="caja-enlinea2 full center centrado" v-if="influencer.onlineStatus !== 'offline'">
                  <div class="online-punto"></div>
                </div>

                <div class="caja-ficha-chica-foto">
                  <img class="ficha-chica-foto" :src="influencer.avatarS3Route || '/images/no_content.png'" style="object-fit: cover; height: 150px; width: 150px;">
                </div>

                <div class="caja-textos-chica fuente-destacada " style="top: 120px;">
                  <div class="separa xs-size" v-if="!isMobile"></div>
                  <span class="caja-nombre-chica blanco">{{ influencer.displayName }}</span><br>
                  <div class="separa xs-size "></div>
                  <span class="caja-nick-chica">{{ `@${influencer.username}` }}</span>
                  <div class="separa xs-size " v-if="influencer.onlineStatus !== 'offline'"></div>
                  <v-btn class="boton-videollamada fuente-destacada" color="#99f5db" small v-if="influencer.onlineStatus !== 'offline'" :to="{ name: 'messages', query: { username: influencer.username, call: true} }">
                    <img class="icono-boton-videollamada" src="/chat/ico-video-call.svg">
                    <p>Videocall</p>
                  </v-btn>
                </div>
                <div class="degradadooscuro"></div>
              </router-link>
            </div>

            <div class="head-contmodal destacado" style="text-align: center">
              <div class="size-logo ">
                <img src="/images/logo_black_white.svg" class="img-responsive" style="margin: 0 auto; ">
              </div>

              <div class="separa xs-size"></div>

              <p class="center fuente-destacada ajust-font3" :style="{fontSize: isMobile ? '2.5vh' : '2.5em'}" style="color: #000000; font-weight: 600; font-family: Montserrat; line-height: 1.4em; text-transform: uppercase;"><strong><span class="blanco">{{ $t('event.halloween.title1')}}</span> <br>{{ $t('event.halloween.desc1') }}</strong></p>

              <div class="separa xs-size"></div>
              <!-- Posicion ficha en móvil	 -->
              <div v-if="isMobile" class="ficha-chica-caja center centrado telefono" :style="{height: influencer.onlineStatus !== 'offline' ? '220px' : '150px'}" style="margin: 0 auto; margin-top: 50px">
                <div class="caja-enlinea2 full center centrado" v-if="influencer.onlineStatus !== 'offline'">
                  <div class="online-punto"></div>
                </div>

                <div class="caja-ficha-chica-foto">
                  <img class="ficha-chica-foto" style="object-fit: cover; height: 100px; width: 100px;" :src="influencer.avatarS3Route || '/images/no_content.png'">
                </div>

                <div class="caja-textos-chica fuente-destacada " style="top: 70px;">
                  <span class="caja-nombre-chica blanco">{{ influencer.displayName }}</span><br>
                  <div class="separa xs-size "></div>
                  <span class="caja-nick-chica">@{{ influencer.username }}</span>
                  <div class="separa xs-size "></div>
                  <v-btn  class="boton-videollamada fuente-destacada" color="#99f5db" small v-if="influencer.onlineStatus !== 'offline' && influencer.videochatEnabled" :to="{ name: 'messages', query: { username: influencer.username, call: true} }">
                    <img class="icono-boton-videollamada" src="/chat/ico-video-call.svg">
                    <p>Videocall</p>
                  </v-btn>
                  <div class="separa l-size"></div>
                </div>
                <div class="degradadooscuro"></div>
              </div>

              <p class="center fuente-destacada  ajust-font4 blanco" style="line-height: 1.2em; font-weight: 500; display: block;" :style="{fontSize: isMobile ? '2.5vh' : '2.5em'}"><span class="negro">{{ $t('event.reload') }}</span> <span style="white-space: nowrap;" class="blanco">{{ $t('event.percent') }}</span></p>
              <div class="separa l-size"></div>

            </div>

          </div>
        </div>
      </div>

      <div class="cont-modal3 relative" style="background-color: #F25801" v-if="isInfluencer">
        <div class="separa xs-size"></div>
        <div class="caja-borde2" style="position: relative">

          <div class="adorno-telarana">
            <img class="img-responsive" src="images/promotions/telarana.svg">
          </div>

          <div class="adorno-fantasma">
            <img class="img-responsive" src="images/promotions/fantasma.svg" width="100%" height="100%">
          </div>
          <div class="separa telefono"></div>
          <div class="head-contmodal destacado" style="text-align: center; position: relative;">
            <div class="size-logo2">
              <img src="/images/logo_black_white.svg" class="img-responsive" style="margin: 0 auto; ">
            </div>

            <div class="separa"></div>


            <p class="center fuente-destacada ajust-font5" :style="{fontSize: isMobile ? '4vh' : '5vh'}" style="color: #000000; font-weight: 600; font-family: Montserrat;"><strong>{{ $t('event.halloween.title2') }}<br><span class="blanco">HALLOWEEN!</span></strong></p>

            <div class="separa"></div>
            <p class="center fuente-destacada  ajust-font4 blanco" :style="{fontSize: isMobile ? '3vh' : '2.1em'}" style="line-height: 1.2em; font-weight: 500"><span class="negro">{{ $t('event.halloween.desc2') }}<br><span style="font-size: 2.5vh">{{ $t('event.halloween.desc3') }}</span></span></p>

            <div class="separa"></div>
            <div class="full center centrado" style="position: relative;">
              <a @click="goToResource()" class="boton negro fuente-destacada" style="font-family: Montserrat, sans-serif !important; font-weight: bold;" :class="{'l-size': !isMobile, 'm-size': isMobile }"> {{ $t(`actions.continue`)}} </a>
            </div>

          </div>
        </div>

        <div class="separa xs-size"></div>
      </div>

        <!--
        <v-img style="margin-bottom: 15px;margin-left: auto; margin-right: auto;" :src="isLoverfans ? 'images/landing/logo-verano-white.svg' : 'images/landing/logo-verano.svg'" max-width="40%"></v-img>

        <div class="head-contmodal destacado" style="width: 95%; margin: auto; background-color: rgba(0,0,0,0.2);" v-if="isInfluencer">
          <p class="titulo-big blanco center fuente-destacada2" :style="cssTitle" v-html="$t(`modal.videochat.creator.title`)"></p>
          <p class="titulo-big blanco center fuente-destacada1" :style="cssTitle" v-html="$t(`modal.videochat.creator.title2`)"></p>
        </div>
        <div class="head-contmodal destacado" style="width: 95%; margin: auto; background-color: rgba(0,0,0,0.2);" v-else>
          <p class="titulo-big blanco center fuente-destacada1" :style="cssTitle" v-html="$t(`modal.videochat.follower.title`)"></p>
        </div>
        <div class="contenedor full black-text">
          <div class="head-contmodal destacado" v-if="isInfluencer">
            <p class="subtitulo-big2  center fuente-destacada2  ajust-font3">{{$t(`modal.videochat.creator.desc1`)}}</p>
            <p class="subtitulo-big2  center fuente-destacada2  ajust-font3">{{$t(`modal.videochat.creator.desc2`)}}</p>
          </div>
          <div class="head-contmodal destacado" v-else>
            <p class="subtitulo-big2  center fuente-destacada1  ajust-font3">{{$t(`modal.videochat.follower.desc`)}}</p>
          </div>

          <div class="full center" style="display: flex; justify-content: space-around;margin-bottom: 20px !important;" v-if="!isInfluencer">
            <div class="relative">
              <h3 class="tit-seccion blanco fuente-destacada full center" style="font-size: 1.5em; color: white !important;"><strong style=" font-family: 'Fjalla One', sans-serif !important;">{{$t(`modal.timerLeft`)}}</strong></h3>
              <div class="separa xs-size escritorio"></div>

              <vue-countdown-timer
                :day-txt="'days'"
                :hour-txt="'hours'"
                :interval="1000"
                :minutes-txt="'minutes'"
                :seconds-txt="'seconds'"
                :start-time="'2022-10-26 00:00:00'"
                :end-time="'2022-11-02 23:59:59'"
                label-position="begin"
                @end_callback="$emit('close')">

                <template slot="countdown" slot-scope="scope" >
                  <div class="countdown-box" style="justify-content: center;">
                    <div class="countdown-box-item fuente-destacada">
                      <div class="countdown-box-numero" style="font-weight: bold">{{scope.props.days}}</div>
                      <div class="countdown-box-titulo" style="font-weight: bold">{{ scope.props.days === 1 ? $t('types.date.day') : $t('types.date.days') }}</div>
                    </div>
                    <div class="countdown-box-item fuente-destacada">
                      <div class="countdown-box-numero" style="font-weight: bold">{{scope.props.hours}}</div>
                      <div class="countdown-box-titulo" style="font-weight: bold">{{ scope.props.hours === 1 ? $t('types.date.hour') : $t('types.date.hours') }}</div>
                    </div>
                    <div class="countdown-box-item fuente-destacada">
                      <div class="countdown-box-numero" style="font-weight: bold">{{ scope.props.minutes }}</div>
                      <div class="countdown-box-titulo" style="font-weight: bold">{{ scope.props.minutes === 1 ? $t('types.date.minute') : $t('types.date.minutes') }}</div>
                    </div>
                    <div class="countdown-box-item fuente-destacada">
                      <div class="countdown-box-numero" style="font-weight: bold">{{ scope.props.seconds }}</div>
                      <div class="countdown-box-titulo" style="font-weight: bold">{{ scope.props.seconds === 1 ? $t('types.date.second') : $t('types.date.seconds') }}</div>
                    </div>

                  </div>

                </template>

                <template slot="end-text" slot-scope="scope">
                  <div class="countdown-box" style="justify-content: center;">
                    <div class="countdown-box-item fuente-destacada">
                      <div class="countdown-box-numero"> 00 </div>
                      <div class="countdown-box-titulo">{{ scope.props.days === 1 ? $t('types.date.day') : $t('types.date.days') }}</div>
                    </div>
                    <div class="countdown-box-item fuente-destacada">
                      <div class="countdown-box-numero"> 00 </div>
                      <div class="countdown-box-titulo">{{ scope.props.hours === 1 ? $t('types.date.hour') : $t('types.date.hours') }}</div>
                    </div>
                    <div class="countdown-box-item fuente-destacada">
                      <div class="countdown-box-numero"> 00 </div>
                      <div class="countdown-box-titulo">{{ scope.props.minutes === 1 ? $t('types.date.minute') : $t('types.date.minutes') }}</div>
                    </div>
                    <div class="countdown-box-item fuente-destacada">
                      <div class="countdown-box-numero"> 00 </div>
                      <div class="countdown-box-titulo">{{ scope.props.seconds === 1 ? $t('types.date.second') : $t('types.date.seconds') }}</div>
                    </div>
                  </div>
                </template>
              </vue-countdown-timer>
            </div>
          </div>
          <div class="separa xs-size escritorio"></div>

          -->

          <!--
          <v-row style="margin-top: 20px;">
            <v-spacer></v-spacer>
            <v-col cols="auto" style="margin-right: 10px;">
              <v-checkbox dark :label="$t('modal.not_show_again')" color="white" @change="notShow()"><p>No mostrar otra vez</p></v-checkbox>
            </v-col>
          </v-row>
          -->
      </div>
    </div>
</template>

<script>
import { ChatActions } from '@/store'
import { mapState } from 'vuex'
import UserApi from '@/api/UserApi'
import project from '@/project'
import cookies from '@/cookies'

export default {
  name: 'PromoModal',
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('preferences', ['darkTheme']),
    ...mapState('profile', ['isFollower', 'isInfluencer', 'isAdmin', 'isAccountManager']),
    query () {
      return {
        order: ['active:true'],
        role: 'influencer',
        includeSelf: false,
        includeTags: true,
        ...this.$store.getters['preferences/selected_tags_ids'],
        darkfans: project.project === 'darkfans',
        limit: 20
      }
    },
    cssClass () {
      return {
        'bg-loverfans': this.isLoverfans,
        'bg-scatbook': this.isScatbook,
        'bg-darkfans': this.isDarkfans
      }
    },
    cssStyle () {
      return {
        backgroundImage: `url(${this.influencer.avatarS3Route || '/images/no_content.png'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }
    },
    cssTitle () {
      const color = project.project === 'loverfans' ? '#F71953 !important' : (project.project === 'scatbook' ? '#ff7605 !important' : '#ff0000 !important')
      return {
        color,
        fontFamily: 'Anton, sans-serif'
      }
    },
    telegramGroup () {
      return this.isLoverfans ? 'https://t.me/loverfans_creators' : 'https://t.me/joinchat/Wv13jxPxhQ5hNDJk'
    },
    getRole () {
      return this.isInfluencer ? 'star' : 'follower'
    },
    isLoverfans () {
      return this.project.project === 'loverfans'
    },
    isScatbook () {
      return this.project.project === 'scatbook'
    },
    isDarkfans () {
      return this.project.project === 'darkfans'
    }
  },
  data () {
    return {
      influencer: {},
      loading: false,
      project
    }
  },
  methods: {
    notShow () {
      cookies.set('notShowVideoChatPromo', true)
      this.$emit('close')
    },
    goTo () {
      this.$emit('close')
      const url = `https://${project.project}.com/promos/halloween`
      window.open(url, '_blank').focus()
    },
    goToResource () {
      this.$emit('close')
      this.$router.push({ name: 'resources' })
    },
    videoCall () {
      this.$router.push({
        name: 'messages',
        query: { username: this.influencer.username, call: true }
      })
      this.$store.dispatch(ChatActions.ShowVideoChat, true)
    }
  },
  async mounted () {
    this.loading = true
    const { data, error } = await UserApi.listActives(this.query)
    if (!error) {
      const random = Math.floor(Math.random() * data.data.length)
      this.influencer = data.data[random]
    }
    this.loading = false
  }
}
</script>

<style scoped>
/*Modal0*/

a {
  color: #333333;
}

a:hover, a:focus {
  color: #000000;
}

acronym, abbr {
  border-bottom:1px dotted #111;
}

hr {
  background: #dddddd;
}

blockquote {
  background-color: rgba(0,0,0,0.03);
  border-left:2px solid #cccccc;
}

.sombra{
  -webkit-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
  -moz-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
  box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
}

::selection {
  background:#c80000;
  color:#fff;
}

::-moz-selection {
  background:#c80000;
  color:#fff;
}

.header-modal.login .cerrar-modal a {
  color:#fff;
}

.header-modal.login + .content-modal {
  background-color:#000;
}

.nav-tabs .nav-link {
  border-top-left-radius:.25rem;
  border-top-right-radius:.25rem;
}

.nav-tabs .nav-link:focus,.nav-tabs .nav-link:hover {
  border-color:#e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color:#6c757d;
}

.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active {
  color:#495057;
  background-color:#fff;
  border-color:#dee2e6 #dee2e6 #fff;
}

.nav-pills .nav-link:hover {
  background-color:rgba(0,0,0,0.03)
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link {
  color:#fff;
  background-color:#000
}

.zona-full-tabs > .nav-tabs > .nav-item > .nav-link:focus, .zona-full-tabs > .nav-tabs > .nav-item > .nav-link:hover {
  border:1px solid #dee2e6;
  border-bottom:2px solid #dee2e6;
}

.zona-full-tabs > .nav-tabs > .nav-item > .nav-link.active {
  color:#000;
  background-color:#fff;
  border-bottom:2px solid #000;
}

.nav-tabs-raya .nav-item {
  color:#343a40;
}

.nav-tabs-raya .nav-item .nav-link {
  background-color:transparent
}

.nav-tabs-raya .nav-item .nav-link::after {
  background:#000;
  height:2px;
}

.nav-tabs-raya .nav-item .nav-link.active {
  color:#000;
}

.boton:hover, .boton:focus {
  color: #ffffff !important;
  background-color: #1c1c1c !important;
}

.boton.default:hover {
  color:#333;
  background-color:#e6e6e6;
  border-color:#adadad;
}

.boton.colordest, .boton.colordest:focus {
  background-color: #c80000 !important;
  color: #ffffff !important;
}

.boton.colordest:hover, .boton.colordest:focus {
  color:#fff !important;
  background-color: #1c1c1c !important;
}

.boton.colordest a {
  color:inherit;
}

.boton.invert, .boton.invert:focus {
  color: #ffffff;
  background-color: #1c1c1c;
}

.boton.invert:hover, .boton.invert:focus {
  color: #ffffff;
  background:#c80000;
}

.boton.invert a {
  color:#fff;
}

a.subrayar {
  background-image: linear-gradient(black, black);
}

a.btn-texto {
  color: #0c54a0;
}

.contenido-modal.add .header-modal {
  background:#4cbb6c;
  color:white;
}

p.preciocarro {
  color: #e53131;
}
.sombra{-webkit-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28); -moz-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28); box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);}

p.preciocarro span {
  color:#666;
}

.gmap iframe {
  border:1px solid #e7e7e7;
}

#mapacontacto {
  border-bottom:1px solid #ccc;
  border-top:1px solid #ccc;
}

.gm-style .gm-style-iw {
  background-color: white !important;
}

.table th, .table td {
  border-top: 1px solid #ccc;
}

.table th {
  background:#f2f2f2;
  color:white;
}

.table thead th {
  background:#404040;
}

.table tbody + tbody {
  border-top: 2px solid #ccc;
}

.table .table {
  background-color: #ffffff;
}

.table.tienda thead th {
  background:none;
  color:#666;
  border-bottom: 0px solid #ccc;
}

.table.tienda tr {
  border-bottom: 1px solid #ccc;
}

.table-condensed th, .table-condensed td {
  border-top: 1px solid #ccc;
}

.table.table-condensed th {
  background:#404040;
  color:white;
}

.share a span {
  color:#fff;
  background:#2d2d2d;
}

.share a span:before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #2d2d2d;
}

.share a span.nomostrar {
  color:#fff;
}

.share.rounded a{
  color:#ffffff;
}

.share.rounded a span{
  color: #ffffff;
}

.share.rounded .facebook, .share.rounded .twitter, .share.rounded .instagram, .share.rounded .youtube {
  background-color: #282828;
}

.share.rounded .facebook:hover, .share.rounded .twitter:hover, .share.rounded .instagram:hover, .share.rounded .youtube:hover {
  background-color: #c80000;
}

.share.mini a {
  color:white;
}

.share.mini a span {
  color: #ffffff;
}

.share.mini .facebook:hover, .share.mini .twitter:hover, .share.mini .instagram:hover, .share.mini .youtube:hover {
  background-color: #e71514;
}

.top-urgente p {
  color: #000000;
}

.top-urgente p a, .top-urgente p a:hover, .top-urgente p a:focus {
  color:black;
}

.top-urgente p span {
  background-color: #ff3c00;
  color:white;
}

.fondo-trans {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: linear-gradient(31deg, rgb(4 6 16) 0%, rgb(8 46 136) 50%, rgb(0 0 0) 100%) !important;
  display:none;
  z-index:11000;
  overflow-y:auto;
}

.fondo-trans:target {
  visibility: visible;
  opacity: 1;
}
.close-btn {
  right: -5px;
}
@media screen and (max-width: 700px){.popup{width: 95%;}.close-btn{right: -5px;}}

.popup {
  margin: auto;
  background: #fff;
  position: relative;
  z-index:12000;
  overflow: hidden;
  width: 100%;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Arial, Helvetica,sans-serif;
  font-size:1.692em;
  display:inline;
}

.popup .cerrar {
  position: absolute;
  top: 6px;
  right: 10px;
  transition: all 200ms;
  font-size: 1.3em;
  font-weight: normal;
  text-decoration: none;
  color: #333;
  z-index: 2;
}

.popup .cerrar:hover {
  color: #000000;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 95%;
  }
}

.cont-modal2 {
  padding-bottom: 15px;
}

.head-contmodal {
  padding: 10px;
  font-size: 1.7vh;
  line-height: 1.4em;
  text-align: left;
}

.boxmodal__titulo-ico span {
  font-size: 2em;
  line-height: 2em;}

.boxmodal__icono i {
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;}

/*Zona modal*/
.cont-modal2 {
  /*background: linear-gradient(31deg, rgb(4 6 16) 0%, rgb(8 46 136) 50%, rgb(0 0 0) 100%);
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #000000;*/
}

@media (max-width:991px) {
  .cont-modal2 {
    background-position: left top;
  }
}

.titulo-big {
  font-size: 4em;
  font-family: 'Anton', sans-serif !important;
  color: #ffffff;
  line-height: 1.2em;
  text-shadow: 3px 3px 1px rgba(0, 0, 0, 0.4);
}
@media (max-width:500px) {
  .titulo-big span{
    font-size: 2.8em;
  }
}

.subtitulo-big {
  font-size: 3.7em !important;
  font-family: 'Anton', sans-serif !important;
  color: #ffffff;
  line-height: 1.2em;
  text-shadow: 3px 3px 1px rgba(0, 0, 0, 0.4);
}
@media (max-width:500px) {
  .subtitulo-big span{
    font-size: 2.2em;
  }
}

.box-cont {
  background-color: rgba(255,255,255,0.9);
  width: 80%;
  padding: 20px;
  border-radius: 15px;
  font-size: 1.5em;
  line-height: 1.3em;
  text-align: left;
}

@media (max-width:991px) {
  .box-cont {
    font-size: 1em;
    padding: 10px;
    width: 100%;
  }
}

.nota {
  font-size: 0.8em;
}

@media (max-width:500px) {
  .nota {
    font-size: 0.8em;
  }
}

.pie-mensajes {
  background-color: rgba(255,255,255,0.5);
  padding: 20px 0;
  border-top: 5px solid rgba(255,255,255,0.8);
}

.box-recuerdo {
  background-color: rgba(0,0,0,0.3);
  border: 2px solid rgba(255,255,255,0.7);
  padding: 15px;
  border-radius: 15px;
  min-height: 90px;
}

@media (max-width:991px) {
  .box-recuerdo {
    margin-bottom: 10px;
  }
}

.text-recuerdo {
  margin-left: 65px;
  font-size: 1em;
  line-height: 1.2em;
}

.full {
  width: 100% !important;
}

a {
  text-decoration: none;
  color: #333333;
}

a:hover, a:focus {
  text-decoration: none;
  color: #000000;
}

footer, header, article, section, div, h1, h2, h3, h4, p, form, input, textarea, img, span, td {
  margin:0;
  padding:0;
  background:transparent;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
  background:transparent;
  outline:none;
}

id, class, div, section {
  outline:none;
}

h1,h2,h3,h4,h5,h6 {
  font:inherit;
}

img {
  border:none;
}

svg {
  overflow:hidden;
  vertical-align:middle;
}

video {
  width:100%;
  height:auto;
  border:0;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

sup, sub {
  font-size: 0.75em;
}

b,strong {
  font-weight:bold;
}
.full { width: 100% !important }

ul {
  display: block;
  list-style-type: square;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

dl{
  margin-top:0;
  margin-bottom:20px;
}

dt,dd {
  line-height:1.42857143;
}

dt {
  font-weight:700;
}

dd {
  margin-left:0;
  margin-bottom:15px;
}

@media (min-width:768px){
  .dl-horizontal dt {
    float:left;
    width:160px;
    clear:left;
    text-align:right;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
  .dl-horizontal dd {
    margin-left:180px;
  }
}

.limpiar {
  clear:both;
  font-size:0;
  line-height:0;
  float:none;
}

acronym, abbr {
  border-bottom:1px dotted #111;
  cursor:help;
  font-style:normal;
  text-decoration:none;
}

.separa {
  width:100%;
  height:30px;
  font-size:0;
  clear:both;
}

.separa.xxl-size {
  width:100%;
  height:70px;
  font-size:0;
  clear:both;
}

.separa.xl-size {
  width:100%;
  height:60px;
  font-size:0;
  clear:both;
}

.separa.l-size {
  width:100%;
  height:50px;
  font-size:0;
  clear:both;
}

.separa.s-size {
  width:100%;
  height:20px;
  font-size:0;
  clear:both;
}

.separa.xs-size {
  width:100%;
  height:10px;
  font-size:0;
  clear:both;
}

.izq {
  float:left;
}

.center {
  text-align:center;
}

.centrado {
  margin:0 auto;
  display:block;
  text-align:center;
  float:none;
}

.full {
  width:100%;
}

*::-ms-backdrop, .underline {
  text-decoration:underline;
}

.redondo {
  border-radius:4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.sombra {
  -webkit-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
  -moz-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
  box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
}

hr {
  display: block;
  margin:2em auto;
  border: 0;
  height: 1px;
  background: #dddddd;
}

mark {
  padding: .2em;
  background-color: #fcf8e3;
}

::selection {
  background: rgb(218, 244, 0);
  color: rgb(7, 7, 7);
}

::-moz-selection {
  background: rgb(218, 244, 0);
  color: rgb(7, 7, 7);
}

.negro {
  color:#000000 !important;
}

.blanco {
  color:#ffffff !important;
}

.verde {
  color:#31ae00 !important;
}

.ajust-font3 {
  line-height: 1.2;
}

.adorno-calabaza {
  position: absolute;
  bottom: -28px;
  right: -48px;
  opacity: 0.3;
}

.adorno-murcielago {
  position: absolute;
  top: -30px;
  opacity: 0.1;
  width: 25%;
}

.bg-loverfans {
  background: linear-gradient(0deg, rgba(226,75,79,1) 0%, rgba(248,166,61,1) 100%);
}

.bg-darkfans {
  background: linear-gradient(0deg, rgba(226,75,79,1) 0%, rgba(248,166,61,1) 100%);
}

.bg-scatbook {
  background: linear-gradient(0deg, rgba(226,75,79,1) 0%, rgba(248,166,61,1) 100%);
}

.caja-borde {
  border: 3px solid #000000;
  margin: 30px 30px;
  position: relative;
}
.zona-ficha {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.posicion-boton {
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
}

.boton.l-size {
  padding: 15px 15%;
  font-weight: 700;
  font-size: 2em;
}

.boton.m-size {
  padding: 15px 20%;
  font-weight: 700;
  font-size: 1.5em;
}
.boton.l-size {
  padding: 14px 16px;
  font-size: 1em;
  line-height: 1.3333333;
}
.boton.negro {
  color: #fff !important;
  background-color: #000;
}
.boton-fix {
  font: normal normal bold 26px/28px Montserrat;
}
.boton {
  font-size: 1.2em;
  padding: 10px 50px;
  border-radius: 30px;
}
.negro {
  color: #000000 !important;
}
.boton {
  display: inline-block;
  padding: 10px 15px;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.42857143;
  text-align: center;
  /* white-space: nowrap; */
  vertical-align: middle;
  border-radius: auto;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background-image: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  background-color: inherit;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-appearance: none;
}

@media (min-width: 992px){
  .escritorio {
    visibility: visible;
    display: inherit;
  }
}

.tableta {
  visibility: hidden;
  display: none;
}
.escritorio {
  visibility: hidden;
  display: none;
}
.ficha-chica-caja {
  z-index: 2;
  position: relative;
  margin-bottom: 30px !important;
  margin-top: 30px !important;
  width: 100%;
  border-radius: 5px;
  border: 1px solid white;
  max-width: 250px;
}
@media (min-width: 992px){
  .escritorio {
    visibility: visible;
    display: inherit;
  }
}
.tableta {
  visibility: hidden;
  display: none;
}
.escritorio {
  visibility: hidden;
  display: none;
}
.centrado {
  margin: 0 auto;
  display: block;
  text-align: center;
  float: none;
}

.degradadooscuro {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.8547794117647058) 0%, rgba(0,0,0,0.6362920168067228) 65%);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 5px;
}
.caja-ficha-chica-foto {
  position: absolute;
  top: -40px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
}
.ficha-chica-foto {
  border-radius: 50%;
  border: 2px solid #ffffff;
  z-index: 2;
}
.blanco {
  color: #ffffff !important;
}
.fuente-destacada {
  font-family: 'Quicksand', sans-serif;
}
.ajust-font4 {
  font-size: 2.1em;
}
.blanco {
  color: #ffffff !important;
}
.center {
  text-align: center;
}
.caja-textos-chica {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 5;
}
.boton-videollamada {
  border-radius: 20px;
  background-color: #99f5db;
  color: #60be99 !important;
  padding: 5px 5px;
  display: inline-flex;
  margin-top: 10px;
}
.icono-boton-videollamada {
  width: 20px;
}
.caja-enlinea2 {
  position: absolute;
  top: -38px;
  width: 100%;
  margin-left: -38px;
  text-align: center;
  z-index: 3;
}
.online-punto {
  z-index: 2;
  background-color: #00d617;
  width: 12px;
  height: 12px;
  border-radius: 14px;
  margin: 0 auto;
  display: inline-block;
}

.caja-nombre-chica {
  font-size: 14px;
  line-height: 26px;
  font-weight: bold;
}
.separa.xs-size {
  width: 100%;
  height: 10px;
  font-size: 0;
  clear: both;
}
.caja-nick-chica {
  font-size: 14px;
  line-height: 26px;
  font-weight: normal;
  font-weight: normal;
  color: #ffffff;
  opacity: 0.8;
}
.cont-modal3 {
  background: #F25801;
  box-shadow: 0px 4px 8px #33333366;
}
.caja-borde2 {
  background-color: #FB7300;
  margin: 10px 20px;
  box-shadow: 0px 4px 8px #33333366;
}
.adorno-telarana {
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.3;
}
.adorno-fantasma {
   position: absolute;
   bottom: 0px;
   right: -10px;
   opacity: 0.1;
   width: 25%;
 }
.separa {
  width: 100%;
  height: 30px;
  font-size: 0;
  clear: both;
}
.ajust-font5 {
  line-height: 1.2em;
}

.boton.l-size {
  padding: 14px 16px;
  font-size: 1em;
  line-height: 1.3333333;
}
.boton {
  display: inline-block;
  padding: 10px 15px;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.42857143;
  text-align: center;
  /* white-space: nowrap; */
  vertical-align: middle;
  border-radius: auto;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background-image: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  background-color: inherit;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-appearance: none;
}
.negro {
  color: #000000 !important;
}
.boton {
  font-size: 1.2em;
  padding: 10px 50px;
  border-radius: 30px;
}
.boton.negro {
  color: #fff !important;
  background-color: #000;
}
.boton.l-size {
  padding: 15px 15%;
  font-weight: 700;
  font-size: 2em;
}
</style>
