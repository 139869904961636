<template>
  <ListView no-gutters
    cols="6" sm="3"
    :loading="cursor.loading"
    :items="cursor.items"
    item-class="dynamic-padding"
    :empty-text="noContentLabel"
    @bottom-reached="cursor.loadItems">
    <template #default="{ item }">
      <ProductCard :product="item" />
    </template>
  </ListView>
</template>
<script>
import { mapState } from 'vuex'
import ProductService from '@/components/products/mixins/product_service'
import ProductCard from '@/components/products/ProductCard'

const cursor = () => [{
  name: 'cursor', action: 'products/list_by_user'
}]

export default {
  name: 'UserMarket',
  mixins: [ProductService({ cursors: cursor() })],
  components: { ProductCard },
  props: { user: Object },
  data () {
    return {
      query: { username: this.user.username }
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    isOwnProfile () {
      return this.currentUser.username === this.user.username
    },
    noContentLabel () {
      const noPost = this.isOwnProfile ? 'no_market' : 'no_market_visit'
      return this.$t(`listview.${noPost}`)
    }
  },
  methods: {

    onProductCreated (product) {
      if (this.user.username !== this.currentUser.username) {
        return
      }
      this.cursor.items.unshift(product)
    },

    onProductDeleted (product) {
      if (this.user.username !== this.currentUser.username) {
        return
      }
      const postIndex = this.cursor.items.findIndex(p => p.id === product.id)
      if (postIndex !== -1) {
        this.cursor.items.splice(postIndex, 1)
      }
    }
  },
  mounted () {
    this.subscribeProductCreated()
    this.subscribeProductDeleted()
  }
}
</script>
<style lang="scss">
.dynamic-padding {
  padding: 0.5% !important;
}
</style>
