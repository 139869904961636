<template>
  <div>
    <component :is="getContractLangPdf" class="mt-5 ">
    </component>
    <p>{{ `${this.$t('models.user.name')}: ${this.name} ${this.surname}` }}</p>
    <p>{{ `${this.$t('models.user.dni')}: ${this.dni}` }}</p>
    <p>{{ `${this.$t('models.user.address')}: ${this.address}` }}</p>
    <p>{{ `${this.$t('models.user.birthdate')}: ${this.$date(this.birthdate)}` }}</p>
    <p>{{ `${this.$t('models.complaint.date')}: ${this.$date(this.date)}` }}</p>
    <br>
    <div id="signature" ref="signature2" style="font-size: 20px;margin-left: 40px;">
      {{
        name
      }}
     </div>
    <pre>
      ____________________________
     </pre>
    <pre>
      {{
        $t('actions.sign')
      }}
     </pre>
    <p v-html="rightsReserved"></p>
  </div>
</template>
<script>
import cookies from "@/cookies";
import Es_PDF from "@/components/signContract/locales/Es_PDF.vue";
import En_PDF from "@/components/signContract/locales/En_PDF.vue";

export default {
  name: 'ContaintPrintContract',
  props: {
    download: Boolean,
    open: Boolean,
    name: {type: String, default: ''},
    surname: {type: String, default: ''},
    dni: {type: String, default: ''},
    address: {type: String, default: ''},
    birthdate: {type: String, default: ''},
    date: [Date,String],
    lang: {type: String, default: 'es'}
  },
  computed: {
    getContractLangPdf() {
      return this.lang === 'es' ? Es_PDF : En_PDF
    },
    /*lang() {
      return cookies.get('locale')
    },*/
    rightsReserved() {
      const en = '<p><b>Last updated: 08th June 2023</b></p><p><b>© 2023. ARTEMISA3000 TECH SOLUTIONS, S.L. All Rights Reserved.</b></p>'
      const es = '<p><b>Última actualización: 8 de junio de 2023</b></p><p><b>© 2023. ARTEMISA3000 TECH SOLUTIONS, S.L. Todos los derechos reservados.</b></p>'
      return this.lang === 'es' ? es : en
    },
  }
}
</script>
