<template>
  <svg id="Grupo_22" data-name="Grupo 22" xmlns="http://www.w3.org/2000/svg" width="25.6" height="22" viewBox="0 0 25.6 22">
    <defs>
      <mask id="attach_circle">
        <rect x="0"
            y="0"
            width="500"
            height="500"
            fill="white" />
        <circle cx="20"
                cy="9"
                r="7"
                fill="black"/>
      </mask>
    </defs>
    <g id="link-24px"  transform="translate(-1 -2)"  fill="none" mask="url(#attach_circle)" >
      <path id="Trazado_15" data-name="Trazado 15" d="M0,0H22V22H0Z" />
      <path
        :fill="fillPrimary"
        id="Trazado_16"
        data-name="Trazado 16"
        d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
        transform="scale(1.2)"/>
    </g>
    <g id="attach-24px" transform="translate(12 0)"  fill="none">
      <path id="Trazado_4" data-name="Trazado 4" d="M0,0H10.31V10.31H0Z" />
      <path
        :fill="fillSecondary"
        id="Trazado_5"
        data-name="Trazado 5"
        d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"
        transform="scale(0.6)"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'AffiliateLinkIcon',
  props: {
    fill: String,
    fillSecond: String
  },
  computed: {
    fillPrimary () {
      return this.fill
        ? this.fill === 'primary'
          ? this.$vuetify.theme.themes.light.primary
          : this.fill
        : 'white'
    },

    fillSecondary () {
      return this.fillSecond
        ? this.fillSecond === 'primary'
          ? this.$vuetify.theme.themes.light.primary
          : this.fillSecond === 'success'
            ? this.$vuetify.theme.themes.light.success
            : this.fillSecond
        : 'white'
    }
  }
}

</script>
