<template>
  <section>
    <Picker
      v-show="showEmojiPicker"
      class="picker-emoji"
      :class="dark"
      :data="emojiIndex"
      :showPreview="false"
      @select="addEmoji"
    />
    <div class="form-chat">
      <c-btn icon color="secondary" v-if="showIconEmojis" :disabled="disabled" @click="showEmojiPickerDialog">
        <v-icon>tag_faces</v-icon>
      </c-btn>
      <textarea
        v-bind="$attrs"
        contenteditable
        name="message"
        :rows="rows"
        id="textArea"
        ref="textarea"
        :maxlength="maxLength"
        v-model="message"
        class="input-area col"
        :class="cssClass"
        :disabled="disabled"
        @input="autoGrow($event)"
        @keydown.enter="enterPressed"
        :placeholder="$t('messages.placeholder')"
      />

      <!--  <span
        v-if="showIconEmojis && right"
        :class="{ disabled: disabled }"
        @click="showEmojiPickerDialog"
      >
        <svg viewBox="0 0 24 24" class="emoji" :class="dark">
          <path
            d="M9.153 11.603c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962zm-3.204 1.362c-.026-.307-.131 5.218 6.063 5.551 6.066-.25 6.066-5.551 6.066-5.551-6.078 1.416-12.129 0-12.129 0zm11.363 1.108s-.669 1.959-5.051 1.959c-3.505 0-5.388-1.164-5.607-1.959 0 0 5.912 1.055 10.658 0zM11.804 1.011C5.609 1.011.978 6.033.978 12.228s4.826 10.761 11.021 10.761S23.02 18.423 23.02 12.228c.001-6.195-5.021-11.217-11.216-11.217zM12 21.354c-5.273 0-9.381-3.886-9.381-9.159s3.942-9.548 9.215-9.548 9.548 4.275 9.548 9.548c-.001 5.272-4.109 9.159-9.382 9.159zm3.108-9.751c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962z"
          ></path></svg
      ></span>  -->
      <slot/>
    </div>
    <span class="seeErrors" v-if="hasAnyError && !firstLoad">
      <span v-if="isBannedWordError"
      >{{
          $t(`errors.banned_word`, {})
        }}<span class="field">{{ isBannedWordError.field }} </span>
      </span>
      <span v-if="isRequiredTextError">{{ $t(`errors.requiredText`) }} </span>
      <span v-if="isHtmlTagsError">{{ $t(`errors.no_html_tags`) }} </span>
      <span v-if="isNoLinksAllowed">{{ $t(`errors.no_links_allowed`) }} </span>
      <span v-if="isPhoneError">{{ $t(`errors.not_phone`) }} </span>
    </span>
    <span v-if="showCount" class="count-character">{{ countCharacter }}/{{ maxLength }}</span>
  </section>
</template>
<script>
import messages from '../locales/all.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import data from 'emoji-mart-vue-fast/data/all.json'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import {
  existBannedWords,
  noHtml,
  noPhone,
  noLink
} from '../../../plugins/form_validations'

const emojiIndex = new EmojiIndex(data)
export default {
  components: {
    Picker
  },
  i18n: { messages },
  props: {
    showIconEmojis: {
      type: Boolean,
      default: true
    },
    showCount: {
      type: Boolean,
      default: true
    },
    maxLength: {
      type: Number,
      default: 1500
    },
    rows: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    noResize: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Array,
      default: () => []
    },
    clearInputText: { type: Boolean },
    firstLoad: { type: Boolean },
    loadMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showEmojiPicker: false,
      emojiIndex: emojiIndex,
      selectEmoji: '',
      message: ''
    }
  },
  computed: {
    cssClass () {
      return {
        dark: this.$vuetify.theme.dark,
        fieldError:
          !this.firstLoad &&
          (this.isBannedWordError || this.isRequiredTextError)
      }
    },
    dark () {
      return {
        dark: this.$vuetify.theme.dark
      }
    },
    resetText () {
      return this.clearInputText
    },
    hasAnyError () {
      return this.hasError.length > 0
    },
    isBannedWordError () {
      return this.hasError.find(err => err.error === 'bannedWord')
    },
    isNoLinksAllowed () {
      return this.hasError.find(err => err.error === 'noLinksAllowed')
    },
    isRequiredTextError () {
      return this.hasError.find(err => err.error === 'textFieldRequired')
    },
    isHtmlTagsError () {
      return this.hasError.find(err => err.error === 'noHtmlTags')
    },
    isPhoneError () {
      return this.hasError.find(err => err.error === 'noPhoneNumber')
    },
    countCharacter () {
      return this.message ? this.message.length : 0
    }
  },
  watch: {
    resetText () {
      this.resetTextArea()
    },
    message () {
      this.checkRules()
    }
  },
  methods: {
    showEmojiPickerDialog () {
      if (this.disabled) {
        return
      }
      this.showEmojiPicker = !this.showEmojiPicker
    },
    autoGrow (element) {
      this.$emit('syncLoad:update', false)
      if (!this.noResize) {
        element.target.style.height = '35px'
        element.target.style.height = element.target.scrollHeight + 2 + 'px'
      }
      this.emitEventNewText()
    },
    enterPressed (event) {
      if (event.shiftKey) return true
      event.preventDefault()
      this.emitEventNewText()
      if (this.hasAnyError) {
        return false
      }
      this.$emit('onSendMessage')
      return false
    },
    resetTextArea () {
      this.message = ''
      if (!this.noResize) {
        document.getElementById('textArea').style.height = '35px'
        document.getElementById('textArea').style.overflow = 'hidden'
      }
    },
    checkRules () {
      this.$emit('hasBannedWord', existBannedWords(this.message))
      this.$emit('hasHtmlLink', noHtml(this.message))
      this.$emit('hasPhone', noPhone(this.message))
      this.$emit('hasLink', noLink(this.message))
      this.$emit('required', this.message)
    },
    emitEventNewText () {
      this.$emit('newText', this.message)
    },
    addEmoji (emoji) {
      if (this.disabled) {
        return true
      }
      this.showEmojiPicker = false
      // this.message = this.message + emoji.native
      this.message = this.message + emoji.native
      this.emitEventNewText()
      this.$refs.textarea.focus()
      /* const start = this.message.substring(0, this.cursorPosition)
      const end = this.message.substring(this.cursorPosition)
      this.message = start + emoji.native + end
      this.$emit('input', this.message)
      this.$nextTick(() => {
        textarea.selectionEnd = this.cursorPosition + emoji.native.length
        this.toggleEmojiPicker()
      }) */
    }
  },
  mounted () {
    this.message = this.loadMessage
  }
}
</script>
<style lang="scss" scoped>
.input-area {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border: 1px solid gray;
  padding: 4px !important;
  margin: 0 0 0 1%;
  resize: none;
  width: 100%;
  overflow: hidden;
  /*&.dark {
    background-color: #1f2c34 !important;
    border: 1px solid white;
  }*/
}

.form-chat {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98% !important;
}

.picker-emoji {
  width: 100% !important;

  &.dark {
    background-color: #1F2C34 !important;
    color: black !important;
  }
}

.emoji {
  width: 24px;
  height: 24px;
  margin: -3px 0;

  &.dark {
    filter: invert(100%) sepia(100%) saturate(16%) hue-rotate(295deg) brightness(106%) contrast(103%);
  }
}

svg {
  .dark {
    filter: invert(100%) sepia(100%) saturate(16%) hue-rotate(295deg) brightness(106%) contrast(103%);
  }
}

textarea:disabled {
  background-color: #9b9b9b3b;
}

/* .input-area:focus {
  font-size: 16px !important;
} */
.no-border-blue {
  outline: none;
}

span.disabled:hover {
  cursor: not-allowed;
}

textarea[contenteditable] {
  max-height: 80px !important;
}

.fieldError {
  border-color: red;
  border-width: 2px;
  background-color: #ff00001a;
}

.seeErrors {
  margin-left: 10px;
  color: red;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  position: relative;
  bottom: 5px;

  .field {
    font-weight: bold;
  }
}

.count-character {
  position: relative;
  display: flex;
  justify-content: flex-end;
  right: 10px;
  font-size: 12px;
  top: -6px;
}

.app-bar-dark .emoji-mart {
  position: inherit !important;
}
</style>
