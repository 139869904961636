<template>
  <section class="app-fullpage">
    <v-row no-gutters>
      <v-col cols="12" md="8">
        <back-row class="border-bottom"></back-row>
        <DummyLoader css="pa-4"
          page
          :loading="isLoading">
          <v-alert dismissible dense type="warning">
            {{ $t('hints.covid')}}
          </v-alert>
          <ProductPage :product="product" />
        </DummyLoader>
      </v-col>
      <v-col cols="12" md="4">
        <DummyLoader css="pa-4"
          text
          :loading="isLoading">
          <ProductDescription
            :class="dynamicClasses"
            :product="product" />
        </DummyLoader>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProductActions } from '@/store'
import ProductPage from '@/components/products/ProductPage'
import ProductDescription from '@/components/products/ProductDescription'

export default {
  name: 'ProductView',
  components: { ProductPage, ProductDescription },
  props: {
    uuid: [String, Number]
  },

  metaInfo () {
    return {
      title: `${this.product.name} | ${this.$t('project')}`,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.product.description
      }]
    }
  },
  data () {
    return {
      product: {},
      isLoading: false
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    dynamicClasses () {
      return {
        'border-top': this.isMobile
      }
    }
  },
  async mounted () {
    this.isLoading = true
    this.product = await this.$store.dispatch(ProductActions.Fetch, { id: this.uuid, force: true })
    if (this.product.errorCode) {
      this.$router.replace({ name: 'home' })
      return
    }
    this.isLoading = false
  }
}
</script>
