<template>
  <section>
    <template v-if="showLogin">
      <SmallLoginForm small @close="emitClose" />
      <span class="pointer bold upper" @click="showLogin = false">
        Crear una cuenta Gratis
      </span>
    </template>
    <template v-else>
      <SmallSignupForm small @close="emitClose"/>
      <span class="pointer bold upper" @click="showLogin = true">
        {{ $t('signup.already_registered')}}
      </span>
    </template>
  </section>
</template>

<script>
import SmallLoginForm from './SmallLoginForm.vue'
import SmallSignupForm from './SmallSignupForm.vue'

export default {
  name: 'SessionForm',
  components: { SmallLoginForm, SmallSignupForm },
  data () {
    return {
      showLogin: false
    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    }
  },
  beforeDestroy() {
    this.$emit('close')
  }
}
</script>
