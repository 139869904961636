<template>
  <c-dialog width="684" :actions="false" :value="value" @input="emitInput">
    <template #title>
      <h1>
        {{ $t(`payments.title.product`, { username: product.username }) }}
      </h1>
    </template>
    <ProductInfoForm
      v-if="step === 0"
      :product="product"
      :vat-post-price="vatPrice"
      @next="handlePaymentMethod"
      @close="emitInput"
    />
    <ProductShippingForm
      v-else-if="step === 1"
      :disabled="requestingPayment"
      :product="product"
      @next="handleShippingInfo"
      @close="emitInput"
    />
    <PaymentProgress v-else-if="requestingPayment" />
    <template v-else-if="isTruspay">
      <TruspayForm class="form-container" :url="paymentUrl" />
    </template>
    <template v-else-if="paymentFormUrl">
      <iframe
        ref="iframe"
        class="form-container"
        :src="paymentFormUrl"
      ></iframe>
    </template>
    <PaymentSuccess
      v-else-if="paymentSuccess"
      :product="product"
      @close="emitInput"
    />
    <PaymentFail
      v-else-if="paymentError"
      :error="paymentError"
      @close="emitInput"
    />
  </c-dialog>
</template>
<script>
import { ProfileActions, ProductActions } from '@/store'
import ProductApi from '@/api/ProductApi'
import PaymentProgress from '@/components/payments/forms/partials/PaymentProgress'
import ProductInfoForm from './ProductInfoForm.vue'
import ProductShippingForm from './ProductShippingForm.vue'
import PaymentSuccess from './PaymentSuccess.vue'
import PaymentFail from './PaymentFail.vue'
import TruspayForm from '@/components/payments/forms/partials/TruspayForm'
import config from '@/project'
import { mapState } from 'vuex'

export default {
  name: 'ProductPaymentDialog',
  components: {
    TruspayForm,
    ProductInfoForm,
    ProductShippingForm,
    PaymentProgress,
    PaymentSuccess,
    PaymentFail
  },
  props: {
    value: Boolean,
    product: Object
  },
  data () {
    return {
      requestingPayment: false,
      step: 0,
      method: '',
      shippingInfo: {},
      provider: '',
      paymentFormUrl: '',
      paymentSuccess: false,
      paymentError: ''
    }
  },

  computed: {
    ...mapState('profile', ['currentUser']),
    vatPrice () {
      const price = this.product.price
      let vat = this.currentUser.billing.vat
      let expenses = (price * this.currentUser.billing.exes) / 100
      const vatAmount = Math.ceil((price + expenses) * vat / 100)

      return Math.ceil(vatAmount + expenses)
    },
    isTruspay () {
      return this.provider === 'truspay'
    },
    origin () {
      if (config.payments.darkfans_redirect) { return `https://darkfans.com?oa=${this.currentUser.sessionToken}&utm_source=${config.project}` }
      return `${window.location.origin}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
    }
  },

  methods: {
    handlePaymentMethod (method) {
      this.method = method
      this.step = 1
    },

    async handleShippingInfo (shippingInfo) {
      this.shippingInfo = shippingInfo
      await this.requestPayment()
      this.step = 2
    },

    async requestPayment () {
      this.requestingPayment = true
      if (this.method === 'wallet') {
        await this.walletPayment()
      } else {
        await this.creditCardPayment()
      }
      this.requestingPayment = false
    },
    async walletPayment () {
      const result = await this.$store.dispatch(ProductActions.Acquire, {
        id: this.product.id,
        attrs: this.shippingInfo
      })
      if (!result.error) {
        this.$store.dispatch(ProfileActions.Fetch)
        this.paymentSuccess = true
        this.$emit('payment-success')
      } else {
        this.paymentError = result.data.errorCode
      }
      return result
    },
    async creditCardPayment () {
      const { data, error } = await ProductApi.requestPayment(this.product.id, {
        ...this.shippingInfo,
        origin: this.origin
      })
      if (!error) {
        if (data.redirectUrl) {
        const slash = data.redirectUrl.includes('?') ? '&' : '?';
        window.location = `${data.redirectUrl}${slash}provider=${data.project}`
        } else {
          this.provider = data.provider
          this.paymentFormUrl = data.url
        }
      } else {
        this.paymentError = result.data.errorCode
      }
    },
    emitInput (value) {
      this.$emit('input', !!value)
      if (!value) {
        this.step = 0
      }
    }
  },
  mounted () {
    this.step = 0
    this.paymentFormUrl = ''
    this.method = ''
    this.shippingInfo = {}
  }
}
</script>
