<template>
  <Cover class="white-text">
    <div class="bold upper mb-2">
      {{ $t('posts.card_overlay.premium_text_1')}}
    </div>

    <PaymentButton
      post
      :color="color"
      :x-small="isMobile"
      :resource="post" />

  </Cover>
</template>
<script>
import Cover from './Cover'
import PaymentButton from '@/components/payments/PaymentButton'
import {mapState} from "vuex";

export default {
  name: 'RegisterOverlay',
  components: { Cover, PaymentButton },
  props: {
    post: Object,
    color: { type: String, default: 'success' }
  },
  computed: {
    ...mapState('application', ['isMobile'])
  }
}
</script>
