import api from '@/api/Api'

export default {

  create (conference) {
    return api.post('/conferences', conference)
  },

  update (conference) {
    return api.put(`/conferences/${conference.id}`, conference)
  },

  find (id) {
    return api.get(`/conferences/${id}`)
  },

  findCurrent (username) {
    return api.get('/conferences/current', { username })
  },

  delete (id) {
    return api.delete(`/conferences/${id}`)
  },

  list (query, options) {
    return api.get('/conferences', query, options)
  },

  start (id) {
    return api.put(`/conferences/${id}/start`)
  },

  join (id) {
    return api.put(`/conferences/${id}/join`)
  },

  finish (id) {
    return api.put(`/conferences/${id}/finish`)
  },

  sendMessage (id, message) {
    return api.post(`/conferences/${id}/message`, message)
  }
}
