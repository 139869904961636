<template>
  <div v-if="showVisible" class="d-flex flex-column justify-center align-center text-center fill-height pa-2 resource-content backgrop  pointer"
    @click="goToPostPage">
    <div class="circle backgrop gradient">
      <v-icon x-large
        class="white-light-text">
        play_arrow
      </v-icon>
    </div>
  </div>
  <PostNoVisible v-else
    :post="post"
    :hover="hover"
    @click="goToPostPage"/>
</template>
<script>
import { mapState } from 'vuex'
import PostNoVisible from '@/components/posts/PostNoVisible'
import cookies from '@/cookies'
import { PreferenceActions } from '@/store'
import config from '@/project'

export default {
  name: 'FakePostOverlay',
  components: { PostNoVisible },
  props: {
    post: Object,
    hover: Boolean
  },

  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapState("application", ["tags"]),
    hasTeaser () {
      return this.post.preview.teaser
    },
    isPremium () {
      return this.post.visibility === 'premium'
    },
    mustRedirect () {
      return config.payments.darkfans_redirect && !this.$route.path.includes('/posts/') && this.currentUser.username !== this.post.username && (this.post.acquired || this.post.author.subscribed )
    },
    showVisible (){
      return this.post.visible || this.hasTeaser || this.isPremium
    }
  },

  methods: {
    goToPostPage () {
      if (this.mustRedirect) {
        if (this.post.author.scatProfile) {
          let catCookies = cookies.get('categories-prefs') || ''
          const cookieScat = catCookies.split(',').includes('scat')
          if (!cookieScat) {
            cookies.set('categories-prefs', catCookies ? catCookies.concat(',scat') : 'scat')
          }
          catCookies = cookies.get('categories-prefs')
          const categories = []
          const tags = this.tags.filter(tag => catCookies.split(',').includes(tag.name)).map(tag => ({ ...tag, text: this.$t(`tags.${tag.name}`) }))
          tags.forEach(tag => categories.push(tag))
          this.$store.dispatch(PreferenceActions.UpdateTagPrefs, true)
          //this.$store.dispatch(PreferenceActions.UpdateCategory, categories)
        }
        window.open(`https://darkfans.com/posts/${this.post.id}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`, '_blank')
        return
      }
      this.$router.push({ name: 'post', params: { uuid: this.post.id }})
    }
  }
}
</script>
<style lang="scss" scoped>
.gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50));
}
</style>


