var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.visibleHeaders,"items":_vm.items,"loading":_vm.loading,"item-key":"id","page":_vm.page,"items-per-page":_vm.perPage,"server-items-length":_vm.totalItems},on:{"update:page":[function($event){_vm.page=$event},_vm.loadItems],"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"3"}},[_c('date-picker-input',{attrs:{"range":"","label":_vm.$t('labels.filter_dates')},model:{value:(_vm.filterDates),callback:function ($$v) {_vm.filterDates=$$v},expression:"filterDates"}})],1),(_vm.isInfluencer)?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"auto"}},[_c('span',{staticClass:"mr-2"},[_vm._v("EUR Sales: "),_c('b',[_vm._v(_vm._s(_vm.salesAmount.eur))])]),_c('span',{staticClass:"mr-2"},[_vm._v("USD Sales: "),_c('b',[_vm._v(_vm._s(_vm.salesAmount.usd))])])]):_vm._e(),(_vm.isInfluencer)?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"auto"}},[_c('c-btn',{attrs:{"color":"secondary","small":"","to":{ name: 'wallet_withdraws' }}},[_vm._v(_vm._s(_vm.$t('models.withdraws.name')))])],1):_vm._e()],1)]},proxy:true},{key:"item.source",fn:function(ref){
var item = ref.item;
return [(!_vm.hasSource(item))?_c('span',[_vm._v(_vm._s(item.resource || '-'))]):(_vm.isSale(item))?_c('router-link',{class:{ 'indigo--text': _vm.isAffiliate(item)},attrs:{"to":("/" + (item.resource) + "/" + (item.resourceId))}},[_vm._v(" "+_vm._s(_vm.$t('actions.show_item', { item: _vm.$t(("models." + (item.resource) + ".singular_name")).toLowerCase() }))+" ")]):(_vm.isReload(item))?_c('span',[_vm._v("-")]):(_vm.isReferral(item))?_c('router-link',{attrs:{"to":_vm.referralLink(item)}},[_vm._v(" "+_vm._s(_vm.$t(("wallet.referral." + (item.resource)), { username: item.username }))+" ")]):(_vm.isCart(item))?_c('a',{on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(_vm._s(_vm.$t('actions.show_details')))]):_c('router-link',{class:{ 'indigo--text': _vm.isAffiliate(item)},attrs:{"to":{ name: 'influencer', params: { username: item.resource } }}},[_vm._v(" "+_vm._s(("@" + (item.resource)))+" ")]),(_vm.isSFSRevolution(item))?_c('div',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$t('wallet.events.sfs_revolution')))]):(_vm.isAffiliate(item))?_c('div',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$t('wallet.events.affiliate_user')))]):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"large-text",class:_vm.amountColor(item.amount)},[_vm._v(" "+_vm._s(_vm.amountSymbol(item.amount))+" ")]),_c('Currency',{attrs:{"unsigned":"","amount":item.amount,"currency":item.currency}}),(item.creditCard)?_c('span',[_vm._v("("+_vm._s(_vm.$t('wallet.events.credit_card'))+")")]):_vm._e()]}},{key:"item.freeAmount",fn:function(ref){
var item = ref.item;
return [(item.freeAmount)?[_c('span',{staticClass:"large-text",class:_vm.amountColor(item.freeAmount)},[_vm._v(" "+_vm._s(_vm.amountSymbol(item.freeAmount))+" ")]),_c('Currency',{attrs:{"unsigned":"","amount":item.freeAmount,"currency":item.currency}})]:_c('span',[_vm._v("N/A")])]}},{key:"item.vat",fn:function(ref){
var item = ref.item;
return [(item.vat > 0)?[_c('span',{staticClass:"large-text error--text"},[_vm._v("-")]),_c('Currency',{attrs:{"amount":item.vat,"currency":item.currency}})]:_c('span',[_vm._v("N/A")])]}},{key:"item.exes",fn:function(ref){
var item = ref.item;
return [(item.exes > 0)?_c('span',{staticClass:"large-text error--text"},[_vm._v("-")]):_vm._e(),_c('Currency',{attrs:{"amount":item.exes,"currency":item.currency}})]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [(item.fee > 0)?_c('span',{staticClass:"large-text error--text"},[_vm._v("-")]):_vm._e(),_c('Currency',{attrs:{"amount":item.fee,"currency":item.currency}})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"amount":item.total,"currency":item.currency}}),(item.reservedAmount)?_c('div',{staticClass:"small-text success--text"},[_vm._v(" "+_vm._s(_vm.$t('wallet.events.reserved_amount', { amount: _vm.$currency(item.reservedAmount, item.currency) }))+" ")]):_vm._e()]}}])}),_c('c-dialog',{staticClass:"dialog",attrs:{"width":"640","max-width":"1280"},model:{value:(_vm.showDetailDialog),callback:function ($$v) {_vm.showDetailDialog=$$v},expression:"showDetailDialog"}},[_c('template',{slot:"title"},[_c('h1',[_vm._v(_vm._s(_vm.$t('payments.detail')))])]),_c('SaleDetail',{attrs:{"payment-id":_vm.selectedItem.paymentId,"items":_vm.selectedItem.items,"details":_vm.selectedItem.details}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }