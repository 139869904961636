<template>
  <section class="app-fullpage chat-dashboard" :class="calcHeight" style="margin-bottom: 3.5vh !important">
    <div class="row my-8 center" v-if="loading">
      <v-progress-circular color="#E400A0" indeterminate class="p-4">
      </v-progress-circular>
    </div>
    <v-row no-gutters class="flex-grow-1" v-else>
      <v-col
        v-if="showChatList"
        :cols="chatListCols"
        class="d-flex flex-column border-right"
      >
        <div class="border-bottom">
          <div class="d-flex align-center justify-space-between "></div>
          <div class="search-chat" v-if="!showChatNew && !showChatSetting">
            <icon-button
              type="plus"
              class="icons"
              :size="28"
              @handler="changeToNewMessageView"
            />
            <input
              type="text"
              v-model="username"
              name="newsletter"
              :placeholder="$t('messages.search_conversation')"
              class=""
            />
            <icon-button
              type="settings"
              class="icons"
              v-if="isInfluencer || isAdmin || isAccountManager"
              :size="28"
              @handler="changeChatSetting"
            />

            <img
              :src="getLangSelected"
              width="20"
              height="15"
              class="icons"
              @click="showHideList()"
            />
          </div>
        </div>
        <span
          class="border-bottom d-flex center"
          v-if="!showChatSetting && !showChatNew"
        >
          <button
            class="chat-buttons py-3"
            type="button"
            :class="{ selected: all, hiden2B: !isInfluencer, dark: darkTheme }"
            @click="selectFilter(true)"
          >
            {{ $t('words.all').toUpperCase() }}
          </button>
          <button
            class="chat-buttons py-3"
            type="button"
            :class="{
              selected: unRead,
              hiden2B: !isInfluencer,
              dark: darkTheme
            }"
            @click="selectFilter(false, !unRead)"
          >
            {{ $t('words.unread').toUpperCase() }}
          </button>
          <button
            v-if="isInfluencer"
            class="chat-buttons py-3"
            type="button"
            :class="{ selected: onlyFans, dark: darkTheme }"
            @click="selectFilter(false, false, !onlyFans)"
          >
            <span class="material-icons fan" style="height: 20px;">star</span
            >{{ $t('words.fans').toUpperCase() }}
          </button>
          <button
            v-if="isInfluencer"
            class="chat-buttons py-3"
            type="button"
            :class="{ selected: withTips, dark: darkTheme }"
            @click="selectFilter(false, false, false, !withTips)"
          >
            <span class="material-icons tip" style="height: 20px;"
            >attach_money</span
            >{{ $t('words.with_tip').toUpperCase() }}
          </button>
          <button
            v-if="isInfluencer || isAdmin || isAccountManager"
            class="chat-buttons py-3"
            style="max-width: 40px;"
            type="button"
            :class="{ selected: withTips, dark: darkTheme }"
            @click="changeToDeleteView()"
          >
            <span class="material-icons btn-delete">delete</span>
          </button>
        </span>
        <div class="chat-list-container flex-grow-1">
          <div
            class="chat-list custom-scrollbar"
            v-if="!showChatSetting && !showChatNew"
          >
            <ChatList
              :pending-approval="pendingApproval"
              :username="username"
              :unRead="unRead"
              :onlyFans="onlyFans"
              :withTips="withTips"
              :deleteChats="showDeleteChat"
              @default-filter="selectFilter()"
              @back="changeToDeleteView()"
            />
          </div>
          <div
            class="chat-list custom-scrollbar"
            v-else-if="showChatSetting && !showChatNew"
          >
            <ChatMenu @back="changeChatSetting()"></ChatMenu>
          </div>
          <div
            class="chat-list custom-scrollbar"
            v-else-if="showChatNew && !showChatSetting"
          >
            <ChatNew @back="changeToNewMessageView()"></ChatNew>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="showChatPage"
        :cols="chatPageCols"
        class="d-flex flex-column"
      >
        <VideoChat
          class="videoChat"
          v-if="showVideoChat"
          @rejected-time-call="showWarningRejectedCall"
        />

        <SettingsMultimediaChat
          v-else-if="showSetting"
          @reload-chat="reloadChat()"
        />

        <BroadcastPage v-else-if="selectedBroadcast"/>
        <ChatPage v-else :key="`chat-${selectedChat.id}`"/>

      </v-col>
      <Carousel
        class="prueba-overlay"
        :resources="resources"
        v-if="showCarousel"
      />
      <WarningDialog
        :show="warningRejectedCall"
        :textWarning="textWarningRejetedCall"
        hideCancelButton
        @handlerButtonOK="showWarningRejectedCall"
        :textButtonOK="$t('actions.ok').toUpperCase()"
      />
      <Dialog
        :show="showLoadFunds"
        @hide="() => (showLoadFunds = !showLoadFunds)"
        :title="$t('actions.load_funds').toUpperCase()"
      >
        <template slot="bodyDialog">
          <div>
            <LoadFunds
              :clearInit="showLoadFunds"
              :textLoadFund="$t('chats.video_chat.notification.errorCall_nofund')"
            />
          </div>
        </template>
      </Dialog>
    </v-row>

  </section>
</template>
<script>
import { mapState } from 'vuex'
import { AppActions, ChatActions } from '@/store'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import dialog from '@/components/mixins/dialog'
import ChatList from '@/components/chat/components/ChatList'
import ChatPage from '@/components/chat/ChatPage'
import BroadcastPage from '@/components/chat/BroadcastPage'
import SettingsMultimediaChat from '@/components/chat/components/SettingsMultimediaChat'
import IconButton from '@/components/chat/component/IconButton.vue'
import Button from '@/components/chat/component/Buttons.vue'
import ChatMenu from '@/components/chat/components/ChatMenu'
import ChatNew from '@/components/chat/components/ChatNew'
import Carousel from '@/components/chat/component/Carousel'
import VideoChat from '@/components/chat/components/videoChat/VideoChat.vue'
import i18n from '@/i18n'
import WarningDialog from '@/components/chat/component/WarningDialog'
import LoadFunds from '@/components/chat/components/LoadFunds'
import Dialog from '@/components/chat/component/Dialog'

export default {
  name: 'MessageDashboard',
  mixins: [dialog],
  props: {
    tab: Object,
    params: Object
  },
  components: {
    Carousel,
    ChatNew,
    ChatMenu,
    ChatList,
    ChatPage,
    BroadcastPage,
    SettingsMultimediaChat,
    IconButton,
    Button,
    VideoChat,
    WarningDialog,
    LoadFunds,
    Dialog
  },
  data () {
    return {
      username: null,
      pendingApproval: null,
      chatSetting: false,
      all: true,
      unRead: false,
      onlyFans: false,
      withTips: false,
      showDeleteChat: false,
      languages: [],
      selectedLang: {},
      settings: [],
      loading: true,
      chatIds: [],
      showList: false,
      warningRejectedCall: false,
      usernameRejectedCall: '',
      textWarningRejetedCall: '',
      showLoadFunds: false
    }
  },
  computed: {
    ...mapState('application', ['isMediumScreenOrGreater']),
    ...mapState('profile', ['currentUser', 'isInfluencer', 'isAdmin', 'isAccountManager']),
    ...mapState('chats', [
      'selectedChat',
      'selectedBroadcast',
      'showChatSetting',
      'showChatNew',
      'showSetting',
      'showVideoChat',
      'showCarousel',
      'carouselItems'
    ]),
    chatListCols () {
      return this.isMediumScreenOrGreater ? 4 : 12
    },
    chatPageCols () {
      return this.isMediumScreenOrGreater ? 8 : 12
    },
    showChatList () {
      return (
        this.isMediumScreenOrGreater ||
        !(this.selectedChat || this.selectedBroadcast)
      )
    },
    showChatPage () {
      return !!this.selectedChat || !!this.selectedBroadcast
    },
    darkTheme () {
      return this.$vuetify.theme.dark
    },
    isDarkTheme () {
      return this.darkTheme ? 'secondary' : 'primary'
    },
    query () {
      const q = {}
      if (this.username) {
        q.search = this.username
      }
      if (this.unRead) {
        q.isRead = !this.unRead
      }
      if (this.onlyFans) {
        q.onlyFans = this.onlyFans
      }
      if (this.withTips) {
        q.withTips = this.withTips
      }
      return q
    },
    resources () {
      return this.carouselItems
    },
    getLangSelected () {
      return this.languages.filter(lang => lang.code === this.selectedLang)[0]
        .image
    },
    attrs () {
      return {
        followers: this.settings.followers,
        followersFree: this.settings.followersFree,
        fans: this.settings.fans,
        fansFree: this.settings.fansFree,
        others: this.settings.others,
        othersFree: this.settings.othersFree,
        followedByMe: this.settings.followedByMe,
        followedByMeFree: this.settings.followedByMeFree,
        messagePrice: this.settings.messagePrice,
        mediaPrice: this.settings.mediaPrice,
        videochatPrice: this.settings.videochatPrice,
        translateLanguage: this.selectedLang
      }
    },
    calcHeight () {
      return {
        recalc: !this.currentUser.emailValidated
      }
    }
  },
  methods: {
    getLangs () {
      const langs = ConversationsApi.getLanguageTranslate().data
      for (var lang of langs) {
        this.languages.push({
          language: lang.language,
          image:
            lang.code === 'en'
              ? '/images/usa-flag-icon-20.jpg'
              : `https://flagcdn.com/28x21/${
                lang.flagCode.length > 2
                  ? lang.flagCode.substring(0, 2)
                  : lang.flagCode
              }.png`,
          code: lang.code
        })
      }
    },
    selectFilter (
      all = true,
      unRead = false,
      onlyFans = false,
      withTips = false
    ) {
      this.showDeleteChat = false
      this.unRead = unRead
      this.onlyFans = onlyFans
      this.withTips = withTips
      this.all = all || (!this.unRead && !this.onlyFans && !this.withTips)
    },
    showHideList () {
      this.$store.dispatch(ChatActions.ShowChatSetting, !this.showChatSetting)
      this.$store.dispatch(ChatActions.ShowGeneralSetting, true)
      this.$store.dispatch(ChatActions.ShowChatNew, false)
      this.showDeleteChat = false
    },
    changeChatSetting () {
      this.$store.dispatch(ChatActions.ShowChatSetting, !this.showChatSetting)
      this.$store.dispatch(ChatActions.ShowGeneralSetting, false)
      this.$store.dispatch(ChatActions.ShowGeneral, false)
      this.$store.dispatch(ChatActions.ShowChatNew, false)
      this.showDeleteChat = false
    },
    changeToNewMessageView () {
      this.$store.dispatch(ChatActions.ShowChatNew, !this.showChatNew)
      this.$store.dispatch(ChatActions.ShowChatSetting, false)
      this.$store.dispatch(ChatActions.ShowGeneralSetting, false)
      this.showList = false
      this.showDeleteChat = false
    },
    changeToDeleteView () {
      this.$store.dispatch(ChatActions.ShowChatNew, false)
      this.$store.dispatch(ChatActions.ShowChatSetting, false)
      this.$store.dispatch(ChatActions.ShowGeneralSetting, false)
      this.showDeleteChat = !this.showDeleteChat
      this.showList = false
    },
    removeHasMessages () {
      this.$store.dispatch(AppActions.UpdateHasMessages, false)
    },
    selectLang (lang = null) {
      this.selectedLang = lang || this.languages[0].code
      this.updateSettings()
      this.showList = false
    },
    async updateSettings () {
      const form = this.attrs
      const {
        data,
        error
      } = await ConversationsApi.updateSetting(form)
      if (!error) {
        this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
      } else {
        this.$store.dispatch(AppActions.SetNotification, {
          type: 'error',
          error: data
        })
      }
        this.$store.dispatch(AppActions.ShowNotification, true)
    },
    async userSelected (user) {
      const {
        data,
        error
      } = await ConversationsApi.create(user.username)
      if (!error) {
        this.$store.dispatch(ChatActions.SelectChat, data)
      } else {
        this.$eventBus.publish('notification', {
          error: true,
          notification: this.$t('messages.cannot_create')
        })
      }
    },
    showWarningRejectedCall (type, username) {
      if (type === 'rejected-call') {
        this.textWarningRejetedCall = this.$t(
          'chats.video_chat.notification.rejected_call',
          { user: username }
        )
      }
      /* if (type === "one-minute-left") {
        this.textWarningRejetedCall = this.$t("chats.video_chat.notification.oneMinuteFinishCall");
      } */
      /*  if (type === "not_enough_funds") {
         this.textWarningRejetedCall = this.$t("chats.video_chat.notification.noFunds");
       } */
      this.warningRejectedCall = !this.warningRejectedCall
    },
    noFundCall () {
      this.$store.dispatch(ChatActions.ShowVideoChat, false)
      this.showLoadFunds = true
      //this.showWarningRejectedCall(type)
    },
  },
  async beforeMount () {
    this.loading = true
    this.getLangs()
    const { data } = await ConversationsApi.settings()
    this.settings = data
    this.selectedLang = data.translateLanguage
    if (data.translateLanguage === '') {
      var translatedLanguage = this.languages.find(
        lang =>
          lang.code ===
          (window.navigator.userLanguage || window.navigator.language)
      )
      if (!translatedLanguage) {
        translatedLanguage = i18n.locale
        data.translateLanguage = translatedLanguage
      } else {
        data.translateLanguage = translatedLanguage.code
      }
      const { data2 } = await ConversationsApi.updateSetting(data)
    }
    this.selectedLang = data.translateLanguage
    this.loading = false
  },
  mounted () {
    if (this.$route.params.tab === 'setting') {
      this.$store.dispatch(ChatActions.ShowChatSetting, true)
      this.$store.dispatch(ChatActions.ShowGeneralSetting, false)
      this.$store.dispatch(ChatActions.ShowGeneral, false)
      this.$store.dispatch(ChatActions.ShowChatNew, false)
      this.showDeleteChat = false
    }
    this.wsHandler = this.removeHasMessages.bind(this)
    this.$ws.subscribe('conversation-started', this.wsHandler)
    this.removeHasMessages()
    if (this.$route.query && this.$route.query.username) {
      this.userSelected({ username: this.$route.query.username })
    }

    this.wsNoFundCall = this.noFundCall.bind(this)
    this.$ws.subscribe('no-funds', this.wsNoFundCall)

  },
  destroyed () {
    this.$ws.unsubscribe('conversation-started', this.wsHandler)
    this.$ws.unsubscribe('no-funds', this.wsNoFundCall)
  }
}
</script>
<style lang="scss" scoped>
.search-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  height: 37px;
}

.search-chat input {
  width: 100%;
  display: inline-block;
  border: 1px solid #d7d7d7;
  height: 40px;
  padding: 7px 15px;
  margin: 4px;
  font-size: 0.9375em;
  border-radius: 30px;
}

.search-chat button {
  margin-left: 5px;
  border: 0;
  margin-left: 5px;
  background-color: #6c757d;
  padding: 4px 8px;
  border-radius: 30px;
  cursor: pointer;
}

.chat-dashboard {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 90vh !important;

  .chat-list-container {
    position: relative;

    .chat-list {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      overflow-x: hidden;
    }
  }
}

.recalc {
  height: calc(99.7% - 100px);
}

.icons {
  margin-top: 5px;
}

.icons:hover {
  cursor: pointer;
}

.lupa {
  padding-top: 3px;
}

.chat-buttons {
  display: table-cell;
  text-align: center;
  font-size: 1em;
  font-family: Quicksand, sans-serif;
  background-color: transparent;
  white-space: nowrap;
  height: 37px;
  width: 23%;
  min-width: fit-content;

  &:hover {
    background-color: #f2f2f2;
  }

  &.dark:hover {
    background-color: #636363;
  }
}

.hiden2B {
  width: 49% !important;
}

.menu-button {
  background-color: transparent !important;
  margin-top: auto !important;
}

.img-ico {
  margin-left: 3px;

  &.dark {
    filter: brightness(0) saturate(100%) invert(95%) sepia(5%) saturate(0%) hue-rotate(53deg) brightness(105%) contrast(106%) !important;
  }
}

.img-ico:hover {
  cursor: pointer;
}

.selected {
  font-weight: bold;
}

.prueba-overlay {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

.material-icons.fan {
  vertical-align: middle;
}

.material-icons.tip {
  vertical-align: middle;
}

.material-icons.btn-delete {
  vertical-align: middle;
  font-size: 20px;
}

.lang-select {
  max-height: 160px;
  padding-bottom: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 100;

  span:hover {
    width: -webkit-fill-available;
    background-color: #f0f0f0 !important;
    cursor: pointer;
  }
}

.videoChat {
  background-color: black;
  /* width: 100vh; */
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  /* z-index: 11000; */
  justify-content: center;
  align-items: center;
}
</style>
