<template>
  <div class="promotion"
    :class="{ mobile: !isDesk, large: isLargeScreen, desk: isDesk }">
    <div class="background"></div>
    <LocaleSelect dense dark class="select-locale" />
    <div class="promotion-wrapper">
      <div class="left-rectangle"></div>
      <div class="flex-grow-1">
        <div class="section first">
          <div class="desc">
            {{ $t('promotion.october.date') }}
            <br>
            <b>{{ $t('project') }}</b>
            <br>
            {{ $t('promotion.october.rules') }}
            <br>
          </div>
          <PromotionCounter v-if="isDesk"
            class="counter" />
        </div>
        <div class="section second">
          <div class="engagment-wrapper text-center">
            <div class="be-star">
              {{ $t('promotion.october.beStar') }}
            </div>

            <v-btn class="login mt-2"
              color="#2e2e2e"
              :to="{ name: 'login' }">
              Click {{$t('words.here')}}
            </v-btn>
          </div>

          <router-link v-if="!isDesk"
            :to="{ name: 'home' }">
            <img
              :src="`/images/loverfans_logo_grey.png`"
              height="30"/>
          </router-link>

          <PromotionCounter v-if="!isDesk" />
        </div>
      </div>
      <div class="right-rectangle"></div>
    </div>

    <router-link class="logo" v-if="isDesk"
      :to="{ name: 'home' }">
      <img
        :src="`/images/loverfans_logo_grey.png`"
        height="30"/>
    </router-link>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import PromotionCounter from '@/views/promotion/PromotionCounter'

export default {
  name: 'PromotionOctober',
  components: { PromotionCounter },
  computed: {
    ...mapState('application', ['isMobile', 'isMediumScreenOrGreater', 'isLargeScreen']),
    isDesk () {
      return this.isMediumScreenOrGreater
    }
  }
}
</script>

<style lang="scss" scoped>
.promotion {
  min-height: 100vh;
  margin: 0 ;
  width: 800px;
  max-width: 70%;
  &-wrapper {
    position: relative;
    display: flex;
  }
  .background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('/images/promotions/october-2020.png') no-repeat center center;
    background-size: cover;
    background-color: black;
  }
  .select-locale {
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: 2000
  }

  .left-rectangle {
    height: 50vh;
    min-height: 50vh;
    border-left: 15px solid #088a7c;
  }

  .right-rectangle {
    margin-top: 30vh;
    min-height: 70vh;
    border-left: 3.5vw solid #aa0101;
  }

  .section {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    &.first {
      justify-content: flex-end;
    }
    &.second {
      align-items: center;
      flex-direction: column-reverse;
      justify-content: space-between;
    }
  }

  .desc {
    font-size: 40px;
    line-height: 49px;
    color: #d5d5d5;
    margin-left: 20px;
    font-family: Montserrat, sans-serif;
  }

  ::v-deep .counter {
    margin: 0 50px 0 30px;
  }

  .engagment-wrapper {
    width: 100%;
    .be-star {
      color: #696969;
      font-size: 19px
    }
    .login {
      width: 100%;
      margin: 15px 0;
    }
  }

  .logo {
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: 2000
  }

  &.large {
    margin-left: 33%;
  }
  &.desk {
    margin: 0 auto 0 auto;
  }
  &.mobile {
    width: 100%;
    max-width: 100%;
    margin: 0;
    .right-rectangle {
    margin-top: 50vh;
    min-height: 50vh;
    }
    .background {
      background: url('/images/promotions/october-2020.png') no-repeat left top;
      background-size: cover;
    }
    .desc {
      font-size: 21px;
      line-height: 25px;
    }
    .engagment-wrapper {
      .login {
        width: 50%;
      }
    }
  }
}

</style>
