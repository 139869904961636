<template>
  <div>
    <!--  <v-row class="m-0">
      <v-col cols="6" sm="4" md="3" v-for="(item, index) in items" :key="index" class="p-0">
<UserCard :user="item" />
      </v-col>
    </v-row> -->
    <ListView
      no-gutters
      :items="items"
      :loading="loading"
      cols="6"
      sm="4"
      md="3"
      @bottom-reached="bottomReached"
    >
      <template #default="{ item, index }">
        <div class="col-12 center">
          <!--          <UserCard :user="item" class="my-1" :noCard="noCard"/>-->
          <CardUserTop :user="item" :userCount="index + 1" @selected="selected"></CardUserTop>
        </div>
      </template>
      <template #empty>
        <p> no existen perfiles</p>
      </template>
    </ListView>
  </div>
</template>

<script>
/* import UserCard from "@/components/users/UserCard"; */
import UserApi from '@/api/UserApi'
import CursorService from '@/components/mixins/cursor_api'
import { mapState } from 'vuex'
import UserCard from '@/views/landing/specialCampaign/components/UserCard'
import project from '@/project'
import CardUserTop from '@/views/landing/specialCampaign/components/stories/CardUserTop.vue'

export default {
  name: 'ListCreator',
  mixins: [CursorService(UserApi, 'starsOffer')],
  props: {
    noCard: {
      type: Boolean,
      default: false
    },
    autoLoad: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CardUserTop,
    UserCard
  },
  computed: {
    ...mapState('application', ['isMobile', 'isLargeScreen']),
    query () {
      return {
        campaign: true,
        includeTags: true,
        genderIds: this.tagFilter(),
        order: 'likes:desc',
        darkfans: project.project === 'darkfans',
        limit: 12
      }
    }
  },
  methods: {
    tagFilter () {
      return this.$store.getters['preferences/selected_tags_ids'].genderIds.find(t => t === 1) ? [1] : [2, 3, 4, 5]
    },
    bottomReached () {
      if (this.autoLoad) {
        this.loadItems()
      }
    },
    selected (user, index) {
      this.$emit('selected', user, index)
    }
  }
}
</script>
<style lang="scss"></style>
