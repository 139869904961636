<template>
  <div>
    <!--     <PersonalData
          @validForm="switchValidForm"
          @personalData="personalData"
          @withdrawData="withdrawData"
        />
        <v-card-actions>
          <c-btn outlined v-if="skipable" color="secondary" @click.stop="skip">
            {{ $t("actions.finish") }}
          </c-btn>
          <v-spacer />
          <c-btn @click.stop.prevent="submit" :disabled="!validForm">
            {{ $t("actions.save") }}
          </c-btn>
        </v-card-actions> -->
    <v-alert dense type="info" v-if="disabledform">
      {{ $t('settings.personal.verified') }}
      <router-link
        class="pointer no-decoration ml-1"
        to="/contact"
      >
        <b style="color: white">{{ $t('settings.personal.contactUs') }}</b>
      </router-link>
    </v-alert>
    <PannelSignCreators/>
    <v-form v-model="validForm" ref="form" @submit.prevent="submit">
      <div style="margin: 4px;">
        <v-col cols="12" class="mb-3">
          <h2 class="title-text semibold">
            {{ $t('settings.personal.personal_data') }}
          </h2>
        </v-col>
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="pr-1">
            <v-select
              outlined
              :label="$t('models.influencer_request.id_document_type')"
              v-model="documentType"
              :items="documentTypes"
              :disabled="disabledform"
              :rules="[$vRequired, validateDni]">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" class="pl-1">
            <v-text-field
              ref="numberRef"
              outlined
              :label="$t('models.influencer_request.id_document_number')"
              v-model="documentNumber"
              :disabled="disabledform"
              :rules="[$vRequired, validateDni, validDni]"
            />
          </v-col>
          <v-col cols="12" sm="6" class="pr-1">
            <CountrySelect
              outlined
              :label="$t('models.influencer_request.id_document_country')"
              v-model="documentCountry"
              :disabled="disabledform"
              :rules="[$vRequired, validateDni]"
            />
          </v-col>
          <v-col cols="12" sm="6" class="pl-1">
            <!--            <date-picker-input
                          outlined
                          :min="dateExpiration"
                          :label="$t('models.influencer_request.id_document_expiration')"
                          v-model="documentExpiration"
                          :disabled="disabledform"
                        />-->
            <v-text-field
              outlined
              :label="$t('models.influencer_request.id_document_expiration')"
              v-model="documentExpiration"
              :disabled="disabledform"
              :rules="[$vRequired]"/>
          </v-col>
        </v-row>
      </div>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h2 class="title-text semibold">
              {{ $t('settings.personal.contact') }}
            </h2>
          </v-col>
          <v-row>
            <v-col cols="12" :sm="largeSizeCols">
              <v-text-field
                outlined
                :label="$t('models.user.name')"
                type="text"
                v-model="name"
                :disabled="disabledform"
                :rules="[$vRequired]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :sm="largeSizeCols">
              <v-text-field
                outlined
                :label="$t('models.user.surname')"
                type="text"
                :disabled="disabledform"
                v-model="surname"
                :rules="[$vRequired]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :sm="largeSizeCols">
              <div class="mb-8">
                <date-picker-input
                  v-model="birthdate"
                  :rules="[$vRequired, $vIsOver18]"
                  :disabled="disabledform"
                  :label="$t('models.user.birthdate')"/>
              </div>
            </v-col>

            <v-col cols="12">
              <h2 class="title-text semibold">
                {{ $t('settings.personal.address') }}
              </h2>
            </v-col>
            <v-col cols="12" :sm="largeSizeCols">
              <CountrySelect
                outlined
                v-model="country"
                :rules="[$vRequired]"
                :disabled="disabledform"
              />
            </v-col>
            <v-col cols="12" :sm="largeSizeCols">
              <v-text-field
                outlined
                :label="$t('models.user.province')"
                type="text"
                v-model="province"
                :rules="[$vRequired]"
                :disabled="disabledform"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :sm="largeSizeCols">
              <v-text-field
                outlined
                :label="$t('models.user.city')"
                type="text"
                v-model="city"
                :rules="[$vRequired]"
                :disabled="disabledform"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :sm="largeSizeCols">
              <v-text-field
                outlined
                :label="$t('models.user.postal_code')"
                type="text"
                v-model="postalCode"
                :rules="[$vRequired]"
                :disabled="disabledform"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                :label="$t('models.user.address')"
                type="text"
                v-model="address"
                :rules="[$vRequired]"
                :disabled="disabledform"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  outlined
                  :label="$t('models.user.phone_prefix')"
                  :prefix="phonePrefixText"
                  v-model="phonePrefix"
                ></v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  outlined
                  :label="$t('models.user.mobile')"
                  v-model="phone"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              :label="$t('models.user.telegram')"
              v-model="telegram"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              :label="$t('models.user.twitter')"
              v-model="twitter"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              :label="$t('models.user.skype')"
              v-model="skype"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <c-btn outlined v-if="skipable" color="secondary" @click.stop="skip">
          {{ $t('actions.finish') }}
        </c-btn>
        <v-spacer/>
        <c-btn @click.stop.prevent="submit" :disabled="!validDni || !validForm">
          {{ $t('actions.save') }}
        </c-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import project from '@/project'
import validatable from '@/components/mixins/validatable'
import {ProfileActions} from '@/store'
import PersonalData from '@/components/users/account/PersonalData'
import PannelSignCreators from '@/components/signContract/PannelSignCreators.vue'
import DniValidator from '@/dni_validator.js'

export default {
  name: 'UserPersonalSettings',
  mixins: [validatable('form')],
  components: {
    PannelSignCreators,
    PersonalData
  },
  props: {
    dark: {
      type: Boolean,
      default: null
    },
    skipable: Boolean,
    twoRows: Boolean
  },
  data() {
    return {
      dateExpiration: new Date().toISOString().substr(0, 10),
      validDni: true,
      name: null,
      surname: null,
      birthdate: null,
      country: null,
      postalCode: null,
      province: null,
      city: null,
      address: null,
      countries: [],
      telegram: null,
      twitter: null,
      skype: null,
      phonePrefix: null,
      phone: null,

      documentExpiration: null,
      documentCountry: null,
      documentNumber: null,
      documentType: null,
      project,
      documentTypes: [
        {
          text: this.$t('models.influencer_request.id_document_types.passport'),
          value: 'passport'
        },
        {
          text: this.$t('models.influencer_request.id_document_types.id_document'),
          value: 'id_document'
        },
        {
          text: this.$t('models.influencer_request.id_document_types.cif'),
          value: 'cif'
        },
        {
          text: this.$t('models.influencer_request.id_document_types.driver_license'),
          value: 'driver_license'
        }
      ]
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer']),
    largeSizeCols() {
      return this.twoRows ? 6 : 12
    },
    selectedCountry() {
      return this.country
        ? this.countries.find(
          c => c.code.toLowerCase() === this.country.toLowerCase()
        )
        : null
    },
    phonePrefixText() {
      let countryIso = '';

      if (this.phonePrefix) {
        const countryCode = this.phonePrefix.substring(1)
        const foundCountry = this.countries.find(country => country.value === countryCode)
        countryIso = foundCountry ? foundCountry.code.toUpperCase() : ''
      }

      if (!countryIso && this.selectedCountry) {
        countryIso = this.selectedCountry.code.toUpperCase()
      }

      return countryIso
    },
 /*   phonePrefixText() {
      let countryIso = ''
      if (this.phonePrefix) {
        countryIso = this.countries.find(c => c.value === this.phonePrefix.substring(1))
        countryIso = countryIso && countryIso.code.toUpperCase()
      }

      if (!countryIso && this.selectedCountry) {
        countryIso = this.selectedCountry.code.toUpperCase()
      }
      return countryIso ? `${countryIso}` : ''
    },*/
    disabledform() {
      return this.currentUser.signedDocument || this.currentUser.personalDataApproved;
    }
  },

  methods: {
    attrs() {
      const attrs = {
        validDni: true,
        name: (this.name || '').trim(),
        surname: (this.surname || '').trim(),
        birthdate: (this.birthdate || '').trim(),
        country: (this.country || '').trim(),
        postalCode: (this.postalCode || '').trim(),
        province: (this.province || '').trim(),
        city: (this.city || '').trim(),
        address: (this.address || '').trim(),
        telegram: (this.telegram || '').trim(),
        twitter: (this.twitter || '').trim(),
        skype: (this.skype || '').trim(),
        phonePrefix: (this.phonePrefix || '').trim(),
        phone: (this.phone || '').trim(),
        documentExpiration: (this.documentExpiration || '').trim(),
        documentCountry: (this.documentCountry || '').trim(),
        documentNumber: (this.documentNumber || '').trim(),
        documentType: (this.documentType || '').trim()
      }

      return attrs
    },
    switchValidForm(isValidForm) {
      this.validForm = isValidForm
    },
    personalData(personalData) {
      this.name = personalData.name
      this.surname = personalData.surname
      this.birthdate = personalData.birthdate
      this.country = personalData.country
      this.province = personalData.province
      this.postalCode = personalData.postalCode
      this.address = personalData.address
    },
    withdrawData(withdrawData) {
    },
    skip() {
      this.$emit('skip')
    },

    // TODO Show errors mostly bad current password
    async onSubmit() {
      const {error} = await this.$store.dispatch(
        ProfileActions.UpdatePersonalData,
        {...this.attrs(), onlydata: true},
      )
      if (!error) {
        this.$emit('submit')
        this.$eventBus.publish('notification', {
          message: this.$t('snackbar.save_success')
        })
      }
    },

    setupPrefix() {
      if (this.phonePrefix) {
        return
      }
      this.phonePrefix = this.selectedCountry && this.selectedCountry.value
    },

    validateDni() {
      if (this.documentType === 'id_document' && this.documentCountry === 'ES' && !DniValidator.isValid(this.documentNumber)) {
        this.validDni = this.$t('errors.invalidNif')
      } else {
        this.validDni = true
      }
      return true
    }
  },
  watch: {
    country(value) {
      this.setupPrefix()
    }
  },
  mounted() {
    var elements = this.$t('countries_v2')
    for (var el of elements) {
      this.countries.push({
        image: 'https://flagcdn.com/32x24/' + el.value.toLowerCase() + '.png',
        code: el.value.toLowerCase(),
        value: el.prefix.replace('+', '')
      })
    }
    this.setupPrefix()
  },
  created() {
    const profile = this.currentUser
    this.name = profile.name
    this.surname = profile.surname
    this.birthdate = profile.birthdate
    this.country = profile.country
    this.postalCode = profile.postalCode
    this.province = profile.province
    this.city = profile.city
    this.address = profile.address
    this.telegram = profile.telegram
    this.twitter = profile.twitter
    this.skype = profile.skype
    this.phonePrefix = profile.phonePrefix
    this.phone = profile.phone
    this.documentExpiration = profile.documentExpiration
    this.documentCountry = profile.documentCountry
    this.documentNumber = profile.documentNumber
    this.documentType = profile.documentType
  }
}
</script>
