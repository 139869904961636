'user strict'

import api from '@/api/Api'

export default {

  list (query) {
    return api.get('/payments', query)
  },

  refund (id) {
    return api.put(`/payments/${id}`, { refund: true })
  },

  statistics (query) {
    return api.get('/payments/statistics', query)
  },

  represented (query) {
    return api.get('/payments/represented', query)
  },

  representedStatistics (query) {
    return api.get('/payments/statistics/represented', query)
  },
  bypassIrpf (payload) {
    return api.put('/admin/withdraw/bypass_irpf', payload)
  },
  methodApproved (payload) {
    return api.put('/admin/withdraw/methodApproved', payload)
  },
  updatedFiscalData (payload) {
    return api.post('/admin/withdraw/withdrawData', payload)
  }
}
