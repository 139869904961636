<template>
  <v-form v-model="validForm" ref="form"
    @submit.prevent="submit">
    <v-alert type="error"
      v-if="saveError"
      icon="warning"
      dense outlined>
      {{ saveError }}
    </v-alert>
    <PasswordInput outlined
      :label="$t('models.user.current_password')"
      v-model="currentPassword"
      :override-rules="[]" />
    <PasswordInput outlined
      ref="password"
      :label="$t('models.user.new_password')"
      v-model="password" />
    <PasswordInput outlined
      :label="$t('models.user.password_confirmation')"
      v-model="passwordConfirmation"
      :override-rules="[$vConfirmation('password')]" />
    <v-card-actions>
      <v-spacer />
      <c-btn @click.stop.prevent="submit">{{ $t('settings.account.change_password') }}</c-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import { mapState } from 'vuex'
import AccountApi from '@/api/AccountApi'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'UserPasswordForm',
  mixins: [validatable('form')],
  data () {
    return {
      saveError: false,
      currentPassword: '',
      password: '',
      passwordConfirmation: ''
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    attrs () {
      return {
        currentPassword: this.currentPassword,
        password: this.password
      }
    }
  },
  methods: {
    async onSubmit () {
      this.saveError = false

      const { data, error } = await AccountApi.changePassword(this.attrs)
      if (!error) {
        this.$eventBus.publish('notification', {
          message: this.$t('snackbar.save_success')
        })
      } else {
        if (data.data && data.data.field) {
          const field = this.$t(`models.user.${data.data.field}`)
          const error = this.$t(`remote_errors.${data.errorCode}`, { field, value: data.data.value })
          this.saveError = error
        }
      }
    }
  }
}
</script>
