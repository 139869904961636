<template>
  <v-card flat class="product-avatar">
    <v-row no-gutters>
      <v-col cols="6">
        <c-img class="product-img rounded"
          :src="preview.url"></c-img>
      </v-col>
      <v-col cols="6" class="d-flex flex-column justify-space-between px-2">
        <div class="small-text light-text truncatable">
          <div class="truncate two-lines link">{{ name }}</div>
          <div class="truncate">by @<span class="semibold">{{ username }}</span></div>
        </div>
        <Currency class="bold" :amount="price" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'ProductAvatar',
  props: {
    username: String,
    name: String,
    preview: Object,
    price: Number
  }
}
</script>
<style lang="scss">
.product-avatar {
  height: 125px;
  padding: 8px;
  .product-img {
    max-height: 109px;
  }
}
</style>
