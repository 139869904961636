<template>
  <c-dialog persistent
            width="684"
            v-model="value"
            :actions="false"
            @input="closedialog">
    <div class="text-center mb-6">
      <h1>{{ $t('cancel_subscription.title').toUpperCase() }}</h1>
      <p class="my-4" v-html="$t('cancel_subscription.info', {username})"></p>

      <v-btn style="margin-right: 20px; color: white; background-color: #007000 !important;"
             rounded
             @click.stop="closedialog">
        <v-icon>close</v-icon>
        <span>{{ $t('types.boolean.false') }}</span>
      </v-btn>

      <v-btn color="primary"
             rounded
             @click.stop="cancelSubscription">
        <v-icon>check</v-icon>
        <span>{{ $t('types.boolean.true') }}</span>
      </v-btn>
<!--      <c-btn @click="closedialog" style="margin-right: 30px;" :color="'#007000'">-->
<!--        {{ $t('types.boolean.false') }}-->
<!--      </c-btn>-->
<!--      <c-btn @click="cancelSubscription">-->
<!--        {{ $t('types.boolean.true') }}-->
<!--      </c-btn>-->
    </div>
    <ul class="x-small-text"
        style="list-style-type: none">
      <li>{{ $t('cancel_subscription.note_1') }}</li>
      <li>{{ $t('cancel_subscription.note_2') }}</li>
    </ul>

  </c-dialog>
</template>

<script>
import { UserActions } from '@/store'

export default {
  name: 'CancelSubscriptionModal',
  props: {
    value: { type: Boolean, default: true },
    username: String
  },
  methods: {
    closedialog () {
      this.$emit('input', false)
    },
    async cancelSubscription () {
      await this.$store.dispatch(UserActions.Unsubscribe, this.username)
      this.closedialog()
    }
  }
}
</script>

<style scoped>

</style>
