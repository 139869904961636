var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-2 test small-text",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"item-key":"username","must-sort":"","sort-by":_vm.sort,"sort-desc":_vm.sortDesc,"page":_vm.page,"items-per-page":_vm.perPage,"server-items-length":_vm.totalItems},on:{"update:sortBy":function($event){_vm.sort=$event},"update:sort-by":function($event){_vm.sort=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.changeSort],"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ListViewTitle',{attrs:{"is-title":_vm.noTitle,"title":_vm.tableTitle,"action":_vm.titleAction}}),_c('v-row',{staticClass:"px-2",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","clearable":"","label":_vm.$t('admin.users.username_email')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"px-2",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","items":_vm.userRoles},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"outlined":"","dense":"","clearable":"","hide-details":"","label":_vm.$t('admin.users.promotion_status'),"items":_vm.promotionStatuses},model:{value:(_vm.promotionStatus),callback:function ($$v) {_vm.promotionStatus=$$v},expression:"promotionStatus"}})],1)],1)]},proxy:true},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"no-decoration",attrs:{"to":{ name: 'partner_view', params: { username: item.username } }}},[_c('div',{staticClass:"title-text-2 roboto"},[_vm._v(_vm._s(item.displayName))]),_c('div',{staticClass:"ml-1"},[_vm._v(_vm._s(("@" + (item.username))))])])]}},{key:"item.blocked",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("types.boolean." + (item.blocked || false))))+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(item.isAgent)?_c('v-chip',{class:_vm.roleClass['studio']},[_c('span',[_vm._v(_vm._s(_vm.$t("models.roles.studio")))])]):_c('v-chip',{class:_vm.roleClass[item.role]},[_c('span',[_vm._v(_vm._s(_vm.$t(("models.roles." + (item.role)))))])])]}},{key:"item.agentPayout",fn:function(ref){
var item = ref.item;
return [(!_vm.editMode || _vm.selectedUser.id !== item.id)?_c('span',[_vm._v(_vm._s(_vm.$t(("types.boolean." + (item.agentPayout || false))))+" ")]):_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"dense":""},model:{value:(item.agentPayout),callback:function ($$v) {_vm.$set(item, "agentPayout", $$v)},expression:"item.agentPayout"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-icon',{on:{"click":function($event){return _vm.close()}}},[_vm._v("close")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-icon',{on:{"click":function($event){return _vm.saveEdit(item)}}},[_vm._v("check_circle")])],1)],1)]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [(!_vm.editMode || _vm.selectedUser.id !== item.id)?_c('span',[_vm._v(_vm._s(item.fee || 0)+"% ")]):_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","dense":"","rules":[_vm.$vInteger, _vm.$vMin(0), _vm.$vMax(100)],"label":"% Fee"},model:{value:(item.fee),callback:function ($$v) {_vm.$set(item, "fee", $$v)},expression:"item.fee"}})],1),_c('v-col',{staticStyle:{"margin-top":"auto","margin-bottom":"auto","display":"inherit"},attrs:{"cols":"2"}},[_c('v-icon',{on:{"click":function($event){return _vm.close()}}},[_vm._v("close")]),_c('v-icon',{on:{"click":function($event){return _vm.saveEdit(item)}}},[_vm._v("check_circle")])],1)],1)]}},{key:"item.promotion",fn:function(ref){
var item = ref.item;
return [_c('h4',{style:({
          color:
            item.promotion.status === 'rejected'
              ? '#ff6868'
              : item.promotion.status === 'pending'
              ? '#b0b0b0'
              : item.promotion.status === 'inactive'
              ? '#a3a3a3'
              : '#00dc00'
        })},[_vm._v(" "+_vm._s(item.promotion.status === "approved" ? item.verifiedAt : item.promotion.status)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(!_vm.editMode)?_c('v-icon',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("edit")]):_vm._e(),_c('v-icon',{on:{"click":function($event){return _vm.impersonate(item)}}},[_vm._v("supervised_user_circle")]),(_vm.isAdmin)?_c('confirm-btn',{attrs:{"icon":"","dark":_vm.darkTheme,"question":_vm.$t('agents.delete_partner_question', {
            agent: _vm.agentUsername,
            partner: item.username
          }),"color":"red"},on:{"confirmed":function($event){return _vm.deletePartner(item)}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}])}),_c('c-dialog',{attrs:{"persistent":"","width":"800","actions":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('template',{slot:"title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("admin.users.promotion.review")))])]),_c('PromotionForm',_vm._b({attrs:{"username":_vm.selectedUser.username,"hide-buttons":""},on:{"close":_vm.hideDialog}},'PromotionForm',_vm.selectedUser.promotion,false))],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }