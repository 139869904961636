<template>
  <section>
    <template v-if="showDetails">
      <h1>{{ $t(`products.delivery.detail`) }}</h1>
      <div><strong>{{ $t('products.sale.ref_number', {ref: paymentId}) }}</strong></div>
      <div>
        <strong>{{ details.email }}</strong>
      </div>
      <div>
        {{ details.name }}
      </div>
      <div>
        {{ details.country }}
      </div>
      <div>
        {{ details.city }} - {{ details.postalCode }} ({{ details.province }})
      </div>
      <div>
        {{ details.address }}
      </div>
    </template>
    <template v-if="items.length">
      <h1>{{ $t('models.payment.items') }}</h1>
      <div v-for="item in items"
           :key="`item-detail-${item.resourceId}`"
           class="mt-1">
        <v-row>
          <v-col cols="4">
            <router-link :to="`/${item.resource}/${item.resourceId}`">
              {{ $t(`models.payment.types.${item.resource}`) }}
            </router-link>
          </v-col>
          <v-col cols="4">
            {{ item.name }}
          </v-col>
          <v-col cols="2">
            {{ item.quantity }}
          </v-col>
          <v-col cols="2">
            {{ $currency(item.price * item.quantity, item.currency) }}
          </v-col>
        </v-row>
      </div>
    </template>
  </section>
</template>
<script>

export default {
  name: 'SaleDetail',
  props: {
    paymentId: Number,
    items: Array,
    details: Object
  },
  computed: {
    showDetails() {
      return this.details && !!this.items.find((p) => p.resource === 'products')
    }
  }
}
</script>
