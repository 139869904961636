<template>
  <v-row>
    <v-col cols="12">
      <template v-if="title">
        <div class="d-flex align-center">
          <v-icon large :class="iconClass">{{ icon }}</v-icon>
          <div class="title-text mx-5">
            <slot name="title"></slot>
          </div>
        </div>
        <p class="mx-10">
          <slot name="description"></slot>
        </p>
        <p class="mx-10">
          <slot name="warn-message"></slot>
        </p>
      </template>
      <p class="mx-10">
        <c-img :max-width="maxPicWidth" max-height="200" :src="imagePreview">
          <v-row v-if="file.preview"
            align="end" justify="center"
            class="fill-height py-3">
              <c-btn fab :color="file.uploaded ? 'success' : 'error'"
                :loading="file.loading"
                @click.stop="clearFile(file)">
                <v-icon v-if="file.uploaded">check</v-icon>
                <v-icon v-else>clear</v-icon>
              </c-btn>
          </v-row>
        </c-img>
        <v-row no-gutters align="center">
          <v-col cols="3" class="x-small-text truncate pr-1">
            {{ file.key }}
          </v-col>
          <v-col cols="9" v-if="file.loading">
            <v-progress-linear :value="file.progress" color="indigo"></v-progress-linear>
          </v-col>
          <v-col cols="9" v-else-if="file.uploaded"
            class="success--text small-text">
            {{ $t("file_uploader.uploaded")}}
            <c-btn icon color="error"
                   :loading="file.loading"
                   @click.stop="clearFile(file)">
              <v-icon>delete_forever</v-icon>
            </c-btn>
          </v-col>
          <v-col cols="9" v-else-if="file.error"
            class="error--text small-text">
            {{ $t("file_uploader.error")}}
            <c-btn x-small @click="retryUpload(file)">{{ $t("file_uploader.retry")}}</c-btn>
          </v-col>
        </v-row>
      </p>
      <p class="mx-10 d-flex">
        <FileInput ref="inputFile"
          button autoupload
           :style="{width: isHome ? '100%' : '', marginTop: isHome ? '10px' : ''}"
           :color="isHome ? 'primary' : null"
           :rounded="!isHome"
           :contain="isHome"
           :block="isHome"
          @file-added="fileAdded">
          {{ $t('actions.upload_image') }}
        </FileInput>
      </p>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'DocumentPreview',
  props: {
    title: { type: Boolean, default: true },
    src: String,
    fullWidth: { type: Boolean, default: false },
    isHome: { type: Boolean, default: false }
  },
  data () {
    return {
      file: {}
    }
  },
  computed: {
    maxPicWidth() {
      return this.fullWidth ? "100%" : "350"
    },
    icon () {
      return this.imagePreview ? 'check_circle' : 'check_circle_outline'
    },
    iconClass () {
      return { 'success--text': this.imagePreview }
    },
    imagePreview () {
      return this.file.preview || this.src
    }
  },
  methods: {
    retryUpload (file) {
      this.$refs.inputFile.uploadFile(file)
    },
    fileAdded (file) {
      this.file = file
      this.$emit('file-added', file)
    },
    clearFile (file) {
      this.$refs.inputFile.removeFile(file, 0)
      this.file = {}
      this.$emit('file-added', {})
    }
  }
}
</script>
