<template>
<div class="px-2 pb-10">
  <div>
    <span class="small-text">{{ $t('chat_broadcast.sent_at', { date: broadcast.date }) }}</span>
    <span>{{ $t(`chat_broadcast.${broadcast.receivers}`) }}</span>
  </div>
  <div class="px-4 py-2 regular-text light-text rounded elevation-1 message"
    :class="darkTheme">
    <MessageRowResource v-if="hasReferece"
        :message="broadcast"
        @resource-loaded="emitLoaded"
      />
    <component v-bind:is="showMessage"/>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import MessageRowResource from './MessageRowResource'
import messages from '../locales/all.json'
import project from '@/project'

import { replaceMention } from '@/components/scripts/ReplaceMentions.js'

export default {
  name: 'BroadcastRow',
  components: { MessageRowResource },
  i18n: { messages },
  props: {
    broadcast: Object
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    darkTheme(){
      return this.$vuetify.theme.dark
    },

    hasReferece () {
      return this.broadcast.resource
    },
    decodedMessage () {
      return this.broadcast.message
    },

    showMessage () {
      const comment = replaceMention(this.decodedMessage, project.project, this.broadcast.username)
      return { template: `<div>${comment}</div>` }
    }
  },
  methods: {
    emitLoaded () {
      this.$emit('resource-loaded')
    }
  }
}
</script>
<style lang="scss" scoped>
@use 'src/scss/colors.scss';

.message {
  background-color: colors.$white-three;
  &.dark {
    background-color: #727272 !important;
    border: 1px groove #d4cbcb !important;
    color: white !important;
  }
}
</style>
