<template>
  <div ref="slider" class="carousel-slider">
    <div class="carousel-track">
      <div v-for="(item, index) in items"
        :key="index">
        <slot :index="index" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import Glider from 'glider-js'

export default {
  name: 'CarouselSlider',
  props: {
    items: Array,
    itemClass: [String, Object]
  },
  data () {
    return {
      options: {
        skipTrack: true,
        slidesToScroll: 1,
        slidesToShow: 3.5,
        draggable: true
      }
    }
  },
  watch: {
    items (values) {
      this.$nextTick(() => {
        this.slider.refresh(true)
      })
    }
  },
  mounted () {
    this.slider = new Glider(this.$refs.slider, this.options)
  }

}
</script>
