<template>
  <v-app>
    <v-content>
      <router-view :username="username" :key="$route.fullPath"/>
    </v-content>
    <LegalAgeMessage/>
    <cookies-modal></cookies-modal>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import LegalAgeMessage from "@/components/layouts/LegalAgeMessage.vue";
import CookiesModal from "@/components/cookies/cookies.vue";

export default {
  name: 'FullPage',
  components: {CookiesModal, LegalAgeMessage},
  props: {
    username: String
  },
  computed: {
    ...mapState('preferences', ['darkTheme'])
  },
  mounted () {
    this.$vuetify.theme.dark = this.darkTheme
  }
}
</script>

<style scoped>

</style>
