<template>
  <c-dialog
    width="684"
    :actions="false"
    :value="value"
    @input="emitInput">
    <template #title>
      <h1>{{ $t(`products.auction.bid_title`, { name: product.name }) }}</h1>
    </template>
    <template v-if="!product.bid">
      <p>· {{ $t('products.auction.bid_description.desc_1', { amount: $currency(reservedAmount, currentWallet.currency) }) }}</p>
      <p>· {{ $t('products.auction.bid_description.desc_2') }}</p>
      <p>· {{ $t('products.auction.bid_description.desc_3') }}</p>
      <i18n path="products.auction.bid_description.show_all_terms_conditions">
        <template #link>
          <a target="blank" @click="showTerms = true">{{ $t('products.auction.bid_description.link_text')}}</a>
        </template>
      </i18n>
      <template v-if="!hasWalletAmount">
        <p class="red--text">{{ $t('payments.wallet_not_enought_amount')}}</p>

        <h1 class="small-text" >
          {{ $t('wallet.reload.remember') }}
          <router-link :to="{ name: 'wallet' }"
            class="no-decoration">
            {{ $t('words.here') }}
          </router-link>
        </h1>
      </template>
      <v-checkbox v-else
        :label="$t('products.auction.accept_terms')"
        v-model="acceptedTerms"></v-checkbox>
    </template>
    <template v-if="product.bid">
      <p v-if="product.bid">
        {{ $t('products.auction.already_reserved_amount', { amount: $currency(product.bid.reservedAmount, currentWallet.currency) }) }}
      </p>
      <a target="blank" @click="showTerms = true">{{ $t('products.auction.bid_description.show_terms_conditions')}}</a>
    </template>
    <span v-if="product.currentBidder" class="green--text">
      {{ $t('products.auction.current_bidder') }}
    </span>
    <BidForm v-if="hasWalletAmount"
      :product="product"
      :disabled="!canBid" 
      :loading="loading" 
      @submit="makeBid"
      @close="emitInput" />
    <TermsDialog v-model="showTerms" />
  </c-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { ProductActions } from '@/store'
import BidForm from './BidForm.vue'
import TermsDialog from './TermsDialog.vue'

const ReservedEurAmount = 5000;

export default {
  name: 'BidDialog',
  components: { BidForm, TermsDialog },
  props: {
    value: Boolean,
    product: Object,
  },
  data () {
    return { loading: false, acceptedTerms: false, showTerms: false }
  },
  computed: {
    ...mapGetters('profile', ['currentWallet']),
    reservedAmount () {
      const currency = this.currentWallet.currency
      return currency === 'EUR' ? ReservedEurAmount : ReservedEurAmount * 1.25
    },

    hasWalletAmount () {
      return this.currentWallet.amount >= this.reservedAmount
    },

    canBid () {
      return this.acceptedTerms || !!this.product.bid
    }
  },

  methods: {
    async makeBid (amount) {
      this.loading = true
      await this.$store.dispatch(ProductActions.Bid, { id: this.product.id, amount: amount })
      this.loading = false
    },
    emitInput (value) {
      this.$emit('input', !!value)
    }
  }

}
</script>
