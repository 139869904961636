<template>
  <v-form ref="form" v-model="validForm"
    :dark="false"
    @submit.prevent="submit">
    <v-alert type="error"
      v-if="loginError"
      icon="warning"
      dense outlined>
      {{ loginError }}
    </v-alert>
    <v-text-field outlined autofocus
      type="text"
      :dark="dark"
      :label="$t('login.login_field')"
      name="login"
      prepend-inner-icon="person"
      v-model="login"
      :rules="[$vRequired]"
    ></v-text-field>
    <PasswordInput outlined
      prepend-inner-icon="vpn_key"
      :dark="dark"
      v-model="password"
      :override-rules="[$vRequired]" />
    <v-spacer style="margin-bottom: 15px"></v-spacer>
    <c-btn block
      type="submit"
      @click.stop.prevent="submit"
      css="mb-3">
      {{ $t('actions.login') }}
    </c-btn>
    <c-btn block text
      css="mb-3"
      @click.stop.prevent="emitSignup">
      {{ $t('actions.signup') }}
    </c-btn>
  </v-form>
</template>

<script>
import { SessionActions } from '@/store'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'LoginForm',
  mixins: [validatable('form')],
  props: {
    dark: Boolean
  },
  data () {
    return {
      login: '',
      password: '',
      loginError: null
    }
  },
  methods: {
    emitSignup () {
      this.$emit('signup')
    },
    async onSubmit () {
      const { data, error } = await this.$store.dispatch(SessionActions.Login, {
        login: this.login, password: this.password
      })
      if (error) {
        this.loginError = this.$t(`remote_errors.${data.errorCode}`)
      } else {
        this.$emit('submitted')
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  .btn {
    font-size: 0.8vw;
  }
</style>
