import profile, { Actions as ProfileActions } from '@/store/profile'
import store, { AppActions, PreferenceActions } from '@/store'
import api from '@/api/Api'
import AccountApi from '@/api/AccountApi'
import cookies from '@/cookies'
import Vue from 'vue'

const FetchProfile = `${profile.namespaceName}/${ProfileActions.Fetch}`

const actionsTypes = {
  OAuth: 'oatuh',
  SetSession: 'set_session',
  ResetSession: 'reset_session',
  Login: 'login',
  Logout: 'logout',
  Signup: 'signup',
  FinishSignup: 'finish_signup'
}

const state = {
  isSetup: false,
  token: null,
  authenticated: false
}

const mutations = {
  reset: (state) => {
    state.isSetup = true
    state.authenticated = false
    state.token = null
    api.removeHeader('AuthToken')
    cookies.delete('AuthToken')
  },

  session_setup: (state, isSetup) => {
    state.isSetup = isSetup
  },

  set_token: (state, payload) => {
    state.authenticated = true
    state.token = payload.token
    api.setHeader('AuthToken', payload.token)
    if (payload.cookie) {
      cookies.set('AuthToken', payload.token)
    }
  }
}

const actions = {
  [actionsTypes.ResetSession]: async (context) => {
    var authToken = cookies.get('AuthToken')
    var reset = true
    var user = null
    if (authToken) {
      api.setHeader('AuthToken', authToken)
      const { data, error } = await context.dispatch(FetchProfile, null, { root: true })
      reset = error
      user = data
    }

    if (reset) {
      context.commit('reset')
    } else {
      context.commit('set_token', { token: authToken })
      const { data, error } = await store.dispatch(AppActions.FetchMasterData)
      if (!error) {
        store.dispatch(PreferenceActions.SetInitTags, { tags: data.tags, profile: user })
      }
      Vue.prototype.$ws.connect(authToken)
    }
    context.commit('session_setup', true)
  },

  [actionsTypes.OAuth]: async (context, token) => {
    const response = await AccountApi.oauth(token)
    if (!response.error) {
      await context.dispatch(actionsTypes.SetSession, response.data)
    }
    return response
  },

  [actionsTypes.Login]: async (context, credentials) => {
    const response = await AccountApi.login(credentials.login, credentials.password)
    if (!response.error) {
      await context.dispatch(actionsTypes.SetSession, response.data)
      var { data } = await store.dispatch(AppActions.FetchMasterData)
      const tags = data.tags
      data = await context.dispatch(FetchProfile, null, { root: true })
      const user = data.data
      store.dispatch(PreferenceActions.SetInitTags, {tags: tags, profile: user})
    }
    return response
  },

  [actionsTypes.Signup]: async (context, user) => {
    const response = await AccountApi.signup(user)
    if (!response.error) {
      await context.dispatch(actionsTypes.SetSession, response.data)
      store.dispatch(PreferenceActions.UpdateTagPrefs, false)
    }
    return response
  },

  [actionsTypes.FinishSignup]: async (context, user) => {
    const response = await AccountApi.finishSignup(user)
    await context.dispatch(FetchProfile, null, { root: true })
    return response
  },

  [actionsTypes.Logout]: async (context) => {
    AccountApi.logout()
    context.dispatch('reset', null, { root: true })
    await Vue.prototype.$ws.disconnect()
    document.location.href = "/"
  },

  [actionsTypes.SetSession]: async (context, user) => {
    context.commit('set_token', { token: user.authToken, cookie: true })
    var { data } = await store.dispatch(AppActions.FetchMasterData)
    const tags = data.tags
    store.dispatch(PreferenceActions.SetInitTags, {tags: tags, profile: user})
    await context.dispatch(FetchProfile, null, { root: true })
    Vue.prototype.$ws.connect(user.authToken)
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'session',
  namespaced: true,
  state,
  mutations,
  actions
}
