<template>
  <v-hover
    v-slot:default="{ hover }"
    class="mb-2 rounded">
    <v-card
      v-bind="$attrs"
      :elevation="hover ? 2 : 0"
      :href="href"
      :to="link"
      :target="mustRedirect ? '_blank' : ''"
      class="pa-1 text-center">
      <UserAvatar :user="user"
        style="width: 100%;"
        vertical center>
        <template #name><span>@{{ user.username }}</span></template>
        <template #username>
          <PaymentButton v-if="user.subscribable"
            x-small inverted
            outlined
            transform="none"
            subscription
            color="secondary"
            hover-color="primary"
            :isProfile="isProfile"
            :hover-outlined="false"
            :resource="user" />
            <btn-video-call :user="user" class="mb-2"/>
        </template>
      </UserAvatar>
    </v-card>
  </v-hover>
</template>
<script>

import UserAvatar from '@/components/users/UserAvatar'
import PaymentButton from '@/components/payments/PaymentButton'
import config from '@/project'

export default {
  name: 'VerticalCard',
  components: { UserAvatar, PaymentButton },
  props: {
    user: Object,
    isProfile: Boolean
  },
  computed: {
    mustRedirect () {
      return config.payments.darkfans_redirect && this.user.subscribed
    },
    link () {
      if (this.mustRedirect) return undefined
      return { name: 'influencer', params: { username: this.user.username } }
    },
    href () {
      if (this.mustRedirect) return `https://darkfans.com/${this.user.username}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
      return undefined
    }
  }
}
</script>
