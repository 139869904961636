<template>
  <c-dialog
    persistent
    :value="value"
    :actions="actions"
    :closable="closable"
    :fullscreen="fullscreen"
    v-bind="$attrs"
    v-on:input="$emit('input', $event)"
  >
    <div class="pt-5">
      <slot name="title"></slot>
      <slot name="subtitle"></slot>
      <slot name="body"></slot>
      <slot name="checkbox"></slot>
      <slot name="continue"></slot>
    </div>
    <template #actions v-if="goAhead">
      <c-btn color="success"
             @click="accept">
        {{ $t('actions.continue') }}
      </c-btn>
    </template>
  </c-dialog>
</template>
<script>
export default {
  name: 'GenericWarning',
  props: {
    title: String,
    actions: { type: Boolean, default: true },
    closable: { type: Boolean, default: true },
    value: Boolean,
    body: String,
    fullscreen: Boolean,
    goAhead: { type: Boolean, default: true }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    accept () {
      this.$emit('accept')
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss">
  .warning-title {
    text-align: center !important;
    font-weight: bold;
  }
  .warning-subtitle {
    text-align: center !important;
    font-weight: normal;
  }
  p.normal-desc {
    font-size: 14px !important;
  }
  .underlined {
    text-decoration: underline;
  }
</style>
