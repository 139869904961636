<template>
  <section>
    <v-list-item v-if="showTitle">
      <v-list-item-icon>
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <span class="title-text semibold">{{ title }}</span>
      </v-list-item-content>
      <v-list-item-action v-if="showAll">
        <c-btn text :to="showAllLink">{{ $t('actions.show_all') }}</c-btn>
      </v-list-item-action>
    </v-list-item>
    <template v-if="isEmpty">
      <slot name="empty">
        <h1 class="title-text px-5">{{ $t('listview.no_content') }}</h1>
      </slot>
    </template>
    <template v-else>
      <div class="d-flex align-center">
        <c-btn
          icon large
          :loading="loading"
          :disabled="disableFirstPage"
          @click="previousPage">
          <v-icon class="giant-text">arrow_back_ios</v-icon>
        </c-btn>
        <v-row class="flex-grow" align="start">
          <template v-for="(item, index) in displayingItems">
            <v-col :class="{ 'show-all': item._showAllLink }"
              :key="item.id"
              :cols="itemCol">
              <template v-if="!item._showAllLink"><slot :item="item" :index="index + internalPosition" /></template>
              <template v-else>
                <c-btn text :to="showAllLink">{{ $t('actions.show_all') }}</c-btn>
              </template>
            </v-col>
          </template>
        </v-row>
        <c-btn size="36"
          icon large
          :loading="loading"
          :disabled="disableLastPage"
          @click="nextPage">
          <v-icon class="giant-text">arrow_forward_ios</v-icon>
        </c-btn>
      </div>
    </template>
  </section>
</template>
<script>
export default {
  name: 'Slider',
  props: {
    icon: String,
    title: String,
    loading: Boolean,
    items: { type: Array, default: () => [] },
    showAllLink: [String, Object],
    perPage: Number
  },
  data () {
    return {
      internalPosition: 0,
      internalPage: 0
    }
  },
  computed: {
    isEmpty () {
      return !this.loading && this.items.length === 0
    },
    allItems () {
      const items = [...this.items]
      if (this.showAll) {
        items.push({ _showAllLink: true })
      }

      return items
    },
    totalLength () {
      return this.allItems.length
    },
    showTitle () {
      return this.icon || this.title || this.showAllLink
    },
    showAll () {
      return !this.isEmpty && this.showAllLink
    },
    disableFirstPage () {
      return this.internalPosition === 0
    },
    disableLastPage () {
      return this.internalPosition + this.perPage >= this.totalLength
    },
    itemCol () {
      return 12 / this.perPage
    },
    displayingItems () {
      return this.allItems.slice(this.internalPosition, this.internalPosition + this.perPage)
    }
  },
  methods: {
    previousPage () {
      const newPosition = this.internalPosition - this.perPage
      this.internalPosition = Math.max(0, newPosition)
    },
    nextPage () {
      this.internalPosition = this.internalPosition + this.perPage
    }
  }
}
</script>
<style scoped>
  .show-all {
    align-self: center;
  }
</style>
