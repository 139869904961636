import { PostActions } from '@/store'
import PostApi from '@/api/PostApi'
import CursorService from '@/components/mixins/cursor_service'

const deconstructWatchers = (cursors) => {
  const properties = {}

  cursors.forEach(cursor => {
    properties[cursor.filter || 'query'] = {
      deep: true,
      handler: function (value) {
        this[cursor.name || 'cursor'].query = value
      }
    }
  })
  return properties
}

export default (options = {}) => {
  const { cursor, cursors } = options
  const innerCursors = []
  if (cursor === true) {
    innerCursors.push({ name: 'cursor', filter: 'query' })
  }

  if (cursors && cursors.length) {
    innerCursors.push(...cursors)
  }

  const data = { }
  innerCursors.forEach(cursor => {
    data[cursor.name] = {}
  })
  return {
    api: PostApi,
    data () {
      return data
    },
    watch: {
      ...deconstructWatchers(innerCursors)
    },
    methods: {
      findPost (id, force) {
        return this.$store.dispatch(PostActions.Fetch, { id, force })
      },

      searchPosts (query) {
        return this.$store.dispatch(PostActions.Search, query)
      },

      subscribeMutation (mutation, handler) {
        this.subscriptions = this.subscriptions || []
        const subscription = this.$store.subscribe((storeMutation, state) => {
          if (storeMutation.type === mutation) {
            handler(storeMutation, state)
          }
        })
        this.subscriptions.push(subscription)
      },

      subscribePostCreated () {
        this.subscribeMutation('posts/insert_post', (mutation, state) => {
          this.onPostCreated(state.posts.posts[mutation.payload.id])
        })
      },

      subscribePostDeleted () {
        this.subscribeMutation('posts/remove_post', (mutation) => {
          this.onPostDeleted(mutation.payload)
        })
      },

      onPostCreated () {
      },

      onPostDeleted () {
      }
    },
    beforeMount () {
      this.subscriptions = this.subscriptions || []
      this.cursors = innerCursors.map(cursor => {
        const c = CursorService(this.$store, {
          query: this[cursor.filter || 'query'],
          action: cursor.action || PostActions.Search
        })
        c.$mount()
        this[cursor.name] = c
        return c
      })
    },
    destroyed () {
      this.cursors.forEach(c => c.$destroy())
      this.subscriptions.forEach(unsubscribe => unsubscribe())
    }
  }
}
