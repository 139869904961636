<template>
  <section>
    <v-col class="center">
      <croppa
        ref="croppa"
        :quality="quality"
        :width="croppaViewPortWidth"
        :height="croppaViewPortHeight"
        :show-loading="true"
        :placeholder="$t('actions.placeholder_select_file')"
        :placeholder-font-size="18"
        :prevent-white-space="cover"
        :show-remove-button="true"
        :remove-button-size="16"
        @file-choose="getFile"
        v-model="myCroppa"></croppa>

      <div class="btns">
        <c-btn text rounded
               active-class="primary--text"
               transform="none"
               @click="myCroppa.rotate()">
          <v-icon>rotate_left</v-icon>
        </c-btn>
        <c-btn text rounded
               active-class="primary--text"
               transform="none"
               @click="myCroppa.rotate(-1)">
          <v-icon>rotate_right</v-icon>
        </c-btn>
        <c-btn text rounded
               active-class="primary--text"
               transform="none"
               @click="myCroppa.flipX()">
          <v-icon>swap_horiz</v-icon>
        </c-btn>
        <c-btn text rounded
               active-class="primary--text"
               transform="none"
               @click="myCroppa.flipY()">
          <v-icon>swap_vert</v-icon>
        </c-btn>
        <c-btn text rounded
               active-class="primary--text"
               transform="none"
               @click="myCroppa.zoomIn()">
          <v-icon>zoom_in</v-icon>
        </c-btn>
        <c-btn text rounded
               active-class="primary--text"
               transform="none"
               @click="myCroppa.zoomOut()">
          <v-icon>zoom_out</v-icon>
        </c-btn>
      </div>

    </v-col>

    <v-col style="margin: auto" cols="2">
      <v-btn rounded color="primary" @click="saveChanges">
        {{ $t('actions.save') }}
      </v-btn>
    </v-col>

  </section>

</template>

<script>
import 'vue-croppa/dist/vue-croppa.css'
import 'vue-slider-component/theme/antd.css'
import { saveAs } from 'file-saver'
import { mapState } from 'vuex'
import FileManager from '@/components/mixins/file_manager'

export default {
  name: 'CCroppa',
  mixins: [FileManager],
  props: {
    ratio: { type: Number, default: 1 },
    width: { type: Number, default: 500 },
    height: { type: Number, default: 0 },
    restore: { type: Boolean, default: false },
    cover: { type: Boolean, default: false }
  },
  data () {
    return {
      quality: 1,
      imageWidth: null,
      myCroppa: null
    }
  },
  watch: {
    restore: function () {
      this.myCroppa.remove()
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    croppaViewPortWidth () {
      return this.width ? this.width : this.croppaWidth
    },
    croppaViewPortHeight () {
      return this.height ? this.height : this.croppaViewPortWidth / this.ratio
    }
  },
  methods: {
    saveLocal (arrayBuffer) {
      var blob = new Blob([arrayBuffer.buffer], { type: 'image/png' })
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      const name = this.$t('project') + date

      saveAs(blob, name)
    },
    resizeContainer () {
      const croppa = this.$refs.croppa
      this.croppaWidth = croppa.$refs.wrapper.clientWidth
    },

    getFile (file) {
      this.resizeContainer()
      const img = new Image()
      img.onload = () => {
        this.imageWidth = img.naturalWidth
        this.quality = this.imageWidth / this.croppaWidth
      }
      img.src = URL.createObjectURL(file)
    },

    async saveChanges () {
      const dataUrl = this.myCroppa.generateDataUrl().substring('data:image/png;base64,'.length)
      var data = atob(dataUrl)
      var asArray = new Uint8Array(data.length)

      for (var i = 0, len = data.length; i < len; ++i) {
        asArray[i] = data.charCodeAt(i)
      }

      var blob = new Blob([asArray.buffer], { type: 'image/png' })

      var file = new File([blob],
        (this.avatar ? 'avatar-' : 'banner-') + this.currentUser.username + '.png',
        { lastModified: new Date() }
      )

      this.clear()
      await this.addFile(file)
      this.myCroppa.remove()
      this.$emit('generatedFile', this.getFiles())
    }
  }
}
</script>

<style lang="scss" scoped>

  .center {
    min-height: auto;
    margin: auto;
    text-align: center;
  }

</style>
