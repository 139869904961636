<template>
  <v-form ref="accountForm" v-model="validForm"
          :dark="false"
          @submit.prevent="submit">
    <v-col :cols="isMobile ? 12 : 9" style="margin: auto">
      <v-row dense>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="{paddingRight: !isMobile ? '10px' : 'null'}">
          <div class="campo-label">{{ $t('models.user.country') }}</div>
          <v-autocomplete
            outlined
            persistent-hint
            dense
            rounded
            :placeholder="$t('models.user.country')"
            :items="countries"
            v-model="country"
            item-text="text"
            item-value="value"
            :dark="isDark"
            :rules="[$vRequired]"
            @change="lockDocumentCountry"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="center" dense>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="{paddingRight: !isMobile ? '10px' : 'null'}"
          @click="checkModalCountry">
          <div class="campo-label">{{ $t('models.influencer_request.id_document_type') }}</div>
          <v-autocomplete
            outlined dense
            rounded
            :disabled="isSpanish"
            v-model="idDocumentType"
            :items="documentTypes"
            :dark="isDark"
            :rules="[$vRequired, validateDni]">
          </v-autocomplete>
        </v-col>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="{paddingRight: !isMobile ? '10px' : 'null'}">
          <div class="campo-label">{{ $t(`models.influencer_request.${isMexico ? 'id_document_number_curp' : 'id_document_number'}`) }}</div>
          <v-text-field
            outlined dense
            persistent-hint
            rounded
            :placeholder="$t('models.influencer_request.id_document_number')"
            v-model="idDocumentNumber"
            :dark="isDark"
            :rules="[$vRequired, validateDni, validDni]"/>
        </v-col>

        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="{paddingRight: !isMobile ? '10px' : 'null'}"
          @click="checkModalCountry">
          <div class="campo-label">{{ $t('models.influencer_request.id_document_country') }}</div>
          <v-autocomplete
            outlined dense
            :dark="isDark"
            :disabled="isSpanish"
            rounded
            :items="$t('countries')"
            v-model="idDocumentCountry"
            :rules="[$vRequired, validateDni]">
          </v-autocomplete>
          <BlockedCountryDialog v-model="showBlockedCountryDialog" />
        </v-col>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="{paddingRight: !isMobile ? '10px' : 'null'}">
          <div class="campo-label">{{ $t('models.influencer_request.id_document_expiration') }}</div>
          <date-picker-input
            outlined dense
            hide-details
            noLabel
            rounded
            :min="dateExpiration"
            v-model="idDocumentExpiration"
            :dark="isDark"
            :rules="dateExpirationRequired"
          />
          <v-checkbox
            hide-details
            outlined
            dense
            class="mt-0"
            v-model="perm_doc"
            :label="$t('models.user.perm_doc')"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col :cols="isMobile ? 12 : 9" style="margin: auto">
      <v-row dense>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="{paddingRight: !isMobile ? '10px' : 'null'}">
          <div class="campo-label">{{ $t('models.user.name') }}</div>
          <v-text-field
            outlined
            rounded
            persistent-hint
            dense
            :dark="isDark"
            :placeholder="$t('models.user.name')"
            v-model="name"
            :rules="[$vRequired]"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="paddingLeft">
          <div class="campo-label">{{ $t('models.user.surname') }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            rounded
            :dark="isDark"
            :placeholder="$t('models.user.surname')"
            v-model="surname"
            :rules="[$vRequired]"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="paddingLeft">
          <div class="campo-label">{{ $t('models.user.birthdate') }}</div>
          <date-picker-input
            outlined dense
            noLabel
            rounded
            :rules="[$vRequired,$vIsOver18]"
            v-model="birthdate"
            :dark="isDark"
          />
        </v-col>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="paddingLeft">
        </v-col>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="paddingLeft">
          <div class="campo-label">{{ $t('models.user.city') }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            rounded
            :dark="isDark"
            :placeholder="$t('models.user.city')"
            v-model="city"
            :rules="[$vRequired]"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="{paddingRight: !isMobile ? '10px' : 'null'}">
          <div class="campo-label">{{ $t('models.user.province') }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            rounded
            :dark="isDark"
            :placeholder="$t('models.user.province')"
            v-model="province"
            :rules="[$vRequired]"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 11" :sm="isMobile ? 12 : 6" :style="paddingLeft">
          <div class="campo-label">{{ $t('models.user.postal_code') }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            rounded
            :dark="isDark"
            :placeholder="$t('models.user.postal_code')"
            v-model="postalCode"
            :rules="[$vRequired]"></v-text-field>
        </v-col>
        <v-col :cols="isMobile ? 12 : 11" sm="12">
          <div class="campo-label">{{ $t('models.user.address') }}</div>
          <v-text-field
            outlined
            persistent-hint
            dense
            rounded
            :dark="isDark"
            :placeholder="$t('models.user.address')"
            v-model="address"
            :rules="[$vRequired]"></v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <v-col :cols="isMobile ? 12 : 9" style="margin: auto">
      <v-row>
        <v-col :cols="6" style="text-align: left">
          <v-btn height="52"
                 rounded
                 :loading="submitting"
                 :block="isMobile"
                 color="#D5D5D5"
                 @click="$emit('back')"
                 style="color: white; padding: 17px 29px;">{{ $t('actions.back') }}
          </v-btn>
        </v-col>
        <v-spacer v-if="!isMobile"></v-spacer>
        <v-col cols="auto" style="text-align: right">
          <v-btn height="52"
                 rounded
                 :loading="submitting"
                 :block="isMobile"
                 :dark="isDark"
                 color="#41B849"
                 :disabled="!validForm"
                 @click="emitData">
            <span style="color: white; padding: 17px 29px;">{{ $t('actions.step', {count: 3}) }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-form>
</template>

<script>
import {mapState} from 'vuex'
import {ProfileActions} from '@/store'
import DniValidator from '@/dni_validator';
import BlockedCountryDialog from './BlockedCountryDialog'

export default {
  name: 'StepTwo',
  components: { BlockedCountryDialog },
  props: {
    isDark: Boolean,
    type: Object
  },
  data() {
    return {
      showBlockedCountryDialog: false,
      validDni: true,
      dateExpiration: new Date().toISOString().substr(0, 10),
      submitting: false,
      documentTypes: [
        {
          text: this.$t('models.influencer_request.id_document_types.passport'),
          value: 'passport'
        },
        {
          text: this.$t('models.influencer_request.id_document_types.id_document'),
          value: 'id_document'
        },
        {
          text: this.$t('models.influencer_request.id_document_types.cif'),
          value: 'cif'
        },
        {
          text: this.$t('models.influencer_request.id_document_types.driver_license'),
          value: 'driver_license'
        }
      ],
      validForm: false,
      name: null,
      surname: null,
      day: null,
      month: null,
      year: null,
      country: null,
      postalCode: null,
      province: null,
      city: null,
      address: null,
      idDocumentType: null,
      idDocumentNumber: null,
      idDocumentCountry: null,
      idDocumentExpiration: null,
      samePersonalData: true,
      managerName: null,
      managerSurname: null,
      managerDay: null,
      managerMonth: null,
      managerYear: null,
      managerCountry: null,
      managerPostalCode: null,
      managerProvince: null,
      managerCity: null,
      managerAddress: null,
      managerDocumentType: null,
      managerDocumentId: null,
      birthdate: null,
      perm_doc: false
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser']),
    ...mapState('withdraws', ['withdrawData']),
    isSpanish() {
      return this.country === 'ES'
    },
    countries() {
      return this.$t('countries_v2')
    },
    paddingRight() {
      if (this.isMobile) return
      return {
        paddingRight: '10px'
      }
    },
    paddingLeft() {
      if (this.isMobile) return
      return {
        paddingLeft: '10px'
      }
    },
    isMexico () {
      return this.idDocumentCountry === 'MX'
    },
    newPersonalData() {
      return {
        documentType: this.idDocumentType,
        documentNumber: this.idDocumentNumber,
        documentCountry: this.idDocumentCountry,
        documentExpiration: this.perm_doc ? '2999-01-01' : this.idDocumentExpiration,
        name: this.name,
        surname: this.surname,
        fullName: this.name + ' ' + this.surname,
        birthdate: this.birthdate,
        country: this.country,
        postalCode: this.postalCode,
        province: this.province,
        city: this.city,
        address: this.address
      }
    },
    dateExpirationRequired() {
      return this.perm_doc ? [] : [this.$vRequired]
    },

    validateDni() {
      if (this.idDocumentType === 'id_document' && this.idDocumentCountry === 'ES' && !DniValidator.isValid(this.idDocumentNumber)) {
        this.validDni = this.$t('errors.invalidNif')
      } else {
        this.validDni = true
      }
      return true
    }

  },
  watch: {
    idDocumentExpiration(value) {
      if (value.length > 0) {
        this.perm_doc = false
      }
    },
    perm_doc(value) {
      if (value) {
        this.idDocumentExpiration = null
      }
    }
  },
  methods: {
    checkModalCountry() {
      this.showBlockedCountryDialog = this.country === 'ES'
    },
    lockDocumentCountry() {
      if (this.country === 'ES') {
        this.idDocumentType = 'id_document'
        this.idDocumentCountry = 'ES'
      }
    },
    getCurrentYear() {
      return new Date().getFullYear()
    },
    async emitData() {
      if (this.validForm) {
        this.submitting = true
        await this.updateFormPersonal(this.newPersonalData)
        this.submitting = false
      }
    },
    async updateFormPersonal(form) {
      this.formPersonal = {...form}
      await this.$store.dispatch(
        ProfileActions.UpdatePersonalData,
        this.formPersonal
      )
      this.$emit('next')
    }
    /* submit () {
      this.submitting = true
      const payload = { form: this.newPersonalData, withdrawData: this.newWithdrawData }
      this.$emit('next', payload)
    } */
  },
  mounted() {
    this.name = this.currentUser.name
    this.surname = this.currentUser.surname
    this.country = this.currentUser.country
    this.postalCode = this.currentUser.postalCode
    this.province = this.currentUser.province
    this.city = this.currentUser.city
    this.address = this.currentUser.address
    this.birthdate = this.currentUser.birthdate
    this.idDocumentType = this.currentUser.documentType
    this.idDocumentNumber = this.currentUser.documentNumber
    this.idDocumentCountry = this.currentUser.documentCountry
    this.$gtm('star_registration_step2', undefined, this.currentUser.email)
    this.lockDocumentCountry()
    if ((this.currentUser.documentExpiration || "").includes('2999')) {
      this.perm_doc = true
      this.idDocumentExpiration = null
    } else {
      this.idDocumentExpiration = this.currentUser.documentExpiration
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
</script>

<style lang="scss" scoped>

.campo-label {
  text-align: left;
  font-size: 16px;
  padding-left: 10px;
  font-family: Quicksand, sans-serif;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  font-family: Quicksand, sans-serif;
}

</style>

