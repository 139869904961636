<template>
  <section>
    <SubscriptionConfigView class="mb-4"/>
    <template v-if="taggable">
      <h1 class="mb-1">{{ $t('tags.content_info') }}
        <c-btn x-small
               class="mb-1"
               @click="showTagDialog = true">
          {{ $t('actions.change') }}
        </c-btn>
      </h1>
      <v-chip v-for="tag in currentUser.tags"
              :key="tag.name"
              outlined
              color="primary"
              class="mx-1">
        {{ $t(`tags.${tag.name}`) }}
      </v-chip>
      <div class="mb-5"></div>
    </template>
    <TagProfileDialog :dialog.sync="showTagDialog"/>
    <FeaturedUserSection class="mb-2"/>
    <BlockedCountryInfo
      class="mb-5"
      :description="false">
      <template #actions>
        <c-btn x-small
               class="mb-1"
               @click="showCountryDialog = true">
          {{ $t('actions.change') }}
        </c-btn>
      </template>
    </BlockedCountryInfo>
    <BlockedUsersInfo editable
                      class="mb-5"/>
    <CountryProfileDialog :dialog.sync="showCountryDialog"/>

  </section>
</template>
<script>
import { mapState } from 'vuex'
import SubscriptionConfigView from '@/components/subscriptions/views/SubscriptionConfigView'
import TagProfileDialog from '@/components/tags/TagProfileDialog'
import FeaturedUserSection from '@/components/featured/FeaturedUserSection'
import BlockedUsersInfo from '@/components/users/blocking/BlockedUsersInfo'
import BlockedCountryInfo from '@/components/countries/BlockedCountryInfo'
import CountryProfileDialog from '@/components/countries/CountryProfileDialog'

export default {
  name: 'UserBankView',
  components: {
    SubscriptionConfigView,
    TagProfileDialog,
    FeaturedUserSection,
    BlockedUsersInfo,
    BlockedCountryInfo,
    CountryProfileDialog
  },
  props: {
    skipable: Boolean,
    dark: {
      type: Boolean,
      default: null
    },
    taggable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showTagDialog: false,
      showCountryDialog: false,
      showFeaturedUsers: false
    }
  },

  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer', 'promotion'])
  },

  methods: {
    skip () {
      this.$emit('skip')
    }
  }
}
</script>
<style lang="scss" scoped>
.select-component {
  .country {
    max-width: 63%;
    margin-right: auto;
  }

  .currency {
    max-width: 33%;
    margin-left: auto;
  }
}
</style>
