<template>
  <div></div>
</template>
<script>
import config from "@/project";

export default {
  created() {
    const query = this.$route.query
    const queryString = Object.keys(query)
      .map(key => `${key}=${encodeURIComponent(query[key])}`)
      .join('&');
    window.location.href = `https://${config.locales.site}/campaigns/promocode/apolonia/20euros.html?${queryString}`
  }
}
</script>
