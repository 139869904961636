<template>
  <audio controls :src="src" class="message-audio" />
</template>
<script>
export default {
  name: "OnlyOneAudio",
  props: {
    src: String
  }
};
</script>

<style lang="scss" scoped>
.message-audio {
  display: flow-root;
  max-width: 100%;
}
</style>
