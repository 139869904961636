import api from '@/api/Api'
import languageTraslate from '@/api/conversations/languageTranslate.json'

export default {
  create (username) {
    return api.post('/conversations', { username })
  },
  list (query, options) {
    return api.get('/conversations', query, options)
  },
  checkRead (id) {
    return api.put(`/conversations/${id}`)
  },
  findConversations (query) {
    return api.get('/conversations', query)
  },
  listMessageSearch (query) {
    return api.post(`/conversations/${query.id}/search`, query)
  },
  listMessage (query) {
    return api.get(`/conversations/${query.id}/messages`, query)
  },
  createMessage (id, message) {
    return api.post(`/conversations/${id}/messages`, message)
  },
  preferences (id, preferences) {
    return api.put(`/conversations/${id}/preferences`, preferences)
  },
  listAttachments (query) {
    return api.get(`/conversations/${query.id}/attachments`, query)
  },
  updateSetting (setting) {
    return api.put('/conversations/settings', setting)
  },
  settings () {
    return api.get('/conversations/settings')
  },
  findMessage (conversationId, conversationMessageId) {
    return api.get(
      `/conversations/${conversationId}/messages/${conversationMessageId}`
    )
  },
  buyMessage (conversationId, conversationMessageId) {
    return api.post(
      `/conversations/${conversationId}/messages/${conversationMessageId}/buy`
    )
  },
  deleteMessage (conversationId, conversationMessageId) {
    return api.delete(
      `/conversations/${conversationId}/messages/${conversationMessageId}`
    )
  },
  deleteNotification (conversationId, conversationMessageId) {
    return api.delete(
      `/conversations/${conversationId}/notification/${conversationMessageId}`
    )
  },
  createBroadcast (broadcast) {
    return api.post('/conversations/broadcast', broadcast)
  },
  getBroadcast (query) {
    return api.get('/conversations/broadcast', query)
  },
  removeBroadcast (broadcastId) {
    return api.delete(`/conversations/broadcast/${broadcastId}`)
  },
  deleteConversations (id) {
    return api.delete(`/conversations/${id}`)
  },
  createAutomessage (automessage) {
    return api.post('/conversations/automessage', automessage)
  },
  getAutomessage () {
    return api.get('/conversations/automessage')
  },
  countUnreadMessages () {
    return api.get('/conversations/unread')
  },

  // album
  createAlbum (album) {
    return api.post('/conversations/album', album)
  },
  findAlbum (query, option) {
    return api.get('/conversations/album', query, option)
  },
  updateAlbum (id, album) {
    return api.put(`/conversations/album/${id}`, album)
  },
  deleteAlbum (id) {
    return api.delete(`/conversations/album/${id}`)
  },
  // user list
  createList (list) {
    return api.post('/conversations/list', list)
  },
  findList (query, options) {
    return api.get('/conversations/list', query, options)
  },
  updateList (id, list) {
    return api.put(`/conversations/list/${id}`, list)
  },
  deleteList (id) {
    return api.delete(`/conversations/list/${id}`)
  },

  deleteConversationsBulk (conversations) {
    return api.deleteBody('/conversations', conversations)
  },
  readChatsBulk (conversations) {
    return api.post('/conversations/read', conversations)
  },

  getStatistics(username) {
    return api.get('/statistics/follower', username)
  },
  getLanguageTranslate () {
    return languageTraslate
  },
  
  searchTextConversation(query) {
    return api.post(`/conversations/${query.id}/search`, query)
  },
}
