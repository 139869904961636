import api from '@/api/Api'

export default {

    call(username){
        return api.post(`/videochats/${username}`)
    },
    rejectCall(username){
        return api.post(`/videochats/${username}/reject`)
    },

}