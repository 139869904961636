<template>
  <section>
    <v-form v-model="validForm"  ref="form"
      @submit.prevent="submit">
      <ListViewTitle
        :title="$t('mail_preferences.title')"
        icon="email"
      />
      <v-row no-gutters>
        <v-col cols="12" v-if="isInfluencer">
          <v-checkbox color="primary"
            hide-details
            :label="$t('mail_preferences.notify_new_subscriber')"
            v-model="config.notifyNewSubscriber"></v-checkbox>
          <v-checkbox color="primary"
            hide-details
            :label="$t('mail_preferences.notify_new_follower')"
            v-model="config.notifyNewFollower"></v-checkbox>
          <v-checkbox color="primary"
            hide-details
            :label="$t('mail_preferences.notify_new_message')"
            v-model="config.notifyNewMessage"></v-checkbox>
          <v-checkbox color="primary"
            hide-details
            :label="$t('mail_preferences.notify_reminder')"
            v-model="config.notifyReminder"></v-checkbox>
          <v-checkbox color="primary"
                      hide-details
                      :label="$t('application.menu.marketing')"
                      v-model="config.acceptedMarketing"></v-checkbox>
          <!--
            <v-checkbox color="primary"
            hide-details
            :label="$t('mail_preferences.notify_weekly_summary')"
            v-model="config.notifyWeeklySummary"></v-checkbox>
          -->
        </v-col>
        <v-col cols="12" v-else>
          <v-checkbox color="primary"
            hide-details
            :label="$t('mail_preferences.notify_new_star_post')"
            v-model="config.notifyNewStarPost"></v-checkbox>
          <v-checkbox color="primary"
            hide-details
            :label="$t('mail_preferences.notify_new_message')"
            v-model="config.notifyNewMessage"></v-checkbox>
          <v-checkbox color="primary"
                      hide-details
                      :label="$t('application.menu.marketing')"
                      v-model="config.acceptedMarketing"></v-checkbox>
          <!--
          <v-checkbox color="primary"
            hide-details
            :label="$t('mail_preferences.notify_new_star_content')"
            v-model="config.notifyNewStarContent"></v-checkbox>
          -->
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <c-btn @click.stop.prevent="submit">{{ $t('actions.save') }}</c-btn>
      </v-card-actions>
    </v-form>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import PreferencesApi from '@/api/PreferencesApi'
import validatable from '@/components/mixins/validatable'
import messages from '../locales/all.json'

export default {
  name: 'MailPreferencesForm',
  mixins: [validatable('form')],
  i18n: { messages },
  data () {
    return {
      config: {}
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isInfluencer'])
  },
  watch: {
    currentUser (user) {
      this.config = { ...user.mailPreferences }
    }
  },
  methods: {
    async onSubmit () {
      this.loading = true
      await PreferencesApi.updateMailPreferences(this.config)
      this.loading = false
    }
  },
  mounted () {
    this.config = { ...this.currentUser.mailPreferences }
    this.$store.dispatch(ProfileActions.Fetch)
  }
}
</script>
