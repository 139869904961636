<template>
  <div id="modal2" class="fondo-trans" >
    <div class="popup redondo">

      <!-- Modal2 -->
      <div class="cont-modal2" :style="cssStyle">
        <v-btn dark style="position: absolute; left: 90%;" icon @click="$emit('close')"><v-icon>close</v-icon></v-btn>
        <div class="separa telefono"></div>
        <br>
        <!--<v-col cols="8" style="margin: auto">
          <v-img src="images/blackfriday.svg"></v-img>
        </v-col>-->
        <div class="separa telefono"></div>
        <div class="head-contmodal destacado">
          <p class="subtitulo-big blanco center fuente-destacada1" :style="cssTitle" v-html="$t(`modal.special.title`)"></p>
        </div>
        <br>
        <div style="width: 100px; height: 2px; background-color: #ffffff; margin: 0 auto"></div>
        <br>
        <div class="contenedor full black-text">
          <div class="head-contmodal destacado">
            <p class="subtitulo-big blanco center blanco fuente-destacada2">{{$t(`modal.special.desc1`)}}.</p>
            <p class="subtitulo-big blanco center blanco fuente-destacada2">{{$t(`modal.special.desc2`)}}</p>
          </div>
          <br>

          <v-col cols="12" style="margin: 0 auto auto; text-align: center;">
            <button type="button" style="padding: 5px 20px; background-color: #41B849" @click="goTo()"><span style="color: white;font-size: 1.5em !important; font-family: Anton, sans-serif">{{ $t(`modal.special.button`)}}</span></button>
            <br>
            <v-checkbox style="font-size:10px; max-width: fit-content; margin-left: auto;" dark :label="$t('modal.not_show_again')" color="white" @change="notShow()"><p>{{ $t(`modal.not_show_again`)}}</p></v-checkbox>

          </v-col>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cookies from '@/cookies'
import project from '@/project'

export default {
  name: 'SpecialNotification',
  data () {
    return {
      project
    }
  },
  computed: {
    cssStyle () {
      return {
        'background-image': 'linear-gradient(31deg, rgb(4, 6, 16) 0%, rgb(8, 46, 136) 50%, rgb(0, 0, 0) 100%)',
        'background-position-x': 'center',
        'background-position-y': 'top',
        'background-size': 'initial',
        'background-repeat-x': 'no-repeat',
        'background-repeat-y': 'no-repeat',
        'background-attachment': 'initial',
        'background-origin': 'initial',
        'background-clip': 'initial',
        'background-color': '#000000'
      }
    },
    cssTitle () {
      return {
        color: this.isLoverfans() ? '#F71953 !important' : '#9b551a !important',
        fontFamily: 'Anton, sans-serif',
        fontSize: '3em !important'
      }
    },
    cssDesc () {
      return {
        color: this.isLoverfans() ? '#ffbcce !important' : '#d7a478 !important',
        fontSize: '1.8em'
      }
    }
  },
  methods: {
    isLoverfans () {
      return this.project.project.includes('loverfans')
    },
    notShow () {
      cookies.set('notShowSpecialNotification', true)
      this.$emit('close')
    },
    goTo () {
      const url = `https://${project.project}.com/messages`
      cookies.set('notShowSpecialNotification', true)
      window.open(url, '_self').focus()
    }
  }
}
</script>


<style scoped>
/*Modal0*/
.fuente-destacada1 {
  font-family: 'Anton', sans-serif;
  font-size: 5em !important;
}

.fuente-destacada2 {
  font-family: 'Quicksand', sans-serif;
  font-size: 3em;
}

a {
  color: #333333;
}

a:hover, a:focus {
  color: #000000;
}

acronym, abbr {
  border-bottom:1px dotted #111;
}

hr {
  background: #dddddd;
}

blockquote {
  background-color: rgba(0,0,0,0.03);
  border-left:2px solid #cccccc;
}

.sombra{
  -webkit-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
  -moz-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
  box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
}

::selection {
  background:#c80000;
  color:#fff;
}

::-moz-selection {
  background:#c80000;
  color:#fff;
}

.header-modal.login .cerrar-modal a {
  color:#fff;
}

.header-modal.login + .content-modal {
  background-color:#000;
}

.nav-tabs .nav-link {
  border-top-left-radius:.25rem;
  border-top-right-radius:.25rem;
}

.nav-tabs .nav-link:focus,.nav-tabs .nav-link:hover {
  border-color:#e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color:#6c757d;
}

.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active {
  color:#495057;
  background-color:#fff;
  border-color:#dee2e6 #dee2e6 #fff;
}

.nav-pills .nav-link:hover {
  background-color:rgba(0,0,0,0.03)
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link {
  color:#fff;
  background-color:#000
}

.zona-full-tabs > .nav-tabs > .nav-item > .nav-link:focus, .zona-full-tabs > .nav-tabs > .nav-item > .nav-link:hover {
  border:1px solid #dee2e6;
  border-bottom:2px solid #dee2e6;
}

.zona-full-tabs > .nav-tabs > .nav-item > .nav-link.active {
  color:#000;
  background-color:#fff;
  border-bottom:2px solid #000;
}

.nav-tabs-raya .nav-item {
  color:#343a40;
}

.nav-tabs-raya .nav-item .nav-link {
  background-color:transparent
}

.nav-tabs-raya .nav-item .nav-link::after {
  background:#000;
  height:2px;
}

.nav-tabs-raya .nav-item .nav-link.active {
  color:#000;
}

.boton:hover, .boton:focus {
  color: #ffffff !important;
  background-color: #1c1c1c !important;
}

.boton.default:hover {
  color:#333;
  background-color:#e6e6e6;
  border-color:#adadad;
}

.boton.colordest, .boton.colordest:focus {
  background-color: #c80000 !important;
  color: #ffffff !important;
}

.boton.colordest:hover, .boton.colordest:focus {
  color:#fff !important;
  background-color: #1c1c1c !important;
}

.boton.colordest a {
  color:inherit;
}

.boton.invert, .boton.invert:focus {
  color: #ffffff;
  background-color: #1c1c1c;
}

.boton.invert:hover, .boton.invert:focus {
  color: #ffffff;
  background:#c80000;
}

.boton.invert a {
  color:#fff;
}

a.subrayar {
  background-image: linear-gradient(black, black);
}

a.btn-texto {
  color: #0c54a0;
}

.contenido-modal.add .header-modal {
  background:#4cbb6c;
  color:white;
}

p.preciocarro {
  color: #e53131;
}
.sombra{-webkit-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28); -moz-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28); box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);}

p.preciocarro span {
  color:#666;
}

.gmap iframe {
  border:1px solid #e7e7e7;
}

#mapacontacto {
  border-bottom:1px solid #ccc;
  border-top:1px solid #ccc;
}

.gm-style .gm-style-iw {
  background-color: white !important;
}

.table th, .table td {
  border-top: 1px solid #ccc;
}

.table th {
  background:#f2f2f2;
  color:white;
}

.table thead th {
  background:#404040;
}

.table tbody + tbody {
  border-top: 2px solid #ccc;
}

.table .table {
  background-color: #ffffff;
}

.table.tienda thead th {
  background:none;
  color:#666;
  border-bottom: 0px solid #ccc;
}

.table.tienda tr {
  border-bottom: 1px solid #ccc;
}

.table-condensed th, .table-condensed td {
  border-top: 1px solid #ccc;
}

.table.table-condensed th {
  background:#404040;
  color:white;
}

.share a span {
  color:#fff;
  background:#2d2d2d;
}

.share a span:before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #2d2d2d;
}

.share a span.nomostrar {
  color:#fff;
}

.share.rounded a{
  color:#ffffff;
}

.share.rounded a span{
  color: #ffffff;
}

.share.rounded .facebook, .share.rounded .twitter, .share.rounded .instagram, .share.rounded .youtube {
  background-color: #282828;
}

.share.rounded .facebook:hover, .share.rounded .twitter:hover, .share.rounded .instagram:hover, .share.rounded .youtube:hover {
  background-color: #c80000;
}

.share.mini a {
  color:white;
}

.share.mini a span {
  color: #ffffff;
}

.share.mini .facebook:hover, .share.mini .twitter:hover, .share.mini .instagram:hover, .share.mini .youtube:hover {
  background-color: #e71514;
}

.top-urgente p {
  color: #000000;
}

.top-urgente p a, .top-urgente p a:hover, .top-urgente p a:focus {
  color:black;
}

.top-urgente p span {
  background-color: #ff3c00;
  color:white;
}

.fondo-trans {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display:contents;
  z-index:11000;
  overflow-y:auto;
}

.fondo-trans:target {
  visibility: visible;
  opacity: 1;
}
@media screen and (max-width: 700px){.popup{width: 95%;}}

.popup {
  margin: 20px auto;
  background: #fff;
  position: relative;
  z-index:12000;
  overflow: hidden;
  width: 100%;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Arial, Helvetica,sans-serif;
  font-size:1.692em;
  display:inline;
}

.popup .cerrar {
  position: absolute;
  top: 6px;
  right: 10px;
  transition: all 200ms;
  font-size: 1.3em;
  font-weight: normal;
  text-decoration: none;
  color: #333;
  z-index: 2;
}

.popup .cerrar:hover {
  color: #000000;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 95%;
  }
}

.modal-big {
  max-width:700px;
}

.head-contmodal {
  padding: 20px;
  font-size: 0.9em;
  line-height: 1.4em;
}

.cont-modal2 {
  padding: 0px;
}

.head-contmodal {
  padding: 20px;
  font-size: 0.9em;
  line-height: 1.4em;
  text-align: left;
}

.head-contmodal {
  padding: 20px;
  font-size: 0.9em;
  line-height: 1.4em;
  text-align: left;
}

.head-contmodal {
  padding: 20px;
  font-size: 0.9em;
  line-height: 1.4em;
  text-align: left;
}

.boxmodal__titulo-ico span {
  font-size: 2em;
  line-height: 2em;}

.boxmodal__icono i {
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;}

/*Zona modal*/
.cont-modal2 {
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #000000;
}

@media (max-width:991px) {
  .cont-modal2 {
    background-position: left top;
  }
}

.subtitulo-big {
  font-size: 3em;
  font-family: 'Anton', sans-serif !important;
  color: #ffffff;
  line-height: 1.2em;
  text-shadow: 3px 3px 1px rgba(0, 0, 0, 0.4);
}
@media (max-width:500px) {
  .subtitulo-big span{
    font-size: 2em;
  }
}

.box-cont {
  background-color: rgba(255,255,255,0.9);
  width: 80%;
  padding: 20px;
  border-radius: 15px;
  font-size: 1.5em;
  line-height: 1.3em;
  text-align: left;
}

@media (max-width:991px) {
  .box-cont {
    font-size: 1em;
    padding: 10px;
    width: 100%;
  }
}

.nota {
  font-size: 0.8em;
}

@media (max-width:500px) {
  .nota {
    font-size: 0.8em;
  }
}

.pie-mensajes {
  background-color: rgba(255,255,255,0.5);
  padding: 20px 0;
  border-top: 5px solid rgba(255,255,255,0.8);
}

.box-recuerdo {
  background-color: rgba(0,0,0,0.3);
  border: 2px solid rgba(255,255,255,0.7);
  padding: 15px;
  border-radius: 15px;
  min-height: 90px;
}

@media (max-width:991px) {
  .box-recuerdo {
    margin-bottom: 10px;
  }
}

.text-recuerdo {
  margin-left: 65px;
  font-size: 1em;
  line-height: 1.2em;
}

.full {
  width: 100% !important;
}

a {
  text-decoration: none;
  color: #333333;
}

a:hover, a:focus {
  text-decoration: none;
  color: #000000;
}

footer, header, article, section, div, h1, h2, h3, h4, p, form, input, textarea, img, span, td {
  margin:0;
  padding:0;
  background:transparent;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
  background:transparent;
  outline:none;
}

id, class, div, section {
  outline:none;
}

h1,h2,h3,h4,h5,h6 {
  font:inherit;
}

img {
  border:none;
}

svg {
  overflow:hidden;
  vertical-align:middle;
}

video {
  width:100%;
  height:auto;
  border:0;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

sup, sub {
  font-size: 0.75em;
}

b,strong {
  font-weight:bold;
}
.full { width: 100% !important }

ul {
  display: block;
  list-style-type: square;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

dl{
  margin-top:0;
  margin-bottom:20px;
}

dt,dd {
  line-height:1.42857143;
}

dt {
  font-weight:700;
}

dd {
  margin-left:0;
  margin-bottom:15px;
}

@media (min-width:768px){
  .dl-horizontal dt {
    float:left;
    width:160px;
    clear:left;
    text-align:right;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
  .dl-horizontal dd {
    margin-left:180px;
  }
}

.limpiar {
  clear:both;
  font-size:0;
  line-height:0;
  float:none;
}

acronym, abbr {
  border-bottom:1px dotted #111;
  cursor:help;
  font-style:normal;
  text-decoration:none;
}

.separa {
  width:100%;
  height:30px;
  font-size:0;
  clear:both;
}

.separa.xxl-size {
  width:100%;
  height:70px;
  font-size:0;
  clear:both;
}

.separa.xl-size {
  width:100%;
  height:60px;
  font-size:0;
  clear:both;
}

.separa.l-size {
  width:100%;
  height:50px;
  font-size:0;
  clear:both;
}

.separa.s-size {
  width:100%;
  height:20px;
  font-size:0;
  clear:both;
}

.separa.xs-size {
  width:100%;
  height:10px;
  font-size:0;
  clear:both;
}

.izq {
  float:left;
}

.center {
  text-align:center;
}

.centrado {
  margin:0 auto;
  display:block;
  text-align:center;
  float:none;
}

.full {
  width:100%;
}

*::-ms-backdrop, .underline {
  text-decoration:underline;
}

.redondo {
  border-radius:4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.sombra {
  -webkit-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
  -moz-box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
  box-shadow: 0px 0px 28px -2px rgba(0,0,0,0.28);
}

hr {
  display: block;
  margin:2em auto;
  border: 0;
  height: 1px;
  background: #dddddd;
}
.abajo a, .abajo a:focus{color: #666666; text-decoration: none;}
.abajo a:hover, .abajo a:focus{color: #ffffff; text-decoration: none;}

blockquote {
  width:100%;
  background-color: rgba(0,0,0,0.03);
  font-size:1.2em;
  font-style:italic;
  color:#222;
  display: block;
  margin:1em 0;
  padding:40px 25px;
  border-left:2px solid #cccccc;
  position: relative;
}

mark {
  padding: .2em;
  background-color: #fcf8e3;
}

::selection {
  background: rgb(218, 244, 0);
  color: rgb(7, 7, 7);
}

::-moz-selection {
  background: rgb(218, 244, 0);
  color: rgb(7, 7, 7);
}

.header-modal.login .cerrar-modal a {
  color:#fff;
}

.title a {
  text-decoration:none;
  color:#000;
}

.title a:hover {
  text-decoration:none;
  color:#000;
}

.titaba a {
  text-decoration:none;
  color:#fff;
}

.titaba a:hover {
  text-decoration:none;
  color:#fff;
}

.abajo a, .abajo a:focus {
  color: #666666;
  text-decoration: none;
}

.abajo a:hover, .abajo a:focus {
  color: #ffffff;
  text-decoration: none;
}

.negro {
  color:#000000 !important;
}

.blanco {
  color:#ffffff !important;
}

.verde {
  color:#31ae00 !important;
}

</style>

