<template>
  <section>
    <h1 class="large-title-text my-5">{{ $t("referrals.title") }}</h1>
    <p>{{ $t("referrals.desc") }}</p>
    <v-row justify="space-between" align="center">
      <v-col cols="6" sm="auto">
        <v-row>
          <v-col cols="12" sm="auto">
            <v-text-field
              rounded
              outlined
              dense
              hide-details
              disabled
              :label="$t('referrals.url')"
              v-model="referralUrl"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6">
              <c-btn color="secondary" @click="copyLink">
                {{ $t("actions.copy_link_referral") }}
              </c-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" >
        <v-card class="current-balance">
          <v-subheader>{{ $t("models.referral.totalFee") }}</v-subheader>
          <v-card-text>
            <Currency class="x-large-title-text semibold" :amount="amount">
            </Currency>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ReferralTable page-items="5" @loaded="updateTotalAmount" />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import ReferralTable from '@/components/referrals/ReferralTable'

export default {
  name: 'ReferraView',
  components: { ReferralTable },
  data () {
    return {
      amount: 0
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    referralUrl () {
      const origin = window.location.origin
      return `${origin}/?ref=${this.currentUser.id}`
    }
  },
  methods: {
    copyLink () {
      const copyText = document.createElement('textarea')
      copyText.value = this.referralUrl
      copyText.setAttribute('readonly', '')
      copyText.style.position = 'absolute'
      copyText.style.left = '-9999px'
      document.body.appendChild(copyText)
      copyText.select()
      document.execCommand('copy')
      document.body.removeChild(copyText)
      this.showMenu = false
      this.$eventBus.publish('notification', {
        message: this.$t('snackbar.clipboard_copied')
      })
    },
    updateTotalAmount (data) {
      this.amount = data.totalFee
    }
  }
}
</script>
