<template>
  <section>
    <v-row>
      <v-col :cols="isMobile ? 12 : 6">
        <v-tabs grow show-arrows
                background-color="transparent"
                class="profile-sections border-bottom">
          <v-col cols="6" v-for="item in viewModes" :key="item.key">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  :key="item.key"
                  v-bind="attrs"
                  v-on="on"
                  replace
                  @click="sendViewMode(item.key)">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-tab>
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>
          </v-col>
          <v-divider
            vertical
          ></v-divider>
        </v-tabs>

      </v-col>

<!-- TODO: DESCOMENTAR CUANDO EL BACKEND ACEPTE LA VARIABLE "TYPE" Y SETEAR LOS :cols de todos a 12 : 4 en lugar de 12 : 6-->

<!--      <v-col :cols="isMobile ? 12 : 4">-->

<!--        <v-btn-toggle-->
<!--          v-model="mediaTab"-->
<!--          style="min-width: 90%"-->
<!--          tile-->
<!--          background-color="primary"-->
<!--          group-->
<!--          multiple-->
<!--          @change="sendMediaTab"-->
<!--        >-->
<!--          <v-btn value="image" style="min-width: 50%">-->
<!--            <v-icon :dark="darkTheme">camera_alt</v-icon>-->
<!--          </v-btn>-->
<!--          <v-btn value="video" style="min-width: 50%">-->
<!--            <v-icon :dark="darkTheme">videocam</v-icon>-->
<!--          </v-btn>-->

<!--        </v-btn-toggle>-->

<!--      </v-col>-->

      <v-col :cols="isMobile ? 12 : 6">

        <v-btn-toggle
          v-model="contentTypeTab"
          style="min-width: 90%"
          tile
          background-color="primary"
          group
          multiple
          @change="sendTypeMode"
        >
          <v-btn value="open" style="min-width: 33%" >
            {{ $t(`users.profile.tabs.content.open.title`, { post: user.postsStatistics ? user.postsStatistics.open : 0 }) }}
          </v-btn>

          <v-btn value="premium"  style="min-width: 33%">
            {{ $t(`users.profile.tabs.content.premium.title`, { post: user.postsStatistics ? user.postsStatistics.premium : 0 }) }}
          </v-btn>

          <v-btn value="followers"  style="min-width: 33%">
            {{ $t(`users.profile.tabs.content.followers.title`, { post: user.postsStatistics ? user.postsStatistics.followers : 0 }) }}
          </v-btn>
        </v-btn-toggle>

      </v-col>
    </v-row>
  </section>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'ProfileTabs',
  props: {
    value: Object,
    photoItems: { type: Number, default: 0 },
    videoItems: { type: Number, default: 0 },
    user: { type: Object, default: null }
  },
  data () {
    return {
      selectedTab: { publicationMode: '', mediaType: null, contentType: ['open', 'premium', 'followers'] },
      contentTypeTab: [],
      mediaTab: ['image', 'video']
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    viewModes () {
      return [
        { key: 'list', icon: 'view_headline', tooltip: this.$t('users.profile.tabs.timeline') },
        { key: 'grid', icon: 'view_module', tooltip: this.$t('users.profile.tabs.mosaic') }
      ]
    }
  },
  methods: {
    sendViewMode (viewMode) {
      this.selectedTab.publicationMode = viewMode
      this.$emit('input', this.selectedTab)
    },
    sendMediaTab () {
      this.selectedTab.mediaTab = this.mediaTab
      this.$emit('input', this.selectedTab)
    },
    sendTypeMode () {
      this.selectedTab.contentType = this.contentTypeTab
      this.$emit('input', this.selectedTab)
    }
  },
  mounted () {
    this.selectedTab = this.value
  }
}
</script>
<style scoped lang="scss">
  .v-btn:before {
    background: none !important;
    border-bottom: #DF3856;
    border-bottom-style: solid;
  }
  .theme--dark.v-btn:before {
    border-bottom-color: white;
  }
  .v-btn--active:before {
    opacity: 1 !important;
  }
</style>
