<template>
  <div>
    <InfluencerRecommendations />
    <!-- <ProductRecommendations /> -->
  </div>
</template>
<script>
import InfluencerRecommendations from '@/components/widgets/InfluencerRecommendations'
import ProductRecommendations from '@/components/widgets/ProductRecommendations'

export default {
  name: 'Recommendations',
  components: { InfluencerRecommendations, ProductRecommendations }
}
</script>
