import api from '@/api/Api'
import xlsx from 'json-as-xlsx'
import FileSaver from 'file-saver'
import config from '@/project'
import utils from '@/utils'

const refineData = ([withdraws]) => {
  const t = withdraws
  t.content = t.content.map((c) => ({
    ...c,
    // date: c.date !== '-' ? utils.formatDate(c.date, 'YYYY-MM-DD') : '-',
    // createdAt: new Date(c.createdAt),
    // commitedAt: new Date(c.commitedAt)
  }))
  return [t]
}

export default {

  create (request) {
    return api.post('/withdraws', request)
  },

  list (query, options) {
    return api.get('/withdraws', query, options)
  },

  async export ({
    start,
    end
  }, options) {
    const result = await api.get('/withdraws/export', {
      start,
      end
    }, options)
    if (!result.error) {
      const data = refineData(result.data)
      const startDate = utils.formatDate(start, 'YYYYMMDD')
      const endDate = utils.formatUTCDate(end, 'YYYYMMDD')
      xlsx(data, { fileName: `${config.acronym}_${startDate}-${endDate}_Retiradas.xlsx` })
    }
  },

  update (id, attrs) {
    return api.put(`/withdraws/${id}`, attrs)
  },

  updateRejectNotified (attrs) {
    return api.put('/withdraws/rejectNotified', attrs)
  },

  async consigmentFile (query) {
    api.setHeader('Accept', 'application/xml')
    const result = await api.get('/withdraws/consignment_file', query)
    if (!result.error) {
      const blob = new Blob([window.atob(result.data)], { type: 'application/xml' })
      FileSaver.saveAs(blob, 'transactions.xml')
    }
    api.removeHeader('Accept')
    return result
  },

  uploadConsigmentResult (result) {
    return api.post('/withdraws/consignment_file', {
      data: result.toString('base64')
    })
  },

  getData () {
    return api.get('/withdraw_data')
  },

  updateData (config) {
    return api.post('/withdraw_data', config)
  },

  listMethods () {
    return api.get('/withdraw_methods')
  },

  createMethod (method) {
    return api.post('/withdraw_methods', method)
  },

  deleteMethod (id) {
    return api.delete(`/withdraw_methods/${id}`)
  },

  getAutomatic (query) {
    return api.get('/withdraws/automatic', query)
  },

  sendAutomatic (body) {
    return api.post('/withdraws/automatic', body)
  }
}
