<template>
  <c-dialog persistent
    ref="dialog"
    width="684"
    :value="value"
    :actions="false"
    @input="emitClose">
    <template #title>
      <h1 class="semibold ">
        {{ $tc('subscriptions.trials.info_title', trial.duration) }}
      </h1>
    </template>
    <template v-if="showDisabled">
      {{ $t('subscriptions.trials.attributes.disabled') }}
    </template>
    <template v-else-if="showInfo">
      <TrialClaimInfo :trial="trial"/>
      <v-row justify="space-around">
        <v-col cols="auto">
          <c-btn color="success"
            large
            @click.stop="startClaim">
            {{ $t('boolean.true')}}
          </c-btn>
        </v-col>
        <v-col cols="auto">
          <c-btn color="secondary" @click="emitClose">
            {{ $t('boolean.false')}}
          </c-btn>
        </v-col>
      </v-row>
    </template>
    <LoginForm v-else-if="showLogin"
      @signup="goToSignup"
      @submitted="claimTrial" />
    <SignupForm v-else-if="showSignup"
      @login="goToLogin"
      @submitted="claimTrial"  />
    <iframe v-else
      :src="paymentUrl"
       class="form-container"
    />
  </c-dialog>
</template>
<script>
import { mapState } from 'vuex'
import SubscriptionApi from '@/api/SubscriptionApi'
import LoginForm from './LoginForm'
import SignupForm from './SignupForm'
import TrialClaimInfo from './TrialClaimInfo'
import messages from '../locales/all.json'

export default {
  name: 'TrialClaimDialog',
  components: { LoginForm, SignupForm, TrialClaimInfo },
  i18n: { messages },
  props: {
    value: Boolean,
    trial: Object
  },
  data () {
    return {
      showDisabled: false,
      showInfo: true,
      showLogin: false,
      showSignup: false,
      dialog: true,
      paymentUrl: ''
    }
  },
  computed: {
    ...mapState('session', ['authenticated'])
  },
  methods: {
    emitClose () {
      this.$emit('input', false)
    },
    goToLogin () {
      this.showSignup = false
      this.showLogin = true
    },
    goToSignup () {
      this.showSignup = true
      this.showLogin = false
    },
    async startClaim () {
      this.showInfo = false
      if (this.authenticated) {
        this.claimTrial()
      } else {
        this.goToLogin()
      }
    },
    async claimTrial () {
      this.showSignup = false
      this.showLogin = false
      const { data, error } = await SubscriptionApi.claimTrial(this.trial.token)
      if (error) {
        this.showDisabled = true
        return
      }
      if (data.started) {
        this.$router.push({ name: 'influencer', params: { username: this.trial.author.username } })
      } else if (data.formUrl) {
        this.paymentUrl = data.formUrl
      } else if (data.billingUrl) {
        window.location = `${data.billingUrl}/complete/${data.uuid}?provider=${data.project}`
      }
    }
  },
  mounted () {
    this.showDisabled = this.trial.disabled
  }
}
</script>
<style lang="scss">
.form-container {
  border: none;
  height: 650px;
  width: 100%;
}
</style>
