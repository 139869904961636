<template>
  <section>
    <template v-if="!currentUser.tags.length">
      <p>{{ $t('tags.description_1', { name: currentUser.displayName })}}</p>
      <p>{{ $t('tags.description_2')}}</p>
      <p>{{ $t('words.thank_you')}}</p>
    </template>
    <h4 class="title-text semibold">{{ $t('tags.category.race')}}</h4>
    <v-chip-group
      column
      v-model="race"
      active-class="primary--text text--accent-4">
      <template v-for="race in races">
        <v-chip outlined
          :key="race || 'none'"
          :value="race">
          {{ $t(`tags.${race || 'none'}`) }}
        </v-chip>
      </template>
    </v-chip-group>
    <h4 class="title-text semibold mt-4">{{ $t('tags.category.gender')}}</h4>
    <v-chip-group
      column
      v-model="gender"
      active-class="primary--text text--accent-4">
      <template v-for="gender in genders">
        <v-chip outlined
          :key="gender"
          :value="gender">
          {{ $t(`tags.${gender}`) }}
        </v-chip>
      </template>
    </v-chip-group>
    <h4 class="title-text semibold mt-4">{{ $t('tags.category.content')}}</h4>
    <v-chip-group
      column
      v-model="category"
      active-class="primary--text text--accent-4"
      multiple
      @change="checkScatWarning">
      <template v-for="category in filteredCategories">
        <v-chip outlined filter
          :key="category"
          :value="category">
          {{ $t(`tags.${category}`) }}
        </v-chip>
      </template>
    </v-chip-group>
    <h4 class="title-text semibold mt-4">{{ $t('tags.category.age')}}</h4>
    <v-chip-group
      column
      active-class="primary--text text--accent-4"
      v-model="age">
      <template v-for="age in ages">
        <v-chip outlined
          :key="age"
          :value="age">
          {{ $t(`tags.${age}`) }}
        </v-chip>
      </template>
    </v-chip-group>

    <v-row align="center" justify="end">
      <v-col cols="auto">
        <c-btn
          :disabled="!isValidForm"
          @click="submit">
          {{ $t('actions.save')}}
        </c-btn>
      </v-col>
    </v-row>
    <ScatWarningDialog
      :dialog.sync="showWarning" 
      @accepted="removeScat"
      @canceled="addScat"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { ProfileActions } from '@/store'
import ScatWarningDialog from './ScatWarningDialog.vue'
import project from '@/project'

export default {
  name: 'TagProfile',
  components: { ScatWarningDialog },
  data () {
    return {
      showWarning: false,
      loading: false,
      genders: ['male', 'female', 'trans', 'couple', 'producer'],
      gender: null,
      races: ['white', 'asian', 'black', 'indian', 'latin', ''],
      race: '',
      categories: ['model', 'soft', 'straight', 'swinger', 'homo', 'lesbian', 'bdsm', 'mistress', 'trans_content', 'scat', 'extreme'],
      category: [],
      ages: ['teen', 'young', 'adult', 'mature'],
      age: null
    }
  },

  computed: {
    ...mapState('application', ['tags']),
    ...mapState('profile', ['currentUser']),
    filteredCategories () {
      if (project.project === 'scatbook') return this.categories.filter((c) => c !== 'scat')
      return this.categories
    },
    isValidForm () {
      return this.gender && this.category.length && this.age
    },
    selectedTags () {
      return [this.gender, ...this.category, this.age, this.race].filter(t => !!t)
    }
  },

  methods: {
    containsScat(tags) {
      return tags.includes('scat')
    },
    checkScatWarning (value) {
      if (this.containsScat(this.oldCategories)) {
        if (!this.containsScat(value)) this.showWarning = true
      }
      this.oldCategories = value
    },
    addScat () {
      if (this.containsScat(this.category)) return
      this.category.push('scat')
    },
    removeScat () {
      this.category = this.category.filter(c => c !== 'scat')
    },
    async submit () {
      const tags = this.selectedTags
      if (project.project === 'scatbook') {
        tags.push('scat')
      }
      const tagIds = this.tags
        .filter(t => tags.includes(t.name))
        .map(t => t.id)
      await this.$store.dispatch(ProfileActions.UpdateAccountData, { tagIds })
      this.$emit('submit')
    }
  },

  mounted () {
    this.currentUser.tags.forEach(tag => {
      if (this.genders.find(g => tag.name === g)) {
        this.gender = tag.name
      } else if (this.categories.find(g => tag.name === g)) {
        this.category.push(tag.name)
      } else if (this.ages.find(g => tag.name === g)) {
        this.age = tag.name
      } else if (this.races.find(r => tag.name === r)) {
        this.race = tag.name
      }
    })
    this.oldCategories = this.category
  }

}
</script>
