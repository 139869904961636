<template>
  <section>
    <div class="center" :class="{mobile: isMobile}" style="margin-bottom: 20px">
      <img src="/images/logo_loverfans_horizontal_white_color.png" :height="isMobile ? 30 : 33" :width="isMobile ? 192 : 211" alt="loverfans_logo" style="margin-top: 24px" :style="{marginRight: isMobile ? '0px' : '40px'}" />
      <img src="/images/logo_horizontal_darkfans_color.png" :height="isMobile ? 40 : 51" :width="isMobile ? 150 : 200" alt="darkfans_logo" style="margin-top: 24px" />
    </div>
    <div class="center">
      <span class="cross">X</span>
    </div>
    <div class="center" style="margin-top: 20px">
      <img src="./resources/sanctuary.png" :height="isMobile ? 84 : 76" :width="isMobile ? 84 : 76" alt="sanctuary_logo" />
    </div>

    <div class="col center mt-3" v-if="!isMobile">
      <div class="free-bonus">
        <div class="text-white" style="max-width: fit-content">{{ $t('posts.card_overlay.free').toUpperCase() }}</div>
        <div class="bonus">{{  $t('promo_code.desade') }}</div>
      </div>
    </div>

    <div class="col center mt-3" v-else>
      <div class="free-bonus mobile">
        <div class="text-white">{{ $t('posts.card_overlay.free').toUpperCase() }}</div>
        <div class="row bonus">{{  $t('promo_code.desade') }}</div>
      </div>
    </div>

    <div class="mx-auto col-10" style="margin-top: 32px; max-width: 500px; width: 90%;">
      <SignUpPromos promo="SANCTUARYBCN23"></SignUpPromos>
    </div>

  </section>
</template>

<script>
import SignUpPromos from './SignUpPromos'
import config from '@/project'
import { mapState } from 'vuex'

export default {
  name: 'sanctuary2023',
  components: { SignUpPromos },
  data () {
    return {
      config
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
  },
  methods: {
    ended () {
      const today = new Date();
      var eventEnded = new Date(2023, 8, 10, 23, 59, 59)
      return (eventEnded - today) < 0
    }
  },
  mounted() {
    if (config.project === 'scatbook' || this.ended()) {
      this.$router.push('home')
    }
  }
}
</script>

<style lang="scss" scoped>

.cross {
  margin-left: 32px;
  margin-right: 32px;
  color: white;
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
}

.text-white {
  border-color: #E9CF88;
  border-style: solid;
  border-width: thick;
  padding: 0 8px;
  font-family: Mshtakan !important;
}

.bonus {
  border-color: #E9CF88;
  border-width: thick;
  border-style: solid;
  background-color: #E9CF88;
  /* margin-left: -22px; */
  font-family: Mshtakan !important;
  white-space: nowrap;
}

.free-bonus {
  font: normal normal bold 65px/72px Mshtakan;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  .text-green {
    color: #88FF00;
  }
  &.mobile {
    width: 90% !important;
    margin: auto;
    padding: 0 8px;
    text-align: center;
    margin-bottom: -5px;
    font-family: Mshtakan !important;
    font-size: 40px !important;
    .text-white {
      border-color: #E9CF88;
      border-style: solid;
      border-width: thick;
      padding: 0 8px;
    }
  }
}

.row.bonus {
  display: block;
}

.center.mobile {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: flex-start;
  align-items: center;
}
</style>
