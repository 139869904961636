import api from '@/api/Api'

export default {

  create (comment) {
    return api.post('/comments', comment)
  },

  delete (id) {
    return api.delete(`/comments/${id}`)
  },

  list (query, options) {
    return api.get('/comments', query, options)
  }
}
