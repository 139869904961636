<template>
  <section>
    <ProfileTabs :value="publicationFilter"
                 :user="user"
      @input="selectedTabs" />
    <v-alert v-if="showPromoteCard && isPending"
      text type="info" color="blue">
      {{ $t('promotion.state.pending') }}
    </v-alert>
    <v-card v-if="showPromoteCard"
      tile flat
      class="not-influencer">

      <h2>{{ $t('user_profile.publications.promote.title') }}</h2>
      <h2 class="title-text-2 roboto my-2">{{ $t("signup.promotion_check.description") }}</h2>
      <div class="engagement text-left">
        <div class="my-4">
          <v-icon>remove_red_eye</v-icon>
          {{ $t("signup.promotion_check.description_1") }}
        </div>
        <!--
          <div class="my-4">
            <v-icon>shopping_basket</v-icon>
            {{ $t("signup.promotion_check.description_2") }}
          </div>
        -->
        <div class="my-4">
          <v-icon>attach_money</v-icon>
          {{ $t("signup.promotion_check.description_3") }}
        </div>
      </div>
      <div>
        <c-btn small
          :to="{ name: 'settings', query: { tab: 'service' } }"
          css="mt-5"
          color="primary">
          {{ $t('user_profile.publications.promote.button')}}
        </c-btn>
      </div>
    </v-card>
    <template v-else>
      <ListView no-gutters
        cols="6" sm="4" md="3"
        v-if="publicationFilter.publicationMode === 'grid'"
        :loading="cursor.loading"
        :items="cursor.items"
        item-class="dynamic-padding"
        :empty-text="noContentLabel"
        @bottom-reached="cursor.loadItems">
        <template #default="{ item }">
          <ConferenceCard v-if="item.componentType === 'conference'"
            :key="`conference-${item.id}`"
            v-bind="item" />
          <PostCard v-else
            :key="`post-${item.id}`"
            :post="item"/>
        </template>
      </ListView>

      <ListView v-else
                no-gutters
                cols="12"
                :loading="cursor.loading"
                :items="cursor.items"
                :item-min-height="200"
                item-class="border-bottom"
                :empty-text="noContentLabel"
                @bottom-reached="cursor.loadItems">
        <template #default="{ item }">
          <PostPage :post="item"
            fake-video />
        </template>
      </ListView>

    </template>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import PostService from '@/components/posts/mixins/post_service'
import ConferenceCard from '@/components/conferences/ConferenceCard'
import PostCard from '@/components/posts/PostCard'
import ConferenceApi from '@/api/ConferenceApi'
import ProfileTabs from '@/components/users/ProfileTabs'
import PostPage from '@/components/posts/PostPage'

const constainsSameVisibility = (arr1, arr2) => {
  const curr = arr1 && arr1.length ? arr1 : []
  const newVis = arr2 && arr2.length ? arr2 : []

  let same = curr.length === newVis.length
  curr.forEach(vis => {
    same = same && newVis.includes(vis)
  })
  return same
}

export default {
  name: 'UserPublications',
  mixins: [PostService({ cursor: true })],
  components: { PostPage, ProfileTabs, PostCard, ConferenceCard },
  props: {
    username: String,
    user: { type: Object, default: null }
  },
  data () {
    return {
      publicationFilter: {
        publicationMode: 'list',
        contentType: [],
        mediaType: null
      },
      query: {
        visibility: ['open', 'premium', 'followers'],
        publisher: this.username,
        promotion: false,
        perPage: 16
      }
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isFollower', 'promotion', 'isAdmin']),
    isCurrentUser () {
      return this.currentUser.username === this.username
    },
    isPending () {
      return this.promotion.status === 'pending'
    },
    showPromoteCard () {
      return this.isCurrentUser && this.isFollower
    },
    noContentLabel () {
      const noPost = this.isCurrentUser ? 'no_posts' : this.publicationFilter ? 'not_found' : 'no_posts_visit'
      return this.$t(`listview.${noPost}`)
    }
  },
  methods: {
    selectedTabs (value) {
      delete this.query.type
      value = value || { contentType: ['open', 'premium', 'followers'] }
      this.publicationFilter = value
      const shouldResetTabs = !constainsSameVisibility(this.query.visibility, value.contentType)
      if (value.contentType.length > 0) {
        this.query.visibility = value.contentType
      } else {
        this.query.visibility = ['open', 'premium', 'followers']
      }
      if (shouldResetTabs) {
        this.cursor.resetCursor(true)
      }
    },
    onPostCreated (post) {
      const publishTime = new Date(post.publishedAt).getTime()
      this.cursor.addItem(post, p => new Date(p.publishedAt).getTime() < publishTime)
    },
    onPostDeleted (postId) {
      this.cursor.removeItem(p => p.id === postId)
    }
  },
  created () {
    if (this.isCurrentUser) {
      this.subscribePostCreated()
      this.subscribePostDeleted()
    }
  },
  async mounted () {
    this.publicationFilter.publicationMode = this.$route.query.listview || 'list'
    const { data, error } = await ConferenceApi.findCurrent(this.username)
    if (!error && data) {
      this.cursor.items.unshift({ ...data, componentType: 'conference' })
    }
  }
}
</script>
<style lang="scss" scoped>
.not-influencer {
  margin: 50px auto;
  max-width: 550px;
  .engagement {
    display: inline-block;
    margin: 25px auto;
  }
}
.dynamic-padding {
  padding: 0.5% !important;
}
</style>
