<template>
  <c-img class="user-cover" aspect-ratio="4.6" :height="checkHeight()"
    gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75) 99%">
    <div class="user-profile">
      <v-row>
        <v-col cols="auto">
          <div class="user-info">
            <UserAvatar vertical x-large profile>
              <v-row justify="center" align="center">
                <div slot="name" class="title-text-2 bold white--text mx-2">{{ user.displayName }}</div>
                <div slot="username" class="regular-text white-light-text mx-2">{{ `@${user.username}` }}</div>
              </v-row>
            </UserAvatar>
          </div>
        </v-col>
      </v-row>
    </div>
  </c-img>
</template>

<script>
import UserAvatar from '@/components/users/UserAvatar'

export default {
  name: 'BlockedCover',
  components: { UserAvatar },
  props: { user: Object },
  methods: {
    checkHeight () {
      return this.isMobile ? 335 : 320
    }
  }
}
</script>
<style lang="scss" scoped>
.user-cover {
  color: white !important;
  position: relative;
  height: 262px;
  padding: 20px;
  .profile-actions {
    position: absolute;
    text-align: right;
    top: 22px;
    right: 22px;
  }
}
</style>
